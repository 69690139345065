import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "../../context/AuthContext";
import { fba } from "../../firebaseConfig";
import Head from "../generales/Head";

const Inicio = ({ history }) => {

  const [admin, setAdmin] = useState(false);
  const { usuario } = useContext(Auth);


  useEffect(() => {

    if (usuario === null) {
      history.push("/siip");
    } else if (window.localStorage.getItem("token") === null) {
      fba.auth().signOut();
      history.push("/siip");
    } else {
      const firestore = fba.firestore();
      const db = firestore.collection("usuarios");

      db.where("correo", "==", usuario.email).get().then((snapshot) => {
        if (snapshot.docs[0]) {
          setAdmin(true);
        } else {
          history.push("/registro");
        }
      });
    }
  }, [history, usuario]);

  return (
    <>
      {admin ? <Head /> : null}
    </>
  );
};

export default withRouter(Inicio);
