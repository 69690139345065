import React, { useEffect, useState } from 'react'
import { Backdrop, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global, FILE_SIZE, SUPPORTED_PDF } from '../../../../constants/global';
import Swal from 'sweetalert'
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { fba } from "../../../../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AgregarMateria = (props) => {

    const classes = useStyles();
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [programas, setProgramas] = useState([])
    const [carga, setCarga] = useState(false)
    const [fileMaya, setFileMaya] = useState(0);


    useEffect(() => {
        const datosIniciales = async () => {
            setRegistroAnterior({
                estatus: '',
                clave: '',
                programa: ''
            })

            await axios.get(Global + "programa", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setProgramas(response.data)
            }).catch(error => console.log(error))
        }

        datosIniciales();

        // await axios.get(Global + "materia", {
        //     headers: {
        //         'Access-Control-Allow-Origin': "*",
        //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
        //         'Content-Type': 'application/json',
        //         Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        //     }
        // }).then(response => {
        //     // console.log(response.data)
        //     setMaterias(response.data)
        // }).catch(error => console.log(error))

    }, [])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        clave: Yup.string().required('Es obligatorio ingresar la  materia'),
        programa: Yup.number().required('Es obligatorio ingresar el programa'),
        maya: Yup.mixed()
            .test(
                "fileType",
                "El formato del archivo es incompatible",
                () => fileMaya === 0 || SUPPORTED_PDF.includes(fileMaya.type)
            )
            .test(
                "fileSize",
                "El tamaño del archivo es mayor a 2Mb",
                () => fileMaya === 0 || fileMaya.size <= FILE_SIZE
            ),
    })

    const initial = registroAnterior

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];

            switch (event.target.id) {
                case "maya":
                    setFileMaya(file);
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "maya":
                    setFileMaya(false);
                    break;
                default:
                    break;
            }
        }
    };


    const funcionSubmit = async valores => {

        setCarga(true)
        let plan = {
            estatus: "alta",
            clave: valores.clave,
            programa: {
                idprograma: valores.programa
            },
            mapa: ""
        };

        if (fileMaya) {
            plan.mapa =
                "Plan_de_estudio/" + valores.clave + "/" + valores.programa + "/" + uuidv4() + fileMaya.name;
            const storageRef = fba.storage().ref(plan.mapa);
            storageRef.put(fileMaya);

        }

        await axios.post(Global + "plan/programa/" + valores.programa, plan, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(() => {
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            setCarga(false)
            props.metodo(true)
        }).catch(error => {
            console.log(error);
            setCarga(false)
        })
    }

    return (
        <>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                validationSchema={schemaValidacion}
                enableReinitialize
                initialValues={initial}
                onSubmit={(valores) => {
                    funcionSubmit(valores)
                }}
            >
                {props => {

                    return (
                        <form noValidate onSubmit={props.handleSubmit}>
                            <Grid container spacing={2} justify='center'>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Clave"
                                        id="clave"
                                        name="clave"
                                        type="text"
                                        value={props.values.clave}
                                        onChange={(e) => {
                                            let clave = e.target.value;
                                            props.setFieldValue("clave", clave.toUpperCase())
                                        }}
                                        onBlur={props.handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        className={classes.formControl}
                                        error={props.touched.programa && props.errors.programa ? (true) : false}>
                                        <InputLabel id="programa">Programa</InputLabel>
                                        <Select
                                            labelId='programa'
                                            native
                                            required
                                            value={props.values.programa}
                                            onChange={props.handleChange}
                                            // onChange={props.handleOnChange}
                                            onBlur={props.handleBlur}
                                            label="programa"
                                            inputProps={{
                                                name: 'programa',
                                                id: 'programa',
                                            }}
                                        >
                                            <option aria-label="None" value="" />{
                                                programas.map((programa) => {
                                                    return <option key={programa.idprograma} value={programa.idprograma}>{programa.programa}</option>
                                                })
                                            }

                                        </Select>
                                        <FormHelperText>
                                            {props.touched.programa && props.errors.programa ? (props.errors.programa) : null}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={10}>
                                    <label htmlFor="maya">
                                        <input
                                            style={{ display: "none" }}
                                            id="maya"
                                            name="maya"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleOnChange}
                                        />
                                        <Button
                                            className={classes.boton}
                                            color="primary"
                                            variant="contained"
                                            component="span"
                                        >
                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                            Maya curricular
                                            {fileMaya ?
                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                : null}
                                        </Button>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            color="error"
                                        >
                                            {props.touched.maya &&
                                                props.errors.maya &&
                                                props.errors.maya}
                                        </Typography>
                                    </label>
                                </Grid>

                            </Grid>
                            <br />
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12} sm={5} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={classes.boton}>
                                        Guardar
                                    </Button>
                                </Grid>
                            </Grid>

                        </form>
                    )
                }}
            </Formik >
            <Grid container spacing={1} justify='center'>
                <Grid item xs={12} sm={5} >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            props.metodo(false);
                        }}>
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default AgregarMateria;