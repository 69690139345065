/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Hidden } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Avatar, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import axios from 'axios';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { fba } from "../../firebaseConfig";
import { Global } from '../../constants/global';
import { Alert } from '@material-ui/lab';
import Mensaje from "./Mensaje"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paperxs: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100px',
        height: '40px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "10%",
        marginRight: "10%",
    },
    div: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        background: theme.palette.secondary.main,
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#d5303e",
        /* color: "#54595F", */
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
}));

const CerrarRegistro = ({ history }) => {
    const classes = useStyles();

    const [datos, setDatos] = useState(false)
    const [escolaridad, setEscolaridad] = useState(false)
    const [posgrado, setPosgrado] = useState(false)
    const [carta, setCarta] = useState(false)
    const [ocupacion, setOcupacion] = useState()
    const [correo, setCorreo] = useState(false)
    const [aspirante, setAspirante] = useState([])
    const [alerta, setAlerta] = useState('')
    const [mensaje, setMensaje] = useState(true)


    useEffect(async () => {
        //Usuario
        let user = fba.auth().currentUser;
        await axios.get(Global + "persona/correo/" + user.email, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            axios.get(Global + "aspirante/personas/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data)
                    setAspirante(response.data[0])
            }).catch(error => console.log(error));

            //Conseguir datos de contacto de emergencia
            axios.get(Global + "contactoem/personas/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data[0])
                    setDatos(true)
            }).catch(error => console.log(error));
            // Revisar si existe escolaridad
            axios.get(Global + "escolaridad/personas/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data[0])
                    setEscolaridad(true)
            }).catch(error => console.log(error))

            //Campos para estudiosrea
            axios.get(Global + "estudiosrea/persona/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if (response.data[0])
                    setPosgrado(true)
            }).catch(error => console.log(error))

            axios.get(Global + "ocupacion/personas/" + response.data[0].idP, {
                 headers: {
                     'Access-Control-Allow-Origin': "http://localhost:3000",
                     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                     'Content-Type': 'application/json',
                     Authorization: 'Bearer ' + window.localStorage.getItem('token'),

                 }
             }).then(responses => {
                if (responses.data[0]) {
                     setOcupacion(true)
                 }
             }).catch(error => console.log(error))

            axios.get(Global + "carta/persona/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json'

                }
            }).then(responses => {
                let datos = 0;
                if (responses.data.length > 1) {
                    setCorreo(true)
                    responses.data.map((registro, index) => {
                        if (registro.estatus === "Completado") {
                            datos++
                        }

                    })
                    if (datos >= 2) {
                        setCarta(true)
                    }
                }

            }).catch(error => console.log(error))
        }).catch(error => console.log(error))
    }, [])



    const submit = () => {
        const user = fba.auth().currentUser.email;        
        if (datos && escolaridad && posgrado && carta) {
            let cambio = aspirante;
            cambio.estatus = "Registro_Cerrado"
            setAspirante(cambio)
            axios.post(`${Global}email/termino?destinatario=${user}`,
            {//se necesita un json para que se envie correctamente,tratar de cambiar content-type
                cita: {
                    cita: "cita"
                }
            },{
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),

                }
            }).then((response)=>{

            }).catch((error)=>{})

            //Actualizamos el estado de aspirante
            axios.put(Global + "aspirante/" + aspirante.idaspirante, aspirante, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                fba.auth().signOut()
                setMensaje(false)
            }).catch(error => {
                console.log(error);
            })
        } else {
            setAlerta(<Alert severity="error">El registro no se puede cerrar complete la información requerida</Alert>)
        }
    }

    return (
        mensaje ?
            <>
                <Paper elevation={3} className={classes.paperContainer}>
                    <Avatar className={classes.avatar}>
                        <AllInboxIcon />
                    </Avatar>
                    <Typography className={classes.subtitulo} >
                        Cerrar Registro Como Aspirante
                    </Typography>
                    <br />
                    <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                                    "Manifiesto que toda la información que he proporcionado es verídica y que no he ocultado
                                    hecho o circunstancia que pudiera, en cualquier forma o momento, afectar desfavorablemente
                                    lo expuesto aquí. Acepto la responsabilidad que me sobreviniera por falsedad en algunos de
                                    los datos o falta de documentos que acrediten esta solicitud. Asimismo, quedo enterado que
                                    esta solicitud no será procesada si la documentación no está completa y que la recepción de
                                    la misma no implica que la Universidad Autónoma Chapingo me acepte como estudiante de posgrado;
                                    además, estoy de acuerdo con los términos de la convocatoria y quedo enterado".
                    </Typography>
                    <br />
                    <div className={classes.div} >
                        <Timeline align="right">
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Hidden smUp>
                                        {datos ?
                                            <Typography variant="body2" color="textSecondary">
                                             Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Datos faltantes


                                            </Typography>
                                        }
                                        <Paper elevation={1} className={classes.paperxs}>
                                            <Typography variant="body2" color={datos ? "textSecondary" : "error"}>
                                              Sección Datos Generales
                                            </Typography>
                                        </Paper>
                                    </Hidden>
                                    <Hidden xsDown>
                                        <br />
                                        {datos ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Registro faltante
                                                <br />
                                                * Datos Generales.
                                                <br />
                                                El campo de fecha de nacimiento es obligatorio.
                                                <br />
                                                El campo género es obligatorio.
                                                <br />
                                                El campo estado civil es obligatorio.
                                                <br />
                                                El campo país es obligatorio.
                                                <br />
                                                Es obligatorio ingresar la institución del otorgante.
                                                <br />
                                                * Domicilio.
                                                <br />
                                                El campo país es obligatorio.
                                                <br />
                                                * En caso de emergencia.
                                                <br />
                                                El campo nombre es obligatorio.
                                                <br />
                                                El parentesco paterno es obligatorio.
                                                <br />
                                                El campo télefono es obligatorio.
                                                <br />
                                                El campo correo es obligatorio.
                                                <br />
                                                El campo país es obligatorio.
                                                <br />
                                                * El campo es obligatorio si es de MÉXICO.
                                                <br />
                                                Código postal.
                                                <br />
                                                Estado.
                                                <br />
                                                Municipo.
                                                <br />
                                                Colonia.
                                                <br />
                                                Calle y número.
                                            </Typography>
                                        }
                                    </Hidden>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="secondary">
                                        <PermIdentityIcon />
                                    </TimelineDot>
                                    <TimelineConnector className={classes.secondaryTail} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Hidden xsDown>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Typography variant="h6" component="h1">
                                                Sección Datos Generales
                                            </Typography>
                                            {/* <Typography>Información personal y datos de contacto</Typography> */}
                                        </Paper>
                                    </Hidden>
                                </TimelineContent>
                            </TimelineItem>

                            

                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Hidden smUp>
                                        {posgrado ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Datos faltantes
                                            </Typography>
                                        }
                                        <Paper elevation={1} className={classes.paperxs}>
                                            <Typography variant="body2" color={posgrado ? "textSecondary" : "error"}>
                                                Sección Estudios Que Desea Realizar
                                            </Typography>
                                        </Paper>
                                    </Hidden>
                                    <Hidden xsDown>
                                        <br />
                                        {posgrado ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Registro Faltante
                                                <br />
                                                * Registro posgrado.
                                                <br />
                                                Es obligatorio ingresar el estudio que desea realizar.
                                                <br />
                                                Es obligatorio seleccionar el periodo.
                                                <br />
                                                Es obligatorio seleccionar el posgrado.
                                                <br />
                                                Es obligatorio seleccionar la categoría.
                                                <br />
                                                Es obligatorio documento proyecto de investigación o trabajo profesional a desarrollar
                                                <br />
                                                Es obligatorio documento fuente de financiamiento
                                            </Typography>
                                        }
                                    </Hidden>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="secondary">
                                        <MenuBookIcon />
                                    </TimelineDot>
                                    <TimelineConnector className={classes.secondaryTail} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Hidden xsDown>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Typography variant="h6" component="h1">
                                                Sección Estudios Que Desea Realizar
                                            </Typography>
                                            {/* <Typography>Información de estudios que desea realizar</Typography> */}
                                        </Paper>
                                    </Hidden>
                                </TimelineContent>
                            </TimelineItem>

                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Hidden smUp>
                                        {escolaridad ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Datos faltantes
                                            </Typography>
                                        }
                                        <Paper elevation={1} className={classes.paperxs}>
                                            <Typography variant="body2" color={escolaridad ? "textSecondary" : "error"}>
                                                Sección Estudios Realizados
                                            </Typography>
                                        </Paper>
                                    </Hidden>
                                    <Hidden xsDown>
                                        <br />
                                        {escolaridad ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Registro faltante.
                                                <br />
                                                * Estudios realizados.
                                                <br />
                                                Es obligatorio ingresar el grado de estudio.
                                                <br />
                                                Es obligatorio seleccionar una institucion.
                                                <br />
                                                Es obligatorio seleccionar el país.
                                                <br />
                                                Es obligatorio ingresar el estado.
                                                <br />
                                                Es obligatorio seleccionar el año inicio.
                                                <br />
                                                Es obligatorio seleccionar el año terminación.
                                                <br />
                                                Es obligatorio ingresar el promedio.
                                                <br />
                                                Es obligatorio ingresar la cédula.
                                                <br />
                                                Es obligatorio ingresar título de maestria
                                                <br />
                                                Es obligatorio ingresar lista oficial de materias con promedio general de maestria
                                                <br />
                                                Es obligatorio ingresar título de licenciatura
                                                <br />
                                                Es obligatorio ingresar lista oficial de materias con promedio general de licenciatura
                                            </Typography>
                                        }
                                    </Hidden>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="secondary">
                                        <AccountBalanceIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Hidden xsDown>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Typography variant="h6" component="h1">
                                                Sección Estudios Realizados
                                            </Typography>
                                           {/*  <Typography>Información de último grado de estudio</Typography> */}
                                        </Paper>
                                    </Hidden>
                                </TimelineContent>
                            </TimelineItem>

                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Hidden smUp>
                                        {ocupacion ?
                                            <Typography variant="body2" color="textSecondary">
                                                Registro completo
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Datos faltantes.
                                            </Typography>
                                        }
                                        <Paper elevation={1} className={classes.paperxs}>
                                            <Typography variant="body2" color={ocupacion ? "textSecondary" : "error"}>
                                                Ocupacion
                                            </Typography>
                                        </Paper>
                                    </Hidden>
                                    <Hidden xsDown>
                                        <br />
                                        {ocupacion ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Registro faltante
                                                <br />
                                                * Experiencia Profesional
                                                <br />
                                                Es obligatorio el CV
                                                <br />
                                               
                                            </Typography>
                                        }
                                    </Hidden>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="secondary">
                                        <BusinessCenterIcon />
                                    </TimelineDot>
                                    <TimelineConnector className={classes.secondaryTail} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Hidden xsDown>
                                        <Paper elevation={3} className={classes.paper} >
                                            <Typography variant="h6" component="h1">
                                                Sección Experiencia Profesional
                                            </Typography>
                                        </Paper>
                                    </Hidden>
                                </TimelineContent>
                            </TimelineItem>

                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Hidden smUp>
                                        {carta ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Datos faltantes
                                            </Typography>
                                        }
                                        <Paper elevation={1} className={classes.paperxs}>
                                            <Typography variant="body2" color={carta ? "textSecondary" : "error"}>
                                                Recomendación
                                            </Typography>
                                        </Paper>
                                    </Hidden>
                                    <Hidden xsDown>
                                        <br />
                                        {correo ?
                                            null :
                                            <Typography variant="body2" color="error">
                                                Registro faltante
                                                <br />
                                                Es obligatorio ingresar el nombre del otorgante
                                                <br />
                                                Es obligatorio ingresar el correo del otorgante
                                                <br />
                                                Es obligatorio seleccionar el grado de estudios del otorgante
                                                <br />
                                                Es obligatorio ingresar la ocupación del otorgante
                                                <br />
                                                Es obligatorio ingresar la institución del otorgante
                                            </Typography>
                                        }
                                        <br />
                                        {carta ?
                                            <Typography variant="body2" color="textSecondary">
                                                Completada
                                            </Typography> :
                                            <Typography variant="body2" color="error">
                                                Falta respuesta de las cartas de recomendación.
                                            </Typography>
                                        }
                                    </Hidden>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="secondary">
                                        <FeaturedPlayListIcon />
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Hidden xsDown>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Typography variant="h6" component="h1">
                                                Sección Cartas de Recomendación
                                            </Typography>
                                            {/* <Typography>Registro de recomendación del aspirante</Typography> */}
                                        </Paper>
                                    </Hidden>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                        <br />
                        {alerta}
                        <br />
                        {datos && escolaridad && posgrado && carta ?
                            <>
                                {/* <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                                    "Manifiesto que toda la información que he proporcionado es verídica y que no he ocultado
                                    hecho o circunstancia que pudiera, en cualquier forma o momento, afectar desfavorablemente
                                    lo expuesto aquí. Acepto la responsabilidad que me sobreviniera por falsedad en algunos de
                                    los datos o falta de documentos que acrediten esta solicitud. Asimismo, quedo enterado que
                                    esta solicitud no será procesada si la documentación no está completa y que la recepción de
                                    la misma no implica que la Universidad Autónoma Chapingo me acepte como estudiante de posgrado;
                                    además, estoy de acuerdo con los términos de la convocatoria y quedo enterado".
                                </Typography> */}
                                <Typography color='error'>
                                    ATENCIÓN: Una vez que usted CIERRE SU REGISTRO, NO podrá modificar esta información o adjuntar documentación, por lo que pedimos, se cerciore de la información vertida.
                                </Typography>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}></Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            onClick={submit}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.boton}>
                                            ACEPTAR Y CERRAR REGISTRO
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}></Grid>
                                </Grid>

                            </>
                            :
                            null
                        }

                    </div>
                </Paper >
            </>
            : <Mensaje titulo="Registro cerrado" mensaje="Su registro se ha completado con éxito." />
    )
        
}
export default CerrarRegistro