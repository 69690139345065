import React, { useState, useEffect, useRef } from 'react';
import {  Hidden, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Global } from "../../../constants/global"
import MaterialTable from 'material-table'
import axios from "axios";



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function Participantes(props) {


    const classes = useStyles();
    const [data, setData] = useState([])
    const { idprograma } = props.programa;
    // const { idunidad } = 1;
    const [open, setOpen] = useState(false)
    const [columns, setColumns] = useState([
        {
            title: "Profesor", render: (rowData) => rowData.profesor.persona.nombre + " " + rowData.profesor.persona.apellidoP + " " + rowData.profesor.persona.apellidoM
            // title: "Profesor", render: (rowData) => console.log(rowData)
        },
        // { title: "Apellido Paterno", field: "profesor.persona.apellidoP" },
        // { title: "Apellido Materno", field: "profesor.persona.apellidoM" },
        { title: "Fecha de ingreso", field: "fechain" },
        { title: "Estatus", field: "estatus" },
        { title: "Núcleo", field: "nucleo" }
    ])

    useEffect(() => {

        const datos = async () => {

            await axios.get(Global + "programaprof/programa/" + idprograma, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))

        }

        datos()

    }, [])

    const descriptionElementRef = useRef(null);

    useEffect(() => {

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

    }, [open]);

    // const handleClickOpen = (valores) => {
    //     setPrograma(valores)
    //     setOpen(true)
    // }

    return (
        <div className={classes.root}>
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de participantes
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>}
                options={{
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar"
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                    }
                }}
                columns={columns}
                data={data}

            />
        </div>

    )
}

export default Participantes