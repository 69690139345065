import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { Button, Hidden,Grid,Typography } from '@material-ui/core';
import axios from 'axios';
import { Global } from '../../../constants/global';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { fba } from "../../../firebaseConfig";
import { FORMATOFECHAMINHOY } from '../../generales/Fecha';
import { obtenerAspirantes } from '../../../servicios/obtenerAspirantes';


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
    boton: {
        fontFamily: "Poppins, Sans-serif",
        fontSize: "15px",
        fontWeight: 600,
        textTransform: "none",
        lineHeight: "18px",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 1px",
        borderRadius: "6px 6px 6px 6px",
        padding: "16px 50px 16px 50px",
        backgroundColor: "#2361b2",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#1481e8",
        },
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
});

function createData(idpersona, nombre, paterno, materno, correo, idaspirante, estatus, idposgrado, posgrado) {
    return { idpersona, nombre,paterno,materno, idaspirante, estatus, idposgrado, posgrado };
}


const TablaCita = () => {
    const classes = useStyles2();
    const rows2 = []
    const [open, setOpen] = React.useState(false);
    const [cita, setCita] = React.useState({ nombre: '' });
    const [aspirante, setAspirante] = React.useState()
    const [citaDatos, setCitaDatos] = React.useState({
        fecha: '',
        hora: '',
        lugar: ''
    });
    const [errors, setErrors] = React.useState({
        fecha: '',
        hora: '',
        lugar: ''
    })
    const [actualizar, setActualizar] = useState('')
    const [data, setData] = React.useState([]);
    const [columns] = React.useState([
        { title: 'Nombre', field: "nombre" },
        { title: 'Apellido paterno', field: "paterno" },
        { title: 'Apellido materno', field: "materno" },
        { title: 'Posgrado solicitado', field: "posgrado" },
        
    ]);

    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');

    useEffect(() => {
        //se sacan los datos del usuario logueado
        let userFB = fba.auth().currentUser;
        //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
        db.where('correo', '==', userFB.email).get().then(snapshot => {
            snapshot.docs.forEach(doc => {

                //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                if (doc.data().posgrado === 0) {
                    //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo
                    axios.get(Global + "aspirante/estatus/Registro_Aceptado", {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        response.data.map((renglon, index) => {
                            rows2[index] = createData(renglon[0], renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8])
                        })

                        setData(rows2)

                    }).catch(error => console.log(error))
                }
                else {//si es diferente de 0 entonces tiene acceso a solo un posgrado
                    //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo y programa del usuario
                    obtenerAspirantes(doc,'aspirante/estatus/Registro_Aceptado/programa/')
                    .then(programa=>{
                        let aspirantes = [];
                        for(let aspirante of programa){
                            aspirantes = aspirantes.concat(aspirante);
                        }
                        console.log('lista de aspirantes',aspirantes)
                        aspirantes.map((renglon,index)=>{
                            rows2[index] = createData(renglon[0], renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8])
                        })
                        setData(rows2)
                        
                    })
                    .catch((err)=>{console.log(err)})
                }
            });
        });
    }, [actualizar])

    const handleClick = (event) => {
        // console.log(event)
        axios.get(Global + "aspirante/" + event.idaspirante, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data) {

                setAspirante(response.data)
                setCita(event);
                setOpen(true);
            }
        }).catch(error => console.log(error));
    }


    const handleClose = () => {

        setOpen(false);
    };


    const handleSubmit = valores => {

        const validate = {
            fecha: '',
            hora: '',
            lugar: ''
        };
        if (citaDatos.fecha !== '') {
            if (citaDatos.hora !== '') {
                if (citaDatos.lugar !== '') {
                    let data = {
                        fecha: citaDatos.fecha,
                        hora: citaDatos.hora,
                        lugar: citaDatos.lugar,
                        razon: "Entrega de documentación y entrevista",
                        tipo: "documentos"
                    }
                    axios.post(Global + "cita/aspirante/" + cita.idaspirante, data, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        let cambio = aspirante;
                        cambio.estatus = "Registro_Cita"
                        cambio.activo = 0
                        setAspirante(cambio)

                        //Actualizamos el estado de aspirante
                        axios.put(Global + "aspirante/" + aspirante.idaspirante, aspirante, {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            //Aqui se haria la parte de envio de correo 
                            axios.post(Global + "email/cita?aspirante=" +
                                aspirante.persona.nombre + " " + aspirante.persona.apellidoP + " " + aspirante.persona.apellidoM
                                + "&destinatario=" + aspirante.persona.correo
                                + "&fecha=" + citaDatos.fecha + "&hora=" + citaDatos.hora
                                + "&lugar=" + citaDatos.lugar,
                                {
                                    cita: {
                                        cita: "cita"
                                    }
                                },
                                {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(response => {
                                    handleClose()
                                    
                                }).catch(error => {
                                    console.log(error);
                                })
                        }).catch(error => {
                            console.log(error);
                        })
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    validate.lugar = "Debe ingresar el lugar de la cita"
                    setErrors(validate)
                }
            } else {
                validate.hora = "Debe ingresar la hora de la cita"
                setErrors(validate)
            }
            setActualizar("ACTUALIZAR");
        } else {
            validate.fecha = "Debe ingresar la fecha para cita"
            setErrors(validate)
        }
        //REALIZAR EL AXIOS PARA EL REGISTRO O MODIFICAION DE LA HORA DE CITA

    }

    return (
        <>
        <MaterialTable
            title={
                <Hidden smDown>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                            <Typography className={classes.subtitulo}>
                            Asignación de citas
                        </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            }      
            actions={[
                {  
                    tooltip: 'Asignar',                    
                    onClick: (e,rowData) =>{
                    handleClick(rowData)}
                },

            ]}
            components={{
                Action: props => (
                <Button
                onClick={(event) => props.action.onClick(event, props.data)}
                    variant="contained"
                    color="primary"
                    className={classes.boton}>
                    Asignar
                </Button>
                ),
                }}
            options={{

                actionsColumnIndex:-1,
                actionsCellStyle:{width:"30%"},
                exportButton:true,
                exportFileName:"Asignación de citas",
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                headerStyle: {
                    backgroundColor: '#66abe0b8',
                    color: '#FFF'
                }
            }}
            localization={{
                header:{
                    actions:""
                },
                toolbar: {
                    exportCSVName: "Exportar como CSV",
                    exportPDFName: "Exportar como PDF",
                    exportTitle: "Exportar",
                    searchTooltip: 'Buscar en la tabla',
                    searchPlaceholder: "Buscar"
                },
                pagination: {
                    labelDisplayedRows: '',
                    labelRowsSelect: '',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Siguiente página',
                    lastTooltip: 'Última página',
                },
                body: {
                    emptyDataSourceMessage: 'No hay información por mostrar',
                }
            }}
            data={data}
            columns={columns}
            />
            <div>
                <Dialog open={open} onClose={handleClose} >
                    <DialogTitle>
                        <DialogContentText className={classes.subtitulo} style={{ marginBottom: -10 }}>Registrar cita</DialogContentText>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Ingrese las datos de la cita para el aspirante: {cita.nombre}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fecha"
                            label="Fecha"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={valores => { citaDatos.fecha = valores.target.value }}
                            error={errors.fecha !== '' ? (
                                true
                            ) : false}
                            helperText={errors.fecha !== '' ? (
                                errors.fecha
                            ) : null}
                            InputProps={FORMATOFECHAMINHOY}
                        />

                        <TextField
                            margin="dense"
                            id="hora"
                            label="Hora"
                            type="time"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={valores => { citaDatos.hora = valores.target.value }}
                            error={errors.hora !== '' ? (
                                true
                            ) : false}
                            helperText={errors.hora !== '' ? (
                                errors.hora
                            ) : null}
                        />
                        <TextField

                            margin="dense"
                            id="lugar"
                            label="Lugar"
                            type="text"
                            fullWidth
                            onChange={valores => { citaDatos.lugar = valores.target.value }}
                            error={errors.lugar !== '' ? (
                                true
                            ) : false}
                            helperText={errors.lugar !== '' ? (
                                errors.lugar
                            ) : null}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" className={classes.boton}>
                            Cancelar
                                        </Button>
                        <Button onClick={handleSubmit} className={classes.boton}>
                            Enviar
                                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    );
}
export default TablaCita;
