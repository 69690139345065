import { CircularProgress, Link, Breadcrumbs, Grid, Paper, Button, TextField, } from '@material-ui/core';
import React, { useEffect, useContext, useState } from 'react';
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core'
import { Auth } from "../../../context/AuthContext";
import { Formik } from "formik";
import * as Yup from "yup";
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import axios from 'axios';
import { Global } from '../../../constants/global';
import TablaAsignacion from './TablaAsignacion';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },

    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    boton2: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
        color: "#000000"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


function createData(idpersona, nombre, paterno, materno, idaspirante, idprograma, posgrado, matricula) {
    return { idpersona, paterno, materno, nombre, idaspirante, idprograma, posgrado, matricula };
}

const RegistroAlumno = ({ history }) => {
    const classes = useStyles();

    const [rows, setRows] = useState([])
    const rows2 = []
    const [open, setOpen] = React.useState(false);
    const [back, setBack] = useState(false)
    const [boton, setBoton] = useState(true)

    const { usuario } = useContext(Auth);
    useEffect(() => {
        if (usuario === null) {
            history.push("/siip");
        }
    })

    const initial = {
        inicio: '',
        termino: '',
    }

    const handleSubmit = async (event) => {
        setBack(true);
        setBoton(false)
        setOpen(false)
        let uno = new Date().getFullYear()
        let dos = new Date().getFullYear() + 3
        let anio = uno + "-" + dos

        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;

        const cargarAlumnos = () => {
            rows.map((renglon, index) => {
                console.log(renglon.nombre)
                const alumno = {
                    estatus: "VG",
                    fechain: today,
                    generacion: anio,
                    matricula: renglon.matricula,
                    programa: {
                        idprograma: renglon.idprograma
                    }
                }
                axios.post(Global + "alumno/persona/" + renglon.idpersona, alumno, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(() => {
                    let cambio = {
                        estatus: "Registro_Terminado",
                        activo: 1
                    }
                    axios.put(Global + "aspirante/" + renglon.idaspirante, cambio, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(responses => {
                    }).catch(error => console.log(error));
                }).catch(error => console.log(error));
            })
        }

        await cargarAlumnos()
        setBack(false);

    }

    const handleClose = () => {
        setOpen(!open);
    };

    const schemaValidation = Yup.object({
        inicio: Yup.number().positive('El valor debe ser un valor numerico postitivo').required('Es obligatorio ingresar la maticula de inicio').integer("El valor debe ser numerico entero"),
        termino: Yup.number().positive('El valor debe ser un valor numerico postitivo').required('Es obligatorio ingresar la maticula de termino').integer("El valor debe ser numerico entero")
            .test('match',
                'La matricula de termino no debe ser mayor a la matricula de inicio',
                function (termino) {
                    if (termino !== "")
                        return termino >= this.parent.inicio
                }),
    });

    const functionSubmit = async (valores) => {
        var { inicio, termino } = valores
        await axios.get(Global + "aspirante/estatus/Registro_Inscripcion", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            response.data.map((renglon, index) => {
                rows2[index] = createData(renglon[0], renglon[1], renglon[2], renglon[3], renglon[5], renglon[7], renglon[8], inicio)
                inicio < termino
                    ? inicio = inicio + 1
                    : inicio = 'Matricula no asignada'
            })
            setRows(rows2)
        }).catch(error => console.log(error))
    };

    return (
        <div className={classes.root}>
            <Head />
            <Paper elevation={3} className={classes.paperContainer}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link href="/inicio"
                        className={classes.link}
                    >
                        <HomeIcon className={classes.icon} />
                        Inicio
                    </Link>
                    <Link
                        aria-current="page"
                        className={classes.link}
                    >
                        <HowToRegIcon className={classes.icon} />
                        Aspirantes
                    </Link>
                    <Link
                        href="/aceptarAspirante"
                        aria-current="page"
                        className={classes.link}
                    >
                        <PersonIcon className={classes.icon} />
                        Asignacion de matricula
                    </Link>
                </Breadcrumbs>
                <br />
                {rows.length > 0
                    ? <>
                        <Backdrop className={classes.backdrop} open={back}>
                            <CircularProgress color="inherit" />
                        </Backdrop> :
                        {boton ?
                            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                                <Grid item xs={12} sm={3}>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={classes.boton}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        Asignar matriculas y dar de alta alumnos
                                    </Button>
                                </Grid>
                            </Grid>
                            : null
                        }
                        <TablaAsignacion rows={rows} total={rows.length} />
                    </>
                    :
                    <>
                        <Typography variant="h5" className={classes.subtitulo}>
                            Asignación de matrícula a los aspirantes
                        </Typography>
                        <br />
                        <Paper elevation={3} className={classes.paperContainer}>
                            <Formik
                                validationSchema={schemaValidation}
                                enableReinitialize
                                initialValues={initial}
                                onSubmit={(valores) => {
                                    functionSubmit(valores);
                                }}
                            >
                                {(props) => {
                                    return (
                                        <form noValidate onSubmit={props.handleSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="Matricula de inicio"
                                                        id="inicio"
                                                        name="inicio"
                                                        type="number"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.touched.inicio && props.errors.inicio ? true : false
                                                        }
                                                        helperText={
                                                            props.touched.inicio && props.errors.inicio
                                                                ? props.errors.inicio
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="Matricula de terminación"
                                                        id="termino"
                                                        name="termino"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.touched.termino && props.errors.termino ? true : false
                                                        }
                                                        helperText={
                                                            props.touched.termino && props.errors.termino
                                                                ? props.errors.termino
                                                                : null
                                                        }
                                                        type="number"
                                                    />
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            className={classes.boton}
                                                        >
                                                            Asignar matriculas
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </Paper>
                    </>}
            </Paper >
            <div>
                <Dialog open={open} onClose={handleClose} >
                    <DialogTitle>
                        <DialogContentText className={classes.subtitulo} align='center'>
                            ¿QUIERE ASIGNAR LAS MATRICULAS Y DAR DE ALTA LOS ALUMNOS MOSTRADOS EN LA TABLA?
                        </DialogContentText>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" className={classes.boton2} fullWidth>
                            Cancelar
                        </Button>
                        <Button onClick={handleSubmit} color="primary" className={classes.boton2} fullWidth>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Pie />

        </div >
    );

}
export default RegistroAlumno;