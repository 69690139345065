import axiosInstance from "./axiosInstance";


const post = (url, json) => {
  
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`${url}`, json)
      .then((success) => {
        resolve(success);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getReq = (url) => {
  
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${url}`)
      .then((success) => {
        resolve(success);
      })
      .catch((error) => {        
        reject(error);
      });
  });
};

const putReq = (url, json) => {
  
  return new Promise((resolve, reject) => {  
    axiosInstance
      .put(`${url}`, json)
      .then((success) => {
        resolve(success);
      })
      .catch((error) => {        
        reject(error);
      });
  });
};

const peticionesHttp = {
  post,
  getReq,
  putReq,
};

export default peticionesHttp;
