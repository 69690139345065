import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Grid,
  Breadcrumbs,
  Link,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";

import { Auth } from "../../../context/AuthContext";
import Head from "../../generales/Head";
import Footer from "../../generales/Footer";
import FormRoles from "./FormRoles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  paperContainer: {
    padding: theme.spacing(6),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),

    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(17),
    },
    [theme.breakpoints.only("sm")]: {
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.only("lg")]: {
      marginBottom: theme.spacing(6),
    },
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function Roles({ history }) {
  const [stateCheckbox, setStateCheckbox] = useState(false);
  const { usuario } = useContext(Auth);

  //**********************Para evitar que un usuario no autenticado vea la UI de Roles******************************* */
  useEffect(() => {
    if (usuario === null) {
      history.push("/siip");
    }
  });
  //***************************************************** */

  const handleChangeCheckBox = (event) => {
    setStateCheckbox(event.target.checked);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Head />
      <Paper elevation={3} className={classes.paperContainer}>
        <Grid container justify="space-between">
          <Breadcrumbs aria-label="breadcrumb">
            <Link href="/inicio" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Inicio
            </Link>

            <Link
              color="textPrimary"
              href="/siip/roles"
              aria-current="page"
              className={classes.link}
            >
              <PeopleIcon className={classes.icon} />
              Roles
            </Link>
          </Breadcrumbs>
          <FormControlLabel
            control={
              <Checkbox
                checked={stateCheckbox}
                onChange={handleChangeCheckBox}
                name="stateCheckbox"
              />
            }
            label="Mostrar tabla de permisos"
          />
        </Grid>

        <FormRoles values={stateCheckbox} />
      </Paper>
      <Footer />
    </div>
  );
}
