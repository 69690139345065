import React, { useEffect, useState } from 'react'
import { FormControl, CircularProgress, FormGroup, FormControlLabel, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Backdrop from '@material-ui/core/Backdrop';
import Mensaje from '../Mensaje'
import Checkbox from '@material-ui/core/Checkbox';
import alerta from "../../administrador/Cuenta/PlantillaAlerta";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "10%",
        marginRight: "10%",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
}));


function RegistroOtorgantes() {

    const [values, setValues] = useState({
        nombreAsp: '',
        posgrado: '',
        anios: '',
        otro: '',
        ocupacion: '',
        nroEstudiantes: '',
        porcentaje: '',
        campoEstudio: '5',
        iniciativa: '5',
        motivacion: '5',
        responsabilidad: '5',
        liderazgo: '5',
        bajoPresion: '5',
        trabajoEquipo: '5',
        oral: '5',
        escrita: '5',
        flexibilidad: '5',
        intelectual: '5',
        nombre: '',
        grado: '',
        institucion: '',
        celular: '',
        comentarios: '',
        prin_deb: '',
    })
    const [ocupacion, setOcupacion] = useState({
        estudiante: false,
        tesista: false,
        profesionista: false,
        academico: false
    })
    const [isLoading, setIsLoading] = useState(false)
    const [mensaje, setMensaje] = useState(false);
    const [change, setChange] = useState(false);
    const [fecha, setFecha] = useState(false);
    const [formularioEnviado,setFormularioEnviado] = useState(false);
    const valores = [5, 6, 7, 8, 9, 10, "Sin conocimiento"]
    const params = new URL(window.location.href);

    useEffect(() => {
        const datos = async () => {
            setIsLoading(true);
            // alert(params.searchParams.get('otorgante').split("?")[1])
            let aspirante = params.searchParams.get('otorgante').split("?")[1]
            let carta = params.searchParams.get('otorgante').split("?")[2]


            await axios.get(Global + "cartad/carta/" + carta.split("=")[1], {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if (response.data.length > 0) {
                    setMensaje(true)
                }
            }).catch(error => console.log(error));

            await axios.get(Global + "persona/correo/" + aspirante.split("=")[1], {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(async response => {
                if (response) {
                    await axios.get(Global + "estudiosrea/persona/" + response.data[0].idP, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        values.nombreAsp = response.data[0].persona.nombre;
                        values.posgrado = response.data[0].programa.programa;
                    }).catch(error => console.log(error));

                    await axios.get(Global + "carta/persona/" + response.data[0].idP + "/correo/" + params.searchParams.get('otorgante').split("?")[0], {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                        }
                    }).then(response => {
                        values.nombre = response.data[0].orotgante;
                        values.grado = response.data[0].estudio;
                        values.ocupacion = response.data[0].ocupacion;
                        values.institucion = response.data[0].institucion;
                        values.celular = response.data[0].celular;

                    }).catch(error => console.log(error));
                }


                setIsLoading(false);
            }).catch(error => console.log(error));
        }

        axios.get(Global + "convocatoira/vigente", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.data.length < 1) {
                setFecha(true);
            } else {
                datos();
            }
        }).catch(error => console.log(error))

        handleCargaClose();

    }, [change])


    const classes = useStyles();

    //Schema de validacion
    const schemaValidacion = Yup.object({
        anios: Yup.string().required('Es obligatorio ingresar los años del otorgante').matches(/^[1-9][0-9]*$/, 'Números mayores que 0'),
        nroEstudiantes: Yup.string().required('Es obligatorio ingresar el número de estudiantes.').matches(/^[1-9][0-9]*$/, 'Números mayores que 0'),
        porcentaje: Yup.string().required('Es obligatorio ingresar el instituto del otorgante').matches(/^[1-9][0-9]*$/, 'Números mayores que 0'),
        nombre: Yup.string().required('Es obligatorio ingresar el nombre del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        grado: Yup.string().required('Es obligatorio seleccionar el grado del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        ocupacion: Yup.string().required('Es obligatorio ingresar la ocupación del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        //celular: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        institucion: Yup.string().required('Es obligatorio ingresar el instituto del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        comentarios: Yup.string().max(254,"El texto debe ser menor a 255 caracteres"),
        prin_deb: Yup.string().max(254,"El texto debe ser menor a 255 caracteres"),

        // comentarios: Yup.string().required('Es obligatorio ingresar el instituto del otorgante').matches(/^[A-Za-z0-9\s]+$/, 'Sin carácteres extraños.')

    })

    const handleCargaClose = () => {
        setChange(false);
    };

    const handleChange = (event) => {
        setOcupacion({ ...ocupacion, [event.target.name]: event.target.checked });
    };

    const initial = values

    const funcionSubmit = async (valores) => {

        setFormularioEnviado(true);
        const otorgante = {
            cantidad: valores.nroEstudiantes,
            comunes: valores.escrita,
            comunor: valores.oral,
            conocimiento: valores.campoEstudio,
            flexibilidad: valores.flexibilidad,
            iniciativa: valores.iniciativa,
            intelectual: valores.intelectual,
            liderazgo: valores.liderazgo,
            motivacion: valores.motivacion,
            nivel: "" + ocupacion.estudiante + "," + ocupacion.tesista + "," + ocupacion.profesionista + "," + ocupacion.academico + "," + valores.otro,
            nombre: valores.nombreAsp,
            porciento: valores.porcentaje,
            programa: valores.posgrado,
            responsabilidad: valores.responsabilidad,
            tiempo: valores.anios,
            trabajobp: valores.bajoPresion,
            trabajoeq: valores.trabajoEquipo
        }

        const carta = {
            celular: valores.celular,
            estatus: "Completado",
            estudio: valores.grado,
            fortalezadeb: valores.comentarios,
            debilidadesprin: valores.prin_deb,
            institucion: valores.institucion,
            ocupacion: valores.ocupacion,
            orotgante: valores.nombre,

        }
        let idcarta = params.searchParams.get('otorgante').split("?")[2]
        await axios.post(Global + "cartad/carta/" + idcarta.split("=")[1], otorgante, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(response => {

        }).catch(error => setFormularioEnviado(false))

        await axios.put(Global + "carta/" + idcarta.split("=")[1], carta, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(() => {
            
            alerta("Datos Registrados");
            setMensaje(true);

        }).catch(error => setFormularioEnviado(false))

    }

    const { estudiante, tesista, profesionista, academico } = ocupacion;

    return (
        fecha !== false ?
            <Mensaje titulo="Registro de aspirantes
            " mensaje="El tiempo para el registro de aspirantes ha terminado." />
            :
            isLoading ?
                <div>
                    <Backdrop className={classes.backdrop} open={change} onClick={handleCargaClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                :
                <div>
                    {
                        mensaje ?
                            <Mensaje titulo="Carta de recomendación" mensaje="La carta ha sido guardada con éxito. Gracias por su tiempo." />
                            : <Paper elevation={3} className={classes.paperContainer}>
                                <Typography variant="h5">
                                    Posgrado Chapingo
                                </Typography>
                                <br />

                                <Formik
                                    validationSchema={schemaValidacion}
                                    enableReinitialize
                                    initialValues={initial}
                                    onSubmit={(valores) => {
                                        funcionSubmit(valores)
                                    }}
                                >
                                    {props => {
                                        return (
                                            <form noValidate onSubmit={props.handleSubmit}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Nombre del aspirante"
                                                            name="nombreAsp"
                                                            id="nombreAsp"
                                                            disabled
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            value={props.values.nombreAsp}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Programa de posgrado"
                                                            name="posgrado"
                                                            id="posgrado"
                                                            variant="outlined"
                                                            disabled
                                                            fullWidth
                                                            value={props.values.posgrado}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.posgrado && props.errors.posgrado ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.posgrado && props.errors.posgrado ? (
                                                                props.errors.posgrado
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid>


                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="body1">
                                                            Conozco al aspirante desde hace
                                                        </Typography>
                                                        <br />

                                                        <TextField
                                                            label="Años de conocido"
                                                            name="anios"
                                                            variant="outlined"
                                                            type='number'
                                                            required
                                                            fullWidth
                                                            value={props.values.anios}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.anios && props.errors.anios ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.anios && props.errors.anios ? (
                                                                props.errors.anios
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}></Grid>


                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="body1">
                                                            como (puede marcar varias opciones):
                                                        </Typography>
                                                        <FormControl component="fieldset" className={classes.formControl}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={estudiante}
                                                                            onChange={handleChange}
                                                                            name="estudiante"
                                                                            value="estudiante" />}
                                                                    label="Estudiante"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={tesista} onChange={handleChange} name="tesista" />}
                                                                    value="tesista"
                                                                    label="Tesista"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={profesionista} onChange={handleChange} name="profesionista" />}
                                                                    value="profesionista"
                                                                    label="Profesionista"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox checked={academico} onChange={handleChange} name="academico" />}
                                                                    value="academico"
                                                                    label="Acádemico"
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            label="Otro"
                                                            name="otro"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={props.values.otro}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.otro && props.errors.otro ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.otro && props.errors.otro ? (
                                                                props.errors.otro
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid>


                                                    <Grid item xs={12} >
                                                        <Typography variant="body1">
                                                            De entre aproximadamente:
                                                        </Typography>
                                                        <TextField
                                                            label="Número de estudiantes"
                                                            name="nroEstudiantes"
                                                            variant="outlined"
                                                            required
                                                            type="number"
                                                            fullWidth
                                                            value={props.values.nroEstudiantes}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.nroEstudiantes && props.errors.nroEstudiantes ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.nroEstudiantes && props.errors.nroEstudiantes ? (
                                                                props.errors.nroEstudiantes
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12} >
                                                        <Typography variant="body1">
                                                            considero que este aspirante esta en
                                                        </Typography>
                                                        <TextField
                                                            label="Porcentaje"
                                                            name="porcentaje"
                                                            variant="outlined"
                                                            type='number'
                                                            required
                                                            fullWidth
                                                            value={props.values.porcentaje}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.porcentaje && props.errors.porcentaje ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.porcentaje && props.errors.porcentaje ? (
                                                                props.errors.porcentaje
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                        <Typography variant="body1">
                                                            más alto.
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                                <br />
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">
                                                        Asignar una calificación entre 5 y 10
                                                        (10 es el rango más alto) por cada uno
                                                        de los atributos  que a su juicio posee
                                                        el aspirante. Si considera que no tiene
                                                        los elementos suficientes para valorar
                                                        un atributo, califique con SC(Sin conocimiento)
                                                    </Typography>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">
                                                        Habilidades del aspirante
                                                    </Typography>
                                                </Grid>
                                                <br />
                                                <Grid
                                                    container
                                                    spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.campoEstudio && props.errors.campoEstudio ? (true) : false}>
                                                            <InputLabel>Conocimiento del campo de estudio</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.campoEstudio}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Conocimiento del campo de estudio"
                                                                inputProps={{
                                                                    name: 'campoEstudio',
                                                                    id: 'campoEstudio',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.campoEstudio && props.errors.campoEstudio ? (props.errors.campoEstudio) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.iniciativa && props.errors.iniciativa ? (true) : false}>
                                                            <InputLabel>Iniciativa</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.iniciativa}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Iniciativa"
                                                                inputProps={{
                                                                    name: 'iniciativa',
                                                                    id: 'iniciativa',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.iniciativa && props.errors.iniciativa ? (props.errors.iniciativa) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.motivacion && props.errors.motivacion ? (true) : false}>
                                                            <InputLabel>Motivación(claridad en sus metas)</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.motivacion}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Motivación(claridad en sus metas)"
                                                                inputProps={{
                                                                    name: 'motivacion',
                                                                    id: 'motivacion',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.motivacion && props.errors.motivacion ? (props.errors.motivacion) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.responsabilidad && props.errors.responsabilidad ? (true) : false}>
                                                            <InputLabel>Responsabilidad</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.responsabilidad}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Responsabilidad"
                                                                inputProps={{
                                                                    name: 'responsabilidad',
                                                                    id: 'responsabilidad',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.responsabilidad && props.errors.responsabilidad ? (props.errors.responsabilidad) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.liderazgo && props.errors.liderazgo ? (true) : false}>
                                                            <InputLabel>Capacidad de liderazgo</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.liderazgo}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Capacidad de liderazgo"
                                                                inputProps={{
                                                                    name: 'liderazgo',
                                                                    id: 'liderazgo',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.liderazgo && props.errors.liderazgo ? (props.errors.liderazgo) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.bajoPresion && props.errors.bajoPresion ? (true) : false}>
                                                            <InputLabel>Habilidad de trabajo bajo presión</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.bajoPresion}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Habilidad de trabajo bajo presión"
                                                                inputProps={{
                                                                    name: 'bajoPresion',
                                                                    id: 'bajoPresion',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.bajoPresion && props.errors.bajoPresion ? (props.errors.bajoPresion) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.trabajoEquipo && props.errors.trabajoEquipo ? (true) : false}>
                                                            <InputLabel>Facilidad para trabajo en equipo</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.trabajoEquipo}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Facilidad para trabajo en equipo"
                                                                inputProps={{
                                                                    name: 'trabajoEquipo',
                                                                    id: 'trabajoEquipo',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.trabajoEquipo && props.errors.trabajoEquipo ? (props.errors.trabajoEquipo) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.oral && props.errors.oral ? (true) : false}>
                                                            <InputLabel>Habilidad de comunicación oral</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.oral}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Habilidad de comunicación oral"
                                                                inputProps={{
                                                                    name: 'oral',
                                                                    id: 'oral',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.oral && props.errors.oral ? (props.errors.oral) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.escrita && props.errors.escrita ? (true) : false}>
                                                            <InputLabel>Habilidad de comunicación escrita</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.escrita}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Habilidad de comunicación escrita"
                                                                inputProps={{
                                                                    name: 'escrita',
                                                                    id: 'escrita',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.escrita && props.errors.escrita ? (props.errors.escrita) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.flexibilidad && props.errors.flexibilidad ? (true) : false}>
                                                            <InputLabel>Flexibilidad para adaptarse a otros ambientes</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.flexibilidad}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Flexibilidad para adaptarse a otros ambientes"
                                                                inputProps={{
                                                                    name: 'flexibilidad',
                                                                    id: 'flexibilidad',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.flexibilidad && props.errors.flexibilidad ? (props.errors.flexibilidad) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.intelectual && props.errors.intelectual ? (true) : false}

                                                        >
                                                            <InputLabel>Habilidad Intelectual</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.intelectual}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Habilidad Intelectual"
                                                                inputProps={{
                                                                    name: 'intelectual',
                                                                    id: 'intelectual',
                                                                }}>
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    valores.map(indice => {
                                                                        return <option key={indice} value={indice}>{indice}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.intelectual && props.errors.intelectual ? (props.errors.intelectual) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <br />
                                                    <br />
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">
                                                            Información de la persona
                                                            que otorgó esta carta de recomendación
                                                        </Typography>
                                                    </Grid>
                                                    <br />
                                                    <br />


                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Nombre"
                                                            name="nombre"
                                                            id="nombre"
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            value={props.values.nombre}
                                                            onChange={(e) => {
                                                                let nomb = e.target.value;
                                                                props.setFieldValue("nombre", nomb.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.nombre && props.errors.nombre ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.nombre && props.errors.nombre ? (
                                                                props.errors.nombre
                                                            ) : null}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        >
                                                        </TextField>
                                                    </Grid>


                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.grado && props.errors.grado ? (true) : false}

                                                        >

                                                            <InputLabel htmlFor="grado">Grado máximo de estudios</InputLabel>
                                                            <Select
                                                                native
                                                                fullWidth
                                                                variant="outlined"
                                                                value={props.values.grado}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}

                                                                label="Grado máximo de estudios"
                                                                inputProps={{
                                                                    name: 'grado',
                                                                    id: 'grado',
                                                                }}
                                                            >
                                                                <option aria-label="Seleccione" value="" />
                                                                <option value={'Maestria'}>Maestria</option>
                                                                <option value={'Doctorado'}>Doctorado</option>
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.grado && props.errors.grado ? (props.errors.grado) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Ocupación"
                                                            name="ocupacion"
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            value={props.values.ocupacion}
                                                            onChange={(e) => {
                                                                let ocup = e.target.value;
                                                                props.setFieldValue("ocupacion", ocup.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.ocupacion && props.errors.ocupacion ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.ocupacion && props.errors.ocupacion ? (
                                                                props.errors.ocupacion
                                                            ) : null}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}

                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Institución donde labora"
                                                            name="institucion"
                                                            id="institucion"
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            value={props.values.institucion}
                                                            onChange={(e) => {
                                                                let ins = e.target.value;
                                                                props.setFieldValue("institucion", ins.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.institucion && props.errors.institucion ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.institucion && props.errors.institucion ? (
                                                                props.errors.institucion
                                                            ) : null}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                   

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Principales Fortalezas (Menor a 255 caracteres)"
                                                            name="comentarios"
                                                            id="comentarios"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={props.values.comentarios}
                                                            onChange={(e) => {
                                                                let comen = e.target.value;
                                                                props.setFieldValue("comentarios", comen.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.comentarios && props.errors.comentarios ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.comentarios && props.errors.comentarios ? (
                                                                props.errors.comentarios
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                    <br />
                                                    <Grid item xs={12}>
                                                    <TextField
                                                        label="Principales Debilidades (Menor a 255 caracteres)"
                                                        name="celular"
                                                        id="celular"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={props.values.celular}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.celular && props.errors.celular ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.celular && props.errors.celular ? (
                                                            props.errors.celular
                                                        ) : null}

                                                    >
                                                    </TextField>
                                                </Grid>

                                                    

                                                </Grid>
                                                <br />
                                                <br />
                                                <Grid container>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            className={classes.boton}
                                                            disabled={formularioEnviado}                                                         
                                                            >
                                                            Guardar
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </Paper >
                    }
                </div >
    );
}

export default RegistroOtorgantes;