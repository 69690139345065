import React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';
import logo from '../../img/logo.png'
import logoposgrado from '../../img/logoposgrado.jpg';
import theme from '../../styles/globalStyles'


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    logo: {
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        color: '#fffc'
    },
    divider: {
        color: 'white',
        backgroundColor: 'rgb(255 255 255 / 80%)',
        position: 'fixed'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));


export default function PrimarySearchAppBar({ history }) {
    const classes = useStyles();

    return (
        <>
            <ThemeProvider theme={theme}>
                <AppBar position="fixed" color='primary'>
                    <Toolbar>
                        <img src={logo} alt='Ejemplo' className={classes.logo} style={{ height: '53px', width: '200px' }} />
                        {/* <MenuIcon /> */}
                        {/* <img src={logoposgrado} alt='Ejemplo' className={classes.logoposgrado} style={{ height: '53px', width: '200px' }} />
                         */}
                        <div className={classes.grow} />

                        <div>
                           {/*  <Typography className={classes.title} variant="h6" >
                                POSGRADO CHAPINGO logo
                            </Typography> */}

                            
                        <img src={logoposgrado} alt='Ejemplo' className={classes.logoposgrado} style={{ height: '53px', width: '200px' }} />
                        <div className={classes.grow} />
                        </div>

                    </Toolbar>
                </AppBar>
                <div className={classes.offset}></div>
            </ThemeProvider>
        </>
    );
}
