import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Grid, Hidden } from "@material-ui/core";
import axios from "axios";
import { Global } from "../../../constants/global";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DatosPosgrado from "./Posgrado";
import EstudiosRealizados from "./EstudiosRealizados";
import CartaRecomendacion from "./CartaRecomendacion";
import Experiencia from "./Experiencia";
import Ocupacion from "./Ocupacion";
import LugarNacimiento from "./LugarNacimiento";
import Domicilio from "./Domicilio";
import Contacto from "./ContactoEmergencia";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { fba } from "../../../firebaseConfig";
import MaterialTable from "material-table";
import { obtenerAspirantes } from "../../../servicios/obtenerAspirantes";
import peticionesHttp from "../../../servicios/peticionesHTTP";

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
  subtitulo: {
    fontSize: "25px",
    color: "#54595F",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function createData(
  id,
  idpersona,
  nombre,
  idaspirante,
  estatus,
  idposgrado,
  posgrado
) {
  return { id, idpersona, nombre, idaspirante, estatus, idposgrado, posgrado };
}

export default function TableAspirantes() {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [aceptar, setAceptar] = React.useState(false);
  const [aspirante, setAspirante] = React.useState("false");
  const [persona, setPersona] = React.useState("");
  const [disableBtnDocumentation,setDisableBtnDocumentation] = React.useState(false);
  const [disableBtnRol,setDisableBtnRol] = React.useState(false);
  const rows2 = [];
  const [data, setData] = React.useState([]);
  const [columns] = React.useState([
    { title: "Aspirante", field: "nombre" },
    { title: "Estatus", field: "estatus" },
    { title: "Posgrado", field: "posgrado" },
  ]);
  const firestore = fba.firestore();
  // firestore.settings({ timestampsInSnapshots: true })
  const db = firestore.collection("usuarios");  
  useEffect(() => {
    let userFB = fba.auth().currentUser;
    //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
    db.where("correo", "==", userFB.email)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos
          if (doc.data().posgrado === 0) {
            //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
      
            peticionesHttp.getReq("aspirante/lista")
            .then(resp=>{
              console.log('aceptar aspirante',resp.data)
              resp.data.forEach((renglon,index)=>{                
                rows2[index] = createData(
                  index + 1,
                  renglon[0],
                  `${renglon[1]} ${renglon[2]} ${renglon[3]}`,
                  renglon[6],
                  renglon[4],
                  renglon[8],
                    renglon[25]
                    );                    
                  })
                  
                let listaAspirantes = [...rows2]
                listaAspirantes = listaAspirantes.filter(estatus=>estatus.estatus!=="Registro" && estatus.estatus!=="Registro_Terminado")
                
                setData(listaAspirantes)
            })
            .catch(err=>{console.log(err)})

          } else {
            //si es diferente de 0 entonces tiene acceso a solo un posgrado
            //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo y programa del usuario

            obtenerAspirantes(
              doc,
              "aspirante/programas/"
            )
              .then((programa) => {
                let aspirantes = [];
                for (let aspirante of programa) {
                  aspirantes = aspirantes.concat(aspirante);
                }
                aspirantes.forEach((renglon, index) => {
                  
                  rows2[index] = createData(
                    index + 1,
                    renglon[0],
                    `${renglon[1]} ${renglon[2]} ${renglon[3]}`,
                    renglon[6],
                    renglon[4],
                    renglon[8],
                      renglon[25]
                      );                
                });
                let listaAspirantes = [...rows2]
                listaAspirantes = listaAspirantes.filter(estatus=>estatus.estatus!== "Registro" && estatus.estatus!=="Registro_Terminado")
                
                setData(listaAspirantes)
                
              })
              .catch((err) => {
                console.log(err);
              });
             
          }
        });
      });
      
  }, [aceptar]);

  
  const handleClickOpen = (valores) => {
    setPersona(valores.idpersona);
    setAspirante(valores.nombre);
    setOpen(true);    
    setDisableBtnDocumentation(valores.estatus === "Registro_Cerrado"?false:true);
   
    
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    // setOpen(false)
    setOpen2(false);
  };

  const handleDialog = () => {
    setOpen2(true);
  };

  const handleChangeStatus = () => {
    console.log('persona',persona)
    axios
      .get(Global + "aspirante/personas/" + persona, {
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        let estatus = {
          estatus: "Registro_Aceptado",
          activo: 0,
        };
        if (response.data) {
          axios
            .put(
              Global + "aspirante/" + response.data[0].idaspirante,
              estatus,
              {
                headers: {
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response) {
                setOpen2(false);
                setOpen(false);
                setAceptar(!aceptar);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  };
  
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {aspirante}
            </Typography>
            
            <Button
              variant="contained"
              autoFocus
              className={classes.boton}
              //onClick={handleDialog}
              color="primary"
              disabled={disableBtnDocumentation}
              
            >
              Aceptar documentación
            </Button>
          </Toolbar>
        </AppBar>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Datos generales</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <LugarNacimiento persona={persona} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Domicilio persona={persona} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Contacto persona={persona} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Estudios realizados
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <EstudiosRealizados persona={persona} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Estudios que desea realizar
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <DatosPosgrado persona={persona} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Experiencia profesional
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container ustify="center" spacing={1}>
              <Grid item xs={12}>
                <Experiencia persona={persona} />
              </Grid>
              <Grid item xs={12}>
                <Ocupacion persona={persona} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Carta de recomendación
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <CartaRecomendacion persona={persona} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Dialog>
      <MaterialTable
        title={
          <Hidden smDown>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography className={classes.subtitulo}>
                  Aprobación de documentación del aspirante
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        }
        actions={[
          {
            tooltip: "Ver información",

            onClick: (e, rowData) => handleClickOpen(rowData),
          },
        ]}
        components={{
          Action: (props) => (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              variant="contained"
              color="primary"
              className={classes.boton}
            >
              Ver información
            </Button>
          ),
        }}
        options={{
          actionsColumnIndex: -1,
          actionsCellStyle: { width: "30%" },
          exportButton: true,
          exportFileName: "Aprobación de documentación del aspirante",
          search: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100, 200],
          headerStyle: {
            backgroundColor: "#66abe0b8",
            color: "#FFF",
          },
        }}
        localization={{
          header: {
            actions: "",
          },
          toolbar: {
            exportCSVName: "Exportar como CSV",
            exportPDFName: "Exportar como PDF",
            exportTitle: "Exportar",
            searchTooltip: "Buscar en la tabla",
            searchPlaceholder: "Buscar",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "filas",
            firstTooltip: "Primera página",
            previousTooltip: "Página anterior",
            nextTooltip: "Siguiente página",
            lastTooltip: "Última página",
          },
          body: {
            emptyDataSourceMessage: "No hay información por mostrar",
          },
        }}
        data={data}
        columns={columns}
      />

      <Dialog
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verificación de acción"}
        </DialogTitle>
        <DialogContent align="center" spacing={2}>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro de que desea aceptar al aspirante seleccionado?
          </DialogContentText>
        </DialogContent>
        <DialogActions align="center">
          <Button
            onClick={handleClose2}
            color="primary"
            variant="contained"
            className={classes.boton}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleChangeStatus}
            color="primary"
            autoFocus
            variant="contained"
            align="center"
            className={classes.boton}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
