import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../../constants/global';
import Swal from 'sweetalert'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#b1cde3',
        marginTop: '2%',
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: '5%',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function AgregarCalificacion(props) {


    // console.log(props.data)

    const [alumnos, setAlumnos] = useState([]);


    const { idcurso } = props.data;
    // const { idplan } = props.programa;
    const classes = useStyles();
    const [data, setData] = useState([]);


    const [columns, setColumns] = useState([
        { title: 'Alumno', render: (rowData) => rowData.alumno.persona.nombre + " " + rowData.alumno.persona.apellidoP + " " + rowData.alumno.persona.apellidoM, editable: 'never' },
        { title: 'Matricula', field: 'alumno.matricula', editable: 'never' },
        {
            title: 'Calificacion', field: 'calificacion',
        }
    ]);


    useEffect(() => {

        const datosIniciales = async () => {
            //Remplazar esta consulta por una de estado tambien 
            await axios.get(Global + "inscripcion/curso/" + idcurso, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setData(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "alumno/curso/" + idcurso, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setAlumnos(response.data)
            }).catch(error => console.log(error))
        }

        datosIniciales();

    }, [])

    return (
        <div>
            <Paper className={classes.root} elevation={3}>
                <MaterialTable
                    title={
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Listado de calificaciones
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de calificaciones",
                        search: false,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea eliminar este programa de la unidad academica?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones'
                        }

                    }}
                    columns={columns}
                    data={data}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    let inscripcion = {
                                        calificacion: newData.calificacion
                                    }
                                    await axios.put(Global + "inscripcion/" + newData.idins, inscripcion, {
                                        headers: {
                                            'Access-Control-Allow-Origin': "*",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then(() => {
                                        Swal({
                                            title: 'Calificación actualizada',
                                            text: ' ',
                                            icon: 'success',
                                            button: false,
                                            timer: 1000
                                        })
                                    }).catch(error => console.log(error))

                                    dataUpdate[index] = newData;
                                    setData([...dataUpdate]);

                                    resolve();
                                }, 1000)
                            }),
                    }}
                />
            </Paper>
        </div >
    )
}

export default AgregarCalificacion;