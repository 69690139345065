import React, { useEffect } from 'react'
import { fba } from "../../../firebaseConfig";
import { Global } from '../../../constants/global';
import { Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios';
import { obtenerAlumnos } from '../../../servicios/obtenerAlumnos';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaCita(props) {

    const classes = useStyles();
    const { useState } = React;
    const [detalles, setDetalles] = useState('');

    const [columns, setColumns] = useState([
        {
            title: 'Nombre', field: 'persona.nombre'
        },
        {
            title: 'Paterno', field: 'persona.apellidoP'
        },
        {
            title: 'Materno', field: 'persona.apellidoM'
        },
        {
            title: 'Programa', field: 'programa.programa'
        },
        {
            title: 'Linea', field: 'linea_investigacion.nombre'
        },
    ]);

    const [data, setData] = useState([]);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');

    useEffect(() => {
        let userFB = fba.auth().currentUser;
        //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
        db.where('correo', '==', userFB.email).get().then(snapshot => {
            snapshot.docs.forEach(doc => {
                //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                if (doc.data().posgrado === 0) {
                    //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
                    axios.get(Global + "tesis/alumno/", {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setData(response.data)
                    }).catch(error => console.log(error))
                } else {
                    obtenerAlumnos(doc,'alumno/programa')
                        .then(programa=>{                            
                            let alumnos = [];
                            for(let alumno of programa){
                                alumnos = alumnos.concat(alumno);
                            }
                            setData(alumnos);
                        })
                        .catch((err)=>{console.log(err)})
                }
            });
        });
    }, [])  
return(
    <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de Alumnos para cita de examen
                        </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar"
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',

                    }
                }}
                columns={columns}
                data={data}
            />
    )   
 }
 export default TablaCita;