import React, { useEffect, useState } from 'react'
import { Avatar, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global, Local } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RegistroOtorgante from './RegistroOtorgante';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },
}));

function createData(observaciones, nombre, correo, id, emitida) {
    return { observaciones, nombre, correo, id, emitida };
}



function RegistroCartas() {
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);

    const classes = useStyles();
    const [values, setValues] = useState({
        nombre: '',
        correo: '',
        grado: '',
        ocupacion: '',
        institucion: '',
        celular: '',
    })
    const [user, setUser] = useState('');
    const [change, setChange] = useState(false);
    const [infoTabla, setInfoTabla] = useState([]);
    const [usuarioInfo, setUsuario] = useState('')
    // const [rows, setRows] = useState([])
    const rows = [];



    useEffect(() => {

        let userFB = fba.auth().currentUser;
        let user = '';
        let datos = async () => {
            await axios.get(Global + "persona/correo/" + userFB.email, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                user = response.data[0];
                setUser(user.idP);
                setUsuario(user);
            }).catch(error => console.log(error));


            let info = '';
            await axios.get(Global + "carta/persona/" + user.idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(async response => {
                info = response.data
                if (response.data.length > 0) {
                    info.map((registro, index) => {
                        rows[index] = createData(registro.fortalezadeb, registro.orotgante, registro.correo, index + 1, registro.estatus)

                    });
                    setInfoTabla(rows)
                    // info.map(async (registro, index) => {
                    //     await axios.get(Global + "cartad/carta/" + registro.idcarta, {
                    //         headers: {
                    //             'Access-Control-Allow-Origin': "http://localhost:3000",
                    //             'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    //             'Content-Type': 'application/json',
                    //             // Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    //         }
                    //     }).then(response => {
                    //         if (response.data.length > 0) {
                    //             // console.log(response.data)
                    //             info[index].celular = response.data[0].fortalezadeb
                    //             info[index].institucion = response.data[0].status
                    //         }
                    //     }).catch(error => console.log(error));
                }
            }).catch(error => console.log(error));

        }

        datos();
    }, [change])


    //Schema de validacion
    const schemaValidacion = Yup.object({
        nombre: Yup.string().required('Es obligatorio ingresar el nombre del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        correo: Yup.string().required('Es obligatorio ingresar el correo del otorgante').email('Ingrese un correo valido'),
        grado: Yup.string().required('Es obligatorio seleccionar el grado del otorgante'),
        ocupacion: Yup.string().required('Es obligatorio ingresar la ocupación del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        institucion: Yup.string().required('Es obligatorio ingresar el institución del otorgante').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        celular: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números.")
    })

    const handleChangeInfo = (valor) => {
        setChange(valor);
    }

    const initial = values

    const funcionSubmit = async (valores) => {
        const otorgante = {
            celular: valores.celular,
            correo: valores.correo,
            estatus: "En espera",
            estudio: valores.grado,
            fortalezadeb: "",
            institucion: valores.institucion,
            ocupacion: valores.ocupacion,
            orotgante: valores.nombre,
        }


        // await axios.get(Global + "carta/correo/" + valores.correo, {
        await axios.get(Global + "carta/persona/" + user + "/correo/" + valores.correo, {
            celular: {
                ejemplo: ""
            }
        }, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data.length > 0) {
                Swal({
                    title: 'Este correo ya ha sido registrado.',
                    text: ' ',
                    icon: 'error',
                    button: false,
                    timer: 1000
                })
            } else {
                axios.post(Global + "carta/persona/" + user, otorgante, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    let payload = {
                        url: Local + '/cartaAceptacion?otorgante=' + valores.correo + "?aspirante=" + usuarioInfo.correo + "?carta=" + response.data.idcarta
                    }

                    setChange(change + 1)
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    axios.post(`${Global}email?aspirante=${usuarioInfo.nombre} ${usuarioInfo.apellidoP} ${usuarioInfo.apellidoM}&destinatario=${valores.correo}&url=${payload.url}`,
                        {
                            persona: {
                                persona: "string",
                            },
                        }, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        <RegistroOtorgante registro={handleChangeInfo} />
                    }).catch(error => console.log(error))

                }).catch(error => console.log(error))
            }
        }).catch(error => console.log(error))



    }

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <FeaturedPlayListIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Cartas de recomendación
                        </Typography>
                    </Grid>
                </Grid>
                <br />

                {/* <Tooltip title="Valide el estatus de sus cartas de recomendación,
                        Indique los datos de las personas que expedirán las cartas de recomendación." aria-label="add">
                    <HelpOutlineIcon className={classes.avatar} />
                </Tooltip> */}
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit"> Valide el estatus de sus cartas e
                        indique los datos de las personas que expedirán las cartas de recomendación.</Typography>
                        </React.Fragment>
                        }
                    >
                    <Fab size="small" color="#f48124" className={classes.margin} >
                        <HelpOutlineIcon />
                    </Fab>
                </HtmlTooltip>
                <br />
                <br />
                <Formik
                    validationSchema={schemaValidacion}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores, { resetForm }) => {
                        funcionSubmit(valores)
                        resetForm();
                    }}>
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Nombre del otorgante"
                                            name="nombre"
                                            id="nombre"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={props.values.nombre}
                                            onChange={(e) => {
                                                let nom = e.target.value;
                                                props.setFieldValue("nombre", nom.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.nombre && props.errors.nombre ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.nombre && props.errors.nombre ? (
                                                props.errors.nombre
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Correo electrónico"
                                            name="correo"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={props.values.correo}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.correo && props.errors.correo ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.correo && props.errors.correo ? (
                                                props.errors.correo
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={props.touched.grado && props.errors.grado ? (true) : false}>
                                            <InputLabel>Grado máximo de estudios</InputLabel>
                                            <Select
                                                native
                                                value={props.values.grado}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Grado máximo de estudios"
                                                inputProps={{
                                                    name: 'grado',
                                                    id: 'grado',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'Maestria'}>Maestria</option>
                                                <option value={'Doctorado'}>Doctorado</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.grado && props.errors.grado ? (props.errors.grado) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Ocupación"
                                            name="ocupacion"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={props.values.ocupacion}
                                            onChange={(e) => {
                                                let ocup = e.target.value;
                                                props.setFieldValue("ocupacion", ocup.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.ocupacion && props.errors.ocupacion ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.ocupacion && props.errors.ocupacion ? (
                                                props.errors.ocupacion
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Institución donde labora"
                                            name="institucion"
                                            id="institucion"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={props.values.institucion}
                                            onChange={(e) => {
                                                let ins = e.target.value;
                                                props.setFieldValue("institucion", ins.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.institucion && props.errors.institucion ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.institucion && props.errors.institucion ? (
                                                props.errors.institucion
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Celular"
                                            name="celular"
                                            id="celular"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{ maxLength: 10 }}
                                            value={props.values.celular}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.celular && props.errors.celular ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.celular && props.errors.celular ? (
                                                props.errors.celular
                                            ) : null}
                                        >
                                        </TextField>

                                    </Grid> */}

                                </Grid>
                                <br />
                                <br />
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}></Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.boton}>
                                            Guardar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}></Grid>
                                </Grid>

                            </form>
                        )
                    }}
                </Formik>
            </Paper >

            {/* <Tooltip title="El número de cartas de recomendación mínimo es de 2 y máximo de 10." aria-label="add">
                <HelpOutlineIcon className={classes.avatar} />
            </Tooltip> */}
            <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Verifique el estatus de Completado de sus cartas de recomendación,
                                                        se requiere un mínimo de 2 para poder cerrar su registro. </Typography>
                        </React.Fragment>
                        }
                    >
                    <Fab size="small" color="#f48124" className={classes.margin} >
                        <HelpOutlineIcon />
                    </Fab>
                </HtmlTooltip>
            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Fortalezas del Aspirante</TableCell> */}
                                <TableCell align="left">Nombre&nbsp;del&nbsp;otorgante</TableCell>
                                <TableCell align="left">Correo&nbsp;del&nbsp;otorgante</TableCell>
                               {/*  <TableCell align="left">ID</TableCell> */}
                                <TableCell align="left">Emitida</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {infoTabla.map((row) => (
                                <TableRow key={row.id}>
                                    {/* <TableCell align="left" component="th" scope="row">
                                        {row.observaciones}
                                    </TableCell> */}
                                    <TableCell align="left">{row.nombre}</TableCell>
                                    <TableCell align="left">{row.correo}</TableCell>
                                   {/*  <TableCell align="left">{row.id}</TableCell> */}
                                    <TableCell align="left">{row.emitida}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    );
}

export default RegistroCartas;