import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import { Global } from '../../../constants/global';


const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginLeft: "20px",
        marginRight: "20px",
        // marginBottom: theme.spacing(5),
        // marginLeft: "10%",
        // marginRight: "10%",
    },
    back: {
        background: 'linear-gradient(0deg, #003366 5%, #FFFFFF 100%)',
    },
}));



function ContactoEmergencia({ persona }) {


    const [datosAnterior, setDatosAnterior] = useState({
        nombre: '',
        parentesco: '',
        telefono: '',
        correo: '',
        paisEm: '',
        cpEm: '',
        estadoEm: '',
        ciudadEm: '',
        coloniaEm: '',
        calleEm: '',
        domicilio: '',
    })

    useEffect(() => {

        let colonia = "";
        const datosIniciales = async () => {
            await axios.get(Global + "contactoem/personas/" + persona, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setDatosAnterior({
                    nombre: response.data[0].nombre,
                    parentesco: response.data[0].parentesco,
                    telefono: response.data[0].telefono,
                    correo: response.data[0].correo,
                    coloniaEm: response.data[0].d.colonia,
                    calleEm: response.data[0].d.calle,
                    domicilio: response.data[0].d.informacion,
                    paisEm: response.data[0].d.pais.pais,
                })
                colonia = response.data[0].d.colonia

                axios.get(Global + "direccioncp/direccion/" + response.data[0].d.iddireccion, {
                    headers: {
                        'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept, Methods',
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,HEAD,POST,PUT',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    if (response.data.length > 0) {
                        setDatosAnterior({
                            cpEm: response.data[0].codigoPostal.cp,
                            estadoEm: response.data[0].codigoPostal.estado,
                            ciudadEm: response.data[0].codigoPostal.ciudad
                        })
                        if (colonia === "" || colonia === null) {
                            // console.log(response.data[0].codigoPostal)
                            setDatosAnterior({
                                coloniaEm: response.data[0].codigoPostal.colonia
                            })
                        }
                    }

                }).catch(error => console.log(error))

            }).catch(error => console.log(error));
        }

        datosIniciales();

    }, []);

    const classes = useStyles();
    const initial = datosAnterior;

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initial}
            >
                {props => {
                    return (
                        <Paper elevation={3} className={classes.paperContainer}>
                            <br />
                            <Typography variant="h5" align="left">
                                Contacto En Caso De Emergencia
                                        <hr />
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid
                                    item xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Nombre completo"
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        value={props.values.nombre}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid

                                    item xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Parentesco"
                                        id="parentesco"
                                        name="parentesco"
                                        type="text"
                                        value={props.values.parentesco}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid

                                    item xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Teléfono"
                                        id="telefono"
                                        name="telefono"
                                        type="text"
                                        value={props.values.telefono}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Correo electrónico"
                                        id="correo"
                                        name="correo"
                                        type="text"
                                        value={props.values.correo}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="País"
                                        id="paisEm"
                                        name="paisEm"
                                        type="text"
                                        value={props.values.paisEm}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Código Postal"
                                        id="cpEm"
                                        name="cpEm"
                                        type="text"
                                        value={props.values.cpEm}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Estado"
                                        id="estadoEm"
                                        name="estadoEm"
                                        type="text"
                                        value={props.values.estadoEm}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Ciudad"
                                        id="ciudadEm"
                                        name="ciudadEm"
                                        type="text"
                                        value={props.values.ciudadEm}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Colonia"
                                        id="coloniaEm"
                                        name="coloniaEm"
                                        type="text"
                                        value={props.values.coloniaEm}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Calle y número"
                                        id="calleEm"
                                        name="calleEm"
                                        value={props.values.calleEm}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                </Grid>

                                <Grid

                                    item
                                    xs={12}
                                >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Domicilio completo"
                                        id="domicilio"
                                        name="domicilio"
                                        value={props.values.domicilio}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>


                            </Grid>
                            <br />
                        </Paper>
                    )
                }}

            </Formik>
        </div>
    );
}

export default ContactoEmergencia;