import { Grid, Paper } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import { makeStyles } from '@material-ui/core/styles';
import { Link, Breadcrumbs } from '@material-ui/core';
import { Auth } from "../../../context/AuthContext";
import TablaAspirantes from './TablaAspirantes';
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import HowtoRegIcon from '@material-ui/icons/HowToReg';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

function Index({ history }) {

    const classes = useStyles();
    const { usuario } = useContext(Auth);

    useEffect(() => {
        if (usuario === null) {
            history.push("/siip");
        }
    })

    return (
        <div className={classes.root}>
            <Head />
            <Paper elevation={3} className={classes.paperContainer}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link href="/inicio"
                        className={classes.link}
                    >
                        <HomeIcon className={classes.icon} />
                        Inicio
                    </Link>
                    <Link
                        aria-current="page"
                        className={classes.link}
                    >
                        <HowtoRegIcon className={classes.icon} />
                        Aspirantes
                    </Link>
                    <Link
                        href="/siip/Aspirantes"
                        aria-current="page"
                        className={classes.link}
                    >
                        <CheckIcon className={classes.icon} />
                        Listado de aspirantes
                    </Link>
                </Breadcrumbs>
                {/* <Typography variant="h5" className={classes.titulo}>
                    Aspirantes
                </Typography> */}
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                    <Grid item xs={12} sm={12}>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TablaAspirantes />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                </Grid>
            </Paper >
            <Pie />
        </div>
    );

}
export default Index;