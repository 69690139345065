// React core & hooks
import React, { useContext, useEffect, useState } from "react";
// Material dependencies
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
// Upload Context
import { UploadContext } from "./UploadContext";
// Service
import { uploadService } from "./services/uploadFile.service";

const EntitySelectorComponent = ({ isValidFile }) => {
  const { uploadState, setUploadState } = useContext(UploadContext);
  const useStyles = makeStyles((theme) => ({
    asideContainer: {
      display: "flex",
      flexDirection: "column",
    },
    submitBtn: {
      fontSize: "1.25rem",
      fontWeight: "500",
      borderRadius: "4px 4px 4px 4px",
      backgroundColor: "#034AA7",
      color: "white",
      width: "10%",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#356eb8",
      },
    },
    selectContainer: {
      display: "flex",
      flexDirection: "column",
      width: "10%",
      paddingTop: "2%",
    },
  }));
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    setLoading(true, setUploadState({ ...setUploadState, resetState: true }));
    uploadService(uploadState).then(({ success, failed }) => {
      setSuccess(success);
      setError(failed);
      setOpenSnackbar(true);
      setUploadState({
        ...uploadState,
        csvName: "",
        csvSize: "",
        csv: [],
        entidad: "",
      });
    });
  };

  const handleOpenSelect = () => {
    setOpenSelect(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setLoading(false);
  };
  const handleCloseSelect = () => {
    setOpenSelect(false);
  };
  const handleChange = (event) => {
    setUploadState({ ...uploadState, entidad: event.target.value });
  };

  useEffect(() => {
    setUploadState({ ...uploadState, entidad: "" });
  }, [uploadState.csv]);

  return (
    <aside className={classes.asideContainer}>
      <h4>Tu archivo</h4>
      {uploadState.resetState === false && (
        <li key={uploadState.csvName}>
          {uploadState.csvName} - {uploadState.csvSize} bytes
        </li>
      )}
      <div className={classes.selectContainer}>
        <FormControl>
          <InputLabel id="label-entidad">Entidad*</InputLabel>
          <Select
            id="entidad"
            open={openSelect}
            onClose={handleCloseSelect}
            onOpen={handleOpenSelect}
            value={uploadState.entidad || ""}
            onChange={handleChange}
            disabled={uploadState.csv?.length === 0}
          >
            <MenuItem value={"alumno"}>Alumnos</MenuItem>
            <MenuItem value={"asociaciones"}>Asociaciones</MenuItem>
            <MenuItem value={"becante"}>Becante</MenuItem>
            <MenuItem value={"curso"}>Curso</MenuItem>
            <MenuItem value={"etnia"}>Etnia</MenuItem>
            <MenuItem value={"inscripcion"}>Calificaciones</MenuItem>
            <MenuItem value={"instituciones"}>Instituciones</MenuItem>
            <MenuItem value={"pais"}>Pais</MenuItem>
            <MenuItem value={"programa"}>Programa</MenuItem>
            <MenuItem value={"materia"}>Materia</MenuItem>
            <MenuItem value={"motivo_baja"}>Motivos de baja</MenuItem>
            <MenuItem value={"profesor"}>Profesores</MenuItem>
            <MenuItem value={"sede"}>Sede</MenuItem>
            <MenuItem value={"moneda"}>Moneda</MenuItem>
            <MenuItem value={"ubpp"}>UBPP</MenuItem>
            <MenuItem value={"unidad_academica"}>Unidad académica</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Button
        className={classes.submitBtn}
        disabled={uploadState.entidad === "" || !isValidFile || loading}
        onClick={handleSubmit}
      >
        Enviar
      </Button>
      <>
        <Dialog
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle component={"span"} id="alert-dialog-title">
            {success.message || error.message}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {success.error?.length !== 0 && error?.error !== null && (
                <div>
                  <p>Los siguientes registros no se insertaron</p>
                  {success.error?.map((error) => (
                    <ul>
                      <li key={error}>
                        {error} - {success.error2}
                      </li>
                    </ul>
                  ))}
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSnackbar} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </aside>
  );
};

export default EntitySelectorComponent;
