import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Global } from "../../../constants/global";
import Backdrop from "@material-ui/core/Backdrop";
import alerta from "./PlantillaAlerta";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(5),
    paddingRight: "20px",
    paddingLeft: "20px",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
  titulo: {
    fontSize: "2em",
    lineHeight: "1em",
    color: "#226af8",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    textTransform: "capitalize",
    textAlign: "center",
  },
  subtitulo: {
    fontSize: "25px",
    color: "#54595F",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
}));

function DomicilioCuenta(props) {
  const classes = useStyles();
  const { persona } = props;
  const [paises, setPaises] = useState([]);
  //Mostrar campos aspirantes para mexicanos
  const [domicilio, setDomicilio] = useState(false);

  //Datos para lugar de domicilio
  const [, setPaisDom] = useState("");

  //Datos del aspirante
  const [datosAnterior, setDatosAnterior] = useState([]);
  const [c, setClaves] = useState([]);

  const [cpDomicilio, setCPDomicilio] = useState("");
  const [estadoDomicilio, setEstadoDomicilio] = useState("");
  const [coloniaDomicilio, setColoniaDomicilio] = useState("");
  const [, setCiudadDomicilio] = useState("");
  const [info, setInfo] = useState("");

  useEffect(() => {
    let personaD = {
      paisDom: "",
      cpDom: "",
      estadoDom: "",
      ciudadDom: "",
      coloniaDom: "",
      otroDom: "",
      calleDom: "",
      informacionDom: "",
    };

    let claves = {
      iddireccion: "",
    };

    const selectDatos = async () => {
      // PAIS
      await axios
        .get(Global + "pais", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setPaises(response.data);
        })
        .catch((error) => console.log(error));
    };

    const datosAspirante = async () => {
      //Conseguir datos direccion
      await axios
        .get(Global + "personad/personas/" + persona.idP, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          claves.iddireccion = response.data[0].direccion.iddireccion;
          personaD.paisDom =
            response.data[0].direccion.pais.idpais +
            "-" +
            response.data[0].direccion.pais.pais;
          personaD.informacionDom = response.data[0].direccion.informacion;
          personaD.calleDom = response.data[0].direccion.calle;
          if (response.data[0].direccion.colonia !== "") {
            personaD.otroDom = response.data[0].direccion.colonia;
            personaD.coloniaDom = "otra";
          } else {
            axios
              .get(
                Global +
                "direccioncp/direccion/" +
                response.data[0].direccion.iddireccion,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                personaD.coloniaDom = response.data[0].codigoPostal.idcodigo;
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));

      await axios
        .get(Global + "direccioncp/direccion/" + claves.iddireccion, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            personaD.cpDom = response.data[0].codigoPostal.cp;
            personaD.estadoDom = response.data[0].codigoPostal.estado;
            personaD.ciudadDom = response.data[0].codigoPostal.ciudad;
          }

          axios
            .get(Global + "codigo/cps/" + response.data[0].codigoPostal.cp, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
              },
            })
            .then((response) => {
              setCPDomicilio(response.data[0]);
              setEstadoDomicilio(response.data[0]);
              setColoniaDomicilio(response.data);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));

      let optionPais2 = personaD.paisDom.split("-");

      if (optionPais2[1] === "MÉXICO") {
        setDomicilio(true);
      }

      //Domicilio
      await axios
        .get(Global + "codigo/estado/" + personaD.estadoDom, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setCiudadDomicilio(response.data);
        })
        .catch((error) => console.log(error));

      setClaves(claves);
      setDatosAnterior(personaD);
      handleCargaClose();
    };

    selectDatos();
    datosAspirante();
  }, [info]);

  const handleCargaClose = () => {
    setCargando(false);
  };

  const schemaValidation = Yup.object({
    paisDom: Yup.string().required("El campo país es obligatorio"),
    cpDom: Yup.string().when("paisDom", (paisDom) => {
      if (paisDom !== undefined) {
        if (paisDom.split("-")[1] === "MÉXICO") {
          return Yup.string().required(
            "El campo es obligatorio si es de MÉXICO"
          );
        } else {
          return Yup.string();
        }
      }
    }),
    estadoDom: Yup.string().when("paisDom", (paisDom) => {
      if (paisDom !== undefined) {
        if (paisDom.split("-")[1] === "MÉXICO") {
          return Yup.string().required(
            "El campo es obligatorio si es de México"
          );
        } else {
          return Yup.string();
        }
      }
    }),
    ciudadDom: Yup.string().when("paisDom", (paisDom) => {
      if (paisDom !== undefined) {
        if (paisDom.split("-")[1] === "MÉXICO") {
          return Yup.string().required(
            "El campo es obligatorio si es de México"
          );
        } else {
          return Yup.string();
        }
      }
    }),
    coloniaDom: Yup.string().when("paisDom", (paisDom) => {
      if (paisDom !== undefined) {
        if (paisDom.split("-")[1] === "MÉXICO") {
          return Yup.string().required(
            "El campo es obligatorio si es de México"
          );
        } else {
          return Yup.string();
        }
      }
    }),
    calleDom: Yup.string().when("paisDom", (paisDom) => {
      if (paisDom !== undefined) {
        if (paisDom.split("-")[1] === "MÉXICO") {
          return Yup.string().required(
            "El campo es obligatorio si es de México"
          );
        } else {
          return Yup.string();
        }
      }
    }),
    informacionDom: Yup.string().when("paisDom", (paisDom) => {
      if (paisDom !== undefined) {
        if (paisDom.split("-")[1] !== "MÉXICO") {
          return Yup.string().required("El campo es obligatorio");
        } else {
          return Yup.string();
        }
      }
    }),
    otroDom: Yup.string().when("coloniaDom", (coloniaDom) => {
      if (coloniaDom !== undefined) {
        if (coloniaDom === "otra") {
          return Yup.string().required(
            "El campo es obligatorio para Otra colonia "
          );
        } else {
          return Yup.string();
        }
      }
    }),
  });

  const functionSubmit = async (valores) => {
    let optionPaisDom = valores.paisDom.split("-");
    let direccion = {
      calle: "",
      colonia: "",
      informacion: "",
      pais: {
        idpais: optionPaisDom[0],
      },
    };

    //Guardar solo valores depende al pa[is]
    if (valores.paisDom.split("-")[1] === "MÉXICO") {
      if (valores.coloniaDom === "otra") {
        direccion.colonia = valores.otroDom;
      }
      direccion.calle = valores.calleDom;
    } else {
      direccion.informacion = valores.informacionDom;
    }

    await axios
      .put(Global + "direccion/" + c.iddireccion, direccion, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      })
      .catch((error) => console.log(error));

    let codigoContactoDom = "";
    let codigoDireccionCPDom = "";
    let putCodigoPostalDom = {
      codigoPostal: {
        idcodigo: "",
      },
    };

    if (optionPaisDom[1] === "MÉXICO") {
      if (valores.coloniaDom === "otra") {
        await axios
          .get(
            Global +
            "codigo/estado/" +
            valores.estadoDom +
            "/cp/{cp}/ciudad/{ciudad}?ciudad=" +
            valores.ciudadDom +
            "&cp=" +
            valores.cpDom,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.length > 0) {
              putCodigoPostalDom.codigoPostal.idcodigo =
                response.data[0].idcodigo;
              codigoContactoDom = response.data[0].idcodigo;
            }
          })
          .catch((error) => console.log(error));
      } else {
        await axios
          .get(Global + "codigo/" + valores.coloniaDom, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.localStorage.getItem("token"),
            },
          })
          .then((response) => {
            putCodigoPostalDom.codigoPostal.idcodigo = response.data.idcodigo;
            codigoContactoDom = response.data.idcodigo;
          })
          .catch((error) => console.log(error));
      }

      // alert(putCodigoPostalDom.codigoPostal.idcodigo)
      //Get id de codigopostal
      //Ver si hay valores en direccioncp
      await axios
        .get(Global + "direccioncp/direccion/" + c.iddireccion, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            codigoDireccionCPDom = response.data[0].iddc;
            ////////////Si tiene direccion///////////////////////

            axios
              .put(
                Global + "direccioncp/" + codigoDireccionCPDom,
                putCodigoPostalDom,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                }
              )
              .catch((error) => console.log(error));
          } else {
            //Guarda en /direccioncp

            axios
              .post(
                Global +
                "direccioncp/cp/" +
                codigoContactoDom +
                "/direccion/" +
                c.iddireccion,
                {
                  persona: {
                    persona: "string",
                  },
                },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                }
              )
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    }

    alerta("Datos registrados", "success");

    setInfo("cambio");
  };

  const handleChangeDomicilio = (e) => {
    e.preventDefault();
    let optionPais = e.target.value.split("-");
    if (optionPais[1] === "MÉXICO") {
      setDomicilio(true);

      axios
        .get(Global + "pais/pais/" + optionPais[1], {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setPaisDom(response.data);
        })
        .catch((error) => console.log(error));
    } else {
      setDomicilio(false);
      axios
        .get(Global + "pais/pais/" + optionPais[1], {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setPaisDom(response.data);
        })
        .catch((error) => console.log(error));
    }
  };

  //Cuando cambian el codigo postal
  const handleChangeCP = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case "cpDom":
        if (e.target.value.length > 0) {
          setCiudadDomicilio("");
          axios
            .get(Global + "codigo/cps/" + e.target.value, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
              },
            })
            .then((response) => {
              setCPDomicilio(response.data[0]);
              setEstadoDomicilio(response.data[0]);
              setColoniaDomicilio(response.data);
            })
            .catch((error) => console.log(error));
        }
        break;
      default:
        break;
    }
  };

  //Cuando cambian el estado en domicilio y contacto de emergencia
  const [cargando, setCargando] = useState(true);

  const initial = datosAnterior;

  return (
    <div>
      <Grid container spacing={1} alignItems="center"></Grid>
      {cargando ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Formik
          enableReinitialize
          validationSchema={schemaValidation}
          initialValues={initial}
          onSubmit={(valores) => {
            functionSubmit(valores);
          }}
        >
          {(props) => {
            return (
              <form noValidate onSubmit={props.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.formControl}
                      error={
                        props.touched.paisDom && props.errors.paisDom
                          ? true
                          : false
                      }
                    >
                      <InputLabel>Pais</InputLabel>
                      <Select
                        native
                        value={props.values.paisDom}
                        onChange={(e) => {
                          handleChangeDomicilio(e);
                          props.setFieldValue("paisDom", e.target.value);
                        }}
                        onBlur={props.handleBlur}
                        label="Pais"
                        inputProps={{
                          name: "paisDom",
                          id: "paisDom",
                        }}
                      >
                        <option aria-label="None" value="" />{" "}
                        {paises.map((pais) => {
                          return (
                            <option
                              key={pais.idpais}
                              value={pais.idpais + "-" + pais.pais}
                              label={pais.pais}
                            >
                              {pais.pais}
                            </option>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {props.touched.paisDom && props.errors.paisDom
                          ? props.errors.paisDom
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {domicilio ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label="Código Postal"
                          name="cpDom"
                          variant="outlined"
                          fullWidth
                          value={props.values.cpDom}
                          onChange={(e) => {
                            handleChangeCP(e);
                            props.setFieldValue("cpDom", e.target.value);
                          }}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.cpDom && props.errors.cpDom
                              ? true
                              : false
                          }
                          helperText={
                            props.touched.cpDom && props.errors.cpDom
                              ? props.errors.cpDom
                              : null
                          }
                        ></TextField>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                          error={
                            props.touched.estadoDom && props.errors.estadoDom
                              ? true
                              : false
                          }
                        >
                          <InputLabel>Estado</InputLabel>
                          <Select
                            native
                            value={props.values.estadoDom}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            label="Estado"
                            inputProps={{
                              name: "estadoDom",
                              id: "estadoDom",
                            }}
                          >
                            <option aria-label="None" value="" />
                            {cpDomicilio ? (
                              <option
                                key={cpDomicilio.idcodigo}
                                value={cpDomicilio.estado}
                              >
                                {cpDomicilio.estado}
                              </option>
                            ) : null}
                          </Select>
                          <FormHelperText>
                            {props.touched.estadoDom && props.errors.estadoDom
                              ? props.errors.estadoDom
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                          error={
                            props.touched.ciudadDom && props.errors.ciudadDom
                              ? true
                              : false
                          }
                        >
                          <InputLabel>Ciudad</InputLabel>
                          <Select
                            native
                            value={props.values.ciudadDom}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            label="Ciudad"
                            inputProps={{
                              name: "ciudadDom",
                              id: "ciudadDom",
                            }}
                          >
                            <option aria-label="None" value="" />
                            {estadoDomicilio ? (
                              <option
                                key={estadoDomicilio.idcodigo}
                                value={estadoDomicilio.ciudad}
                              >
                                {estadoDomicilio.ciudad}
                              </option>
                            ) : null}
                          </Select>
                          <FormHelperText>
                            {props.touched.ciudadDom && props.errors.ciudadDom
                              ? props.errors.ciudadDom
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                          error={
                            props.touched.coloniaDom && props.errors.coloniaDom
                              ? true
                              : false
                          }
                        >
                          <InputLabel>Colonia</InputLabel>
                          <Select
                            native
                            value={props.values.coloniaDom}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            label="coloniaDom"
                            inputProps={{
                              name: "coloniaDom",
                              id: "coloniaDom",
                            }}
                          >
                            <option aria-label="None" value="" />
                            {coloniaDomicilio
                              ? coloniaDomicilio.map((colonia) => {
                                return (
                                  <option
                                    key={colonia.idcodigo}
                                    value={colonia.idcodigo}
                                  >
                                    {colonia.colonia}
                                  </option>
                                );
                              })
                              : null}
                            <option value="otra">Otra colonia...</option>
                          </Select>
                          <FormHelperText>
                            {props.touched.coloniaDom && props.errors.coloniaDom
                              ? props.errors.coloniaDom
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="otroDom"
                          name="otroDom"
                          label="Otra colonia"
                          value={props.values.otroDom}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.otroDom && props.errors.otroDom
                              ? true
                              : false
                          }
                          helperText={
                            props.touched.otroDom && props.errors.otroDom
                              ? props.errors.otroDom
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="calleDom"
                          label="Calle y número"
                          name="calleDom"
                          value={props.values.calleDom}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.touched.calleDom && props.errors.calleDom
                              ? true
                              : false
                          }
                          helperText={
                            props.touched.calleDom && props.errors.calleDom
                              ? props.errors.calleDom
                              : null
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="informacionDom"
                        label="Domicilio completo"
                        name="informacionDom"
                        value={props.values.informacionDom}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                          props.touched.informacionDom &&
                            props.errors.informacionDom
                            ? true
                            : false
                        }
                        helperText={
                          props.touched.informacionDom &&
                            props.errors.informacionDom
                            ? props.errors.informacionDom
                            : null
                        }
                      />
                    </Grid>
                  )}

                  <Grid container spacing={3} justify="center">
                    <Grid item xs={4} sm={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.boton}
                      >
                        Actualizar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default DomicilioCuenta;
