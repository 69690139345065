import React, { useEffect, useContext, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Breadcrumbs } from '@material-ui/core'
import { Auth } from "../../../context/AuthContext";
import TablaAlumnos from './TablaActualizar';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

function Index({ history }) {

    const classes = useStyles();
    const { usuario } = useContext(Auth);

    const [titulo, settitulo] = useState('Alumnos')

    useEffect(() => {
        if (usuario == null) {
            history.push("/siip");
        }
    })


    const cambiarTitulo = (titulo) => {
        settitulo(titulo);
    }

    return (
        <div className={classes.root}>
            <Head />
            <Paper elevation={3} className={classes.paperContainer}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link href="/inicio"
                        className={classes.link}
                    >
                        <HomeIcon className={classes.icon} />
                        Inicio
                    </Link>
                    <Link
                        aria-current="page"
                        className={classes.link}
                    >
                        <AssignmentIndIcon className={classes.icon} />
                        Alumno
                    </Link>
                    <Link
                        color="textPrimary"
                        href="/siip/indexAlumno"
                        aria-current="page"
                        className={classes.link}
                    >
                        <SearchIcon className={classes.icon} />
                        Menú Alumnoo
                    </Link>
                </Breadcrumbs>
                <Typography variant="h5" className={classes.titulo}>
                    {titulo}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {
                            usuario != null ?
                                <TablaAlumnos metodo={cambiarTitulo} />
                                : null
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                </Grid>
            </Paper >
            <Pie />
        </div>
    );

}
export default Index;