import React from 'react'
import TablaAdscripcion from './TablaAdscripcion';
import TablaPrograma from './TablaPrograma';
import TablaLineas from './TablaLineas';
import TablaAsociacion from './TablaAsociacion';

const DatosProfesor = (props) => {

    return (
        <>
            <TablaAdscripcion data={props.data} />
            <br />
            <TablaPrograma data={props.data} />
            <br />
            <TablaLineas data={props.data} />
            <br />
            <TablaAsociacion data={props.data} />
        </>
    );
}

export default DatosProfesor;