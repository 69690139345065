import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { Hidden, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Global } from "../../../constants/global"
import Swal from 'sweetalert'
import { Button } from 'antd';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    boton: {
        fontFamily: "Poppins, Sans-serif",
        fontSize: "15px",
        fontWeight: 600,
        textTransform: "none",
        lineHeight: "18px",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 1px",
        borderRadius: "6px 6px 6px 6px",
        padding: "16px 50px 16px 50px",
        backgroundColor: "#2361b2",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#1481e8",
        },
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function ExamenProfesional(props) {

    const classes = useStyles();
    const [columns, setColumns] = useState([
        {
            title: "Dia", field: "diaexam",lookup: {1: '1', 2:'2', 3:'3', 4:'4', 5:'5', 6:'6', 7:'7', 8:'8', 9:'9', 10:'10', 11:'11', 12:'12', 13:'13', 14:'14', 15:'15', 16:'16', 17:'17', 18:'18', 19:'19', 20:'20', 21:'21', 22:'22', 23:'23', 24:'24', 25:'25', 26:'26', 27:'27', 28:'28', 29:'29', 30:'30',31:'31'},
             validate: rowData =>
                rowData.diaexam === '' ? { isValid: false, helperText: 'El día no puede estar vacio' }
                    :
                    rowData.diaexam !== undefined && rowData.diaexam !== "" && rowData.diaexam !== null ?
                        rowData.diaexam.trim().length !== rowData.diaexam.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
        {
            title: "Mes", field: "mesexam",lookup: {ENERO: "ENERO",FEBRERO: "FEBRERO",MARZO:"MARZO",ABRIL:"ABRIL",MAYO:"MAYO",JUNIO:"JUNIO",JULIO:"JULIO",AGOSTO:"AGOSTO",SEPTIEMBRE:"SEPTIEMBRE",OCTUBRE:"OCTUBRE",NOVIEMBRE:"NOVIEMBRE",DICIEMBRE:"DICIEMBRE"},
             validate: rowData =>
                rowData.mesexam === '' ? { isValid: false, helperText: 'El Mes no puede estar vacio' }
                    :
                    rowData.mesexam !== undefined && rowData.mesexam !== "" && rowData.mesexam !== null ?
                        rowData.mesexam.trim().length !== rowData.mesexam.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
        {title: "Año", field: "anioexam", lookup: {2022:'2022',2023:'2023',2024:'2024',2025:'2025',2026:'2026',2027:'2027',2028:'2028',2029:'2029',2030:'2030',2031:'2031',2032:'2032'}},
        { title: "Hora de Examen", field: "horaexam",type:"time"},
        /*  {
            title: "Hora", field: "horaexam",lookup: {0: '00',1: '01', 2:'02', 3:'03', 4:'04', 5:'05', 6:'06', 7:'07', 8:'08', 9:'09', 10:'10', 11:'11', 12:'12', 13:'13', 14:'14', 15:'15', 16:'16', 17:'17', 18:'18', 19:'19', 20:'20', 21:'21', 22:'22', 23:'23'},
             validate: rowData =>
                rowData.horaexam === '' ? { isValid: false, helperText: 'El día no puede estar vacio' }
                    :
                    rowData.horaexam !== undefined && rowData.horaexam !== "" && rowData.horaexam !== null ?
                        rowData.horaexam.trim().length !== rowData.horaexam.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        }, */
        /* {
            title: "Minutos", field: "minutosexam",lookup: {0:'00',1: '01', 2:'02', 3:'03', 4:'04', 5:'05', 6:'06', 7:'07', 8:'08', 9:'09', 10:'10', 11:'11', 12:'12', 13:'13', 14:'14', 15:'15', 16:'16', 17:'17', 18:'18', 19:'19', 20:'20', 21:'21', 22:'22', 23:'23', 24:'24', 25:'25', 26:'26', 27:'27', 28:'28', 29:'29', 30:'30',31:'31',32:'32',33:'33',34:'34',35:'35',36:'36',37:'37',38:'38',39:'39',40:'40',41:'41',42:'42',43:'43',44:'44',45:'45',46:'46',47:'47',48:'48',49:'49',50:'50',51:'51',52:'52',53:'53',54:'54',55:'55',56:'56',57:'57',58:'58',59:'59'},
             validate: rowData =>
                rowData.minutosexam === '' ? { isValid: false, helperText: 'El día no puede estar vacio' }
                    :
                    rowData.minutosexam !== undefined && rowData.minutosexam !== "" && rowData.minutosexam !== null ?
                        rowData.minutosexam.trim().length !== rowData.minutosexam.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        }, */
        {
            title: "CEDE", field: "sedeexam",lookup: {SI:"SI",NO:"NO"},
             validate: rowData =>
                rowData.sedeexam === '' ? { isValid: false, helperText: 'El campo no puede estar vacio' }
                    :
                    rowData.sedeexam !== undefined && rowData.sedeexam !== "" && rowData.sedeexam !== null ?
                        rowData.sedeexam.trim().length !== rowData.sedeexam.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
        {
            title: "Veredicto", field: "veredictoexam",lookup:{Aprobado_por_Mayoria:"Aprobado por Mayoria",Aprobado_Por_Unanimidad:"Aprobado Por Unanimidad",Aprobado_Por_Unanimidad_Honorifica:"Aprobado Por Unanimidad Honorifica",Diferido:"Diferido",Reprobado:"Reprobado" },
             validate: rowData =>
                rowData.veredictoexam === '' ? { isValid: false, helperText: 'El campo no puede estar vacio' }
                    :
                    rowData.veredictoexam !== undefined && rowData.veredictoexam !== "" && rowData.veredictoexam !== null ?
                        rowData.veredictoexam.trim().length !== rowData.veredictoexam.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
        {title: "Fojas", field: "fojas",
        lookup: {0:'0',1: '1', 2:'2', 3:'3', 4:'4', 5:'5', 6:'6', 7:'7', 8:'8', 9:'9', 10:'10', 11:'11', 12:'12', 13:'13', 14:'14', 15:'15', 16:'16', 17:'17', 18:'18', 19:'19', 20:'20', 21:'21', 22:'22', 23:'23', 24:'24', 25:'25', 26:'26', 27:'27', 28:'28', 29:'29', 30:'30',31:'31',32:'32',33:'33',34:'34',35:'35',36:'36',37:'37',38:'38',39:'39',40:'40',41:'41',42:'42',43:'43',44:'44',45:'45',46:'46',47:'47',48:'48',49:'49',
                    50:'50',51:'51',52:'52',53:'53',54:'54',55:'55',56:'56',57:'57',58:'58',59:'59',60:'60',61:'61',62:'62',63:'63',64:'64',65:'65',66:'66',67:'67',68:'68',69:'69',70:'70',71:'71',72:'72' ,73:'73',74:'74' ,75:'75',76:'76',77:'77',78:'78',79:'79',80:'80',81:'81',82:'82',83:'83',84:'84',85:'85',86:'86',87:'87',88:'88',89:'89',90:'90',91:'91',92:'92',93:'93',94:'94',95:'95',96:'96',97:'97',98:'98',99:'99'
        }},
        {title: "Libro", field: "libro",
        lookup: {I:"I",II:"II",III:"III",IV:"IV",V:"V",VI:"VI",VII:"VII",VIII:"VIII",IX:"IX",X:"X",XI:"XI",XII:"XII",XIII:"XIII",XIV:"XIV",XV:"XV",XVI:"XVI",XVII:"XVII",XVIII:"XVIII",XIX:"XIX",XX:"XX",XXI:"XXI",XXII:"XXII",XXIII:"XXIII",XXIV:"XXIV",XXV:"XXV",XXVI:"XXVI",XXVII:"XXVII",XXVIII:"XXVIII",XXIX:"XXIX",XXX:'XXX',	
        XXXI:'XXXI',XXXII:'XXXII',XXXIII:'XXXIII',XXXIV:'XXXIV',XXXV:'XXXV',XXXVI:'XXXVI',XXXVII:'XXXVII',XXXVIII: 'XXXVIII',XXXIX	:'XXXIX',XL :'XL',XLI:'XLI',XLII:'XLII'
        ,XLIII:'XLIII',XLIV:'XLIV',XLV:'XLV',XLVI:'XLVI',XLVII:'XLVII',XLVIII:'XLVIII',XLIX:'XLIX',L:'L',LI	:'LI',LII:'LII',LIII:'LIII',LIV:'LIV',LV	:'LV',LVI:'LVI',LVII:'LVII',LVIII:'LVIII',LIX:'LIX'
        ,LX :'LX',LXI:'LXI',LXII:'LXII',LXIII:'LXIII',LXIV:'LXIV',LXV:'LXV',LXVI:'LXVI',LXVII:'LXVII',LXVIII:'LXVIII',LXIX:'LXIX',LXX:'LXX',LXXI:'LXXI',LXXII:'LXXII',LXXIII:'LXXIII',LXXIV:'LXXIV',LXXV:'LXXV',LXXVI:'LXXVI',LXXVII:'LXXVII',LXXVIII:'LXXVIII',LXXIX:'LXXIX',LXXX:'LXXX',LXXXI:'LXXXI',LXXXII:'LXXXII',LXXXIII:'LXXXIII',LXXXIV:'LXXXIV',LXXXV:'LXXXV',LXXXVI:'LXXXVI',LXXXVII:'LXXXVII',LXXXVIII:'LXXXVIII',LXXXIX	:'LXXXIX',XC:'XC',XCI:'XCI',XCII:'XCII',XCIII:'XCIII',XCIV:'XCIV',XCV:'XCV',XCVI:'XCVI',XCVII:'XCVII',XCVIII:'XCVIII',XCIX:'XCIX'    }
        },
        { title: "Aula Virtual", field: "aulavirtual"},

    ])

    const [data, setData] = useState([])
    //const [idExamen, setExamen] = useState('')
    const { idtesis } = props.data
    const { idreunion } = props.data;
    const { idexamenpro } = props.data;
    const [open, setOpen] = useState(false)

    useEffect(() => {

      /*   let exam = {
            diaexam: '',   
            mesexam: '',    
            anioexam: '',  
            horaexam:  '', 
            minutosexam: '',
            aulavirtual: '',
            sedeexam:  '', 
            veredictoexam: '',
            fojas:  '',    
            libro:  '',    
            actaexam: '',  
        } */

        const datos = async () => {

            await axios.get(Global + "examenpro/tesis/" + idtesis, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error)) 

            /* await axios.get(Global + "examenpro/" + idexamenpro, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                
                exam.diaexam = response.data.diaexam
                exam.mesexam = response.data.mesexam
                exam.anioexam = response.data.anioexam
                exam.horaexam = response.data.horaexam
                exam.minutosexam = response.data.minutosexam
                exam.aulavirtual = response.data.aulavirtual
                exam.sedeexam = response.data.sedeexam
                exam.veredictoexam = response.data.veredictoexam
                exam.fojas = response.data.fojas
                exam.libro = response.data.libro
                exam.actaexam = response.data.actaexam
                setData(response.data)
            }).catch(error => console.log(error)) */


            await axios.get(Global + "examenpro/" + idexamenpro, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))
        }

        datos()

    }, [])

  /*   const handleClickDownload = () => {
     
        console.log('idexamen ',idexamenpro)
        axios({
            url: Global + "pdf/veredicto/"+idexamenpro, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Acta_Reunión.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })

    } */

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        props.rol === "administrador" ||
            props.rol === "coordinador_general" ||
            props.rol === "coordinador_posgrado" ?
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de Examen Profesional 
                            </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
               /*  actions={[
                    {  
                        tooltip: 'Asignar',                    
                        onClick: (e,rowData) =>{
                        handleClickDownload(rowData)}
                    },
    
                ]} */
             /*    components={
                    {Action: props => (
                    <Button
                    onClick={handleClickDownload(idexamenpro)}
                    variant="contained"   
                    color="primary"
                    aling="left"
                    className={classes.boton}>
                    Imprimir Acta
                    </Button>
                    ),}
                } */
                options={{

                    actionsColumnIndex:-1,
                    actionsCellStyle:{width:"30%"},
                    exportButton:true,
                    exportFileName:"Asignación de citas",
                    
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    //columnsButton: true,
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    header: {
                        actions: ""
                    },
                    toolbar: {
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar",
                        searchTooltip: 'Buscar en la tabla',
                       
                        searchPlaceholder: "Buscar",
                        
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        //addTooltip: "Agregar",
                        /* editTooltip: "Editar",
                        deleteTooltip: "Dar de baja",
                        editRow: {
                            deleteText: "¿Esta seguro que desea dar de baja?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar"
                        }, */
                        emptyDataSourceMessage: 'No hay información por mostrar',

                    }
                }}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                   //console.log(newData,'pruebanewdata')
                               if (newData.diaexam !== undefined) {
                                    //     await axios.get(Global + "linea/nombre/" + newData.nombre,
                                    //         {
                                    //             headers: {
                                    //                 'Access-Control-Allow-Origin': "http://localhost:3000",
                                    //                 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                    //                 'Content-Type': 'application/json',
                                    //                 Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    //             }
                                    //         }).then(response => {
                                    //             if (response.data.length < 1) {
                                    axios.post(Global + "examenpro/tesis/" + idtesis,
                                        {
                                            estatus: "alta",
                                            tesis: {
                                                idtesis: ""
                                            },
                                            diaexam:    newData.diaexam,
                                            mesexam:    newData.mesexam,
                                            anioexam:   newData.anioexam,
                                            horaexam:   newData.horaexam,
                                            minutosexam: newData.minutosexam,
                                            aulavirtual: newData.aulavirtual,
                                            sedeexam:   newData.sedeexam,
                                            veredictoexam: newData.veredictoexam,
                                            fojas:      newData.fojas,
                                            libro:      newData.libro,
                                            actaexam:   newData.actaexam,
                                        }, {
                                        headers: {
                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then(response => {
                                        Swal({
                                            title: 'Examen Profesional Registrado',
                                            text: ' ',
                                            icon: 'success',
                                            button: false,
                                            timer: 1000
                                        })
                                        setData([...data, response.data]);
                                    }).catch(error => console.log(error))

                                    //             } else {

                                    //                 Swal({
                                    //                     title: 'Esta linea de investigacion ya existe',
                                    //                     text: ' ',
                                    //                     icon: 'error',
                                    //                     button: false,
                                    //                     timer: 1000
                                    //                 })

                                    //             }
                                    //         }).catch(error => console.log(error))


                                    } else {
                                    Swal({
                                        title: 'El nombre no puede estar vacío',
                                        text: ' ',
                                        icon: 'error',
                                        button: false,
                                        timer: 2000
                                    })
                                }
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData, rowData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                await axios.put(Global + "examenpro/" + oldData.idexamenpro,
                                    {
                                        tesis: {
                                            idtesis: ""
                                        },
                                        diaexam:  newData.diaexam,
                                        mesexam:  newData.mesexam,
                                        anioexam: newData.anioexam,
                                        horaexam:   newData.horaexam,
                                        minutosexam: newData.minutosexam,
                                        aulavirtual: newData.aulavirtual,
                                        sedeexam: newData.sedeexam,
                                        veredictoexam: newData.veredictoexam,
                                        fojas:    newData.fojas,
                                        libro:    newData.libro,
                                        actaexam: newData.actaexam,
                                    }, {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(response => {
                                    Swal({
                                        title: 'Examen Profesional Actualizado',
                                        text: ' ',
                                        icon: 'success',
                                        button: false,
                                        timer: 1000
                                    })
                                }).catch(error => console.log(error))


                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {

                                await axios.put(Global + "examenpro/id/" + oldData.idexamenpro,
                                    {
                                        estatus: "baja"

                                    }, {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(response => {
                                    // console.log(response.data);
                                    Swal({
                                        title: 'Examen Profesional Actualizado',
                                        text: ' ',
                                        icon: 'success',
                                        button: false,
                                        timer: 1000
                                    })
                                }).catch(error => console.log(error))


                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                resolve();
                            }, 1000);
                        })
                }}
            />
            :
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de Examen Profesional 
                        </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }

               
                options={{

                    actionsColumnIndex:-1,
                    actionsCellStyle:{width:"30%"},
                    exportButton:true,
                    exportFileName:"Asignación de citas",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    toolbar: {
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar",
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: "Buscar"
                    },
                   
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',

                    }
                }}
                columns={columns}
                data={data}

            />

    )
}

export default ExamenProfesional