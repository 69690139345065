import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { fba } from "../../firebaseConfig";
import { Auth } from "../../context/AuthContext";
import { withRouter } from "react-router-dom";
import Encabezado from "../generales/Encabezado";
import { Avatar } from "@material-ui/core";
import logo from "../../img/logoregistro.jpg";
import { useFormik } from "formik";
import Link from "@material-ui/core/Link";
import Footer from "../generales/Footer";
import Contrasena from "./Contraseña"; //Cambiar contraseña
import Swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  paperIma: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-8),
    backgroundColor: '#fff',
    width: "190px",
    height: "190px",
  },
  logo: {
    height: '160px',
    width: '120px'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  subtitulo: {
    fontSize: "25px",
    color: "#d5303e",
    /* color: "#54595F", */
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
},
}));

const Login = ({ history }) => {
  const classes = useStyles();
  const [password, setPassword] = useState(false);
  const { usuario } = useContext(Auth);

  useEffect(() => {

    if (usuario) {
      const firestore = fba.firestore();
      const db = firestore.collection("usuarios");
      db.where("correo", "==", usuario.email)
        .get()
        .then((snapshot) => {
          if (snapshot.docs[0]) {
            history.push("/inicio");
          } else {
            history.push("/home");
          }
        });
    }
  }, [history]);

  const formik = useFormik({
    initialValues: {
      email: "",
      contrasena: "",
    },
    onSubmit: async (valores) => {
      var acceso = false;
      await fba
        .auth()
        .signInWithEmailAndPassword(valores.email, valores.contrasena)
        .then((result) => {
          acceso = true;
        })
        .catch((error) => {
          Swal({
            title: "Correo o clave de acceso incorrecto.",
            text: " ",
            icon: "error",
            button: false,
            timer: 1000,
          });
        });

      if (acceso) {
        const firestore = fba.firestore();
        const db = firestore.collection("usuarios");
        db.where("correo", "==", valores.email)
          .get()
          .then((snapshot) => {
            if (snapshot.docs[0]) {
              // console.log(fba.auth().currentUser)
              window.localStorage.setItem(
                "refreshToken",
                fba.auth().currentUser.refreshToken
              );
              window.localStorage.setItem("token", fba.auth().currentUser._lat);
              history.push("/inicio");
            } else {
              Swal({
                title: "Correo o clave de acceso incorrecto.",
                text: " ",
                icon: "error",
                button: false,
                timer: 1000,
              });
              fba.auth().signOut();
              window.localStorage.removeItem("token");
              window.localStorage.removeItem("refreshToken");
            }
          });
      }
    },
  });


  return (
    <div style={{ background: "#034aa7de" }} className={classes.root}>
      <Encabezado />
      <br />

      {!password ? (
        <Container component="main" maxWidth="xs">
          <div className={classes.paperIma}>
            {/* <Avatar className={classes.avatar}>
              <img src={logo} alt="Logo UACh" className={classes.logo} />
            </Avatar> */}
          </div>
          <Card>
            <CardContent>
              <CssBaseline />
              <Typography align="center"  component="h1" variant="h5" className={classes.subtitulo}>
                SISTEMA INTEGRAL DE INFORMACIÓN DE POSGRADO CHAPINGO
              </Typography>
              <form
                className={classes.form}
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  autoFocus
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                  type="text"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="contrasena"
                  label="Clave de acceso"
                  type="password"
                  id="contrasena"
                  // autoComplete="current-password"
                  value={formik.values.contrasena}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Iniciar Sesión
                </Button>
              </form>

              <div className="link-container">
                <Link
                  href="#"
                  onClick={() => setPassword(true)}
                  variant="body2"
                >
                  ¿Olvidé mi contraseña?
                </Link>
              </div>
            </CardContent>
          </Card>
        </Container>
      ) : (
        <Contrasena setPassword={setPassword} />
      )}
      <Footer />
    </div>
  );
};

export default withRouter(Login);
