import React, { useEffect, useContext } from 'react'
import { Global } from '../../../constants/global';
import MaterialTable from 'material-table'
import axios from 'axios';
import Swal from 'sweetalert'
import DetallesUnidad from "./DetallesUnidad"
import { Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaUnidad() {

    const { useState } = React;
    const classes = useStyles();
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')
    const [recarga, setRecarga] = useState(false);

    const [columns, setColumns] = useState([
        { title: 'Nombre', field: 'nombre', validate: rowData => rowData.nombre === '' ? { isValid: false, helperText: 'El nombre de la unidad no puede estar vacío' } : true },
    ]);

    const [data, setData] = useState([]);

    useEffect(() => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }
                });
            });
        }

        axios.get(Global + "unidad/estatus/alta", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setData(response.data)
        }).catch(error => console.log(error))



    }, [recarga])


    return (
        rol === "coordinador_general" ?
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de unidades académicas
                                </Typography >
                            </Grid >
                        </Grid >
                    </Hidden >
                }
                options={{
                    exportButton: true,
                    exportFileName: "Listado de unidades académicas",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                        addTooltip: 'Agregar',
                        editTooltip: 'Editar',
                        deleteTooltip: 'Deshabilitada',
                        editRow: {
                            deleteText: '¿Desea inhabilitar esta unidad académica?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Guardar'
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones',
                    }


                }}
                columns={columns}
                data={data}
                detailPanel={
                    [
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary' },
                            tooltip: 'Información de la unidad académica',
                            render: rowData => {
                                return (
                                    <DetallesUnidad data={rowData} />
                                )
                            },
                        },
                    ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                // console.log(newData.nombre)
                                if (newData.nombre !== undefined) {
                                    var datos = {
                                        nombre: newData.nombre,
                                        estatus: "alta"
                                    }

                                    await axios.get(Global + "unidad/nombre/" + newData.nombre, {
                                        headers: {
                                            'Access-Control-Allow-Origin': "*",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then((response) => {
                                        // console.log(response.data.length)
                                        if (response.data.length === 0) {
                                            axios.post(Global + "unidad/", datos, {
                                                headers: {
                                                    'Access-Control-Allow-Origin': "http://localhost:3000",
                                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                }
                                            }).then(() => {
                                                setData([...data, datos]);
                                                Swal({
                                                    title: 'Unidad registrada',
                                                    text: ' ',
                                                    icon: 'success',
                                                    button: false,
                                                    timer: 1000
                                                })
                                                setRecarga(!recarga)
                                            }).catch(error => {
                                                console.log(error);
                                            })
                                        } else {
                                            Swal({
                                                title: 'El nombre de la unidad ya esta registrado',
                                                text: ' ',
                                                icon: 'error',
                                                button: false,
                                                timer: 1000
                                            })
                                        }
                                    }).catch(error => {
                                        console.log(error);
                                    })
                                } else {
                                    Swal({
                                        title: 'El nombre de la unidad no puede ser vacío',
                                        text: ' ',
                                        icon: 'error',
                                        button: false,
                                        timer: 1000
                                    })
                                }
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                await axios.get(Global + "unidad/nombre/" + newData.nombre, {
                                    headers: {
                                        'Access-Control-Allow-Origin': "*",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then((response) => {
                                    // console.log(response.data.length)
                                    if (response.data.length === 0) {
                                        axios.put(Global + "unidad/" + dataUpdate[index].idunidad, dataUpdate[index], {
                                            headers: {
                                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                'Content-Type': 'application/json',
                                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                            }
                                        }).then(() => {
                                            Swal({
                                                title: 'Información Actualizada',
                                                text: ' ',
                                                icon: 'success',
                                                button: false,
                                                timer: 1000
                                            })
                                            setData([...dataUpdate]);
                                        }).catch(error => {
                                            console.log(error);
                                        })
                                    } else {
                                        Swal({
                                            title: 'El nombre de la unidad ya esta registrado',
                                            text: ' ',
                                            icon: 'error',
                                            button: false,
                                            timer: 1000
                                        })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                })


                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                var changeData = {
                                    idunidad: dataDelete[index].idunidad,
                                    nombre: dataDelete[index].nombre,
                                    estatus: 'baja',
                                }
                                dataDelete.splice(index, 1);
                                await axios.put(Global + "unidad/" + changeData.idunidad, changeData, {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(() => {
                                    setData([...dataDelete]);
                                    Swal({
                                        title: 'Unidad deshabilitada',
                                        text: ' ',
                                        icon: 'success',
                                        button: false,
                                        timer: 1000
                                    })
                                }).catch(error => { console.log(error) })
                                resolve()

                            }, 1000)
                        }),
                }}

            />
            :
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de unidades académicas
                                </Typography >
                            </Grid >
                        </Grid >
                    </Hidden >
                }
                options={{
                    exportButton: true,
                    exportFileName: "Listado de unidades académicas",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                        addTooltip: 'Agregar',
                        editTooltip: 'Editar',
                        deleteTooltip: 'Deshabilitada',
                        editRow: {
                            deleteText: '¿Desea inhabilitar esta unidad académica?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Guardar'
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones',
                    }


                }}
                columns={columns}
                data={data}
                detailPanel={
                    [
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary' },
                            tooltip: 'Información de la unidad académica',
                            render: rowData => {
                                return (
                                    <DetallesUnidad data={rowData} />
                                )
                            },
                        },
                    ]
                }
            />


    )
}

export default TablaUnidad;