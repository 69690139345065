import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Swal from 'sweetalert'
import {FORMATOFECHAMINHOY, mayor} from "../../generales/Fecha"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "10%",
        marginRight: "10%",
    },
    avatar: {
        background: theme.palette.secondary.main,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    tableHeadd: {
        backgroundColor: '#66abe0b8',
        color: '#FFF'
    },
}));

function createData(periodo, fini, ffin) {
    return { periodo, fini, ffin };
}



function RegistroCartas() {

    const [values, setValues] = useState({
        fini: '',
        ffin: '',
        periodo: '',
    })
    const classes = useStyles();

    const [open2, setOpen2] = useState(false);
    const [change, setChange] = useState(false);
    const [infoTabla, setInfoTabla] = useState([]);
    const [carga, setCarga] = useState(false)
    const [data, setData] = useState([]);
    const rows = [];


    useEffect(async () => {

        let info = '';
        
        await axios.get(Global + "convocatoira", {
           
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            //console.log(response.data)
            if (response.data.length < 3) {
                info = response.data
            }
        }).catch(error => console.log(error));

        // setInfoCarta(carta)
        setInfoTabla(info);
         //console.log(rows)

    }, [change])


    if (infoTabla.length > 0) {
        
        infoTabla.map((info, index) => {
            rows[index] = createData(info.periodo, info.fecha_in, info.fecha_ter)
        });

    }

    //Schema de validacion
    const schemaValidacion = Yup.object().shape({
        fini: Yup.string().required("La fecha de inicio es obligatoria").test('match',
        'La fecha de inicio debe ser mayor a la fecha actual',
        function (fini) {
            return mayor(fini)
        }),
        ffin: Yup.string().required("La fecha de fin es obligatoria").test('match',
        'La fecha de fin debe ser mayor a la fecha de inicio',
        function (ffin) {
            if (ffin !== "")
                return ffin > this.parent.fini
        }),
        periodo: Yup.string().required('Es obligatorio ingresar el periodo'),
    })

    const handleClose2 = () => {
        setOpen2(false);
    }

    const initial = values

    const funcionSubmit = async (valores) => {
        setCarga(true)
        const convocatoria = {
            periodo: valores.periodo,
            fecha_in: valores.fini,
            fecha_ter: valores.ffin,
        }
        
        await axios.post(Global + "convocatoira", convocatoria, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).catch(error => console.log(error))
        setChange(change + 1)
        Swal({
            title: 'Datos registrados',
            text: ' ',
            icon: 'success',
            button: false,
            timer: 1000
        })
        setCarga(false)

    }

    return (
        <div >
            <Paper elevation={3} className={classes.paperContainer}>
                <Typography className={classes.subtitulo}>
                    Registro de convocatoria
                </Typography>
                <br />
                <Snackbar open={open2} autoHideDuration={2000} onClose={handleClose2}>
                    <Alert onClose={handleClose2} severity="error">
                        La fecha de inicio no debe ser mayor
                    </Alert>
                </Snackbar>
                <Formik
                    validationSchema={schemaValidacion}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        funcionSubmit(valores)
                    }}>
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Fecha de inicio"
                                            name="fini"
                                            id="fini"
                                            type="date"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.values.fini}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.fini && props.errors.fini ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.fini && props.errors.fini ? (
                                                props.errors.fini
                                            ) : null}
                                            InputProps={FORMATOFECHAMINHOY}
                                        />
                                        
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Fecha de fin"
                                            name="ffin"
                                            variant="outlined"
                                            type="date"
                                            required
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.values.ffin}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.ffin && props.errors.ffin ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.ffin && props.errors.ffin ? (
                                                props.errors.ffin
                                            ) : null}
                                            InputProps={FORMATOFECHAMINHOY}
                                        />
                                        
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={props.touched.periodo && props.errors.periodo ? (true) : false}>
                                            <InputLabel>Período</InputLabel>
                                            <Select
                                                native
                                                value={props.values.periodo}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Periodo"
                                                inputProps={{
                                                    name: 'periodo',
                                                    id: 'periodo',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={`P${new Date().getFullYear() + 1}`}>Primavera {new Date().getFullYear() + 1}</option>
                                                <option value={`O${new Date().getFullYear() + 1}`}>Otoño {new Date().getFullYear() + 1}</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.periodo && props.errors.periodo ? (props.errors.periodo) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                    </Grid>
                                </Grid>                                    
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <br />

                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={4}>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.boton}>
                                            Guardar
                                    </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }}
                </Formik>
            </Paper >

            <Paper>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHeadd}>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell>Período</TableCell>
                                <TableCell align="left">Fecha&nbsp;de&nbsp;inicio</TableCell>
                                <TableCell align="left">Fecha&nbsp;de&nbsp;fin</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                //console.log(row)
                                <TableRow key={index + 1}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left" component="th" scope="row">
                                        {row.periodo}
                                    </TableCell>
                                    <TableCell align="left">{row.fini}</TableCell>
                                    <TableCell align="left">{row.ffin}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    );
}

export default RegistroCartas;