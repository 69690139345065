import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import { Global } from "../../../constants/global"
import { Grid, Hidden, Typography } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";
import Swal from 'sweetalert'
import AgregarMateria from './AgregarMateria';
import axios from "axios";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function TablaMaterias(props) {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [recarga, setRecarga] = useState(false)
    const [scroll, setScroll] = React.useState('paper');
    const [columns, setColumns] = useState([
        { title: 'Clave', field: 'clave', validate: rowData => rowData.clave === '' ? { isValid: false, helperText: 'El campo clave no puede ser vacío' } : true },
        { title: 'Nombre', field: 'nombre', validate: rowData => rowData.nombre === '' ? { isValid: false, helperText: ' El campo nombre no puede ser vacío' } : true },
        { title: 'Descripción', field: 'descripcion' },
        {
            title: 'Créditos', field: 'credito', type: 'numeric', validate: rowData => rowData.credito < 1 ? { isValid: false, helperText: 'El numero de creditos debe ser mayor a 0' } : true,
        },
        { title: 'Hrs. teoría', field: 'teoria', type: 'numeric' },
        { title: 'Hrs. práctica', field: 'practica', type: 'numeric' },
    ]);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(() => {

        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        // console.log(doc.data())
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            // console.log("No se encuentra");
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "materia", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))
        }



        datos()
    }, [recarga])


    const handleClickOpen = (valores) => {
        setOpen(true)
    }

    const handleClose = (valor) => {
        if (valor) {
            setRecarga(!recarga)
        }
        setOpen(false)
    }

    return (

        rol === "coordinador_general" ?
            <>
                <Dialog
                    open={open}
                    scroll={scroll}
                    maxWidth='sm'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title" >
                        <Typography className={classes.subtitulo}>
                            Agregar nueva materia
                    </Typography>
                    </DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <AgregarMateria metodo={handleClose} />
                    </DialogContent>
                </Dialog>
                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de materias
                        </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>}
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de materias",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: "Buscar",
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            editRow: {
                                deleteText: "¿Esta seguro que desea dar de baja la materia?",
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Dar de baja"
                            },
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            deleteTooltip: "Dar de baja",

                        }
                    }}
                    columns={columns}
                    data={data}
                    actions={[
                        {
                            icon: 'add_box',
                            tooltip: 'Agregar',
                            isFreeAction: true,
                            onClick: (event) => handleClickOpen()
                        }
                    ]}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    // console.log(dataUpdate[index])
                                    setData([...dataUpdate]);
                                    await axios.put(Global + "materia/" + dataUpdate[index].idmateria, dataUpdate[index], {
                                        headers: {
                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then(response => {
                                        // console.log(response);
                                        Swal({
                                            title: 'Información Actualizada',
                                            text: ' ',
                                            icon: 'success',
                                            button: false,
                                            timer: 1000
                                        })
                                    }).catch(error => {
                                        console.log(error);
                                    })


                                    resolve();
                                }, 1000)
                            })
                    }}
                />
            </>
            :
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Listado de materias
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    exportButton:true,
                    exportFileName:"Listado de materias",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: "Buscar",
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },

                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {

                        emptyDataSourceMessage: 'No hay información por mostrar',
                    }
                }}
                columns={columns}
                data={data}
            />

    )
}
export default TablaMaterias
