import React, { useEffect, useState, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import { Button, makeStyles } from '@material-ui/core';
import Swal from 'sweetalert'
import { FormControl, InputLabel, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";

const useStyles = makeStyles((theme) => ({
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaAsociacion(props) {
    const classes = useStyles();
    const { persona } = props.data;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    const [asociaciones, setAsociaciones] = useState([])
    const [id, setId] = React.useState('');
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')
    const [columns, setColumns] = useState([
        {
            title: 'Asociación', field: 'asociacion.asociacion'
        },
        {
            title: 'Detalle', field: 'asociacion.detalle'
        },
        {
            title: 'Web', field: 'asociacion.web'
        },
    ]);

    useEffect(() => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }
                });
            });
        }

        axios.get(Global + "asociacion/persona/" + persona.idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response)
            setAsociaciones(response.data)
        }).catch(error => console.log(error))

        axios.get(Global + "personaso/persona/" + persona.idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response)
            setData(response.data)
        }).catch(error => console.log(error))

    }, [])

    const handleCancel = () => {
        setOpen(false)
        setId('')
    };

    const handleOnChange = (e) => {
        setId(e.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
        setId('')
    };

    const handleSubmit = () => {
        if (id !== '') {
            axios.post(Global + "personaso/persona/" + persona.idP + "/asociacion/" + id,
                {
                    estatus: 'alta'
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(() => {
                    setId('')
                    axios.get(Global + "asociacion/persona/" + persona.idP, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response)
                        setAsociaciones(response.data)
                    }).catch(error => console.log(error))

                    axios.get(Global + "personaso/persona/" + persona.idP, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response)
                        setData(response.data)
                    }).catch(error => console.log(error))


                    Swal({
                        title: 'Asociacion agregada',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })

                }).catch(error => console.log(error))


        } else {
            Swal({
                title: '¡Debe seleccionar una asociación!',
                text: ' ',
                icon: 'error',
                button: false,
                timer: 1000
            })
        }
    }


    return (
        rol === "administrador" ||
            rol === "coordinador_general" ||
            rol === "coordinador_posgrado" ?
            <>
                <MaterialTable
                    style={{
                        box_shadow: 'none'
                    }}
                    options={{
                        exportButton:true,
                        exportFileName:"Asociación",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea inhabilitar este docente?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones',
                        }

                    }}
                    title={
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Asociación
                            </Typography>
                            </Grid>
                        </Grid>
                    }
                    columns={columns}
                    data={data}
                    actions={[
                        {
                            icon: 'add_box',
                            tooltip: 'Agregar',
                            isFreeAction: true,
                            onClick: () => handleClickOpen()
                        }
                    ]}
                />
                <Dialog open={open}>
                    <DialogTitle style={{ marginBottom: -10 }} id="customized-dialog-title">
                        <DialogContentText style={{ marginBottom: -10 }}>Seleccione la asociacion que desea agregar</DialogContentText>
                    </DialogTitle>
                    <DialogContent>
                        <br />
                        <FormControl
                            variant="outlined"
                            fullWidth
                            required>
                            <InputLabel id="asociacion">Asociacion</InputLabel>
                            <Select
                                labelId="asociacion"
                                native
                                label="asociacion"
                                onChange={e => {
                                    handleOnChange(e);
                                    // alert(e.target.value)
                                }}
                                inputProps={{
                                    name: 'asociacion',
                                    id: 'asociacion',
                                }}
                            >
                                <option aria-label="None" value="" />{
                                    asociaciones.map((asociacion) => {
                                        return <option key={asociacion.idasociacion} value={asociacion.idasociacion}>{asociacion.asociacion}</option>
                                    })
                                }

                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} color="primary">
                            Cancelar
                    </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Guardar
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
            :
            <MaterialTable
                style={{
                    box_shadow: 'none'
                }}
                options={{
                    exportButton:true,
                    exportFileName:"Asociación",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                    },
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones',
                    }

                }}
                title={
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Asociación
                            </Typography>
                            </Grid>
                        </Grid>
                    }
                columns={columns}
                data={data}

            />
    )
}

export default TablaAsociacion;