import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { fba } from '../../firebaseConfig';
import { Auth } from '../../context/AuthContext';
import { withRouter } from 'react-router-dom';
import Encabezado from '../generales/Encabezado';
import Footer from './Footer';
import { Avatar } from '@material-ui/core';
import logo from '../../img/logoregistro.jpg';
import { useFormik } from 'formik';
import axios from 'axios';
import { Global } from '../../constants/global';
import Swal from 'sweetalert'
import Mensaje from '../aspirantes/Mensaje'

const useStyles = makeStyles((theme) => ({
    paperIma: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-8),
        backgroundColor: '#fff',
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(2, 0, 0),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    logo: {
        height: '160px',
        width: '120px'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#d5303e",
        /* color: "#54595F", */
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const Login = ({ history }) => {

    const classes = useStyles();
    const { usuario } = useContext(Auth);
    const [mensaje, setMensaje] = useState(true)
    const [fecha, setFecha] = useState(false)
    const [convocatoria, setConvocatoria] = useState(0)

    useEffect(() => {

        const datos = async () => {
            await axios.get(Global + "convocatoira/vigente", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                setConvocatoria(response.data[0].idConvocatoria)
                if (response.data.length < 1) {
                    setFecha(true);
                } else {
                    if (usuario) {
                        const firestore = fba.firestore();
                        const db = firestore.collection('usuarios');
                        db.where('correo', '==', usuario.email).get().then(snapshot => {
                            if (snapshot.docs[0]) {
                                history.push("/inicio");
                            } else {
                                history.push("/home");
                            }
                        });
                    }
                }

            }).catch(error => console.log(error))
        }

        datos()

    }, [history]);

    const formik = useFormik({
        initialValues: {
            email: '',
            contrasena: ''
        },

        onSubmit: async valores => {

            await fba.auth()
                .signInWithEmailAndPassword(valores.email, valores.contrasena)
                .then(result => {
                    const firestore = fba.firestore();
                    const db = firestore.collection('usuarios');
                    db.where('correo', '==', valores.email).get().then(snapshot => {
                        // console.log(snapshot.docs)
                        if (snapshot.docs.length === 0) {
                            window.localStorage.setItem('token', result.user._lat);

                            if (window.localStorage.getItem('token') !== null) {
                                axios.get(Global + "persona/correo/" + valores.email, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                    }
                                }).then(response => {
                                    if (response.data.length > 0) {
                                        //Si existe 
                                        axios.get(Global + "aspirante/personas/" + response.data[0].idP, {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                            }
                                        }).then(responses => {
                                            if (convocatoria === responses.data[0].convocatoria.idConvocatoria ) {
                                                 if (responses.data[0].estatus !== 'Registro') {
                                                //existe = 0;
                                                setMensaje(false)
                                                fba.auth().signOut()
                                                window.localStorage.removeItem('token')
                                            } else {
                                                history.push("/home");
                                            }
                                        }else {
                                            fba.auth().signOut()
                                            window.localStorage.removeItem('token')
                                        }
                                        }).catch(error => console.log(error));
                                    } else {
                                        Swal({
                                            title: 'El usuario no está registrado.',
                                            text: ' ',
                                            icon: 'error',
                                            button: false,
                                            timer: 1000
                                        })
                                        fba.auth().signOut()
                                        window.localStorage.removeItem('token')
                                    }
                                }).catch(error => console.log(error));
                            }
                            else {
                                Swal({
                                    title: 'Usuario incorrecto.',
                                    text: ' ',
                                    icon: 'error',
                                    button: false,
                                    timer: 1000
                                })
                            }

                        } else {
                            // console.log("entra al else")
                            Swal({
                                title: 'Acceso restringido',
                                text: ' ',
                                icon: 'error',
                                button: false,
                                timer: 1000

                            })
                            fba.auth().signOut()
                            window.localStorage.removeItem('token')
                        }
                    })

                }).catch(error => {
                    // console.log(error)
                    Swal({
                        title: 'Usuario no existe',
                        text: ' ',
                        icon: 'error',
                        button: false,
                        timer: 1000

                    })
                });
        }
    });

    const handleRegistro = () => {
        history.push("/alta");
    }

    return (
        <div style={{ background: '#034aa7de' }} className={classes.root}>

            <Encabezado />

            {fecha === true ?
                <Mensaje titulo="Registro de aspirantes
                " mensaje="Por el momento no hay una convocatoria vigente." />
                : mensaje ?
                    <Container component="main" maxWidth="xs" >
                        <div className={classes.paperIma}>
                            {/* <Avatar className={classes.avatar}>
                                <img src={logo} alt="Logo de UACh" className={classes.logo} />
                            </Avatar> */}
                        </div>

                        <Card >
                            <CardContent>
                                <CssBaseline />
                                <Typography align="center" component="h1" variant="h5" className={classes.subtitulo}>
                                SISTEMA DE REGISTRO ELECTRÓNICO DE ASPIRANTES
                                </Typography>
                                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Correo electrónico"
                                        autoFocus
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && formik.errors.email ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.email && formik.errors.email ? (
                                            formik.errors.email
                                        ) : null}
                                        type='text'

                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="contrasena"
                                        label="Clave de acceso"
                                        type="text"
                                        id="contrasena"
                                        // autoComplete="current-password"
                                        value={formik.values.contrasena}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                    <br />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={handleRegistro}
                                    >
                                        Registrar Usuario Nuevo 
                                    </Button>


                                </form>
                            </CardContent>
                        </Card>

                    </Container >
                    : <Mensaje titulo="Registro cerrado" mensaje="Su registro ya ha sido completado, por favor espere a ser contactado en base a sus resultados" />
            }
            <br />
            <Footer />
        </div>
    );
}

export default withRouter(Login);