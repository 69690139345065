import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../img/logoregistro.jpg';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperIma: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-12),
        backgroundColor: theme.palette.secondary,
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    back: {
        background: 'linear-gradient(0deg, #003366 5%, #FFFFFF 100%)',
    },
    titulo: {
        marginTop: theme.spacing(8),
    },
    logo: {
        height: '160px',
        width: '120px'
    }
}));


const Mensaje = (props) => {
    const classes = useStyles();
    return (
        <Container maxWidth="xs" style={{ padding: '10px' }}>
            <div className={classes.paperdir} >
                <div className={classes.paperIma}>
                    <Avatar className={classes.avatar}>
                        <img src={logo} alt="" className={classes.logo} />
                    </Avatar>
                </div>
                <Paper elevation={3} className={classes.paper} >

                    <Typography variant="h4" align="center" className={classes.titulo}>
                        {props.titulo}
                        <hr />
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                {props.mensaje}
                                <hr />
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Container>
    );
}

export default Mensaje;