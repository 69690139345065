import React, { useEffect, useState, useContext } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import logo from '../../img/logo.png'
import logoposgrado from '../../img/logoposgrado.jpg';
import MenuBurger from '../siip/Menu';
import { fba } from '../../firebaseConfig'
import theme from '../../styles/globalStyles'
import { Auth } from "../../context/AuthContext";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Global } from '../../constants/global';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import { Divider } from '@material-ui/core';
// import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    logo: {
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
        marginLeft: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        color: '#fffc'
    },
    divider: {
        color: 'white',
        backgroundColor: 'rgb(255 255 255 / 80%)',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));


export default function PrimarySearchAppBar({ history }) {
    const classes = useStyles();
    const [admin, setAdmin] = useState(false)
    const { usuario } = useContext(Auth);

    useEffect(() => {
        // console.log(usuario)
        if (usuario !== null) {
            const firestore = fba.firestore();
            const db = firestore.collection('usuarios');
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                // console.log(snapshot.docs[0])
                if (snapshot.docs[0]) {
                    setAdmin(true)
                } else {
                    history.push("/registro");
                }
            });
        }
    }, [history, usuario]);

    useEffect(async () => {
        const refrescar = async () => {
            if (window.localStorage.getItem('refreshToken') !== null) {
                await axios.get(Global + "direccion/prueba", {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    // console.log(response)
                }).catch(error => {
                    // console.log(error)
                    // console.log('esho')
                    axios.post("https://securetoken.googleapis.com/v1/token?key=AIzaSyDbEjkKzEgL09oCVFuhcD_T6C5MvrIksn4",
                        {
                            grant_type: 'refresh_token',
                            refresh_token: window.localStorage.getItem("refreshToken")
                        })
                        .then((response) => {
                            // console.log(response);
                            window.localStorage.setItem('refreshToken', response.data.refresh_token);
                            window.localStorage.setItem('token', response.data.access_token);
                        })
                })
            }
        }
        await refrescar();
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleCerrar = () => {

        fba.auth().signOut()
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refreshToken')
    }



    const menuId = 'primary-search-account-menu';


    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}

            <MenuItem component={Link} to="/siip/cuenta">Configura tu cuenta</MenuItem>
            <MenuItem onClick={handleCerrar}>Cerrar sesión</MenuItem>

        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notificaciones</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Perfil</p>
            </MenuItem>
        </Menu>
    );


    return (
        admin ?
            <>
                <ThemeProvider theme={theme}>
                    <AppBar position="absolute" color='primary'>
                        <Toolbar>
                            <MenuBurger usuario={usuario} className={classes.menuButton} />
                            {/* <MenuIcon /> */}
                            <img src={logo} className={classes.logo} style={{ height: '53px', width: '200px' }} alt="" />
                            <div className={classes.grow} />
                            <div>
                                {/* <Typography className={classes.title} variant="h6" >
                                    POSGRADO CHAPINGO
                                </Typography> */}
                                <img src={logoposgrado} className={classes.logoposgrado} style={{ height: '53px', width: '200px' }} alt="" />
                                <div className={classes.grow} />
                            </div>
                            <div className={classes.sectionDesktop}>
                                {/* <IconButton aria-label="show 17 new notifications" color="inherit">
                                    <Badge badgeContent={17} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton> */}
                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle fontSize="large" />
                                </IconButton>
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {renderMobileMenu}
                    {renderMenu}
                    <div className={classes.offset}></div>
                </ThemeProvider>
            </> : null
    );
}
