import React, { useEffect, useState, useContext } from "react";
import MaterialTable from "material-table";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Global } from '../../../constants/global';
import axios from "axios";
import AgregarPredoctoral from './AgregarPredoctoral';
import ActualizarPredoctoral from './ActualizarPredoctoral';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaActualizacion(props) {

    const classes = useStyles();
    const { idalumno } = props.data
    const [recarga, setRecarga] = useState(true);
    const [nuevo, setNuevo] = useState(false);
    const [data, setData] = useState([]);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(() => {

        const datos = async () => {
            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            return null;
                        }
                    });
                });
            }
            await axios.get(Global + "predoctoral/alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))
        }

        datos();

    }, [recarga])

    const [columns, setColumns] = useState([
        { title: 'Fecha', field: 'fecha' },
        { title: 'Oportunidad', field: 'oportunidad' },
        { title: 'Dictamen', field: 'dictamen' },
    ])

    const actualiza = () => {
        setRecarga(!recarga)
    }

    const cambioVista = () => {
        setNuevo(!nuevo)
    }

    const actualizarCambio = () => {
        actualiza()
        cambioVista()
    }

    return (
        nuevo ?
            <AgregarPredoctoral data={props.data} metodo={actualizarCambio} />
            :
            rol === "administrador" ||
            rol === "coordinador_general" ||
            rol === "coordinador_posgrado"  ?
                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de predoctoral
                                </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    }
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de predoctoral",
                        search: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'

                        }
                    }}
                    detailPanel={[
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary' },
                            tooltip: 'Información del predoctoral',
                            render: rowData => {
                                return (
                                    <ActualizarPredoctoral data={rowData} metodo={actualiza} rol={rol}/>
                                )
                            },
                        }
                    ]}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea eliminar la adscripcion del profesor?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones'
                        },
                    }}
                    columns={columns}
                    data={data}
                    actions={
                        [
                            {
                                icon: 'add_box',
                                tooltip: 'Agregar',
                                isFreeAction: true,
                                onClick: (event) => cambioVista()
                            }
                        ]}
                />
                :
                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de predoctoral
                            </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    }
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de predoctoral",
                        search: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'

                        }
                    }}
                    detailPanel={[
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary' },
                            tooltip: 'Información del predoctoral',
                            render: rowData => {
                                return (
                                    <ActualizarPredoctoral data={rowData} metodo={actualiza} rol={rol} />
                                )
                            },
                        }
                    ]}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea eliminar la adscripcion del profesor?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones'
                        },
                    }}
                    columns={columns}
                    data={data}
                />
    );
}

export default TablaActualizacion