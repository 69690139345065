import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Divider from '@material-ui/core/Divider';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperContainer: {
       // display:'flex',
        // flexDirection:'column',
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        marginLeft: "20px",
        marginRight: "20px",
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-12),
        backgroundColor: theme.palette.secondary,
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    back: {
        background: 'linear-gradient(0deg, #003366 5%, #FFFFFF 100%)',
    },
}));



function LugarNacimiento({ persona }) {

    //
    const [datosAnterior, setDatosAnterior] = useState({
        nacimiento: '',
        gen: '',
        civil: '',
        etnico: '',
        paisNac: '',
        estadoNac: '',
        ciudadNac: '',
        rfc: '',
        curp: '',
        curpDoc: '',
        foto: '',
        acta: '',
    })

    const [nacimiento, setNacimiento] = useState(true);

    //Archivos
    const [linkCurp, setCurp] = useState(null)
    const [linkFoto, setFoto] = useState(null)
    const [linkActa, setActa] = useState(null)


    useEffect(async () => {
        //Conseguir datos de persona(persona)
        await axios.get(Global + "persona/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async response => {
            setDatosAnterior({
                gen: response.data.genero,
                civil: response.data.estado_civil,
                rfc: response.data.rfc,
                curp: response.data.curp,
                nacimiento: response.data.fecha_na,
                curpDoc: response.data.doc_curp,
                foto: response.data.fotografia,
                acta: response.data.acta_nac
            })
            if (response.data.doc_curp !== null && response.data.doc_curp !== "")  {
                // alert(response.data.doc_curp)
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data.doc_curp)
                setCurp(await httpsReference.getDownloadURL());
            }
            if (response.data.fotografia !== null && response.data.fotografia !== "") {
                let storage2 = fba.storage().ref();
                let httpsReference2 = storage2.child('' + response.data.fotografia)
                setFoto(await httpsReference2.getDownloadURL());
            }
            if (response.data.acta_nac !== null && response.data.acta_nac !== "") {
                let storage3 = fba.storage().ref();
                let httpsReference3 = storage3.child('' + response.data.acta_nac)
                setActa(await httpsReference3.getDownloadURL());
            }
        }).catch(error => console.log(error));

        //Conseguir datos de etnia(personad)
        await axios.get(Global + "personaed/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data) {
                setDatosAnterior({
                    etnico: response.data[0].etnia.etnia
                })
            }
        }).catch(error => console.log(error));

        // Conseguir datos lugar de nacimiento(lugar_nacim)
        await axios.get(Global + "lugarna/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setDatosAnterior({
                paisNac: response.data[0].pais.pais,
                estadoNac: response.data[0].estado,
                ciudadNac: response.data[0].ciudad,
            })
        }).catch(error => console.log(error));



        // console.log(httpsReference)



    }, []);

    const classes = useStyles();
    const initial = datosAnterior;


    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initial}>
                {props => {
                    return (
                        <Paper elevation={3} className={classes.paperContainer}>
                            <br />
                            <Typography variant="h5" align="left" display="initial">
                                Registro de aspirantes
                                        <hr />
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Fecha de nacimiento"
                                        id="nacimiento"
                                        type="date"
                                        value={props.values.nacimiento}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Fecha de Nacimiento"
                                        id="nacimiento"
                                        name="nacimiento"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={props.values.nacimiento}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.touched.nacimiento && props.errors.nacimiento ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.nacimiento && props.errors.nacimiento ? (
                                            props.errors.nacimiento
                                        ) : null} 
                                    />
                                </Grid> */}

                                <Grid
                                    item
                                    xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Género"
                                        id="gen"
                                        name="gen"
                                        type="text"
                                        value={props.values.gen}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        disabled
                                        fullWidth
                                        id="civil"
                                        name="civil"
                                        label="Estado civil"
                                        value={props.values.civil}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </Grid>

                                <Grid

                                    item
                                    xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        disabled
                                        fullWidth
                                        id="etnico"
                                        name="etnico"
                                        label="Grupo Étnico"
                                        value={props.values.etnico}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        disabled
                                        fullWidth
                                        id="paisNac"
                                        name="paisNac"
                                        label="País"
                                        value={props.values.paisNac}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    item
                                    xs={12} sm={6}>

                                    <Grid
                                        item xs={12} sm={8}>
                                        Acta de nacimiento
                                        <Grid item xs={10} sm={8}>
                                        {linkActa !== '' && linkActa !== null ?
                                            <a style={{ display: "table-cell" }} href={linkActa} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {nacimiento ? (
                                    <>
                                        <Grid
                                            item
                                            xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                disabled
                                                fullWidth
                                                id="estadoNac"
                                                name="estadoNac"
                                                label="Estado"
                                                value={props.values.estadoNac}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid

                                            item
                                            xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                disabled
                                                fullWidth
                                                id="ciudadNac"
                                                name="ciudadNac"
                                                label="Ciudad"
                                                value={props.values.ciudadNac}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Divider />

                                        <Grid
                                            item
                                            xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                disabled
                                                fullWidth
                                                id="rfc"
                                                name="rfc"
                                                label="RFC"
                                                value={props.values.rfc}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                label="CURP"
                                                id="curp"
                                                name="curp"
                                                fullWidth
                                                disabled
                                                value={props.values.curp}
                                                type='text'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>


                                        {/*Documentos curp y foto*/}
                                        <Grid
                                            container
                                            direction="column"
                                            item
                                            xs={12} sm={6}>
                                            CURP
                                            <Grid item xs={10} sm={8}>
                                            {linkCurp !== '' && linkCurp !== null ?
                                            <a style={{ display: "table-cell" }} href={linkCurp} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                            </Grid>



                                        </Grid>

                                    </>) : null}

                                <Grid container
                                    direction="column"
                                    item
                                    xs={12} sm={6}>
                                    <Grid item xs={10} sm={8}>
                                        Foto
                                        <Grid item xs={10} sm={8}>
                                        {linkFoto !== '' && linkFoto !== null ?
                                            <a style={{ display: "table-cell" }} href={linkFoto} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>

                                    </Grid>


                                </Grid>

                            </Grid>

                            <br />
                        </Paper>
                    )
                }}

            </Formik>
        </div >
    );
}

export default LugarNacimiento;