import React, {useState,useEffect} from 'react';
import { Formik,Form } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Box,
    TextField,
    CircularProgress,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    FormHelperText,
    Button,
    Typography,
    makeStyles,

} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Swal from "sweetalert";
import axios from "axios";
import { fba } from "../../../firebaseConfig";
import { Global } from "../../../constants/global";
import TablaPermisos from "./TablaPermisos";

//********************Filtros para componente Autocomplete********************************* */
const filterOptions = createFilterOptions({
    ignoreAccents: true,
    ignoreCase: true,
    limit: 5,
    trim: true,
  });
//***************************************************** */

//********************Para ajustar el tamaño de las ventanas de los selects si hay muchas opciones********************************* */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
PaperProps: {
    style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
    },
},
};
//***************************************************** */
const arrayOptionsRoles = [
{ value: "administrador", label: "ADMINISTRADOR DE LA APLICACIÓN" },
{ value: "profesor", label: "ACADÉMICO" },
{ value: "coordinador_general", label: "ACADÉMICO-COORDINADOR GENERAL DE POSGRADO" },
/* {value: "administrativo_coordinacion", label:"Personal Administrativo de Coordinación General"}, */
{ value: "coordinador_posgrado", label: "ACADÉMICO-COORDINADOR DE PROGRAMA" },
 {value: "evaluador_aspirante",label:"ACADÉMICO-EVALUADOR ASPIRANTE"},

{ value: "alumno", label: "ALUMNO" },
{
  value: "asistente_coordinador_general",
  label: "ADMINISTRATIVO COORDINACIÓN",
},
  {
    value: "asistente_coordinador_posgrado", label: "ADMINISTRATIVO DE PROGRAMA",
},

];

const arrayOptionsAdmin = [{ id: "1", value: "0", label: "Sin posgrado" }];

const formValidation = Yup.object().shape({

correo: Yup.string()
    .required("Selecciona un usuario"),
rol: Yup.string()
    .required("Selecciona un rol"),
});

function showAlert(title,text, icon) {
    Swal({
      title: title,
      text: text,
      icon: icon,
      button: false,
      timer: 2000,
    });
  }

const useStyles = makeStyles((theme) => ({

    subtitulo: {
      fontSize: "25px",
      color: "#54595F",
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600,
      textAlign: "center",
      marginBottom: "20px",
    },

    boton: {
      fontFamily: { Poppins: "SansSerif" },
      fontSize: "15px",
      fontWeight: "500",
      backgroundColor: "#034AA7",
      borderRadius: "4px 4px 4px 4px",
    },
  }));

export default function FormRoles(stateCheckbox){

const [usersArray, setArrayUsers] = useState([]);
const [openautocomplete, setOpenAutocomplete] = useState(false);
const loadingArrayUsers = openautocomplete && usersArray.length === 0;

const [openposgraduate, setOpenPostgraduate] = useState(false);
const [userFullName, setFullName] = useState({
    userName: "",
    lastName: "",
    mothersLastName: "",
    correo: "",
});
//arrayPostStudies es la lista de posgrados
const [arrayPostStudies, setArrayPostStudies] = useState([]);
const [clearAutocomplete, setClearAutocomplete] = useState(true);
const [posgradosAsignados,setPosgradosAsignados] = useState([]);
const classes = useStyles();
  //*********************Para abrir la ventana del Autocomplete si hay caracteres******************************** */
  const handleInputChange = (newInputValue) => {
    if(newInputValue.target.value !== ""){
      setFullName(newInputValue.target.value);
      setOpenAutocomplete(true)

    }else{
      setFullName("");
      setOpenAutocomplete(false);
    }
  };
  //***************************************************** */
  function submitRoles(values,actions){
    const firebasedb = fba.firestore();
    const esRolAdmin = values.rol === "coordinador_general"
    || values.rol === "evaluador_aspirante"
    || values.rol === "administrador" ? 0:posgradosAsignados;
    let emailExistsOnFireBase = false;
    //setClearAutocomplete nos permite limpiar el componente Autocomplete despues del submit
    setClearAutocomplete(false);
    setPosgradosAsignados([]);
    setTimeout(() => {
      actions.setSubmitting(false);
      actions.resetForm(
        {
          values: {
            correo: "",
            rol: "",
            posgrado: "",
          },
        },
        //si no se setea otra vez, no vuelve a cambiar de valor
        setClearAutocomplete(true)
        );
      }, 1);

    firebasedb
      .collection("usuarios")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().correo.includes(userFullName.correo)) {
            firebasedb.collection("usuarios").doc(doc.id).update({
              rol: values.rol,
              posgrado: esRolAdmin,
            });
            showAlert("Usuario actualizado con éxito", "","success");
            emailExistsOnFireBase = true;
          }
        });

        if (!emailExistsOnFireBase) {
          const year = new Date().getFullYear();

          firebasedb.collection("usuarios").add({
            correo: userFullName.correo,
            posgrado: esRolAdmin,
            rol: values.rol,
          });

          showAlert("Usuario actualizado con éxito", "","success");

          fba
            .auth()
            .createUserWithEmailAndPassword(userFullName.correo, "uach" + year);
        }
      })
      .catch((error) => {
        showAlert("Usuario no actualizado", 'Ha ocurrido un inesperado error.Intente más tarde',"error");
      });

  }

  const handleChangePosgrados = (e)=>{

    setPosgradosAsignados(e.target.value)
  }

    //**********************Peticion para obtener usuarios******************************* */
    useEffect(() => {
        if (userFullName.userName !== undefined) {
          let activeAutocomplete = true;
          if (!loadingArrayUsers) {
            return undefined;
          }
          //para que no mande cadenas vacias y devuelva error en consola
          if (userFullName.userName !== "") {
            (async () => {
              await axios
                .get(
                  Global +
                    "persona/nombres/" +
                    userFullName.userName +
                    "/apellido/" +
                    userFullName.lastName +
                    "/apellidom/" +
                    userFullName.mothersLastName,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                      "Content-Type": "application/json",
                      Authorization:
                        "Bearer " + window.localStorage.getItem("token"),
                    },
                  }
                )
                .then((response) => {
                  const arrayResponseUsers = response.data;
                  if (activeAutocomplete && userFullName.userName !== undefined) {
                    setArrayUsers(
                      Object.keys(arrayResponseUsers).map(
                        (key) => arrayResponseUsers[key]
                      )
                    );
                  }
                })
                .catch((error) => {
                });
            })();
          }
          return () => {
            activeAutocomplete = false;
          };
        }
      }, [loadingArrayUsers, userFullName.userName]);
      //***************************************************** */
      //**********************Peticion para obtener posgrados******************************* */
      useEffect(() => {
        if (openposgraduate) {
          (async () => {
            await axios
              .get(Global + "programa", {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + window.localStorage.getItem("token"),
                },
              })
              .then((response) => {
                const respuestaProgramas = response.data;
                setArrayPostStudies(
                  Object.keys(respuestaProgramas).map(
                    (key) => respuestaProgramas[key]
                  )
                );
              })
              .catch((error) => {
              });
          })();
        }
      }, [openposgraduate]);
      //***************************************************** */
      //***********************Muestra los usuarios cuando el Autocomplete esta activo****************************** */
      useEffect(() => {
        if (!openautocomplete) {
          setArrayUsers([]);
        }
      }, [openautocomplete]);
      //***************************************************** */
      const assignPosgrado = ({values}) => {
        if((values.rol !== "administrador"
            && values.rol !== "coordinador_general"
            && values.rol !== "evaluador_aspirante"
            && posgradosAsignados.includes('0'))
            || (values.rol === "administrador" && !posgradosAsignados.includes('0'))
            || (values.rol === "coordinador_general" && !posgradosAsignados.includes('0'))
            || (values.rol === "evaluador_aspirante" && !posgradosAsignados.includes('0'))
          ){
            return false
          }else{
            return true
          }
      }

    return (
        <Formik
          enableReinitialize
          validationSchema={formValidation}
          initialValues={{correo:'',rol:'',posgrado:''}}
          onSubmit={(values,actions) => {
              submitRoles(values,actions);
          }}>
        {formik => {
          return (
          <Form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={10} md={8} lg={8} xl={6}>
              <Typography variant="h5" className={classes.subtitulo}>
                Asignación de Roles
              </Typography>

              <Grid container spacing={8} justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    error={
                      formik.touched.correo && Boolean(formik.errors.correo)
                    }
                    required
                    fullWidth
                  >
                    <Autocomplete
                      key={clearAutocomplete}
                      id="correo"
                      name="correo"
                      filterOptions={filterOptions}
                      open={openautocomplete}
                      //para que muestre el nombre completo del usuario y su correo
                      //y evitar confusiones si hay nombres completos iguales
                      options={usersArray.map(
                        (option) =>
                          option.nombre +
                          " " +
                          option.apellidoP +
                          " " +
                          option.apellidoM +
                          "-." +
                          option.correo
                      )}
                      onInputChange={handleInputChange}
                      onOpen={() => {
                        setOpenAutocomplete(true);
                      }}
                      onClose={() => {
                        setOpenAutocomplete(false);
                      }}
                      getOptionSelected={(option, values) =>
                        option.nombre === values.nombre
                      }
                      onBlur={formik.handleBlur}
                      //este onchange recibe el nombre completo
                      //setFullName es necesario para guardar el nombre completo del usuario que
                      //sera asignado
                      onChange={(e, values) => {
                        formik.setFieldValue(
                          "correo",
                          values !== null ? values : formik.initialValues.correo
                        );
                        var strCorreo = values !== null ? values : "";
                        let splitCorreo = strCorreo.split("-.")[1]
                        const newUs = {
                          userName: values,
                          lastName: values,
                          mothersLastName: values,
                          correo: splitCorreo,
                        };
                        setFullName(newUs);
                      }}
                      renderInput={(params) => (
                        <FormControl fullWidth>
                          <TextField
                            {...params}
                            error={
                              formik.touched.correo &&
                              Boolean(formik.errors.correo)
                            }
                            label="Usuario"
                            required
                            variant="outlined"
                            name="correo"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              endAdornment: (
                                <React.Fragment>
                                  {loadingArrayUsers ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            //este onChange detecta el valor que escriba el usuario,
                            onChange={(e) => {
                              const val = params.inputProps.value;

                              setFullName((prevState) => {
                                return Object.assign({}, prevState, {
                                  userName: val,
                                  lastName: val,
                                  mothersLastName: val,
                                });
                              });
                            }}
                          ></TextField>
                        </FormControl>
                      )}
                    />

                    <FormHelperText>
                      {formik.touched.correo && formik.errors.correo}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={8} justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant="outlined"
                    error={formik.touched.rol && Boolean(formik.errors.rol)}
                    required
                    fullWidth
                  >
                    <InputLabel id="labelRol">Rol</InputLabel>
                    <Select
                      label="Rol"
                      name="rol"
                      onChange={(e)=>{
                        formik.setFieldValue('rol',e.target.value)
                        if(assignPosgrado(formik)){
                          setPosgradosAsignados([])
                        }
                      }}
                      value={formik.values.rol}
                      onBlur={formik.handleBlur}
                      MenuProps={MenuProps}
                    >
                      {arrayOptionsRoles.map((rol) => (
                        <MenuItem key={rol.value} value={rol.value}>
                          {rol.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.rol && formik.errors.rol}
                    </FormHelperText>
                  </FormControl>
                  <Box display={stateCheckbox.values ? "inline" : "none"}>
                    <TablaPermisos value={formik.values} />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={8} justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    variant={formik.values.rol ? "outlined" : "filled"}
                    error={
                      formik.touched.posgrado && Boolean(posgradosAsignados.length <1)
                    }
                    required
                    disabled={!formik.values.rol}
                    fullWidth
                  >
                    <InputLabel>Posgrado</InputLabel>
                    <Select
                      label="Posgrado"
                      name="posgrado"
                      multiple
                      onChange={handleChangePosgrados}
                      open={openposgraduate}
                      onOpen={() => {
                        setOpenPostgraduate(true);
                      }}
                      onClose={() => {
                        setOpenPostgraduate(false);
                      }}
                      //si el rol es de tipo administrativo se muestra un valor para administradores
                      //si es otro rol, se muestran todos posgrados, asi no se asignan valores incorrectos

                      value={posgradosAsignados}
                      onBlur={formik.handleBlur}
                      MenuProps={MenuProps}
                    >
                      {formik.values.rol === "administrador" ||
                      formik.values.rol === "coordinador_general" ||
                      formik.values.rol === "evaluador_aspirante"
                        ? arrayOptionsAdmin.map((admin) => (
                            <MenuItem key={admin.value} value={admin.value}>
                              {admin.label}
                            </MenuItem>
                          ))
                        : arrayPostStudies.map((posgrado) => (
                            <MenuItem
                              key={posgrado.idprograma}
                              value={posgrado.idprograma}
                            >
                              {posgrado.programa}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.posgrado && posgradosAsignados.length <1 ? "Escoge un posgrado" : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={8} justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    className={classes.boton}
                    disabled={
                      !formik.values.correo ||
                      !formik.values.rol ||
                      posgradosAsignados.length < 1
                    }
                  >
                    Asignar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
          );
          }}
        </Formik>
    );
}