import React, { useEffect, useState } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AgregarLinea = (props) => {
    // console.log(props)
    const classes = useStyles();
    const [lineas, setLineas] = React.useState([])
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [recarga, setRecarga] = useState(false)
    const [carga, setCarga] = useState(false)

    useEffect(async () => {
        setRegistroAnterior({
            estatus: '',
            linea: '',
            profesor: '',

        })
        await axios.get(Global + "linea/estatus/alta/profesor/" + props.data.idprofesor + "/lineaestatus/alta", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response.data)
            setLineas(response.data)
        }).catch(error => console.log(error))

        handleClose()
    }, [recarga])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        linea: Yup.string().required('Es obligatorio seleccionar una linea de investigacion'),

    })

    const initial = registroAnterior
    // console.log(registroAnterior)

    const funcionSubmit = async valores => {
        // console.log(valores)
        setCarga(true)

        let programa = {
            estatus: 'alta',
        };
        await axios.post(Global + "lineaprof/profesor/" + props.data.idprofesor + "/linea/" + valores.linea, programa, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response);
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            setCarga(false)

        }).catch(error => {
            console.log(error);
            setCarga(false)

        })
        props.metodo()
    }
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>

                {open ? <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            //console.log(props);
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.linea && props.errors.linea ? (true) : false}>
                                                <InputLabel>Linea de investigacion</InputLabel>
                                                <Select
                                                    native
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Linea de investigacion"
                                                    inputProps={{
                                                        name: 'linea',
                                                        id: 'linea',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        lineas.map((progra) => {
                                                            return <option key={progra.idlinea} value={progra.idlinea}>{progra.nombre}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.programa && props.errors.programa ? (props.errors.programa) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>

                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                props.metodo();
                            }}>
                            Cancelar
                                            </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>
            </Paper >
        </div >
    );
}

export default AgregarLinea;