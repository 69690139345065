import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Global } from "../../../constants/global";
import { Grid, Typography } from "@material-ui/core";
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";
import MaterialTable from "material-table";
import axios from "axios";
import EditarPrograma from "./EditarPrograma";
import DetallesPrograma from "./DetallesPrograma";
import { obtenerProgramas } from "../../../servicios/obtenerProgramas";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  titulo: {
    fontSize: "2em",
    lineHeight: "1em",
    color: "#226af8",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    textTransform: "capitalize",
    textAlign: "center",
  },
  subtitulo: {
    fontSize: "25px",
    color: "#54595F",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },
  campo: {
    background: theme.palette.text.main,
  },
}));

function ActualizarPrograma() {
  const classes = useStyles();

  // alert(response.data.doc_curp)

  const { usuario } = useContext(Auth);
  const firestore = fba.firestore();
  const db = firestore.collection("usuarios");
  const [rol, setRol] = useState("");

  const [data, setData] = useState([]);
  const [scroll, setScroll] = React.useState("paper");
  const [programa, setPrograma] = useState("");
  const [open, setOpen] = useState(false);
  const [recarga, setRecarga] = useState(true);

  const [columns, setColumns] = useState([
    { title: "Clave", field: "clave" },
    { title: "Programa", field: "programa" },
    { title: "Estatus", field: "estatus" },
    { title: "Vigencia", field: "vigencia" },
    { title: "Versión", field: "version" },
    { title: "Nivel", field: "tipo" },
    { title: "Abreviatura", field: "abrev" },
    { title: "Fecha de Creación", field: "fechacr" },
    { title: "Fecha de Inicio", field: "Fechain" },
    { title: "Año de ingreso al PNPC", field: "pnpc" },
    { title: "Clave en el Conacyt", field: "claveco" },
    { title: "Clave de la S.E.P.", field: "clavese" },
    { title: "Orientación", field: "orientacion" },
    { title: "Pagina Web", field: "pweb" },
    { title: "Comentarios", field: "comentarios" },
    { title: "Periodo", field: "periodo" },
    {
      title: "Clave SEP",
      field: "docs",
      render: (rowData) =>
        rowData.docs !== "" ? (
          <a
            style={{ display: "table-cell" }}
            href={rowData.docs}
            target="_blank"
            rel="noreferrer"
          >
            Ver archivo
          </a>
        ) : null,
    },
    {
      title: "Acta por el consejo",
      field: "acta",
      render: (rowData) =>
        rowData.acta !== "" ? (
          <a
            style={{ display: "table-cell" }}
            href={rowData.acta}
            target="_blank"
            rel="noreferrer"
          >
            Ver archivo
          </a>
        ) : null,
    },
   /*  {
      title: "Plan de Estudio",
      field: "planestudio",
      render: (rowData) =>
        rowData.planestudio !== "" ? (
          <a
            style={{ display: "table-cell" }}
            href={rowData.planestudio}
            target="_blank"
            rel="noreferrer"
          >
            Ver archivo
          </a>
        ) : null,
    }, */
  ]);

  useEffect(() => {
    if (usuario.email !== null || usuario.email !== undefined) {
      db.where("correo", "==", usuario.email)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            if (usuario.email === doc.data().correo) {
              setRol(doc.data().rol);
            } else {
              return null;
            }

            if (doc.data().posgrado === 0) {
              //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA

              axios.get(Global + "programa/", {
                  headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                })
                .then((response) => {
                  let storage = fba.storage().ref();
                  // let storage2 = fba.storage().ref();

                  response.data.map(async (e, index) => {
                    if (e.acta !== null && e.acta !== "") {
                      let httpsReference = storage.child("" + e.acta);
                      // let httpsReference2 = storage2.child('' + e.docs)
                      response.data[index].acta =
                        await httpsReference.getDownloadURL();
                      // response.data[index].docs = await httpsReference2.getDownloadURL()
                    }
                  });

                  response.data.map(async (e, index) => {
                    if (e.docs !== null && e.docs !== "") {
                      let httpsReference = storage.child("" + e.docs);
                      // let httpsReference2 = storage2.child('' + e.docs)
                      response.data[index].docs =
                        await httpsReference.getDownloadURL();
                      // response.data[index].docs = await httpsReference2.getDownloadURL()
                    }
                  });

                  response.data.map(async (e, index) => {
                    if (e.planestudio !== null && e.planestudio !== "") {
                      let httpsReference = storage.child("" + e.planestudio);
                      // let httpsReference2 = storage2.child('' + e.docs)
                      response.data[index].planestudio =
                        await httpsReference.getDownloadURL();
                      // response.data[index].docs = await httpsReference2.getDownloadURL()
                    }
                  });




                  if (response.data) {
                    setData(response.data);
                  }
                })
                .catch((error) => console.log(error));
            } else {

              obtenerProgramas(doc,"programa/")
              .then(programa=>{
                setData(programa)
              })
              .catch((err)=>{console.log(err)})
            }
          });
        });
    }
  }, [recarga]);

  const descriptionElementRef = React.useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpen = (valores) => {
    setPrograma(valores);
    setOpen(true);
  };

  const actualiza = () => {
    setRecarga(!recarga);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {rol === "administrador" ||
      rol === "coordinador_general" ||
      rol === "coordinador_posgrado" ? (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            maxWidth="lg"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Información del programa
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <EditarPrograma
                programa={programa}
                rol={rol}
                metodo={actualiza}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
          {/* <a href={data[26].acta} style={{ display: "table-cell" }}
                            target="_blank" rel="noreferrer">Ver archivo</a> */}
          <MaterialTable
            title={
              <Hidden smDown>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography className={classes.subtitulo}>
                      Listado de programas
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
            }
            options={{
              exportButton: true,
              exportFileName: "Listado de programas",
              search: true,
              pageSize: 10,
              pageSizeOptions: [10,25,50,100,200],
              columnsButton: true,
              headerStyle: {
                backgroundColor: "#66abe0b8",
                color: "#FFF",
              },
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar en la tabla",
                searchPlaceholder: "Buscar",
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                exportTitle: "Exportar",
              },
              header: {
                actions: "Acciones",
              },
              pagination: {
                /* labelDisplayedRows: "", */
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsSelect: "",
                firstTooltip: "Primera página",
                previousTooltip: "Página anterior",
                nextTooltip: "Siguiente página",
                lastTooltip: "Última página",
              },
              body: {
                editRow: {
                  deleteText: "¿Esta seguro que desea dar de baja?",
                  cancelTooltip: "Cancelar",
                  saveTooltip: "Dar de baja",
                },
                emptyDataSourceMessage: "No hay información por mostrar",
                deleteTooltip: "Dar de baja",
              },
            }}
            columns={columns}
            data={data}
            actions={[
              {
                icon: "edit",
                tooltip: "Editar",
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                },
              },
            ]}
            detailPanel={[
              {
                icon: "more_vert",
                openIcon: "expand_less",
                iconProps: { color: "secondary" },
                tooltip: "Información del programa",
                render: (rowData) => {
                  return <DetallesPrograma programa={rowData} />;
                },
              },
            ]}
          />
        </>
      ) : (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            maxWidth="lg"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <Typography className={classes.subtitulo}>
                Información del programa
              </Typography>
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <EditarPrograma programa={programa} rol={rol} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
          <MaterialTable
            title={
              <Hidden smDown>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography className={classes.subtitulo}>
                      Listado de programas
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
            }
            options={{
              exportButton: true,
              exportFileName: "Listado de programas",
              search: true,
              pageSize: 10,
              pageSizeOptions: [10,25,50,100,200],
              columnsButton: true,
              headerStyle: {
                backgroundColor: "#66abe0b8",
                color: "#FFF",
              },
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar en la tabla",
                searchPlaceholder: "Buscar",
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                exportTitle: "Exportar",
              },
              header: {
                actions: "Acciones",
              },
              pagination: {
                /* labelDisplayedRows: "", */
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "",
                firstTooltip: "Primera página",
                previousTooltip: "Página anterior",
                nextTooltip: "Siguiente página",
                lastTooltip: "Última página",
              },
              body: {
                editRow: {
                  deleteText: "¿Esta seguro que desea dar de baja?",
                  cancelTooltip: "Cancelar",
                  saveTooltip: "Dar de baja",
                },
                emptyDataSourceMessage: "No hay información por mostrar",
                deleteTooltip: "Dar de baja",
              },
            }}
            columns={columns}
            data={data}
            actions={[
              {
                icon: "edit",
                tooltip: "Ver",
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                },
              },
            ]}
            detailPanel={[
              {
                icon: "more_vert",
                openIcon: "expand_less",
                iconProps: { color: "secondary" },
                tooltip: "Información del programa",
                render: (rowData) => {
                  return <DetallesPrograma programa={rowData} />;
                },
              },
            ]}
          />
        </>
      )}
    </div>
  );
}

export default ActualizarPrograma;
