import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Grid, Paper } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Personales from './Personales';
import TablaEscolaridad from './TablaEscolaridad';
import DatosProfesor from './DatosProfesor';
import TablaIdioma from './TablaIdioma';
import Sniprofesor from './Sniprofesor';
import TablaMovilidad from './TablaMovilidad';
import TablaCurso from './TablaCurso';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#b1cde3',
        marginTop: '2%',
        marginBottom: '3%',
    },
    barra: {
        backgroundColor: '#2361b1',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#2361b1",
        borderRadius: "4px 4px 4px 4px",
        color: '#ffff',
        marginLeft: '5%',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

export default function SimpleTabs(props) {
    // console.log(props)
    const { persona } = props.data;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>

            <Button
                className={classes.boton}
                startIcon={<BackspaceIcon />}
                onClick={(e) => { props.metodo('') }}
            >
                Atras
            </Button>
            <Paper className={classes.root}>
                <Grid container spacing={2} justify='center'>
                    <Grid item >
                        <Typography className={classes.subtitulo}>
                            {persona.nombre + " " + persona.apellidoP + " " + persona.apellidoM}
                        </Typography>
                    </Grid>
                </Grid>
                <AppBar position="static" className={classes.barra}>
                    <Tabs value={value} onChange={handleChange} variant="scrollable"
                        scrollButtons="auto">
                        <Tab label="Personales" {...a11yProps(0)} />
                        <Tab label="Escolaridad" {...a11yProps(1)} />
                        {/* <Tab label="Domicilio" {...a11yProps(2)} /> */}
                        <Tab label="Datos relacionados" {...a11yProps(2)} />
                        <Tab label="SNI" {...a11yProps(3)} />
                        <Tab label="Movilidad" {...a11yProps(4)} />
                        <Tab label="Cursos de actualizacion" {...a11yProps(5)} />

                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Personales data={props.data} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TablaEscolaridad data={props.data} />
                    <br />
                    <TablaIdioma data={props.data} />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    <DomicilioProfesor data={props.data} />
                </TabPanel> */}
                <TabPanel value={value} index={2}>
                    <DatosProfesor data={props.data} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Sniprofesor data={props.data} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <TablaMovilidad data={props.data} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <TablaCurso data={props.data} />
                </TabPanel>
            </Paper>
        </>
    );
}