import React, { useEffect, useState, useContext } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import Swal from 'sweetalert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Global } from '../../../../constants/global';
import { Auth } from "../../../../context/AuthContext";
import { fba } from "../../../../firebaseConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#b1cde3',
    marginTop: '2%',
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '5%',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  titulo: {
    fontSize: '2em',
    lineHeight: '1em',
    color: '#226af8',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  subtitulo: {
    fontSize: '25px',
    color: '#54595F',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
  },
  campo: {
    background: theme.palette.text.main,
  },
}));

function AgregarAlumno(props) {

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [alumnos, setAlumnos] = useState([]);
  const [recarga, setRecarga] = useState(false)
  const { idcurso } = props.data;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { usuario } = useContext(Auth);
  const firestore = fba.firestore();
  const db = firestore.collection('usuarios');
  const [rol, setRol] = useState('')

  const [columns, setColumns] = useState([
    { title: 'Alumno', render: (rowData) => `${rowData.alumno.persona.nombre} ${rowData.alumno.persona.apellidoP} ${rowData.alumno.persona.apellidoM}`, editable: 'never' },
    { title: 'Matrícula', field: 'alumno.matricula', editable: 'never' },
    { title: 'Calificación', field: 'calificacion' },
  ]);

  useEffect(() => {

    let datos = async () => {

      if (usuario.email !== null || usuario.email !== undefined) {
        db.where('correo', '==', usuario.email).get().then(snapshot => {
          snapshot.docs.forEach(doc => {
            if (usuario.email === doc.data().correo) {
              setRol(doc.data().rol)
            } else {
              return null;
            }
          });
        });
      }

      await axios.get(`${Global}inscripcion/curso/${idcurso}`, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }).then((response) => {
        setData(response.data);
      }).catch((error) => console.log(error));

      await axios.get(`${Global}alumno/curso/${idcurso}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }).then((response) => {
        setAlumnos(response.data);
      }).catch((error) => console.log(error));
    }

    datos();

  }, [recarga]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReload = () => {
    setRecarga(!recarga)
  }

  const formik = useFormik({
    initialValues: {
      alumno: ''
    },
    validationSchema: Yup.object({
      alumno: Yup.string().required('El campo de alumno es obligatorio'),
    }),
    onSubmit: async () => {

      formik.resetForm();
      //Obtenemos el id del alumno por medio de su matricula
      await axios.get(`${Global}alumno/matricula/${inputValue}`, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }).then(response => {

        let ID = response.data[0].idalumno
        //Revisamos si este alumno ya tiene relacion con el curso dentro de inscripcion
        axios.get(`${Global}inscripcion/alumno/${ID}/curso/${idcurso}`, {
          headers: {
            'Access-Control-Allow-Origin': 'http://localhost:3000',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        }).then(response => {
          //Si no existe
          if (response.data.length < 1) {
            //Hacemos el registro de inscripcion del alumno con relacion al curso
            axios.post(`${Global}inscripcion/curso/${idcurso}/alumno/${ID}`,
              {
                calificacion: 0,
              },
              {
                headers: {
                  'Access-Control-Allow-Origin': 'http://localhost:3000',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
              }).then(() => {
                Swal({
                  title: 'Alumno agregado',
                  text: ' ',
                  icon: 'success',
                  button: false,
                  timer: 1000,
                });
                handleReload()
              }).catch(error => console.log(error))
          } else {
            Swal({
              title: 'Este alumo ya ha sido agregado',
              text: ' ',
              icon: 'error',
              button: false,
              timer: 1000,
            });
          }
        }).catch(error => console.log(error))


      }).catch((error) => console.log(error));
    },

  });


  return (
    <div>
      <Paper className={classes.root} elevation={3}>
        {rol === "coordinador_general" ?

          <>
            <MaterialTable
              title={(
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography className={classes.subtitulo}>
                      Listado de calificaciones
                </Typography>
                  </Grid>
                </Grid>
              )}
              options={{
                exportButton:true,
                exportFileName:"Listado de calificaciones",
                search: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                headerStyle: {
                  backgroundColor: '#66abe0b8',
                  color: '#FFF',
                },
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: '',
                  labelRowsSelect: '',
                  firstTooltip: 'Primera página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Siguiente página',
                  lastTooltip: 'Última página',
                },
                body: {
                  emptyDataSourceMessage: 'No hay información por mostrar',
                  addTooltip: 'Agregar',
                  editTooltip: 'Editar',
                  deleteTooltip: 'Deshabilitada',
                  editRow: {
                    deleteText: '¿Desea eliminar este programa de la unidad academica?',
                    cancelTooltip: 'Cancelar',
                    saveTooltip: 'Guardar',
                  },
                },
                toolbar: {
                  searchTooltip: 'Buscar en la tabla',
                  searchPlaceholder: 'Buscar',
                  exportCSVName: "Exportar como CSV",
                  exportPDFName: "Exportar como PDF",
                  exportTitle: "Exportar"
                },
                header: {
                  actions: 'Acciones',
                },

              }}
              columns={columns}
              data={data}
              actions={[
                {
                  icon: 'add_box',
                  tooltip: 'Agregar',
                  isFreeAction: true,
                  onClick: () => handleClickOpen(),
                },
              ]}
              editable={{
                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                  setTimeout(async () => {

                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    const inscripcion = {
                      calificacion: newData.calificacion,
                    };
                    await axios.put(`${Global}inscripcion/${newData.idins}`, inscripcion, {
                      headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                      },
                    }).then(() => {
                      Swal({
                        title: 'Calificación actualizada',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000,
                      });
                    }).catch((error) => console.log(error));

                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);

                    resolve();
                  }, 1000);
                }),
              }}
            />
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                <DialogContentText style={{ marginBottom: -10 }}>Seleccione el alumno al que desea agregar una calificación</DialogContentText>
              </DialogTitle>
              <form noValidate onSubmit={formik.handleSubmit}>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={alumnos}
                        inputValue={formik.values.alumno}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          formik.setFieldValue("alumno", newInputValue)
                        }}
                        fullWidth
                        getOptionLabel={(option) => option.matricula}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              id="textSpan"
                              style={{ fontWeight: 'bold' }}
                            >
                              {option.matricula}
                            </span>
                        ({option.persona.correo})
                          </React.Fragment>
                        )}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            id='alumno'
                            name='alumno'
                            label="Matrícula"
                            variant="outlined" />}
                      />

                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>
                    Cancelar
              </Button>
                  <Button
                    type="submit"
                  >
                    Agregar
              </Button>
                </DialogActions>
              </form>
            </Dialog>
          </>
          :
          <MaterialTable
            title={(
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography className={classes.subtitulo}>
                    Listado de calificaciones
                </Typography>
                </Grid>
              </Grid>
            )}
            options={{
              exportButton:true,
              exportFileName:"Listado de calificaciones",
              search: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30],
              headerStyle: {
                backgroundColor: '#66abe0b8',
                color: '#FFF',
              },
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '',
                labelRowsSelect: '',
                firstTooltip: 'Primera página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Siguiente página',
                lastTooltip: 'Última página',
              },
              body: {
                emptyDataSourceMessage: 'No hay información por mostrar',
                addTooltip: 'Agregar',
                editTooltip: 'Editar',
                deleteTooltip: 'Deshabilitada',
                editRow: {
                  deleteText: '¿Desea eliminar este programa de la unidad academica?',
                  cancelTooltip: 'Cancelar',
                  saveTooltip: 'Guardar',
                },
              },
              toolbar: {
                searchTooltip: 'Buscar en la tabla',
                searchPlaceholder: 'Buscar',
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                exportTitle: "Exportar"
              },
              header: {
                actions: 'Acciones',
              },

            }}
            columns={columns}
            data={data}

          />
        }
      </Paper>
    </div>
  );
}

export default AgregarAlumno;
