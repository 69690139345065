import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DatosGenerales from './DatosGenerales/index';
import CartasRecomendacion from './CartasDeRecomendacion/index'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Hidden } from '@material-ui/core';
import Escolaridad from './Escolaridad/FormEscolaridad';
import Posgrado from './Posgrado/index';
import Experiencia from './Experiencia/FormExperiencia';
import CerrarRegistro from './CerrarRegistro';
import Ocupacion from './Experiencia/FormOcupacion';
import PreguntasFrec from './PreguntasFrecuentes/PreguntasFrec';
import axios from 'axios';
import { Global } from '../../constants/global';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        marginTop: '2%',
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1000,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    titulo: {
        fontSize: "3em",
        lineHeight: "1em",
        color: "#ffffff",
        fontFamily: "Palanquin Dark, sans-serif",
        fontWeight: 700,
        textTransform: "uppercase",
        letterSpacing: "4px",
    },
    boton: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(5),
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const steps = ['Antes de Iniciar', 'Datos Generales', 'Estudios Que Desea Realizar', 'Estudios Realizados', 'Experiencia Profesional', 'Carta De Recomendación'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <PreguntasFrec />;
        case 1:
            return <DatosGenerales/>;
        case 2:
            return <Posgrado />;
        case 3:
            return <Escolaridad />;
        case 4:
            return <><Experiencia /><Ocupacion /></>
        case 5:
            return <CartasRecomendacion />
        case 6:
            return <CerrarRegistro />
        default:
            throw new Error('Accion desconocida');
    }
}

export default function Checkout() {

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [progress, setProgress] = useState(20);
    const [stepsCircular, setStepsCircular] = useState(steps[0]);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        setProgress(progress + 20);
        setStepsCircular(steps[activeStep + 1]);
    };

    const handlCerrar = () => {
        setActiveStep(6);
    }
    const handleBack = () => {
        setActiveStep(activeStep - 1);
        setProgress(progress - 20);
        setStepsCircular(steps[activeStep - 1]);
    };

    useEffect(async () => {
        const refrescar = async () => {
            if (window.localStorage.getItem('refreshToken') !== null) {
                await axios.get(Global + "direccion/prueba", {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    // console.log(response)
                }).catch(error => {
                    // console.log(error)
                    // console.log('esho')
                    axios.post("https://securetoken.googleapis.com/v1/token?key=AIzaSyDbEjkKzEgL09oCVFuhcD_T6C5MvrIksn4",
                        {
                            grant_type: 'refresh_token',
                            refresh_token: window.localStorage.getItem("refreshToken")
                        })
                        .then((response) => {
                            window.localStorage.setItem('refreshToken', response.data.refresh_token);
                            window.localStorage.setItem('token', response.data.access_token);
                        })
                })
            }
        }
        await refrescar();
    }, [activeStep])

    return (
        <>
            <main className={classes.layout}>
                <Typography align="center" className={classes.titulo}>
                    Registro de aspirante a posgrado
                    </Typography>
                <Paper className={classes.paper}>
                    <Hidden smDown>
                        <br />
                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {steps.map((label) => (
                                <Step key={label}>

                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Hidden>
                    <Hidden smUp>
                        <br />
                        <br />
                        <br />
                        <Grid alignItems="center" justify="center"
                            container spacing={2}>
                            <Grid item >
                                <CircularProgress size={60} variant="determinate" value={progress} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    {stepsCircular}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>


                    <>
                        {getStepContent(activeStep)}
                        <div className={classes.buttons}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} variant="contained" color="primary" className={classes.boton}>
                                    Regresar
                                </Button>
                            )}
                            {activeStep === steps.length - 1 ?
                                <Button
                                    // justifyContent="end"
                                    variant="contained"
                                    color="primary"
                                    onClick={handlCerrar}
                                    className={classes.boton}>
                                    Siguiente
                                        </Button>
                                : null}
                            {activeStep < steps.length - 1 ?
                                <Button
                                    // justifyContent="end"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.boton}>
                                    Siguiente
                                        </Button> : null
                            }
                        </div>
                    </>
                </Paper>
            </main>
        </>
    );
}
