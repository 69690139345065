import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Header from "../generales/Header";
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from "../../context/AuthContext";
import Stepper from "./Stepper";
import { fba } from '../../firebaseConfig'
import Footer from "../generales/Footer";
// import axios from 'axios';
// import { Global } from '../../constants/global';
// import Datos from "./DatosGenerales/RegistroDatosGenerales";

const useStyles = makeStyles((theme) => ({
    paperIma: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-8),
        backgroundColor: theme.palette.secondary,
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));

const Home = ({ history }) => {

    const { usuario } = useContext(Auth);
    const [admin, setAdmin] = useState(false)

    useEffect(() => {
        // console.log(usuario)
        if (usuario === null) {
            history.push("/registro");
        } else {
            if (window.localStorage.getItem('token') === null) {
                fba.auth().signOut()
            } else {
                const firestore = fba.firestore();
                const db = firestore.collection('usuarios');
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    // console.log(snapshot.docs)
                    snapshot.docs.forEach(doc => {
                        setAdmin(true)
                        history.push("/inicio");
                    });
                });
            }
        }
    }, [history, usuario]);
    const classes = useStyles();


    return (
        usuario !== null & !admin ?
            <div className={classes.root}>
                <Header />
                <Stepper />
                <Footer />
            </div>
            : null
    );
}

export default withRouter(Home);