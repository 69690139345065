import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Typography, Button, TextField, Grid, FormControl, InputLabel, Select, FormHelperText, CircularProgress, Paper } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik"
import { Global } from "../../../constants/global"
import Swal from 'sweetalert'
import Backdrop from '@material-ui/core/Backdrop';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import * as Yup from 'yup'
import axios from "axios";
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";
import { menor,FORMATOFECHAMAXHOY } from "../../generales/Fecha"


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    foto: {
        width: '180px',
        height: '180px'
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));


function InfoAlumno(props) {

    const { idalumno, persona, matricula } = props.data;
    const classes = useStyles();

    const [programa, setPrograma] = useState([]);
    const [institucion, setInstitucion] = useState([]);
    const [sede, setSede] = useState([]);
    const [open, setOpen] = useState(true);
    const [data, setData] = useState([])
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [acceso, setAcceso] = useState(true)

    useEffect(() => {

        let datos = {
            nombre: "",
            orientacion: "",
            institucion: "",
            tutor: "",
            sede: "",
            conacyt: "",
            dependencia: "",
            estatus: "",
            fechain: "",
            generacion: "",
            idioma: "",
            matricula: "",
            observacion: "",
            toefel: "",
            toefelec: "",
            toefelfe: "",
            programa: ""
        }

        const inicial = async () => {
            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            doc.data().rol === "administrador" ||
                                doc.data().rol === "coordinador_general" ||
                                doc.data().rol === "coordinador_posgrado"
                                ? setAcceso(false) : setAcceso(true)
                        } else {
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "institucion/", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setInstitucion(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "programa", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPrograma(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "sede", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setSede(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                datos = response.data
                datos.programa = response.data.programa.idprograma
                datos.orientacion = response.data.programa.orientacion
            }).catch(error => console.log(error))

            await axios.get(Global + "escolaridad/personas/" + persona.idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                datos.institucion = response.data[0].institucion.idins
            }).catch(error => console.log(error))

            await axios.get(Global + "alumnosed/alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    datos.sede = response.data[0].sede.idsede
                }
            }).catch(error => console.log(error))

            setData(datos)
            setOpen(false)

        }

        inicial();

    }, [])


    const handleClose = () => {
        setOpen(false);
    };

    const schemaValidation = Yup.object({
        matricula: Yup.string().required('Es obligatorio ingresar la matricula.').matches(/^[0-9]{7}[-]{1}[0-9]{1}$/, 'El campo no cumple con el formato de la matricula. (0000000-0)'),
        conacyt: Yup.string(),
        dependencia: Yup.string(),
        estatus: Yup.string().required('El campo de estatus obligatorio'),
        fechain: Yup.string().required('El campo es obligatorio'),
        generacion: Yup.string(),
        idioma: Yup.string(),
        observacion: Yup.string(),
        toefel: Yup.number().positive('El valor debe ser mayor a 0'),
        toefelec: Yup.number().positive('El valor debe ser mayor a 0'),
        toefelfe: Yup.string().test('match',
            'La fecha debe ser menor a la fecha actual',
            function (fecha) {
                return menor(fecha)
            }
        ),
        programa: Yup.string().required('El campo de programa es obligatorio')
    })

    const functionSubmit = async valores => {

        let programa = valores.programa
        await axios.get(Global + "estudiosrea/persona/" + persona.idP,
            {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(async response => {

                let estudios_realizados = {
                    cate: response.data[0].cate,
                    fechaini: response.data[0].fechaini,
                    finan: response.data[0].finan,
                    nivel: response.data[0].nivel,
                    programa: {
                        idprograma: valores.programa
                    }
                }
                if (response.data.length > 0) {
                    await axios.put(Global + "estudiosrea/" + response.data[0].idposgrado, estudios_realizados,
                        {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).catch(error => console.log(error))
                }
            }).catch(error => console.log(error))


        await axios.get(Global + "escolaridad/personas/" + persona.idP,
            {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async response => {

                let escolaridad = response.data[0]
                escolaridad.institucion.idins = valores.institucion
                await axios.put(Global + "escolaridad/" + response.data[0].idescolaridad, escolaridad,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error))


            }).catch(error => console.log(error))


        if (valores.sede) {
            await axios.get(Global + "alumnosed/alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async response => {

                if (response.data.length > 0) {

                    await axios.put(Global + "alumnosed/" + response.data[0].idas, {
                        sede: {
                            idsede: valores.sede
                        }
                    }, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error))

                } else {
                    await axios.post(Global + "alumnosed/alumno/" + idalumno + "/sede/" + valores.sede, {
                        alumno: {
                            conacyt: ""
                        }
                    }, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error))
                }

            }).catch(error => console.log(error))
        }

        valores.matricula = matricula
        valores.persona = {
            idP: persona.idP
        }
        valores.programa = {
            idprograma: programa
        }
        await axios.put(Global + "alumno/estatus/id/" + idalumno, {
            "estatus": valores.estatus
        }, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).catch(error => console.log(error))

        await axios.put(Global + "alumno/id/" + idalumno, valores, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            Swal({
                title: 'Datos guardados',
                text: '',
                icon: 'success',
                button: false,
                timer: 1000
            })
        }).catch(error => console.log(error))





    }


    const initial = data;

    return (
        <Paper elevation={3} className={classes.paperContainer}>
            <Grid
                container
                spacing={1}
                alignItems="center">
                <Grid item>
                    <Avatar className={classes.avatar}>
                        <AccountBoxIcon />
                    </Avatar>
                </Grid>
                <Grid item>
                    <Typography className={classes.subtitulo}>
                        Alumno
                        </Typography>
                </Grid>
            </Grid>
            <br />
            {open ?
                <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                : <Formik
                    validationSchema={schemaValidation}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        functionSubmit(valores)
                    }}>
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>

                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            label="Matricula"
                                            id="matricula"
                                            name="matricula"
                                            value={props.values.matricula}
                                        />
                                    </Grid> */}
                                    

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="Matricula"
                                            id="matricula"
                                            name="matricula"
                                            value={props.values.matricula}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.matricula && props.errors.matricula ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.matricula && props.errors.matricula ? (
                                                props.errors.matricula
                                            ) : null} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                    </Grid>

                                   {/*  <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="programa"
                                            name="programa"
                                            label="Programa"
                                            value={props.values.programa}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>  */}

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            
                                            disabled
                                            required
                                            error={props.touched.programa && props.errors.programa ? (true) : false}>
                                            <InputLabel>Programa</InputLabel>
                                            <Select 
                                                native
                                                value={props.values.programa}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Programa"
                                                inputProps={{
                                                    name: 'programa',
                                                    id: 'programa',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {programa !== '' ?
                                                    programa.map((programa) => {
                                                        return <option key={programa.idprograma} value={programa.idprograma}>{programa.programa}</option>
                                                    })
                                                    : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.programa && props.errors.programa ? (props.errors.programa) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            label="Orientación"
                                            id="orientacion"
                                            name="orientacion"
                                            type="orientacion"
                                            value={props.values.orientacion}
                                            onChange={(e)=>{
                                                let orientacion = e.target.value;
                                                props.setFieldValue("orientacion",orientacion.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.orientacion && props.errors.orientacion ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.orientacion && props.errors.orientacion ? (
                                                props.errors.orientacion
                                            ) : null} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.institucion && props.errors.institucion ? (true) : false}>
                                            <InputLabel>Institución de procedencia</InputLabel>
                                            <Select
                                                native
                                                value={props.values.institucion}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Institucion de procedencia"
                                                inputProps={{
                                                    name: 'institucion',
                                                    id: 'institucion',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {institucion !== '' ?
                                                    institucion.map((institucion) => {
                                                        return <option key={institucion.idins} value={institucion.idins}>{institucion.nombre}</option>
                                                    })
                                                    : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="Dependencia de la Institucion de procedencia"
                                            id="dependencia"
                                            name="dependencia"
                                            value={props.values.dependencia}
                                            onChange={(e)=>{
                                                let dep = e.target.value;
                                                props.setFieldValue("dependencia",dep.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.dependencia && props.errors.dependencia ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.dependencia && props.errors.dependencia ? (
                                                props.errors.dependencia
                                            ) : null} />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            
                                            error={props.touched.tutor && props.errors.tutor ? (true) : false}>
                                            <InputLabel>Tutor</InputLabel>
                                            <Select
                                                native
                                                value={props.values.tutor}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Tutor"
                                                inputProps={{
                                                    name: 'tutor',
                                                    id: 'tutor',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'Doctorado'}>Doctorado</option>
                                                <option value={'Maestria'}>Maestria</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.tutor && props.errors.tutor ? (props.errors.tutor) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            disabled={acceso}
                                            error={props.touched.estatus && props.errors.estatus ? (true) : false}>
                                            <InputLabel>Estatus</InputLabel>
                                            <Select
                                                native
                                                value={props.values.estatus}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Estatus"
                                                inputProps={{
                                                    name: 'estatus',
                                                    id: 'estatus',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'AN'}>Aceptado no inscrito</option>
                                                <option value={'BD'}>Baja definitiva</option>
                                                <option value={'BT'}>Baja temporal</option>
                                                <option value={'GA'}>Graduado</option>
                                                <option value={'PR'}>Prorroga</option>
                                                <option value={'SF'}>Baja SF</option>
                                                <option value={'VG'}>Vigente</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.estatus && props.errors.estatus ? (props.errors.estatus) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Ingreso"
                                            id="fechain"
                                            name="fechain"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.values.fechain}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.fechain && props.errors.fechain ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.fechain && props.errors.fechain ? (
                                                props.errors.fechain
                                            ) : null} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            label="Generación"
                                            id="generacion"
                                            name="generacion"
                                            type="text"
                                            value={props.values.generacion}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={props.touched.generacion && props.errors.generacion ? (true) : false}>
                                                <InputLabel>Generacion</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.generacion}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Generacion"
                                                    inputProps={{
                                                        name: 'generacion',
                                                        id: 'generacion',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'Doctorado'}>Doctorado</option>
                                                    <option value={'Maestria'}>Maestria</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.generacion && props.errors.generacion ? (props.errors.generacion) : null}</FormHelperText>
                                            </FormControl>

                                        </Grid> */}


                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.sede && props.errors.sede ? (true) : false}>
                                            <InputLabel>Sede</InputLabel>
                                            <Select
                                                native
                                                value={props.values.sede}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Sede"
                                                inputProps={{
                                                    name: 'sede',
                                                    id: 'sede',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {sede !== '' ?
                                                    sede.map((sede) => {
                                                        return <option key={sede.idsede} value={sede.idsede}>{sede.nombre}</option>
                                                    })
                                                    : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.sede && props.errors.sede ? (props.errors.sede) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.conacyt && props.errors.conacyt ? (true) : false}>
                                            <InputLabel>CONACYT</InputLabel>
                                            <Select
                                                native
                                                value={props.values.conacyt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="CONACYT"
                                                inputProps={{
                                                    name: 'conacyt',
                                                    id: 'conacyt',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'Si'}>Si</option>
                                                <option value={'No'}>No</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.conacyt && props.errors.conacyt ? (props.errors.conacyt) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="Observaciones"
                                            id="observacion"
                                            name="observacion"
                                            value={props.values.observacion}
                                            onChange={(e)=>{
                                                let observacion = e.target.value;
                                                props.setFieldValue("observacion",observacion.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.observacion && props.errors.observacion ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.observacion && props.errors.observacion ? (
                                                props.errors.observacion
                                            ) : null} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.idioma && props.errors.idioma ? (true) : false}>
                                            <InputLabel>Cubierto idioma</InputLabel>
                                            <Select
                                                native
                                                value={props.values.idioma}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Cubierto idioma"
                                                inputProps={{
                                                    name: 'idioma',
                                                    id: 'idioma',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'Si'}>Si</option>
                                                <option value={'No'}>No</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.idioma && props.errors.idioma ? (props.errors.idioma) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="TOEFL"
                                            type="number"
                                            id="toefel"
                                            name="toefel"
                                            value={props.values.toefel}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.toefel && props.errors.toefel ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.toefel && props.errors.toefel ? (
                                                props.errors.toefel
                                            ) : null} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="TOEFL compresión lectura"
                                            type="number"
                                            id="toefelec"
                                            name="toefelec"
                                            value={props.values.toefelec}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.toefelec && props.errors.toefelec ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.toefelec && props.errors.toefelec ? (
                                                props.errors.toefelec
                                            ) : null} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="Fecha oficial del TOEFL"
                                            id="toefelfe"
                                            name="toefelfe"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.values.toefelfe}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.toefelfe && props.errors.toefelfe ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.toefelfe && props.errors.toefelfe ? (
                                                props.errors.toefelfe
                                            ) : null} 
                                            InputProps={FORMATOFECHAMAXHOY}
                                            />
                                    </Grid>

                                    {
                                        !acceso ?
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                                </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            :
                                            null

                                    }

                                </Grid>
                            </form>
                        )
                    }}
                </Formik>
            }
        </Paper>
    )

}

export default InfoAlumno;