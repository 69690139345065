import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Grid, Paper, Avatar } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import InfoAlumno from "./InfoAlumno";
import Personales from "./Personales";
import Beca from "./Beca";
import Baja from "./Baja";
import TablaEscolaridad from "./TablaEscolaridad";
import Domicilio from "./Domicilio";
import Ocupacion from "./Ocupacion";
import Tesis from "./Tesis";
import TablaAsociacion from "./TablaAsociacion";
import TablaIdioma from "./TablaIdioma";
import TablaMovilidad from "./TablaMovilidad";
import TablaActualizacion from "./TablaActualizacion";
import TablaPredoctoral from "./TablaPredoctoral";
import TablaReunion from "./TablaReunion";
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import TablaExamen from "./TablaExamen";
import AgregarExamen from "./AgregarExamen";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#b1cde3",
    marginTop: "2%",
    marginBottom: "3%",
  },
  barra: {
    backgroundColor: "#2361b1",
  },
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#2361b1",
    borderRadius: "4px 4px 4px 4px",
    color: "#ffff",
    marginLeft: "5%",
  },
  subtitulo: {
    fontSize: "25px",
    color: "#54595F",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },
  foto: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  leftContainer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function SimpleTabs(props) {

  const classes = useStyles();
  const { matricula, persona, estatus } = props.data;
  const [value, setValue] = React.useState(0);
  const [foto, setFoto] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    const getFoto = async () => {
      let storage2 = fba.storage().ref();
      let httpsReference2 = storage2.child('' + persona.fotografia)
      setFoto(await httpsReference2.getDownloadURL());
    }

    getFoto();


  }, [])

  return (

    <>
      <Button
        className={classes.boton}
        startIcon={<BackspaceIcon />}
        onClick={(e) => {
          props.metodo("");
        }}
      >
        Atras
      </Button>
      <Paper className={classes.root}>
        <Grid container spacing={1} justify="center"  >
          <Grid item className={classes.leftContainer}>
            <Avatar
              className={classes.foto}
              // alt="foto de perfil"}
              src={foto}
            />

            <Typography className={classes.subtitulo} style={{ display: 'flex', marginLeft: "5%" }} >
              {persona.nombre +
                " " +
                persona.apellidoP +
                " " +
                persona.apellidoM +
                " " +
                matricula}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <AppBar position="static" className={classes.barra}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Alumno" {...a11yProps(0)} />
            <Tab label="Titulación" {...a11yProps(1)} />
            <Tab label="Predoctoral" {...a11yProps(2)} />
            <Tab label="Personales" {...a11yProps(3)} />
            <Tab label="Reuniones" {...a11yProps(4)} />
            <Tab label="Beca" {...a11yProps(5)} />
            {/* Se cambia el nombre a permanencia, antes  baja */}
            <Tab label="Permanencia" {...a11yProps(6)} />
            {/* <Tab label="Baja" {...a11yProps(6)} /> */}
            <Tab label="Escolaridad" {...a11yProps(7)} />
            <Tab label="Domicilio" {...a11yProps(8)} />
            <Tab label="Movilidad" {...a11yProps(9)} />
            <Tab label="Cursos de actualizacion" {...a11yProps(10)} />
            {/* <Tab label="Examen Profesional" {...a11yProps(12)} /> */}

            {/* {
              estatus === 'GA'
                ?
                <>
                  <Tab label="Ocupacion" {...a11yProps(9)} />
                  <Tab label="Movilidad" {...a11yProps(10)} />
                  <Tab label="Cursos de actualizacion" {...a11yProps(11)} />
                </>

                :
                <>
                  <Tab label="Movilidad" {...a11yProps(9)} />
                  <Tab label="Cursos de actualizacion" {...a11yProps(10)} />
                </>
            } */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <InfoAlumno data={props.data} />
        </TabPanel>
        {/* se agrega modulo de titulación  */}
        <TabPanel value={value} index={1}>
          <>
          <Tesis data={props.data} />
            <br />
          <TablaReunion data={props.data} />
            <br />
          <TablaExamen data={props.data} />
          </>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TablaPredoctoral data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Personales data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TablaReunion data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Beca data={props.data} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Baja data={props.data} />
        </TabPanel>

        <TabPanel value={value} index={7}>
          <>
            <TablaEscolaridad data={props.data} />
            <br />
            <TablaAsociacion data={props.data} />
            <br />
            <TablaIdioma data={props.data} />
          </>
        </TabPanel>

        <TabPanel value={value} index={8}>
          <Domicilio data={props.data} />
        </TabPanel>

        {/* <TabPanel value={value} index={12}>
          <TablaExamen data={props.data} />
        </TabPanel> */}

        {
          estatus === 'GA'
            ?
            <>
              <TabPanel value={value} index={11}>
                <Ocupacion data={props.data} />
              </TabPanel>

              <TabPanel value={value} index={9}>
                <TablaMovilidad data={props.data} />
              </TabPanel>

              <TabPanel value={value} index={10}>
                <TablaActualizacion data={props.data} />
              </TabPanel>
            </>

            :
            <>
              <TabPanel value={value} index={9}>
                <TablaMovilidad data={props.data} />
              </TabPanel>

              <TabPanel value={value} index={10}>
                <TablaActualizacion data={props.data} />
              </TabPanel>

            </>
        }

      </Paper>
    </>
  );
}
