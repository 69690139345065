// React
import React, { useCallback, useContext, useState } from "react";
// Dependencies
import { useDropzone } from "react-dropzone";
// Material dependencies
import { makeStyles } from "@material-ui/core/styles";
import BackupIcon from "@material-ui/icons/Backup";
// Components
import EntitySelectorComponent from "./EntitySelectorComponent";
//Context
import { UploadContext } from "./UploadContext";

const DropzoneComponent = () => {
  const { uploadState, setUploadState } = useContext(UploadContext);

  const [isCsvValid, setIsCsvValid] = useState(false);
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "sans-serif",
    },
    dropzone: {
      flex: "1",
      alignItems: "center",
      textAlign: "center",
      padding: "20px",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
  }));

  const classes = useStyles();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setUploadState({
        ...uploadState,
        csv: file,
        csvName: file.path,
        csvSize: file.size,
      });
      setIsCsvValid(true);
    });
  }, []);

  const {  getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",

    minSize: 100,
    maxSize: 25000000,
    multiple: false,
  });
  return (
    <section className={classes.container}>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input type="file" {...getInputProps()} />
        <BackupIcon style={{ fontSize: 100 }} />
        <p>Deja los archivos aquí</p>
        <em>(Solo acepta archivos .csv con un máximo de 25 Mb)</em>
      </div>
      <EntitySelectorComponent isValidFile={isCsvValid} />
    </section>
  );
};

export default DropzoneComponent;
