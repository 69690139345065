import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { Button } from '@material-ui/core'
import CartaOtorgante from './CartaOtorgante';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import peticionesHttp from '../../../servicios/peticionesHTTP';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    avatar: {
        background: theme.palette.secondary.main,
    },
    boton: {
        fontFamily: "Poppins, Sans-serif",
        fontSize: "15px",
        textTransform: "none",
        lineHeight: "18px",
        borderStyle: "solid",
        borderRadius: "6px 6px 6px 6px",
        backgroundColor: "#2361b2",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#1481e8",
        },
    }
}));

function createData(idcarta, nombre, correo,estatus, id) {
    return { idcarta, nombre, correo,estatus, id };
}

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

function CartaRecomendacion({ persona }) {

    const [open, setOpen] = useState(false);
    const [infoTabla, setInfoTabla] = useState([]);
    const rows = [];
    const [scroll, setScroll] = React.useState('paper');
    const [info, setInfo] = useState([])



    useEffect(() => {

        const datosIniciales = async () => {
            let info = '';
            await axios.get(Global + "carta/persona/" + persona, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                info = response.data

            }).catch(error => console.log(error));
            setInfoTabla(info);
        }

        datosIniciales()

    }, [])
    
    if (infoTabla !== null && infoTabla !== '') {
        infoTabla.map((info, index) => {
            rows[index] = createData(info.idcarta, info.orotgante, info.correo,info.estatus, index + 1, info.status)
        });
    }
    const classes = useStyles();



    const handleClickOpen = (scrollType) => () => {

        setInfo(scrollType.split(",")[1])
        setOpen(true);
        setScroll(scrollType.split(",")[0]);
    };
    const handleClickDownload = (id) => () => {
        axios({
            url: Global + "pdf/carta/"+id, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Carta_recomendación.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })

    }

    const handleClose = () => {
        setOpen(false);
    }

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    return (
        <>
            <TableContainer >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nombre&nbsp;del&nbsp;otorgante</TableCell>
                            <TableCell align="left">Correo&nbsp;del&nbsp;otorgante</TableCell>
                            <TableCell align="left">Estatus&nbsp;del&nbsp;otorgante</TableCell>
                            {/* <TableCell align="left">ID</TableCell> */}
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.nombre}</TableCell>
                                <TableCell align="left">{row.correo}</TableCell>
                                <TableCell align="left">{row.estatus}</TableCell>
                               {/*  <TableCell align="left">{row.id}</TableCell> */}
                                <TableCell align="left">
                                    <Button
                                        onClick={
                                            handleClickOpen('paper,' + row.idcarta)
                                        }
                                        variant="contained"
                                        color="primary"
                                        className={classes.boton}>
                                        Ver carta
                                 </Button>
                                 {"  "}
                                 <Button
                                        onClick={

                                            handleClickDownload(row.idcarta)
                                        }
                                        variant="contained"
                                        color="primary"
                                        aling="left"
                                        className={classes.boton}>
                                        Imprimir Carta
                                 </Button>

                                  
                                    

                                </TableCell>

                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Carta de recomendación</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <CartaOtorgante info={info} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CartaRecomendacion;