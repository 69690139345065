import React from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Paper,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";

const permisosAdmin = [
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
];
const permisosgenerales = [
  <BlockIcon />,
  <BlockIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
];
const consultaasistentegeneral = [
  <BlockIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
];
const consultaevaluador = [
  <BlockIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
  <CheckIcon />,
];
const edicionasistentes = [
  <BlockIcon />,
  <BlockIcon />,
  <BlockIcon />,
  <BlockIcon />,
  <BlockIcon />,
];
const edicionprofesor = [
  <BlockIcon />,
  <BlockIcon />,
  <BlockIcon />,
  <CheckIcon />,
  <BlockIcon />,
];
const edicionalumno = [
  <BlockIcon />,
  <BlockIcon />,
  <BlockIcon />,
  <BlockIcon />,
  <CheckIcon />,
];

function PermisosConsulta(props) {
  const { value } = props;
  let id = 0;
  return (
    <>
      {value === "administrador" || value === "coordinador_general"
        ? permisosAdmin.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
        : value === "asistente_coordinador_general"
        ? consultaasistentegeneral.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))

          : value === "evaluador_aspirante"
        ? consultaevaluador.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))


        /*   : value ==="administrativo_coordinacion"
          ? consultaasistentegeneral.map((permisos)=>(
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          )) */
        : value === "coordinador_posgrado" ||
          value === "asistente_coordinador_posgrado" ||
         /*  value==="personal_administrativo"|| */
          value === "profesor" ||
          value === "alumno"
        ? permisosgenerales.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
        : null}
    </>
  );
}

function PermisosEdicion(props) {
  const { value } = props;
  let id = 0;
  console.log(value) //observar como se recibe el value
  return (
    <>
      {value === "administrador" || value === "coordinador_general"
        ? permisosAdmin.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
        : value === "asistente_coordinador_general" ||
          value === "asistente_coordinador_posgrado" ||
        /*   value=== "personal_administrativo" */
          value === "evaluador_aspirante"
        ? edicionasistentes.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
         /* :value=== "administrativo_coordinacion"
         ? edicionasistentes.map((permisos)=>(
          <TableCell key={id++} align="center">
            {permisos}
          </TableCell>
          )) */
        : value === "coordinador_posgrado"
        ? permisosgenerales.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
        : value === "profesor"
        ? edicionprofesor.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
        : value === "alumno"
        ? edicionalumno.map((permisos) => (
            <TableCell key={id++} align="center">
              {permisos}
            </TableCell>
          ))
        : null}
    </>
  );
}

const useStyles = makeStyles((theme) => ({

    table: {
      marginTop: theme.spacing(2),
    },

  }));

export default function TablaPermisos(props) {

  const classes = useStyles()
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell variant="head"></TableCell>
            <TableCell>Estadísticas</TableCell>
            <TableCell>Datos del SIIP</TableCell>
            <TableCell>Datos de Posgrado</TableCell>
            <TableCell>Datos de Academico</TableCell>
            <TableCell>Datos de Alumno/Aspirante</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Consulta</TableCell>
            {/* ya no se pasa como props.values si no en singular */}
            <PermisosConsulta value={props.value.rol} />
          </TableRow>
          <TableRow>
            <TableCell variant="head">Edición</TableCell>

            <PermisosEdicion value={props.value.rol} />
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
