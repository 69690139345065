// React dependencies
import React, { useState, useContext, useEffect } from "react";
// Components
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import DropzoneComponent from "./DropzoneComponent";

// Materia dependencies
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Breadcrumbs, Link } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// Context
import { UploadContext } from "./UploadContext";
import { Auth } from "../../../context/AuthContext";

const ImportaArchivos = ({ history }) => {
  const stateConfig = {
    csv: [],
    csvName: "",
    csvSize: "",
    entidad: "",
    resetState: false,
  };
  const [uploadState, setUploadState] = useState(stateConfig);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      minHeight: "80vh",
    },
    grow: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    paperContainer: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    titulo: {
      padding: "1em",
      fontSize: "2em",
      lineHeight: "1em",
      color: "#54595F",
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600,
      textTransform: "capitalize",
      textAlign: "left",
    },
    link: {
      display: "flex",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }));
  const classes = useStyles();
  const { usuario } = useContext(Auth);
  useEffect(() => {
    if (usuario === null) {
      history.push("/siip");
    }
  });

  return (
    <UploadContext.Provider value={{ uploadState, setUploadState }}>
      <div className={classes.root}>
        <Head />
        <Paper elevation={3} className={classes.paperContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link href="/inicio" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Inicio
            </Link>
            <Link
              color="textPrimary"
              href="/siip/importaArchivos"
              aria-current="page"
              className={classes.link}
            >
              <CloudUploadIcon className={classes.icon} />
              Importar archivos
            </Link>
          </Breadcrumbs>
          <Typography variant="h5" className={classes.titulo}>
            Importa Archivos
          </Typography>
          <DropzoneComponent />
        </Paper>
        <Pie />
      </div>
    </UploadContext.Provider>
  );
};

export default ImportaArchivos;
