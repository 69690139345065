import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

import { Global } from "../../../constants/global";
import alerta from "./PlantillaAlerta";

const useStyles = makeStyles((theme) => ({
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ValidaDatosContacto = Yup.object().shape({
  telefono_usuario: Yup.string()
    .required("Introduce tu teléfono de casa")
    .matches(/^[0-9]+$/, "Introduce sólo dígitos")
    .min(10, "El número debe ser de 10 dígitos")
    .max(10, "El número debe ser de 10 dígitos"),
  celular_usuario: Yup.string()
    .required("Introduce tu celular")
    .matches(/^[0-9]+$/, "Introduce sólo dígitos")
    .min(10, "El número debe ser de 10 dígitos")
    .max(10, "El número debe ser de 10 dígitos"),
  correo_contacto: Yup.string()
    .required("Introduce el correo del contacto")
    .email("Introduce un correo válido"),
  telefono_contacto: Yup.string()
    .required("Introduce el teléfono de contacto")
    .matches(/^[0-9]+$/, "Introduce sólo dígitos")
    .min(10, "El número debe ser de 10 dígitos")
    .max(10, "El número debe ser de 10 dígitos"),
  nombre_completo: Yup.string()
    .required("Introduce el nombre completo del contacto")
    .matches(
      /^[A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ -]+$/,
      "Solo letras"
    ),
  parentesco_contacto: Yup.string()
    .required("Introduce el parentesco")
    .matches(
      /^[A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ -]+$/,
      "Solo letras"
    ),
});

export default function DatosContacto(props) {
  const classes = useStyles();
  const { persona } = props;
  const [cargando, setCargando] = useState(true);
  const [datosContactoVacio, setDatosContactoVacio] = useState({
    iddireccion: 0,
    calle: "",
    colonia: "",
    informacion: "",
    idpais: "",
    pais: "",
    nacionalidad: "",
    telefono_persona: "",
    celular_persona: "",
  });
  const [datosContacto, setDatosContacto] = useState({
    idCEM: null,
    nombre_contacto: "",
    telefono_contacto: "",
    parentesco_contacto: "",
    correo_contacto: "",
    telefono_persona: "",
    celular_persona: "",
  });
  //guarda el domicilio del usuario relacionado al contacto de emergencia que se actualiza en la pestaña
  //datos de contacto
  const [domicilioUsuarioContactos, setDomicilioUsuarioContacto] = useState({
    calle: "",
    colonia: "",
    iddireccion: 0,
    informacion: "",
    idpais: 0,
    nacionalidad: "",
    pais: "",
  });
  /*********************************************** */
  useEffect(async () => {
    const cargaDatosUsuario = async () => {
      await axios
        .get(Global + "contactoem/personas/" + persona.idP, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const respuestaContactosEM = response.data;          
          
          setDatosContacto({
            idCEM: respuestaContactosEM[0].idCEM,
            nombre_contacto: respuestaContactosEM[0].nombre,
            parentesco_contacto: respuestaContactosEM[0].parentesco,
            telefono_contacto: respuestaContactosEM[0].telefono,
            correo_contacto: respuestaContactosEM[0].correo,
            telefono_persona: respuestaContactosEM[0].persona.telefono,
            celular_persona: respuestaContactosEM[0].persona.celular,
          });
          setDomicilioUsuarioContacto({
            calle: respuestaContactosEM[0].d.calle,
            colonia: respuestaContactosEM[0].d.colonia,
            iddireccion: respuestaContactosEM[0].d.iddireccion,
            informacion: respuestaContactosEM[0].d.informacion,
            idpais: respuestaContactosEM[0].pais.idpais,
            nacionalidad: respuestaContactosEM[0].pais.nacionalidad,
            pais: respuestaContactosEM[0].pais.pais,
          });
        })
        .catch((error) => {});
      //esta llamada al servicio personad/personas/persona.idP
      //es en el caso de que la persona no tenga ningun dato de contacto
      //se usa put para actualizar los datos, se usa post para crearlos
      await axios
        .get(Global + "personad/personas/" + persona.idP, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const creaContactoEmergencia = response.data[0];

          setDatosContactoVacio({
            iddireccion: creaContactoEmergencia.direccion.iddireccion,
            calle: creaContactoEmergencia.direccion.calle,
            colonia: creaContactoEmergencia.direccion.colonia,
            informacion: creaContactoEmergencia.direccion.informacion,
            idpais: creaContactoEmergencia.direccion.pais.idpais,
            pais: creaContactoEmergencia.direccion.pais.pais,
            nacionalidad: creaContactoEmergencia.direccion.pais.nacionalidad,
            telefono_persona: creaContactoEmergencia.persona.telefono,
            celular_persona: creaContactoEmergencia.persona.celular,
          });
        })
        .catch((error) => {});
    };
    await cargaDatosUsuario();
    setCargando(false);
  }, []);

  return (
    <>
      {cargando ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Formik
          initialValues={{
            //datosContacto guarda la info que se obtiene del servicio contactoem, aunque no haya datos, el telefono y celular son
            //de la persona registrada , esos datos ya se registraron al principio
            telefono_usuario:
              datosContacto.telefono_persona === ""
                ? datosContactoVacio.telefono_persona
                : datosContacto.telefono_persona,
            celular_usuario:
              datosContacto.celular_persona === ""
                ? datosContactoVacio.celular_persona
                : datosContacto.celular_persona,
            nombre_completo: datosContacto.nombre_contacto,
            correo_contacto: datosContacto.correo_contacto,
            parentesco_contacto: datosContacto.parentesco_contacto,
            telefono_contacto: datosContacto.telefono_contacto,
          }}
          validationSchema={ValidaDatosContacto}
          onSubmit={(values, actions) => {
            let updateContacto = {
              idP: persona.idP,
              nombre: persona.nombre,
              apellidoP: persona.apellidoP,
              apellidoM: persona.apellidoM,
              estado_civil: persona.estado_civil,
              telefono: values.telefono_usuario,
              celular: values.celular_usuario,
              correo: persona.correo,
              acta_nac: persona.acta_nac,
              curp: persona.curp,
              cv: persona.cv,
              cvu: persona.cvu,
              doc_curp: persona.doc_curp,
              email: persona.email,
              fecha_na: persona.fecha_na,
              fotografia: persona.fotografia,
              genero: persona.genero,
              orcid: persona.orcid,
              rfc: persona.rfc,
            };
            (async () => {
              axios
                .put(Global + "persona/" + persona.idP, updateContacto, {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                })
                .then((response) => {
                  let updateContactoEm = {
                    correo: values.correo_contacto,
                    d: {
                      calle: domicilioUsuarioContactos.calle,
                      colonia: domicilioUsuarioContactos.colonia,
                      iddireccion: domicilioUsuarioContactos.iddireccion,
                      informacion: domicilioUsuarioContactos.informacion,
                      pais: {
                        idpais: domicilioUsuarioContactos.idpais,
                        nacionalidad: domicilioUsuarioContactos.nacionalidad,
                        pais: domicilioUsuarioContactos.pais,
                      },
                    },

                    idCEM: datosContacto.idCEM,
                    nombre: values.nombre_completo,
                    parentesco: values.parentesco_contacto,
                    persona: {
                      idP: persona.idP,
                      nombre: persona.nombre,
                      apellidoP: persona.apellidoP,
                      apellidoM: persona.apellidoM,
                      estado_civil: persona.estado_civil,
                      telefono: values.telefono_usuario,
                      celular: values.celular_usuario,
                      correo: persona.correo,
                      acta_nac: persona.acta_nac,
                      curp: persona.curp,
                      cv: persona.cv,
                      cvu: persona.cvu,
                      doc_curp: persona.doc_curp,
                      email: persona.email,
                      fecha_na: persona.fecha_na,
                      fotografia: persona.fotografia,
                      genero: persona.genero,
                      orcid: persona.orcid,
                      rfc: persona.rfc,
                    },
                    telefono: values.telefono_contacto,
                  };
                  //si ya se cuenta con un dato de contacto asociado, debe tener un idCEM, por lo que se usa put , aunque los campos esten
                  //vacios
                  if (datosContacto.idCEM !== null) {
                    axios
                      .put(
                        Global + "contactoem/" + datosContacto.idCEM,
                        updateContactoEm,
                        {
                          headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                              "GET,PUT,POST,DELETE",
                            "Content-Type": "application/json",
                            Authorization:
                              "Bearer " + window.localStorage.getItem("token"),
                          },
                        }
                      )
                      .then((response) => {                      
                        alerta("Datos de contacto actualizados", "success");
                      })
                      .catch((error) => {
                        alerta("Ha ocurrido un error.Inténtalo más tarde", "error");
                      });
                    //como no hay dato de contacto asociado, se hace un post
                  } else {
                    let createContactoEm = {
                      correo: values.correo_contacto,
                      d: {
                        calle: datosContactoVacio.calle,
                        colonia: datosContactoVacio.colonia,
                        iddireccion: datosContactoVacio.iddireccion,
                        informacion: datosContactoVacio.informacion,
                        pais: {
                          idpais: datosContactoVacio.idpais,
                          nacionalidad: datosContactoVacio.nacionalidad,
                          pais: datosContactoVacio.pais,
                        },
                      },

                      //no se registra un idCEM para que lo haga automaticamente la bd
                      nombre: values.nombre_completo,
                      parentesco: values.parentesco_contacto,
                      persona: {
                        idP: persona.idP,
                        nombre: persona.nombre,
                        apellidoP: persona.apellidoP,
                        apellidoM: persona.apellidoM,
                        estado_civil: persona.estado_civil,
                        telefono: values.telefono_usuario,
                        celular: values.celular_usuario,
                        correo: persona.correo,
                        acta_nac: persona.acta_nac,
                        curp: persona.curp,
                        cv: persona.cv,
                        cvu: persona.cvu,
                        doc_curp: persona.doc_curp,
                        email: persona.email,
                        fecha_na: persona.fecha_na,
                        fotografia: persona.fotografia,
                        genero: persona.genero,
                        orcid: persona.orcid,
                        rfc: persona.rfc,
                      },
                      telefono: values.telefono_contacto,
                    };

                    axios
                      .post(
                        Global +
                          "contactoem/direccion/" +
                          datosContactoVacio.iddireccion +
                          "/persona/" +
                          persona.idP,
                        createContactoEm,
                        {
                          headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                              "GET,PUT,POST,DELETE",
                            "Content-Type": "application/json",
                            Authorization:
                              "Bearer " + window.localStorage.getItem("token"),
                          },
                        }
                      )
                      .then((response) => {
                        alerta("Datos de contacto actualizados", "success");
                      })
                      .catch((error) => {
                        alerta(
                          "Da de alta tu domicilio para actualizar el contacto de emergencia",
                          "error"
                        );
                      });
                  }
                })
                .catch((error) => {
                  alerta("Ha ocurrido un error. Inténtalo más tarde", "error");
                });
            })();

            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          }}
        >
          {(props) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={
                      props.touched.telefono_usuario &&
                      Boolean(props.errors.telefono_usuario)
                    }
                    required
                    fullWidth
                    label="Teléfono de casa"
                    variant="outlined"
                    name="telefono_usuario"
                    onBlur={props.handleBlur}
                    value={props.values.telefono_usuario}
                    helperText={
                      props.touched.telefono_usuario &&
                      props.errors.telefono_usuario
                    }
                    onChange={(e, values) => {
                      props.setFieldValue("telefono_usuario", e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="Teléfono celular"
                    name="celular_usuario"
                    onChange={(e, values) => {
                      props.setFieldValue("celular_usuario", e.target.value);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.celular_usuario}
                    error={
                      props.touched.celular_usuario &&
                      Boolean(props.errors.celular_usuario)
                    }
                    helperText={
                      props.touched.celular_usuario &&
                      props.errors.celular_usuario
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled
                    variant="filled"
                    label="Correo"
                    defaultValue={persona.correo}
                    fullWidth
                  />
                </Grid>
                {/* ******************Contacto de emergencia******************************* */}

                <Grid item xs={12}>
                  <Typography variant="h5">Contacto de emergencia</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="Nombre completo"
                    name="nombre_completo"
                    onChange={(e, values) => {
                      props.setFieldValue("nombre_completo", e.target.value);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.nombre_completo}
                    error={
                      props.touched.nombre_completo &&
                      Boolean(props.errors.nombre_completo)
                    }
                    helperText={
                      props.touched.nombre_completo &&
                      props.errors.nombre_completo
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="Correo"
                    name="correo_contacto"
                    onChange={(e, values) => {
                      props.setFieldValue("correo_contacto", e.target.value);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.correo_contacto}
                    error={
                      props.touched.correo_contacto &&
                      Boolean(props.errors.correo_contacto)
                    }
                    helperText={
                      props.touched.correo_contacto &&
                      props.errors.correo_contacto
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="Parestesco"
                    name="parentesco_contacto"
                    onChange={(e, values) => {
                      props.setFieldValue(
                        "parentesco_contacto",
                        e.target.value
                      );
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.parentesco_contacto}
                    error={
                      props.touched.parentesco_contacto &&
                      Boolean(props.errors.parentesco_contacto)
                    }
                    helperText={
                      props.touched.parentesco_contacto &&
                      props.errors.parentesco_contacto
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="Teléfono"
                    name="telefono_contacto"
                    onChange={(e, values) => {
                      props.setFieldValue("telefono_contacto", e.target.value);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.telefono_contacto}
                    error={
                      props.touched.telefono_contacto &&
                      Boolean(props.errors.telefono_contacto)
                    }
                    helperText={
                      props.touched.telefono_contacto &&
                      props.errors.telefono_contacto
                    }
                  />
                </Grid>

                <Grid container justify="center">
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      className={classes.boton}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
