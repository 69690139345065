import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../img/logoregistro.jpg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Encabezado from '../generales/Encabezado';
import { Divider, Paper } from '@material-ui/core';
import { fba } from "../../firebaseConfig";
import Link from '@material-ui/core/Link';
import Footer from "../generales/Footer";
import Mensaje from "./Mensaje"
import Aviso from "./aviso"
import axios from 'axios';
import { Global, Local } from '../../constants/global';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperIma: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-12),
        backgroundColor: '#fff',
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    back: {
        background: 'linear-gradient(0deg, #003366 5%, #FFFFFF 100%)',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    logo: {
        height: '160px',
        width: '120px'
    },
    registroaspirante:{
        color: "#d5303e",
    },
    avisojust: {
        align: "justify"
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "10%",
        marginRight: "10%",
    },
    subtitulo: {
        fontSize: "50px",
        /* color: "#d5303e", */
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
        textAlign: 'center'
    },
    divider: {
    color: 'error',
    backgroundColor: 'rgb(0 0 0 / 80%)',
    },

}));

let actionCodeSettings = {
    url: Local + '/password',
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link'
};

const RegistroAspirante = ({ history }) => {


    const [open, setOpen] = useState(false);
    const [openSB, setOpenSB] = useState(false);
    const [fecha, setFecha] = useState(false);

    useEffect(() => {

        axios.get(Global + "convocatoira/vigente", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            // console.log(response.data)
            if (response.data.length < 1) {
                setFecha(true);
            }
        }).catch(error => console.log(error))

    }, [])


    const formik = useFormik({
        initialValues: {
            // paterno: '',
            // materno: '',
            // nombre: '',
            email: ''
        },
        validationSchema: Yup.object({
            // paterno: Yup.string().required('El apellido paterno es obligatorio'),
            // materno: Yup.string().required('El apellido materno es obligatorio'),
            // nombre: Yup.string().required('El nombre es obligatorio'),
            email: Yup.string().required('El email es obligatorio').email('Ingrese un email valido'),
        }),
        onSubmit: async valores => {
            let existe = "";
            // let aspirante = {
            //     estatus: "Registro",
            //     persona: {
            //         apellidoP: valores.paterno,
            //         apellidoM: valores.materno,
            //         nombre: valores.nombre,
            //         correo: valores.email
            //     },
            // };

            //Hacemos un get para verificar si el correo existe
            await axios.get(Global + "persona/correo/" + valores.email, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                // console.log(response.data.length);
                if (response.data.length > 0) {
                    //Si existe 
                    // console.log("Si existe")
                    existe = response.data.length;
                } else {
                    //No existe
                    // console.log("No existe")
                    existe = response.data.length;
                }
            }).catch(error => console.log(error));

            // console.log(existe);
            if (existe > 0) {
                // console.log("Ya esta registrado");
                setOpenSB(true);
            } else {

                fba.auth().sendSignInLinkToEmail(valores.email, actionCodeSettings)
                    .then(function () {
                        setOpen(true);
                        window.localStorage.setItem('emailForSignIn', valores.email);
                        // window.localStorage.setItem('paterno', valores.paterno);
                        // window.localStorage.setItem('materno', valores.materno);
                        // window.localStorage.setItem('nombre', valores.nombre);
                        //fba.auth().signOut()
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
                // console.log("No existe");
            }
        }

    });

    const handleLogin = () => {
        history.push("/registro");
    }

    const handleClose = () => {
        setOpenSB(false);
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Encabezado />
            {fecha === true ? <Mensaje titulo="Registro de aspirantes
                " mensaje="El tiempo para el registro de aspirantes ha terminado." /> :
                !open ?
                    <Container maxWidth="xs" style={{ padding: '10px' }}>
                        <div className={classes.paperdiv} >
                            <div className={classes.paperIma}>
                                <Avatar className={classes.avatar}>
                                    <img src={logo} alt="" className={classes.logo} />
                                </Avatar>
                            </div>

                            <Paper elevation={3} className={classes.paper} >
                                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                                    <br />
                                    <Typography variant="h5" align="center">
                                        Registro de aspirantes
                                        <hr />
                                    </Typography>

                                    <Grid container spacing={2}>
                                        {/* <Grid item xs={12}>
                                            <TextField
                                                name="paterno"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="paterno"
                                                label="Apellido paterno"
                                                // autoFocus
                                                value={formik.values.paterno}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.paterno && formik.errors.paterno ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.paterno && formik.errors.paterno ? (
                                                    formik.errors.paterno
                                                ) : null}
                                                type='text'
                                            />

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="materno"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="materno"
                                                label="Apellido materno"
                                                value={formik.values.materno}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.materno && formik.errors.materno ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.materno && formik.errors.materno ? (
                                                    formik.errors.materno
                                                ) : null}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="nombre"
                                                label="Nombre"
                                                name="nombre"
                                                value={formik.values.nombre}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.nombre && formik.errors.nombre ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.nombre && formik.errors.nombre ? (
                                                    formik.errors.nombre
                                                ) : null}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.email && formik.errors.email ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.email && formik.errors.email ? (
                                                    formik.errors.email
                                                ) : null}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Registrar
                                    </Button>
                                    <div className="link-container">
                                        <Link onClick={handleLogin} className="link">
                                            Ya tengo una cuenta
                                        </Link>
                                    </div>
                                </form>
                            </Paper>
                        </div>
                        <Snackbar open={openSB} autoHideDuration={3000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error">
                                El usuario ya esta registrado.
                            </Alert>
                        </Snackbar>
                    </Container>
                    :
                    <>
                        {/* <Aviso titulo="Registro de aspirantes" mensaje="¡Gracias por su registro!, se ha enviado un correo electrónico con una liga de confirmación para continuar con el proceso de registro acceda a su correo electrónico" />
                        
                        <Aviso  titulo="AVISO DE PRIVACIDAD" mensaje=" La Coordinación General de Estudios de Posgrado de la Universidad Autónoma Chapingo (UACh), con domicilio en ubicación de las instalaciones, en Campus Universitario sitio en el Km. 38.5 de la carreterra México-Texcoco, Chapingo, Estado de México, es responsable del tratamiento de sus datos personales; por lo tanto están debidamente protegidos de conformidad con el Décimo Séptimo lineamiento para el Acceso a la Información de la UACh, del anexo al Acuerdo para la Transparencia, aprobado por el H. Consejo Universitario, según acuerdo 657-4 del 20 de ocutbre de 2003, y en los artículos 4, 20 y 21 de la Ley Federal de Transparencia y Acceso a la Información Pública Gubernamental.

                    Los datos que le solicitamos serán utilizados únicamente en la aplicación de los procedimientos de selección y de inscripción precisando que los datos de los aspirantes que no resultaron seleccionados, por sistema se eliminarán de manera automática en un plazo de veinte días, y los pertenecientes a los aspirantes aceptados, se mantendrán bajo la reserva que establece el lineamiento referido y los que establezcan las leyes en la materia.
                    "
                        /> */}
                <Paper elevation={3} className={classes.paperContainer}>
                    <Typography className={classes.subtitulo} >
                    Registro de Aspirantes
                    </Typography>
                    <br />
                    <Divider className={classes.divider} />
                    <Typography align='center' paragraph variant='h6' color='error'>
                    ¡Gracias por su registro!, se ha enviado un correo electrónico con una liga de confirmación para continuar con el proceso de registro acceda a su correo electrónico, y revise todas sus bandejas de correo incluida Spam
                    </Typography>
                    <Divider className={classes.divider} />
                    <br />
                </Paper>
                <Paper elevation={3} className={classes.paperContainer}>
                   
                    <br />
                    
                    <Typography className={classes.subtitulo} >
                    AVISO DE PRIVACIDAD
                    </Typography>
                    <br />
                    <Divider className={classes.divider} />
                    <Typography align="justify" paragraph variant='h6' color='textPrimary'>
                    La Coordinación General de Estudios de Posgrado de la Universidad Autónoma Chapingo (UACh), con domicilio en ubicación de las instalaciones, en Campus Universitario sitio en el Km. 38.5 de la carreterra México-Texcoco, Chapingo, Estado de México, es responsable del tratamiento de sus datos personales; por lo tanto están debidamente protegidos de conformidad con el Décimo Séptimo lineamiento para el Acceso a la Información de la UACh, del anexo al Acuerdo para la Transparencia, aprobado por el H. Consejo Universitario, según acuerdo 657-4 del 20 de octubre de 2003, y en los artículos 4, 20 y 21 de la Ley Federal de Transparencia y Acceso a la Información Pública Gubernamental.
                    <br />
                    Los datos que le solicitamos serán utilizados únicamente en la aplicación de los procedimientos de selección y de inscripción precisando que los datos de los aspirantes que no resultaron seleccionados, por sistema se eliminarán de manera automática en un plazo de veinte días, y los pertenecientes a los aspirantes aceptados, se mantendrán bajo la reserva que establece el lineamiento referido y los que establezcan las leyes en la materia.
                   </Typography>
                    <Divider className={classes.divider} />
                    <br />

                </Paper>
                    </>
            }
            <Footer />
        </div >
    );
}

export default RegistroAspirante;
