import React, { useEffect, useState } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import { SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FORMATOFECHAMINHOY } from '../../generales/Fecha';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AdscripcionProfesor = (props) => {
    // console.log(props)
    const classes = useStyles();
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [recarga, setRecarga] = useState(false)
    const [persona, setPersona] = useState(props.data.profesor.persona)

    const [SniMensaje, setSniMensaje] = useState(null)
    const [SniMensaje2, setSniMensaje2] = useState(null)
    const [fileSni, setFileSni] = useState(0)
    const [uploadSni, setUploadSni] = useState(0)

    const [CvuMensaje, setCvuMensaje] = useState(null)
    const [CvuMensaje2, setCvuMensaje2] = useState(null)
    const [fileCvu, setFileCvu] = useState(0)
    const [uploadCvu, setUploadCvu] = useState(0)
    const [acceso, setAcceso] = useState(true)



    useEffect(async () => {
        setRegistroAnterior({
            idsni: props.data.idsni,
            nivel: props.data.nivel,
            registro: props.data.registro,
            fechain: props.data.fechain,
            fechafin: props.data.fechafin,
            archivosni: props.data.archivosni,
            archivocvu: props.data.archivocvu,
        })

        props.rol === "administrador" ||
            props.rol === "coordinador_general" ||
            props.rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)


        if (props.data.archivosni) {
            // console.log(props.data.dip)
            let storage3 = fba.storage().ref();
            let httpsReference3 = storage3.child('' + props.data.archivosni)
            setSniMensaje2(await httpsReference3.getDownloadURL());
            setUploadSni(100)
            setSniMensaje('Archivo cargado')
        }
        if (props.data.archivocvu) {
            // console.log(props.data.dip)
            let storage3 = fba.storage().ref();
            let httpsReference3 = storage3.child('' + props.data.archivocvu)
            setCvuMensaje2(await httpsReference3.getDownloadURL());
            setUploadCvu(100)
            setCvuMensaje('Archivo cargado')
        }
        handleClose()
    }, [recarga])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        nivel: Yup.string().required('Es obligatorio seleccionar el nivel'),
        fechain: Yup.string().required('Es obligatorio ingresar la fecha de incio'),
        archivosni: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileSni === 0 || SUPPORTED_PDF.includes(fileSni.type))),
        archivocvu: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileCvu === 0 || SUPPORTED_PDF.includes(fileCvu.type))),
    })

    const initial = registroAnterior
    // console.log(registroAnterior)


    const funcionSubmit = async valores => {
        // console.log(valores)
        var linkSni = registroAnterior.archivosni;
        var linkCvu = registroAnterior.archivosni;
        if (fileSni) {
            if (linkSni === '' || linkSni === null) {
                //console.log('entro')
                linkSni = persona.correo + '/DocumentosSni/' + uuidv4() + fileSni.name
            }
            const storageRef = fba.storage().ref(linkSni)
            const task = storageRef.put(fileSni)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadSni(porcentage)
                setSniMensaje(porcentage + '%')
            }, (error) => {
                setSniMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }
        if (fileCvu) {
            if (linkCvu === '' || linkCvu === null) {
                //console.log('entro')
                linkCvu = persona.correo + '/DocumentosSni/' + uuidv4() + fileCvu.name
            }
            const storageRef = fba.storage().ref(linkCvu)
            const task = storageRef.put(fileCvu)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadCvu(porcentage)
                setCvuMensaje(porcentage + '%')
            }, (error) => {
                setCvuMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        let sni = {
            nivel: valores.nivel,
            registro: valores.registro,
            fechain: valores.fechain,
            fechafin: valores.fechafin,
            estatus: valores.estatus,
            archivosni: linkSni,
            archivocvu: linkCvu,
            profesor: {
                idprofesor: props.data.profesor.idprofesor
            }
        };

        await axios.put(Global + "sni/" + registroAnterior.idsni, sni, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response);
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
        }).catch(error => {
            console.log(error);
        })
        props.metodo()
    }

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            // console.log(event)
            const file = event.target.files[0]

            switch (event.target.id) {
                case "archivosni":
                    setFileSni(file)
                    setUploadSni(0)
                    setSniMensaje('')
                    break;
                case "archivocvu":
                    setFileCvu(file)
                    setUploadCvu(0)
                    setCvuMensaje('')
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "archivosni":
                    setFileSni(false)
                    break;
                case "archivocvu":
                    setFileCvu(false)
                    break;
                default:
                    break;
            }
        }
    }
    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>

                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            //console.log(props);
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                className={classes.formControl}
                                                error={props.touched.nivel && props.errors.nivel ? (true) : false}>
                                                <InputLabel>Nivel</InputLabel>
                                                <Select
                                                    labelId="nivel"
                                                    native
                                                    value={props.values.nivel}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="nivel"
                                                    inputProps={{
                                                        name: 'nivel',
                                                        id: 'nivel',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'Candidato'}>Candidato</option>
                                                    <option value={'Nivel 1'}>Nivel 1</option>
                                                    <option value={'Nivel 2'}>Nivel 2</option>
                                                    <option value={'Nivel 3'}>Nivel 3</option>
                                                    <option value={'Emérito'}>Emérito</option>

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.nivel && props.errors.nivel ? (props.errors.nivel) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Registro"
                                                name="registro"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.registro}
                                                onChange={(e) => {
                                                    let registro = e.target.value;
                                                    props.setFieldValue("registro", registro.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.registro && props.errors.registro ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.registro && props.errors.registro ? (
                                                    props.errors.registro
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Fecha de inicio"
                                                name="fechain"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                disabled={acceso}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                //defaultValue={escolaridadAnterior[0].promedio}
                                                value={props.values.fechain}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechain && props.errors.fechain ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null}
                                                type="date"
                                                max="100"
                                                InputProps={FORMATOFECHAMINHOY}
                                            />
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Fecha de Termino"
                                                name="fechafin"
                                                variant="outlined"
                                                required
                                                disabled={acceso}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                //defaultValue={escolaridadAnterior[0].promedio}
                                                value={props.values.fechafin}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechafin && props.errors.fechafin ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechafin && props.errors.fechafin ? (
                                                    props.errors.fechafin
                                                ) : null}
                                                type="date"
                                                max="100"
                                                InputProps={FORMATOFECHAMINHOY}
                                            />
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10} sm={6}>
                                            <label htmlFor="archivosni">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="archivosni"
                                                    name="archivosni"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento SNI
                                                    {fileSni ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.archivosni && props.errors.archivosni) && (props.errors.archivosni)}
                                                </Typography>
                                            </label>
                                            {SniMensaje2 !== null && SniMensaje2 !== '' ?
                                                <a style={{ display: "table-cell" }} href={SniMensaje2} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                                </a> : "Sin documento"}
                                        </Grid>
                                        <Grid item xs={12} sm={1}></Grid>
                                        <Grid item xs={10} sm={6}>
                                            <label htmlFor="archivocvu">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="archivocvu"
                                                    name="archivocvu"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento CVU
                                                    {fileCvu ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.archivocvu && props.errors.archivocvu) && (props.errors.archivocvu)}
                                                </Typography>
                                            </label>
                                            {CvuMensaje2 !== null && CvuMensaje2 !== '' ?
                                                <a style={{ display: "table-cell" }} href={CvuMensaje2} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                                </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    {
                                        !acceso ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            :
                                            null
                                    }
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
        </div >
    );
}

export default AdscripcionProfesor;