import React, { useEffect, useContext } from 'react'
import { FormControl, Grid, InputLabel, Select, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import Swal from 'sweetalert'
import { Button, Hidden } from '@material-ui/core';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";

function ProgramaUnidad(props) {

    const [open, setOpen] = React.useState(false);
    const [programas, setProgramas] = React.useState([]);
    const [idprograma, setidPrograma] = React.useState('');
    const { idunidad, } = props.data;
    const { useState } = React;

    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    const [columns, setColumns] = useState([
        { title: 'Nombre', field: 'programa.programa' },
        { title: 'Nivel', field: 'programa.tipo' }
    ]);

    const [data, setData] = useState([]);

    useEffect(() => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }
                });
            });
        }

        const datosIniciales = async () => {
            //Remplazar esta consulta por una de estado tambien 
            await axios.get(Global + "programau/unidad/" + idunidad + "/estatus/alta", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setData(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "programa/unidad/" + idunidad, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setProgramas(response.data)
            }).catch(error => console.log(error))
        }

        datosIniciales();

    }, [])

    const handleClickOpen = () => {
        setOpen(true);
        setidPrograma('')
    };

    const handleClose = () => {
        setOpen(false);
        setidPrograma('')
    };

    const handleSubmit = () => {
        if (idprograma !== '') {
            axios.post(Global + "programau/programa/" + idprograma + "/unidad/" + idunidad,
                {
                    estatus: 'alta'
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    setidPrograma('')
                    axios.get(Global + "programau/unidad/" + idunidad, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setData(response.data)
                    }).catch(error => console.log(error))

                    axios.get(Global + "programa/unidad/" + idunidad, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response.data)
                        setProgramas(response.data)
                    }).catch(error => console.log(error))
                    setOpen(false);
                    Swal({
                        title: 'Programa agregado',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                }).catch(error => console.log(error))
        } else {
            Swal({
                title: '¡Debe seleccionar un programa!',
                text: ' ',
                icon: 'error',
                button: false,
                timer: 1000
            })
        }
    }
    const handleOnChange = (e) => {
        setidPrograma(e.target.value)
    }

    return (
        <div>
            {
                rol === "coordinador_general" ?
                    <>
                        <MaterialTable
                            options={{
                                exportButton:true,
                                exportFileName:"Programas pertenecientes a la unidad",
                                search: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30],
                                headerStyle: {
                                    backgroundColor: '#66abe0b8',
                                    color: '#FFF'
                                }
                            }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '',
                                    labelRowsSelect: '',
                                    firstTooltip: 'Primera página',
                                    previousTooltip: 'Página anterior',
                                    nextTooltip: 'Siguiente página',
                                    lastTooltip: 'Última página',
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay información por mostrar',
                                    addTooltip: 'Agregar',
                                    editTooltip: 'Editar',
                                    deleteTooltip: 'Deshabilitada',
                                    editRow: {
                                        deleteText: '¿Desea eliminar este programa de la unidad academica?',
                                        cancelTooltip: 'Cancelar',
                                        saveTooltip: 'Guardar'
                                    }
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar en la tabla',
                                    searchPlaceholder: 'Buscar',
                                    exportCSVName: "Exportar como CSV",
                                    exportPDFName: "Exportar como PDF",
                                    exportTitle: "Exportar"
                                },
                                header: {
                                    actions: 'Acciones'
                                }

                            }}
                            title={
                                <Hidden smDown>
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center">
                                        <Grid item>
                                            <Typography >
                                                Programas pertenecientes a la unidad
                        </Typography>
                                        </Grid>
                                    </Grid>
                                </Hidden>}
                            columns={columns}
                            data={data}
                            actions={[
                                {
                                    icon: 'add_box',
                                    tooltip: 'Agregar',
                                    isFreeAction: true,
                                    onClick: (event) => handleClickOpen()
                                }
                            ]}
                            editable={{
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(async () => {
                                            const dataDelete = [...data];
                                            const index = oldData.tableData.id;
                                            var changeData = {
                                                idpu: dataDelete[index].idpu,
                                                programa: dataDelete[index].programa,
                                                unidadAcademica: dataDelete[index].unidadAcademica,
                                                estatus: 'baja',
                                            }
                                            // console.log(dataDelete);
                                            // console.log(changeData);

                                            dataDelete.splice(index, 1);
                                            setData([...dataDelete]);
                                            await axios.put(Global + "programau/" + changeData.idpu, changeData,
                                                {
                                                    headers: {
                                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                        'Content-Type': 'application/json',
                                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                    }
                                                }).then(response => {
                                                    Swal({
                                                        title: 'Unidad deshabilitada',
                                                        text: ' ',
                                                        icon: 'success',
                                                        button: false,
                                                        timer: 1000
                                                    })
                                                }).catch(error => {
                                                    console.log(error);
                                                })

                                            resolve()
                                        }, 1000)
                                    }),
                            }}
                        />
                        <Dialog open={open} onClose={handleClose} >
                            <DialogTitle>
                                <DialogContentText style={{ marginBottom: -10 }}>Seleccione el programa que desea agregar a la unidad académica</DialogContentText>
                            </DialogTitle>
                            <DialogContent>

                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required>
                                    <InputLabel id="programa">Programa</InputLabel>
                                    <Select
                                        labelId="programa"
                                        native
                                        label="Programa"
                                        onChange={e => {
                                            handleOnChange(e);
                                            // alert(e.target.value)
                                        }}
                                        inputProps={{
                                            name: 'programa',
                                            id: 'programa',
                                        }}
                                    >
                                        <option aria-label="None" value="" />{
                                            programas.map((pro) => {
                                                return <option key={pro.idprograma} value={pro.idprograma}>{pro.programa}</option>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button onClick={handleSubmit}>
                                    Enviar
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                    :
                    <MaterialTable
                        options={{
                            exportButton:true,
                            exportFileName:"Programas pertenecientes a la unidad",
                            search: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay información por mostrar',
                                addTooltip: 'Agregar',
                                editTooltip: 'Editar',
                                deleteTooltip: 'Deshabilitada',
                                editRow: {
                                    deleteText: '¿Desea eliminar este programa de la unidad academica?',
                                    cancelTooltip: 'Cancelar',
                                    saveTooltip: 'Guardar'
                                }
                            },
                            toolbar: {
                                searchTooltip: 'Buscar en la tabla',
                                searchPlaceholder: 'Buscar',
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                exportTitle: "Exportar"
                            },
                            header: {
                                actions: 'Acciones'
                            }

                        }}
                        title={
                            <Hidden smDown>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography >
                                            Programas pertenecientes a la unidad
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>}
                        columns={columns}
                        data={data}
                    />
            }
        </div>
    )
}

export default ProgramaUnidad;