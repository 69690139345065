import peticionesHttp from "./peticionesHTTP.js";

export const obtenerAspirantes = async (doc=[],endpoint="") => {

return new Promise((resolve,reject)=>{
    
    const arregloPosgrados = doc.data().posgrado;
    let posgrados = [];
    let arregloPromesas = [];
    arregloPosgrados.forEach((posgrado)=>{      
        const apiRequest = peticionesHttp.getReq(`${endpoint}${posgrado}`)
        arregloPromesas = [...arregloPromesas,apiRequest];
    });
    
    Promise.all(arregloPromesas)
    .then(response => {
        response.forEach(respuesta=>{
            posgrados = [...posgrados,respuesta.data];            
        })
        resolve(posgrados)
    })
    .catch(err =>reject(err))
})
      
};
