import Swal from "sweetalert";

export default function alerta(message, icon="success") {
  Swal({
    title: message,
    text: " ",
    icon: icon,
    button: false,
    timer: 2000,
  });
}
