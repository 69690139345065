import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


const fba = firebase.initializeApp({
    
    // Api de produccion
     apiKey: "AIzaSyCSfm7ItEqSdWDAFP48Wy3nFDxanxWGD8Y",
     authDomain: "pro-uach-siip.firebaseapp.com",
     projectId: "pro-uach-siip",
     storageBucket: "pro-uach-siip.appspot.com",
     messagingSenderId: "337159289511",
     appId: "1:337159289511:web:1d81460355b6c190ad3982",
     measurementId: "G-2XZZVS1YRL"
});
export { fba };
