import React, { useEffect, useContext, useState } from 'react';
import { Backdrop, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography, Breadcrumbs, Link } from '@material-ui/core'
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from "../../../context/AuthContext";
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Swal from 'sweetalert';
import HomeIcon from "@material-ui/icons/Home";
import TimelineIcon from '@material-ui/icons/Timeline';
import BookIcon from '@material-ui/icons/Book';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    link: {
        display: "flex",
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

function Index({ history }) {

    const { usuario } = useContext(Auth);
    const [materias, setMaterias] = useState([])
    const [profesores, setProfesores] = useState([])
    const [carga, setCarga] = useState(false)
    useEffect(() => {
        if (usuario === null) {
            history.push("/siip");
        }
    })
    const initial = {
        matricula: '',
        semestre: '',
        firma: ''
    }
    const inicial = {
        materia: '',
        profesor: '',
        semestre: '',
        departamento: '',
        firma: ''
    }

    useEffect(async () => {
        await axios.get(Global + "materia", {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setMaterias(response.data)
        }).catch(error => console.log(error))

        await axios.get(Global + "profesor", {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response.data)
            setProfesores(response.data)
        }).catch(error => console.log(error))
    }, [])
    const schemaValidacion = Yup.object({
        matricula: Yup.string().required('Es necesario ingresar la matricula'),
        firma: Yup.string().required('Es necesario ingresar el nombre de quien firma'),
    })
    const schemaValidacion2 = Yup.object({
        materia: Yup.string().required('Es necesario seleccionar la materia'),
        profesor: Yup.string().required('Es necesario seleccionar el profesor'),
        semestre: Yup.string().required('Es necesario seleccionar el semestre'),
        firma: Yup.string().required('Es necesario ingresar el nombre de quien firma'),
    })

    const funcionSubmit = async valores => {
        // console.log(valores)
        setCarga(true)
        axios({
            url: Global + "pdf/reporte/pdf/matricula/" + valores.matricula + "/coordinador/" + valores.firma, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Historial_alumno_' + valores.matricula + '.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setCarga(false)
        }).catch(error => {
            setCarga(false)
            Swal({
                title: 'Datos no encontrados',
                text: 'No se encontro información con los datos ingresados',
                icon: 'error',
                button: false,
                timer: 1000
            })
        })
    }
    const funcionSubmitCurso = async valores => {
        // console.log(valores)
        setCarga(true)
        axios({
            url: Global + "pdf/reporte/pdf/profesor/" + valores.profesor + "/semestre/" + valores.semestre + "/materia/" + valores.materia + "/responsable/" + valores.firma, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Historial_materia_' + valores.materia + '.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setCarga(false)
        }).catch(error => {
            setCarga(false)
            Swal({
                title: 'Datos no encontrados',
                text: 'No se encontro información con los datos ingresados',
                icon: 'error',
                button: false,
                timer: 1000
            })
        })
    }

    const classes = useStyles();
    return (
        <div >
            <Head />
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>
                {/* <Typography variant="h5" className={classes.titulo}>
                    Historial
                </Typography> */}
                {/* <br /> */}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link href="/inicio" className={classes.link}>
                        <HomeIcon className={classes.icon} />
                        Inicio
                    </Link>
                    <Link
                        aria-current="page"
                        className={classes.link}
                    >
                        <BookIcon className={classes.icon} />
                        Calificaciones
                    </Link>
                    <Link
                        color="textPrimary"
                        href="/historial"
                        aria-current="page"
                        className={classes.link}
                    >
                        <TimelineIcon className={classes.icon} />
                        Historial Académico
                    </Link>
                </Breadcrumbs>
                <Paper elevation={3} className={classes.paperContainer}>
                    <Typography className={classes.subtitulo}>
                        Historial académico por alumno
                    </Typography>
                    <br />
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            //console.log(props);
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Matrícula"
                                                name="matricula"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                //defaultValue={escolaridadAnterior[0].promedio}
                                                // value={props.values.ciclo}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.matricula && props.errors.matricula ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.matricula && props.errors.matricula ? (
                                                    props.errors.matricula
                                                ) : null}
                                            // type='number'
                                            // max="100"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Firmado por (Título. nombre completo)"
                                                name="firma"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                //defaultValue={escolaridadAnterior[0].promedio}
                                                value={props.values.firma}
                                                onChange={(e) => {
                                                    let firma = e.target.value;
                                                    props.setFieldValue("firma", firma.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.firma && props.errors.firma ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.firma && props.errors.firma ? (
                                                    props.errors.firma
                                                ) : null}
                                            // max="100"
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Generar PDF
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                </Paper>
                <Paper elevation={3} className={classes.paperContainer}>
                    <Typography className={classes.subtitulo}>
                        Historial académico por curso
                    </Typography>
                    <br />
                    <Formik
                        validationSchema={schemaValidacion2}
                        enableReinitialize
                        initialValues={inicial}
                        onSubmit={(valores) => {
                            funcionSubmitCurso(valores)
                        }}
                    >
                        {props => {

                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={props.touched.materia && props.errors.materia ? (true) : false}
                                                className={classes.formControl}
                                                required
                                            >
                                                <InputLabel >Materia</InputLabel>
                                                <Select
                                                    native
                                                    // value={props.values.pais}
                                                    onChange={e => {
                                                        // handleOnChangePais(e);
                                                        props.setFieldValue("materia", e.target.value);

                                                    }}
                                                    label="Materia"
                                                    inputProps={{
                                                        name: 'materia',
                                                        id: 'materia',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        materias.map((materia) => {
                                                            return <option key={materia.idmateria} value={materia.nombre}>{materia.nombre}</option>
                                                        })
                                                    }

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.materia && props.errors.materia ? (props.errors.materia) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={props.touched.profesor && props.errors.profesor ? (true) : false}
                                                className={classes.formControl}
                                                required
                                            >
                                                <InputLabel >Profesor</InputLabel>
                                                <Select
                                                    native
                                                    // value={props.values.pais}
                                                    onChange={e => {
                                                        // handleOnChangePais(e);
                                                        props.setFieldValue("profesor", e.target.value);

                                                    }}
                                                    label="profesor"
                                                    inputProps={{
                                                        name: 'profesor',
                                                        id: 'profesor',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        profesores.map((profesor) => {
                                                            return <option key={profesor.idprofesor} value={profesor.idprofesor}>
                                                                {profesor.abreviatura + " " + profesor.persona.nombre + " " + profesor.persona.apellidoP + " " + profesor.persona.apellidoM}
                                                            </option>
                                                        })
                                                    }

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.profesor && props.errors.profesor ? (props.errors.profesor) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={props.touched.semestre && props.errors.semestre ? (true) : false}>
                                                <InputLabel>Semestre</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.semestre}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Semestre"
                                                    inputProps={{
                                                        name: 'semestre',
                                                        id: 'semestre',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={"PRIMERO"}>PRIMERO</option>
                                                    <option value={"SEGUNDO"}>SEGUNDO</option>
                                                    <option value={"TERCERO"}>TERCERO</option>
                                                    <option value={"CUARTO"}>CUARTO</option>
                                                    <option value={"QUINTO"}>QUINTO</option>
                                                    <option value={"SEXTO"}>SEXTO</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.semestre && props.errors.semestre ? (props.errors.semestre) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Firmado por (Título. nombre completo)"
                                                name="firma"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                //defaultValue={escolaridadAnterior[0].promedio}
                                                value={props.values.firma}
                                                onChange={(e) => {
                                                    let firma = e.target.value;
                                                    props.setFieldValue("firma", firma.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.firma && props.errors.firma ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.firma && props.errors.firma ? (
                                                    props.errors.firma
                                                ) : null}
                                            // max="100"
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Generar PDF
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                </Paper>
            </Paper >
            <Pie />
        </div>

    );

}
export default Index;