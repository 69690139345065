import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table'
import { Hidden, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Global } from "../../../../constants/global"
import Swal from 'sweetalert'
import { Auth } from "../../../../context/AuthContext";
import { fba } from "../../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function TablaEtnia(props) {

    const classes = useStyles();
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(() => {
        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "etnia", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))

        }
        datos()
    }, [])

    const [columns, setColumns] = useState([
        {
            title: "Etnia", field: "etnia", validate: rowData =>
                rowData.etnia === '' ? { isValid: false, helperText: 'El nombre no puede estar vacio' }
                    :
                    rowData.etnia !== undefined && rowData.etnia !== "" && rowData.etnia !== null ?
                        rowData.etnia.trim().length !== rowData.etnia.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
    ])

    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

    }, [open]);

    return (
        <div className={classes.root}>
            {
                rol === "coordinador_general" ?
                    <MaterialTable
                        title={
                            <Hidden smDown>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo} >
                                            Listado de etnias
                    </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>}
                        options={{
                            exportButton:true,
                            exportFileName:"Listado de etnias",
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            toolbar: {
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                exportTitle: "Exportar",
                                searchPlaceholder: "Buscar"
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                addTooltip: "Agregar",
                                editTooltip: "Editar",
                                deleteTooltip: "Dar de baja",
                                editRow: {
                                    deleteText: "¿Esta seguro que desea dar de baja?",
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Guardar"
                                },
                                emptyDataSourceMessage: 'No hay información por mostrar',

                            }
                        }}
                        columns={columns}
                        data={data}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                        // console.log(newData)
                                        if (newData.etnia!== undefined) {
                                            axios.get(Global + "etnia/nombre/" + newData.etnia, {
                                                headers: {
                                                    'Access-Control-Allow-Origin': "http://localhost:3000",
                                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                }
                                            }).then(response => {
                                                if (response.data.length == 0) {

                                                    axios.post(Global + "etnia",
                                                        {
                                                            etnia: newData.etnia,

                                                        }, {
                                                        headers: {
                                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                            'Content-Type': 'application/json',
                                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                        }
                                                    }).then(response => {
                                                        Swal({
                                                            title: 'Etnia registrada',
                                                            text: ' ',
                                                            icon: 'success',
                                                            button: false,
                                                            timer: 1000
                                                        })
                                                        setData([...data, response.data]);
                                                    }).catch(error => console.log(error))

                                                } else {
                                                    Swal({
                                                        title: 'La etnia ya esta registrada',
                                                        text: ' ',
                                                        icon: 'error',
                                                        button: false,
                                                        timer: 2000
                                                    })
                                                }
                                            }).catch(error => console.log(error))
                                        } else {
                                            Swal({
                                                title: 'El nombre no puede estar vacío',
                                                text: ' ',
                                                icon: 'error',
                                                button: false,
                                                timer: 2000
                                            })
                                        }



                                        resolve();
                                    }, 1000)
                                }),
                            onRowUpdate: (newData, oldData, rowData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                        // console.log(oldData)
                                        // console.log(newData)
                                        // console.log(rowData)
                                        if (newData.etnia !== undefined) {
                                            await axios.get(Global + "etnia/nombre/" + newData.etnia, {
                                                headers: {
                                                    'Access-Control-Allow-Origin': "http://localhost:3000",
                                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                }
                                            }).then(response => {
                                                if (response.data.length == 0) {

                                                    axios.put(Global + "etnia/" + oldData.idetnia,
                                                        {
                                                            etnia: newData.etnia,
                                                            idetnia: oldData.idetnia

                                                        }, {
                                                        headers: {
                                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                            'Content-Type': 'application/json',
                                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                        }
                                                    }).then(response => {
                                                        // console.log(response);
                                                        const dataUpdate = [...data];
                                                        const index = oldData.tableData.id;
                                                        dataUpdate[index] = newData;
                                                        setData([...dataUpdate]);
                                                        Swal({
                                                            title: 'Etnia actualizada',
                                                            text: ' ',
                                                            icon: 'success',
                                                            button: false,
                                                            timer: 1000
                                                        })
                                                    }).catch(error => console.log(error))

                                                } else {
                                                    Swal({
                                                        title: 'La etnia ya esta registrada',
                                                        text: ' ',
                                                        icon: 'error',
                                                        button: false,
                                                        timer: 2000
                                                    })
                                                }
                                            }).catch(error => console.log(error))
                                        } else {
                                            Swal({
                                                title: 'El nombre no puede estar vacío',
                                                text: ' ',
                                                icon: 'error',
                                                button: false,
                                                timer: 2000
                                            })
                                        }

                                        resolve();
                                    }, 1000)
                                })
                        }}
                    />
                    :
                    <MaterialTable
                        title={
                            <Hidden smDown>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo} >
                                            Listado de etnias
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>}
                        options={{
                            exportButton:true,
                            exportFileName:"Listado de etnias",
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            toolbar: {
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                exportTitle: "Exportar",
                                searchPlaceholder: "Buscar"
                            },
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay información por mostrar',

                            }
                        }}
                        columns={columns}
                        data={data}

                    />
            }
        </div>

    )
}

export default TablaEtnia