import React, { useEffect, useState, useContext } from 'react'
import { Avatar, Paper, Typography, Button, TextField, Grid, FormControl, InputLabel, Select, FormHelperText, CircularProgress } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik"
import * as Yup from 'yup'
import axios from "axios";
import { Global } from "../../../constants/global"
import Swal from 'sweetalert'
import { fba } from "../../../firebaseConfig";
import { Auth } from "../../../context/AuthContext";
import Backdrop from '@material-ui/core/Backdrop';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { FORMATOFECHAMINHOY } from '../../generales/Fecha';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    foto: {
        width: '180px',
        height: '180px'
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));
function Beca(props) {

    const classes = useStyles();

    const { idalumno } = props.data;

    const [datos, setDatos] = useState([])
    const [becante, setBecante] = useState([])
    const [moneda, setMoneda] = useState([])
    const [open, setOpen] = useState(true);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [acceso, setAcceso] = useState(true)
    const [carga, setCarga] = useState(false)


    useEffect(() => {

        let beca = {
            conacyt: "",
            fechain: "",
            fechafin: "",
            moneda: "",
            becante: "",
            monton: "",
            observacion: "",
        }
        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            doc.data().rol === "administrador" ||
                                doc.data().rol === "coordinador_general" ||
                                doc.data().rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)
                        } else {
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "becante", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setBecante(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "moneda", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setMoneda(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "alumnobeca/alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    beca.conacyt = response.data[0].conacyt
                    beca.fechain = response.data[0].fechain
                    beca.fechafin = response.data[0].fechafin
                    beca.moneda = response.data[0].moneda.idmoneda
                    beca.becante = response.data[0].becante.idbecante
                    beca.monton = response.data[0].monton
                    beca.observacion = response.data[0].observacion
                }
            }).catch(error => console.log(error))

            setDatos(beca)
            setOpen(false)
        }

        datos()


    }, [])

    const schemaValidation = Yup.object({
        fechain: Yup.string().required("La fecha es obligatoria").test('match',
            'La fecha de inicio no debe ser mayor a la fecha de fin',
            function (fechain) {
                return fechain < this.parent.fechafin
            }),
        fechafin: Yup.string().required("La fecha es obligatoria").test('match',
            'La fecha de fin no debe ser menor a la fecha de inicio',
            function (fechafin) {
                return fechafin > this.parent.fechain
            }),
        // .when('fechain', (fechafin, schema) => {
        //     return schema.test({
        //         test: fechafin => fechafin > fechafin,
        //         message: "La fecha de fin debe ser mayor que la fecha de inicio"
        //     })
        // }),
        becante: Yup.string().required("Es obligatorio ingresar la institución"),
        conacyt: Yup.number().positive('El valor debe ser mayor a 0'),
        monton: Yup.number().positive('El valor debe ser mayor a 0'),
        observacion: Yup.string().matches(/^[a-zA-z1-9][a-zA-Z0-9]*$/, "Sin caracteres especiales"),
    })

    const functionSubmit = async (valores) => {
        setCarga(true)
        let beca = {
            conacyt: valores.conacyt,
            fechain: valores.fechain,
            fechafin: valores.fechafin,
            moneda: {
                idmoneda: valores.moneda
            },
            becante: {
                idbecante: valores.becante
            },
            monton: valores.monton,
            observacion: valores.observacion,
        }

        await axios.get(Global + "alumnobeca/alumno/" + idalumno, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async response => {

            if (response.data.length > 0) {
                await axios.put(Global + "alumnobeca/" + response.data[0].idab, beca, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    setCarga(false)

                }).catch(error => console.log(error))
            } else {
                await axios.post(Global + "alumnobeca/alumno/" + idalumno + "/becante/" + valores.becante + "/moneda/" + valores.moneda, beca, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    setCarga(false)
                }).catch(error => console.log(error))
            }
        }).catch(error => console.log(error))



    }

    const handleClose = () => {
        setOpen(false);
    };



    const initial = datos

    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <MonetizationOnIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Beca
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Formik
                        validationSchema={schemaValidation}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(values) => {
                            functionSubmit(values)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Desde"
                                                id="fechain"
                                                name="fechain"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fechain}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechain && props.errors.fechain ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Hasta"
                                                id="fechafin"
                                                name="fechafin"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fechafin}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechafin && props.errors.fechafin ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechafin && props.errors.fechafin ? (
                                                    props.errors.fechafin
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                />
                                        </Grid>


                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                error={props.touched.becante && props.errors.becante ? (true) : false}>
                                                <InputLabel>Institución que la otorga</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.becante}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Institucion que la otorga"
                                                    inputProps={{
                                                        name: 'becante',
                                                        id: 'becante',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        becante ?
                                                            becante.map((becante) => {
                                                                return <option key={becante.idbecante} value={becante.idbecante}>{becante.institucion}</option>
                                                            }) : null
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.becante && props.errors.becante ? (props.errors.becante) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Numero de registro en CONACYT"
                                                id="conacyt"
                                                name="conacyt"
                                                type="number"
                                                value={props.values.conacyt}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.conacyt && props.errors.conacyt ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.conacyt && props.errors.conacyt ? (
                                                    props.errors.conacyt
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Monto"
                                                type="number"
                                                id="monton"
                                                name="monton"
                                                value={props.values.monton}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.monton && props.errors.monton ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.monton && props.errors.monton ? (
                                                    props.errors.monton
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                error={props.touched.moneda && props.errors.moneda ? (true) : false}>
                                                <InputLabel>Moneda</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.moneda}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Moneda"
                                                    inputProps={{
                                                        name: 'moneda',
                                                        id: 'moneda',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        moneda ?
                                                            moneda.map((moneda) => {
                                                                return <option key={moneda.idmoneda} value={moneda.idmoneda}>{moneda.moneda}</option>
                                                            }) : null
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.moneda && props.errors.moneda ? (props.errors.moneda) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Observaciones"
                                                id="observacion"
                                                name="observacion"
                                                value={props.values.observacion}
                                                onChange={(e)=>{
                                                    let ob = e.target.value;
                                                    props.setFieldValue("observacion",ob.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.observacion && props.errors.observacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.observacion && props.errors.observacion ? (
                                                    props.errors.observacion
                                                ) : null} />

                                        </Grid>

                                        {
                                            !acceso ?
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            className={classes.boton}>
                                                            Guardar
                                                </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                </Grid>
                                                :
                                                null
                                        }

                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper>
        </div>

    )

}

export default Beca;