import React, { useEffect, useState } from 'react'
import { Avatar, Backdrop, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightRegular,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: theme.palette.secondary.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#B2B2B2',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#CCCCCC",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#000000",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    subtitulo1: {
        fontSize: "25px",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },
    
    
}));

const PreguntasFrec = () => {
    const classes = useStyles();  

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 300,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);


    return (
        <div  className={classes.root}>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item >
                        <Typography className={classes.titulo} >
                        &nbsp;&nbsp;&nbsp;ANTES DE INICIAR, CONSULTA ESTE APARTADO
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography color="inherit">Lo que debes saber antes de llenar tu registro</Typography>
                        </React.Fragment>
                            }
                        >
                        <Fab size="small" color="#A5A19F" className={classes.margin} >
                            <HelpOutlineIcon />
                        </Fab>
                    </HtmlTooltip>
                </Grid>
                <br/>
             <Accordion className={classes.boton}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.subtitulo} >SECCIÓN 2: DATOS PERSONALES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                            <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                            <h3> 
                            En esta sección deberás registrar tus datos personales y de contacto en caso de emergencia.
                            <br></br>
                            Deberás tener a la mano la documentación en archivos de formato PDF en un tamaño no mayor a 2MB.
                            <br></br>
                            Tu fotografía debe ser en un formato JPG en un tamaño no mayor a 2MB, tamaño pasaporte. 
                            </h3>
                            </Typography>
                            
                    </AccordionDetails>
             </Accordion>
                <br/>
             <Accordion className={classes.boton}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    >
                    <Typography className={classes.subtitulo}>SECCIÓN 3: ESTUDIOS QUE DESEA REALIZAR</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                        <Typography color='primary' > <h3>Proyecto de Investigación o Trabajo Profesional a Desarrollar:</h3> </Typography> 
                        <h3>
                        Documento escrito que describe el tema a desarrollar, debe contener los siguientes puntos:
                        <br/>
                        Título
                        <br/>
                        Justificación
                        <br/>
                        Objetivo
                        <br/>
                        Metodología
                        <br/>
                        Cronograma
                        </h3>
                        <Typography color='primary' > <h3>Documento Fuente de Financiamiento:</h3> </Typography> 
                        <h3>
                        Documento de formato libre donde indiques que, en caso de ser seleccionado en el programa de tu interés, solicitarás la beca de manutención de CONAHCyT y serás alumno de tiempo completo. 
                        </h3>
                        <Typography color='primary' > <h3>Carta de Reconocimiento o de No Adeudo:</h3> </Typography> 
                        <h3>
                        Documento emitido por CONAHCyT; en caso de no contar con este documento, cargar un archivo en formato PDF donde indiques que no has gozado de algún tipo apoyo. 
                        </h3>
                        </Typography>
                    </AccordionDetails>
             </Accordion>
                <br/>
             <Accordion className={classes.boton}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    >
                    <Typography className={classes.subtitulo}>SECCIÓN 4: ESTUDIOS REALIZADOS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                    <h3>
                        En caso de no contar con alguno de los documentos solicitados, deberás cargar un documento en formato PDF
                        donde indiques la situación actual y te comprometes a entregarlo en el momento de tu inscripción,
                        en caso de ser seleccionado para el programa de tu interés.
                    </h3>
                    </Typography>
                </AccordionDetails>
             </Accordion>
                <br/>
             <Accordion className={classes.boton}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    >
                    <Typography className={classes.subtitulo}>SECCIÓN 5: EXPERIENCIA PROFESIONAL</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                    <h3>
                    Tu CV debe ser fácil de leer, organizado, estructurado y veraz.
                    <br/>
                    Preferentemente incluir las constancias y publicaciones.
                    </h3>
                    <Typography color='primary' > <h3>OCUPACIÓN ACTUAL</h3> </Typography> 
                    <h3>
                    Es obligatorio ingresar tu ocupación actual.
                    </h3>
                    </Typography>
                </AccordionDetails>
             </Accordion>
                <br/>
             <Accordion className={classes.boton}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    >
                    <Typography className={classes.subtitulo}>SECCIÓN 6 : CARTAS DE RECOMENDACIÓN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography align="justify" paragraph variant='subtitle1' color='textPrimary'>
                    <h3>
                    Las cartas no son físicas; al registrar la información solicitada de tus otorgantes en la sección 6 y dar
                    guardar, les llegará a sus correos electrónicos registrados un enlace con el cuestionario para 
                    completar, al concluirlo y dar guardan, el estatus de las cartas en tu registro cambiará de "en espera"
                    a "completado".
                    <br/>
                    Recomienda a tus otorgantes que revisen en sus diferentes carpetas de su correo, incluyendo SPAM o correo no deseado.
                    <br/>
                    El sistema solo te permitirá cerrar tu registro hasta cuando el estatus de al menos dos cartas sea de “completado”.
                    </h3>
                    </Typography>
                </AccordionDetails>
             </Accordion>
                <br/>
             <Accordion className={classes.boton}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    >
                    <Typography className={classes.subtitulo1}>CIERRA TU REGISTRO CORRECTAMENTE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                    <h3>
                    Después de completar las secciones 2, 3, 4, 5 y 6, el sistema te da la opción de: 
                    </h3>
                    <Typography color='error' align="center"> <h3>ACEPTAR Y CERRAR REGISTRO</h3> </Typography> 
                    <h3>
                    Al aceptar te llegará un correo de Registro Finalizado.
                    <br/>
                    Para cualquier consulta respecto a tu proceso de registro, por favor comunícate con nosotros: 
                    </h3>
                    <Typography color='primary' > <h3>POSGRADO.SIIP@chapingo.mx</h3> </Typography> 
                    </Typography>
                </AccordionDetails>
             </Accordion>

            </Paper >
        </div >
    );

}

export default PreguntasFrec;