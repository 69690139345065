import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Avatar,FormControl, FormHelperText, Grid, InputLabel, Paper, Select, Tooltip, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { FORMATOFECHAMAXHOY, menor } from "../../generales/Fecha"
import { FILE_SIZE, SUPPORTED_IMG, SUPPORTED_PDF } from "../../../constants/global"
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    back: {
        background: 'linear-gradient(0deg, #003366 5%, #FFFFFF 100%)',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },

}));


function DatosGenerales({ changeGenerales }) {

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 300,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);

    const [user, setUser] = useState('');
    const [paises, setPaises] = useState([]);
    const [etnia, setEtnia] = useState([]);
    const [estado, setEstado] = useState('');
    const [ciudades, setCiudades] = useState('');

    //Archivos
    // const [uploadActa, setUploadActa] = useState(0);
    const [fileActa, setFileActa] = useState(0);
    const [, setActaMensaje] = useState('')

    const [, setUploadCurp] = useState(0);
    const [fileCurp, setFileCurp] = useState(0);
    const [, setCurpMensaje] = useState('')

    const [, setUploadFoto] = useState(0);
    const [fileFoto, setFileFoto] = useState(0);
    const [, setFotoMensaje] = useState('')

    //Mostrar campos para mexicanos
    const [nacimiento, setNacimiento] = useState(false);
    const [domicilio, setDomicilio] = useState(false);
    const [emergencia, setEmergencia] = useState(false);

    //Datos para lugar de nacimiento
    const [paisNac, setPaisNac] = useState('');
    // const [estadoNac, setEstadoNac] = useState([]);
    // const [ciudadNac, setCiudadNac] = useState([]);

    //Datos para lugar de domicilio
    const [paisDom, setPaisDom] = useState('');

    //Datos para lugar de contacto de emergencia
    const [paisEm, setPaisEm] = useState('');    

    useEffect(() => {
        
        const selectDatos = async () => {
            // PAIS
            await axios.get(Global + "pais", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response);
                setPaises(response.data);
            }).catch(error => console.log(error));

            // ETNIA
            await axios.get(Global + "etnia", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response);
                setEtnia(response.data);
            }).catch(error => console.log(error));

            //Estados
            await axios.get(Global + "codigo/estados", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setEstado(response.data);
            }).catch(error => console.log(error));

            //Usuario
            let user = fba.auth().currentUser;
            await axios.get(Global + "persona/correo/" + user.email, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                setUser(response.data)
            }).catch(error => console.log(error))
        }
        selectDatos();

    }, []);



    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "curpDoc":
                    setFileCurp(file)
                    // setUploadCurp(0)
                    setCurpMensaje('')
                    break;
                case "acta":
                    setFileActa(file)
                    // setUploadActa(0)
                    setActaMensaje('')
                    break;
                case "foto":
                    setFileFoto(file)
                    // setUploadFoto(0)
                    setFotoMensaje('')
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "curpDoc":
                    setFileCurp(false)
                    break;
                case "acta":
                    setFileActa(false)
                    break;
                case "foto":
                    setFileFoto(false)
                    break;
                default:
                    break;
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            nacimiento: '',
            gen: '',
            civil: '',
            etniapersona: '',
            resp_etnia: '',
            etnico: '',
            paisNac: '',
            estadoNac: '',
            ciudadNac: '',
            rfc: '',
            curp: '',
            curpDoc: '',
            foto: '',
            acta: '',
            paisDom: '',
            cpDom: '',
            estadoDom: '',
            ciudadDom: '',
            coloniaDom: '',
            otroDom: '',
            calleDom: '',
            informacionDom: '',
            telefonoDom: '',
            movil: '',
            nombre: '',
            parentesco: '',
            telefono: '',
            correo: '',
            paisEm: '',
            cpEm: '',
            estadoEm: '',
            ciudadEm: '',
            coloniaEm: '',
            calleEm: '',
            domicilio: '',
            otroEm: '',
        },
        validationSchema: Yup.object({
            
            nacimiento: Yup.string().required("El campo de fecha de nacimiento es obligatorio")
                .test('match',
                    'La fecha debe ser menor a la fecha actual',
                    function (fecha) {
                        return menor(fecha)
                    }
                ),
            gen: Yup.string().required('El campo género es obligatorio'),
            civil: Yup.string().required('El campo estado civil es obligatorio'),
            paisNac: Yup.string().required('El campo país es obligatorio'),
            estadoNac: Yup.string().when('paisNac', (paisNac) => {
                if (paisNac !== undefined) {
                    if (paisNac === null) {
                        return Yup.string()
                    }
                    if (paisNac.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            ciudadNac: Yup.string().when('paisNac', (paisNac) => {
                if (paisNac !== undefined) {
                    if (paisNac === null) {
                        return Yup.string()
                    }
                    if (paisNac.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            rfc: Yup.string().when('paisNac', (paisNac) => {
                if (paisNac !== undefined) {
                    if (paisNac === null) {
                        return Yup.string()
                    }
                    if (paisNac.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo RFC es obligatorio si es de MÉXICO").matches(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/, 'El campo no cumple con el formato de RFC.')
                    } else {
                        return Yup.string()
                    }
                }
            }),
            curp: Yup.string().when('paisNac', (paisNac) => {
                if (paisNac !== undefined) {
                    if (paisNac === null) {
                        return Yup.string()
                    }
                    if (paisNac.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo CURP es obligatorio si es de MÉXICO").matches(/^[A-Z]{4}[0-9]{6}[A-Z]{6}[A-Z0-9]{2}$/, 'El campo no cumple con el format de CURP.')
                    } else {
                        return Yup.string()
                    }
                }
            }),
            paisDom: Yup.string().required('El campo país es obligatorio'),
            cpDom: Yup.string().when('paisDom', (paisDom) => {
                if (paisDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (paisDom.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            estadoDom: Yup.string().when('paisDom', (paisDom) => {
                if (paisDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (paisDom.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            ciudadDom: Yup.string().when('paisDom', (paisDom) => {
                if (paisDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (paisDom.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            coloniaDom: Yup.string().when('paisDom', (paisDom) => {
                if (paisDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (paisDom.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            calleDom: Yup.string().when('paisDom', (paisDom) => {
                if (paisDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (paisDom.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            informacionDom: Yup.string().when('paisDom', (paisDom) => {
                if (paisDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (paisDom.split("-")[1] !== "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            telefonoDom: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
            otroDom: Yup.string().when('coloniaDom', (coloniaDom) => {
                if (coloniaDom !== undefined) {
                    if (paisDom === null) {
                        return Yup.string()
                    }
                    if (coloniaDom === "otra") {
                        return Yup.string().required("El campo es obligatorio para Otra colonia")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            movil: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
            nombre: Yup.string().required('El campo nombre es obligatorio'),
            parentesco: Yup.string().required('El parentesco paterno es obligatorio'),
            telefono: Yup.string().required('El campo télefono es obligatorio').matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
            correo: Yup.string().required('El campo correo es obligatorio').email('Ingrese un email valido'),
            paisEm: Yup.string().required('El campo país es obligatorio'),
            cpEm: Yup.string().when('paisEm', (paisEm) => {
                if (paisEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (paisEm.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            estadoEm: Yup.string().when('paisEm', (paisEm) => {
                if (paisEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (paisEm.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            ciudadEm: Yup.string().when('paisEm', (paisEm) => {
                if (paisEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (paisEm.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            coloniaEm: Yup.string().when('paisEm', (paisEm) => {
                if (paisEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (paisEm.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            otroEm: Yup.string().when('coloniaEm', (coloniaEm) => {
                if (coloniaEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (coloniaEm === "otra") {
                        return Yup.string().required("El campo es obligatorio para Otra colonia")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            calleEm: Yup.string().when('paisEm', (paisEm) => {
                if (paisEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (paisEm.split("-")[1] === "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            domicilio: Yup.string().when('paisEm', (paisEm) => {
                if (paisEm !== undefined) {
                    if (paisEm === null) {
                        return Yup.string()
                    }
                    if (paisEm.split("-")[1] !== "MÉXICO") {
                        return Yup.string().required("El campo es obligatorio.")
                    } else {
                        return Yup.string()
                    }
                }
            }),
            foto: Yup.mixed()
                .test('fileType', "El formato del archivo es incompatible",
                    () => (fileFoto === 0 || SUPPORTED_IMG.includes(fileFoto.type)))
                .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                    () => (fileFoto === 0 || fileFoto.size <= FILE_SIZE))
                .test('fileRequired', "El documento es obligatorio",
                    () => (fileFoto !== 0 && fileFoto !== false)),
            acta: Yup.mixed()
                .test('fileType', "El formato del archivo es incompatible",
                    () => (fileActa === 0 || SUPPORTED_PDF.includes(fileActa.type)))
                .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                    () => (fileActa === 0 || fileActa.size <= FILE_SIZE))
                .test('fileRequired', "El documento es obligatorio",
                () => (fileActa !== 0 && fileActa !== false)),
            curpDoc: Yup.mixed()
                .test('fileType', "El formato del archivo es incompatible",
                    () => (fileCurp === 0 || SUPPORTED_PDF.includes(fileCurp.type)))
                .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                    () => (fileCurp === 0 || fileCurp.size <= FILE_SIZE)),
        }),
        onSubmit: async valores => {

            let linkActa = "";
            let linkCurp = "";
            let linkFoto = "";
            //Acta para storage
            if (fileActa) {
                linkActa = fba.auth().currentUser.email + "/DocumentosDatosGenerales/" + uuidv4() + fileActa.name
                const storageRef = fba.storage().ref(linkActa)
                const task = storageRef.put(fileActa)
                task.on('state_changed', (snapshot) => {
                    // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    // setUploadActa(porcentage)
                    // setActaMensaje(porcentage + '%')
                }, (error) => {
                    console.log(error);
                    setActaMensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            ///////////////////
            //Foto para storage
            if (fileFoto) {
                linkFoto = fba.auth().currentUser.email + "/DocumentosDatosGenerales/" + uuidv4() + fileFoto.name
                const storageRef2 = fba.storage().ref(linkFoto)
                const task2 = storageRef2.put(fileFoto)
                task2.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadFoto(porcentage)
                    setFotoMensaje(porcentage + '%')
                }, (error) => {
                    console.log(error);
                    setFotoMensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            ///////////////////
            //Curp para storage
            if (fileCurp) {
                linkCurp = fba.auth().currentUser.email + "/DocumentosDatosGenerales/" + uuidv4() + fileCurp.name
                const storageRef3 = fba.storage().ref(linkCurp)
                const task3 = storageRef3.put(fileCurp)
                task3.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCurp(porcentage)
                    setCurpMensaje(porcentage + '%')
                }, (error) => {
                    console.log(error);
                    setCurpMensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            ////////////////////

            //Valores para cada inserci[on]
            let persona = {
                fecha_na: valores.nacimiento,
                celular: valores.movil,
                acta_nac: linkActa,
                curp: valores.curp,
                doc_curp: linkCurp,
                estado_civil: valores.civil,
                fotografia: linkFoto,
                genero: valores.gen,
                rfc: valores.rfc,
                telefono: valores.telefonoDom
            }
            let lugar_nacim = {
                ciudad: valores.ciudadNac,
                estado: valores.estadoNac
            }

            let direccion = {
                calle: '',
                colonia: '',
                informacion: '',
            }

            //Guardar solo valores depende al pa[is]
            if (valores.paisDom.split("-")[1] === "MÉXICO") {
                if (valores.coloniaDom === "otra") {
                    direccion.colonia = valores.otroDom
                }
                direccion.calle = valores.calleDom
            } else {
                direccion.informacion = valores.informacionDom
            }

            let direccionEm = {
                calle: '',
                colonia: '',
                informacion: '',
            }
            if (valores.paisEm.split("-")[1] === "MÉXICO") {
                if (valores.coloniaEm === "otra") {
                    direccionEm.colonia = valores.otroEm
                }
                direccionEm.calle = valores.calleEm
            } else {
                direccionEm.informacion = valores.domicilio
            }


            let contacto_em = {
                correo: valores.correo,
                nombre: valores.nombre,
                parentesco: valores.parentesco,
                telefono: valores.telefono,
                etniapersona: valores.etniapersona,
                resp_etnia: valores.resp_etnia,

            }

            //Actualizar persona, registrar nuevos campos
            await axios.put(Global + "persona/ID/" + user[0].idP, persona, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
            }).catch(error => console.log(error));
            //Guardar la etnia
            if (valores.etnico !== '') {
                await axios.post(Global + "personaed/persona/" + user[0].idP + "/etnia/" + valores.etnico,
                    {
                        etnia: {
                            etnia: "string",
                        },
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response)
                    }).catch(error => console.log(error));
                // console.log("Paso por guardar etnia");
            }

            //Info para lugar de nacimiento
            await axios.post(Global + "lugarna/pais/" + paisNac + "/persona/" + user[0].idP, lugar_nacim, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).catch(error => console.log(error));
            // console.log("Paso por lugar de nacimiento");


            // Guardar el domicilio de la persona /direccion
            let idDirDom = '';
            await axios.post(Global + "direccion/pais/" + paisDom[0].idpais, direccion, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                idDirDom = response.data;
                //Para llenar /personad 
                axios.post(Global + "personad/persona/" + user[0].idP + "/direccion/" + response.data.iddireccion,
                    {
                        direccion: {
                            calle: "string",
                        },
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));
                // console.log("Paso por personad de la persona");

            }).catch(error => console.log(error));
            // console.log("Paso por direccion de la persona");


            //Guarda en /direccioncp
            if (paisDom[0].pais === "MÉXICO") {
                //de direccioncp
                if (valores.coloniaDom === 'otra') {
                    //Get del codigo postal para /direccioncp
                    // http://34.123.116.147:4002/codigo/colonia/Manantiales%20de%20La%20Carolina/estado/{estado}/cp/{cp}/ciudad/{ciudad}?ciudad=Zit%C3%A1cuaro&cp=61514&estado=Michoac%C3%A1n%20de%20Ocampo
                    axios.get(Global + "codigo/estado/" + valores.estadoDom + "/cp/{cp}/ciudad/{ciudad}?ciudad=" + valores.ciudadDom + "&cp=" + valores.cpDom, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        axios.post(Global + "direccioncp/cp/" + response.data[0].idcodigo + "/direccion/" + idDirDom.iddireccion,
                            {
                                persona: {
                                    persona: "string",
                                },
                            }, {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).catch(error => console.log(error));
                    }).catch(error => console.log(error));
                } else {
                    axios.post(Global + "direccioncp/cp/" + valores.coloniaDom + "/direccion/" + idDirDom.iddireccion, {
                        persona: {
                            persona: "string",
                        },
                    }, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));
                }
            }

            // /*
            // Contacto
            // */
            let idDirEm = '';
            // Guardar la direccion del contacto 
            await axios.post(Global + "direccion/pais/" + paisEm[0].idpais, direccionEm, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response);
                idDirEm = response.data;
            }).catch(error => console.log(error));
            // console.log("Paso por direccion del  contacto");

            // Info de contacto de emergencia
            await axios.post(Global + "contactoem/direccion/" + idDirEm.iddireccion + "/persona/" + user[0].idP, contacto_em, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).catch(error => console.log(error));
            // console.log("Paso por el contacto_em");

            if (paisEm[0].pais === "MÉXICO") {
                if (valores.coloniaEm === 'otra') {
                    axios.get(Global + "codigo/estado/" + valores.estadoEm + "/cp/{cp}/ciudad/{ciudad}?ciudad=" + valores.ciudadEm + "&cp=" + valores.cpEm, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        //Direccioncp
                        axios.post(Global + "direccioncp/cp/" + response.data[0].idcodigo + "/direccion/" + idDirEm.iddireccion,
                            {
                                persona: {
                                    persona: "string",
                                },
                            },
                            {
                                headers: {
                                    'Access-Control-Allow-Origin': "*",
                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                }
                            }).catch(error => console.log(error));
                    }).catch(error => console.log(error));

                } else {
                    axios.post(Global + "direccioncp/cp/" + valores.coloniaEm + "/direccion/" + idDirEm.iddireccion,
                        {
                            persona: {
                                persona: "string",
                            },
                        },
                        {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).catch(error => console.log(error));
                }

            }

            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            changeGenerales(true);

        }
    });

    const handleChangePais = e => {
        // e.preventDefault();
        let optionPais = e.split("-");
        if (optionPais[1] === "MÉXICO") {
            setNacimiento(true);
            setPaisNac(optionPais[0])
        } else {
            setPaisNac(optionPais[0]);
            setNacimiento(false);
        }
    }

    const handleChangeEmergencia = async e => {
        // e.preventDefault();
        let optionPais = e.split("-");

        if (optionPais[1] === "MÉXICO") {
            setEmergencia(true);
            await axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisEm(response.data);
            }).catch(error => console.log(error))
        } else {
            setEmergencia(false);
            await axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisEm(response.data);
                // console.log(paisEm);
            }).catch(error => console.log(error))
        }
    }

    const handleChangeDomicilio = e => {
        // e.preventDefault();
        let optionPais = e.split("-");

        if (optionPais[1] === "MÉXICO") {
            setDomicilio(true);
            axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisDom(response.data);
            }).catch(error => console.log(error))
        } else {
            setDomicilio(false);
            axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisDom(response.data);
            }).catch(error => console.log(error));
        }
    }

    const handleChangeEstado = e => {
        e.preventDefault();
        if (e.target.value) {
            axios.get(Global + "codigo/estado/" + e.target.value, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setCiudades(response.data);
            }).catch(error => console.log(error));
        } else {
            setCiudades('');
        }


    }

    const [cpDomicilio, setCPDomicilio] = useState('');
    const [cpContacto, setCPContacto] = useState('');

    const [estadoDomicilio, setEstadoDomicilio] = useState('');
    const [estadoContacto, setEstadoContacto] = useState('');

    const [coloniaDomicilio, setColoniaDomicilio] = useState('');
    const [coloniaContacto, setColoniaContacto] = useState('');
    //Cuando Cambian el codigo postal
    const handleChangeCP = e => {
        e.preventDefault();
        switch (e.target.name) {
            case "cpDom":
                if (e.target.value.length > 0) {
                    setEstadoDomicilio('');
                    axios.get(Global + "codigo/cps/" + e.target.value, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setCPDomicilio(response.data[0]);
                        setEstadoDomicilio(response.data[0]);
                        setColoniaDomicilio(response.data)
                    }).catch(error => console.log(error));
                }
                break;
            case "cpEm":
                if (e.target.value.length > 0) {
                    setEstadoContacto('');
                    axios.get(Global + "codigo/cps/" + e.target.value, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setCPContacto(response.data[0]);
                        setEstadoContacto(response.data[0]);
                        setColoniaContacto(response.data)
                    }).catch(error => console.log(error));
                }
                break;
            default:
                break;

        }


    }

    const classes = useStyles();

    return (
        <div>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    item
                    xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <br />
                        <Typography className={classes.subtitulo}>
                            Datos generales
                        </Typography>
                        <hr />
                        <br />
                        <HtmlTooltip
                                title={
                                <React.Fragment>
                                    <Typography color="inherit">Lugar: datos de nacimiento, tener a la mano acta de nacimiento.</Typography>
                                </React.Fragment>
                                }
                            >
                             <Fab size="small" color="#A5A19F" className={classes.margin} >
                                <HelpOutlineIcon />
                            </Fab>
                       </HtmlTooltip>

                        <br />
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="nacimiento"
                                    name="nacimiento"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    value={formik.values.nacimiento}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.nacimiento && formik.errors.nacimiento ? (
                                        true
                                    ) : false}
                                    helperText={formik.touched.nacimiento && formik.errors.nacimiento ? (
                                        formik.errors.nacimiento
                                    ) : null}
                                    InputProps={FORMATOFECHAMAXHOY}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                    className={classes.formControl}
                                    error={formik.touched.gen && formik.errors.gen ? (true) : false}>
                                    <InputLabel>Género</InputLabel>
                                    <Select
                                        native
                                        value={formik.values.gen}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="Género"
                                        inputProps={{
                                            name: 'gen',
                                            id: 'gen',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value={'Masculino'}>Masculino</option>
                                        <option value={'Femenino'}>Femenino</option>
                                    </Select>
                                    <FormHelperText>
                                        {formik.touched.gen && formik.errors.gen ? (formik.errors.gen) : null}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                    className={classes.formControl}
                                    error={formik.touched.civil && formik.errors.civil ? (true) : false}>
                                    <InputLabel>Estado civil</InputLabel>
                                    <Select
                                        native
                                        value={formik.values.civil}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="Estado civil"
                                        inputProps={{
                                            name: 'civil',
                                            id: 'civil',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value={'soltero'}>Soltero</option>
                                        <option value={'casado'}>Casado</option>
                                        <option value={'otro'}>Otro</option>
                                    </Select>
                                    <FormHelperText>
                                        {formik.touched.civil && formik.errors.civil ? (formik.errors.civil) : null}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                    className={classes.formControl}
                                    error={formik.touched.resp_etnia && formik.errors.resp_etnia ? (true) : false}>
                                    <InputLabel>¿Se identifica con algún grupo etnico?</InputLabel>
                                    <Select
                                        native
                                        value={formik.values.resp_etnia}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="¿Se identifica con algún grupo etnico?"
                                        inputProps={{
                                            name: 'resp_etnia',
                                            id: 'resp_etnia',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value={'SI'}>SI</option>
                                        <option value={'NO'}>NO</option>
                                    </Select>
                                    <FormHelperText>
                                        {formik.touched.resp_etnia && formik.errors.resp_etnia ? (formik.errors.resp_etnia) : null}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                    className={classes.formControl}
                                    error={formik.touched.paisNac && formik.errors.paisNac ? (true) : false}>
                                    <Autocomplete                                        
                                        freeSolo
                                        name='paisNac'
                                        id='paisNac'
                                        value={formik.values.paisNac}
                                        onChange={(event, newValue) => {
                                            if (newValue != null) {
                                                handleChangePais(newValue);
                                            }
                                            formik.setFieldValue("paisNac", newValue);
                                        }}
                                        label="País de Nacimiento"
                                        options={paises.map((pais) => pais.idpais + "-" + pais.pais)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="País de Nacimiento"
                                                name='paisNac'
                                                id='paisNac'
                                                margin="normal"
                                                variant="outlined"
                                                onBlur={formik.handleBlur}
                                            />
                                        )}
                                    >
                                    </Autocomplete>
                                    <FormHelperText>
                                        {formik.touched.paisNac && formik.errors.paisNac ? "El campo país es obligatorio" : null}</FormHelperText>
                                </FormControl>
                            </Grid>
                            {formik.values.resp_etnia === 'SI' &&
                            <Grid item xs={12} sm={6}>
                            <br />
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                    className={classes.formControl}
                                    error={formik.touched.etniapersona && formik.errors.etniapersona ? (true) : false}>
                                    <InputLabel>Grupo Étnico</InputLabel>
                                    <Select
                                        native
                                        value={formik.values.etniapersona}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="Grupo Étnico"
                                        inputProps={{
                                            name: 'etniapersona',
                                            id: 'etniapersona',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value={'AGUACATECO'}>AGUACATECO</option>
                                        <option value={'AMUZGO'}>AMUZGO</option>
                                        <option value={'AYAPANECO'}>AYAPANECO</option>
                                        <option value={'CAKCHIQUEL'}>CAKCHIQUEL</option>
                                        <option value={'CHATINO'}>CHATINO</option>
                                        <option value={'CHICHIMECA'}>CHICHIMECA</option>
                                        <option value={'CHINANTE'}>CHINANTE</option>
                                        <option value={'CHINANTECO'}>CHINANTECO</option>
                                        <option value={'CHOCHO'}>CHOCHO</option>
                                        <option value={'CHOL'}>CHOL</option>
                                        <option value={'CHONTAL'}>CHONTAL</option>
                                        <option value={'CHUJ'}>CHUJ</option>
                                        <option value={'COCHIMI'}>COCHIMI</option>
                                        <option value={'CORA'}>CORA</option>
                                        <option value={'CUCAPA'}>CUCAPA</option>
                                        <option value={'CUICATECO'}>CUICATECO</option>
                                        <option value={'GUARIJIO'}>GUARIJIO</option>
                                        <option value={'HUASTECO'}>HUASTECO</option>
                                        <option value={'HUAVE'}>HUAVE</option>
                                        <option value={'HUICHOL'}>HUICHOL</option>
                                        <option value={'IXCATECO'}>IXCATECO</option>
                                        <option value={'IXIL'}>IXIL</option>
                                        <option value={'JACALTECO'}>JACALTECO</option>
                                        <option value={'KANJOBAL'}>KANJOBAL</option>
                                        <option value={'KEKCHI'}>KEKCHI</option>
                                        <option value={'KIKAPU'}>KIKAPU</option>
                                        <option value={'KILIWA'}>KILIWA</option>
                                        <option value={'KUMIAI'}>KUMIAI</option>
                                        <option value={'LACANDON'}>LACANDON</option>
                                        <option value={'MAME'}>MAME</option>
                                        <option value={'MATLATZINC'}>MATLATZINC</option>
                                        <option value={'MAYA'}>MAYA</option>
                                        <option value={'MAYO'}>MAYO</option>
                                        <option value={'MAZAHUA'}>MAZAHUA</option>
                                        <option value={'MAZATECO'}>MAZATECO</option>
                                        <option value={'MIXE'}>MIXE</option>
                                        <option value={'MIXTECO'}>MIXTECO</option>
                                        <option value={'MOTOCINTLE'}>MOTOCINTLE</option>
                                        <option value={'NAHUATL'}>NAHUATL</option>
                                        <option value={'OCUILTECO'}>OCUILTECO</option>
                                        <option value={'OTOMI'}>OTOMI</option>
                                        <option value={'OTRASLE'}>OTRASLE</option>
                                        <option value={'OTRASLENG'}>OTRASLENG</option>
                                        <option value={'PAIPAI'}>PAIPAI</option>
                                        <option value={'PAME'}>PAME</option>
                                        <option value={'PAPABUCO'}>PAPABUCO</option>
                                        <option value={'PAPAGO'}>PAPAGO</option>
                                        <option value={'PIMA'}>PIMA</option>
                                        <option value={'POHA'}>POHA</option>
                                        <option value={'POPOLOCA'}>POPOLOCA</option>
                                        <option value={'POPOLUCA'}>POPOLUCA</option>
                                        <option value={'PUREPECHA'}>PUREPECHA</option>
                                        <option value={'QUICHE'}>QUICHE</option>
                                        <option value={'SERI'}>SERI</option>
                                        <option value={'SOLTECO'}>SOLTECO</option>
                                        <option value={'TACUATE'}>TACUATE</option>
                                        <option value={'TARAHUMARA'}>TARAHUMARA</option>
                                        <option value={'TEPEHUA'}>TEPEHUA</option>
                                        <option value={'TEPEHUANO'}>TEPEHUANO</option>
                                        <option value={'TLAPANECO'}>TLAPANECO</option>
                                        <option value={'TOJOLABAL'}>TOJOLABAL</option>
                                        <option value={'TOLE'}>TOLE</option>
                                        <option value={'TOTONACA'}>TOTONACA</option>
                                        <option value={'TRIQUI'}>TRIQUI</option>
                                        <option value={'TZELTAL'}>TZELTAL</option>
                                        <option value={'TZOTZIL'}>TZOTZIL</option>
                                        <option value={'YAQUI'}>YAQUI</option>
                                        <option value={'ZAPOTECO'}>ZAPOTECO</option>
                                        <option value={'ZOQUE'}>ZOQUE</option>
                                    </Select>
                                    <FormHelperText>
                                        {formik.touched.etniapersona && formik.errors.etniapersona ? (formik.errors.etniapersona) : null}</FormHelperText>
                                </FormControl>
                            </Grid>
                              }
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            
                            {/* <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    className={classes.formControl}
                                    error={formik.touched.etnico && formik.errors.etnico ? (true) : false}>
                                    <InputLabel>Grupo étnico</InputLabel>
                                    <Select
                                        native
                                        value={formik.values.etnico}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="Grupo étnico"
                                        inputProps={{
                                            name: 'etnico',
                                            id: 'etnico',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            etnia.map((etnia) => {
                                                return <option key={etnia.idetnia} value={etnia.idetnia}>{etnia.etnia}</option>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>
                                        {formik.touched.etnico && formik.errors.etnico ? (formik.errors.etnico) : null}</FormHelperText>
                                </FormControl>
                            </Grid> */}
                            <Grid container direction="row"
                                justify="center"
                                alignItems="center" item xs={12} sm={6}>
                            </Grid>
                            {nacimiento ? (
                                <>
                                    <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        item
                                        xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={formik.touched.estadoNac && formik.errors.estadoNac ? (true) : false}>
                                            <InputLabel >Estado</InputLabel>
                                            <Select
                                                native
                                                value={formik.values.estadoNac}
                                                onChange={e => {
                                                    handleChangeEstado(e);
                                                    formik.setFieldValue("estadoNac", e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                label="Estado"
                                                inputProps={{
                                                    name: 'estadoNac',
                                                    id: 'estadoNac',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {
                                                    estado.map((estado) => {
                                                        return <option key={estado.idcodigo} value={estado.estado}>{estado.estado}</option>
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {formik.touched.estadoNac && formik.errors.estadoNac ? (formik.errors.estadoNac) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        item
                                        xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={formik.touched.ciudadNac && formik.errors.ciudadNac ? (true) : false}>
                                            <InputLabel>Municipio</InputLabel>
                                            <Select
                                                native
                                                value={formik.values.ciudadNac}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                label="Municipio"
                                                inputProps={{
                                                    name: 'ciudadNac',
                                                    id: 'ciudadNac',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {ciudades !== '' ?
                                                    ciudades.map((ciudad) => {
                                                        return <option key={ciudad.idcodigo} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                    })
                                                    : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {formik.touched.ciudadNac && formik.errors.ciudadNac ? (formik.errors.ciudadNac) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        {/* <Tooltip title="RFC con homoclave" aria-label="add">
                                            <Avatar className={classes.avatar}>
                                                <HelpOutlineIcon />
                                            </Avatar>
                                        </Tooltip> */}
                                        <HtmlTooltip
                                            title={
                                            <React.Fragment>
                                                <Typography color="inherit">RFC con homoclave</Typography>
                                            </React.Fragment>
                                             }
                                            >
                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                              <HelpOutlineIcon />
                                            </Fab>
                                        </HtmlTooltip>
                                        <br />
                                        <br />
                                        <TextField
                                            name="rfc"
                                            type="text"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="rfc"
                                            label="RFC"
                                            value={formik.values.rfc}
                                            onChange={(e) => {
                                                let rfc = e.target.value;
                                                formik.setFieldValue("rfc", rfc.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.rfc && formik.errors.rfc ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.rfc && formik.errors.rfc ? (
                                                formik.errors.rfc
                                            ) : null}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        {/* <Tooltip title="CURP: Está compuesto por (AAAA000000HMDRES00)" aria-label="add">
                                            <Avatar className={classes.avatar}>
                                                <HelpOutlineIcon />
                                            </Avatar>
                                        </Tooltip> */}
                                        <HtmlTooltip
                                            title={
                                            <React.Fragment>
                                                <Typography color="inherit">CURP: Está compuesto por (AAAA000000HMDRES00)</Typography>
                                            </React.Fragment>
                                             }
                                            >
                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                              <HelpOutlineIcon />
                                            </Fab>
                                        </HtmlTooltip>
                                        <br />
                                        <br />
                                        <TextField
                                            name="curp"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="curp"
                                            label="CURP"
                                            value={formik.values.curp}
                                            onChange={(e) => {
                                                let curp = e.target.value;
                                                formik.setFieldValue("curp", curp.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.curp && formik.errors.curp ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.curp && formik.errors.curp ? (
                                                formik.errors.curp
                                            ) : null}
                                            type='text'

                                        />
                                    </Grid>
                                    <Grid container
                                        direction="column"
                                        item xs={11} sm={4}>
                                        <label htmlFor="curpDoc">
                                            <input
                                                style={{ display: 'none' }}
                                                id="curpDoc"
                                                name="curpDoc"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">Archivo Obligatorio.
                                                                    Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB</Typography>
                                                                </React.Fragment>
                                                                }
                                                            >
                                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                                <HelpOutlineIcon />
                                                            </Fab>
                                            </HtmlTooltip>
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                CURP
                                                {fileCurp ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(formik.touched.curpDoc && formik.errors.curpDoc) && (formik.errors.curpDoc)}
                                            </Typography>
                                        </label>

                                    </Grid>
                                    <Grid item xs={1} sm={8}></Grid>
                                </>) : null}

                            <Grid container direction="column" item
                                xs={11} sm={4}>
                                <label htmlFor="acta">
                                    <input
                                        style={{ display: 'none' }}
                                        id="acta"
                                        name="acta"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={handleOnChange}

                                    />
                                    {/* <Tooltip title="Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB." aria-label="add">
                                        <Avatar className={classes.avatar}>
                                            <HelpOutlineIcon />
                                        </Avatar>
                                    </Tooltip> */}
                                    <HtmlTooltip
                                            title={
                                            <React.Fragment>
                                                <Typography color="inherit">Documento Obligatorio.
                                                Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB</Typography>
                                            </React.Fragment>
                                             }
                                            >
                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                              <HelpOutlineIcon />
                                            </Fab>
                                        </HtmlTooltip>
                                    <br />
                                    <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                        Acta de nacimiento
                                        {fileActa ?
                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                            : null}
                                    </Button>
                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                        {(formik.touched.acta && formik.errors.acta) && (formik.errors.acta)}
                                    </Typography>

                                </label>


                            </Grid>
                            <Grid item xs={1} sm={8}></Grid>
                            <Grid container direction="column"
                                item xs={11} sm={4}>
                                <label

                                    htmlFor="foto">
                                    <input
                                        id="foto"
                                        name="foto"
                                        type="file"
                                        accept="image/jpeg"
                                        onChange={handleOnChange}
                                        style={{ display: 'none' }}
                                    />
                                    {/* <Tooltip title="La fotografía en formato JPG y con encuadre tipo diploma." aria-label="add">
                                        <Avatar className={classes.avatar}>
                                            <HelpOutlineIcon />
                                        </Avatar>
                                    </Tooltip> */}
                                    <HtmlTooltip
                                            title={
                                            <React.Fragment>
                                                <Typography color="inherit">Archivo Obligatorio.
                                                La fotografía en formato JPG y con encuadre tamaño pasaporte</Typography>
                                            </React.Fragment>
                                             }
                                            >
                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                              <HelpOutlineIcon />
                                            </Fab>
                                    </HtmlTooltip>
                                    <br />
                                    <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                        Fotografía
                                        {fileFoto ?
                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                            : null}
                                    </Button>
                                    
                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                        {(formik.touched.foto && formik.errors.foto) && (formik.errors.foto)}
                                    </Typography>
                                </label>


                            </Grid>
                            <Grid item xs={1} sm={8}></Grid>

                        </Grid>
                        <br />
                    </Paper>
                </Grid>

                {/* <Grid container spacing={2}> */}
                    {/* Paper Domicilio */}
                    <Grid item xs={12}>

                        <Paper elevation={2} className={classes.paper} >
                            <br />
                            <Typography className={classes.subtitulo}>
                                Domicilio Actual
                            </Typography>
                            {/* <hr />
                            <br /> */}
                            {/* <Tooltip title="Domicilio: Domicilio donde actualmente está radicando." aria-label="add">
                                 <HelpOutlineIcon className={classes.avatar} />
                            </Tooltip> */}
                            <HtmlTooltip
                                title={
                                <React.Fragment>
                                    <Typography color="inherit">Domicilio: domicilio donde actualmente está radicando</Typography>
                                </React.Fragment>
                                    }
                                >
                                <Fab size="small" color="#A5A19F" className={classes.margin} >
                                    <HelpOutlineIcon />
                                </Fab>
                            </HtmlTooltip>
                            <Grid container spacing={2}>

                                <Grid item xs={12}sm={6}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className={classes.formControl}
                                        error={formik.touched.paisDom && formik.errors.paisDom ? (true) : false}>
                                        {/* <InputLabel >Pais</InputLabel>
                                        <Select
                                            native
                                            value={formik.values.paisDom}
                                            onChange={e => {
                                                handleChangeDomicilio(e);
                                                formik.setFieldValue("paisDom", e.target.value);

                                            }}
                                            onBlur={formik.handleBlur}
                                            label="Pais"
                                            inputProps={{
                                                name: 'paisDom',
                                                id: 'paisDom',
                                            }}
                                        >
                                            <option aria-label="None" value="" /> {
                                                paises.map((pais) => {
                                                    return <option key={pais.idpais} value={pais.idpais + "-" + pais.pais} label={pais.pais}>{pais.pais}</option>
                                                })
                                            }
                                        </Select> */}
                                        {/* ////////////////////// */}
                                        <Autocomplete
                                            freeSolo
                                            name='paisDom'
                                            id='paisDom'
                                            value={formik.values.paisDom}
                                            onChange={(event, newValue) => {
                                                if (newValue != null) {
                                                    handleChangeDomicilio(newValue);
                                                }
                                                formik.setFieldValue("paisDom", newValue);
                                            }}
                                            label="País"
                                            options={paises.map((pais) => pais.idpais + "-" + pais.pais)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="País"
                                                    name='paisDom'
                                                    id='paisDom'
                                                    margin="normal"
                                                    variant="outlined"
                                                    onBlur={formik.handleBlur}
                                                />
                                            )}
                                        >
                                        </Autocomplete>
                                        <FormHelperText>
                                            {formik.touched.paisDom && formik.errors.paisDom ? "El campo país es obligatorio" : null}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}></Grid>
                                <Grid item xs={12}></Grid>
                                
                                {domicilio ?
                                    <>
                                        
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Código postal"
                                                name="cpDom"
                                                variant="outlined"
                                                fullWidth
                                                value={formik.values.cpDom}
                                                onChange={e => {
                                                    handleChangeCP(e);
                                                    formik.setFieldValue("cpDom", e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.cpDom && formik.errors.cpDom ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.cpDom && formik.errors.cpDom ? (
                                                    formik.errors.cpDom
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={formik.touched.estadoDom && formik.errors.estadoDom ? (true) : false}>
                                                <InputLabel >Estado</InputLabel>
                                                <Select
                                                    native
                                                    value={formik.values.estadoDom}
                                                    // onChange={e => {
                                                    //     handleChangeEstados(e);
                                                    //     formik.setFieldValue("estadoDom", e.target.value);
                                                    // }}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Estado"
                                                    inputProps={{
                                                        name: 'estadoDom',
                                                        id: 'estadoDom',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />

                                                    {cpDomicilio ?
                                                        <option key={cpDomicilio.idcodigo} value={cpDomicilio.estado} >{cpDomicilio.estado}</option>
                                                        // cpDomicilio.map((estado) => {
                                                        //     return <option key={estado.idcodigo} value={estado.estado} >{estado.estado}</option>
                                                        // }) : null
                                                        : null
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {formik.touched.estadoDom && formik.errors.estadoDom ? (formik.errors.estadoDom) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={formik.touched.ciudadDom && formik.errors.ciudadDom ? (true) : false}>
                                                <InputLabel >Municipio</InputLabel>
                                                <Select
                                                    native
                                                    value={formik.values.ciudadDom}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Municipio"
                                                    inputProps={{
                                                        name: 'ciudadDom',
                                                        id: 'ciudadDom',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {estadoDomicilio ?
                                                        <option key={estadoDomicilio.idcodigo} value={estadoDomicilio.ciudad} >{estadoDomicilio.ciudad}</option>
                                                        // estadoDomicilio.map((ciudad) => {
                                                        //     return <option key={ciudad.idcodigo} value={ciudad.ciudad} >{ciudad.ciudad}</option>
                                                        // }) : null
                                                        : null
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {formik.touched.ciudadDom && formik.errors.ciudadDom ? (formik.errors.ciudadDom) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={formik.touched.coloniaDom && formik.errors.coloniaDom ? (true) : false}>
                                                <InputLabel>Colonia</InputLabel>
                                                <Select
                                                    native
                                                    value={formik.values.coloniaDom}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Colonia"
                                                    inputProps={{
                                                        name: 'coloniaDom',
                                                        id: 'coloniaDom',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {coloniaDomicilio ?
                                                        coloniaDomicilio.map((colonia) => {
                                                            return <option key={colonia.idcodigo} value={colonia.idcodigo} >{colonia.colonia}</option>
                                                        }) : null
                                                    }
                                                    <option value="otra">Otra colonia...</option>
                                                </Select>
                                                <FormHelperText>
                                                    {formik.touched.coloniaDom && formik.errors.coloniaDom ? (formik.errors.coloniaDom) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Otra colonia"
                                                id="otroDom"
                                                name="otroDom"
                                                value={formik.values.otroDom}
                                                onChange={(e) => {
                                                    let otrodom = e.target.value;
                                                    formik.setFieldValue("otroDom", otrodom.toUpperCase())
                                                }}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.otroDom && formik.errors.otroDom ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.otroDom && formik.errors.otroDom ? (
                                                    formik.errors.otroDom
                                                ) : null}

                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="calleDom"
                                                label="Calle y número"
                                                name="calleDom"
                                                value={formik.values.calleDom}
                                                onChange={(e) => {
                                                    let calle = e.target.value;
                                                    formik.setFieldValue("calleDom", calle.toUpperCase())
                                                }}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.calleDom && formik.errors.calleDom ? (
                                                    true
                                                ) : false}
                                                helperText={formik.touched.calleDom && formik.errors.calleDom ? (
                                                    formik.errors.calleDom
                                                ) : null}

                                            />
                                        </Grid>
                                    </> :
                                    
                                    <Grid xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="informacionDom"
                                            label="Domicilio completo"
                                            name="informacionDom"
                                            value={formik.values.informacionDom}
                                            onChange={(e) => {
                                                let dom = e.target.value;
                                                formik.setFieldValue("informacionDom", dom.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.informacionDom && formik.errors.informacionDom ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.informacionDom && formik.errors.informacionDom ? (
                                                formik.errors.informacionDom
                                            ) : null}
                                        />
                                    </Grid>}

                                 <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Teléfono fijo"
                                        id="telefonoDom"
                                        name="telefonoDom"
                                        inputProps={{ maxLength: 10 }}
                                        value={formik.values.telefonoDom}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.telefonoDom && formik.errors.telefonoDom ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.telefonoDom && formik.errors.telefonoDom ? (
                                            formik.errors.telefonoDom
                                        ) : null}
                                    />
                                        <HtmlTooltip
                                            title={
                                            <React.Fragment>
                                                <Typography color="inherit">Teléfono: el teléfono fijo contiene como máximo 10 dígitos</Typography>
                                            </React.Fragment>
                                                }
                                            >
                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                <HelpOutlineIcon />
                                            </Fab>
                                        </HtmlTooltip>
                                 </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="movil"
                                        label="Móvil"
                                        name="movil"
                                        value={formik.values.movil}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.movil && formik.errors.movil ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.movil && formik.errors.movil ? (
                                            formik.errors.movil
                                        ) : null}
                                    />
                                    <br />
                                    <HtmlTooltip
                                        title={
                                        <React.Fragment>
                                            <Typography color="inherit">Móvil: el móvil puede tener mas de 10 dígitos por ladas internacionales</Typography>
                                        </React.Fragment>
                                            }
                                        >
                                        <Fab size="small" color="#A5A19F" className={classes.margin} >
                                            <HelpOutlineIcon />
                                        </Fab>
                                   </HtmlTooltip>
                                    <br /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                </Grid>
                            </Grid>
                            <br />
                        </Paper>

                    </Grid>

                    {/* Paper Contacto Emergencia */}
                    <Grid item  xs={12}>
                        <Paper elevation={3} className={classes.paper}>

                            <br />
                            <Typography className={classes.subtitulo}>
                             Contacto En Caso De Emergencia
                            </Typography>
                            <hr />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="nombre"
                                        label="Nombre completo"
                                        name="nombre"
                                        value={formik.values.nombre}
                                        onChange={(e) => {
                                            let nombre = e.target.value;
                                            formik.setFieldValue("nombre", nombre.toUpperCase())
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.nombre && formik.errors.nombre ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.nombre && formik.errors.nombre ? (
                                            formik.errors.nombre
                                        ) : null}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="parentesco"
                                        label="Parentesco"
                                        name="parentesco"
                                        value={formik.values.parentesco}
                                        onChange={(e) => {
                                            let parentesco = e.target.value;
                                            formik.setFieldValue("parentesco", parentesco.toUpperCase())
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.parentesco && formik.errors.parentesco ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.parentesco && formik.errors.parentesco ? (
                                            formik.errors.parentesco
                                        ) : null}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="telefono"
                                        label="Teléfono"
                                        name="telefono"
                                        inputProps={{ maxLength: 10 }}
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.telefono && formik.errors.telefono ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.telefono && formik.errors.telefono ? (
                                            formik.errors.telefono
                                        ) : null}

                                    />
                                     <br />
                                     <br />
                                   {/*  <Tooltip title="Teléfono: El teléfono contiene como máximo 10 dígitos." aria-label="add">
                                         <HelpOutlineIcon className={classes.avatar}/>
                                    </Tooltip> */}
                                    <HtmlTooltip
                                        title={
                                        <React.Fragment>
                                            <Typography color="inherit">Teléfono: el teléfono fijo contiene como máximo 10 dígitos</Typography>
                                        </React.Fragment>
                                            }
                                        >
                                        <Fab size="small" color="#A5A19F" className={classes.margin} >
                                            <HelpOutlineIcon />
                                        </Fab>
                                   </HtmlTooltip>
                                    <br />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="correo"
                                        label="Correo electrónico"
                                        name="correo"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.correo && formik.errors.correo ? (
                                            true
                                        ) : false}
                                        helperText={formik.touched.correo && formik.errors.correo ? (
                                            formik.errors.correo
                                        ) : null}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className={classes.formControl}
                                        error={formik.touched.paisEm && formik.errors.paisEm ? (true) : false}>
                                        {/* <InputLabel>País</InputLabel>
                                        <Select
                                            native
                                            value={formik.values.paisEm}
                                            onChange={e => {
                                                handleChangeEmergencia(e);
                                                formik.setFieldValue("paisEm", e.target.value);

                                            }}
                                            onBlur={formik.handleBlur}
                                            label="País"
                                            inputProps={{
                                                name: 'paisEm',
                                                id: 'paisEm',
                                            }}
                                        >
                                            <option aria-label="None" value="" /> {
                                                paises.map((pais) => {
                                                    return <option key={pais.idpais} value={pais.idpais + "-" + pais.pais} label={pais.pais}>{pais.pais}</option>
                                                })
                                            }
                                        </Select> */}
                                        {/* ////////////////////// */}
                                        <Autocomplete
                                            freeSolo
                                            name='paisEm'
                                            id='paisEm'
                                            value={formik.values.paisEm}
                                            onChange={(event, newValue) => {
                                                if (newValue != null) {
                                                    handleChangeEmergencia(newValue);
                                                }
                                                formik.setFieldValue("paisEm", newValue);
                                            }}
                                            label="País"
                                            options={paises.map((pais) => pais.idpais + "-" + pais.pais)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="País"
                                                    name='paisEm'
                                                    id='paisEm'
                                                    margin="normal"
                                                    variant="outlined"
                                                    onBlur={formik.handleBlur}
                                                />
                                            )}
                                        >
                                        </Autocomplete>
                                        <FormHelperText>
                                            {formik.touched.paisEm && formik.errors.paisEm ? "El campo país es obligatorio" : null}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}> </Grid>

                                {emergencia ? <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Código postal"
                                            name="cpEm"
                                            variant="outlined"
                                            fullWidth
                                            value={formik.values.cpEm}
                                            onChange={e => {
                                                handleChangeCP(e);
                                                formik.setFieldValue("cpEm", e.target.value);
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.cpEm && formik.errors.cpEm ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.cpEm && formik.errors.cpEm ? (
                                                formik.errors.cpEm
                                            ) : null}
                                        ></TextField>

                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={formik.touched.estadoEm && formik.errors.estadoEm ? (true) : false}>
                                            <InputLabel>Estado</InputLabel>
                                            <Select
                                                native
                                                value={formik.values.estadoEm}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                label="Estado"
                                                inputProps={{
                                                    name: 'estadoEm',
                                                    id: 'estadoEm',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {cpContacto ?
                                                    <option value={cpContacto.estado} >{cpContacto.estado}</option>
                                                    // cpContacto.map((estado) => {
                                                    //     return <option key={estado.idcodigo} value={estado.estado} >{estado.estado}</option>
                                                    // }) : null
                                                    : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {formik.touched.estadoEm && formik.errors.estadoEm ? (formik.errors.estadoEm) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={formik.touched.ciudadEm && formik.errors.ciudadEm ? (true) : false}>
                                            <InputLabel>Municipio</InputLabel>
                                            <Select
                                                native
                                                value={formik.values.ciudadEm}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                label="Municipio"
                                                inputProps={{
                                                    name: 'ciudadEm',
                                                    id: 'ciudadEm',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {estadoContacto ?
                                                    <option value={estadoContacto.ciudad} >{estadoContacto.ciudad}</option>
                                                    // estadoContacto.map((ciudad) => {
                                                    //     return <option key={ciudad.idcodigo} value={ciudad.ciudad} >{ciudad.ciudad}</option>
                                                    // }) : null
                                                    : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {formik.touched.ciudadEm && formik.errors.ciudadEm ? (formik.errors.ciudadEm) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={formik.touched.coloniaEm && formik.errors.coloniaEm ? (true) : false}>
                                            <InputLabel >Colonia</InputLabel>
                                            <Select
                                                native
                                                value={formik.values.coloniaEm}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                label="Colonia"
                                                inputProps={{
                                                    name: 'coloniaEm',
                                                    id: 'coloniaEm',
                                                }}>
                                                <option aria-label="None" value="" />
                                                {coloniaContacto ?
                                                    coloniaContacto.map((colonia) => {
                                                        return <option key={colonia.idcodigo} value={colonia.idcodigo} >{colonia.colonia}</option>
                                                    }) : null
                                                }
                                                <option value="otra">Otra colonia..</option>
                                            </Select>
                                            <FormHelperText>
                                                {formik.touched.coloniaEm && formik.errors.coloniaEm ? (formik.errors.coloniaEm) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Otra colonia..."
                                            id="otroEm"
                                            name="otroEm"
                                            value={formik.values.otroEm}
                                            onChange={(e) => {
                                                let col = e.target.value;
                                                formik.setFieldValue("otroEm", col.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.otroEm && formik.errors.otroEm ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.otroEm && formik.errors.otroEm ? (
                                                formik.errors.otroEm
                                            ) : null}

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="calleEm"
                                            label="Calle y número"
                                            name="calleEm"
                                            value={formik.values.calleEm}
                                            onChange={(e) => {
                                                let callenum = e.target.value;
                                                formik.setFieldValue("calleEm", callenum.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.calleEm && formik.errors.calleEm ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.calleEm && formik.errors.calleEm ? (
                                                formik.errors.calleEm
                                            ) : null}

                                        />
                                    </Grid>
                                </> :
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="domicilio"
                                            label="Domicilio completo"
                                            name="domicilio"
                                            value={formik.values.domicilio}
                                            onChange={(e) => {
                                                let dom = e.target.value;
                                                formik.setFieldValue("domicilio", dom.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.domicilio && formik.errors.domicilio ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.domicilio && formik.errors.domicilio ? (
                                                formik.errors.domicilio
                                            ) : null}
                                        />
                                    </Grid>

                                }

                            </Grid>
                            <br />
                        </Paper>
                    </Grid>
                {/* </Grid> */}

                <br />
                <br />
                <Grid container>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.boton}>
                            Guardar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>
                <br />
            </form>
        </div >
    );
}

export default DatosGenerales;