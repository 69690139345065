import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useState } from 'react';
import { withRouter } from "react-router";

import { fba } from '../../firebaseConfig';

import Swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
    paper: {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zTndex: theme.zIndex.drawer + 2,
        color: '#fff'
    },
    paperContainer: {

        padding: theme.spacing(6),
        marginTop: theme.spacing(14),
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
}));



const showAlert = () => {
    Swal({
        title: 'Ve a tu correo para restablecer tu contraseña',
        text: ' ',
        icon: 'success',
        button: false,
        timer: 3000
    })
}

const showError = () => {
    Swal({
        title: 'Verifica tu email',
        text: ' ',
        icon: 'error',
        button: false,
        timer: 3000
    })
}

const Password = ({ setPassword, history }) => {

    const [cargando, setCargando] = useState(false)
    const [exito, setExito] = useState(false)

    const [correo, setCorreo] = useState('');
    const [error, seterror] = useState('');

    const timerSucces = () => {
        setExito(false)
        setPassword(false)
    }

    const classes = useStyles();

    const handleChangePassword = async e => {
        e.preventDefault();

        setCargando(true);
        await fba
            .auth()
            .sendPasswordResetEmail(correo)
            .then(result => {

                setCargando(false);

            })
            .catch(error => {
                seterror(error.message);
            });
        setExito(true);
        setTimeout(timerSucces, 2000);

    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paperContainer}>
                <CssBaseline />
                {cargando ?
                    <Backdrop className={classes.backdrop} open>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    (
                        <div className={classes.paper}>
                            { exito ? (

                                showAlert()

                            ) : null
                            }
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>

                            <Typography component="h1" variant="h5">
                                Cambiar contraseña
                            </Typography>
                            <form className={classes.form} noValidate onSubmit={handleChangePassword}>
                                {error ? showError() : null}
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={e => {
                                                setCorreo(e.target.value)

                                            }}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={!correo}
                                >
                                    Enviar
                                </Button>

                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <Typography>
                                            <Link href="#" variant="body2" onClick={() => setPassword(false)}>
                                                Regresar
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    )
                }
            </Paper>
        </Container >
    );
}

export default withRouter(Password);