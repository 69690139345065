import React, { useEffect, useState, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import { Button, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import Swal from 'sweetalert'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControl, FormControlLabel, FormLabel, InputLabel, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";

const useStyles = makeStyles((theme) => ({
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaIdioma(props) {
    const classes = useStyles();
    const { persona } = props.data;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [idiomas, setIdiomas] = useState([])
    const [id, setId] = React.useState('');
    const [value, setValue] = React.useState('female');
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    const [columns, setColumns] = useState([
        {
            title: 'Idioma', field: 'idioma.idioma'
        },
        {
            title: 'Nivel', field: 'nivel'
        },
        // {
        //     title: 'Web', field: 'asociacion.web'
        // },
    ]);

    useEffect(() => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {

                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }

                    // if (doc.data().posgrado === 0) {
                    //     //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
                    //     axios.get(Global + "programa/", {
                    //         headers: {
                    //             'Access-Control-Allow-Origin': "http://localhost:3000",
                    //             'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    //             'Content-Type': 'application/json',
                    //             Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    //         }
                    //     }).then(response => {
                    //         setData(response.data)
                    //     }).catch(error => console.log(error))
                    // } else {
                    //     //si es diferente de 0 entonces tiene acceso a solo un posgrado
                    //     //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo y programa del usuario
                    //     axios.get(Global + "programa/" + doc.data().posgrado,
                    //         {
                    //             headers: {
                    //                 'Access-Control-Allow-Origin': "http://localhost:3000",
                    //                 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    //                 'Content-Type': 'application/json',
                    //                 Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    //             }
                    //         }).then(response => {
                    //             setData([response.data])
                    //         }).catch(error => console.log(error))
                    // }
                });
            });
        }
        
        axios.get(Global + "idioma/persona/" + persona.idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response)
            setIdiomas(response.data)
        }).catch(error => console.log(error))

        axios.get(Global + "personaid/persona/" + persona.idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response)
            setData(response.data)
        }).catch(error => console.log(error))

    }, [])


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleCancel = () => {
        setOpen(false)
        setId('')
    };

    const handleOnChange = (e) => {
        setId(e.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
        setId('')
    };

    const handleSubmit = () => {
        if (id !== '') {
            axios.post(Global + "personaid/persona/" + persona.idP + "/idioma/" + id,
                {
                    estatus: 'alta',
                    nivel: value
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(() => {
                    setId('')
                    axios.get(Global + "idioma/persona/" + persona.idP, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response)
                        setIdiomas(response.data)
                    }).catch(error => console.log(error))

                    axios.get(Global + "personaid/persona/" + persona.idP, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response)
                        setData(response.data)
                    }).catch(error => console.log(error))


                    Swal({
                        title: 'Idioma agregado',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })

                }).catch(error => console.log(error))


        } else {
            Swal({
                title: '¡Debe seleccionar un idioma!',
                text: ' ',
                icon: 'error',
                button: false,
                timer: 1000
            })
        }
    }


    return (
        rol === "administrador" ||
        rol === "coordinador_general" ||
        rol === "coordinador_posgrado" ?
        <>
            <MaterialTable
                style={{
                    box_shadow: 'none'
                }}
                options={{
                    exportButton:true,
                    exportFileName:"Idioma",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                        addTooltip: 'Agregar',
                        editTooltip: 'Editar',
                        deleteTooltip: 'Deshabilitada',
                        editRow: {
                            deleteText: '¿Desea inhabilitar este docente?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Guardar'
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones',
                    }

                }}
                title={
                        <Hidden smDown>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Idioma
                                </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    }
                columns={columns}
                data={data}
                actions={[
                    {
                        icon: 'add_box',
                        tooltip: 'Agregar idioma',
                        isFreeAction: true,
                        onClick: () => handleClickOpen()
                    }
                ]}

            />
            <Dialog open={open}>
                <DialogTitle style={{ marginBottom: -10 }} id="customized-dialog-title">
                    <DialogContentText style={{ marginBottom: -10 }}>Seleccione el idioma que desea agregar</DialogContentText>
                </DialogTitle>
                <DialogContent>
                    <br />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Nivel</FormLabel>
                        <RadioGroup aria-label="nivel" name="nivel" value={value} onChange={handleChange}>
                            <FormControlLabel value="BAJO" control={<Radio />} label="BAJO" />
                            <FormControlLabel value="MEDIO" control={<Radio />} label="MEDIO" />
                            <FormControlLabel value="ALTO" control={<Radio />} label="ALTO" />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl
                        variant="outlined"
                        fullWidth
                        required>
                        <InputLabel id="idioma">Idioma</InputLabel>
                        <Select
                            labelId="idioma"
                            native
                            label="idioma"
                            onChange={e => {
                                handleOnChange(e);
                                // alert(e.target.value)
                            }}
                            inputProps={{
                                name: 'idioma',
                                id: 'idioma',
                            }}
                        >
                            <option aria-label="None" value="" />{
                                idiomas.map((idioma) => {
                                    return <option key={idioma.ididioma} value={idioma.ididioma}>{idioma.idioma}</option>
                                })
                            }

                        </Select>
                    </FormControl>



                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        :
        <MaterialTable
        style={{
            box_shadow: 'none'
        }}
        options={{
            exportButton:true,
            exportFileName:"Idioma",
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            headerStyle: {
                backgroundColor: '#66abe0b8',
                color: '#FFF'
            }
        }}
        localization={{
            pagination: {
                labelDisplayedRows: '',
                labelRowsSelect: '',
                firstTooltip: 'Primera página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Siguiente página',
                lastTooltip: 'Última página',
            },
            body: {
                emptyDataSourceMessage: 'No hay información por mostrar'
            },
            toolbar: {
                searchTooltip: 'Buscar en la tabla',
                searchPlaceholder: 'Buscar',
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                exportTitle: "Exportar"
            },
            header: {
                actions: 'Acciones',
            }

        }}
        title={
                <Hidden smDown>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography className={classes.subtitulo}>
                                Idioma
                        </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            }
        columns={columns}
        data={data}
      

    />
    )
}

export default TablaIdioma;