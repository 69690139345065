import React, { useEffect, useState } from 'react'
import { Avatar, Grid, Paper, TextField, Typography } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    avatar: {
        background: theme.palette.secondary.main,
    }
}));

const  DatosPosgrado = ({ persona }) => {


    //Archivos
    const [fileRequisito1, setFileRequisito1] = useState(null);
    const [fileRequisito2, setFileRequisito2] = useState(null);
    const [fileRequisito3, setFileRequisito3] = useState(null);
    const [fileDocumento, setFileDocumento] = useState(null)
    const [fileFinanciamiento, setFileFinanciamiento] = useState(null)
    const [fileAnteproyecto, setFileAnteproyecto] = useState(null);    
    const [posgrado, setPosgrado] = useState({

    });
    const [equipot, setEquipot] = useState(true);

    const classes = useStyles();

    useEffect(async () => {

        let pos = {
            estudios: '',
            posgrado: '',
            periodo: '',
            categoria: '',
            equipot: '',           
            documento: '',
            financiamiento: '',
            anteproyecto: '',
            requisito1:'',
            requisito2:'',
            requisito3:'',
        }

        await axios.get(Global + "estudiosrea/persona/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            console.log(response.data)
            pos.estudios = response.data[0].nivel
            pos.posgrado = response.data[0].programa.programa
            pos.periodo = response.data[0].fechaini
            pos.categoria = response.data[0].cate
            pos.financiamiento = response.data[0].finan
            pos.anteproyecto = response.data[0].anteproyecto
            pos.requisito1 = response.data[0].req1
            pos.requisito2 = response.data[0].req2
            pos.requisito3 = response.data[0].req3  
            
            //Ver si existe equipo
            if (pos.financiamiento !== null && pos.financiamiento !== "") {
                // alert(response.data[0].finan)
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data[0].finan)
                setFileFinanciamiento(await httpsReference.getDownloadURL());
            }
            if (pos.anteproyecto !== null && pos.anteproyecto !== "") {
                
                let storageante = fba.storage().ref();
                let httpsReferenceante = storageante.child('' + pos.anteproyecto)
                setFileAnteproyecto(await httpsReferenceante.getDownloadURL());
            }
            if (pos.requisito1 !== null && pos.requisito1 !== "") {
                let storagereq1 = fba.storage().ref();
                let httpsReferencereq1 = storagereq1.child('' + response.data[0].req1)
                setFileRequisito1(await httpsReferencereq1.getDownloadURL());
            }
            if (pos.requisito2 !== null && pos.requisito2 !== "" ) {
                let storagereq2 = fba.storage().ref();
                let httpsReferencereq2 = storagereq2.child('' + response.data[0].req2)
                setFileRequisito2(await httpsReferencereq2.getDownloadURL());
            }
            if (pos.requisito3 !== null && pos.requisito3 !== "" ) {
                let storagereq3 = fba.storage().ref();
                let httpsReferencereq3 = storagereq3.child('' + response.data[0].req3)
                setFileRequisito3(await httpsReferencereq3.getDownloadURL());
            }

            await axios.get(Global + "equipo/estudios/" + response.data[0].idposgrado, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    pos.equipot = response.data[0].nombre
                }
            }).catch(error => console.log(error))

        }).catch(error => console.log(error))

        await axios.get(Global + "escolaridad/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async response => {
            console.log(response.data)
            pos.documento = response.data[0].finan
          
            if (pos.documento !== null && pos.documento !== "") {
                let storage3 = fba.storage().ref();
                let httpsReference3 = storage3.child('' + pos.documento)
                setFileDocumento(await httpsReference3.getDownloadURL());
            }


        }).catch(error => console.log(error))
        setPosgrado(pos)

    }, [])




    const initial = posgrado
    
    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid
                    container
                    spacing={1}
                    alignItems="center"

                >
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <AccountBalanceIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">
                            Posgrado
                        </Typography>
                    </Grid>

                </Grid>
                <br />
                <Formik
                    enableReinitialize
                    initialValues={initial}
                >
                    {props => {

                        return (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="estudios"
                                            name="estudios"
                                            label="Estudios que desea realizar"
                                            value={props.values.estudios}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="periodo"
                                            name="periodo"
                                            label="Periodo"
                                            value={props.values.periodo}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="posgrado"
                                            name="posgrado"
                                            label="Posgrado"
                                            value={props.values.posgrado}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="categoria"
                                            name="categoria"
                                            label="Categoría"
                                            value={props.values.categoria}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>


                                    {
                                        equipot ?

                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    variant="outlined"
                                                    type="text"
                                                    disabled
                                                    fullWidth
                                                    id="equipot"
                                                    name="equipot"
                                                    label="Equipo de trabajo"
                                                    value={props.values.equipot}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            : null
                                    }  </Grid>

                                <Grid
                                    container
                                    spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                    Proyecto de Investigación o Trabajo Profesional
                                        <Grid item xs={10} sm={8}>
                                            {fileAnteproyecto !== null && fileAnteproyecto !== '' ?
                                                <a style={{ display: "table-cell" }} href={fileAnteproyecto} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    justify="center"
                                    spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        Documento Finiquito Conacyt
                                        <Grid item xs={10} sm={8}>
                                            {fileDocumento !== '' && fileDocumento !== null ?
                                                <a style={{ display: "table-cell" }} href={fileDocumento} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                                </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <Grid item xs={12} sm={6}>
                                        Documento Fuente de Financiamiento
                                        <Grid item xs={10} sm={8}>
                                            {fileFinanciamiento !== '' && fileFinanciamiento !== null ?
                                                <a style={{ display: "table-cell" }} href={fileFinanciamiento} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Requisito Específico 1
                                        <Grid item xs={10} sm={8}>
                                            {fileRequisito1 !== '' && fileRequisito1 !== null ?
                                                <a style={{ display: "table-cell" }} href={fileRequisito1} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        Requisito Específico 2
                                        <Grid item xs={10} sm={8}>
                                            {fileRequisito2 !== '' && fileRequisito2 !== null ?
                                                <a style={{ display: "table-cell" }} href={fileRequisito2} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        Requisito Específico 3
                                        <Grid item xs={10} sm={8}>
                                            {fileRequisito3 !== '' && fileRequisito3 !== null ?
                                                <a style={{ display: "table-cell" }} href={fileRequisito3} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                    <br />
                                </Grid>
                            </>
                        )
                    }}
                </Formik>
            </Paper >
        </div >
    );
}

export default DatosPosgrado;