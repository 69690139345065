import React, { useEffect } from 'react'
import { fba } from "../../../firebaseConfig";
import { Global } from '../../../constants/global';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios';
import DetallesAlumno from "./DetallesAlumno"
import { obtenerAlumnos } from '../../../servicios/obtenerAlumnos';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaActualizar(props) {

    const classes = useStyles();
    const { useState } = React;
    const [detalles, setDetalles] = useState('');

    const [columns, setColumns] = useState([
        {
            title: 'Mátricula', field: 'matricula'
        },
        {
            title: 'Nombre', field: 'persona.nombre'
        },
        {
            title: 'Paterno', field: 'persona.apellidoP'
        },
        {
            title: 'Materno', field: 'persona.apellidoM'
        },
        {
            title: 'Generación', field: 'generacion'
        },
        {
            title: 'Programa', field: 'programa.programa'
        },
        {
            title: 'Dependencia de la Institución de Procedencia', field: 'dependencia'
        },
        {
            title: 'Estatus', field: 'estatus'
        },
        {
            title: 'Conacyt', field: 'conacyt'
        },
        {
            title: 'Cubierto Idioma', field: 'idioma'
        },
        {
            title: 'TOEFL', field: 'toefel'
        },
        {
            title: 'TOEFEL Comprencion Lectura', field: 'toefelec'
        },
        {
            title: 'Fecha Oficial del TOEFEL', field: 'toefelfe'
        },
        {
            title: 'Observación', field: 'observacion'
        },
        {
            title: 'Fecha de Ingreso', field: 'fechain'
        },

    ]);

    const [data, setData] = useState([]);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');

    useEffect(() => {
        let userFB = fba.auth().currentUser;
        //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
        db.where('correo', '==', userFB.email).get().then(snapshot => {
            snapshot.docs.forEach(doc => {
                //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                if (doc.data().posgrado === 0) {
                    //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
                    axios.get(Global + "alumno/", {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setData(response.data)
                    }).catch(error => console.log(error))
                } else {
                    obtenerAlumnos(doc,'alumno/programa/')
                        .then(programa=>{                            
                            let alumnos = [];
                            for(let alumno of programa){
                                alumnos = alumnos.concat(alumno);
                            }
                            setData(alumnos);
                        })
                        .catch((err)=>{console.log(err)})
                }
            });
        });
    }, [])

    useEffect(() => {
        if (detalles !== '') {
            props.metodo('Detalles de alumno')
        } else {
            props.metodo('Alumnos')
        }
    }, [detalles])

    const redirect = (data) => {
        setDetalles(data);
    }

    return (
        detalles !== '' ?
            <>
                <DetallesAlumno data={detalles} metodo={redirect} />
            </>
            : <MaterialTable
                style={{
                    box_shadow: 'none'
                }}
                options={{
                    exportButton:true,
                    exportFileName:"Listado de alumnos",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10,25,50,100,200],
                    columnsButton: true,
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    pagination: {
                        /* labelDisplayedRows: '', */
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                        addTooltip: 'Agregar',
                        editTooltip: 'Editar',
                        deleteTooltip: 'Deshabilitada',
                        editRow: {
                            deleteText: '¿Desea inhabilitar este docente?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Guardar'
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones',
                    }

                }}
                title={
                    <Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                            <Typography className={classes.subtitulo}>
                                Listado de alumnos
                            </Typography>
                        </Grid>
                    </Grid>
                }
                columns={columns}
                data={data}
                actions={[
                    {
                        icon: 'folder_shared',
                        tooltip: 'Detalles del alumno',
                        onClick: (event, rowData) => {
                            redirect(rowData)
                        }
                    }
                ]}
                editable={{
                    // onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //         setTimeout(async () => {
                    //             const dataDelete = [...data];
                    //             const index = oldData.tableData.id;
                    //             var changeData = {
                    //                 idunidad: dataDelete[index].idunidad,
                    //                 nombre: dataDelete[index].nombre,
                    //                 estatus: 'baja',
                    //             }
                    //             dataDelete.splice(index, 1);
                    //             setData([...dataDelete]);
                    //             await axios.put(Global + "unidad/" + changeData.idunidad, changeData, {
                    //                 headers: {
                    //                     'Access-Control-Allow-Origin': "http://localhost:3000",
                    //                     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    //                     'Content-Type': 'application/json',
                    //                     Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    //                 }
                    //             }).then(response => {
                    //                 console.log(response);
                    //                 Swal({
                    //                     title: 'Alumno deshabilitado',
                    //                     text: ' ',
                    //                     icon: 'success',
                    //                     button: false,
                    //                     timer: 1000
                    //                 })
                    //             }).catch(error => {
                    //                 console.log(error);
                    //             })

                    //             resolve()
                    //         }, 1000)
                    //     }),
                }}
            />
    )
}

export default TablaActualizar;