import React, { useEffect, useState } from 'react'
import { Avatar, Grid, Paper, TextField, Typography } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperContainer: {
        // display:'flex',
        // flexDirection:'column',
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        marginLeft: "20px",
        marginRight: "20px",
    },
    avatar: {
        background: theme.palette.secondary.main,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    }
}));

function EstudiosRealizados({ persona }) {

    // const [persona, setPersona] = useState('')
    const [escolaridadAnterior, setEscolaridadAnterior] = useState({
        grado: '',
        institucion: '',
        ins: '',
        estado: '',
        pais: '',
        fechain: '',
        fechafin: '',
        promedio: '',
        cedula: '',
        titulo: '',
        toefel: '',
        lmma: '',
        ced: '',
        prt: '',
        lm: '',
    })
    const [fileTitulo, setFileTitulo] = useState('')
    const [fileListaMateriasLicenciatura, setFileListaMateriasLicenciatura] = useState('')
    const [fileDiploma, setFileDiploma] = useState('')
    const [fileIdentificacionOficial, setFileIdentificacionOficial] = useState ('')
    const [fileListaMateriasMaest, setFileListaMateriasMaest] = useState('')
    const [fileCedulaLicenciatura, setFileCedulaLicenciatura] = useState('')
    const [fileCedulaMaestria, setFileCedulaMaestria] = useState('')



    useEffect(async () => {

        await axios.get(Global + "escolaridad/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async responses => {
            // console.log(responses.data)
            responses.data[0].institucion = responses.data[0].institucion.nombre
            setEscolaridadAnterior(responses.data[0])
            if (responses.data[0].titl !== null && responses.data[0].titl !== "") {

                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + responses.data[0].titl)
                setFileTitulo(await httpsReference.getDownloadURL());
            }
            if (responses.data[0].toefel !== null && responses.data[0].toefel !== "") {
                let storage2 = fba.storage().ref();
                let httpsReference2 = storage2.child('' + responses.data[0].toefel)
                setFileListaMateriasLicenciatura(await httpsReference2.getDownloadURL());
            }

            if (responses.data[0].dip !== null && responses.data[0].dip !== "") {
                let storage3 = fba.storage().ref();
                let httpsReference3 = storage3.child('' + responses.data[0].dip)
                setFileDiploma(await httpsReference3.getDownloadURL());
            }

            if (responses.data[0].lmma !== null && responses.data[0].lmma !== "") {
                let storage4 = fba.storage().ref();
                let httpsReference4 = storage4.child('' + responses.data[0].lmma)
                setFileListaMateriasMaest(await httpsReference4.getDownloadURL());
            }

            if (responses.data[0].ced !== null && responses.data[0].ced !== "") {
                let storage5 = fba.storage().ref();
                let httpsReference5 = storage5.child('' + responses.data[0].ced)
                setFileCedulaLicenciatura(await httpsReference5.getDownloadURL());
            }

            if (responses.data[0].prt !== null && responses.data[0].prt !== "") {
                let storage6 = fba.storage().ref();
                let httpsReference6 = storage6.child('' + responses.data[0].prt)
                setFileCedulaMaestria(await httpsReference6.getDownloadURL());
            }

            if (responses.data[0].prt !== null && responses.data[0].prt !== "") {
                let storage7 = fba.storage().ref();
                let httpsReference7 = storage7.child('' + responses.data[0].lm)
                setFileIdentificacionOficial(await httpsReference7.getDownloadURL());
            }

            // setEscolaridadAnterior()
        }).catch(error => console.log(error));

    }, [])


    const classes = useStyles();
    const initial = escolaridadAnterior

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initial}
            >
                {props => {
                    return (
                        <Paper elevation={3} className={classes.paperContainer}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Avatar className={classes.avatar}>
                                        <AccountBalanceIcon />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        Estudios realizados
                                    </Typography>
                                </Grid>

                            </Grid>

                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Grado"
                                        id="grado"
                                        name="grado"
                                        type="text"
                                        value={props.values.grado}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Institución"
                                        id="institucion"
                                        name="institucion"
                                        type="text"
                                        value={props.values.institucion}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Estado o Provincia"
                                        id="estado"
                                        name="estado"
                                        type="text"
                                        value={props.values.estado}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        disabled
                                        fullWidth
                                        id="pais"
                                        name="pais"
                                        label="País"
                                        value={props.values.pais}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        disabled
                                        fullWidth
                                        label="Año de inicio"
                                        id="fechain"
                                        name="fechain"
                                        value={props.values.fechain}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        type="text"
                                        label="Año de terminación"
                                        id="fechafin"
                                        name="fechafin"
                                        value={props.values.fechafin}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        type="number"
                                        label="Promedio"
                                        id="promedio"
                                        name="promedio"
                                        value={props.values.promedio}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        type="text"
                                        label="Cédula"
                                        id="cedula"
                                        name="cedula"
                                        value={props.values.cedula}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Identificación Oficial
                                        <Grid item xs={10} sm={8}>
                                        {fileIdentificacionOficial !== '' && fileIdentificacionOficial !== null ?
                                            <a style={{ display: "table-cell" }} href={fileIdentificacionOficial} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Titulo de Maestria
                                        <Grid item xs={10} sm={8}>
                                        {fileDiploma !== '' && fileDiploma !== null ?
                                            <a style={{ display: "table-cell" }} href={fileDiploma} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Lista oficial de materias con promedio general de Maestria
                                    <Grid item xs={10} sm={8}>
                                        {fileListaMateriasMaest !== '' && fileListaMateriasMaest !== null ?
                                            <a style={{ display: "table-cell" }} href={fileListaMateriasMaest} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Titulo de Licenciatura
                                    <Grid item xs={10} sm={8}>
                                        {fileTitulo !== '' && fileTitulo !== null ?
                                            <a style={{ display: "table-cell" }} href={fileTitulo} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}

                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Lista oficial de materias con promedio general de Licenciatura
                                    <Grid item xs={10} sm={8}>
                                        {fileListaMateriasLicenciatura !== '' && fileListaMateriasLicenciatura !== null ?
                                            <a style={{ display: "table-cell" }} href={fileListaMateriasLicenciatura} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Cédula Profesional de Licenciatura
                                    <Grid item xs={10} sm={8}>
                                        {fileCedulaLicenciatura !== '' && fileCedulaLicenciatura !== null ?
                                            <a style={{ display: "table-cell" }} href={fileCedulaLicenciatura} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={8}>
                                    Cédula Profesional de Maestria
                                    <Grid item xs={10} sm={8}>
                                        {fileCedulaMaestria !== '' && fileCedulaMaestria !== null ?
                                            <a style={{ display: "table-cell" }} href={fileCedulaMaestria} target="_blank" rel="noreferrer">
                                                Ver archivo
                                            </a> : "Sin documento"}
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Paper >
                    )
                }}
            </Formik>
        </div>
    );
}

export default EstudiosRealizados;