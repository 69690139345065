import React, { useState, useEffect } from "react"
import { Typography, Button, TextField, Grid, FormControl, InputLabel, Select, FormHelperText, CircularProgress } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Global, FILE_SIZE, SUPPORTED_PDF } from "../../../constants/global"
import { Formik } from "formik"
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup'
import axios from "axios";
import Swal from 'sweetalert'
import Backdrop from '@material-ui/core/Backdrop';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { menor, mayor, FORMATOFECHAMAXHOY, FORMATOFECHAMINHOY } from '../../generales/Fecha';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    boton2: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#020e69",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Editar({ programa, rol, metodo }) {

    const classes = useStyles();

    const [fileActa, setFileActa] = useState(0);
    const [, setMensajeActa] = useState('');

    const [fileDoc, setFileDoc] = useState(0);
    const [, setMensajeDoc] = useState('');

    const [filePlan, setFilePlan] = useState(0);
    const [, setMensajePlan] = useState('');

    const [datosPrograma, setDatosPrograma] = useState([])
    const [backdrop, setBackdrop] = useState(false);
    const [change, setChange] = useState(false);
    const [acceso, setAcceso] = useState(true);


    useEffect(() => {

        const datos2 = async () => {

            rol === "administrador" ||
                rol === "coordinador_general" ||
                rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)

            await axios.get(Global + "programa/" + programa.idprograma, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async response => {
                if (response.data.acta !== null && response.data.acta !== '') {
                    // alert(response.data.doc_curp)
                    let storage = fba.storage().ref();
                    let httpsReference = storage.child('' + response.data.acta)
                    response.data.acta = await httpsReference.getDownloadURL()

                }
                if (response.data.docs !== null && response.data.docs !== '') {
                    // alert(response.data.doc_curp)
                    let storage2 = fba.storage().ref();
                    let httpsReference2 = storage2.child(response.data.docs)
                    response.data.docs = await httpsReference2.getDownloadURL()
                }
                if (response.data.planestudio !== null && response.data.planestudio !== ''){
                    let storage3 = fba.storage().ref();
                    let httpsReference3 = storage3.child('' + response.data.planestudio)
                    response.data.planestudio = await httpsReference3.getDownloadURL()
                }

                if (response.data) {
                    setDatosPrograma(response.data)
                    setBackdrop(true)
                }

            }).catch(error => console.log(error))


        }

        datos2();

        handleCargaClose();


    }, [change])


    const schemaValidation = Yup.object({
        abrev: Yup.string().required('El campo es obligatorio').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        clave: Yup.string().required('El campo es obligatorio').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        programa: Yup.string().required('El campo es obligatorio').matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, 'Solo letras.'),
        tipo: Yup.string().required('El campo es obligatorio'),
        claveco: Yup.number().positive('El valor debe ser mayor a 0'),
        clavese: Yup.number().positive('El valor debe ser mayor a 0'),
        comentarios: "",
        acta: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileActa === 0 || SUPPORTED_PDF.includes(fileActa.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileActa === 0 || fileActa.size <= FILE_SIZE)),
        docs: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileDoc === 0 || SUPPORTED_PDF.includes(fileDoc.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileDoc === 0 || fileDoc.size <= FILE_SIZE)),
        planestudio: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (filePlan === 0 || SUPPORTED_PDF.includes(filePlan.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (filePlan === 0 || filePlan.size <= FILE_SIZE)),
        estatus: "",
        periodo: "",
        fechacr: Yup.string()
            .test('match',
                'La fecha debe ser menor a la fecha actual',
                function (fecha) {
                    return menor(fecha)
                }
            ),
        fechain: Yup.string().test('match',
            'La fecha de inicio debe ser mayor a la fecha de creación',
            function (fechain) {
                if (fechain !== "")
                    return fechain >= this.parent.fechacr
            }),
        pncp: "",
        pweb: "",
        orientacion: Yup.string(),
        vigencia: Yup.string()
            .test('match',
                'La fecha debe ser mayor a la fecha actual',
                function (fecha) {
                    return mayor(fecha)
                }
            ),
        version: ""
    })

    const functionSubmit = valores => {
        if (valores.fechacr === "") {
            valores.fechacr = "2000-01-01"
            valores.fechain = "2000-01-01"
        }

        const programaN = {
            abrev: valores.abrev,
            acta: "",
            clave: valores.clave,
            claveco: valores.claveco,
            clavese: valores.clavese,
            comentarios: valores.comentarios,
            docs: "",
            estatus: valores.estatus,
            periodo: valores.periodo,
            fechacr: valores.fechacr,
            fechain: valores.fechain,
            programa: valores.programa,
            planestudio: "",
            pncp: valores.pncp,
            pweb: valores.pweb,
            tipo: valores.tipo,
            orientacion: valores.orientacion,
            vigencia: valores.vigencia,
            version: valores.version,
        }

        programaN.acta = programa.acta
        programaN.docs = programa.docs
        programaN.planestudio = programa.planestudio
        if (fileActa) {
            if (programa.acta === '') {
                programaN.acta = 'Programa/' + valores.programa + '/' + uuidv4() + fileActa.name
                console.log(programaN.acta)
            }
            const storageRef = fba.storage().ref(programaN.acta)
            const task = storageRef.put(fileActa)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setMensajeActa(porcentage + '%')
            }, (error) => {
                console.log(error);
                setMensajeActa('Error en la carga del archivo ' + error.message)
            })
        }


        if (fileDoc) {
            if (programa.docs === '') {
                programaN.docs = 'Programa/' + valores.programa + '/' + uuidv4() + fileDoc.name
            }
            const storageRef2 = fba.storage().ref(programaN.docs)
            const task2 = storageRef2.put(fileDoc)
            task2.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setMensajeDoc(porcentage + '%')
            }, (error) => {
                console.log(error);
                setMensajeActa('Error en la carga del archivo ' + error.message)
            })
        }

        if (filePlan) {
            if (programa.planestudio === '') {
                programaN.planestudio = 'Programa/' + valores.programa + '/' + uuidv4() + filePlan.name
            }
            const storageRef3 = fba.storage().ref(programaN.planestudio)
            const task3 = storageRef3.put(filePlan)
            task3.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setMensajePlan(porcentage + '%')
            }, (error) => {
                console.log(error);
                setMensajePlan('Error en la carga del archivo ' + error.message)
            })
        }


        axios.put(Global + "programa/" + programa.idprograma, programaN, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.status === 200) {
                Swal({
                    title: 'Datos registrados',
                    text: '',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
            }
            metodo()
        }).catch(error => console.log(error))


    }


    const handleOnChange = (event) => {

        if (event.target.files[0]) {
            const file = event.target.files[0]

            switch (event.target.id) {
                case "acta":
                    setFileActa(file)
                    break;
                case "docs":
                    setFileDoc(file)
                    break;
                case "planestudio":
                    setFilePlan(file)
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "acta":
                    setFileActa(false)
                    break;
                case "docs":
                    setFileDoc(false)
                    break;
                case "planestudio":
                    setFilePlan(false)
                    break;
                default:
                    break;
            }
        }
    }

    const handleCargaClose = () => {
        setChange(false);
    };


    const initial = datosPrograma
    // console.log(acceso)
    return (
        <div>
            <main className={classes.layout}>
                {backdrop ?
                    <Formik
                        validationSchema={schemaValidation}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            functionSubmit(valores)
                        }}
                    >
                        {props => {

                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Clave"
                                                id="clave"
                                                name="clave"
                                                value={props.values.clave}
                                                onChange={(e) => {
                                                    let clave = e.target.value;
                                                    props.setFieldValue("clave", clave.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.clave && props.errors.clave ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.clave && props.errors.clave ? (
                                                    props.errors.clave
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Nombre"
                                                id="programa"
                                                name="programa"
                                                value={props.values.programa}
                                                onChange={(e) => {
                                                    let program = e.target.value;
                                                    props.setFieldValue("programa", program.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.programa && props.errors.programa ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.programa && props.errors.programa ? (
                                                    props.errors.programa
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Orientacion"
                                                id="orientacion"
                                                name="orientacion"
                                                value={props.values.orientacion}
                                                onChange={(e) => {
                                                    let ori = e.target.value;
                                                    props.setFieldValue("orientacion", ori.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.orientacion && props.errors.orientacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.orientacion && props.errors.orientacion ? (
                                                    props.errors.orientacion
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Abreviatura"
                                                id="abrev"
                                                name="abrev"
                                                value={props.values.abrev}
                                                onChange={(e) => {
                                                    let ab = e.target.value;
                                                    props.setFieldValue("abrev", ab.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.abrev && props.errors.abrev ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.abrev && props.errors.abrev ? (
                                                    props.errors.abrev
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                error={props.touched.tipo && props.errors.tipo ? (true) : false}>
                                                <InputLabel>Nivel</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.tipo}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Nivel"
                                                    inputProps={{
                                                        name: 'tipo',
                                                        id: 'tipo',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'Doctorado'}>Doctorado</option>
                                                    <option value={'Maestria'}>Maestria</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.tipo && props.errors.tipo ? (props.errors.tipo) : null}</FormHelperText>
                                            </FormControl>

                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                id="fechacr"
                                                name="fechacr"
                                                label="Fecha de creación"
                                                type="date"
                                                value={props.values.fechacr}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={props.touched.fechacr && props.errors.fechacr ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechacr && props.errors.fechacr ? (
                                                    props.errors.fechacr
                                                ) : null}
                                                InputProps={FORMATOFECHAMAXHOY}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                id="fechain"
                                                name="fechain"
                                                label="Fecha de inicio"
                                                type="date"
                                                value={props.values.fechain}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechain && props.errors.fechain ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null}
                                                InputProps={FORMATOFECHAMINHOY}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                error={props.touched.estatus && props.errors.estatus ? (true) : false}>
                                                <InputLabel>Estatus</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.estatus}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Estatus"
                                                    inputProps={{
                                                        name: 'estatus',
                                                        id: 'estatus',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'CONSOLIDADO'}>CONSOLIDADO</option>
                                                    <option value={'DESARROLLO'}>DESARROLLO</option>
                                                    <option value={'NUEVA CREACIÓN'}>NUEVA CREACIÓN</option>
                                                    <option value={'INTERNACIONAL'}>INTERNACIONAL</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.estatus && props.errors.estatus ? (props.errors.estatus) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Año de ingreso al PNPC"
                                                id="pncp"
                                                name="pncp"
                                                type='number'
                                                value={props.values.pncp}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.pncp && props.errors.pncp ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.pncp && props.errors.pncp ? (
                                                    props.errors.pncp
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="vigencia"
                                                name="vigencia"
                                                label="Vigencia de PNPC"
                                                type="date"
                                                value={props.values.vigencia}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={props.touched.vigencia && props.errors.vigencia ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.vigencia && props.errors.vigencia ? (
                                                    props.errors.vigencia
                                                ) : null}
                                                InputProps={FORMATOFECHAMINHOY}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Versión del PNPC"
                                                id="version"
                                                name="version"
                                                value={props.values.version}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={
                                                    props.touched.version && props.errors.version ? true : false
                                                }
                                                helperText={
                                                    props.touched.version && props.errors.version
                                                        ? props.errors.version
                                                        : null
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Página web"
                                                id="pweb"
                                                name="pweb"
                                                value={props.values.pweb}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.pweb && props.errors.pweb ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.pweb && props.errors.pweb ? (
                                                    props.errors.pweb
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Comentarios"
                                                id="comentarios"
                                                name="comentarios"
                                                value={props.values.comentarios}
                                                onChange={(e) => {
                                                    let comen = e.target.value;
                                                    props.setFieldValue("comentarios", comen.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.comentarios && props.errors.comentarios ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.comentarios && props.errors.comentarios ? (
                                                    props.errors.comentarios
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Clave en el Conacyt"
                                                id="claveco"
                                                name="claveco"
                                                type='number'
                                                value={props.values.claveco}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.claveco && props.errors.claveco ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.claveco && props.errors.claveco ? (
                                                    props.errors.claveco
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Clave de la S.E.P."
                                                id="clavese"
                                                name="clavese"
                                                type='number'
                                                value={props.values.clavese}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.clavese && props.errors.clavese ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.clavese && props.errors.clavese ? (
                                                    props.errors.clavese
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                error={props.touched.periodo && props.errors.periodo ? (true) : false}>
                                                <InputLabel>Periodo</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.periodo}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Periodo"
                                                    inputProps={{
                                                        name: 'periodo',
                                                        id: 'periodo',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'PRIMAVERA'}>PRIMAVERA</option>
                                                    <option value={'OTOÑO'}>OTOÑO</option>
                                                    <option value={'MIXTO'}>MIXTO</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.periodo && props.errors.periodo ? (props.errors.periodo) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {/* <Grid item xs={10} sm={8}> */}

                                            <label htmlFor="acta">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="acta"
                                                    name="acta"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Acta por el H. Consejo
                                                    {fileActa ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.acta && props.errors.acta) && (props.errors.acta)}
                                                </Typography>
                                            </label>

                                            <Grid item xs={1} sm={2} >
                                                <Typography variant='body2'>
                                                    {
                                                        datosPrograma.acta !== null && datosPrograma.acta !== "" ?
                                                            <a style={{ display: "table-cell" }} href={datosPrograma.acta} target="_blank" rel="noreferrer">
                                                                Ver archivo
                                                            </a>
                                                            : null
                                                    }
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={12}>

                                            <label htmlFor="docs">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="docs"
                                                    name="docs"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Docto. de Clave S.E.P.
                                                    {fileDoc ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.docs && props.errors.docs) && (props.errors.docs)}
                                                </Typography>
                                            </label>

                                            <Grid item xs={1} sm={2} >
                                                <Typography variant='body2'>
                                                    {
                                                        datosPrograma.docs !== null && datosPrograma.docs !== "" ?
                                                            <a style={{ display: "table-cell" }} href={datosPrograma.docs} target="_blank" rel="noreferrer">
                                                                Ver archivo
                                                            </a>
                                                            : null
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* <Grid item xs={12}>

                                            <label htmlFor="planestudio">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="planestudio"
                                                    name="planestudio"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Plan de Estudio
                                                    {filePlan ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.planestudio && props.errors.planestudio) && (props.errors.planestudio)}
                                                </Typography>
                                            </label>

                                            <Grid item xs={1} sm={2} >
                                                <Typography variant='body2'>
                                                    {
                                                        datosPrograma.planestudio !== null && datosPrograma.planestudio !== "" ?
                                                            <a style={{ display: "table-cell" }} href={datosPrograma.planestudio} target="_blank" rel="noreferrer">
                                                                Ver archivo
                                                            </a>
                                                            : null
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                                        <Grid item xs={12}></Grid>
                                        {
                                            !acceso ?
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            className={classes.boton}>
                                                            Guardar
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                </Grid>
                                                :
                                                null
                                        }

                                    </Grid>
                                </form>
                            )

                        }}

                    </Formik>
                    :
                    <Backdrop className={classes.backdrop} open={change} onClick={handleCargaClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </main>
        </div>
    );
}


export default Editar