import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PhoneIcon from "@material-ui/icons/Phone";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(1, 1),
    marginTop: "auto",
    backgroundColor: "#020e69",
    color: theme.palette.secondary.contrastText,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  leftContainer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    // <div>
    <footer className={classes.footer}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} className={classes.leftContainer}>
          <Typography style={{ display: "flex" }}>
            {/* <PhoneIcon style={{ color: "#fffc" }} /> */}
            Consultas: POSGRADO.SIIP@chapingo.mx
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
          <Typography variant="body1">
            © Derechos reservados UNIVERSIDAD AUTÓNOMA CHAPINGO
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <a
            href="https://www.facebook.com/ChapingoOficial?ref=hl"
            className={classes.icon}
          >
            <FacebookIcon style={{ color: "#fffc" }} />
          </a>
         {/*  <a href="https://twitter.com/uach_chapingo" className={classes.icon}>
            <TwitterIcon style={{ color: "#fffc" }} />
          </a> */}
          {/* <a
            href="https://www.youtube.com/user/UAChapingovideos"
            className={classes.icon}
          >
            <YouTubeIcon style={{ color: "#fffc" }} />
          </a> */}
         {/*  <a href="https://www.chapingo.mx/correo/" className={classes.icon}>
            <EmailIcon style={{ color: "#fffc" }} />
          </a> */}
        </Grid>
      </Grid>
    </footer>
    // </div>
  );
}
