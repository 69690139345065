import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import { Global } from '../../../constants/global';
// import Domicilio from '../../../../../../^untitled:Untitled-1';

const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        marginLeft: "20px",
        marginRight: "20px",
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-12),
        backgroundColor: theme.palette.secondary,
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    back: {
        background: 'linear-gradient(0deg, #003366 5%, #FFFFFF 100%)',
    },
}));



function Domicilio({ persona }) {

    const [datosAnterior, setDatosAnterior] = useState({
        paisDom: '',
        cpDom: '',
        estadoDom: '',
        ciudadDom: '',
        coloniaDom: '',
        calleDom: '',
        informacionDom: '',
        telefonoDom: '',
        movil: '',
    })

    useEffect(async () => {

        //Conseguir datos direccion
        let colonia = ""
        await axios.get(Global + "personad/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setDatosAnterior({
                paisDom: response.data[0].direccion.pais.pais,
                informacionDom: response.data[0].direccion.informacion,
                coloniaDom: response.data[0].direccion.colonia,
                calleDom: response.data[0].direccion.calle,
                telefonoDom: response.data[0].persona.telefono,
                movil: response.data[0].persona.celular,
            })
            colonia = response.data[0].direccion.colonia

            axios.get(Global + "direccioncp/direccion/" + response.data[0].direccion.iddireccion, {
                headers: {
                    'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept, Methods',
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,HEAD,POST,PUT',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
               
                if (response.data.length > 0) {
                    setDatosAnterior({
                        cpDom: response.data[0].codigoPostal.cp,
                        estadoDom: response.data[0].codigoPostal.estado,
                        ciudadDom: response.data[0].codigoPostal.ciudad,
                    })
                    if(colonia === "" || colonia === null){
                        setDatosAnterior({
                            coloniaDom: response.data[0].codigoPostal.colonia
                        })  
                    }
                }
            }).catch(error => console.log(error))

        }).catch(error => console.log(error));

        // console.log(datosAnterior);

    }, []);

    const classes = useStyles();
    const initial = datosAnterior;

    return (
        <div>

            <Formik
                enableReinitialize
                initialValues={initial}
            >
                {props => {
                    return (
                        <Paper elevation={3} className={classes.paperContainer} >
                            <br />
                            <Typography variant="h5" align="left">
                                Domicilio
                                <hr />
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid
                                    item xs={12} >
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="País"
                                        id="paisDom"
                                        name="paisDom"
                                        type="text"
                                        value={props.values.paisDom}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Código Postal"
                                        id="cpDom"
                                        name="cpDom"
                                        type="text"
                                        value={props.values.cpDom}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Estado"
                                        id="estadoDom"
                                        name="estadoDom"
                                        type="text"
                                        value={props.values.estadoDom}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Ciudad"
                                        id="ciudadDom"
                                        name="ciudadDom"
                                        type="text"
                                        value={props.values.ciudadDom}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Colonia"
                                        id="coloniaDom"
                                        name="coloniaDom"
                                        value={props.values.coloniaDom}
                                        type="text"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Calle y número"
                                        id="calleDom"
                                        name="calleDom"
                                        type="text"
                                        value={props.values.calleDom}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        label="Domicilio completo"
                                        id="informacionDom"
                                        name="informacionDom"
                                        type="text"
                                        value={props.values.informacionDom}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                                   <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        disabled
                                        label="Teléfono fijo"
                                        id="telefonoDom"
                                        name="telefonoDom"
                                        value={props.values.telefonoDom}                             
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        disabled
                                        id="movil"
                                        label="Móvil"
                                        name="movil"
                                        value={props.values.movil}
                                       

                                    />
                                </Grid>
                            </Grid>


                            <br />
                        </Paper>

                    )
                }}
            </Formik>
        </div >
    );
}

export default Domicilio;