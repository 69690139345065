import React from 'react';
import RegistroCartas from './RegistroCartas';

function CartasRecomendacion() {

    return (
        <div>
            <RegistroCartas />
        </div>
    );
}

export default CartasRecomendacion;