import React, { useEffect, useState } from 'react'
import { Avatar, Grid, Paper, TextField, Typography } from '@material-ui/core'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import axios from 'axios';
import { Global } from '../../../constants/global';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginLeft: "20px",
        marginRight: "20px",
    },
    avatar: {
        background: theme.palette.secondary.main,
    }
}));

const Ocupacion = ({ persona }) => {
    const classes = useStyles();

    const [ocupacionAnterior, setOcupacionAnterior] = useState({
        ocupacion: '',
        empresa: '',
        pais: '',
        cp: '',
        estado: '',
        ciudad: '',
        colonia: '',
        calle: '',
        direccion: '',
        fechaIn: '',
        telefono: '',
    })
    const [paisselect, setPaisselect] = useState('MÉXICO')


    useEffect(() => {

        axios.get(Global + "ocupacion/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(responses => {
            if (responses.data[0]) {
                // console.log(responses.data)
                setOcupacionAnterior(responses.data[0])
            }
        }).catch(error => console.log(error))

    }, [])

    const initial = ocupacionAnterior
    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid
                    container
                    spacing={1}
                    alignItems="center"

                >
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <BusinessCenterIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">
                            Ocupación actual
                        </Typography>
                    </Grid>

                </Grid>

                <br />
                <Formik
                    enableReinitialize
                    initialValues={initial}
                >
                    {props => {
                        return (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Ocupación"
                                            id="ocupacion"
                                            name="ocupacion"
                                            disabled
                                            fullWidth
                                            value={props.values.ocupacion}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Institución o Empresa"
                                            id="empresa"
                                            name="empresa"
                                            disabled
                                            fullWidth
                                            value={props.values.empresa}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="pais"
                                            name="pais"
                                            label="País"
                                            value={props.values.pais}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    (paisselect === 'MÉXICO') ?
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Codigo Postal"
                                                        id="cp"
                                                        name="cp"
                                                        disabled
                                                        fullWidth
                                                        value={props.values.cp}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="text"
                                                        disabled
                                                        fullWidth
                                                        id="estado"
                                                        name="estado"
                                                        label="Estado"
                                                        value={props.values.estado}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="text"
                                                        disabled
                                                        fullWidth
                                                        id="ciudad"
                                                        name="ciudad"
                                                        label="Ciudad"
                                                        value={props.values.ciudad}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Colonia"
                                                        id="colonia"
                                                        name="colonia"
                                                        disabled
                                                        fullWidth
                                                        value={props.values.colonia}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Calle y numero "
                                                        id="calle"
                                                        name="calle"
                                                        disabled
                                                        fullWidth
                                                        value={props.values.calle}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </> : null
                                }

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            label="Direccion completa"
                                            id="direccion"
                                            name="direccion"
                                            variant="outlined"
                                            disabled
                                            fullWidth
                                            value={props.values.direccion}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Fecha de inicio"
                                            id="fechaIn"
                                            name="fechaIn"
                                            variant="outlined"
                                            disabled
                                            fullWidth
                                            value={props.values.fechaIn}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            label="Teléfono"
                                            id="telefono"
                                            name="telefono"
                                            disabled
                                            fullWidth
                                            value={props.values.telefono}
                                            type='number'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }}
                </Formik>
            </Paper >
        </div>
    );

}

export default Ocupacion;