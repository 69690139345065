import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Backdrop,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import Head from "../../generales/Head";
import axios from "axios";
import { Global, FILE_SIZE, SUPPORTED_PDF, Local } from "../../../constants/global";
import Swal from "sweetalert";
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import Pie from "../../generales/Footer";
import { Auth } from "../../../context/AuthContext";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import HomeIcon from "@material-ui/icons/Home";
import CreateIcon from "@material-ui/icons/Create";
import ComputerIcon from "@material-ui/icons/Computer";
import { menor, mayor, FORMATOFECHAMAXHOY, FORMATOFECHAMINHOY } from '../../generales/Fecha';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  layout: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  subtitulo: {
    fontSize: "25px",
    color: "#54595F",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
  boton2: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#020e69",
    borderRadius: "4px 4px 4px 4px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

function Registro({ history }) {
  const classes = useStyles();
  const { usuario } = useContext(Auth);

  const [fileActa, setFileActa] = useState(0);
  const [fileDoc, setFileDoc] = useState(0);
  const [filePlan,setFilePlan] = useState(0);

  const [, setMensajeActa] = useState("");
  const [, setMensajeDoc] = useState("");
  const [, setMensajePlan] = useState("");
  const [carga, setCarga] = useState(false);

  useEffect(() => {
    if (usuario === null) {
      history.push("/siip");
    }
  });

  const schemaValidation = Yup.object({
    abrev: Yup.string()
      .required("El campo es obligatorio")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, "Solo letras."),
    clave: Yup.string()
      .required("El campo es obligatorio")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, "Solo letras."),
    programa: Yup.string()
      .required("El campo es obligatorio")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/, "Solo letras."),
    tipo: Yup.string().required("El campo es obligatorio"),
    claveco: Yup.number().positive("El valor debe ser mayor a 0"),
    clavese: Yup.number().positive("El valor debe ser mayor a 0"),
    comentarios: "",
    acta: Yup.mixed()
      .test(
        "fileType",
        "El formato del archivo es incompatible",
        () => fileActa === 0 || SUPPORTED_PDF.includes(fileActa.type)
      )
      .test(
        "fileSize",
        "El tamaño del archivo es mayor a 2Mb",
        () => fileActa === 0 || fileActa.size <= FILE_SIZE
      ),
    docs: Yup.mixed()
      .test(
        "fileType",
        "El formato del archivo es incompatible",
        () => fileDoc === 0 || SUPPORTED_PDF.includes(fileDoc.type)
      )
      .test(
        "fileSize",
        "El tamaño del archivo es mayor a 2Mb",
        () => fileDoc === 0 || fileDoc.size <= FILE_SIZE
      ),
      planestudio: Yup.mixed()

      .test(
        "fileType",
        "El formato del archivo es incompatible",
        () => filePlan === 0 || SUPPORTED_PDF.includes(filePlan.type)
      )
      .test(
        "fileSize",
        "El tamaño del archivo es mayor a 2Mb",
        () => filePlan === 0 || filePlan.size <= FILE_SIZE
      ),
    estatus: "",
    fechacr: Yup.string()
      .test('match',
        'La fecha debe ser menor a la fecha actual',
        function (fecha) {
          return menor(fecha)
        }
      ),
    fechain: Yup.string().test(
      "match",
      "La fecha de inicio debe ser mayor a la fecha de creación",
      function (fechain) {
        if (fechain !== undefined) return fechain >= this.parent.fechacr;
      }
    ),
    pncp: "",
    pweb: "",
    orientacion: Yup.string(),
    vigencia: Yup.string()
      .test('match',
        'La fecha debe ser mayor a la fecha actual',
        function (fecha) {
          return mayor(fecha)
        }
      ),
    version: ""


  });

  const functionSubmit = async (valores) => {
    setCarga(true);
    let existe = false;
    await axios
      .get(Global + "programa/clave/" + valores.clave, {
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      })
      .then(async (response) => {
        if (response.data.length > 0) {
          Swal({
            title: "Esta clave ya ha sido registrada",
            text: "",
            icon: "error",
            button: false,
            timer: 1000,
          });
          setCarga(false);
        } else {
          await axios
            .get(Global + "programa/nombre/" + valores.programa, {
              headers: {
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.length > 0) {
                Swal({
                  title: "Este nombre ya está registrado",
                  text: "",
                  icon: "error",
                  button: false,
                  timer: 1000,
                });
                setCarga(false);
              } else {
                existe = true;
              }
            })
            .catch((error) => {
              setCarga(false);
            });
        }
      })
      .catch(() => {
        setCarga(false);
      });

    if (valores.fechacr === "") {
      valores.fechacr = "2000-01-01";
      valores.fechain = "2000-01-01";
    }

    //Defecto
    const programa = {
      abrev: valores.abrev,
      acta: "",
      clave: valores.clave,
      claveco: valores.claveco,
      clavese: valores.clavese,
      periodo: valores.periodo,
      comentarios: valores.comentarios,
      docs: "",
      estatus: valores.estatus,
      fechacr: valores.fechacr,
      fechain: valores.fechain,
      programa: valores.programa,
      planestudio:"",
      pncp: valores.pncp,
      pweb: valores.pweb,
      tipo: valores.tipo,
      orientacion: valores.orientacion,
      vigencia: valores.vigencia,
      version: valores.version,
    };

    if (existe) {
      if (fileActa) {
        programa.acta =
          "Programa/" + valores.programa + "/" + uuidv4() + fileActa.name;
        const storageRef = fba.storage().ref(programa.acta);
        const task = storageRef.put(fileActa);
        task.on(
          "state_changed",
          (snapshot) => {
            let porcentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setMensajeActa(porcentage + "%");
          },
          (error) => {
            console.log(error);
            setMensajeActa("Error en la carga del archivo " + error.message);
          }
        );
      }

      if (fileDoc) {
        programa.docs =
          "Programa/" + valores.programa + "/" + uuidv4() + fileDoc.name;
        const storageRef2 = fba.storage().ref(programa.docs);
        const task2 = storageRef2.put(fileDoc);
        task2.on(
          "state_changed",
          (snapshot) => {
            let porcentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setMensajeDoc(porcentage + "%");
          },
          (error) => {
            console.log(error);
            setMensajeActa("Error en la carga del archivo " + error.message);
          }
        );
      }

      if (filePlan) {
        programa.planestudio =
          "Programa/" + valores.programa + "/" + uuidv4() + filePlan.name;
        const storageRef3 = fba.storage().ref(programa.planestudio);
        const task3 = storageRef3.put(filePlan);
        task3.on(
          "state_changed",
          (snapshot) => {
            let porcentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setMensajePlan(porcentage + "%");
          },
          (error) => {
            console.log(error);
            setMensajePlan("Error en la carga del archivo " + error.message);
          }
        );
      }

      await axios
        .post(Global + "programa", programa, {
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.status === 200) {
            Swal({
              title: "Datos registrados",
              text: "Datos registrados",
              icon: "success",
              button: false,
              timer: 1000,
            });
            setCarga(false);
            history.push("/siip/actualizarPrograma");
          }
        })
        .catch(() => {
          setCarga(false);
        });
    }
  };

  const handleOnChange = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];

      switch (event.target.id) {
        case "acta":
          setFileActa(file);

          break;
        case "docs":
          setFileDoc(file);
          break;

        case "planestudio":
          setFilePlan(file);
          break;

        default:
          break;
      }
    } else {
      switch (event.target.id) {
        case "acta":
          setFileActa(false);
          break;
        case "docs":
          setFileDoc(false);
          break;
        case "planestudio":
          setFilePlan(false);
          break;
        default:
          break;
      }
    }
  };

  const initial = {
    abrev: "",
    acta: "",
    clave: "",
    claveco: "",
    clavese: "",
    periodo: "",
    comentarios: "",
    docs: "",
    estatus: "",
    fechacr: "",
    fechain: "",
    programa: "",
    planestudio: "",
    pncp: "",
    pweb: "",
    tipo: "",
    vigencia: "",
    version: "",
  };

  return (
    <div>
      <Head />
      <Backdrop className={classes.backdrop} open={carga}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={3} className={classes.paper}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link href="/inicio" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Inicio
          </Link>
          <Link aria-current="page" className={classes.link}>
            <ComputerIcon className={classes.icon} />
            Programas
          </Link>
          <Link
            color="textPrimary"
            href="/siip/programa"
            aria-current="page"
            className={classes.link}
          >
            <CreateIcon className={classes.icon} />
            Registrar programa
          </Link>
        </Breadcrumbs>
        <br />
        <Typography className={classes.subtitulo}>Nuevo programa</Typography>
        <br />
        <Formik
          validationSchema={schemaValidation}
          enableReinitialize
          initialValues={initial}
          onSubmit={(valores) => {
            functionSubmit(valores);
          }}
        >
          {(props) => {
            return (
              <form noValidate onSubmit={props.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Clave"
                      id="clave"
                      name="clave"
                      value={props.values.clave}
                      onChange={(e) => {
                        let clave = e.target.value;
                        props.setFieldValue("clave", clave.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.clave && props.errors.clave ? true : false
                      }
                      helperText={
                        props.touched.clave && props.errors.clave
                          ? props.errors.clave
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Nombre"
                      id="programa"
                      name="programa"
                      value={props.values.programa}
                      onChange={(e) => {
                        let nom = e.target.value;
                        props.setFieldValue("programa", nom.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.programa && props.errors.programa
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.programa && props.errors.programa
                          ? props.errors.programa
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Orientación"
                      id="orientacion"
                      name="orientacion"
                      value={props.values.orientacion}
                      onChange={(e) => {
                        let orien = e.target.value;
                        props.setFieldValue("orientacion", orien.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.orientacion && props.errors.orientacion
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.orientacion && props.errors.orientacion
                          ? props.errors.orientacion
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Abreviatura"
                      id="abrev"
                      name="abrev"
                      value={props.values.abrev}
                      onChange={(e) => {
                        let abrev = e.target.value;
                        props.setFieldValue("abrev", abrev.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.abrev && props.errors.abrev ? true : false
                      }
                      helperText={
                        props.touched.abrev && props.errors.abrev
                          ? props.errors.abrev
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={
                        props.touched.tipo && props.errors.tipo ? true : false
                      }
                    >
                      <InputLabel>Nivel</InputLabel>
                      <Select
                        native
                        value={props.values.tipo}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        label="Nivel"
                        inputProps={{
                          name: "tipo",
                          id: "tipo",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={"Doctorado"}>Doctorado</option>
                        <option value={"Maestria"}>Maestria</option>
                      </Select>
                      <FormHelperText>
                        {props.touched.tipo && props.errors.tipo
                          ? props.errors.tipo
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="fechacr"
                      name="fechacr"
                      label="Fecha de creación"
                      type="date"
                      value={props.values.fechacr}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        props.touched.fechacr && props.errors.fechacr
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.fechacr && props.errors.fechacr
                          ? props.errors.fechacr
                          : null
                      }
                      InputProps={FORMATOFECHAMAXHOY}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="fechain"
                      name="fechain"
                      label="Fecha de inicio"
                      type="date"
                      value={props.values.fechain}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.fechain && props.errors.fechain
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.fechain && props.errors.fechain
                          ? props.errors.fechain
                          : null
                      }
                      InputProps={FORMATOFECHAMINHOY}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        props.touched.estatus && props.errors.estatus
                          ? true
                          : false
                      }
                    >
                      <InputLabel>Estatus</InputLabel>
                      <Select
                        native
                        value={props.values.estatus}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        label="Estatus"
                        inputProps={{
                          name: "estatus",
                          id: "estatus",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={'CONSOLIDADO'}>CONSOLIDADO</option>
                        <option value={'DESARROLLO'}>DESARROLLO</option>
                        <option value={'NUEVA CREACIÓN'}>NUEVA CREACIÓN</option>
                        <option value={'INTERNACIONAL'}>INTERNACIONAL</option>
                      </Select>
                      <FormHelperText>
                        {props.touched.estatus && props.errors.estatus
                          ? props.errors.estatus
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Año de ingreso al PNPC"
                      id="pncp"
                      name="pncp"
                      type="number"
                      value={props.values.pncp}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.pncp && props.errors.pncp ? true : false
                      }
                      helperText={
                        props.touched.pncp && props.errors.pncp
                          ? props.errors.pncp
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="vigencia"
                      name="vigencia"
                      label="Vigencia de PNPC"
                      type="date"
                      value={props.values.vigencia}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={props.touched.vigencia && props.errors.vigencia ? (
                        true
                      ) : false}
                      helperText={props.touched.vigencia && props.errors.vigencia ? (
                        props.errors.vigencia
                      ) : null}
                      InputProps={FORMATOFECHAMINHOY}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Versión del PNPC"
                      id="version"
                      name="version"
                      value={props.values.version}
                      // onChange={props.handleChange}
                      onChange={(e) => {
                        let version = e.target.value;
                        props.setFieldValue("version", version.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.version && props.errors.version ? true : false
                      }
                      helperText={
                        props.touched.version && props.errors.version
                          ? props.errors.version
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Página web"
                      id="pweb"
                      name="pweb"
                      value={props.values.pweb}
                      // onChange={props.handleChange}
                      onChange={(e) => {
                        let pweb = e.target.value;
                        props.setFieldValue("pweb", pweb.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.pweb && props.errors.pweb ? true : false
                      }
                      helperText={
                        props.touched.pweb && props.errors.pweb
                          ? props.errors.pweb
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Comentarios"
                      id="comentarios"
                      name="comentarios"
                      value={props.values.comentarios}
                      onChange={(e) => {
                        let comen = e.target.value;
                        props.setFieldValue("comentarios", comen.toUpperCase())
                      }}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.comentarios && props.errors.comentarios
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.comentarios && props.errors.comentarios
                          ? props.errors.comentarios
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Clave en el Conacyt"
                      id="claveco"
                      name="claveco"
                      type="number"
                      value={props.values.claveco}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.claveco && props.errors.claveco
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.claveco && props.errors.claveco
                          ? props.errors.claveco
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Clave de la S.E.P."
                      id="clavese"
                      name="clavese"
                      type="number"
                      value={props.values.clavese}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.clavese && props.errors.clavese
                          ? true
                          : false
                      }
                      helperText={
                        props.touched.clavese && props.errors.clavese
                          ? props.errors.clavese
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        props.touched.periodo && props.errors.periodo
                          ? true
                          : false
                      }
                    >
                      <InputLabel>Periodo</InputLabel>
                      <Select
                        native
                        value={props.values.periodo}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        label="Periodo"
                        inputProps={{
                          name: "periodo",
                          id: "periodo",
                        }}
                      >
                        <option aria-label="None" value="" />
                        <option value={'PRIMAVERA'}>PRIMAVERA</option>
                        <option value={'OTOÑO'}>OTOÑO</option>
                        <option value={'MIXTO'}>MIXTO</option>
                      </Select>
                      <FormHelperText>
                        {props.touched.periodo && props.errors.periodo
                          ? props.errors.periodo
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <Grid item xs={10} sm={8}> */}

                    <label htmlFor="acta">
                      <input
                        style={{ display: "none" }}
                        id="acta"
                        name="acta"
                        type="file"
                        accept="application/pdf"
                        onChange={handleOnChange}
                      />
                      <Button
                        className={classes.boton}
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        <FolderOpenIcon style={{ marginRight: 10 }} />
                        Acta por el H. Consejo
                        {fileActa ?
                          <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                          : null}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        color="error"
                      >
                        {props.touched.acta &&
                          props.errors.acta &&
                          props.errors.acta}
                      </Typography>
                    </label>
                    <br />
                  </Grid>

                  <Grid item xs={12}>
                    <label htmlFor="docs">
                      <input
                        style={{ display: "none" }}
                        id="docs"
                        name="docs"
                        type="file"
                        accept="application/pdf"
                        onChange={handleOnChange}
                      />
                      <Button
                        className={classes.boton}
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        <FolderOpenIcon style={{ marginRight: 10 }} />
                        Docto. de Clave S.E.P.
                        {fileDoc ?
                          <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                          : null}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        color="error"
                      >
                        {props.touched.docs &&
                          props.errors.docs &&
                          props.errors.docs}
                      </Typography>
                    </label>
                  </Grid>
                {/*  <Grid item xs={12}>
                    <label htmlFor="planestudio">
                      <input
                        style={{ display: "none" }}
                        id="planestudio"
                        name="planestudio"
                        type="file"
                        accept="application/pdf"
                        onChange={handleOnChange}
                      />
                      <Button
                        className={classes.boton}
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        <FolderOpenIcon style={{ marginRight: 10 }} />
                        Plan de Estudio
                        {filePlan ?
                          <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                          : null}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        color="error"
                      >
                        {props.touched.planestudio &&
                          props.errors.planestudio &&
                          props.errors.planestudio}
                      </Typography>
                    </label>
                  </Grid> */}

                  <Grid item xs={12}></Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.boton}
                      >
                        Guardar
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Paper>

      <Pie />
    </div>
  );
}

export default Registro;
