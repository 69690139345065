import React, { useEffect, useState } from 'react'
import { Backdrop, CircularProgress, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Global } from '../../../constants/global';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AgregarMateria = (props) => {

    const classes = useStyles();
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [carga, setCarga] = useState(false)

    useEffect(() => {
        setRegistroAnterior({
            clave: '',
            nombre: '',
            descripcion: '',
            credito: '',
            teoria: '',
            practica: '',
        })

    }, [])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        clave: Yup.string().required('Es obligatorio ingresar la clave de materia'),
        nombre: Yup.string().required('Es obligatorio ingresar el nombre'),
        credito: Yup.number().required('Es obligatorio ingresar el numero de creditos').positive('El valor debe ser mayor a 0'),
        teoria: Yup.number().required('Es obligatorio ingresar el numero de horas de teoria').positive('El valor debe ser mayor a 0'),
        practica: Yup.number().required('Es obligatorio ingresar el numero de horas de practica').positive('El valor debe ser mayor a 0'),
    })

    const initial = registroAnterior

    const funcionSubmit = async valores => {
        setCarga(true)
        let programa = {
            clave: valores.clave,
            nombre: valores.nombre,
            descripcion: valores.descripcion,
            credito: valores.credito,
            teoria: valores.teoria,
            practica: valores.practica,
        };

        await axios.post(Global + "materia", programa, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            setCarga(false)
            props.metodo(true)
        }).catch(error => {
            setCarga(false)
            console.log(error);
        })
    }
    return (
        <>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                validationSchema={schemaValidacion}
                enableReinitialize
                initialValues={initial}
                onSubmit={(valores) => {
                    funcionSubmit(valores)
                }}
            >
                {props => {
                    //console.log(props);
                    return (
                        <form noValidate onSubmit={props.handleSubmit}>
                            <Grid container spacing={2} justify='center'>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        label="Clave"
                                        name="clave"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //defaultValue={escolaridadAnterior[0].promedio}
                                        value={props.values.clave}
                                        onChange={(e)=>{
                                            let clave = e.target.value;
                                            props.setFieldValue("clave",clave.toUpperCase())
                                        }}
                                        onBlur={props.handleBlur}
                                        error={props.touched.clave && props.errors.clave ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.clave && props.errors.clave ? (
                                            props.errors.clave
                                        ) : null}
                                    // max="100"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        label="Nombre"
                                        name="nombre"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //defaultValue={escolaridadAnterior[0].promedio}
                                        value={props.values.nombre}
                                        onChange={(e)=>{
                                            let name = e.target.value;
                                            props.setFieldValue("nombre",name.toUpperCase())
                                        }}
                                        onBlur={props.handleBlur}
                                        error={props.touched.nombre && props.errors.nombre ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.nombre && props.errors.nombre ? (
                                            props.errors.nombre
                                        ) : null}
                                    // max="100"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        label="Descripción"
                                        name="descripcion"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //defaultValue={escolaridadAnterior[0].promedio}
                                        value={props.values.descripcion}
                                        onChange={(e)=>{
                                            let des = e.target.value;
                                            props.setFieldValue("descripcion",des.toUpperCase())
                                        }}
                                        onBlur={props.handleBlur}
                                        error={props.touched.descripcion && props.errors.descripcion ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.descripcion && props.errors.descripcion ? (
                                            props.errors.descripcion
                                        ) : null}
                                    // max="100"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        label="Número de créditos"
                                        name="credito"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //defaultValue={escolaridadAnterior[0].promedio}
                                        // value={props.values.fechain}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.touched.credito && props.errors.credito ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.credito && props.errors.credito ? (
                                            props.errors.credito
                                        ) : null}
                                        type="number"
                                    // max="100"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        label="Horas de teoría"
                                        name="teoria"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //defaultValue={escolaridadAnterior[0].promedio}
                                        // value={props.values.fechain}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.touched.teoria && props.errors.teoria ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.teoria && props.errors.teoria ? (
                                            props.errors.teoria
                                        ) : null}
                                        type="number"
                                    // max="100"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        label="Horas de práctica"
                                        name="practica"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //defaultValue={escolaridadAnterior[0].promedio}
                                        // value={props.values.fechain}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.touched.practica && props.errors.practica ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.practica && props.errors.practica ? (
                                            props.errors.practica
                                        ) : null}
                                        type="number"
                                    // max="100"
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12} sm={5} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        className={classes.boton}>
                                        Guardar
                                    </Button>
                                </Grid>
                            </Grid>

                        </form>
                    )
                }}
            </Formik >
            <Grid container spacing={1} justify='center'>
                <Grid item xs={12} sm={5} >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            props.metodo(false);
                        }}>
                        Cancelar
                        </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default AgregarMateria;