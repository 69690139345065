import React, { useEffect, useState } from 'react';
import RegistroDatos from './RegistroDatosGenerales';
import ActualizaDatos from './ActualizarDatosPersonales';
import axios from 'axios';
import { fba } from "../../../firebaseConfig";
import { Global } from '../../../constants/global';

function DatosPersonales() {
    const [info, setInfo] = useState(false);
    const [change, setChange] = useState(false);

    useEffect(() => {

        let user = fba.auth().currentUser;

        const verificacion = async () => {
            //Ver si tiene info de usuario
            await axios.get(Global + "persona/correo/" + user.email, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    // Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                let usuario = response.data;
                axios.get(Global + "personad/personas/" + usuario[0].idP, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                })
                    .then(response => {
                        if (response.data.length > 0) {
                            setInfo(true);
                        } else {
                            setInfo(false);
                        }
                    })
                    .catch(error => console.log(error))
            }).catch(error => console.log(error));
        }

        verificacion()

    }, [change])

    const handleChangeGenerales = (valor) => {
        setChange(valor);
    }

    return (
        <div>
            {info ?
                <ActualizaDatos />
                :
                <RegistroDatos changeGenerales={handleChangeGenerales} />
            }
        </div >
    );
}

export default DatosPersonales;
