import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Link,
  AppBar,
  Breadcrumbs,
  Paper,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import axios from "axios";

import Head from "../../generales/Head";
import Footer from "../../generales/Footer";
import { Auth } from "../../../context/AuthContext";
import { Global } from "../../../constants/global";
import { fba } from "../../../firebaseConfig";
import DomicilioCuenta from "./DomicilioCuenta";
import CambiaContraseña from "./Contraseña";
import DatosContacto from "./DatosContacto";
import InformacionPersonal from "./InformacionPersonal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  appbar: {
    color: "#54595F",
    backgroundColor: theme.palette.background.paper,
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  paperContainer: {
    padding: theme.spacing(8),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),

    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(17),
    },
    [theme.breakpoints.only("sm")]: {
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.only("lg")]: {
      marginBottom: theme.spacing(6),
    },
  },
  foto: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  input: {
    display: "none",
  },
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
}));

export default function Cuenta({ history }) {
  const classes = useStyles();
  const { usuario } = useContext(Auth);
  const [value, setValues] = useState(0);
  //const [rutaFoto,setRutaFoto] = useState(null)
  const [datos, setDatos] = useState({
    idP: 0,
    nombre: "",
    apellidoP: "",
    apellidoM: "",
    estado_civil: "",
    telefono: "",
    celular: "",
    correo: "",
    acta_nac: "",
    curp: "",
    cv: "",
    cvu: "",
    doc_curp: "",
    email: "",
    fecha_na: "",
    fotografia: "",
    genero: "",
    orcid: "",
    rfc: "",
  });

  const [cargando, setCargando] = useState(true);

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  useEffect(() => {
    if (usuario === null) {
      history.push("/siip");
    }
  });

  /*********************************************** */
  useEffect(async () => {
    const cargaDatosUsuario = async () => {
      var currUser = fba.auth().currentUser.email;
      //este servicio no necesita un token de autorizacion
      await axios
        .get(Global + "persona/correo/" + currUser, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const respuestaDatos = response.data[0];

          setDatos({
            idP: respuestaDatos.idP,
            nombre: respuestaDatos.nombre,
            apellidoP: respuestaDatos.apellidoP,
            apellidoM: respuestaDatos.apellidoM,
            estado_civil: respuestaDatos.estado_civil,
            celular: respuestaDatos.celular,
            telefono: respuestaDatos.telefono,
            correo: respuestaDatos.correo,
            acta_nac: respuestaDatos.acta_nac,
            curp: respuestaDatos.curp,
            cv: respuestaDatos.cv,
            cvu: respuestaDatos.cvu,
            doc_curp: respuestaDatos.doc_curp,
            email: respuestaDatos.email,
            fecha_na: respuestaDatos.fecha_na,
            fotografia: respuestaDatos.fotografia,
            genero: respuestaDatos.genero,
            orcid: respuestaDatos.orcid,
            rfc: respuestaDatos.rfc,
          });
        })
        .catch((error) => { });
    };
    await cargaDatosUsuario();
    setCargando(false);
  }, []);
  /*********************************************** */

  return (
    <>
      {usuario !== null ? (
        cargando ? (
          <>
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ) : (
          <div className={classes.root}>
            <Head />

            <Paper className={classes.paperContainer}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link href="/inicio" className={classes.link}>
                  <HomeIcon className={classes.icon} />
                  Inicio
                </Link>

                <Link
                  color="textPrimary"
                  href="/siip/cuenta"
                  aria-current="page"
                  className={classes.link}
                >
                  <AccountCircleIcon className={classes.icon} />
                  Mi cuenta
                </Link>
              </Breadcrumbs>
              <AppBar
                position="static"
                className={classes.appbar}
                elevation={0}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Pestañas"
                  centered
                >
                  <Tab label="Información Personal" {...a11yProps(0)} />
                  <Tab label="Domicilio" {...a11yProps(1)} />
                  <Tab label="Datos de contacto" {...a11yProps(2)} />
                  <Tab label="Contraseña" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              {/* ******************Informacion personal******************************* */}

              <TabPanel value={value} index={0}>
                <InformacionPersonal />
              </TabPanel>
              {/* ******************Domicilio******************************* */}
              <TabPanel value={value} index={1}>
                <DomicilioCuenta persona={datos} />
              </TabPanel>
              {/* ******************Contactos******************************* */}
              <TabPanel value={value} index={2}>
                <DatosContacto persona={datos} />
              </TabPanel>
              {/* ******************Contraseña******************************* */}
              <TabPanel value={value} index={3}>
                <CambiaContraseña />
              </TabPanel>
            </Paper>
            <Footer />
          </div>
        )
      ) : null}
    </>
  );
}
