import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@material-ui/core";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import firebase from "firebase";

import { fba } from "../../../firebaseConfig";
//alerta con sweetalert
import alerta from "./PlantillaAlerta";

const Contraseña = Yup.object().shape({
  oldPass: Yup.string()
    .trim("no espacios")
    .min(6, "La contraseña debe ser mínimo a 6 caracteres")
    .required("Introduce tu contraseña actual"),
  newPass: Yup.string()
    .min(6, "La contraseña debe ser mínimo a 6 caracteres")
    .notOneOf(
      [Yup.ref("oldPass"), null],
      "La nueva contraseña no puede ser igual a la actual"
    )
    .required("Introduce tu nueva contraseña"),
  confirmPass: Yup.string()
    .min(6, "La contraseña debe ser mínimo a 6 caracteres")
    .oneOf([Yup.ref("newPass"), null], "Las contraseñas deben coincidir")
    .required("Confirma la nueva contraseña"),
});

const useStyles = makeStyles((theme) => ({
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
}));

export default function CambiaContraseña() {
  const classes = useStyles();
  //para cambiar de pass con firebase es necesario comprobar que el usuario
  ///se ha loggeado recientemente y fb pide salir de la cuenta y volver a ingresar
  //con esta funcion reauthenticate evitamos eso y le proporcionamos el usuario y contraseña nosotros
  const reauthenticate = (oldPass) => {
    var user = fba.auth().currentUser;
    //auth.EmailAuthProvider.credential no se encuentra en fba, se debe usar import firebase from 'firebase'

    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      oldPass
    );
    return user.reauthenticateWithCredential(cred);
  };

  //una vez que reauthenticate regresa las credenciales, se obtienen en onChangePassword y cambia la pass
  const onChangePassword = (newPass, oldPass) => {
    reauthenticate(oldPass)
      .then(() => {
        var user = fba.auth().currentUser;
        user
          .updatePassword(newPass)
          .then(function () {
            alerta("Contraseña cambiada con éxito", "success");
          })
          .catch(function (error) {
            alerta("Ha ocurrido un error. Inténtalo más tarde", "error");
          });
      })
      .catch(function (error) {
        alerta("Tu contraseña actual es incorrecta", "error");
      });
  };

  return (
    <Formik
      initialValues={{ oldPass: "", newPass: "", confirmPass: "" }}
      validationSchema={Contraseña}
      onSubmit={(values, actions) => {
        onChangePassword(values.newPass, values.oldPass);
        setTimeout(() => {
          actions.resetForm({
            values: {
              oldPass: "",
              newPass: "",
              confirmPass: "",
            },
          });
          actions.setSubmitting(false);
        }, 10);
      }}
    >
      {(props) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                type="password"
                variant="outlined"
                label="Actual contraseña"
                fullWidth
                id="oldPass"
                error={Boolean(props.errors.oldPass) && props.touched.oldPass}
                helperText={
                  props.errors.oldPass && props.touched.oldPass
                    ? props.errors.oldPass
                    : null
                }
                {...props.getFieldProps("oldPass")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                type="password"
                variant="outlined"
                label="Nueva contraseña"
                fullWidth
                id="newPass"
                error={Boolean(props.errors.newPass) && props.touched.newPass}
                helperText={
                  props.errors.newPass && props.touched.newPass
                    ? props.errors.newPass
                    : null
                }
                {...props.getFieldProps("newPass")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                type="password"
                variant="outlined"
                label="Repite nueva contraseña"
                fullWidth
                id="confirmPass"
                error={
                  Boolean(props.errors.confirmPass) && props.touched.confirmPass
                }
                helperText={
                  props.errors.confirmPass && props.touched.confirmPass
                    ? props.errors.confirmPass
                    : null
                }
                {...props.getFieldProps("confirmPass")}
              />
            </Grid>

            <Grid container justify="center">
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  className={classes.boton}
                >
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
