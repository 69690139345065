import React, { useEffect, useState } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import { FORMATOFECHAMAXHOY } from '../../generales/Fecha';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AgregarAdscripcion = (props) => {
    // console.log(props)
    const classes = useStyles();
    const [ubpps, setUbpps] = React.useState([])
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [recarga, setRecarga] = useState(false)
    const [carga, setCarga] = useState(false)

    //REVISAR
    useEffect( () => {

        const datosIniciales = async () => {
            setRegistroAnterior({
                idads: '',
                ubpp: '',
                clave: '',
                area: '',
                categoria: '',
                telefono: '',
                fechain: '',
                idprofesor: '',
            })
            await axios.get(Global + "ubpp", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setUbpps(response.data)
            }).catch(error => console.log(error))
        }

        
        datosIniciales()
        handleClose()
    }, [recarga])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        ubpp: Yup.string().required('Es obligatorio seleccionar la UBPP'),
        clave: Yup.string().required('Es obligatorio ingresar la clave de empleado'),
        area: Yup.string().required('Es obligatorio ingresar una area o seccion'),
        categoria: Yup.string().required('Es obligatorio seleccionar la categoria'),
        fechain: Yup.string().required('Es obligatorio ingresar la fecha'),

    })

    const initial = registroAnterior
    // console.log(registroAnterior)

    const funcionSubmit = async valores => {
        // console.log(valores)
        setCarga(true)
        let adscripcion = {
            area: valores.area,
            categoria: valores.categoria,
            clave: valores.clave,
            fechain: valores.fechain,
            telefono: valores.telefono,
            estatus: 'alta'
        };

        await axios.post(Global + "adscripcion/profesor/" + props.data.idprofesor + "/ubpp/" + valores.ubpp, adscripcion, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response);
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            setCarga(false)

        }).catch(error => {
            console.log(error);
            setCarga(false)

        })
        props.metodo()
    }
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>

                {open ? <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            //console.log(props);
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.ubpp && props.errors.ubpp ? (true) : false}>
                                                <InputLabel>UBPP</InputLabel>
                                                <Select
                                                    labelId="UBPP"
                                                    native
                                                    // value={props.values.ubpp}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="ubpp"
                                                    inputProps={{
                                                        name: 'ubpp',
                                                        id: 'ubpp',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        ubpps.map((ubp) => {
                                                            return <option key={ubp.idubpp} value={ubp.idubpp}>{ubp.nombre}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.ubpp && props.errors.ubpp ? (props.errors.ubpp) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Clave de empleado"
                                                name="clave"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.clave}
                                                onChange={(e)=>{
                                                    let clave = e.target.value;
                                                    props.setFieldValue("clave",clave.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.clave && props.errors.clave ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.clave && props.errors.clave ? (
                                                    props.errors.clave
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Área o seccion"
                                                name="area"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.area}
                                                onChange={(e)=>{
                                                    let area = e.target.value;
                                                    props.setFieldValue("area",area.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.area && props.errors.area ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.area && props.errors.area ? (
                                                    props.errors.area
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={props.touched.categoria && props.errors.categoria ? (true) : false}>
                                                <InputLabel id="inst">Categoria</InputLabel>
                                                <Select
                                                    labelId='inst'
                                                    native
                                                    value={props.values.categoria}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="categoria"
                                                    inputProps={{
                                                        name: 'categoria',
                                                        id: 'categoria',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value="C2 TC">C2 TC</option>
                                                    <option value="C2 MT">C2 MT</option>
                                                    <option value="C2 PP">C2 PP</option>
                                                    <option value="C2 PA">C2 PA</option>
                                                    <option value="C1 TC">C1 TC</option>
                                                    <option value="C1 MT">C1 MT</option>
                                                    <option value="C1 PP">C1 PP</option>
                                                    <option value="C1 PA">C1 PA</option>
                                                    <option value="B2 TC">B2 TC</option>
                                                    <option value="B2 MT">B2 MT</option>
                                                    <option value="B2 PP">B2 PP</option>
                                                    <option value="B2 PA">B2 PA</option>
                                                    <option value="B1 TC">B1 TC</option>
                                                    <option value="B1 MT">B1 MT</option>
                                                    <option value="B1 PP">B1 PP</option>
                                                    <option value="B1 PA">B1 PA</option>
                                                    <option value="A2 TC">A2 TC</option>
                                                    <option value="A2 MT">A2 MT</option>
                                                    <option value="A2 PP">A2 PP</option>
                                                    <option value="A2 PA">A2 PA</option>
                                                    <option value="A1 TC">A1 TC</option>
                                                    <option value="A1 MT">A1 MT</option>
                                                    <option value="A1 PP">A1 PP</option>
                                                    <option value="A1 PA">A1 PA</option>


                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.categoria && props.errors.categoria ? (props.errors.categoria) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Telefono o extencion"
                                                name="telefono"
                                                variant="outlined"
                                                fullWidth
                                                // value={props.values.telefono}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.telefono && props.errors.telefono ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.telefono && props.errors.telefono ? (
                                                    props.errors.telefono
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Fecha de ingreso"
                                                name="fechain"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                //defaultValue={escolaridadAnterior[0].promedio}
                                                // value={props.values.fechain}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechain && props.errors.fechain ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null}
                                                type="date"
                                                max="100"
                                                InputProps={FORMATOFECHAMAXHOY}
                                            />
                                            
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>

                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                props.metodo();
                            }}>
                            Cancelar
                                            </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>
            </Paper >
        </div >
    );
}

export default AgregarAdscripcion;