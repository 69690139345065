import React, { useEffect, useState } from 'react'
import { Backdrop, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Swal from 'sweetalert';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import { menor,FORMATOFECHAMAXHOY } from "../../generales/Fecha"
import { SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function ActualizarExamen(props) {
    // console.log(props.data)

    const classes = useStyles();
    const { idalumno } = props.data.alumno;
    const { idexamenpro } = props.data;
    const { correo } = props.data.alumno.persona;
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [adjunto, setAdjunto] = useState(0);
    const [adjuntoMensaje2, setAdjuntoMensaje2] = useState('')
    const [acceso, setAcceso] = useState(true)

    //Seleccionar año 
    const year = new Date().getFullYear();
    const years = Array.from(new Array(100), (val, index) => year + index);
    useEffect(() => {

        let examen = {
            diaexam: '',
            mesexam: '',
            anioexam: '',
            horaexam: '',
            minutosexam: '',
            veredictoexam:'',
            sedeexam: '',
            aulavirtual: '',
          
        }

        const datosIniciales = async () => {

            props.rol === "administrador" ||
                props.rol === "coordinador_general" ||
                props.rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)

             console.log('accede a id examen ',idexamenpro)
            await axios.get(Global + "examenpro/" + idexamenpro, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                //console.log(response.data)
                examen.diaexam = response.data.diaexam
                examen.mesexam = response.data.mesexam
                examen.anioexam = response.data.anioexam
                examen.horaexam = response.data.horaexam
                examen.minutosexam = response.data.minutosexam
                examen.veredictoexam = response.data.veredictoexam
                examen.sedeexam = response.data.sedeexam
                examen.aulavirtual = response.data.aulavirtual

            }).catch(error => console.log(error))

           /*  if (predoctoral.acta !== null && predoctoral.acta !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + predoctoral.acta)
                setAdjuntoMensaje2(await httpsReference.getDownloadURL());
            } */
            handleClose();
        }

        datosIniciales();
        setRegistroAnterior(examen)

    }, [])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        
    })

    const initial = registroAnterior

    const funcionSubmit = async valores => {

       /*  let linkDocumento = registroAnterior.acta
        if (adjunto) {
            if (registroAnterior.acta === '') {
                linkDocumento = correo + '/DocumentosPredoctoral/' + uuidv4() + adjunto.name
            }
            const storageRef = fba.storage().ref(linkDocumento)
            const task = storageRef.put(adjunto)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        } */

        let examen = {
            alumno: {
                idalumno: idalumno
            },
            diaexam: valores.diaexam,
            mesexam: valores.mesexam,
            anioexam: valores.anioexam,
            horaexam: valores.horaexam,
            minutosexam: valores.minutosexam,
            veredictoexam: valores.veredictoexam,
            sedeexam: valores.sedeexam,
            aulavirtual: valores.aulavirtual,

        };

        await axios.put(Global + "examenpro/" + idexamenpro, examen, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(() => {
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
        }).catch(error => { console.log(error); })


        props.metodo()
    }

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickDownload = (idexamenpro) => () => {
        console.log('idreunion',idexamenpro)
        axios({
            url: Global + "pdf/grado/"+idexamenpro, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Acta_Examen.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })

    }

    const handleClickDownload1 = (idexamenpro) => () => {
        console.log('idreunion',idexamenpro)
        axios({
            url: Global + "pdf/veredicto/"+idexamenpro, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Acta_Veredicto.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })

    }

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "documento":
                    setAdjunto(file);
                    break;
                default:
                    break;
            }

        } else {
            switch (event.target.id) {
                case "documento":
                    setAdjunto(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                     <Grid container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    disabled={acceso}
                                                    error={props.touched.diaexam && props.errors.diaexam ? (true) : false}>
                                                    <InputLabel>Dia de Examen</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.diaexam}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Dia de Examen"
                                                        inputProps={{
                                                            name: 'diaexam',
                                                            id: 'diaexam'
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={'01'}>01</option>  
                                                        <option value={'02'}>02</option>
                                                        <option value={'03'}>03</option>  
                                                        <option value={'04'}>04</option>
                                                        <option value={'05'}>05</option>  
                                                        <option value={'06'}>06</option>
                                                        <option value={'07'}>07</option>  
                                                        <option value={'08'}>08</option>
                                                        <option value={'09'}>09</option>  
                                                        <option value={'10'}>10</option>
                                                        <option value={'11'}>11</option>  
                                                        <option value={'12'}>12</option>
                                                        <option value={'13'}>13</option>  
                                                        <option value={'14'}>14</option>
                                                        <option value={'15'}>15</option>  
                                                        <option value={'16'}>16</option>  
                                                        <option value={'17'}>17</option>
                                                        <option value={'18'}>18</option>  
                                                        <option value={'19'}>19</option>
                                                        <option value={'20'}>20</option>  
                                                        <option value={'21'}>21</option>
                                                        <option value={'22'}>22</option>  
                                                        <option value={'23'}>23</option>
                                                        <option value={'24'}>24</option>  
                                                        <option value={'25'}>25</option>
                                                        <option value={'26'}>26</option>  
                                                        <option value={'27'}>27</option>
                                                        <option value={'28'}>28</option>  
                                                        <option value={'29'}>29</option>
                                                        <option value={'30'}>30</option>  
                                                        <option value={'31'}>31</option>                       
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.diaexam && props.errors.diaexam ? (props.errors.diaexam) : null} </FormHelperText>
                                                </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        //disabled={acceso}
                                                        error={props.touched.mesexam && props.errors.mesexam ? (true) : false}>
                                                        <InputLabel>Mes de Examen</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.mesexam}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            label="Mes de Examen"
                                                            inputProps={{
                                                                name: 'mesexam',
                                                                id: 'mesexam'
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"ENERO"}>ENERO</option>
                                                            <option value={"FEBRERO"}>FEBRERO</option>
                                                            <option value={"MARZO"}>MARZO</option>
                                                            <option value={"ABRIL"}>ABRIL</option>
                                                            <option value={"MAYO"}>MAYO</option>
                                                            <option value={"JUNIO"}>JUNIO</option>
                                                            <option value={"JULIO"}>JULIO</option>
                                                            <option value={"AGOSTO"}>AGOSTO</option>
                                                            <option value={"SEPTIEMBRE"}>SEPTIEMBRE</option>
                                                            <option value={"OCTUBRE"}>OCTUBRE</option>
                                                            <option value={"NOVIEMBRE"}>NOVIEMBRE</option>
                                                            <option value={"DICIEMBRE"}>DICIEMBRE</option>                     
                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.mesexam && props.errors.mesexam ? (props.errors.mesexam) : null} </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                
                                
                                                <Grid item xs={12} sm={4}>
                                                        <FormControl
                                                            disabled={acceso}
                                                            variant="outlined"
                                                            fullWidth
                                                            className={classes.formControl}
                                                            error={
                                                            props.touched.anioexam && props.errors.anioexam
                                                                ? true
                                                                : false
                                                            }
                                                        >
                                                            <InputLabel>Año de Examen</InputLabel>
                                                            <Select
                                                            native
                                                            value={props.values.anioexam}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            label="Año de Examen"
                                                            inputProps={{
                                                                name: "anioexam",
                                                                id: "anioexam",
                                                            }}
                                                            >
                                                            <option aria-label="None" value="" />
                                                            {years.map((year, index) => {
                                                                return (
                                                                <option key={`year${index}`} value={year}>
                                                                    {year}
                                                                </option>
                                                                );
                                                            })}
                                                            </Select>
                                                            <FormHelperText>
                                                            {props.touched.anioexam && props.errors.anioexam
                                                                ? props.errors.anioexam
                                                                : null}
                                                            </FormHelperText>
                                                        </FormControl>
                                                 </Grid>
                                                 <br />
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={props.touched.horaexam && props.errors.horaexam ? (true) : false}>
                                                        <InputLabel>Hora de Examen</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.horaexam}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            label="hora de Examen"
                                                            inputProps={{
                                                                name: 'horaexam',
                                                                id: 'horaexam'
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={'00'}>00</option>
                                                            <option value={'01'}>01</option>  
                                                            <option value={'02'}>02</option>
                                                            <option value={'03'}>03</option>  
                                                            <option value={'04'}>04</option>
                                                            <option value={'05'}>05</option>  
                                                            <option value={'06'}>06</option>
                                                            <option value={'07'}>07</option>  
                                                            <option value={'08'}>08</option>
                                                            <option value={'09'}>09</option>  
                                                            <option value={'10'}>10</option>
                                                            <option value={'11'}>11</option>  
                                                            <option value={'12'}>12</option>
                                                            <option value={'13'}>13</option>  
                                                            <option value={'14'}>14</option>
                                                            <option value={'15'}>15</option>  
                                                            <option value={'16'}>16</option>  
                                                            <option value={'17'}>17</option>
                                                            <option value={'18'}>18</option>  
                                                            <option value={'19'}>19</option>
                                                            <option value={'20'}>20</option>  
                                                            <option value={'21'}>21</option>
                                                            <option value={'22'}>22</option>  
                                                            <option value={'23'}>23</option> 
                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.horaexam && props.errors.horaexam ? (props.errors.horaexam) : null} </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={props.touched.minutosexam && props.errors.minutosexam ? (true) : false}>
                                                        <InputLabel>Minutos</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.minutosexam}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            label="minutos"
                                                            inputProps={{
                                                                name: 'minutosexam',
                                                                id: 'minutosexam'
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={'00'}>00</option>
                                                            <option value={'01'}>01</option>  
                                                            <option value={'02'}>02</option>
                                                            <option value={'03'}>03</option>  
                                                            <option value={'04'}>04</option>
                                                            <option value={'05'}>05</option>  
                                                            <option value={'06'}>06</option>
                                                            <option value={'07'}>07</option>  
                                                            <option value={'08'}>08</option>
                                                            <option value={'09'}>09</option>  
                                                            <option value={'10'}>10</option>
                                                            <option value={'11'}>11</option>  
                                                            <option value={'12'}>12</option>
                                                            <option value={'13'}>13</option>  
                                                            <option value={'14'}>14</option>
                                                            <option value={'15'}>15</option>  
                                                            <option value={'16'}>16</option>  
                                                            <option value={'17'}>17</option>
                                                            <option value={'18'}>18</option>  
                                                            <option value={'19'}>19</option>
                                                            <option value={'20'}>20</option>  
                                                            <option value={'21'}>21</option>
                                                            <option value={'22'}>22</option>  
                                                            <option value={'23'}>23</option>
                                                            <option value={'24'}>24</option>  
                                                            <option value={'25'}>25</option>
                                                            <option value={'26'}>26</option>
                                                            <option value={'27'}>27</option>
                                                            <option value={'28'}>28</option>
                                                            <option value={'29'}>29</option>
                                                            <option value={'30'}>30</option>
                                                            <option value={'31'}>31</option>
                                                            <option value={'32'}>32</option>  
                                                            <option value={'33'}>33</option>
                                                            <option value={'34'}>34</option>  
                                                            <option value={'35'}>35</option>
                                                            <option value={'36'}>36</option>
                                                            <option value={'37'}>37</option>
                                                            <option value={'38'}>38</option>
                                                            <option value={'39'}>39</option>
                                                            <option value={'40'}>40</option>
                                                            <option value={'41'}>41</option>
                                                            <option value={'42'}>42</option>  
                                                            <option value={'43'}>43</option>
                                                            <option value={'44'}>44</option>  
                                                            <option value={'45'}>45</option>
                                                            <option value={'46'}>46</option>
                                                            <option value={'47'}>47</option>
                                                            <option value={'48'}>48</option>
                                                            <option value={'49'}>49</option>
                                                            <option value={'50'}>50</option>
                                                            <option value={'51'}>51</option>
                                                            <option value={'52'}>52</option>  
                                                            <option value={'53'}>53</option>
                                                            <option value={'54'}>54</option>  
                                                            <option value={'55'}>55</option>
                                                            <option value={'56'}>56</option>
                                                            <option value={'57'}>57</option>
                                                            <option value={'58'}>58</option>
                                                            <option value={'59'}>59</option>
                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.minutosexam && props.errors.minutosexam ? (props.errors.minutosexam) : null} </FormHelperText>
                                                  </FormControl>
                                             </Grid>
                                             <br />
                                             <Grid item xs={12} sm={4}>
                                                     <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={props.touched.veredictoexam && props.errors.veredictoexam ? (true) : false}>
                                                            <InputLabel>Veredicto</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.veredictoexam}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="veredicto"
                                                                inputProps={{
                                                                    name: 'veredictoexam',
                                                                    id: 'veredictoexam'
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                <option value="Aprobado por unanimidad">Aprobado por unanimidad</option>
                                                                <option value="No aprobado">No aprobado</option>

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.veredictoexam && props.errors.veredictoexam ? (props.errors.veredictoexam) : null}</FormHelperText>
                                                    </FormControl>
                                             </Grid>
                                             <br />
                                                 <Grid item xs={12} sm={4}>
                                                     <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={props.touched.sedeexam && props.errors.sedeexam ? (true) : false}>
                                                            <InputLabel>SEDE</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.sedeexam}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="SEDE"
                                                                inputProps={{
                                                                    name: 'sedeexam',
                                                                    id: 'sedeexam'
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                <option value="Si">Si</option>
                                                                <option value="No">No</option>
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.sedeexam && props.errors.sedeexam ? (props.errors.sedeexam) : null}</FormHelperText>
                                                     </FormControl>
                                                 </Grid>
                                                 <br />
                                                 <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            label="Aula Virtual"
                                                            name="aulavirtual"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={props.values.aulavirtual}
                                                            onChange={(e) => {
                                                                let doc = e.target.value;
                                                                props.setFieldValue("aulavirtual", doc.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.aulavirtual && props.errors.aulavirtual ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.aulavirtual && props.errors.aulavirtual ? (
                                                                props.errors.aulavirtual
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                 </Grid>
                                                 <Grid item xs={12} sm={12}>
                                            <Grid item xs={12} sm={3}>
                                                    <Button
                                                        onClick={handleClickDownload(idexamenpro)}
                                                        variant="contained"   
                                                        color="primary"
                                                        aling="left"
                                                        className={classes.boton}>
                                                        Imprimir Acta de Grado
                                                    </Button>
                                                   
                                            </Grid>
                                            <br />
                                            <br />
                                            <Grid item xs={12} sm={3}>
                                                  
                                                    <Button
                                                        onClick={handleClickDownload1(idexamenpro)}
                                                        variant="contained"   
                                                        color="primary"
                                                        aling="left"
                                                        className={classes.boton}>
                                                        Imprimir Veredicto de Examen
                                                    </Button>
                                            </Grid>
                                            

                                        </Grid>
                                     </Grid>
                                       <br />
                                   {
                                        !acceso ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            :
                                            null
                                    }
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper>
        </div >
    );
}

export default ActualizarExamen;