import React, { useEffect, useState } from 'react';
import RegistroPosgrado from './RegistroPosgrado';
import ActualizarPosgrado from './ActualizarPosgrado'
import axios from 'axios';
import { fba } from "../../../firebaseConfig";
import { Global } from '../../../constants/global';

function Posgrado() {

    const [info, setInfo] = useState(false);
    const [change, setChange] = useState(false);

    useEffect(async () => {
        let user = fba.auth().currentUser;
        //Ver si tiene info de usuario
        await axios.get(Global + "persona/correo/" + user.email, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            let usuario = response.data;
            // console.log(usuario[0].idP);
            axios.get(Global + "estudiosrea/persona/" + usuario[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if(response.data.length > 0){
                    // alert("No es null")
                    setInfo(true);
                }else{
                    // alert("Null")
                    setInfo(false);
                }
            }).catch(error => console.log(error))

        }).catch(error => console.log(error));

    }, [change])

    const handleChangePosgrado = (valor) => {
        setChange(valor);
    }
    // console.log('%c LogIn index.js', 'color: orange; font-weight: bold;', props);
    return (
        <div>
            {/* <RegistroPosgrado changePosgrado={handleChangePosgrado}/> */}
            {info ?
                <ActualizarPosgrado />
                : <RegistroPosgrado changePosgrado={handleChangePosgrado}/>
            }
        </div>
    );
}

export default Posgrado;