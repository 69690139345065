import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, Tooltip, TextField, Typography, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { FORMATOFECHAMAXHOY, menor } from "../../generales/Fecha"
import { FILE_SIZE, SUPPORTED_IMG, SUPPORTED_PDF } from "../../../constants/global"
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    hidden: {
        display: "none",
    },
    importLabel: {
        color: "black",
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },

}));

const ActualizarDatosGenerales = () => {

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 300,
          fontSize: theme.typography.pxToRem(14),
          border: '2px solid #f48124',
        },
      }))(Tooltip);
    const [user, setUser] = useState('');
    const [paises, setPaises] = useState([]);
    const [etnia, setEtnia] = useState([]);
    const [estado, setEstado] = useState([]);
    const [ciudades, setCiudades] = useState('');

    /** Para los campos de archivos **/
    const [, setUploadActa] = useState(0);
    const [fileActa, setFileActa] = useState(0);
    const [, setActaMensaje] = useState(null)
    const [actaMensaje2, setActaMensaje2] = useState(null)

    const [, setUploadCurp] = useState(0);
    const [fileCurp, setFileCurp] = useState(0);
    const [curpMensaje, setCurpMensaje] = useState('')

    const [, setUploadFoto] = useState(0);
    const [fileFoto, setFileFoto] = useState(0);
    const [, setFotoMensaje] = useState('')
    const [fotoMensaje2, setFotoMensaje2] = useState('')
    /** Fin de campo archivos **/

    //Mostrar campos aspirantes para mexicanos
    const [nacimiento, setNacimiento] = useState(false);
    const [domicilio, setDomicilio] = useState(false);
    const [emergencia, setEmergencia] = useState(false);

    //Datos para lugar de nacimiento
    const [, setPaisNac] = useState('');

    //Datos para lugar de domicilio
    const [, setPaisDom] = useState('');

    //Datos para lugar de contacto de emergencia
    const [, setPaisEm] = useState('');


    //Datos del aspirante
    const [datosAnterior, setDatosAnterior] = useState([])
    const [c, setClaves] = useState([]);

    //Llenar estados y ciudades
    // const [estadoDomicilio, setEstadoDomicilio] = useState('');
    // const [estadoContacto, setEstadoContacto] = useState('');
    const [, setCiudadDomicilio] = useState('');
    const [, setCiudadContacto] = useState('');

    const [cpDomicilio, setCPDomicilio] = useState('');
    const [cpContacto, setCPContacto] = useState('');

    const [estadoDomicilio, setEstadoDomicilio] = useState('');
    const [estadoContacto, setEstadoContacto] = useState('');

    const [coloniaDomicilio, setColoniaDomicilio] = useState('');
    const [coloniaContacto, setColoniaContacto] = useState('');    
    const [info, setInfo] = useState('');

    useEffect(() => {
        let persona = {
            nacimiento: '',
            gen: '',
            civil: '',
            etniapersona: '',
            resp_etnia: '',
            etnico: '',
            paisNac: '',
            estadoNac: '',
            ciudadNac: '',
            rfc: '',
            curp: '',
            curpDoc: '',
            foto: '',
            acta: '',
            paisDom: '',
            cpDom: '',
            estadoDom: '',
            ciudadDom: '',
            coloniaDom: '',
            otroDom: '',
            calleDom: '',
            informacionDom: '',
            telefonoDom: '',
            movil: '',
            nombre: '',
            parentesco: '',
            telefono: '',
            correo: '',
            paisEm: '',
            cpEm: '',
            estadoEm: '',
            ciudadEm: '',
            coloniaEm: '',
            otroEm: '',
            calleEm: '',
            domicilio: '',
        }

        let claves = {
            idLN: '',
            iddireccion: '',
            iddireccionDos: '',
            idCEM: '',
            idPed: '',
        }
        
        const selectDatos = async () => {
            // PAIS
            await axios.get(Global + "pais", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaises(response.data);
            }).catch(error => console.log(error));

            // ETNIA
            await axios.get(Global + "etnia", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setEtnia(response.data);
            }).catch(error => console.log(error));

            //Estados
            await axios.get(Global + "codigo/estados", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setEstado(response.data);
                // setEstadoContacto(response.data);
                // setEstadoDomicilio(response.data);
            }).catch(error => console.log(error));
        }

        const datosAspirante = async () => {

            let userFB = fba.auth().currentUser;
            let user = '';
            //Conseguir fecha y etnia
            await axios.get(Global + "persona/correo/" + userFB.email, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                user = response.data;
                persona.nacimiento = response.data[0].fecha_na
                //Conseguir etnia de persona
                axios.get(Global + "personaed/personas/" + user[0].idP, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    if (response.data.length > 0) {
                        claves.idPed = response.data[0].idP
                        persona.etnico = response.data[0].etnia.idetnia
                    }
                }).catch(error => console.log(error));

            }).catch(error => console.log(error))

            setUser(user[0].idP);

            // Conseguir lugar de nacimiento
            await axios.get(Global + "lugarna/personas/" + user[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                persona.paisNac = response.data[0].pais.idpais + "-" + response.data[0].pais.pais
                persona.estadoNac = response.data[0].estado
                persona.ciudadNac = response.data[0].ciudad
                claves.idLN = response.data[0].idLN
            }).catch(error => console.log(error));

            //Conseguir datos direccion
            await axios.get(Global + "personad/personas/" + user[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                claves.iddireccion = response.data[0].direccion.iddireccion
                persona.paisDom = response.data[0].direccion.pais.idpais + "-" + response.data[0].direccion.pais.pais
                persona.informacionDom = response.data[0].direccion.informacion
                persona.calleDom = response.data[0].direccion.calle
                if (response.data[0].direccion.colonia !== "") {
                    persona.otroDom = response.data[0].direccion.colonia
                    persona.coloniaDom = "otra"
                } else {
                    axios.get(Global + "direccioncp/direccion/" + response.data[0].direccion.iddireccion, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response.data[0].codigoPostal)
                        persona.coloniaDom = response.data[0].codigoPostal.idcodigo
                    }).catch(error => console.log(error));

                }
            }).catch(error => console.log(error));

            await axios.get(Global + "direccioncp/direccion/" + claves.iddireccion, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    persona.cpDom = response.data[0].codigoPostal.cp
                    persona.estadoDom = response.data[0].codigoPostal.estado
                    persona.ciudadDom = response.data[0].codigoPostal.ciudad
                }

                axios.get(Global + "codigo/cps/" + response.data[0].codigoPostal.cp, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    setCPDomicilio(response.data[0]);
                    setEstadoDomicilio(response.data[0]);
                    setColoniaDomicilio(response.data);
                }).catch(error => console.log(error));

            }).catch(error => console.log(error))

            //Conseguir datos de contacto de emergencia
            await axios.get(Global + "contactoem/personas/" + user[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async response => {
                claves.idCEM = response.data[0].idCEM
                claves.iddireccionDos = response.data[0].d.iddireccion
                persona.nacimiento = response.data[0].persona.fecha_na
                persona.gen = response.data[0].persona.genero
                persona.civil = response.data[0].persona.estado_civil
                persona.etniapersona = response.data[0].etniapersona
                persona.resp_etnia = response.data[0].resp_etnia
                persona.rfc = response.data[0].persona.rfc
                persona.curp = response.data[0].persona.curp
                persona.curpDoc = response.data[0].persona.doc_curp
                persona.foto = response.data[0].persona.fotografia
                persona.acta = response.data[0].persona.acta_nac
                persona.nombre = response.data[0].nombre
                persona.parentesco = response.data[0].parentesco
                persona.telefono = response.data[0].telefono
                persona.telefonoDom = response.data[0].persona.telefono
                persona.movil = response.data[0].persona.celular
                persona.correo = response.data[0].correo
                persona.paisEm = response.data[0].d.pais.idpais + "-" + response.data[0].d.pais.pais
                persona.domicilio = response.data[0].d.informacion

                persona.calleEm = response.data[0].d.calle
                if (response.data[0].d.colonia !== "") {
                    persona.otroEm = response.data[0].d.colonia
                    persona.coloniaEm = "otra"
                } else {
                    axios.get(Global + "direccioncp/direccion/" + response.data[0].d.iddireccion, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        // console.log(response.data[0].codigoPostal)
                        persona.coloniaEm = response.data[0].codigoPostal.idcodigo
                    }).catch(error => console.log(error));

                }

                if (response.data[0].persona.doc_curp !== null && response.data[0].persona.doc_curp !== '') {
                    let storage = fba.storage().ref();
                    let httpsReference = storage.child('' + response.data[0].persona.doc_curp)
                    setCurpMensaje(await httpsReference.getDownloadURL());
                }
                if (response.data[0].persona.fotografia !== null && response.data[0].persona.fotografia !== '') {
                    let storage2 = fba.storage().ref();
                    let httpsReference2 = storage2.child('' + response.data[0].persona.fotografia)
                    setFotoMensaje2(await httpsReference2.getDownloadURL());
                }
                if (response.data[0].persona.acta_nac !== null && response.data[0].persona.acta_nac !== '') {
                    let storage3 = fba.storage().ref();
                    let httpsReference3 = storage3.child('' + response.data[0].persona.acta_nac)
                    setActaMensaje2(await httpsReference3.getDownloadURL());
                }


            }).catch(error => console.log(error));

            await axios.get(Global + "direccioncp/direccion/" + claves.iddireccionDos, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    persona.cpEm = response.data[0].codigoPostal.cp
                    persona.estadoEm = response.data[0].codigoPostal.estado
                    persona.ciudadEm = response.data[0].codigoPostal.ciudad
                }
                axios.get(Global + "codigo/cps/" + response.data[0].codigoPostal.cp, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    setCPContacto(response.data[0]);
                    setEstadoContacto(response.data[0]);
                    setColoniaContacto(response.data);
                }).catch(error => console.log(error))
            }).catch(error => console.log(error))


            let optionPais = persona.paisNac.split("-");
            let optionPais2 = persona.paisDom.split("-");
            let optionPais3 = persona.paisEm.split("-");
            if (optionPais[1] === "MÉXICO") {
                setNacimiento(true);
            }
            if (optionPais2[1] === "MÉXICO") {
                setDomicilio(true);
            }
            if (optionPais3[1] === "MÉXICO") {
                setEmergencia(true);
            }

            //Lugar de nacimiento
            if (persona.estadoNac !== null && persona.estadoNac !== "") {
                await axios.get(Global + "codigo/estado/" + persona.estadoNac, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    setCiudades(response.data);
                }).catch(error => console.log(error));
            }

            //Domicilio
            await axios.get(Global + "codigo/estado/" + persona.estadoDom, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setCiudadDomicilio(response.data);
            }).catch(error => console.log(error));

            //Contacto de emergencia
            await axios.get(Global + "codigo/estado/" + persona.estadoEm, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setCiudadContacto(response.data);
            }).catch(error => console.log(error));

            setClaves(claves);
            setDatosAnterior(persona);
            handleCargaClose()
        }

        selectDatos();
        datosAspirante();


    }, [info]);
    const classes = useStyles();
    const initial = datosAnterior;


    const handleCargaClose = () => {
        setCargando(false);
    };

    const handleOnChange = (event) => {

        if (event.target.files[0]) {
            const file = event.target.files[0]

            switch (event.target.id) {
                case "curpDoc":
                    setFileCurp(file)
                    setUploadCurp(0)
                    // setCurpMensaje('')
                    break;
                case "acta":
                    setFileActa(file)
                    setUploadActa(0)
                    // setActaMensaje2('')
                    break;
                case "foto":
                    setFileFoto(file)
                    setUploadFoto(0)
                    // setFotoMensaje2('')
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "curpDoc":
                    setFileCurp(false)
                    break;
                case "acta":
                    setFileActa(false)
                    break;
                case "foto":
                    setFileFoto(false)
                    break;
                default:
                    break;
            }
        }
    }

    const schemaValidacion = Yup.object({
        nacimiento: Yup.string().required("El campo de fecha de nacimiento es obligatorio")
            .test('match',
                'La fecha debe ser menor a la fecha actual',
                function (fecha) {
                    return menor(fecha)
                }),
        gen: Yup.string().required('El campo genero  obligatorio'),
        civil: Yup.string().required('El campo estado civil es obligatorio'),
        paisNac: Yup.string().required('El campo país es obligatorio'),
        estadoNac: Yup.string().when('paisNac', (paisNac) => {
            if (paisNac !== undefined) {
                if (paisNac === null) {
                    return Yup.string()
                }
                if (paisNac.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }

            }
        }),
        ciudadNac: Yup.string().when('paisNac', (paisNac) => {
            if (paisNac !== undefined) {
                if (paisNac === null) {
                    return Yup.string()
                }
                if (paisNac.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        rfc: Yup.string().when('paisNac', (paisNac) => {
            if (paisNac !== undefined) {
                if (paisNac === null) {
                    return Yup.string()
                }
                if (paisNac.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo RFC es obligatorio si es de MÉXICO").matches(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/, 'El campo no cumple con el formato de RFC.')
                } else {
                    return Yup.string()
                }
            }
        }),
        curp: Yup.string().when('paisNac', (paisNac) => {
            if (paisNac !== undefined) {
                if (paisNac === null) {
                    return Yup.string()
                }
                if (paisNac.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo CURP es obligatorio si es de MÉXICO").matches(/^[A-Z]{4}[0-9]{6}[A-Z]{6}[A-Z0-9]{2}$/, 'El campo no cumple con el formato de CURP.')
                } else {
                    return Yup.string()
                }
            }
        }),
        paisDom: Yup.string().required('El campo país es obligatorio'),
        cpDom: Yup.string().when('paisDom', (paisDom) => {
            if (paisDom !== undefined) {
                if (paisDom === null) {
                    return Yup.string()
                }
                if (paisDom.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        estadoDom: Yup.string().when('paisDom', (paisDom) => {
            if (paisDom !== undefined) {
                if (paisDom === null) {
                    return Yup.string()
                }
                if (paisDom.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        ciudadDom: Yup.string().when('paisDom', (paisDom) => {
            if (paisDom !== undefined) {
                if (paisDom === null) {
                    return Yup.string()
                }
                if (paisDom.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        coloniaDom: Yup.string().when('paisDom', (paisDom) => {
            if (paisDom !== undefined) {
                if (paisDom === null) {
                    return Yup.string()
                }
                if (paisDom.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        calleDom: Yup.string().when('paisDom', (paisDom) => {
            if (paisDom !== undefined) {
                if (paisDom === null) {
                    return Yup.string()
                }
                if (paisDom.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        informacionDom: Yup.string().when('paisDom', (paisDom) => {
            if (paisDom !== undefined) {
                if (paisDom === null) {
                    return Yup.string()
                }
                if (paisDom.split("-")[1] !== "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio")
                } else {
                    return Yup.string()
                }
            }
        }),
        telefonoDom: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        otroDom: Yup.string().when('coloniaDom', (coloniaDom) => {
            if (coloniaDom !== undefined) {
                if (coloniaDom === "otra") {
                    return Yup.string().required("El campo es obligatorio para Otra colonia ")
                } else {
                    return Yup.string()
                }
            }
        }),
        movil: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        nombre: Yup.string().required('El campo nombre es obligatorio'),
        parentesco: Yup.string().required('El parentesco paterno es obligatorio'),
        telefono: Yup.string().required('El campo télefono es obligatorio'),
        correo: Yup.string().required('El campo correo es obligatorio').email('Ingrese un email valido'),
        paisEm: Yup.string().required('El campo país es obligatorio'),
        cpEm: Yup.string().when('paisEm', (paisEm) => {
            if (paisEm !== undefined) {
                if (paisEm === null) {
                    return Yup.string()
                }
                if (paisEm.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        estadoEm: Yup.string().when('paisEm', (paisEm) => {
            if (paisEm !== undefined) {
                if (paisEm === null) {
                    return Yup.string()
                }
                if (paisEm.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        ciudadEm: Yup.string().when('paisEm', (paisEm) => {
            if (paisEm !== undefined) {
                if (paisEm === null) {
                    return Yup.string()
                }
                if (paisEm.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        coloniaEm: Yup.string().when('paisEm', (paisEm) => {
            if (paisEm !== undefined) {
                if (paisEm === null) {
                    return Yup.string()
                }
                if (paisEm.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        otroEm: Yup.string().when('coloniaEm', (coloniaEm) => {
            if (coloniaEm !== undefined) {
                if (coloniaEm === "otra") {
                    return Yup.string().required("El campo es obligatorio para Otra colonia")
                } else {
                    return Yup.string()
                }
            }
        }),
        calleEm: Yup.string().when('paisEm', (paisEm) => {
            if (paisEm !== undefined) {
                if (paisEm === null) {
                    return Yup.string()
                }
                if (paisEm.split("-")[1] === "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio si es de MÉXICO")
                } else {
                    return Yup.string()
                }
            }
        }),
        domicilio: Yup.string().when('paisEm', (paisEm) => {
            if (paisEm !== undefined) {
                if (paisEm === null) {
                    return Yup.string()
                }
                if (paisEm.split("-")[1] !== "MÉXICO") {
                    return Yup.string().required("El campo es obligatorio.")
                } else {
                    return Yup.string()
                }
            }
        }),
        foto: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileFoto === 0 || SUPPORTED_IMG.includes(fileFoto.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileFoto === 0 || fileFoto.size <= FILE_SIZE)),
           /*  .test('fileRequired', "El documento es obligatorio",
                () => (fileFoto !== 0 && fileFoto !== false)), */
        acta: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileActa === 0 || SUPPORTED_PDF.includes(fileActa.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileActa === 0 || fileActa.size <= FILE_SIZE)),
            /* .test('fileRequired', "El documento es obligatorio",
                () => (fileActa !== 0 && fileActa !== false)), */
        curpDoc: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileCurp === 0 || SUPPORTED_PDF.includes(fileCurp.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileCurp === 0 || fileCurp.size <= FILE_SIZE)),
    });


    const functionSubmit = async valores => {
        let persona = {
            fecha_na: valores.nacimiento,
            celular: valores.movil,
            acta_nac: "",
            curp: valores.curp,
            doc_curp: "",
            estado_civil: valores.civil,
            fotografia: "",
            genero: valores.gen,
            rfc: valores.rfc,
            telefono: valores.telefonoDom
        }

        //Acta para storage
        if (fileActa) {
            persona.acta_nac = fba.auth().currentUser.email + "/DocumentosDatosGenerales/" + uuidv4() + fileActa.name
            const storageRef = fba.storage().ref(persona.acta_nac)
            const task = storageRef.put(fileActa)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadActa(porcentage)
                setActaMensaje(porcentage + '%')
            }, (error) => {
                console.log(error);
                setActaMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        } else {
            if (datosAnterior.acta) {
                persona.acta_nac = datosAnterior.acta;
            }
        }
        ///////////////////////////////////////////////////
        //Foto para storage
        if (fileFoto) {
            persona.fotografia = fba.auth().currentUser.email + '/DocumentosDatosGenerales/' + uuidv4() + fileFoto.name
            const storageRef2 = fba.storage().ref(persona.fotografia)
            const task2 = storageRef2.put(fileFoto)
            task2.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadFoto(porcentage)
                setFotoMensaje(porcentage + '%')
            }, (error) => {
                console.log(error);
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })

        } else {
            if (datosAnterior.foto) {
                persona.fotografia = datosAnterior.foto;
            }
        }
        ///////////////////////////////////////////////////
        //Curp para storage
        if (fileCurp) {
            persona.doc_curp = fba.auth().currentUser.email + '/DocumentosDatosGenerales/' + uuidv4() + fileCurp.name
            const storageRef3 = fba.storage().ref(persona.doc_curp)
            const task3 = storageRef3.put(fileCurp)
            task3.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadCurp(porcentage)
                // setCurpMensaje(porcentage + '%')
            }, (error) => {
                console.log(error);
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        } else {
            if (datosAnterior.curpDoc) {
                persona.doc_curp = datosAnterior.curpDoc;
            }
        }
        ///////////////////////////////////////////////
        // console.log(persona)
        let paisLN = valores.paisNac.split("-");
        let lugar_nacim = {
            ciudad: '',
            estado: '',
            pais: {
                idpais: paisLN[0]
            }
        }
        //Guardar solo valores depende al pa[is]
        if (valores.paisNac.split("-")[1] === "MÉXICO") {
            lugar_nacim.ciudad = valores.ciudadNac
            lugar_nacim.estado = valores.estadoNac
        }


        let optionPaisDom = valores.paisDom.split("-");
        let direccion = {
            calle: '',
            colonia: '',
            informacion: '',
            pais: {
                idpais: optionPaisDom[0]
            }
        }

        //Guardar solo valores depende al pa[is]
        if (valores.paisDom.split("-")[1] === "MÉXICO") {
            if (valores.coloniaDom === "otra") {
                direccion.colonia = valores.otroDom
            }
            direccion.calle = valores.calleDom
        } else {
            direccion.informacion = valores.informacionDom
        }

        let optionPaisEm = valores.paisEm.split("-");

        let direccionEm = {
            calle: '',
            colonia: '',
            informacion: '',
            pais: {
                idpais: optionPaisEm[0]
            }
        }

        //Guardar solo valores depende al pa[is]
        if (valores.paisEm.split("-")[1] === "MÉXICO") {
            if (valores.coloniaEm === "otra") {
                direccionEm.colonia = valores.otroEm
            }
            direccionEm.calle = valores.calleEm
        } else {
            direccionEm.informacion = valores.domicilio
        }


        let contacto_em = {
            correo: valores.correo,
            nombre: valores.nombre,
            parentesco: valores.parentesco,
            telefono: valores.telefono,
            etniapersona: valores.etniapersona,
            resp_etnia: valores.resp_etnia,
        }

        /************************* 
         * Actualizar primera secci[on]
         * 
         * ***********************/
        // Actualizar persona, registrar nuevos campos 
        await axios.put(Global + "persona/ID/" + user, persona, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            console.log(response)
        }).catch(error => console.log(error));

        // alert(c.idPed)
        // console.log(c.idPed)
        if (valores.etnico !== "") {
            if (c.idPed !== "") {
                // Actualizar etnia de la persona personaed
                let personaed = {
                    etnia: {
                        idetnia: valores.etnico
                    }
                }
                axios.put(Global + "personaed/" + c.idPed, personaed, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).catch(error => console.log(error));
            } else {
                //Registra por primera vez
                axios.post(Global + "personaed/persona/" + user + "/etnia/" + valores.etnico,
                    {
                        etnia: {
                            etnia: "string",
                        },
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));
            }
        }


        //Actualizar lugar_nacim
        await axios.put(Global + "lugarna/" + c.idLN, lugar_nacim, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).catch(error => console.log(error));
        /** Fin primera secci[on] */

        await axios.put(Global + "direccion/" + c.iddireccion, direccion, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).catch(error => console.log(error));

        /*
         * INFORMACI[ON] PARA EL  DEL ASPIRANTE
         */

        let codigoContactoDom = ""
        let codigoDireccionCPDom = ""
        let putCodigoPostalDom = {
            codigoPostal: {
                idcodigo: ""
            }
        }

        if (optionPaisDom[1] === "MÉXICO") {
            if (valores.coloniaDom === 'otra') {
                await axios.get(Global + "codigo/estado/" + valores.estadoDom + "/cp/{cp}/ciudad/{ciudad}?ciudad=" + valores.ciudadDom + "&cp=" + valores.cpDom, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    // console.log(response.data)
                    if (response.data.length > 0) {
                        putCodigoPostalDom.codigoPostal.idcodigo = response.data[0].idcodigo;
                        codigoContactoDom = response.data[0].idcodigo
                    }
                    // console.log(putCodigoPostalDom)
                }).catch(error => console.log(error));
            } else {
                await axios.get(Global + "codigo/" + valores.coloniaDom, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    putCodigoPostalDom.codigoPostal.idcodigo = response.data.idcodigo;
                    codigoContactoDom = response.data.idcodigo
                }).catch(error => console.log(error));
            }

            // alert(putCodigoPostalDom.codigoPostal.idcodigo)
            //Get id de codigopostal
            //Ver si hay valores en direccioncp
            await axios.get(Global + "direccioncp/direccion/" + c.iddireccion, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    codigoDireccionCPDom = response.data[0].iddc
                    ////////////Si tiene direccion///////////////////////
                    // alert(putCodigoPostal.codigoPostal.idcodigo)
                    axios.put(Global + "direccioncp/" + codigoDireccionCPDom, putCodigoPostalDom, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));


                } else {
                    //Guarda en /direccioncp  
                    // alert(codigoContactoDom)                 
                    axios.post(Global + "direccioncp/cp/" + codigoContactoDom + "/direccion/" + c.iddireccion,
                        {
                            persona: {
                                persona: "string",
                            },
                        }, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));

                }
            }).catch(error => console.log(error));
        }

        /*
         * INFORMACI[ON] PARA EL CONTACTO DEL ASPIRANTE
         */


        let codigoContactoEm = ""
        let codigoDireccionCP = ""
        let putCodigoPostal = {
            codigoPostal: {
                idcodigo: ""
            }
        }

        if (optionPaisEm[1] === "MÉXICO") {
            console.log("Entra optionPaisEm", optionPaisEm[1]);
            if (valores.coloniaEm === 'otra') {
                await axios.get(Global + "codigo/estado/" + valores.estadoEm + "/cp/{cp}/ciudad/{ciudad}?ciudad=" + valores.ciudadEm + "&cp=" + valores.cpEm, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    if (response.data.length > 0) {
                        putCodigoPostal.codigoPostal.idcodigo = response.data[0].idcodigo;
                        codigoContactoEm = response.data[0].idcodigo

                    }
                }).catch(error => console.log(error));
            } else {
                await axios.get(Global + "codigo/" + valores.coloniaEm, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {

                    putCodigoPostal.codigoPostal.idcodigo = response.data.idcodigo;
                    codigoContactoEm = response.data.idcodigo

                }).catch(error => console.log(error));
            }
            //Ver si hay valores en direccioncp
            await axios.get(Global + "direccioncp/direccion/" + c.iddireccionDos, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    ////////////Si tiene direccion///////////////////////
                    codigoDireccionCP = response.data[0].iddc
                    // alert(putCodigoPostal.codigoPostal.idcodigo)

                    axios.put(Global + "direccioncp/" + codigoDireccionCP, putCodigoPostal, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));

                } else {
                    //Guarda en /direccioncp   
                    // alert(codigoContactoEm)
                    axios.post(Global + "direccioncp/cp/" + codigoContactoEm + "/direccion/" + c.iddireccionDos,
                        {
                            persona: {
                                persona: "string",
                            },
                        }, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).catch(error => console.log(error));

                }
            }).catch(error => console.log(error));
        }

        // Actualizar contacto_em y direccion
        await axios.put(Global + "direccion/" + c.iddireccionDos, direccionEm, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).catch(error => console.log(error));


        // Modificar contactoem
        await axios.put(Global + "contactoem/" + c.idCEM, contacto_em, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).catch(error => console.log(error));

        Swal({
            title: 'Datos registrados',
            text: ' ',
            icon: 'success',
            button: false,
            timer: 1000
        })

        setInfo("Cambio");



    }

    const handleChangePais = e => {
        // e.preventDefault();
        let optionPais = e.split("-");
        console.log(optionPais);
        if (optionPais[1] === "MÉXICO") {
            setNacimiento(true);
            setPaisNac(optionPais[0]);
        } else {
            setPaisNac(optionPais[0]);
            setNacimiento(false);
        }
    }

    const handleChangeEmergencia = async e => {
        // e.preventDefault();
        let optionPais = e.split("-");
        console.log(optionPais);
        if (optionPais[1] === "MÉXICO") {
            setEmergencia(true);

            await axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisEm(response.data);
            }).catch(error => console.log(error))

        } else {
            setEmergencia(false);
            await axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisEm(response.data);
            }).catch(error => console.log(error))
        }
    }

    const handleChangeDomicilio = e => {
        // e.preventDefault();
        let optionPais = e.split("-");
        console.log(optionPais)
        if (optionPais[1] === "MÉXICO") {
            setDomicilio(true);

            axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisDom(response.data);
            }).catch(error => console.log(error))
        } else {
            setDomicilio(false);

            axios.get(Global + "pais/pais/" + optionPais[1], {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaisDom(response.data);
            }).catch(error => console.log(error));
        }
    }

    const handleChangeEstado = e => {
        e.preventDefault();
        axios.get(Global + "codigo/estado/" + e.target.value, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setCiudades(response.data);
        }).catch(error => console.log(error));

    }

    //Cuando cambian el codigo postal 
    const handleChangeCP = e => {
        e.preventDefault();
        switch (e.target.name) {
            case "cpDom":
                if (e.target.value.length > 0) {
                    setCiudadDomicilio('');
                    axios.get(Global + "codigo/cps/" + e.target.value, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setCPDomicilio(response.data[0]);
                        setEstadoDomicilio(response.data[0]);
                        setColoniaDomicilio(response.data);
                    }).catch(error => console.log(error));
                }
                break;
            case "cpEm":
                if (e.target.value.length > 0) {
                    setCiudadContacto('');
                    axios.get(Global + "codigo/cps/" + e.target.value, {
                        headers: {
                            'Access-Control-Allow-Origin': "*",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setCPContacto(response.data[0]);
                        setEstadoContacto(response.data[0]);
                        setColoniaContacto(response.data);
                    }).catch(error => console.log(error));
                }
                break;
            default:
                break;


        }


    }

    const [cargando, setCargando] = useState(true)

    return (
        <div>
            {cargando ?
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <Formik
                    validationSchema={schemaValidacion}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        functionSubmit(valores);
                    }}>
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>

                                <Grid container spacing={2}>
                                    <Paper elevation={3} className={classes.paper}>
                                        <br />
                                        <Typography className={classes.subtitulo}>
                                            Datos Generales
                                        </Typography>
                                        <hr />
                                        <br />
                                        {/* <Tooltip title="Lugar: Datos de nacimiento, tener a la mano Acta de Nacimiento." aria-label="add">
                                            <Avatar className={classes.avatar}>
                                                <HelpOutlineIcon/>
                                            </Avatar>
                                        </Tooltip> */}
                                            <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">Lugar: datos de nacimiento, tener a la mano acta de nacimiento</Typography>
                                                </React.Fragment>
                                                }
                                            >
                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                <HelpOutlineIcon />
                                            </Fab>
                                       </HtmlTooltip>
                                        <br />

                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" spacing={3}>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined" required fullWidth
                                                    id="nacimiento"
                                                    label="Fecha de nacimiento"
                                                    type="date"
                                                    value={props.values.nacimiento}
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.nacimiento && props.errors.nacimiento ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.nacimiento && props.errors.nacimiento ? (
                                                        props.errors.nacimiento
                                                    ) : null}
                                                    InputProps={FORMATOFECHAMAXHOY}
                                                />
                                            </Grid>                                            
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined" fullWidth required
                                                    className={classes.formControl}
                                                    error={props.touched.gen && props.errors.gen ? (true) : false}>
                                                    <InputLabel>Género</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.gen}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Género"
                                                        inputProps={{
                                                            name: 'gen',
                                                            id: 'gen',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={'Masculino'}>Masculino</option>
                                                        <option value={'Femenino'}>Femenino</option>
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.gen && props.errors.gen ? (props.errors.gen) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    className={classes.formControl}
                                                    error={props.touched.civil && props.errors.civil ? (true) : false}>
                                                    <InputLabel>Estado civil</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.civil}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Estado civil"
                                                        inputProps={{
                                                            name: 'civil',
                                                            id: 'civil',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={'soltero'}>Soltero</option>
                                                        <option value={'casado'}>Casado</option>
                                                        <option value={'otro'}>Otro</option>
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.civil && props.errors.civil ? (props.errors.civil) : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            {/*  Pregunta de etnia */}
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    className={classes.formControl}
                                                    error={props.touched.resp_etnia && props.errors.resp_etnia ? (true) : false}>
                                                    <InputLabel> ¿Se identifica con algún grupo étnico?</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.resp_etnia}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="¿Se identifica con algún grupo étnico?"
                                                        inputProps={{
                                                            name: 'resp_etnia',
                                                            id: 'resp_etnia',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={'SI'}>SI</option>
                                                        <option value={'NO'}>NO</option>
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.resp_etnia && props.errors.resp_etnia ? (props.errors.resp_etnia) : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    className={classes.formControl}
                                                    error={props.touched.paisNac && props.errors.paisNac ? (true) : false}>
                                                    <Autocomplete
                                                        freeSolo
                                                        required
                                                        name='paisNac'
                                                        id='paisNac'
                                                        value={props.values.paisNac}
                                                        onChange={(event, newValue) => {
                                                            if (newValue != null) {
                                                                handleChangePais(newValue);
                                                            }
                                                            props.setFieldValue("paisNac", newValue);
                                                        }}
                                                        label="País de Nacimiento"
                                                        options={paises.map((pais) => pais.idpais + "-" + pais.pais)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="País de Nacimiento"
                                                                name='paisNac'
                                                                id='paisNac'
                                                                margin="normal"
                                                                variant="outlined"
                                                                onBlur={props.handleBlur}
                                                            />
                                                        )}
                                                    >
                                                    </Autocomplete>
                                                    <FormHelperText>
                                                        {props.touched.paisNac && props.errors.paisNac ? "El campo país es obligatorio" : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            {props.values.resp_etnia === 'SI' &&
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    className={classes.formControl}
                                                    error={props.touched.etniapersona && props.errors.etniapersona ? (true) : false}>
                                                    <InputLabel>Grupo Étnico</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.etniapersona}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Grupo Étnico"
                                                        inputProps={{
                                                            name: 'etniapersona',
                                                            id: 'etniapersona',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={'AGUACATECO'}>AGUACATECO</option>
                                                        <option value={'AMUZGO'}>AMUZGO</option>
                                                        <option value={'AYAPANECO'}>AYAPANECO</option>
                                                        <option value={'CAKCHIQUEL'}>CAKCHIQUEL</option>
                                                        <option value={'CHATINO'}>CHATINO</option>
                                                        <option value={'CHICHIMECA'}>CHICHIMECA</option>
                                                        <option value={'CHINANTE'}>CHINANTE</option>
                                                        <option value={'CHINANTECO'}>CHINANTECO</option>
                                                        <option value={'CHOCHO'}>CHOCHO</option>
                                                        <option value={'CHOL'}>CHOL</option>
                                                        <option value={'CHONTAL'}>CHONTAL</option>
                                                        <option value={'CHUJ'}>CHUJ</option>
                                                        <option value={'COCHIMI'}>COCHIMI</option>
                                                        <option value={'CORA'}>CORA</option>
                                                        <option value={'CUCAPA'}>CUCAPA</option>
                                                        <option value={'CUICATECO'}>CUICATECO</option>
                                                        <option value={'GUARIJIO'}>GUARIJIO</option>
                                                        <option value={'HUASTECO'}>HUASTECO</option>
                                                        <option value={'HUAVE'}>HUAVE</option>
                                                        <option value={'HUICHOL'}>HUICHOL</option>
                                                        <option value={'IXCATECO'}>IXCATECO</option>
                                                        <option value={'IXIL'}>IXIL</option>
                                                        <option value={'JACALTECO'}>JACALTECO</option>
                                                        <option value={'KANJOBAL'}>KANJOBAL</option>
                                                        <option value={'KEKCHI'}>KEKCHI</option>
                                                        <option value={'KIKAPU'}>KIKAPU</option>
                                                        <option value={'KILIWA'}>KILIWA</option>
                                                        <option value={'KUMIAI'}>KUMIAI</option>
                                                        <option value={'LACANDON'}>LACANDON</option>
                                                        <option value={'MAME'}>MAME</option>
                                                        <option value={'MATLATZINC'}>MATLATZINC</option>
                                                        <option value={'MAYA'}>MAYA</option>
                                                        <option value={'MAYO'}>MAYO</option>
                                                        <option value={'MAZAHUA'}>MAZAHUA</option>
                                                        <option value={'MAZATECO'}>MAZATECO</option>
                                                        <option value={'MIXE'}>MIXE</option>
                                                        <option value={'MIXTECO'}>MIXTECO</option>
                                                        <option value={'MOTOCINTLE'}>MOTOCINTLE</option>
                                                        <option value={'NAHUATL'}>NAHUATL</option>
                                                        <option value={'OCUILTECO'}>OCUILTECO</option>
                                                        <option value={'OTOMI'}>OTOMI</option>
                                                        <option value={'OTRASLE'}>OTRASLE</option>
                                                        <option value={'OTRASLENG'}>OTRASLENG</option>
                                                        <option value={'PAIPAI'}>PAIPAI</option>
                                                        <option value={'PAME'}>PAME</option>
                                                        <option value={'PAPABUCO'}>PAPABUCO</option>
                                                        <option value={'PAPAGO'}>PAPAGO</option>
                                                        <option value={'PIMA'}>PIMA</option>
                                                        <option value={'POHA'}>POHA</option>
                                                        <option value={'POPOLOCA'}>POPOLOCA</option>
                                                        <option value={'POPOLUCA'}>POPOLUCA</option>
                                                        <option value={'PUREPECHA'}>PUREPECHA</option>
                                                        <option value={'QUICHE'}>QUICHE</option>
                                                        <option value={'SERI'}>SERI</option>
                                                        <option value={'SOLTECO'}>SOLTECO</option>
                                                        <option value={'TACUATE'}>TACUATE</option>
                                                        <option value={'TARAHUMARA'}>TARAHUMARA</option>
                                                        <option value={'TEPEHUA'}>TEPEHUA</option>
                                                        <option value={'TEPEHUANO'}>TEPEHUANO</option>
                                                        <option value={'TLAPANECO'}>TLAPANECO</option>
                                                        <option value={'TOJOLABAL'}>TOJOLABAL</option>
                                                        <option value={'TOLE'}>TOLE</option>
                                                        <option value={'TOTONACA'}>TOTONACA</option>
                                                        <option value={'TRIQUI'}>TRIQUI</option>
                                                        <option value={'TZELTAL'}>TZELTAL</option>
                                                        <option value={'TZOTZIL'}>TZOTZIL</option>
                                                        <option value={'YAQUI'}>YAQUI</option>
                                                        <option value={'ZAPOTECO'}>ZAPOTECO</option>
                                                        <option value={'ZOQUE'}>ZOQUE</option>
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.etniapersona && props.errors.etniapersona ? (props.errors.etniapersona) : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            }
                                            <Grid item xs={12} sm={6}></Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12} sm={6}></Grid>
                                            {/*   <Grid item xs={12}>
                                                <FormControl
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    className={classes.formControl}
                                                    error={props.touched.etnico && props.errors.etnico ? (true) : false}>
                                                    <InputLabel>Grupo Étnico</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.etnico}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Grupo Etnico"
                                                        inputProps={{
                                                            name: 'etnico',
                                                            id: 'etnico',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {
                                                            etnia.map((etni) => {
                                                                return <option key={etni.idetnia} value={etni.idetnia}>{etni.etnia}</option>
                                                            })
                                                        }

                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.etnico && props.errors.etnico ? (props.errors.etnico) : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid> */}
                                            <Grid item xs={12} sm={6}>

                                            </Grid>

                                            {nacimiento ? (
                                                <>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.estadoNac && props.errors.estadoNac ? (true) : false}>
                                                            <InputLabel >Estado</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.estadoNac}
                                                                onChange={
                                                                    e => {
                                                                        handleChangeEstado(e);
                                                                        props.setFieldValue("estadoNac", e.target.value);
                                                                    }
                                                                }
                                                                onBlur={props.handleBlur}
                                                                label="Estado"
                                                                inputProps={{
                                                                    name: 'estadoNac',
                                                                    id: 'estadoNac',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    estado.map((estado) => {
                                                                        return <option key={estado.idcodigo} value={estado.estado}>{estado.estado}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.estadoNac && props.errors.estadoNac ? (props.errors.estadoNac) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.ciudadNac && props.errors.ciudadNac ? (true) : false}>
                                                            <InputLabel>Municipio</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.ciudadNac}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Municipio"
                                                                inputProps={{
                                                                    name: 'ciudadNac',
                                                                    id: 'ciudadNac',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {ciudades !== '' ?
                                                                    ciudades.map((ciudad) => {
                                                                        return <option key={ciudad.idcodigo} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                                    })
                                                                    : null
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.ciudadNac && props.errors.ciudadNac ? (props.errors.ciudadNac) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        {/* <Tooltip title="RFC con homoclave" aria-label="add">
                                                            <Avatar className={classes.avatar}>
                                                               <HelpOutlineIcon/>
                                                            </Avatar>
                                                        </Tooltip> */}
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">RFC con homoclave</Typography>
                                                                </React.Fragment>
                                                                }
                                                            >
                                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                                <HelpOutlineIcon />
                                                            </Fab>
                                                        </HtmlTooltip>
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            variant="outlined"
                                                            name="rfc"
                                                            type="text"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            required
                                                            fullWidth
                                                            id="rfc"
                                                            label="RFC"
                                                            value={props.values.rfc}
                                                            onChange={(e) => {
                                                                let rfc = e.target.value;
                                                                props.setFieldValue("rfc", rfc.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.rfc && props.errors.rfc ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.rfc && props.errors.rfc ? (
                                                                props.errors.rfc
                                                            ) : null}
                                                        />

                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        {/* <Tooltip title="CURP: Está compuesto por (AAAA000000HMDRES00)" aria-label="add">
                                                           <Avatar className={classes.avatar}>
                                                             <HelpOutlineIcon/>
                                                           </Avatar>
                                                        </Tooltip> */}
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">CURP: Está compuesto por (AAAA000000HMDRES00)</Typography>
                                                                </React.Fragment>
                                                                }
                                                            >
                                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                                <HelpOutlineIcon />
                                                            </Fab>
                                                        </HtmlTooltip>
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            variant="outlined"
                                                            name="curp"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            required
                                                            fullWidth
                                                            id="curp"
                                                            label="CURP"
                                                            value={props.values.curp}
                                                            onChange={(e) => {
                                                                let curp = e.target.value;
                                                                props.setFieldValue("curp", curp.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.curp && props.errors.curp ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.curp && props.errors.curp ? (
                                                                props.errors.curp
                                                            ) : null}
                                                            type='text'
                                                        />
                                                    </Grid>

                                                    {/*Documentos CURP y Foto*/}
                                                    <Grid item xs={12} sm={4}>
                                                        <label htmlFor="curpDoc">
                                                            <input
                                                                style={{ display: 'none' }}
                                                                id="curpDoc"
                                                                name="curpDoc"
                                                                type="file"
                                                                accept="application/pdf"
                                                                onChange={handleOnChange}
                                                            />
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography color="inherit">Archivo Obligatorio.
                                                                        Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB</Typography>
                                                                    </React.Fragment>
                                                                    }
                                                                >
                                                                <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                                    <HelpOutlineIcon />
                                                                </Fab>
                                                            </HtmlTooltip>  
                                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                                CURP
                                                                {fileCurp ?
                                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                                    : null}
                                                            </Button>

                                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                                {(props.touched.curpDoc && props.errors.curpDoc) && (props.errors.curpDoc)}
                                                            </Typography>
                                                        </label>

                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant='body2'>
                                                                {curpMensaje !== null && curpMensaje !== '' ?
                                                                    <a style={{ display: "table-cell" }} href={curpMensaje} target="_blank" rel="noreferrer">
                                                                        Ver archivo
                                                                    </a>
                                                                    : null}
                                                            </Typography>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={8}></Grid>

                                                </>) : null}

                                            <Grid item xs={12} sm={4}>
                                                <label htmlFor="acta">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="acta"
                                                        name="acta"
                                                        type="file"
                                                        accept="application/pdf"
                                                        onChange={handleOnChange}
                                                    />
                                                    {/* <Tooltip title="Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB." aria-label="add">
                                                        <Avatar className={classes.avatar} >
                                                          <HelpOutlineIcon/>
                                                        </Avatar>
                                                    </Tooltip> */}
                                                    <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">Archivo Obligatorio.
                                                                    Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB</Typography>
                                                                </React.Fragment>
                                                                }
                                                            >
                                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                                <HelpOutlineIcon />
                                                            </Fab>
                                                    </HtmlTooltip>       
                                                    <br />
                                                    <br />   
                                                    <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                                        Acta de nacimiento
                                                        {fileActa ?
                                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                            : null}
                                                    </Button>
                                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                                        {(props.touched.acta && props.errors.acta) && (props.errors.acta)}
                                                    </Typography>
                                                </label>
                                                <Grid item xs={3} sm={3} >

                                                    <Typography variant='body2'>
                                                        {
                                                            actaMensaje2 !== null && actaMensaje2 !== '' ?
                                                                <a style={{ display: "table-cell" }} href={actaMensaje2} target="_blank" rel="noreferrer">
                                                                    Ver archivo
                                                                </a> : null
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>

                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label htmlFor="foto">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="foto"
                                                        name="foto"
                                                        type="file"
                                                        accept="image/jpeg"
                                                        onChange={handleOnChange}
                                                    />
                                                   {/*  <Tooltip title="La fotografía en formato JPG y con encuadre tipo diploma." aria-label="add">
                                                        <Avatar className={classes.avatar}>
                                                          <HelpOutlineIcon/>
                                                        </Avatar>
                                                    </Tooltip> */}
                                                    <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">Archivo Obligatorio.
                                                                    La fotografía en formato JPG y con encuadre tamaño pasaporte.</Typography>
                                                                </React.Fragment>
                                                                }
                                                            >
                                                            <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                                <HelpOutlineIcon />
                                                            </Fab>
                                                    </HtmlTooltip>  
                                                    <br />
                                                    <br />
                                                    <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                                        Fotografía
                                                        {fileFoto ?
                                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                            : null}
                                                    </Button>
                                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                                        {(props.touched.foto && props.errors.foto) && (props.errors.foto)}
                                                    </Typography>
                                                </label>
                                                <Grid item xs={3} sm={3}>
                                                    <Typography variant='body2'>
                                                        {fotoMensaje2 !== null && fotoMensaje2 !== '' ?
                                                            <a style={{ display: "table-cell" }} href={fotoMensaje2} target="_blank" rel="noreferrer">
                                                                Ver archivo
                                                            </a> : null}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>

                                            </Grid>
                                        </Grid>
                                        <br />
                                    </Paper>

                                </Grid>


                               {/*  <Grid container spacing={2}> */}
                               <Grid item xs={12}>
                                    {/* Contenerdor de Paper Domicilio */}
                                    {/* <Grid item xs={12} sm={6}> */}

                                        <Paper elevation={2} className={classes.paper} >
                                            <br />
                                            <Typography className={classes.subtitulo}>
                                                Domicilio Actual 
                                            </Typography>
                                            {/* <hr />
                                            <br /> */}
                                            {/* <Tooltip title="Domicilio: Domicilio donde actualmente está radicando." aria-label="add">
                                               <Avatar className={classes.avatar} >
                                                  <HelpOutlineIcon/>
                                               </Avatar>
                                            </Tooltip> */}
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">Domicilio: domicilio donde actualmente está radicando</Typography>
                                                    </React.Fragment>
                                                    }
                                                >
                                                <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                    <HelpOutlineIcon />
                                                </Fab>
                                            </HtmlTooltip> 
                                           {/*  <br /> */}
                                            
                                                    {/* <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.formControl}
                                                        error={props.touched.paisDom && props.errors.paisDom ? (true) : false}>
                                                        <InputLabel>Pais</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.paisDom}
                                                            onChange={e => {
                                                                handleChangeDomicilio(e);
                                                                props.setFieldValue("paisDom", e.target.value);

                                                            }}
                                                            onBlur={props.handleBlur}
                                                            label="Pais"
                                                            inputProps={{
                                                                name: 'paisDom',
                                                                id: 'paisDom',
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" /> {
                                                                paises.map((pais) => {
                                                                    return <option key={pais.idpais} value={pais.idpais + "-" + pais.pais} label={pais.pais}>{pais.pais}</option>
                                                                })
                                                            }
                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.paisDom && props.errors.paisDom ? (props.errors.paisDom) : null}</FormHelperText>
                                                    </FormControl> */}
                                                    {/* //////////////////////////////////////////////////// */}
                                                <Grid container spacing={2}>
                                                  <Grid item xs={12} sm={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        className={classes.formControl}
                                                        error={props.touched.paisDom && props.errors.paisDom ? (true) : false}>
                                                        <Autocomplete
                                                            freeSolo
                                                            required
                                                            name='paisDom'
                                                            id='paisDom'
                                                            value={props.values.paisDom}
                                                            onChange={(event, newValue) => {
                                                                if (newValue != null) {
                                                                    handleChangeDomicilio(newValue);
                                                                }
                                                                props.setFieldValue("paisDom", newValue);
                                                            }}
                                                            label="País"

                                                            options={paises.map((pais) => pais.idpais + "-" + pais.pais)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="País"
                                                                    name='paisDom'
                                                                    id='paisDom'
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    onBlur={props.handleBlur}
                                                                />
                                                            )}
                                                        >
                                                        </Autocomplete>
                                                        <FormHelperText>
                                                            {props.touched.paisDom && props.errors.paisDom ? "El campo país es obligatorio" : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}> </Grid>
                                                {domicilio ?
                                                    <>
                                                        
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                label="Código postal"
                                                                name="cpDom"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant="outlined"
                                                                fullWidth
                                                                value={props.values.cpDom}
                                                                onChange={e => {
                                                                    handleChangeCP(e);
                                                                    props.setFieldValue("cpDom", e.target.value);
                                                                }}
                                                                onBlur={props.handleBlur}
                                                                error={props.touched.cpDom && props.errors.cpDom ? (
                                                                    true
                                                                ) : false}
                                                                helperText={props.touched.cpDom && props.errors.cpDom ? (
                                                                    props.errors.cpDom
                                                                ) : null}
                                                            >
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                fullWidth
                                                                className={classes.formControl}
                                                                error={props.touched.estadoDom && props.errors.estadoDom ? (true) : false}>
                                                                <InputLabel>Estado</InputLabel>
                                                                <Select
                                                                    native
                                                                    value={props.values.estadoDom}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur}
                                                                    label="Estado"
                                                                    inputProps={{
                                                                        name: 'estadoDom',
                                                                        id: 'estadoDom',
                                                                    }}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    {cpDomicilio ?
                                                                        <option key={cpDomicilio.idcodigo} value={cpDomicilio.estado} >{cpDomicilio.estado}</option>
                                                                        : null
                                                                    }
                                                                </Select>
                                                                <FormHelperText>
                                                                    {props.touched.estadoDom && props.errors.estadoDom ? (props.errors.estadoDom) : null}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                fullWidth
                                                                className={classes.formControl}
                                                                error={props.touched.ciudadDom && props.errors.ciudadDom ? (true) : false}>
                                                                <InputLabel>Ciudad</InputLabel>
                                                                <Select
                                                                    native
                                                                    value={props.values.ciudadDom}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur}
                                                                    label="Ciudad"
                                                                    inputProps={{
                                                                        name: 'ciudadDom',
                                                                        id: 'ciudadDom',
                                                                    }}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    {estadoDomicilio ?
                                                                        <option key={estadoDomicilio.idcodigo} value={estadoDomicilio.ciudad} >{estadoDomicilio.ciudad}</option>

                                                                        : null
                                                                    }
                                                                </Select>
                                                                <FormHelperText>
                                                                    {props.touched.ciudadDom && props.errors.ciudadDom ? (props.errors.ciudadDom) : null}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                fullWidth
                                                                className={classes.formControl}
                                                                error={props.touched.coloniaDom && props.errors.coloniaDom ? (true) : false}>
                                                                <InputLabel >Colonia</InputLabel>
                                                                <Select
                                                                    native
                                                                    value={props.values.coloniaDom}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur}
                                                                    label="coloniaDom"
                                                                    inputProps={{
                                                                        name: 'coloniaDom',
                                                                        id: 'coloniaDom',
                                                                    }}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    {coloniaDomicilio ?
                                                                        coloniaDomicilio.map((colonia) => {
                                                                            return <option key={colonia.idcodigo} value={colonia.idcodigo} >{colonia.colonia}</option>
                                                                        }) : null
                                                                    }
                                                                    <option value="otra" >Otra colonia...</option>
                                                                </Select>
                                                                <FormHelperText>
                                                                    {props.touched.coloniaDom && props.errors.coloniaDom ? (props.errors.coloniaDom) : null}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                id="otroDom"
                                                                name="otroDom"
                                                                label="Otra colonia"
                                                                value={props.values.otroDom}
                                                                onChange={(e) => {
                                                                    let otracol = e.target.value;
                                                                    props.setFieldValue("otroDom", otracol.toUpperCase())
                                                                }}
                                                                onBlur={props.handleBlur}
                                                                error={props.touched.otroDom && props.errors.otroDom ? (
                                                                    true
                                                                ) : false}
                                                                helperText={props.touched.otroDom && props.errors.otroDom ? (
                                                                    props.errors.otroDom
                                                                ) : null}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                required
                                                                fullWidth
                                                                id="calleDom"
                                                                label="Calle y número"
                                                                name="calleDom"
                                                                value={props.values.calleDom}
                                                                onChange={(e) => {
                                                                    let callenum = e.target.value;
                                                                    props.setFieldValue("calleDom", callenum.toUpperCase())
                                                                }}
                                                                onBlur={props.handleBlur}
                                                                error={props.touched.calleDom && props.errors.calleDom ? (
                                                                    true
                                                                ) : false}
                                                                helperText={props.touched.calleDom && props.errors.calleDom ? (
                                                                    props.errors.calleDom
                                                                ) : null}

                                                            />
                                                        </Grid>
                                                    </> :


                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            required
                                                            fullWidth
                                                            id="informacionDom"
                                                            label="Domicilio completo"
                                                            name="informacionDom"
                                                            value={props.values.informacionDom}
                                                            onChange={(e) => {
                                                                let dom = e.target.value;
                                                                props.setFieldValue("informacionDom", dom.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.informacionDom && props.errors.informacionDom ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.informacionDom && props.errors.informacionDom ? (
                                                                props.errors.informacionDom
                                                            ) : null}
                                                        />
                                                    </Grid>
                                                    }
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        label="Teléfono fijo"
                                                        id="telefonoDom"
                                                        name="telefonoDom"
                                                        inputProps={{ maxLength: 10 }}
                                                        value={props.values.telefonoDom}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.telefonoDom && props.errors.telefonoDom ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.telefonoDom && props.errors.telefonoDom ? (
                                                            props.errors.telefonoDom
                                                        ) : null}
                                                    />
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit">Teléfono: el teléfono fijo contiene como máximo 10 dígitos</Typography>
                                                            </React.Fragment>
                                                            }
                                                        >
                                                        <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                            <HelpOutlineIcon />
                                                        </Fab>
                                                    </HtmlTooltip> 
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        id="movil"
                                                        label="Móvil"
                                                        name="movil"
                                                        value={props.values.movil}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.movil && props.errors.movil ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.movil && props.errors.movil ? (
                                                            props.errors.movil
                                                        ) : null}

                                                    />
                                                    <br />
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">Móvil: el móvil puede tener mas de 10 dígitos por ladas internacionales</Typography>
                                                    </React.Fragment>
                                                    }
                                                >
                                                <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                    <HelpOutlineIcon />
                                                </Fab>
                                            </HtmlTooltip> 
                                                </Grid>

                                                <Grid item xs={12}>
                                                   {/*  <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit">Teléfono: El teléfono fijo contiene como máximo 10 dígitos</Typography>
                                                            </React.Fragment>
                                                            }
                                                        >
                                                        <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                            <HelpOutlineIcon />
                                                        </Fab>
                                                    </HtmlTooltip>  */}
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>


                                    {/* Contenerdor de Paper Contacto Emergencia */}
                                    {/* <Grid item xs={12} sm={6}> */}
                                    <Grid container spacing={2}>
                                        <Paper elevation={3} className={classes.paper}>
                                            <br />
                                            <Typography className={classes.subtitulo}>
                                                Contacto En caso De Emergencia
                                            </Typography>
                                            <hr />
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        required
                                                        fullWidth
                                                        id="nombre"
                                                        label="Nombre completo"
                                                        name="nombre"
                                                        value={props.values.nombre}
                                                        onChange={(e) => {
                                                            let nom = e.target.value;
                                                            props.setFieldValue("nombre", nom.toUpperCase())
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.nombre && props.errors.nombre ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.nombre && props.errors.nombre ? (
                                                            props.errors.nombre
                                                        ) : null}

                                                    />
                                                </Grid>

                                                <Grid item xs={12}sm={6} >
                                                    <TextField
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        required
                                                        fullWidth
                                                        id="parentesco"
                                                        label="Parentesco"
                                                        name="parentesco"
                                                        value={props.values.parentesco}
                                                        onChange={(e) => {
                                                            let parentesco = e.target.value;
                                                            props.setFieldValue("parentesco", parentesco.toUpperCase())
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.parentesco && props.errors.parentesco ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.parentesco && props.errors.parentesco ? (
                                                            props.errors.parentesco
                                                        ) : null}

                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        required
                                                        fullWidth
                                                        id="telefono"
                                                        label="Teléfono"
                                                        name="telefono"
                                                        value={props.values.telefono}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.telefono && props.errors.telefono ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.telefono && props.errors.telefono ? (
                                                            props.errors.telefono
                                                        ) : null}

                                                    />
                                                      <br />
                                                      <br />
                                                    {/* <Tooltip title="Teléfono: El teléfono puede tener mas de 10 dígitos por ladas internacionales." aria-label="add">
                                                          <HelpOutlineIcon className={classes.avatar}/>
                                                    </Tooltip> */}
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">Teléfono: el teléfono puede tener mas de 10 dígitos por ladas internacionales</Typography>
                                                    </React.Fragment>
                                                    }
                                                >
                                                <Fab size="small" color="#A5A19F" className={classes.margin} >
                                                    <HelpOutlineIcon />
                                                </Fab>
                                            </HtmlTooltip> 
                                                    <br />
                                                    
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        required
                                                        fullWidth
                                                        id="correo"
                                                        label="Correo electronico"
                                                        name="correo"
                                                        value={props.values.correo}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.correo && props.errors.correo ? (
                                                            true
                                                        ) : false}
                                                        helperText={props.touched.correo && props.errors.correo ? (
                                                            props.errors.correo
                                                        ) : null}

                                                    />

                                                </Grid>

                                                <Grid item xs={12}  sm={6}>
                                                    {/* <FormControl
                                                        variant="outlined"

                                                        fullWidth
                                                        className={classes.formControl}
                                                        error={props.touched.paisEm && props.errors.paisEm ? (true) : false}>
                                                        <InputLabel>País</InputLabel>
                                                        <Select
                                                            native
                                                            required
                                                            value={props.values.paisEm}
                                                            onChange={e => {
                                                                handleChangeEmergencia(e);
                                                                props.setFieldValue("paisEm", e.target.value);

                                                            }}
                                                            onBlur={props.handleBlur}
                                                            label="País"
                                                            inputProps={{
                                                                name: 'paisEm',
                                                                id: 'paisEm',
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" /> {
                                                                paises.map((pais) => {
                                                                    return <option key={pais.idpais} value={pais.idpais + "-" + pais.pais} label={pais.pais}>{pais.pais}</option>
                                                                })
                                                            }
                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.paisEm && props.errors.paisEm ? (props.errors.paisEm) : null}</FormHelperText>
                                                    </FormControl> */}
                                                    {/* ////////////////////////////////////// */}
                                                    <FormControl
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        className={classes.formControl}
                                                        error={props.touched.paisEm && props.errors.paisEm ? (true) : false}>
                                                        <Autocomplete
                                                            freeSolo
                                                            name='paisEm'
                                                            id='paisEm'
                                                            value={props.values.paisEm}
                                                            required
                                                            onChange={(event, newValue) => {
                                                                if (newValue != null) {
                                                                    handleChangeEmergencia(newValue);
                                                                }
                                                                props.setFieldValue("paisEm", newValue);

                                                            }}
                                                            label="País"

                                                            options={paises.map((pais) => pais.idpais + "-" + pais.pais)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="País"
                                                                    name='paisEm'
                                                                    id='paisEm'
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    onBlur={props.handleBlur}
                                                                />
                                                            )}
                                                        >
                                                        </Autocomplete>
                                                        <FormHelperText>
                                                            {props.touched.paisEm && props.errors.paisEm ? "El campo país es obligatorio" : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}></Grid>

                                                {emergencia ? <>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Código postal"
                                                            name="cpEm"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={props.values.cpEm}
                                                            onChange={e => {
                                                                handleChangeCP(e);
                                                                props.setFieldValue("cpEm", e.target.value);
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.cpEm && props.errors.cpEm ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.cpEm && props.errors.cpEm ? (
                                                                props.errors.cpEm
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"

                                                            fullWidth
                                                            className={classes.formControl}
                                                            error={props.touched.estadoEm && props.errors.estadoEm ? (true) : false}>
                                                            <InputLabel >Estado</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.estadoEm}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Estado"
                                                                inputProps={{
                                                                    name: 'estadoEm',
                                                                    id: 'estadoEm',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {cpContacto ?
                                                                    <option value={cpContacto.estado} >{cpContacto.estado}</option>
                                                                    // cpContacto.map((estado) => {
                                                                    //     return <option key={estado.idcodigo} value={estado.estado} >{estado.estado}</option>
                                                                    // }) : null
                                                                    : null
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.estadoEm && props.errors.estadoEm ? (props.errors.estadoEm) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"

                                                            fullWidth
                                                            className={classes.formControl}
                                                            error={props.touched.ciudadEm && props.errors.ciudadEm ? (true) : false}>
                                                            <InputLabel>Ciudad</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.ciudadEm}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Ciudad"
                                                                inputProps={{
                                                                    name: 'ciudadEm',
                                                                    id: 'ciudadEm',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {estadoContacto ?
                                                                    <option value={estadoContacto.ciudad} >{estadoContacto.ciudad}</option>
                                                                    // estadoContacto.map((ciudad) => {
                                                                    //     return <option key={ciudad.idcodigo} value={ciudad.ciudad} >{ciudad.ciudad}</option>
                                                                    // }) : null
                                                                    : null
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.ciudadEm && props.errors.ciudadEm ? (props.errors.ciudadEm) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"

                                                            fullWidth
                                                            className={classes.formControl}
                                                            error={props.touched.coloniaEm && props.errors.coloniaEm ? (true) : false}>
                                                            <InputLabel >Colonia</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.coloniaEm}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                label="Colonia"
                                                                inputProps={{
                                                                    name: 'coloniaEm',
                                                                    id: 'coloniaEm',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {coloniaContacto ?
                                                                    coloniaContacto.map((colonia) => {
                                                                        return <option key={colonia.idcodigo} value={colonia.idcodigo} >{colonia.colonia}</option>
                                                                    }) : null
                                                                }
                                                                <option value="otra" >Otra colonia...</option>
                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.coloniaEm && props.errors.coloniaEm ? (props.errors.coloniaEm) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            label="Otra colonia"
                                                            id="otroEm"
                                                            name="otroEm"
                                                            // InputLabelProps={{
                                                            //     shrink: true,
                                                            // }}
                                                            value={props.values.otroEm}
                                                            onChange={(e) => {
                                                                let otracol = e.target.value;
                                                                props.setFieldValue("otroEm", otracol.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.otroEm && props.errors.otroEm ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.otroEm && props.errors.otroEm ? (
                                                                props.errors.otroEm
                                                            ) : null}

                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            required
                                                            fullWidth
                                                            id="calleEm"
                                                            label="Calle y número"
                                                            name="calleEm"
                                                            value={props.values.calleEm}
                                                            onChange={(e) => {
                                                                let callenum = e.target.value;
                                                                props.setFieldValue("calleEm", callenum.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.calleEm && props.errors.calleEm ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.calleEm && props.errors.calleEm ? (
                                                                props.errors.calleEm
                                                            ) : null}

                                                        />
                                                    </Grid>
                                                </> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                        item
                                                        xs={12}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            required
                                                            fullWidth
                                                            id="domicilio"
                                                            label="Domicilio completo"
                                                            name="domicilio"
                                                            value={props.values.domicilio}
                                                            onChange={(e) => {
                                                                let domi = e.target.value;
                                                                props.setFieldValue("domicilio", domi.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.domicilio && props.errors.domicilio ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.domicilio && props.errors.domicilio ? (
                                                                props.errors.domicilio
                                                            ) : null}

                                                        />
                                                    </Grid>
                                                }

                                            </Grid>
                                            <br />

                                        </Paper>

                                    </Grid>


                                {/* </Grid> */}
                                <br />
                                <br />
                                <Grid container>
                                    <Grid item xs={12} sm={4}></Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.boton}>
                                            Guardar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}></Grid>
                                </Grid>
                                <br />
                            </form>
                        )
                    }}
                </Formik>

            }

        </div >
    );
}

export default ActualizarDatosGenerales;