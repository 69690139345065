// Desarrollo
//export const Global = 'http://localhost:4002/'
//export const Local = 'http://localhost:3000'

//Dev 
//export const Global = 'http://34.123.62.89:4002/'
//export const Local = 'http://34.71.53.22:3000'

//QA
//export const Global = 'https://api.uach.badak.io/'
//export const Local = 'https://uach.badak.io'

//Producción
//export const Global = 'https://api.chapingo.posgrado.mx/'
//export const Local = 'https://chapingo.posgrado.mx/'

//Tomadas de archivos .env
export const Global = process.env.REACT_APP_API_URL;
export const Local = process.env.REACT_APP_URL;

///////Tipos de archivos
export const FILE_SIZE = 2000000;

export const SUPPORTED_IMG = [
    "image/jpg",
    "image/jpeg",
    "image/png"
]

export const SUPPORTED_PDF = [
    "application/pdf"
];