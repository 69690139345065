import React, { useEffect, useState } from 'react'
import { Avatar, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { FILE_SIZE, SUPPORTED_PDF } from "../../../constants/global"
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FORMATOFECHAMAXHOY } from '../../generales/Fecha';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },
}));

const FormEscolaridad = () => {
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);
    const [Instituciones, setInstituciones] = useState([])
    const [Paises, setPaises] = useState([])
    const [persona, setPersona] = useState('')
    const [escolaridadAnterior, setEscolaridadAnterior] = useState([])
    //Subidas
    //Titulo_Licenciatura_Maestria
    const [, setUploadTitulo] = useState(0)
    const [, setUploadDiploma] = useState(0)
    //Lista_Materias_Licenciatura_Maestria
    const [, setUploadListaMateriasLicenciatura] = useState(0)
    const [, setUploadListaMateriasMaest] = useState(0)
    //Cedula_Maestria_Licenciatura
    const [, setUploadCedulaMaestria] = useState(0)
    const [, setUploadCedLic] = useState(0)
    //Identificación
    const [, setUploadIdentificacionOficial] = useState(0)
    //Mensajes
    const [, setTitulomensaje] = useState(null)
    const [Titulomensaje2, setTitulomensaje2] = useState(null)

    const [, setListaMateriasMaestMensaje] = useState(null)
    const [ListaMateriasMaestMensaje2, setListaMateriasMaestMensaje2] = useState (null)
   //Licenciatura
    const [, setListaMateriasLicenciaturamensaje] = useState(null)
    const [ListaMateriasLicenciaturamensaje2, setListaMateriasLicenciaturamensaje2] = useState(null)

    const [, setDiplomamensaje] = useState(null)
    const [Diplomamensaje2, setDiplomamensaje2] = useState(null)
    //Cedula_Licenciatura
    const [, setCedLicmensaje] = useState(null)
    const [CedLicmensaje2, setCedLicmensaje2] = useState(null)
    //Cedula_Maestria
    const [, setCedulaMaestriamensaje] = useState(null)
    const [CedulaMaestriamensaje2, setCedulaMaestriamensaje2] = useState(null)
    //Identificacion
    const [, setIdentificacionOficialmensaje] = useState(null)
    const [IdentificacionOficialmensaje2, setIdentificacionOficialmensaje2] = useState(null)
    //Archivos
    const [fileTitulo, setFileTitulo] = useState(0);
    //Licenciatura
    const [fileListaMateriasLicenciatura, setFileListaMateriasLicenciatura] = useState(0);
    const [fileDiploma, setFileDiploma] = useState(0);
    const [fileListaMateriasMaest, setFileListaMateriasMaest] = useState(0);
    const [fileCedLic, setFileCedLic] = useState(0);
    const [fileCedulaMaestria, setFileCedulaMaestria] = useState(0);
    const [fileIdentificacionOficial, setFileIdentificacionOficial] = useState(0);
    
    const classes = useStyles();
    const year = new Date().getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);
    const [recarga, setRecarga] = useState(true);
    const [otra, setOtra] = useState(true);
    const [gradoSeleccionado, setGradoSeleccionado] = useState( escolaridadAnterior.grado);

    useEffect(() => {
        setEscolaridadAnterior({
            grado: '',
            institucion: '',
            ins: '',
            estado: '',
            pais: '',
            fechain: '',
            fechafin: '',
            promedio: '',
            cedula: '',
            titulo: '',
            toefel: '',
            ced: '',
            lmma: '',
            lm: '',
            prt: '',
            dip: '',
            titulacion: '',
            cedulaMaestria:'',
        })
        
        axios.get(Global + "institucion", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setInstituciones(response.data)
        }).catch(error => console.log(error))

        axios.get(Global + "pais", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setPaises(response.data)
        }).catch(error => console.log(error))

        //REVISAR SI EXITE YA ESCOLARIDAD DE ESTA PERSONA 
        var user = fba.auth().currentUser;
        axios.get(Global + "persona/correo/" + user.email, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            setPersona(response.data)
            axios.get(Global + "escolaridad/personas/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async responses => {
                //SI RESPONSES ESTA BACIO ES PORQ NO EXISTE UN REGISTRO
                if (responses.data[0]) {
                    
                    responses.data[0].institucion = responses.data[0].institucion.idins
                    
                    setEscolaridadAnterior(responses.data[0])
                    if (responses.data[0].institucion === 1) {
                        setOtra(false)
                    }
                    if (responses.data[0].titl) {
                    
                        let storage = fba.storage().ref();
                        let httpsReference = storage.child('' + responses.data[0].titl)
                        setTitulomensaje2(await httpsReference.getDownloadURL());
                        setUploadTitulo(100)
                        setTitulomensaje('Archivo cargado')
                    }
                    if (responses.data[0].toefel) {
                        let storage2 = fba.storage().ref();
                        let httpsReference2 = storage2.child('' + responses.data[0].toefel)
                        setListaMateriasLicenciaturamensaje2(await httpsReference2.getDownloadURL());
                        setUploadListaMateriasLicenciatura(100)
                        setListaMateriasLicenciaturamensaje('Archivo cargado')
                    }
                    
                    if (responses.data[0].dip) {
                        let storage3 = fba.storage().ref();
                        let httpsReference3 = storage3.child('' + responses.data[0].dip)
                        setDiplomamensaje2(await httpsReference3.getDownloadURL());
                        setUploadDiploma(100)
                        setDiplomamensaje('Archivo cargado')
                    }
                    
                    if (responses.data[0].lmma) {
                        let storage4 = fba.storage().ref();
                        let httpsReference4 = storage4.child('' + responses.data[0].lmma)
                        setListaMateriasMaestMensaje2(await httpsReference4.getDownloadURL());
                        setUploadListaMateriasMaest(100)
                        setListaMateriasMaestMensaje('Archivo cargado')
                    }

                    if (responses.data[0].ced) {
                        let storage5 = fba.storage().ref();
                        let httpsReference5 = storage5.child('' + responses.data[0].ced)
                        setCedLicmensaje2(await httpsReference5.getDownloadURL());
                        setUploadCedLic(100)
                        setCedLicmensaje('Archivo cargado')
                    }

                    if (responses.data[0].prt) {
                        let storage6 = fba.storage().ref();
                        let httpsReference6 = storage6.child('' + responses.data[0].prt)
                        setCedulaMaestriamensaje2(await httpsReference6.getDownloadURL());
                        setUploadCedulaMaestria(100)
                        setCedLicmensaje('Archivo cargado')
                    }
                    if (responses.data[0].lm) {
                        let storage7 = fba.storage().ref();
                        let httpsReference7 = storage7.child('' + responses.data[0].lm)
                        setIdentificacionOficialmensaje2(await httpsReference7.getDownloadURL());
                        setUploadIdentificacionOficial(100)
                        setIdentificacionOficialmensaje('Archivo cargado')
                    }

                }
            }).catch(error => console.log(error))

        }).catch(error => console.log(error))
        handleClose()
    }, [recarga])

    //Schema de validacion
    const schemaValidacion = Yup.object({
        grado: Yup.string().required('Es obligatorio ingresar el grado de estudio'),
        institucion: Yup.string().required('Es obligatorio seleccionar una institucion'),
        estado: Yup.string().required('Es obligatorio ingresar el estado'),
        pais: Yup.string().required('Es obligatorio seleccionar el pais'),
        fechain: Yup.string().required('Es obligatorio seleccionar el año'),
        fechafin: Yup.string().required('Es obligatorio selecionar el año').test('match',
            'La fecha de fin no debe ser menor a la fecha de inicio',
            function (fechafin) {
                if (fechafin !== "")
                    return fechafin >= this.parent.fechain
            }),
        promedio: Yup.number().positive('El promedio debe ser un valor numerico postitivo')
            .required('Es obligatorio ingresar el promedio')
            .max(100.00, 'El promedio debe ser maximo de 100').min(80.00, 'El promedio minimo es de 80.00'),                
        titulo: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileTitulo === 0 || SUPPORTED_PDF.includes(fileTitulo.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileTitulo === 0 || fileTitulo.size <= FILE_SIZE))
            .test( "fileRequired", "El documento es obligatorio",
              () =>(fileTitulo !== false && fileTitulo !== 0) || Titulomensaje2 !== null
            ),
        lm: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileIdentificacionOficial === 0 || SUPPORTED_PDF.includes(fileIdentificacionOficial.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileIdentificacionOficial === 0 || fileIdentificacionOficial.size <= FILE_SIZE))
            .test( "fileRequired", "El documento es obligatorio",
              () =>(fileIdentificacionOficial !== false && fileIdentificacionOficial !== 0) || IdentificacionOficialmensaje2 !== null
            ),
        toefel: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileListaMateriasLicenciatura === 0 || SUPPORTED_PDF.includes(fileListaMateriasLicenciatura.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileListaMateriasLicenciatura === 0 || fileListaMateriasLicenciatura.size <= FILE_SIZE))
            .test( "fileRequired","El documento es obligatorio",
              () => (fileListaMateriasLicenciatura !== false && fileListaMateriasLicenciatura !== 0) || ListaMateriasLicenciaturamensaje2 !== null
            ),
        ced: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileCedLic === 0 || SUPPORTED_PDF.includes(fileCedLic.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileCedLic === 0 || fileCedLic.size <= FILE_SIZE))
            .test( "fileRequired","El documento es obligatorio",
                () => (fileCedLic !== false && fileCedLic !== 0) || CedLicmensaje2 !== null
            ),

        lmma: Yup.mixed()
                 .test('fileType', "El formato del archivo es incompatible",
                     () => (fileListaMateriasMaest === 0 || SUPPORTED_PDF.includes(fileListaMateriasMaest.type)))
                 .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                 () => (fileListaMateriasMaest === 0 || fileListaMateriasMaest.size <= FILE_SIZE))
                 .test("fileRequired", "El documento es obligatorio", () => {
                  if (gradoSeleccionado === "Maestria") {
                    if ( fileListaMateriasMaest !== 0 && fileListaMateriasMaest !== false) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }),   

        dip: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileDiploma === 0 || SUPPORTED_PDF.includes(fileDiploma.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileDiploma === 0 || fileDiploma.size <= FILE_SIZE))
            .test("fileRequired", "El documento es obligatorio", () => {
              if (gradoSeleccionado === "Maestria") {
                if (fileDiploma !== 0 && fileDiploma !== false) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }),

        prt: Yup.mixed()
        .test('fileType', "El formato del archivo es incompatible",
            () => (fileCedulaMaestria === 0 || SUPPORTED_PDF.includes(fileCedulaMaestria.type)))
        .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
            () => (fileCedulaMaestria === 0 || fileCedulaMaestria.size <= FILE_SIZE))
        .test("fileRequired", "El documento es obligatorio", () => {
            if (gradoSeleccionado === "Maestria") {
            if (fileCedulaMaestria !== 0 && fileCedulaMaestria !== false) {
                return true;
            } else {
                return false;
            }
            } else {
            return true;
            }
        }),
           
        titulacion: '',

    })

    const initial = escolaridadAnterior
  
    const funcionSubmit = valores => {
        //mensaje de carga de archivos
        var linkTitulo = ""
        var linkListaMateriasLicenciatura = ""
        var linkDiploma = ""
        var linkListaMateriasMaestria = ""
        var linkCedLic = ""
        var linkCedulaMaestria = ""
        var linkIdentificacionOficial = ""

        if (escolaridadAnterior.grado === '') {
            if (fileTitulo) {
                linkTitulo = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileTitulo.name
                const storageRef = fba.storage().ref(linkTitulo)
                const task = storageRef.put(fileTitulo)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadTitulo(Math.round(porcentage))
                    setTitulomensaje(Math.round(porcentage) + '%')
                }, (error) => {
                    setTitulomensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (fileListaMateriasLicenciatura) {
                linkListaMateriasLicenciatura = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileListaMateriasLicenciatura.name
                const storageRef2 = fba.storage().ref(linkListaMateriasLicenciatura)
                const task2 = storageRef2.put(fileListaMateriasLicenciatura)
                task2.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadListaMateriasLicenciatura(Math.round(porcentage))
                    setListaMateriasMaestMensaje(Math.round(porcentage) + '%')
                }, (error) => {
                    setListaMateriasMaestMensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                });
            }

            if (fileDiploma) {
              linkDiploma = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileDiploma.name
              const storageRef3 = fba.storage().ref(linkDiploma)
              const task3 = storageRef3.put(fileDiploma)
              task3.on('state_changed', (snapshot) => {
                  let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  setUploadDiploma(Math.round(porcentage))
                  setDiplomamensaje(Math.round(porcentage) + '%')
              }, (error) => {
                  setDiplomamensaje('Error en la carga del archivo ' + error.message)
              }, () => {
                  //setTitulomensaje('Archivo listo')
              });
          }
        if (fileListaMateriasMaest) {
            linkListaMateriasMaestria = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileListaMateriasMaest.name
            const storageRef4 = fba.storage().ref(linkListaMateriasMaestria)
            const task4 = storageRef4.put(fileListaMateriasMaest)
            task4.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadListaMateriasMaest(Math.round(porcentage))
                setListaMateriasMaestMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setListaMateriasMaestMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            });
        }
        if (fileCedLic) {
            linkCedLic = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileCedLic.name
            const storageRef5 = fba.storage().ref(linkCedLic)
            const task5 = storageRef5.put(fileCedLic)
            task5.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadCedLic(Math.round(porcentage))
                setCedLicmensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setCedLicmensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            });
        }
        if (fileCedulaMaestria) {
            linkCedulaMaestria = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileCedulaMaestria.name
            const storageRef6 = fba.storage().ref(linkCedulaMaestria)
            const task6 = storageRef6.put(fileCedulaMaestria)
            task6.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadCedulaMaestria(Math.round(porcentage))
                setCedulaMaestriamensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setCedulaMaestriamensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            });
        }
        if (fileIdentificacionOficial) {
            linkIdentificacionOficial = persona[0].correo + "/DocumentosEscolaridad/" + uuidv4() + fileIdentificacionOficial.name
            const storageRef7 = fba.storage().ref(linkIdentificacionOficial)
            const task7 = storageRef7.put(fileIdentificacionOficial)
            task7.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadIdentificacionOficial(Math.round(porcentage))
                setIdentificacionOficialmensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setIdentificacionOficialmensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            });
        }

           

            let escolaridad = {
                cedula: valores.cedula,
                estado: valores.estado,
                fechafin: valores.fechafin,
                fechain: valores.fechain,
                grado: valores.grado,
                ins: valores.ins,
                toefel: linkListaMateriasLicenciatura,
                ced: linkCedLic,
                prt: linkCedulaMaestria,
                lmma: linkListaMateriasMaestria,
                lm: linkIdentificacionOficial,
                pais: valores.pais,
                promedio: valores.promedio,
                titl: linkTitulo,
                dip: linkDiploma,
                titulacion: valores.titulacion,
                cedulaMaestria:valores.cedulaMaestria,
            };
            
            if (!otra) {
                let instituto = {
                    nombre: valores.ins,
                    giro: "",
                    pagweb: "",
                };
                axios.post(Global + "institucion/paisNombre/" + valores.pais, instituto, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    
                    if (response.data.idins !== null) {
                        axios.post(Global + "escolaridad/institucion/" + response.data.idins + "/persona/" + persona[0].idP, escolaridad, {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            Swal({
                                title: 'Datos registrados',
                                text: ' ',
                                icon: 'success',
                                button: false,
                                timer: 1000
                            })
                        }).catch(error => {
                            console.log(error);
                        })
                    } else {
                        Swal({
                            title: 'Error en la institución revise el catalogo o vuelva a interntar',
                            text: ' ',
                            icon: 'error',
                            button: false,
                            timer: 1000
                        })
                    }
                }).catch(error => {
                    console.log(error)
                })
            } else {
                axios.post(Global + "escolaridad/institucion/" + valores.institucion + "/persona/" + persona[0].idP, escolaridad, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                }).catch(error => {
                    console.log(error);
                })
            }


        } else {
            linkTitulo = escolaridadAnterior.titl
            linkListaMateriasLicenciatura = escolaridadAnterior.toefel
            linkDiploma = escolaridadAnterior.dip
            linkCedLic = escolaridadAnterior.ced
            linkCedulaMaestria = escolaridadAnterior.prt
            linkListaMateriasMaestria = escolaridadAnterior.lmma
            linkIdentificacionOficial = escolaridadAnterior.lm

            if (fileTitulo) {
                if (escolaridadAnterior.titl === '') {
                    
                    linkTitulo = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileTitulo.name
                }
                const storageRef = fba.storage().ref(linkTitulo)
                const task = storageRef.put(fileTitulo)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadTitulo(porcentage)
                    setTitulomensaje(porcentage + '%')
                }, (error) => {
                    setTitulomensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (fileCedLic) {
                if (escolaridadAnterior.ced === '') {
                    
                    linkCedLic = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileCedLic.name
                }
                const storageRef = fba.storage().ref(linkCedLic)
                const task = storageRef.put(fileCedLic)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCedLic(porcentage)
                    setCedLicmensaje(porcentage + '%')
                }, (error) => {
                    setCedLicmensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (fileCedulaMaestria) {
                if (escolaridadAnterior.prt === '') {
                    
                    linkCedulaMaestria = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileCedulaMaestria.name
                }
                const storageRef = fba.storage().ref(linkCedulaMaestria)
                const task = storageRef.put(fileCedulaMaestria)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCedulaMaestria(porcentage)
                    setCedulaMaestriamensaje(porcentage + '%')
                }, (error) => {
                    setCedulaMaestriamensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (fileListaMateriasLicenciatura) {
                if (escolaridadAnterior.toefel === '') {
                    
                    linkListaMateriasLicenciatura = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileListaMateriasLicenciatura.name
                }
                const storageRef = fba.storage().ref(linkListaMateriasLicenciatura)
                const task = storageRef.put(fileListaMateriasLicenciatura)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadListaMateriasLicenciatura(porcentage)
                    setListaMateriasLicenciaturamensaje(porcentage + '%')
                }, (error) => {
                    setListaMateriasLicenciaturamensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (fileListaMateriasMaest) {
                if (escolaridadAnterior.lmma === '') {
                    
                    linkListaMateriasMaestria = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileListaMateriasMaest.name
                }
                const storageRef = fba.storage().ref(linkListaMateriasMaestria)
                const task = storageRef.put(fileListaMateriasMaest)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadListaMateriasMaest(porcentage)
                    setListaMateriasMaestMensaje(porcentage + '%')
                }, (error) => {
                    setListaMateriasMaestMensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }

            if (fileDiploma) {
                if (escolaridadAnterior.dip === '') {
                    
                    linkDiploma = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileDiploma.name
                }
                const storageRef = fba.storage().ref(linkDiploma)
                const task = storageRef.put(fileDiploma)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadDiploma(porcentage)
                    setDiplomamensaje(porcentage + '%')
                }, (error) => {
                    setDiplomamensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (fileIdentificacionOficial) {
                if (escolaridadAnterior.lm === '') {
                    
                    linkIdentificacionOficial = persona[0].correo + '/DocumentosEscolaridad/' + uuidv4() + fileIdentificacionOficial.name
                }
                const storageRef = fba.storage().ref(linkIdentificacionOficial)
                const task = storageRef.put(fileIdentificacionOficial)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadIdentificacionOficial(porcentage)
                    setIdentificacionOficialmensaje(porcentage + '%')
                }, (error) => {
                    setIdentificacionOficialmensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
            }
            if (!otra) {

                let instituto = {
                    nombre: valores.ins,
                    giro: "",
                    pagweb: "",
                };
                axios.post(Global + "institucion/paisNombre/" + valores.pais, instituto, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    let escolaridad = {
                        cedula: valores.cedula,
                        estado: valores.estado,
                        fechafin: valores.fechafin,
                        fechain: valores.fechain,
                        grado: valores.grado,
                        ins: valores.ins,
                        pais: valores.pais,
                        promedio: valores.promedio,
                        titulacion: valores.titulacion,
                        cedulaMaestria:valores.cedulaMaestria,
                        institucion: {
                            idins: response.data.idins
                        },
                        dip: linkDiploma,
                        lm: linkIdentificacionOficial,
                        titl: linkTitulo,
                        toefel: linkListaMateriasLicenciatura,
                        ced: linkCedLic,
                        prt: linkCedulaMaestria,
                        lmma:linkListaMateriasMaestria,
                    };
                    console.log('entrar a escolaridad',escolaridad)
                    axios.put(Global + "escolaridad/" + escolaridadAnterior.idescolaridad, escolaridad, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        Swal({
                            title: 'Datos registrados',
                            text: ' ',
                            icon: 'success',
                            button: false,
                            timer: 1000
                        })
                    }).catch(error => {
                        console.log(error);
                    })
                })
            } else {
                let escolaridad = {
                    cedula: valores.cedula,
                    estado: valores.estado,
                    fechafin: valores.fechafin,
                    fechain: valores.fechain,
                    grado: valores.grado,
                    ins: valores.ins,
                    toefel: linkListaMateriasLicenciatura,
                    ced: linkCedLic,
                    prt: linkCedulaMaestria,
                    lmma:linkListaMateriasMaestria,
                    pais: valores.pais,
                    promedio: valores.promedio,
                    titl: linkTitulo,
                    institucion: {
                        idins: valores.institucion
                    },
                    dip: linkDiploma,
                    lm: linkIdentificacionOficial,
                    titulacion: valores.titulacion,
                    cedulaMaestria:valores.cedulaMaestria
                };
                
                axios.put(Global + "escolaridad/" + escolaridadAnterior.idescolaridad, escolaridad, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                }).catch(error => {
                    console.log(error);
                })
            }
        }
        setRecarga(!recarga)
      
    }

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]

            switch (event.target.id) {
                case "titulo":
                    setFileTitulo(file)
                    setUploadTitulo(0)
                    setTitulomensaje('')
                    break;
                case "toefel":
                    setFileListaMateriasLicenciatura(file)
                    setUploadListaMateriasLicenciatura(0)
                    setListaMateriasLicenciaturamensaje('')
                    break;
                case "lmma":
                    setFileListaMateriasMaest(file)
                    setUploadListaMateriasMaest(0)
                    setListaMateriasMaestMensaje('')
                    break; 
                case "ced":
                        setFileCedLic(file)
                        setUploadCedLic(0)
                        setCedLicmensaje('')
                        break;  
                case "prt":
                    setFileCedulaMaestria(file)
                    setUploadCedulaMaestria(0)
                    setCedulaMaestriamensaje('')
                    break;           
                case "dip":
                    setFileDiploma(file)
                    setUploadDiploma(0)
                    setDiplomamensaje('')
                    break;
                case "lm":
                    setFileIdentificacionOficial(file)
                    setUploadIdentificacionOficial(0)
                    setIdentificacionOficialmensaje('')
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "titulo":
                    setFileTitulo(false)
                    break;
                case "toefel":
                    setFileListaMateriasLicenciatura(false)
                    break;
                case "lmma":
                    setFileListaMateriasMaest(false)
                    break;
                case "ced":
                    setFileCedLic(false)
                    break;
                case "prt":
                    setFileCedulaMaestria(false)
                    break;  
                case "dip":
                    setFileDiploma(false)
                    break;
                case "lm":
                    setFileIdentificacionOficial(false)
                    break;
                default:
                    break;
            }
        }
    }

    const handleUnabledIns = (e) => {
        e.target.value === "OTRA" ? setOtra(false) : setOtra(true)
    }
    const handleChangeGrade = (e) => {
      setGradoSeleccionado(e.target.value);
    };
    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid
                    container
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <AccountBalanceIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Estudios realizados
                        </Typography>
                    </Grid>

                </Grid>

                <br />

                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.grado && props.errors.grado ? (true) : false}>
                                               <InputLabel id="grad">Grado Anterior</InputLabel>
                                                    <Select
                                                      labelId="grad"
                                                      native
                                                      value={props.values.grado}
                                                      onChange={(e) => {
                                                        handleChangeGrade(e);
                                                        props.setFieldValue("grado", e.target.value);
                                                      }}
                                                      onBlur={props.handleBlur}
                                                      label="Grado Anterior"
                                                      inputProps={{
                                                        name: "grado",
                                                        id: "grado",
                                                      }}
                                                    >
                                                      <option aria-label="None" value="" />
                                                      <option value={"Licenciatura"}>Licenciatura</option>
                                                      <option value={"Maestria"}>Maestria</option>
                                                    </Select>
                                                <FormHelperText>
                                                    {props.touched.grado && props.errors.grado ? (props.errors.grado) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={props.touched.institucion && props.errors.institucion ? (true) : false}>
                                                <InputLabel id="inst">Institución</InputLabel>
                                                <Select
                                                    native
                                                    labelId='inst'
                                                    value={props.values.institucion}
                                                    onChange={e => {
                                                        handleUnabledIns(e);
                                                        props.setFieldValue("institucion", e.target.value);

                                                    }}
                                                    onBlur={props.handleBlur}
                                                    label="Institucion"
                                                    inputProps={{
                                                        name: 'institucion',
                                                        id: 'institucion',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        Instituciones.map((instituto) => {
                                                            return <option key={instituto.idins} value={instituto.idins}>{instituto.nombre}</option>
                                                        })
                                                    }
                                                    <option value="OTRA">OTRA</option>

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Otra institución"
                                                name="ins"
                                                variant="outlined"
                                                disabled={otra}
                                                fullWidth
                                                value={props.values.ins}
                                                onChange={(e) => {
                                                    let otra = e.target.value;
                                                    props.setFieldValue("ins", otra.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.ins && props.errors.ins ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.ins && props.errors.ins ? (
                                                    props.errors.ins
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                required
                                                variant="outlined" fullWidth className={classes.formControl} 
                                                error={props.touched.pais && props.errors.pais ? (
                                                    true
                                                ) : false}>

                                                <Autocomplete
                                                    freeSolo
                                                    required
                                                    name='pais'
                                                    id='pais'
                                                    value={props.values.pais}                                                    
                                                    onChange={(event, newValue) => {
                                                        props.setFieldValue("pais", newValue);
                                                    }}
                                                    label="País"
                                                    options={Paises.map((pais) => pais.pais)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="País"
                                                            name='pais'
                                                            id='pais'
                                                            margin="normal"
                                                            variant="outlined"
                                                            onBlur={props.handleBlur}
                                                        />
                                                    )}
                                                >
                                                </Autocomplete>
                                                <FormHelperText>{props.touched.pais && props.errors.pais ? "El campo país es obligatorio" : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Estado o provincia"
                                                margin="normal"
                                                name="estado"
                                                id="estado"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={props.values.estado}
                                                onChange={(e) => {
                                                    let estado = e.target.value;
                                                    props.setFieldValue("estado", estado.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.estado && props.errors.estado ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.estado && props.errors.estado ? (
                                                    props.errors.estado
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>


                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required
                                                variant="outlined" fullWidth className={classes.formControl} error={props.touched.fechain && props.errors.fechain ? (
                                                    true
                                                ) : false}>
                                                <InputLabel >Año de inicio</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.fechain}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Año de inicio"
                                                    required
                                                    inputProps={{
                                                        name: 'fechain',
                                                        id: 'fechain',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        years.map((year, index) => {
                                                            return <option key={`year${index}`} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required
                                                variant="outlined" fullWidth className={classes.formControl} error={props.touched.fechafin && props.errors.fechafin ? (
                                                    true
                                                ) : false}>
                                                <InputLabel >Año de terminación</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.fechafin}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Año de terminacion"
                                                    required
                                                    inputProps={{
                                                        name: 'fechafin',
                                                        id: 'fechafin',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        years.map((year, index) => {
                                                            return <option key={`year${index}`} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.fechafin && props.errors.fechafin ? (
                                                    props.errors.fechafin
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Tooltip title="RANGO DE 80.00 a 100" aria-label="add">
                                                <TextField
                                                    label="Promedio"
                                                    name="promedio"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    //defaultValue={escolaridadAnterior[0].promedio}
                                                    value={props.values.promedio}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.promedio && props.errors.promedio ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.promedio && props.errors.promedio ? (
                                                        props.errors.promedio
                                                    ) : null}
                                                    type="number"
                                                    max="100"
                                                >
                                                </TextField>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Cédula de licenciatura"
                                                name="cedula"
                                                variant="outlined"                                                
                                                fullWidth
                                                value={props.values.cedula}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="titulacion"
                                                name="titulacion"
                                                label="Fecha de titulación"
                                                type="date"
                                                value={props.values.titulacion}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.titulacion && props.errors.titulacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.titulacion && props.errors.titulacion ? (
                                                    props.errors.titulacion
                                                ) : null}
                                                InputProps={FORMATOFECHAMAXHOY}
                                            /> 
                                        </Grid>
                                        {props.values.grado === 'Maestria' &&
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth                                                
                                                id="cedulaMaestria"
                                                name="cedulaMaestria"
                                                label="Cédula de maestría"                                                
                                                value={props.values.cedulaMaestria}
                                                className={classes.textField}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}                                               
                                            /> 
                                        </Grid>
                                        }
                                        <br />

                                    </Grid>
                                    <br />
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">Adjuntar documentación en formato PDF, tamaño menor a 2 MB.
                                                Todos los documentos son obligatorios y deberán ser legibles, de lo contrario no serán aceptados. </Typography>
                                            </React.Fragment>
                                            }
                                        >
                                        <Fab size="small" color="#f48124" className={classes.margin} >
                                            <HelpOutlineIcon />
                                        </Fab>
                                    </HtmlTooltip>
                                    <Grid container direction="column" item xs={12} sm={5}>

                                    </Grid>
                                     {/* identificación oficial*/}
                <Grid container direction="column" item xs={12} sm={5}>
                    <label htmlFor="lm">
                      <input
                        style={{ display: "none" }}
                        id="lm"
                        name="lm"
                        type="file"
                        accept="application/pdf"
                        onChange={handleOnChange}
                      />
                      <Button
                        className={classes.boton}
                        color="primary"
                        variant="contained"
                        component="span"
                        fullWidth
                      >
                        <FolderOpenIcon style={{ marginRight: 10 }} />
                        Identificación Oficial
                        {fileIdentificacionOficial ? (
                          <>
                            <CheckCircleIcon style={{ marginLeft: "10%" }} />
                          </>
                        ) : null}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        color="error"
                      >
                        {props.touched.lm && props.errors.lm && props.errors.lm}
                      </Typography>
                    </label>
                    <Grid item xs={3} sm={3}>
                      {/* <CircularProgress variant="determinate" value={uploadLista} max='100' ></CircularProgress> */}
                      <Typography variant="body2">
                        {IdentificacionOficialmensaje2 !== null && IdentificacionOficialmensaje2 !== "" ? (
                          <a
                            style={{ display: "table-cell" }}
                            href={IdentificacionOficialmensaje2}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver archivo
                          </a>
                        ) : null}
                      </Typography>
                    </Grid>
                 </Grid>
             {/* cedula profesional de maestria */}
                  {props.values.grado === "Maestria" && (
                    <Grid container direction="column" item xs={12} sm={5}>
                      <label htmlFor="prt">
                        <input
                          style={{ display: "none" }}
                          id="prt"
                          name="prt"
                          type="file"
                          accept="application/pdf"
                          onChange={handleOnChange}
                        />
                        <Button
                          className={classes.boton}
                          color="primary"
                          variant="contained"
                          component="span"
                          fullWidth
                        >
                          <FolderOpenIcon style={{ marginRight: 10 }} />
                          Cédula Profesional de Maestria
                          {fileCedulaMaestria ? (
                            <>
                              <CheckCircleIcon style={{ marginLeft: "10%" }} />
                            </>
                          ) : null}
                        </Button>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="error"
                        >
                          {props.touched.prt &&
                            props.errors.prt &&
                            props.errors.prt}
                        </Typography>
                      </label>
                      <Grid item xs={3} sm={3}>
                        {/* <CircularProgress variant="determinate" value={uploadLista} max='100' ></CircularProgress> */}
                        <Typography variant="body2">
                          {CedulaMaestriamensaje2 !== null &&
                           CedulaMaestriamensaje2 !== "" ? (
                            <a
                              style={{ display: "table-cell" }}
                              href={CedulaMaestriamensaje2}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Ver archivo
                            </a>
                          ) : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <br />
            {props.values.grado === "Maestria" && (
                    <Grid container direction="column" item xs={12} sm={5}>
                      <label htmlFor="dip">
                        <input
                          style={{ display: "none" }}
                          id="dip"
                          name="dip"
                          type="file"
                          accept="application/pdf"
                          onChange={handleOnChange}
                        />
                        <Button
                          className={classes.boton}
                          color="primary"
                          variant="contained"
                          component="span"
                          fullWidth
                        >
                          <FolderOpenIcon style={{ marginRight: 10 }} />
                          Título de maestría
                          {fileDiploma ? (
                            <>
                              <CheckCircleIcon style={{ marginLeft: "10%" }} />
                            </>
                          ) : null}
                        </Button>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="error"
                        >
                          {props.touched.dip &&
                            props.errors.dip &&
                            props.errors.dip}
                        </Typography>
                      </label>

                      <Grid item xs={3} sm={3}>
                        {/* <CircularProgress variant="determinate" value={uploadDiploma} max='100' ></CircularProgress> */}
                        <Typography variant="body2">
                          {Diplomamensaje2 !== null &&
                          Diplomamensaje2 !== "" ? (
                            <a
                              style={{ display: "table-cell" }}
                              href={Diplomamensaje2}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Ver archivo
                            </a>
                          ) : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {props.values.grado === "Maestria" && (
                    <Grid container direction="column" item xs={12} sm={5}>
                      <label htmlFor="lmma">
                        <input
                          style={{ display: "none" }}
                          id="lmma"
                          name="lmma"
                          type="file"
                          accept="application/pdf"
                          onChange={handleOnChange}
                        />
                        <Button
                          className={classes.boton}
                          color="primary"
                          variant="contained"
                          component="span"
                          fullWidth
                        >
                          <FolderOpenIcon style={{ marginRight: 10 }} />
                          Lista oficial de materias con promedio general de
                          maestria
                          {fileListaMateriasMaest ? (
                            <>
                              <CheckCircleIcon style={{ marginLeft: "10%" }} />
                            </>
                          ) : null}
                        </Button>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="error"
                        >
                          {props.touched.lmma &&
                            props.errors.lmma &&
                            props.errors.lmma}
                        </Typography>
                      </label>
                      <Grid item xs={3} sm={3}>
                        {/* <CircularProgress variant="determinate" value={uploadLista} max='100' ></CircularProgress> */}
                        <Typography variant="body2">
                          {ListaMateriasMaestMensaje2 !== null &&
                          ListaMateriasMaestMensaje2 !== "" ? (
                            <a
                              style={{ display: "table-cell" }}
                              href={ListaMateriasMaestMensaje2}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Ver archivo
                            </a>
                          ) : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <br />  {/* cedula profesional de licenciatura */}
                <Grid container direction="column" item xs={12} sm={5}>
                    <label htmlFor="ced">
                      <input
                        style={{ display: "none" }}
                        id="ced"
                        name="ced"
                        type="file"
                        accept="application/pdf"
                        onChange={handleOnChange}
                      />
                      <Button
                        className={classes.boton}
                        color="primary"
                        variant="contained"
                        component="span"
                        fullWidth
                      >
                        <FolderOpenIcon style={{ marginRight: 10 }} />
                        Cédula Profesional de Licenciatura
                        {fileCedLic ? (
                          <>
                            <CheckCircleIcon style={{ marginLeft: "10%" }} />
                          </>
                        ) : null}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        color="error"
                      >
                        {props.touched.ced && props.errors.ced && props.errors.ced}
                      </Typography>
                    </label>
                    <Grid item xs={3} sm={3}>
                      {/* <CircularProgress variant="determinate" value={uploadLista} max='100' ></CircularProgress> */}
                      <Typography variant="body2">
                        {CedLicmensaje2 !== null && CedLicmensaje2 !== "" ? (
                          <a
                            style={{ display: "table-cell" }}
                            href={CedLicmensaje2}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver archivo
                          </a>
                        ) : null}
                      </Typography>
                    </Grid>
                 </Grid>
                  <br />

                  <Grid container direction="column" item xs={12} sm={5}>
                    <Tooltip
                      title="Título de licenciatura: o en su caso, una carta compromiso del director de tesis informando que presentará su examen profesional o de grado antes de la fecha de inscripción al posgrado"
                      aria-label="tit"
                    >
                      <label htmlFor="titulo">
                        <input
                          style={{ display: "none" }}
                          id="titulo"
                          name="titulo"
                          type="file"
                          accept="application/pdf"
                          onChange={handleOnChange}
                        />
                        <Button
                          className={classes.boton}
                          color="primary"
                          variant="contained"
                          component="span"
                          fullWidth
                        >
                          <FolderOpenIcon style={{ marginRight: 10 }} />
                          Título de licenciatura
                          {fileTitulo ? (
                            <>
                              <CheckCircleIcon style={{ marginLeft: "10%" }} />
                            </>
                          ) : null}
                        </Button>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="error"
                        >
                          {props.touched.titulo &&
                            props.errors.titulo &&
                            props.errors.titulo}
                        </Typography>
                      </label>
                    </Tooltip>

                    <Grid item xs={3} sm={3}>
                      {/* <CircularProgress variant="determinate" value={uploadTitulo} max='100'></CircularProgress> */}
                      <Typography variant="body2">
                        {Titulomensaje2 !== null && Titulomensaje2 !== "" ? (
                          <a
                            style={{ display: "table-cell" }}
                            href={Titulomensaje2}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver archivo
                          </a>
                        ) : null}
                      </Typography>
                    </Grid>
                  </Grid>

                  <br />
                  <Grid container direction="column" item xs={12} sm={5}>
                    <label htmlFor="toefel">
                      <input
                        style={{ display: "none" }}
                        id="toefel"
                        name="toefel"
                        type="file"
                        accept="application/pdf"
                        onChange={handleOnChange}
                      />
                      <Button
                        className={classes.boton}
                        color="primary"
                        variant="contained"
                        component="span"
                        fullWidth
                      >
                        <FolderOpenIcon style={{ marginRight: 10 }} />
                        Lista oficial de materias con promedio general de
                        licenciatura
                        {fileListaMateriasLicenciatura ? (
                          <>
                            <CheckCircleIcon style={{ marginLeft: "10%" }} />
                          </>
                        ) : null}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        color="error"
                      >
                        {props.touched.toefel && props.errors.toefel && props.errors.toefel}
                      </Typography>
                    </label>
                    <Grid item xs={3} sm={3}>
                      {/* <CircularProgress variant="determinate" value={uploadLista} max='100' ></CircularProgress> */}
                      <Typography variant="body2">
                        {ListaMateriasLicenciaturamensaje2 !== null && ListaMateriasLicenciaturamensaje2 !== "" ? (
                          <a
                            style={{ display: "table-cell" }}
                            href={ListaMateriasLicenciaturamensaje2}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver archivo
                          </a>
                        ) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.boton}
                      >
                        Guardar
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        }
      </Paper>
    </div>
  );
};

export default FormEscolaridad;
