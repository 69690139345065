import React, { useEffect, useState } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import { FORMATOFECHAMINHOY } from '../../generales/Fecha';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AgregarAdscripcion = (props) => {

    const classes = useStyles();
    const [programas, setProgramas] = React.useState([])
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [recarga, setRecarga] = useState(false)
    const [carga, setCarga] = useState(false)

    useEffect(() => {

        const datosIniciales = async () => {
            setRegistroAnterior({
                programa: '',
                estatus: '',
                fechabaj: '',
                fechain: '',
                nucleo: '',
            })
            await axios.get(Global + "programa/estatus/alta/profesor/" + props.data.idprofesor, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setProgramas(response.data)
            }).catch(error => console.log(error))
            handleClose()
        }

        datosIniciales()
    }, [recarga])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        programa: Yup.string().required('Es obligatorio seleccionar el programa'),
        fechain: Yup.string().required('Es obligatorio ingresar la fecha de inicio'),
        nucleo: Yup.string().required('Es obligatorio seleccionar si es de nucleo'),

    })

    const initial = registroAnterior

    const funcionSubmit = async valores => {
        setCarga(true)

        let programa = {
            estatus: 'alta',
            fechabaj: '',
            fechain: valores.fechain,
            nucleo: valores.nucleo,
        };

        await axios.post(Global + "programaprof/programa/" + valores.programa + "/profesor/" + props.data.idprofesor, programa, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(() => {
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            setCarga(false)

        }).catch(error => {
            console.log(error);
            setCarga(false)

        })
        props.metodo()
    }
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>

                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.programa && props.errors.programa ? (true) : false}>
                                                <InputLabel>Programa</InputLabel>
                                                <Select
                                                    labelId="Programa"
                                                    native
                                                    // value={props.values.ubpp}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="programa"
                                                    inputProps={{
                                                        name: 'programa',
                                                        id: 'programa',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        programas.map((progra) => {
                                                            return <option key={progra.idprograma} value={progra.idprograma}>{progra.programa}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.programa && props.errors.programa ? (props.errors.programa) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Fecha de ingreso"
                                                    name="fechain"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // value={props.values.fechain}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.fechain && props.errors.fechain ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.fechain && props.errors.fechain ? (
                                                        props.errors.fechain
                                                    ) : null}
                                                    type="date"
                                                    max="100"
                                                    InputProps={FORMATOFECHAMINHOY}
                                                />                                                
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.nucleo && props.errors.nucleo ? (true) : false}>
                                                <InputLabel>Núcleo</InputLabel>
                                                <Select
                                                    labelId="nucleo"
                                                    native
                                                    // value={props.values.ubpp}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="nucleo"
                                                    inputProps={{
                                                        name: 'nucleo',
                                                        id: 'nucleo',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option aria-label="None" value="Si" >Si</option>
                                                    <option aria-label="None" value="No" >No</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.nucleo && props.errors.nucleo ? (props.errors.nucleo) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>

                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                props.metodo();
                            }}>
                            Cancelar
                                            </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>
            </Paper >
        </div >
    );
}

export default AgregarAdscripcion;