import { Grid, Paper, Breadcrumbs, Link } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import { makeStyles } from "@material-ui/core/styles";
import Tabla from "./TablaCitaExam";
import { Auth } from "../../../context/AuthContext";

import HomeIcon from "@material-ui/icons/Home";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import EventIcon from "@material-ui/icons/Event";
import TablaCitaExam from "./TablaCitaExam";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
  },
  titulo: {
    fontSize: "2em",
    lineHeight: "1em",
    color: "#226af8",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    textTransform: "capitalize",
    textAlign: "center",
  },
  subtitulo: {
    fontSize: "25px",
    color: "#54595F",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },
  campo: {
    background: theme.palette.text.main,
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const AsignarCitaExam = ({ history }) => {
  const classes = useStyles();
  const { usuario } = useContext(Auth);

  useEffect(() => {
    if (usuario === null) {
      history.push("/siip");
    }
  });
  return (
    <div className={classes.root}>
      <Head />
      <br />
      <Paper elevation={3} className={classes.paperContainer}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link href="/inicio" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Inicio
          </Link>
          <Link aria-current="page" className={classes.link}>
            <HowToRegIcon className={classes.icon} />
            Alumnos
          </Link>
          <Link
            href="/asignarCitaExam"
            aria-current="page"
            className={classes.link}
          >
            <EventIcon className={classes.icon} />
            Cita para Examen Profesional
          </Link>
        </Breadcrumbs>
        <br />

        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TablaCitaExam />
          </Grid>
        </Grid>
        <Grid container spacing={2}></Grid>
      </Paper>
      <Pie />
    </div>
  );
};
export default AsignarCitaExam;
