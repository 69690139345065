import React, { useEffect, useState, useContext } from 'react'
import { Avatar, Paper, Typography, Button, TextField, Grid, FormControl, InputLabel, Select, FormHelperText, CircularProgress } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik"
import * as Yup from 'yup'
import axios from "axios";
import { Global } from "../../../constants/global"
import Swal from 'sweetalert'
import Backdrop from '@material-ui/core/Backdrop';
import BlockIcon from '@material-ui/icons/Block';
import { fba } from "../../../firebaseConfig";
import { Auth } from "../../../context/AuthContext";
import { red, green, yellow } from '@material-ui/core/colors'
import { FORMATOFECHAMINHOY } from '../../generales/Fecha';

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    red: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    green: {
        backgroundColor: green[500],
    },
    yellow: {
        color: theme.palette.getContrastText(yellow[500]),
        backgroundColor: yellow[500],
    },
}));

function Baja(props) {

    const classes = useStyles();

    const { idalumno } = props.data;

    const [datos, setDatos] = useState([])
    const [baja, setBaja] = useState([])
    const [servicio, setServicio] = useState(false);
    const [open, setOpen] = useState(true);
    const [recarga, setRecarga] = useState(false);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const [acceso, setAcceso] = useState(true)
    const [carga, setCarga] = useState(false)
    const [semaforo, setSemaforo] = useState("")
    const db = firestore.collection('usuarios');

    useEffect(() => {

        const datos = async () => {

            let baja = {
                idbaja: "",
                condicion: "",
                fechabaja: "",
                fecharei: "",
                motivo: "",
                observacion: "",
                tipobaja: "",
            }

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            doc.data().rol === "administrador" ||
                                doc.data().rol === "coordinador_general" ||
                                doc.data().rol === "coordinador_posgrado"
                                ? setAcceso(false) : setAcceso(true)
                        } else {
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "motivobaja", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setBaja(response.data)
            }).catch(error => console.log(error))


            await axios.get(Global + "alumnobaja/alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    setServicio(true)
                    baja.idbaja = response.data[0].idbaj
                    baja.condicion = response.data[0].condicion
                    baja.fechabaja = response.data[0].fechabaja
                    response.data[0].fecharei === null ? baja.fecharei = "" : baja.fecharei = response.data[0].fecharei
                    baja.motivo = response.data[0].motivoBaja.idmotivo
                    baja.observacion = response.data[0].observacion
                    baja.tipobaja = response.data[0].tipobaja
                }
            }).catch(error => console.log(error))

            await axios.get(Global + "alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // alert("Entra")
                if (response.data.estatus === "VG") {
                    setSemaforo("green")
                } else if (response.data.estatus === "BT") {
                    setSemaforo("yellow")
                }
                else if (response.data.estatus === "BD") {
                    setSemaforo("red")
                }
                else if (response.data.estatus === "VG") {
                    setSemaforo("green2")
                }
            }).catch(error => console.log(error))

            // const result = compareAsc(new Date("2021-04-22"), new Date("2021-04-20"))
            // console.log(result)
            setDatos(baja)
            setOpen(false)
        }

        datos()

    }, [recarga])



    const schemaValidation = Yup.object({
        condicion: Yup.string(),
        fechabaja: Yup.string().required('La fecha de baja es obligatoria'),
        fecharei: Yup.string(),
        motivo: Yup.string(),
        observacion: Yup.string(),
        tipobaja: Yup.string().required('Es obligatorio ingresar el tipo de baja'),
    })

    const functionSubmit = async (valores) => {

        setCarga(true)

        let bajaO = {
            alumno: {
                idalumno: idalumno
            },
            condicion: valores.condicion,
            fechabaja: valores.fechabaja,
            fecharei: valores.fecharei,
            motivoBaja: {
                idmotivo: valores.motivo
            },
            observacion: valores.observacion,
            tipobaja: valores.tipobaja,
        }

        if (servicio) {

            await axios.put(Global + "alumno/estatus/id/" + idalumno, {
                "estatus": valores.tipobaja
            }, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).catch(error => console.log(error))

            await axios.put(Global + "alumnobaja/" + datos.idbaja, bajaO, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(() => {
                Swal({
                    title: 'Datos actualizados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
                setCarga(false)
                setRecarga(!recarga)
            }).catch(error => console.log(error))
        } else {
            await axios.post(Global + "alumnobaja/alumno/" + idalumno + "/motivo/" + valores.motivo, bajaO, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(() => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
                setCarga(false)
                setRecarga(!recarga)
            }).catch(error => console.log(error))
        }
    }

    const initial = datos

    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>

                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <BlockIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Permanencia
                        </Typography>
                    </Grid>
                    <Grid item>
                        {
                            semaforo === "red" ?
                                <Avatar className={classes.red}></Avatar>
                                :
                                semaforo === "green" ?
                                    <Avatar className={classes.green}></Avatar>
                                    :
                                    semaforo === "green2" ?
                                        <Avatar className={classes.green}></Avatar>
                                        :
                                        semaforo === "yellow" ?
                                            <Avatar className={classes.yellow}></Avatar>
                                            : <Avatar>-</Avatar>
                        }
                    </Grid>
                </Grid>
                <br />
                {open ?
                    <Backdrop className={classes.backdrop} open={open}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Formik
                        enableReinitialize
                        validationSchema={schemaValidation}
                        initialValues={initial}
                        onSubmit={(values) => {
                            functionSubmit(values)
                        }}>
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                required
                                                error={props.touched.tipobaja && props.errors.tipobaja ? (true) : false}>
                                                <InputLabel>Tipo de baja</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.tipobaja}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Tipo de baja"
                                                    inputProps={{
                                                        name: 'tipobaja',
                                                        id: 'tipobaja',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value="BT" >Baja temporal</option>
                                                    <option value="BD" >Baja definitiva</option>
                                                    <option value="VG" >Reingreso</option>

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.tipobaja && props.errors.tipobaja ? (props.errors.tipobaja) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Fecha de la baja"
                                                id="fechabaja"
                                                name="fechabaja"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fechabaja}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechabaja && props.errors.fechabaja ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechabaja && props.errors.fechabaja ? (
                                                    props.errors.fechabaja
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                
                                                />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                error={props.touched.motivo && props.errors.motivo ? (true) : false}>
                                                <InputLabel>Motivo de la baja</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.motivo}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Motivo de la baja"
                                                    inputProps={{
                                                        name: 'motivo',
                                                        id: 'motivo',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        baja ?
                                                            baja.map((baja) => {
                                                                return <option key={baja.idmotivo} value={baja.idmotivo}>{baja.motivo}</option>
                                                            }) : null
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.motivo && props.errors.motivo ? (props.errors.motivo) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Fecha de reingreso"
                                                id="fecharei"
                                                name="fecharei"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fecharei}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fecharei && props.errors.fecharei ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fecharei && props.errors.fecharei ? (
                                                    props.errors.fecharei
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                label="Condición de reingreso"
                                                id="condicion"
                                                name="condicion"
                                                value={props.values.condicion}
                                                onChange={(e)=>{
                                                    let condicion = e.target.value;
                                                    props.setFieldValue("condicion",condicion.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.condicion && props.errors.condicion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.condicion && props.errors.condicion ? (
                                                    props.errors.condicion
                                                ) : null} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Observaciones"
                                                disabled={acceso}
                                                id="observacion"
                                                name="observacion"
                                                value={props.values.observacion}
                                                onChange={(e)=>{
                                                    let ob = e.target.value;
                                                    props.setFieldValue("observacion",ob.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.observacion && props.errors.observacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.observacion && props.errors.observacion ? (
                                                    props.errors.observacion
                                                ) : null} />
                                        </Grid>

                                        {
                                            !acceso ?
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            className={classes.boton}>
                                                            Guardar
                                                </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}></Grid>
                                                </Grid>
                                                :
                                                null
                                        }

                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper>
        </div>
    )
}

export default Baja;