import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { Hidden, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Global } from "../../../constants/global"
import Swal from 'sweetalert'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function ArticuloTesis(props) {

    const classes = useStyles();
    const [columns, setColumns] = useState([
        {
            title: "Título", field: "titulo", validate: rowData =>
                rowData.titulo === '' ? { isValid: false, helperText: 'El título no puede estar vacio' }
                    :
                    rowData.titulo !== undefined && rowData.titulo !== "" && rowData.titulo !== null ?
                        rowData.titulo.trim().length !== rowData.titulo.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
    ])

    const [data, setData] = useState([])
    const { idtesis } = props.data
    const [open, setOpen] = useState(false)

    useEffect(() => {

        const datos = async () => {

            await axios.get(Global + "tesisarticulo/tesis/" + idtesis, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))

        }

        datos()

    }, [])

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        props.rol === "administrador" ||
            props.rol === "coordinador_general" ||
            props.rol === "coordinador_posgrado" ?
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de artículos
                            </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar"
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        addTooltip: "Agregar",
                        editTooltip: "Editar",
                        deleteTooltip: "Dar de baja",
                        editRow: {
                            deleteText: "¿Esta seguro que desea dar de baja?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar"
                        },
                        emptyDataSourceMessage: 'No hay información por mostrar',

                    }
                }}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                   //console.log(newData,'pruebanewdata')
                                if (newData.titulo !== undefined) {
                                    //     await axios.get(Global + "linea/nombre/" + newData.nombre,
                                    //         {
                                    //             headers: {
                                    //                 'Access-Control-Allow-Origin': "http://localhost:3000",
                                    //                 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                    //                 'Content-Type': 'application/json',
                                    //                 Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    //             }
                                    //         }).then(response => {
                                    //             if (response.data.length < 1) {
                                    axios.post(Global + "tesisarticulo/tesis/" + idtesis,
                                        {
                                            estatus: "alta",
                                            tesis: {
                                                idtesis: ""
                                            },
                                            titulo: newData.titulo
                                        }, {
                                        headers: {
                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then(response => {
                                        Swal({
                                            title: 'Articulo registrado',
                                            text: ' ',
                                            icon: 'success',
                                            button: false,
                                            timer: 1000
                                        })
                                        setData([...data, response.data]);
                                    }).catch(error => console.log(error))

                                    //             } else {

                                    //                 Swal({
                                    //                     title: 'Esta linea de investigacion ya existe',
                                    //                     text: ' ',
                                    //                     icon: 'error',
                                    //                     button: false,
                                    //                     timer: 1000
                                    //                 })

                                    //             }
                                    //         }).catch(error => console.log(error))


                                } else {
                                    Swal({
                                        title: 'El nombre no puede estar vacío',
                                        text: ' ',
                                        icon: 'error',
                                        button: false,
                                        timer: 2000
                                    })
                                }
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData, rowData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                await axios.put(Global + "tesisarticulo/" + oldData.idtesisart,
                                    {
                                        tesis: {
                                            idtesis: ""
                                        },
                                        titulo: newData.titulo

                                    }, {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(response => {
                                    Swal({
                                        title: 'Articulo actualizado',
                                        text: ' ',
                                        icon: 'success',
                                        button: false,
                                        timer: 1000
                                    })
                                }).catch(error => console.log(error))


                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(async () => {

                                await axios.put(Global + "tesisarticulo/id/" + oldData.idtesisart,
                                    {
                                        estatus: "baja"

                                    }, {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(response => {
                                    // console.log(response.data);
                                    Swal({
                                        title: 'Articulo actualizado',
                                        text: ' ',
                                        icon: 'success',
                                        button: false,
                                        timer: 1000
                                    })
                                }).catch(error => console.log(error))


                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                resolve();
                            }, 1000);
                        })
                }}
            />
            :
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Listado de artículos
                        </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar"
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',

                    }
                }}
                columns={columns}
                data={data}

            />

    )
}

export default ArticuloTesis