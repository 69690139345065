import React from "react";
import './App.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './components/generales/Login';
import Home from './components/aspirantes/Home';
import Stepper from './components/aspirantes/Stepper';
import Registro from './components/aspirantes/RegistroAspirantes';
import { AuthContext } from './context/AuthContext';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/globalStyles';
import Password from './components/aspirantes/Password';
import AsignarCita from "./components/administrador/citas/AsignarCita";
import AsignarCarta from "./components/administrador/cartasAceptacion/AsignarCarta";
import ReasignarCita from "./components/administrador/citas/ReasignarCita";
import ReasignarCarta from "./components/administrador/cartasAceptacion/ReasignarCarta";
import FiltroGeneral from "./components/administrador/filtroCordinacion/FiltroGeneral";

import RegistroOtorgante from './components/aspirantes/CartasDeRecomendacion/RegistroOtorgante';
import AceptarAspirante from "./components/administrador/AceptarAspirante/AceptarAspirante";
import RegistroMatriculas from "./components/administrador/alumno/RegistrarMatriculas";
import CartaOtorgante from "./components/administrador/AceptarAspirante/CartaOtorgante";
import AceptarServicios from "./components/administrador/ServiciosEscolares/AceptarServicios";
import RegistroAlumno from "./components/administrador/alumno/RegistroAlumno";
import LoginUsuarios from "./components/administrador/Login";
import Convocatoria from "./components/administrador/Convocatoria/index";
import Inicio from "./components/siip/Inicio";
import Head from "./components/generales/Head.js";

import RegistroPrograma from "./components/administrador/programas/RegistroPrograma";
import ActualizarPrograma from "./components/administrador/programas/Index";
import IndexUnidad from "./components/administrador/unidadAcademica/IndexUnidad";
import AgregarProfesor from './components/administrador/profesores/AgregarProfesor';
import IndexProfesores from "./components/administrador/profesores/IndexProfesores";
import ActualizarAlumno from "./components/administrador/alumno/index";
import DetallesProfesor from "./components/administrador/profesores/DetallesProfesor";
import Otro from "./components/administrador/alumno/Beca";

import Roles from "./components/administrador/Rol/Roles";
import Cuenta from "./components/administrador/Cuenta/Cuenta";

import IndexMateria from "./components/administrador/Materias/Index";
import IndexCurso from "./components/administrador/curso/Index";
import Planes from "./components/administrador/calificaciones/plan_de_estudio/index";
import Calificacion from "./components/administrador/calificaciones/calificacion/index";
import Aspirantes from "./components/administrador/aspirantes/Index";
import AlumnoCurso from "./components/administrador/calificaciones/alumno_curso/AlumnoCurso";

import Entidad from "./components/administrador/Catalogos/entidad/Index";
import Pais from "./components/administrador/Catalogos/pais/index";
import Sede from "./components/administrador/Catalogos/sede/index";
import Ubpp from "./components/administrador/Catalogos/ubpp/index";
import Becante from "./components/administrador/Catalogos/becante/index";
import Motivo from "./components/administrador/Catalogos/motivoBaja/index";
import Etnia from "./components/administrador/Catalogos/etnia/index";
import Moneda from "./components/administrador/Catalogos/Moneda/index";
import Institucion from "./components/administrador/Catalogos/institucion/index";
import Asociacion from "./components/administrador/Catalogos/asociacion/index";
import Historial from "./components/administrador/historial/index";
import ImportaArchivos from "./components/administrador/ImportaArchivos/ImportaArchivos";
import Estadisticas from "./components/administrador/Estadisticas/estadisticos";

import Actualizartoken from "./components/siip/Actualizartoken";
import AsignarCitaExam from "./components/administrador/CitasExamen/AsignarCitaExam";



function App() {
  return (
    <div id="box">
      <Actualizartoken />
      <AuthContext>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                component={(props) => <LoginUsuarios {...props} />}
              />
              <Route
                exact
                path="/siip"
                component={(props) => <LoginUsuarios {...props} />}
              />
              <Route
                exact
                path="/registro"
                component={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/asignarCita"
                component={(props) => <AsignarCita {...props} />}
              />
                <Route
                exact
                path="/asignarCitaExam"
                component={(props) => <AsignarCitaExam {...props} />}
              />
              <Route
                exact
                path="/asignarCarta"
                component={(props) => <AsignarCarta {...props} />}
              />
              <Route
                exact
                path="/reasignarCita"
                component={(props) => <ReasignarCita {...props} />}
              />
              <Route
                exact
                path="/reasignarCarta"
                component={(props) => <ReasignarCarta {...props} />}
              />
              <Route
                exact
                path="/filtroGeneral"
                component={(props) => <FiltroGeneral {...props} />}
              />
              <Route
                exact
                path="/head"
                component={(props) => <Head {...props} />}
              />
              <Route
                exact
                path="/home"
                component={(props) => <Home {...props} />}
              />
              Administrativo
              <Route
                exact
                path="/inicio"
                component={(props) => <Inicio {...props} />}
              />
              <Route
                exact
                path="/alta"
                component={(props) => <Registro {...props} />}
              />
              <Route
                exact
                path="/password"
                component={(props) => <Password {...props} />}
              />
              <Route
                exact
                path="/stepper"
                component={(props) => <Stepper {...props} />}
              />
              <Route
                exact
                path="/cartaAceptacion"
                component={(props) => <RegistroOtorgante {...props} />}
              />
              <Route
                exact
                path="/cartaOtorgante"
                component={(props) => <CartaOtorgante {...props} />}
              />
              <Route
                exact
                path="/aspiranteAceptacion"
                component={(props) => <AceptarAspirante {...props} />}
              />
              <Route
                exact
                path="/aceptarServiciosEscolares"
                component={(props) => <AceptarServicios {...props} />}
              />
              <Route
                exact
                path="/aceptarAspirante"
                component={(props) => <RegistroAlumno {...props} />}
              />
              <Route
                exact
                path="/asignarMatriculas"
                component={(props) => <RegistroMatriculas {...props} />}
              />
              <Route
                exact
                path="/convocatoria"
                component={(props) => <Convocatoria {...props} />}
              />
              <Route
                exact
                path="/siip/programa"
                component={(props) => <RegistroPrograma {...props} />}
              />
              <Route
                exact
                path="/siip/actualizarPrograma"
                component={(props) => <ActualizarPrograma {...props} />}
              />
              <Route
                exact
                path="/siip/IndexUnidad"
                component={(props) => <IndexUnidad {...props} />}
              />
              <Route
                exact
                path="/siip/IndexProfesores"
                component={(props) => <IndexProfesores {...props} />}
              />
              <Route
                exact
                path="/siip/IndexAlumno"
                component={(props) => <ActualizarAlumno {...props} />}
              />
              <Route
                exact
                path="/siip/AgregarProfesor"
                component={(props) => <AgregarProfesor {...props} />}
              />
              <Route
                exact
                path="/siip/DetallesProfesor"
                component={(props) => <DetallesProfesor {...props} />}
              />
              <Route
                exact
                path="/siip/IndexMateria"
                component={(props) => <IndexMateria {...props} />}
              />
              <Route
                exact
                path="/siip/IndexCurso"
                component={(props) => <IndexCurso {...props} />}
              />
              <Route
                exact
                path="/siip/otro"
                component={(props) => <Otro {...props} />}
              />
              <Route
                exact
                path="/siip/plan_de_estudio"
                component={(props) => <Planes {...props} />}
              />
              <Route
                exact
                path="/siip/roles"
                component={(props) => <Roles {...props} />}
              />
              <Route
                exact
                path="/siip/cuenta"
                component={(props) => <Cuenta {...props} />}
              />
              <Route
                exact
                path="/siip/IndexCalificaciones"
                component={(props) => <Calificacion {...props} />}
              />
              <Route
                exact
                path="/siip/Aspirantes"
                component={(props) => <Aspirantes {...props} />}
              />
              <Route
                exact
                path="/siip/AlumnoCurso"
                component={(props) => <AlumnoCurso {...props} />}
              />
              <Route
                exact
                path="/Entidad"
                component={(props) => <Entidad {...props} />}
              />
              <Route
                exact
                path="/Pais"
                component={(props) => <Pais {...props} />}
              />
              <Route
                exact
                path="/Sede"
                component={(props) => <Sede {...props} />}
              />
              <Route
                exact
                path="/Ubpp"
                component={(props) => <Ubpp {...props} />}
              />
              <Route
                exact
                path="/Becante"
                component={(props) => <Becante {...props} />}
              />
              <Route
                exact
                path="/Motivo"
                component={(props) => <Motivo {...props} />}
              />
              <Route
                exact
                path="/Etnia"
                component={(props) => <Etnia {...props} />}
              />
              <Route
                exact
                path="/Moneda"
                component={(props) => <Moneda {...props} />}
              />
              <Route
                exact
                path="/Institucion"
                component={(props) => <Institucion {...props} />}
              />
              <Route
                exact
                path="/Asociacion"
                component={(props) => <Asociacion {...props} />}
              />
              <Route
                exact
                path="/historial"
                component={(props) => <Historial {...props} />}
              />
              <Route
                exact
                path="/siip/importaArchivos"
                component={(props) => <ImportaArchivos {...props} />}
              />
              <Route
                exact
                path="/estadisticas"
                component={(props) => <Estadisticas {...props} />}
              />

            </Switch>
          </Router>
        </ThemeProvider>
      </AuthContext>
    </div>
  );

}

export default App;
