import axios from "axios";
import { Global } from "../constants/global";

const base = Global;

let headers = {
    // "Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept, Methods",
    "Access-Control-Allow-Origin": "http://localhost:3000",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    "Content-Type": "application/json",
    // Authorization: 'Bearer ' + window.localStorage.getItem('token'),
};
if(window.localStorage.getItem('token')){
    headers.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
}

const axiosInstance = axios.create({
    baseURL: base,
    headers,
});

export default axiosInstance;