import React, { useEffect, useState, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import AgregarExamen from './AgregarExamen';
import ActualizarExamen from './ActualizarExamen';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaExamen(props) {
   
    const classes = useStyles();
    const { idalumno } = props.data;
    const [data, setData] = useState([]);
    const [recarga, setRecarga] = useState(false);
    const [nuevo, setNuevo] = useState(false);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(() => {
      //const datos = async () => {
        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }
                });
            });
        }
         console.log(idalumno)
         axios.get(Global + "examenpro/alumno/" + idalumno, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setData(response.data)
        }).catch(error => console.log(error))

       //}

      // datos();

    }, [recarga])
    const [columns, setColumns] = useState([
        { title: 'Dia ', field: 'diaexam'},
        {title: 'Mes', field: 'mesexam'},
        {title: 'Año', field: 'anioexam'},
    ]);

    const actualiza = () => {
        setRecarga(!recarga)
    }

    const cambioVista = () => {
        setNuevo(!nuevo)
    }

    const actualizarCambio = async () => {
       await  actualiza()
       await  cambioVista()
    }

    return (
        nuevo ?
            <AgregarExamen data={props.data} metodo={actualizarCambio} />
            :
            rol === "administrador" ||
            rol === "coordinador_general" ||
            rol === "coordinador_posgrado" ?
            <MaterialTable
                style={{
                    box_shadow: 'none'
                }}
                options={{
                    exportButton:true,
                    exportFileName:"Escolaridad",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    pagination: {
                        // labelDisplayedRows: 'Del {from} al {to} de {count}',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                        addTooltip: 'Agregar',
                        editTooltip: 'Editar',
                        deleteTooltip: 'Deshabilitada',
                        editRow: {
                            deleteText: '¿Desea inhabilitar este docente?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Guardar'
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: 'Buscar',
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones',
                    }
                }
                }
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Examen Profesional
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>}
                columns={columns}
                data={data}
                detailPanel={[
                    {
                        icon: 'more_vert',
                        openIcon: 'expand_less',
                        iconProps: { color: 'secondary' },
                        tooltip: 'Información de escolaridad',
                        render: rowData => {
                            return (
                                <ActualizarExamen data={rowData} metodo={actualiza} rol={rol}/>
                            )
                        },
                    },
                ]}
                actions={[
                    {
                        icon: 'add_box',
                        tooltip: 'Agregar',
                        isFreeAction: true,
                        onClick: () => cambioVista()
                    }
                ]}
            />
            :
            <MaterialTable
            style={{
                box_shadow: 'none'
            }}
            options={{
                exportButton:true,
                exportFileName:"Escolaridad",
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                headerStyle: {
                    backgroundColor: '#66abe0b8',
                    color: '#FFF'
                }
            }}
            localization={{
                pagination: {
                    // labelDisplayedRows: 'Del {from} al {to} de {count}',
                    labelRowsSelect: '',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Siguiente página',
                    lastTooltip: 'Última página',
                },
                body: {
                    emptyDataSourceMessage: 'No hay información por mostrar',
                    addTooltip: 'Agregar',
                    editTooltip: 'Editar',
                    deleteTooltip: 'Deshabilitada',
                    editRow: {
                        deleteText: '¿Desea inhabilitar este docente?',
                        cancelTooltip: 'Cancelar',
                        saveTooltip: 'Guardar'
                    }
                },
                toolbar: {
                    searchTooltip: 'Buscar en la tabla',
                    searchPlaceholder: 'Buscar',
                    exportCSVName: "Exportar como CSV",
                    exportPDFName: "Exportar como PDF",
                    exportTitle: "Exportar"
                },
                header: {
                    actions: 'Acciones',
                }
            }
            }
            title={
                <Hidden smDown>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                            <Typography >
                                Examen Profesional
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>}
            columns={columns}
            data={data}
            detailPanel={[
                {
                    icon: 'more_vert',
                    openIcon: 'expand_less',
                    iconProps: { color: 'secondary' },
                    tooltip: 'Información de escolaridad',
                    render: rowData => {
                        return (
                            <ActualizarExamen data={rowData} metodo={actualiza} rol={rol}/>
                        )
                    },
                },
            ]}
        />

    )
}
export default TablaExamen;