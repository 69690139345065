import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Hidden, Grid, Typography, Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Formik } from "formik"
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { Global } from "../../../constants/global";
import Swal from 'sweetalert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function ComiteAsesor(props) {

    // console.log(props)
    const [data, setData] = useState([])
    const { idtesis } = props.data
    const [datos, setDatos] = useState([])
    const [open, setOpen] = useState(false)

    const [docmod, setMod] = useState(null)
    const [docap, setAp] = useState(null)
    const [mensajeAp, setMensajeAp] = useState(null)
    const [mensajeMod, setMensajeMod] = useState(null)
    const [acceso, setAcceso] = useState(true)

    useEffect(() => {

        let docs = {
            docap: '',
            docmod: ''
        }

        const datos = async () => {


            props.rol === "administrador" ||
                props.rol === "coordinador_general" ||
                props.rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)


            await axios.get(Global + "tesis/" + idtesis, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                docs.docap = response.data.docap
                docs.docmod = response.data.docmod
            }).catch(error => console.log(error))

            await axios.get(Global + "comite/tesis/" + idtesis + "/tipo/Asesor", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))

            setColumns([
               
                {
                    title: "Miembro Participante", field: "profesor.persona.nombre", validate: rowData => (
                        rowData.nombre === '' ? { isValid: false, helperText: 'El nombre no puede estar vacio' }
                            :
                            rowData.nombre !== undefined && rowData.nombre !== "" && rowData.nombre !== null ?
                                rowData.nombre.trim().length !== rowData.nombre.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                                : true
                    ),
                    editComponent: valores => (

                        <Autocomplete
                            options={props.profesor}
                            id="controlled-demo"
                            value={valores.idprofesor}
                            onChange={(event, newValue) => {
                                valores.onChange(newValue);
                            }}
                            fullWidth
                            getOptionLabel={(option) => option.persona.nombre + " " + option.persona.apellidoM + " " + option.persona.apellidoP }
                            //getOptionLabel={(option) => option.matricula}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span
                                        id="textSpan"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {/* ({option.matricula}) */}
                                        {option.persona.nombre}
                                    </span>
                                    {/* {option.estatus} */}
                                    {option.persona.correo}

                                </React.Fragment>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Nombre"
                                    variant="standard"
                                />
                            }
                        />
                    )
                },
                {
                    title: 'Participación',
                    field: 'participacion',
                    lookup: { asesor1: "ASESOR 1",asesor2: "ASESOR 2",asesor3: "ASESOR 3", revisor: "REVISOR", presidente: "PRESIDENTE", codirector: "CO-DIRECTOR", lectorexterno: "LECTOR EXTERNO", secretario: "SECRETARIO", suplente: "SUPLENTE", vocal: "VOCAL" },
                },
                {
                    title: 'Clave Profesor',
                    field: 'clave',
                },
                {
                    title: 'Cédula',
                    field: 'cedula',
                },
           
                {
                    title: 'Correo Electrónico',
                    field: 'email',
                },
            ])

            if (docs.docap !== null && docs.docap !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + docs.docap)
                setMensajeAp(await httpsReference.getDownloadURL());
            }
            if (docs.docmod !== null && docs.docmod !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + docs.docmod)
                setMensajeMod(await httpsReference.getDownloadURL());
            }



        }

        datos()
        setDatos(docs)
        // console.log(alumnos)

    }, [open])


    const classes = useStyles();
    const [columns, setColumns] = useState([])

    const functionSubmit = async () => {

        let linkAprobacion = datos.docap
        let linkModificacion = datos.docmod

        if (docap) {
            if (datos.docap === '') {
                linkAprobacion = props.correo + '/ComiteAsesor/' + uuidv4() + docap.name
            }
            const storageRef = fba.storage().ref(linkAprobacion)
            const task = storageRef.put(docap)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        if (docmod) {
            if (datos.docmod === '') {
                linkModificacion = props.correo + '/ComiteAsesor/' + uuidv4() + docmod.name
            }
            const storageRef = fba.storage().ref(linkModificacion)
            const task = storageRef.put(docmod)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })

            let docs = {
                docap: linkAprobacion,
                docmod: linkModificacion
            }
            // console.log(docs)
            axios.put(Global + "tesis/id/" + idtesis, docs, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(() => {
                Swal({
                    title: 'Documentos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
                setOpen(true)
                props.metodo()
            }).catch(error => console.log(error))

        }

    }

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, []);

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "docap":
                    setAp(file);
                    break;
                case "docmod":
                    setMod(file);
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "docap":
                    setAp(false)
                    break;
                case "docmod":
                    setMod(false)
                    break;
                default:
                    break;
            }
        }
    }

    const initial = datos

    return (

        props.rol === "administrador" ||
            props.rol === "coordinador_general" ||
            props.rol === "coordinador_posgrado" ?

            <>
                <Formik
                    // validationSchema={schemaValidation}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(values) => {
                        functionSubmit(values)
                    }}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="docap">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="docap"
                                                    name="docap"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento de aprobacion
                                                    {docap ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {mensajeAp !== null && mensajeAp !== '' ?
                                                    <a style={{ display: "table-cell" }} href={mensajeAp} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : "Sin documento"}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="docmod">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="docmod"
                                                    name="docmod"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento de modificacion
                                                    {docmod ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {mensajeMod !== null && mensajeMod !== '' ?
                                                    <a style={{ display: "table-cell" }} href={mensajeMod} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : "Sin documento"}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>

                                </Grid>
                            </form>
                        )
                    }}
                </Formik>
                <br />

                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo} >
                                        Listado de miembros
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    }
                    options={{
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        toolbar: {
                            searchPlaceholder: "Buscar"
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            addTooltip: "Agregar",
                            editTooltip: "Editar",
                            deleteTooltip: "Dar de baja",
                            editRow: {
                                deleteText: "¿Esta seguro que desea dar de baja?",
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Guardar"
                            },
                            emptyDataSourceMessage: 'No hay información por mostrar',

                        }
                    }}
                    columns={columns}
                    data={data}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    if (newData.profesor.persona.nombre !== undefined) {
                                        await axios.get(Global + "comite/profesor/" + newData.profesor.persona.nombre.idprofesor + "/tesis/" + idtesis,
                                            {
                                                headers: {
                                                    'Access-Control-Allow-Origin': "http://localhost:3000",
                                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                }
                                            }).then(response => {
                                                if (response.data.length < 1) {
                                                    axios.post(Global + "comite/tesis/" + idtesis,
                                                        {
                                                            estatus: "alta",
                                                            participacion: newData.participacion,
                                                            cedula: newData.cedula,
                                                            email: newData.email,
                                                            clave: newData.clave,
                                                            profesor: {
                                                                idprofesor: newData.profesor.persona.nombre.idprofesor
                                                            },
                                                            tesis: {
                                                                idtesis: ""
                                                            },
                                                            tipo: "Asesor"
                                                        }, {
                                                        headers: {
                                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                            'Content-Type': 'application/json',
                                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                        }
                                                    }).then(() => {

                                                        Swal({
                                                            title: 'Miembro registrado',
                                                            text: ' ',
                                                            icon: 'success',
                                                            button: false,
                                                            timer: 1000
                                                        })

                                                        axios.get(Global + "comite/tesis/" + idtesis + "/tipo/Asesor", {
                                                            headers: {
                                                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                                'Content-Type': 'application/json',
                                                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                            }
                                                        }).then(response => {
                                                            setData(response.data);
                                                        }).catch(error => console.log(error))

                                                    }).catch(error => console.log(error))

                                                } else {

                                                    Swal({
                                                        title: 'Este profesor ya forma parte del comite o del jurado examinador',
                                                        text: ' ',
                                                        icon: 'error',
                                                        button: false,
                                                        timer: 1000
                                                    })

                                                }
                                            }).catch(error => console.log(error))


                                    } else {
                                        Swal({
                                            title: 'El miembro no puede estar vacío',
                                            text: ' ',
                                            icon: 'error',
                                            button: false,
                                            timer: 2000
                                        })
                                    }
                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    await axios.put(Global + "comite/" + oldData.idcomite,
                                        {
                                            participacion: newData.participacion,
                                            cedula: newData.cedula,
                                            email: newData.email,
                                            clave: newData.clave,
                                            profesor: {
                                                idprofesor: oldData.profesor.idprofesor
                                            },
                                            tesis: {
                                                idtesis: ""
                                            },
                                            tipo: "Asesor"
                                        }, {
                                        headers: {
                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then(() => {
                                        Swal({
                                            title: 'Miembro actualizado',
                                            text: ' ',
                                            icon: 'success',
                                            button: false,
                                            timer: 1000
                                        })
                                    }).catch(error => console.log(error))


                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setData([...dataUpdate]);

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    await axios.put(Global + "comite/id/" + oldData.idcomite,
                                        {
                                            estatus: "baja"
                                        }
                                        , {
                                            headers: {
                                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                'Content-Type': 'application/json',
                                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                            }
                                        }).catch(error => console.log(error))


                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setData([...dataDelete]);
                                    resolve();
                                }, 1000);
                            })
                    }}
                />
            </>
            :
            <>
                <Formik
                    // validationSchema={schemaValidation}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(values) => {
                        functionSubmit(values)
                    }}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="docap">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="docap"
                                                    disabled={acceso}
                                                    name="docap"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento de aprobacion
                                                    {docap ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {mensajeAp !== null && mensajeAp !== '' ?
                                                    <a style={{ display: "table-cell" }} href={mensajeAp} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : "Sin documento"}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="docmod">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="docmod"
                                                    name="docmod"
                                                    disabled={acceso}
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento de modificacion
                                                    {docmod ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {mensajeMod !== null && mensajeMod !== '' ?
                                                    <a style={{ display: "table-cell" }} href={mensajeMod} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : "Sin documento"}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {
                                        !acceso ?
                                            <Grid container spacing={3}>  
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            : null
                                    }

                                </Grid>
                            </form>
                        )
                    }}
                </Formik>
                <br />

                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo} >
                                        Listado de miembros
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    }
                    options={{
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        toolbar: {
                            searchPlaceholder: "Buscar"
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                        }
                    }}
                    columns={columns}
                    data={data}

                />
            </>
    )
}

export default ComiteAsesor