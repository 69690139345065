
import React, { useEffect, useContext } from 'react';
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from "../../../context/AuthContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function Estadisticas({ history }) {

    const classes = useStyles();
    const { usuario } = useContext(Auth);

    useEffect(() => {
        if (usuario === null) {
            history.push("/siip");
        }
    })

    return (
        <div className={classes.root}>
            <Head />
            {/* <Paper elevation={3} className={classes.paperContainer}>
                <Typography variant="h5" className={classes.titulo}>
                    Estadísticas
            </Typography>
                <br />
            </Paper > */}
            <center>
                <iframe
                    title="Estadísticos de UACH"
                    width="1250"
                    height="910"
                    src="https://datastudio.google.com/embed/reporting/d64b93df-9a87-44c5-a05e-38b804bce9d1/page/aNi6B"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </center>
            <Pie />
        </div>
    );

}
export default Estadisticas;