import React, { useEffect, useState } from 'react'
import { Avatar, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import { FORMATOFECHAMAXHOY, menor } from "../../generales/Fecha"
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: theme.palette.secondary.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const FormOcupacion = () => {
    const classes = useStyles();

    const [persona, setPersona] = useState('')
    const [ocupacionAnterior, setOcupacionAnterior] = useState('')
    const [paises, setPaises] = useState([]);
    const [paisselect, setPaisselect] = useState('pais')
    const [codigo, setCodigo] = useState(0);
    const [estados, setEstados] = useState([]);
    const [estadoselect, setEstadoselect] = useState('estado')
    const [ciudades, setCiudades] = useState([]);
    const [recarga, setRecarga] = useState('')    
    useEffect(() => {
        setOcupacionAnterior({
            ocupacion: '',
            empresa: '',
            pais: '',
            cp: '',
            estado: '',
            ciudad: '',
            colonia: '',
            calle: '',
            direccion: '',
            fechaIn: '',
            telefono: '',
        })

        axios.get(Global + "pais", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setPaises(response.data)
        }).catch(error => console.log(error))


        //REVISAR SI EXITE YA EXPERIENCIA DE ESTA PERSONA 
        var user = fba.auth().currentUser;
        // console.log(user.email)
        axios.get(Global + "persona/correo/" + user.email, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(response => {

            setPersona(response.data)
            axios.get(Global + "ocupacion/personas/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {
                if (responses.data[0]) {
                    setOcupacionAnterior(responses.data[0])
                    setPaisselect(responses.data[0].pais)
                    setCodigo(responses.data[0].cp)
                    setEstadoselect(responses.data[0].estado)
                    // setCiudadselect(responses.data[0].ciudad)
                }
                handleCargaClose()
            }).catch(error => console.log(error))

        }).catch(error => console.log(error))
    }, [recarga])

    const [carga, setCarga] = React.useState(true);
    const handleCargaClose = () => {
        setCarga(false);
    };



    useEffect(() => {
        axios.get(Global + "codigo/cp/" + codigo, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setEstados(response.data)
        }).catch(error => console.log(error))
    }, [codigo])

    useEffect(() => {
        axios.get(Global + "codigo/estado/" + estadoselect, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setCiudades(response.data)
        }).catch(error => console.log(error))
    }, [estadoselect])

    const funcionSubmit = valores => {
        if (valores.pais !== 'MÉXICO') {
            valores.cp = ''
            valores.estado = ''
            valores.ciudad = ''
            valores.colonia = ''
            valores.calle = ''
        }
        let ocupacion = {
            ocupacion: valores.ocupacion,
            empresa: valores.empresa,
            pais: valores.pais,
            cp: valores.cp,
            estado: valores.estado,
            ciudad: valores.ciudad,
            colonia: valores.colonia,
            calle: valores.calle,
            direccion: valores.direccion,
            fechaIn: valores.fechaIn,
            telefono: valores.telefono,
        };
        if (ocupacionAnterior.ocupacion === '') {
            axios.post(Global + "ocupacion/persona/" + persona[0].idP, ocupacion, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
            }).catch(error => {
                console.log(error);
            })
        } else {
            axios.put(Global + "ocupacion/" + ocupacionAnterior.idocupacion, ocupacion, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
            }).catch(error => {
                console.log(error);
            })
        }
        setRecarga('recarga')
    }


    //Schema de validacion
    const schemaValidation = Yup.object({
        ocupacion: Yup.string().required('Es obligatorio ingresar la ocupacion actual'),
       /*  fechaIn: Yup.string().test('match',
            'La fecha debe ser menor a la fecha actual',
            function (fecha) {
                return menor(fecha)
            }), */
    })

    const initial = ocupacionAnterior

    const handleOnChangePais = (event) => {
        setPaisselect(event)
    }
    const handleOnChangeCodigo = (event) => {
        setCodigo(event.target.value)
    }
    const handleOnChangeEstado = (event) => {
        setEstadoselect(event.target.value)
    }


    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <BusinessCenterIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Ocupación actual
                        </Typography>
                    </Grid>
                </Grid>


                <br />
                {carga ?
                    <Backdrop className={classes.backdrop} open={carga} onClick={handleCargaClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        enableReinitialize
                        validationSchema={schemaValidation}
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                fullWidth
                                                label="Ocupación"
                                                name="ocupacion"
                                                value={props.values.ocupacion}
                                                onChange={(e)=>{
                                                    let ocupacion = e.target.value;
                                                    props.setFieldValue("ocupacion",ocupacion.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.ocupacion && props.errors.ocupacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.ocupacion && props.errors.ocupacion ? (
                                                    props.errors.ocupacion
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Institución o Empresa"
                                                name="empresa"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.empresa}
                                                onChange={(e)=>{
                                                    let emp = e.target.value;
                                                    props.setFieldValue("empresa",emp.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                            >
                                                {/* <InputLabel >País</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.pais}
                                                    onChange={e => {
                                                        handleOnChangePais(e);
                                                        props.setFieldValue("pais", e.target.value);

                                                    }}
                                                    label="pais"
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        paises.map((pais) => {
                                                            return <option key={pais.idpais} value={pais.pais}>{pais.pais}</option>
                                                        })
                                                    }

                                                </Select> */}
                                                {/* /////////// */}
                                                <Autocomplete
                                                        freeSolo
                                                        required
                                                        name= 'pais'
                                                        id= 'pais'
                                                        value={props.values.pais}
                                                        // onChange={props.handleChange}
                                                        onChange={(event, newValue) => {
                                                            handleOnChangePais(newValue);
                                                            props.setFieldValue("pais", newValue);
                                                            }}
                                                        label="País"
                                                        options={paises.map((pais) =>pais.pais)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="País"
                                                                name= 'pais'
                                                                id= 'pais'
                                                                margin="normal"
                                                                variant="outlined"
                                                                onBlur={props.handleBlur}
                                                            />
                                                            )}
                                                    >
                                                    </Autocomplete>
                                                <FormHelperText>
                                                    {props.touched.pais && props.errors.pais ? (props.errors.pais) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {
                                        (paisselect === 'MÉXICO') ?
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Código postal"
                                                            name="cp"
                                                            variant="outlined"

                                                            fullWidth
                                                            value={props.values.cp}
                                                            onChange={e => {
                                                                handleOnChangeCodigo(e);
                                                                props.setFieldValue("cp", e.target.value);

                                                            }}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            className={classes.formControl}
                                                        // error={props.touched.institucion && props.errors.institucion ? (true) : false}
                                                        >
                                                            <InputLabel id="demo" shrink>Estado</InputLabel>
                                                            <Select
                                                                label="Estado"
                                                                variant="outlined"
                                                                value={props.values.estado}
                                                                native
                                                                onChange={e => {
                                                                    handleOnChangeEstado(e);
                                                                    props.setFieldValue("estado", e.target.value);

                                                                }}
                                                                inputProps={{
                                                                    name: 'estado',
                                                                    id: 'estado',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    estados.map((estado) => {
                                                                        return <option key={estado.estado} value={estado.estado}>{estado.estado}</option>
                                                                    })
                                                                }

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth

                                                            className={classes.formControl}
                                                        // error={props.touched.institucion && props.errors.institucion ? (true) : false}
                                                        >
                                                            <InputLabel shrink >Municipio</InputLabel>
                                                            <Select
                                                                label="Municipio"
                                                                variant="outlined"
                                                                value={props.values.ciudad}
                                                                native
                                                                onChange={e => {
                                                                    // handleOnChangeCiudad(e);
                                                                    props.setFieldValue("ciudad", e.target.value);

                                                                }}
                                                                inputProps={{
                                                                    name: 'ciudad',
                                                                    id: 'ciudad',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    ciudades.map((ciudad) => {
                                                                        return <option key={ciudad.ciudad} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                                    })
                                                                }

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Colonia"
                                                            name="colonia"
                                                            variant="outlined"

                                                            fullWidth
                                                            value={props.values.colonia}
                                                            onChange={props.handleChange}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            label="Calle y número "
                                                            name="calle"
                                                            variant="outlined"

                                                            fullWidth
                                                            value={props.values.calle}
                                                            onChange={(e)=>{
                                                                let callenum = e.target.value;
                                                                props.setFieldValue("calle",callenum.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </> : null
                                    }

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Dirección completa"
                                                name="direccion"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.direccion}
                                                onChange={(e)=>{
                                                    let direc = e.target.value;
                                                    props.setFieldValue("direccion",direc.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Fecha de inicio"
                                                id="fechaIn"
                                                name="fechaIn"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fechaIn}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechaIn && props.errors.fechaIn ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechaIn && props.errors.fechaIn ? (
                                                    props.errors.fechaIn
                                                ) : null}
                                                InputProps={FORMATOFECHAMAXHOY}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Teléfono"
                                                name="telefono"
                                                variant="outlined"
                                                inputProps={{ maxLength: 10 }}
                                                fullWidth
                                                value={props.values.telefono}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                type='number'
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>

                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
        </div >
    );

}

export default FormOcupacion;