import React, { useState, createRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Avatar,
  IconButton,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import { fba } from "../../../firebaseConfig";
import { Global } from "../../../constants/global";
import alerta from "./PlantillaAlerta";

const useStyles = makeStyles((theme) => ({
  foto: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  input: {
    display: "none",
  },
  boton: {
    fontFamily: { Poppins: "SansSerif" },
    fontSize: "15px",
    fontWeight: "500",
    backgroundColor: "#034AA7",
    borderRadius: "4px 4px 4px 4px",
  },
}));

const ValidacionInformacionPersonal = Yup.object().shape({
  edo_civil: Yup.string().required("Escoge tu estado civil"),
});

export default function InformacionPersonal() {
  const classes = useStyles();
  //image es para guardar la imagen que escoga el usuario
  const [image, _setImage] = useState(null);
  //fotofb obtiene la imagen guardada en firebase
  const [fotofb, setFB] = useState(null);
  //referencia hacia la foto subida
  const inputFileRef = createRef(null);
  //obtiene la ruta de firebase para guardarla en bd
  const [rutaFoto, setRutaFoto] = useState(null);
  //cargando se asegura de primero cargar los datos del usuario para despues mostrarlos en los componentes renderizados
  const [cargando, setCargando] = useState(true);
  const [persona, setDatos] = useState({
    idP: 0,
    nombre: "",
    apellidoP: "",
    apellidoM: "",
    estado_civil: "",
    telefono: "",
    celular: "",
    correo: "",
    acta_nac: "",
    curp: "",
    cv: "",
    cvu: "",
    doc_curp: "",
    email: "",
    fecha_na: "",
    fotografia: "",
    genero: "",
    orcid: "",
    rfc: "",
  });

  const setImage = (newImage) => {
    _setImage(newImage);
  };

  //actualizar la foto de perfil en firebase storage
  const handleChangeImage = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      var currUser = fba.auth().currentUser.email;
      if (currUser !== null) {
        var rutaFotoFB =
          currUser + "/DocumentosDatosGenerales/" + uuidv4() + newImage.name;
        if (rutaFoto === null) {
          var update = fba.storage().ref();
          var updateRef = update.child(rutaFotoFB);
          setImage(URL.createObjectURL(newImage));
          updateRef.put(newImage);
        } else {
          var update2 = fba.storage().ref();
          var updateRef2 = update2.child(rutaFoto);
          setImage(URL.createObjectURL(newImage));
          updateRef2.put(newImage);
        }

        (async () => {
          //este servicio no necesita un token de autorizacion
          await axios
            .get(Global + "persona/correo/" + currUser, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              const datosPersona = {
                acta_nac: response.data[0].acta_nac,
                apellidoM: response.data[0].apellidoM,
                apellidoP: response.data[0].apellidoP,
                celular: response.data[0].celular,
                correo: response.data[0].correo,
                curp: response.data[0].curp,
                doc_curp: response.data[0].doc_curp,
                cv: response.data[0].cv,
                cvu: response.data[0].cvu,
                email: response.data[0].email,
                estado_civil: response.data[0].estado_civil,
                fecha_na: response.data[0].fecha_na,
                fotografia: rutaFoto === null ? rutaFotoFB : rutaFoto,
                genero: response.data[0].genero,
                idP: response.data[0].idP,
                nombre: response.data[0].nombre,
                orcid: response.data[0].orcid,
                rfc: response.data[0].rfc,
                telefono: response.data[0].telefono,
              };

              axios
                .put(Global + "persona/" + datosPersona.idP, datosPersona, {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                })
                .then((response) => {})
                .catch((error) => {
                  
                });
            })
            .catch((error) => {
              
            });
        })();
      }
    }
  };

  /*******obtener la foto**************/

  var currUser = fba.auth().currentUser.email;
  if (currUser !== null) {
    if (rutaFoto !== null) {
      var storageRef = fba.storage();
      var path = storageRef.ref();
      var pathChild = path.child(rutaFoto);      
      pathChild
        .getDownloadURL()
        .then(function (url) {
          setFB(url);
        })
        .catch(function (error) {
            
        });
      
    }
  }
  /*******obtener la foto**************/
  useEffect(async () => {
    const cargaDatosUsuario = async () => {
      var currUser = fba.auth().currentUser.email;
      //este servicio no necesita un token de autorizacion
      await axios
        .get(Global + "persona/correo/" + currUser, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const respuestaDatos = response.data[0];

          setRutaFoto(respuestaDatos.fotografia);

          setDatos({
            idP: respuestaDatos.idP,
            nombre: respuestaDatos.nombre,
            apellidoP: respuestaDatos.apellidoP,
            apellidoM: respuestaDatos.apellidoM,
            estado_civil: respuestaDatos.estado_civil,
            celular: respuestaDatos.celular,
            telefono: respuestaDatos.telefono,
            correo: respuestaDatos.correo,
            acta_nac: respuestaDatos.acta_nac,
            curp: respuestaDatos.curp,
            cv: respuestaDatos.cv,
            cvu: respuestaDatos.cvu,
            doc_curp: respuestaDatos.doc_curp,
            email: respuestaDatos.email,
            fecha_na: respuestaDatos.fecha_na,
            fotografia: respuestaDatos.fotografia,
            genero: respuestaDatos.genero,
            orcid: respuestaDatos.orcid,
            rfc: respuestaDatos.rfc,
          });
        })
        .catch((error) => {});
    };
    await cargaDatosUsuario();
    setCargando(false);
  }, []);

  return (
    <>
      {cargando ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Formik
          enableReinitialize
          validationSchema={ValidacionInformacionPersonal}
          initialValues={{ edo_civil: persona.estado_civil }}
          onSubmit={(valores) => {
            let updateInfo = {
              idP: persona.idP,
              nombre: persona.nombre,
              apellidoP: persona.apellidoP,
              apellidoM: persona.apellidoM,
              estado_civil: valores.edo_civil,
              telefono: persona.telefono,
              celular: persona.celular,
              correo: persona.correo,
              acta_nac: persona.acta_nac,
              curp: persona.curp,
              cv: persona.cv,
              cvu: persona.cvu,
              doc_curp: persona.doc_curp,
              email: persona.email,
              fecha_na: persona.fecha_na,
              fotografia: rutaFoto,
              genero: persona.genero,
              orcid: persona.orcid,
              rfc: persona.rfc,
            };

            (async () => {
              axios
                .put(Global + "persona/" + persona.idP, updateInfo, {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("token"),
                  },
                })
                .then((response) => {
                  alerta("Datos actualizados", "success");
                })
                .catch((error) => {
                  alerta("Ha ocurrido un error.Inténtalo más tarde", "error");
                });
            })();
          }}
        >
          {(props) => {
            return (
              <>
                <Form noValidate onSubmit={props.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid container justify="center">
                      <input
                        ref={inputFileRef}
                        accept="image/*"
                        hidden
                        id="avatar-image-upload"
                        type="file"
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="avatar-image-upload">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Avatar
                            className={classes.foto}
                            alt="foto de perfil"
                            src={image || fotofb}
                          />
                        </IconButton>
                      </label>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        disabled
                        variant="filled"
                        label="Nombre"
                        value={persona.nombre}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        disabled
                        variant="filled"
                        label="Apellido Paterno"
                        value={persona.apellidoP}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        disabled
                        variant="filled"
                        label="Apellido Materno"
                        value={persona.apellidoM}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        error={
                          props.touched.edo_civil && props.errors.edo_civil
                            ? true
                            : false
                        }
                      >
                        <InputLabel>Estado Civil</InputLabel>
                        <Select
                          native
                          value={props.values.edo_civil}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          label="Estado Civil"
                          inputProps={{
                            name: "edo_civil",
                            id: "edo_civil",
                          }}
                        >
                          <option value={"soltero"}>Soltero</option>
                          <option value={"casado"}>Casado</option>
                          <option value={"divorciado"}>Divorciado</option>
                          <option value={"separacion"}>
                            Separación en proceso judicial
                          </option>
                          <option value={"viudo"}>Viudo</option>
                          <option value={"concubinato"}>Concubinato</option>
                        </Select>
                        <FormHelperText>
                          {props.touched.edo_civil && props.errors.edo_civil
                            ? props.errors.edo_civil
                            : null}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid container spacing={3} justify="center">
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          fullWidth
                          className={classes.boton}
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}
