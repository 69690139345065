import React, { useState } from 'react'
import { Typography, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, CircularProgress, Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Swal from 'sweetalert';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import { menor,FORMATOFECHAMAXHOY } from "../../generales/Fecha"
import { SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function AgregarPredoctoral(props) {

    const classes = useStyles();
    const { idalumno } = props.data;
    const { correo } = props.data.persona;
    const [registroAnterior, setRegistroAnterior] = useState({
        acta: '',
        comentarios: '',
        dictamen: '',
        documento: '',
        fecha: '',
        oportunidad: '',

    })
    const [adjunto, setAdjunto] = useState(0);
    const [carga, setCarga] = useState(false)



    // Schema de validacion
    const schemaValidacion = Yup.object({
        comentarios: '',
        documento: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (adjunto === 0 || SUPPORTED_PDF.includes(adjunto.type))),
        dictamen: Yup.string().required("El campo de dictamen es obligatorio"),
        fecha: Yup.string().required("El campo de fecha es obligatorio").test('match',
            'La fecha debe ser menor a la fecha actual',
            function (fecha) {
                return menor(fecha)
            }),
        oportunidad: Yup.string().required("El campo de oportunidad es obligatorio"),

    })

    const initial = registroAnterior

    const funcionSubmit = async valores => {
        setCarga(true)
        let linkDocumento = ""

        if (adjunto) {
            linkDocumento = correo + '/DocumentosPredoctoral/' + uuidv4() + adjunto.name
            const storageRef = fba.storage().ref(linkDocumento)
            const task = storageRef.put(adjunto)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        let predoctoral = {
            acta: linkDocumento,
            comentarios: valores.comentarios,
            dictamen: valores.dictamen,
            fecha: valores.fecha,
            oportunidad: valores.oportunidad,
            alumno: {
                idalumno: ''
            }
        };


        await axios.get(Global + "predoctoral/alumno/" + idalumno, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {

            if (response.data.length > 1) {
                Swal({
                    title: 'Solo se puede tener 2 registros',
                    text: ' ',
                    icon: 'error',
                    button: false,
                    timer: 1000
                })
                setCarga(false)
            } else {
                axios.post(Global + "predoctoral/alumno/" + idalumno, predoctoral, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(() => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    setCarga(false)
                }).catch(error => { console.log(error); })
            }

        }).catch(error => { console.log(error); })
        props.metodo()

    }



    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "documento":
                    setAdjunto(file);
                    break;
                default:
                    break;
            }

        } else {
            switch (event.target.id) {
                case "documento":
                    setAdjunto(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>
                <Formik
                    validationSchema={schemaValidacion}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        funcionSubmit(valores)
                    }}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={1}>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={props.touched.oportunidad && props.errors.oportunidad ? (true) : false}>
                                            <InputLabel>Oportunidad</InputLabel>
                                            <Select
                                                labelId="Oportunidad"
                                                native
                                                value={props.values.oportunidad}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Oportunidad"
                                                inputProps={{
                                                    name: 'oportunidad',
                                                    id: 'oportunidad',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value="1ra">1ra</option>
                                                <option value="2da">2da</option>

                                            </Select>
                                            <FormHelperText>
                                                {props.touched.oportunidad && props.errors.oportunidad ? (props.errors.oportunidad) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Inicio"
                                            id="fecha"
                                            name="fecha"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.values.fecha}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.fecha && props.errors.fecha ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.fecha && props.errors.fecha ? (
                                                props.errors.fecha
                                            ) : null} 
                                            InputProps={FORMATOFECHAMAXHOY}
                                            />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={props.touched.dictamen && props.errors.dictamen ? (true) : false}>
                                            <InputLabel>Dictamen</InputLabel>
                                            <Select
                                                labelId="Dictamen"
                                                native
                                                value={props.values.dictamen}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Dictamen"
                                                inputProps={{
                                                    name: 'dictamen',
                                                    id: 'dictamen',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value="Aprobado por unanimidad">Aprobado por unanimidad</option>
                                                <option value="No aprobado">No aprobado</option>

                                            </Select>
                                            <FormHelperText>
                                                {props.touched.dictamen && props.errors.dictamen ? (props.errors.dictamen) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Comentarios"
                                            name="comentarios"
                                            variant="outlined"
                                            fullWidth
                                            value={props.values.comentarios}
                                            onChange={(e) => {
                                                let comen = e.target.value;
                                                props.setFieldValue("comentarios", comen.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.comentarios && props.errors.comentarios ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.comentarios && props.errors.comentarios ? (
                                                props.errors.comentarios
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="documento">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="documento"
                                                    name="documento"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Acta
                                                    {adjunto ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.documento && props.errors.documento) && (props.errors.documento)}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>

                                        </Grid>

                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}></Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.boton}>
                                            Guardar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}></Grid>
                                </Grid>
                            </form>
                        )
                    }}
                </Formik>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                props.metodo();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>

            </Paper >
        </div >
    );
}

export default AgregarPredoctoral;