import React, { useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import Swal from 'sweetalert'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import AgregarLinea from './AgregarLinea';
import Backdrop from '@material-ui/core/Backdrop';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));
function TablaLineas(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [recarga, setRecarga] = React.useState(true);
    const [nuevo, setNuevo] = React.useState(false);
    const [data, setData] = React.useState([]);
    const { useState } = React;
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(async () => {
        const rec = async () => {
            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            return null;
                        }
                    });
                });
            }
            // await axios.get(Global + "linea/estatus/alta/profesor/" + props.data.idprofesor + "/lineaestatus/alta", {
            await axios.get(Global + "lineaprof/estatus/alta/profesor/" + props.data.idprofesor, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setData(response.data)
            }).catch(error => console.log(error))
        }
        await rec()
        setOpen(false)
    }, [recarga])

    // useEffect(async () => {
    //     await axios.get(Global + "programa", {
    //         headers: {
    //             'Access-Control-Allow-Origin': "http://localhost:3000",
    //             'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    //             'Content-Type': 'application/json',
    //             Authorization: 'Bearer ' + window.localStorage.getItem('token'),
    //         }
    //     }).then(response => {
    //         // console.log(response.data)
    //         setUbpps(response.data)
    //     }).catch(error => console.log(error))
    // }, [])

    const [columns, setColumns] = useState([
        { title: 'Línea', field: 'lineaInvestigacion.nombre' },
        { title: 'Nombre', field: 'lineaInvestigacion.descripcion' },
    ]);



    const actualizaNuevo = () => {
        setNuevo(false)
        setRecarga(!recarga)
    }





    return (
        open ? <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop> :
            nuevo ?
                <AgregarLinea data={props.data} metodo={actualizaNuevo} />
                :
                rol === "administrador" ||
                    rol === "coordinador_general" ||
                    rol === "coordinador_posgrado" ?
                    <MaterialTable
                        title={<Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Lineas de investigación
                            </Typography>
                            </Grid>
                        </Grid>}
                        options={{
                            exportButton:true,
                            exportFileName:"Lineas de investigación",
                            search: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay información por mostrar',
                                addTooltip: 'Agregar',
                                editTooltip: 'Editar',
                                deleteTooltip: 'Deshabilitada',
                                editRow: {
                                    deleteText: '¿Desea eliminar la linea de investigación?',
                                    cancelTooltip: 'Cancelar',
                                    saveTooltip: 'Guardar'
                                }
                            },
                            toolbar: {
                                searchTooltip: 'Buscar en la tabla',
                                searchPlaceholder: 'Buscar',
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                exportTitle: "Exportar"
                            },
                            header: {
                                actions: 'Acciones'
                            }

                        }}
                        columns={columns}
                        data={data}
                        actions={[
                            {
                                icon: 'add_box',
                                tooltip: 'Agregar',
                                isFreeAction: true,
                                onClick: (event) => {
                                    // console.log(event.target.value)
                                    setNuevo(true)
                                }
                            }
                        ]}
                        editable={{
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                        const dataDelete = [...data];
                                        const index = oldData.tableData.id;
                                        // console.log(dataDelete)
                                        var changeData = {
                                            estatus: 'baja',
                                            lineaInvestigacion: {
                                                idlinea: dataDelete[index].lineaInvestigacion.idlinea
                                            },
                                            profesor: {
                                                idprofesor: dataDelete[index].profesor.idprofesor
                                            }
                                        }

                                        await axios.put(Global + "lineaprof/" + dataDelete[index].idlip, changeData,
                                            {
                                                headers: {
                                                    'Access-Control-Allow-Origin': "http://localhost:3000",
                                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                }
                                            }).then(response => {
                                                // console.log(response);
                                                dataDelete.splice(index, 1);
                                                setData([...dataDelete]);
                                                Swal({
                                                    title: 'Linea eliminada',
                                                    text: ' ',
                                                    icon: 'success',
                                                    button: false,
                                                    timer: 1000
                                                })
                                            }).catch(error => {
                                                console.log(error);
                                            })

                                        resolve()
                                    }, 1000)
                                }),
                        }}
                    />
                    :
                    <MaterialTable
                        title={<Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Lineas de investigación
                        </Typography>
                            </Grid>
                        </Grid>}
                        options={{
                            exportButton:true,
                            exportFileName:"Lineas de investigación",
                            search: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay información por mostrar',
                            },
                            toolbar: {
                                searchTooltip: 'Buscar en la tabla',
                                searchPlaceholder: 'Buscar',
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                exportTitle: "Exportar"
                            },

                        }}
                        columns={columns}
                        data={data}

                    />


    )
}

export default TablaLineas;