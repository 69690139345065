import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../../constants/global';
import Swal from 'sweetalert'
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, Grid, InputLabel, Paper, Select, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#b1cde3',
        marginTop: '2%',
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: '5%',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function AgregarMateria(props) {

    const [open, setOpen] = useState(false);
    const [materias, setMaterias] = useState([]);
    const [programa, setPrograma] = useState('');
    const [criterio, setCriterio] = useState('');
    const { idplan } = props.programa;
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([
        { title: 'Materia', field: 'materia.nombre' },
    ]);
    const [acceso, setAcceso] = useState(false)


    useEffect(() => {

        props.rol === "coordinador_general" ? setAcceso(true) : setAcceso(false)
        //Remplazar esta consulta por una de estado tambien 
        const datos = async () => {
            await axios.get(Global + "planmateria/plan/" + idplan + "/estatus/alta", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "materia/materia/" + props.programa.idplan, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setMaterias(response.data)
            }).catch(error => console.log(error))
        }

        datos()

    }, [])

    const handleClickOpen = () => {
        setOpen(true);
        setPrograma('')
    };

    const handleClose = () => {
        setOpen(false);
        setPrograma('')
    };

    const handleSubmit = (data) => {
        if (programa !== '') {
            if (criterio !== '') {
                axios.post(Global + "planmateria/plan/" + idplan + "/materia/" + programa,
                    {
                        estatus: 'alta',
                        criterio: criterio
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(async () => {
                        setPrograma('')
                        setCriterio('')

                        await axios.get(Global + "planmateria/plan/" + idplan + "/estatus/alta", {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            setData(response.data)
                        }).catch(error => console.log(error))

                        await axios.get(Global + "materia/materia/" + props.programa.idplan, {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            setMaterias(response.data)
                        }).catch(error => console.log(error))

                        setOpen(false);
                        Swal({
                            title: 'Materia agregada',
                            text: ' ',
                            icon: 'success',
                            button: false,
                            timer: 1000
                        })
                    }).catch(error => console.log(error))
            } else {
                Swal({
                    title: '¡Debe seleccionar un criterio!',
                    text: ' ',
                    icon: 'error',
                    button: false,
                    timer: 1000
                })
            }
        } else {
            Swal({
                title: '¡Debe seleccionar una materia!',
                text: ' ',
                icon: 'error',
                button: false,
                timer: 1000
            })
        }
    }

    const handleOnChange = (e) => {
        setPrograma(e.target.value)
    }
    const handleOnChangeCriterio = (e) => {
        setCriterio(e.target.value)
    }

    return (
        <div>
            <Paper className={classes.root} elevation={3}>
                {
                    acceso ?
                        <>
                            <MaterialTable
                                title={
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center">
                                        <Grid item>
                                            <Typography className={classes.subtitulo}>
                                                Listado de materias por programa
                                </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                options={{
                                    search: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 30],
                                    headerStyle: {
                                        backgroundColor: '#66abe0b8',
                                        color: '#FFF'
                                    }
                                }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '',
                                        labelRowsSelect: '',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Siguiente página',
                                        lastTooltip: 'Última página',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay información por mostrar',
                                        addTooltip: 'Agregar',
                                        editTooltip: 'Editar',
                                        deleteTooltip: 'Deshabilitar',
                                        editRow: {
                                            deleteText: '¿Desea eliminar este programa de la unidad academica?',
                                            cancelTooltip: 'Cancelar',
                                            saveTooltip: 'Guardar'
                                        }
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar en la tabla',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    }

                                }}
                                columns={columns}
                                data={data}
                                actions={[
                                    {
                                        icon: 'add_box',
                                        tooltip: 'Agregar',
                                        isFreeAction: true,
                                        onClick: () => handleClickOpen()
                                    }
                                ]}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(async () => {
                                                const dataDelete = [...data];
                                                const index = oldData.tableData.id;
                                                var changeData = {
                                                    idplanma: dataDelete[index].idplanma,
                                                    planEstudio: dataDelete[index].planEstudio,
                                                    materia: dataDelete[index].materia,
                                                    estatus: 'baja',
                                                }
                                                dataDelete.splice(index, 1);
                                                setData([...dataDelete]);
                                                await axios.put(Global + "planmateria/" + changeData.idplanma, changeData,
                                                    {
                                                        headers: {
                                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                            'Content-Type': 'application/json',
                                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                        }
                                                    }).then(response => {

                                                        Swal({
                                                            title: 'Materia deshabilitada',
                                                            text: ' ',
                                                            icon: 'success',
                                                            button: false,
                                                            timer: 1000
                                                        })
                                                    }).catch(error => {
                                                        console.log(error);
                                                    })

                                                resolve()
                                            }, 1000)
                                        }),
                                }}
                            />
                            <Dialog open={open} onClose={handleClose} >
                                <DialogTitle>
                                    <DialogContentText style={{ marginBottom: -10 }}>Seleccione la materia que desea agregar al plan de estudio</DialogContentText>
                                </DialogTitle>
                                <DialogContent>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        required>
                                        <InputLabel id="materia">Materia</InputLabel>
                                        <Select
                                            labelId="materia"
                                            native
                                            label="Materia"
                                            onChange={e => {
                                                handleOnChange(e);
                                            }}
                                            inputProps={{
                                                name: 'materia',
                                                id: 'materia',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                materias.map((materia) => {
                                                    return <option key={materia.idmateria} value={materia.idmateria}>{materia.nombre}</option>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        required
                                        style={{ marginTop: 10 }}>
                                        <InputLabel id="criterio">Criterio de selección</InputLabel>
                                        <Select
                                            labelId="criterio de selección"
                                            native
                                            label="criterio"
                                            onChange={e => {
                                                handleOnChangeCriterio(e);
                                            }}
                                            inputProps={{
                                                name: 'criterio',
                                                id: 'criterio',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="tronco común">Tronco común</option>
                                            <option value="obligatoria">Obligatoria</option>
                                            <option value="electiva de orientacion">Electiva de la orientación</option>
                                            <option value="electiva de tronco común">Electiva de tronco común</option>
                                            <option value="electiva">Electiva</option>
                                            <option value="externa">Externa</option>

                                        </Select>

                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>
                                        Cancelar
                    </Button>
                                    <Button onClick={handleSubmit}>
                                        Agregar
                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                        :
                        <MaterialTable
                            title={
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo}>
                                            Listado de materias por programa
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            options={{
                                search: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30],
                                headerStyle: {
                                    backgroundColor: '#66abe0b8',
                                    color: '#FFF'
                                }
                            }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '',
                                    labelRowsSelect: '',
                                    firstTooltip: 'Primera página',
                                    previousTooltip: 'Página anterior',
                                    nextTooltip: 'Siguiente página',
                                    lastTooltip: 'Última página',
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay información por mostrar',
                                    addTooltip: 'Agregar',
                                    editTooltip: 'Editar',
                                    deleteTooltip: 'Deshabilitar',
                                    editRow: {
                                        deleteText: '¿Desea eliminar este programa de la unidad academica?',
                                        cancelTooltip: 'Cancelar',
                                        saveTooltip: 'Guardar'
                                    }
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar en la tabla',
                                    searchPlaceholder: 'Buscar'
                                },
                                header: {
                                    actions: 'Acciones'
                                }

                            }}
                            columns={columns}
                            data={data}

                        />

                }
            </Paper>

        </div>
    )
}

export default AgregarMateria;