import React, { useEffect, useState } from 'react'
import { Avatar, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { FILE_SIZE, SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },
}));

const RegistroPosgrado = () => {
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);

    //Subidas
    const [, setUploadRequisito1] = useState(0);
    const [, setUploadRequisito2] = useState(0);
    const [, setUploadRequisito3] = useState(0);

    const [, setUploadDocumento] = useState(0)
    const [, setUploadFinanciamiento] = useState(0)
    const [, setUploadAnteproyecto] = useState(0)

    //Mensajes
    const [, setListaMensaje] = useState('')
    const [, setListaMateriasMaestMensaje] = useState('')
    const [, setDocumentoMensaje] = useState('')
    const [, setFinanciamientoMensaje] = useState('')
    const [, setAnteproyectoMensaje] = useState('')

    const [, setRequisito1Mensaje] = useState('')
    const [, setRequisito2Mensaje] = useState('')
    const [, setRequisito3Mensaje] = useState('')

    const [documentoMensaje2, setDocumentoMensaje2] = useState(null)
    const [financiamientoMensaje2, setFinanciamientoMensaje2] = useState(null)
    const [anteproyectoMensaje2, setAnteproyectoMensaje2] = useState(null);
    const [requisito1Mensaje2, setRequisito1Mensaje2] = useState(null);
    const [requisito2Mensaje2, setRequisito2Mensaje2] = useState(null);
    const [requisito3Mensaje2, setRequisito3Mensaje2] = useState(null);

    //Archivos
    const [fileDocumento, setFileDocumento] = useState(0)
    const [fileFinanciamiento, setFileFinanciamiento] = useState(0)
    const [fileAnteproyecto, setFileAnteproyecto] = useState(0)

    const [fileRequisito1, setFileRequisito1] = useState(0);
    const [fileRequisito2, setFileRequisito2] = useState(0);
    const [fileRequisito3, setFileRequisito3] = useState(0);


    const [, setUsuario] = useState('');
    const [escolaridadID, setEscolaridadID] = useState('');
    const [posgrado, setPosgrado] = useState([]);
    const [claves, setClaves] = useState([]);
    const [programa, setPrograma] = useState([]);
    const [equipot, setEquipot] = useState(false);
    const [recarga, setRecarga] = useState(true);
    const [verDocs, setVerDocs] = useState(false)
    const classes = useStyles();
    const [periodo, setPeriodo] = useState({ clave: "", nombre: "" });

    useEffect(async () => {
        setPosgrado({
            estudios: '',
            posgrado: '',
            periodo: '',
            categoria: '',
            equipot: '',
            documento: '',
            financiamiento: '',
            anteproyecto: '',
            requisito1: '',
            requisito2: '',
            requisito3: '',
        })
        setClaves({
            idestudios: "",
            idprograma: "",
            idequipo: ""
        })

        let claves = {
            idestudios: "",
            idprograma: "",
            idequipo: ""
        }
        let posgrado = {
            estudios: '',
            posgrado: '',
            periodo: '',
            categoria: '',
            equipot: '',
            documento: '',
            financiamiento: '',
            anteproyecto: '',
            requisito1: '',
            requisito2: '',
            requisito3: '',
        }
        let programa = ''
        /**Usuario id */
        let userFB = fba.auth().currentUser;
        let user = '';
        await axios.get(Global + "persona/correo/" + userFB.email, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            user = response.data;
            setUsuario(response.data);
        }).catch(error => console.log(error))
        //setUsuario(user[0].idP);

        // let escolaridad = '';
        await axios.get(Global + "escolaridad/personas/" + user[0].idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async response => {

            //console.log(response.data[0].idescolaridad)

            setEscolaridadID(response.data[0].idescolaridad);
            posgrado.documento = response.data[0].finan

            if (response.data[0].finan !== null && response.data[0].finan !== "") {
                let storage3 = fba.storage().ref();
                let httpsReference3 = storage3.child('' + response.data[0].finan)
                setDocumentoMensaje2(await httpsReference3.getDownloadURL());
                setUploadDocumento(100)
                setDocumentoMensaje('Archivo cargado')
            }

        }).catch(error => console.log(error))
        await axios.get(`${Global}convocatoira/periodo`, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            //esta conversion se hace porque se lanzan las convocatorias para un periodo despues, es decir
            //en chapingo quieren mandar la convocatoria para primavera, entonces registran una convocatoria para otoño
            //consultar con el cliente o lider de proyecto o pm para mas informacion
            const letraPeriodo = response.data[0].slice(0, 1);
            const anioPeriodo = response.data[0].slice(1);
            if (letraPeriodo === "P") {
                setPeriodo({ clave: `O${anioPeriodo}`, nombre: `Otoño ${anioPeriodo}` })

            } else {
                setPeriodo({ clave: `P${anioPeriodo}`, nombre: `Primavera ${anioPeriodo}` })

            }

        }).catch((error) => {

        })
        //Campos para estudiosrea
        await axios.get(Global + "estudiosrea/persona/" + user[0].idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(async response => {
            posgrado.estudios = response.data[0].nivel
            posgrado.periodo = response.data[0].fechaini
            posgrado.categoria = response.data[0].cate
            posgrado.financiamiento = response.data[0].finan
            posgrado.anteproyecto = response.data[0].anteproyecto
            posgrado.posgrado = response.data[0].programa.idprograma + "-" + response.data[0].programa.programa
            posgrado.requisito1 = response.data[0].req1;
            posgrado.requisito2 = response.data[0].req2;
            posgrado.requisito3 = response.data[0].req3;

            if (response.data[0].nivel === "doctorado") {
                setVerDocs(true);
            }

            if (response.data[0].finan !== null && response.data[0].finan !== "") {
                // alert(response.data[0].finan)
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data[0].finan)
                setUploadFinanciamiento(100)
                setFinanciamientoMensaje('Archivo cargado')
                setFinanciamientoMensaje2(await httpsReference.getDownloadURL());
            }
            if (response.data[0].anteproyecto !== null && response.data[0].anteproyecto !== "") {
                // alert(response.data[0].finan)
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data[0].anteproyecto);
                setUploadAnteproyecto(100);
                setAnteproyectoMensaje('Archivo cargado');
                setAnteproyectoMensaje2(await httpsReference.getDownloadURL());
            }

            if (response.data[0].req1 !== null && response.data[0].req1 !== "") {

                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data[0].req1);
                setUploadRequisito1(100);
                setRequisito1Mensaje('Archivo cargado');
                setRequisito1Mensaje2(await httpsReference.getDownloadURL());
            }

            if (response.data[0].req2 !== null && response.data[0].req2 !== "") {

                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data[0].req2);
                setUploadRequisito2(100);
                setRequisito2Mensaje('Archivo cargado');
                setRequisito2Mensaje2(await httpsReference.getDownloadURL());
            }

            if (response.data[0].req3 !== null && response.data[0].req3 !== "") {

                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + response.data[0].req3);
                setUploadRequisito3(100);
                setRequisito3Mensaje('Archivo cargado');
                setRequisito3Mensaje2(await httpsReference.getDownloadURL());
            }


            if (response.data[0].programa.programa === "MAESTRÍA EN CIENCIAS EN DESARROLLO RURAL REGIONAL") {
                setEquipot(true);
            } else {
                setEquipot(false)
            }
            claves.idestudios = response.data[0].idposgrado
        }).catch(error => console.log(error))


        if (posgrado.estudios === "maestria" && posgrado.periodo.slice(0, 1) === "P") {
            axios.get(`${Global}programa/nivel/${posgrado.estudios}/periodo/otoño`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {

                programa = response.data
            }).catch(error => console.log(error))
        }
        else if (posgrado.estudios === "doctorado" && posgrado.periodo.slice(0, 1) === "P") {
            axios.get(`${Global}programa/nivel/${posgrado.estudios}/periodo/otoño`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                programa = response.data
            }).catch(error => console.log(error))
        } else if (posgrado.estudios === "maestria" && posgrado.periodo.slice(0, 1) !== "P") {
            axios.get(`${Global}programa/nivel/${posgrado.estudios}/periodo/primavera`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {

                programa = response.data
            }).catch(error => console.log(error))
        }
        else if (posgrado.estudios === "doctorado" && posgrado.periodo.slice(0, 1) !== "P") {
            axios.get(`${Global}programa/nivel/${posgrado.estudios}/periodo/primavera`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                programa = response.data
            }).catch(error => console.log(error))
        }
        else {
            setPrograma([]);
        }

        //Ver si existe equipo
        await axios.get(Global + "equipo/estudios/" + claves.idestudios, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data.length > 0) {
                posgrado.equipot = response.data[0].nombre
                claves.idequipo = response.data[0].idequipo
            } else {
                claves.idequipo = ""
            }
        }).catch(error => console.log(error))

        //Campos para equipo
        await axios.get(Global + "equipo/" + user[0].idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            //   posgrado.equipot = response.data
        }).catch(error => console.log(error))

        setPrograma(programa)
        setClaves(claves)
        setPosgrado(posgrado)
        handleCargaClose()
    }, [recarga])

    // console.log(posgrado)
    // console.log(claves)
    // console.log(programa)

    //Schema de validacion
    const schemaValidacion = Yup.object({
        estudios: Yup.string().required('Es obligatorio ingresar el estudio que desea realizar'),
        posgrado: Yup.string().required('Es obligatorio seleccionar el posgrado.'),
        periodo: Yup.string().required('Es obligatorio seleccionar el periodo'),
        categoria: Yup.string().required('Es obligatorio seleccionar la categoria.'),
        equipot: Yup.string().when('posgrado', (posgrado) => {
            if (posgrado !== undefined) {
                if (posgrado.split("-")[1] === "MAESTRÍA EN CIENCIAS EN DESARROLLO RURAL REGIONAL") {
                    return Yup.string().required("El campo es obligatorio para la maestria en ciencias en desarrollo rurral regional")
                } else {
                    return Yup.string()
                }
            }
        }),
        documento: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileDocumento === 0 || SUPPORTED_PDF.includes(fileDocumento.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileDocumento === 0 || fileDocumento.size <= FILE_SIZE))
            .test('fileRequired', "El documento es obligatorio",
        () => (fileDocumento !== 0 && fileDocumento !== false)),
        financiamiento: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileFinanciamiento === 0 || SUPPORTED_PDF.includes(fileFinanciamiento.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileFinanciamiento === 0 || fileFinanciamiento.size <= FILE_SIZE))
                .test('fileRequired', "El documento es obligatorio",
                () => ((fileFinanciamiento !== 0 && fileFinanciamiento !== false) || financiamientoMensaje2 !== null)),      
        anteproyecto: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileAnteproyecto === 0 || SUPPORTED_PDF.includes(fileAnteproyecto.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileAnteproyecto === 0 || fileAnteproyecto.size <= FILE_SIZE))
            .test('fileRequired', "El documento es obligatorio",
                () => ((fileAnteproyecto !== 0 && fileAnteproyecto !== false) || anteproyectoMensaje2 !== null)),  
        requisito1: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileRequisito1 === 0 || SUPPORTED_PDF.includes(fileRequisito1.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileRequisito1 === 0 || fileRequisito1.size <= FILE_SIZE)),
        requisito2: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileRequisito2 === 0 || SUPPORTED_PDF.includes(fileRequisito2.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileRequisito2 === 0 || fileRequisito2.size <= FILE_SIZE)),
        requisito3: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileRequisito3 === 0 || SUPPORTED_PDF.includes(fileRequisito3.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileRequisito3 === 0 || fileRequisito3.size <= FILE_SIZE)),
    })

    const initial = posgrado

    const funcionSubmit = async valores => {
        let escolaridad = {
            finan: "",
        };

        let id_programa = valores.posgrado.split("-")
        let estudios_realizados = {
            cate: valores.categoria,
            fechaini: valores.periodo,
            finan: "",
            anteproyecto: "",
            req1: "",
            req2: "",
            req3: "",
            nivel: valores.estudios,
            programa: {
                idprograma: id_programa[0]
            }
        }

        if (fileDocumento) {
            escolaridad.finan = fba.auth().currentUser.email + "/DocumentosEscolaridad/" + uuidv4() + fileDocumento.name
            const storageRef = fba.storage().ref(escolaridad.finan)
            const task = storageRef.put(fileDocumento)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadDocumento(Math.round(porcentage))
                setDocumentoMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setDocumentoMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setDiplomaMensaje('Archivo listo')
            })

        } else {
            if (posgrado.documento) {
                escolaridad.finan = posgrado.documento
            }
        }
        if (fileFinanciamiento) {
            // alert(fileFinanciamiento.name)
            estudios_realizados.finan = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileFinanciamiento.name
            const storageRef = fba.storage().ref(estudios_realizados.finan)
            const task = storageRef.put(fileFinanciamiento)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadFinanciamiento(Math.round(porcentage))
                setFinanciamientoMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setFinanciamientoMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setDiplomaMensaje('Archivo listo')
            })

        } else {
            if (posgrado.financiamiento) {
                estudios_realizados.finan = posgrado.financiamiento
            }
        }
        if (fileAnteproyecto) {
            // alert(fileFinanciamiento.name)
            estudios_realizados.anteproyecto = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileAnteproyecto.name
            const storageRef = fba.storage().ref(estudios_realizados.anteproyecto)
            const task = storageRef.put(fileAnteproyecto)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadAnteproyecto(Math.round(porcentage))
                setAnteproyectoMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setAnteproyectoMensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setDiplomaMensaje('Archivo listo')
            })

        } else {
            if (posgrado.anteproyecto) {
                estudios_realizados.anteproyecto = posgrado.anteproyecto
            }
        }


        if (fileRequisito1) {
            // alert(fileFinanciamiento.name)
            estudios_realizados.req1 = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileRequisito1.name
            const storageRef = fba.storage().ref(estudios_realizados.req1)
            const task = storageRef.put(fileRequisito1)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadRequisito1(Math.round(porcentage));
                setRequisito1Mensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setRequisito1Mensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setDiplomaMensaje('Archivo listo')
            })

        } else {
            if (posgrado.requisito1) {
                estudios_realizados.req1 = posgrado.requisito1
            }
        }

        if (fileRequisito2) {
            // alert(fileFinanciamiento.name)
            estudios_realizados.req2 = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileRequisito2.name
            const storageRef = fba.storage().ref(estudios_realizados.req2)
            const task = storageRef.put(fileRequisito2)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadRequisito2(Math.round(porcentage));
                setRequisito2Mensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setRequisito2Mensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setDiplomaMensaje('Archivo listo')
            })

        } else {
            if (posgrado.requisito2) {
                estudios_realizados.req2 = posgrado.requisito2
            }
        }

        if (fileRequisito3) {
            // alert(fileFinanciamiento.name)
            estudios_realizados.req3 = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileRequisito3.name
            const storageRef = fba.storage().ref(estudios_realizados.req3)
            const task = storageRef.put(fileRequisito3)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadRequisito3(Math.round(porcentage));
                setRequisito3Mensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setRequisito3Mensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setDiplomaMensaje('Archivo listo')
            })

        } else {
            if (posgrado.requisito3) {
                estudios_realizados.req3 = posgrado.requisito3
            }
        }

        let equipo = {
            nombre: valores.equipot
        }


        //Registro de estudios_rea
        let estudiosID = '';
        // alert(claves.idestudios)

        if ((!fileFinanciamiento && estudios_realizados.finan === '') ||
            (!fileAnteproyecto && estudios_realizados.anteproyecto === '')) {

            Swal({
                title: 'Cargar Archivos',
                text: ' ',
                icon: 'error',
                button: false,
                timer: 1000
            })
        } else {


            await axios.put(Global + "estudiosrea/" + claves.idestudios, estudios_realizados, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                estudiosID = response.data.idposgrado;

            }).catch(error => {
                console.log(error);
            })


            //Ver si existe equipo
            let id_equipo = "";
            await axios.get(Global + "equipo/estudios/" + claves.idestudios, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // alert(response.data[0].idequipo)
                if (response.data.length > 0) {
                    id_equipo = response.data[0].idequipo
                } else {
                    id_equipo = ""
                }
            }).catch(error => console.log(error))

            if (valores.equipot !== '') {
                // alert("No null")
                // alert(valores.equipot)
                axios.get(Global + "equipo/estudios/" + estudiosID, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    if (response.data.length > 0) {
                        // alert("Con")
                        axios.put(Global + "equipo/" + id_equipo, equipo, {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            // console.log(response);
                        }).catch(error => {
                            console.log(error);
                        })
                    } else {
                        // alert("Sin")
                        axios.post(Global + "equipo/estudiosrea/" + estudiosID, equipo, {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            // console.log(response);
                        }).catch(error => {
                            console.log(error);
                        })
                    }
                }).catch(error => {
                    console.log(error);
                })


            } else {
                // alert("Sin equipo")
            }

            axios.put(Global + "escolaridad/posgrados/" + escolaridadID, escolaridad, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            })

            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
        }
        setRecarga(!recarga)

    }

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "documento":
                    setFileDocumento(file)
                    setUploadDocumento(0)
                    setDocumentoMensaje('')
                    break;
                case "financiamiento":
                    setFileFinanciamiento(file)
                    setUploadFinanciamiento(0)
                    setFinanciamientoMensaje('')
                    break;
                case "anteproyecto_investigacion":
                    setFileAnteproyecto(file)
                    setUploadAnteproyecto(0)
                    setAnteproyectoMensaje('')
                    break;

                case "requisito1":
                    setFileRequisito1(file)
                    setUploadRequisito1(0)
                    setRequisito1Mensaje2('')
                    break;
                case "requisito2":
                    setFileRequisito2(file)
                    setUploadRequisito2(0)
                    setRequisito2Mensaje2('')
                    break;
                case "requisito3":
                    setFileRequisito3(file)
                    setUploadRequisito3(0)
                    setRequisito3Mensaje2('')
                    break;
                default:

                    break;
            }
        } else {
            switch (event.target.id) {
                case "documento":
                    setFileDocumento(false)
                    break;
                case "financiamiento":
                    setFileFinanciamiento(false)
                    break;
                case "anteproyecto_investigacion":
                    setFileAnteproyecto(false)
                    break;
                case "requisito1":
                    setFileRequisito1(false)
                    break;
                case "requisito2":
                    setFileRequisito2(false)
                    break;
                case "requisito3":
                    setFileRequisito3(false)
                    break;
                default:
                    break;
            }
        }
    }

    const handleChangePrograma = e => {
        let optionPosgrado = e.target.value.split("-")
            console.log(optionPosgrado,'wakandata')
        if (optionPosgrado[1] === "MAESTRÍA EN CIENCIAS EN DESARROLLO RURAL REGIONAL") {
            setEquipot(true);

        } else {
            setEquipot(false);
        }
    }

    const handleChangeEstudios = e => {

        const nuevosProgramas = [];
        if (e.target.value === "maestria" && periodo.clave.slice(0, 1) === "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/otoño`, {//preguntar a back porque otoño si estoy validando primavera
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {
                const listaProgramas = responses.data;
                console.log(listaProgramas)
                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(false)
        }
        else if (e.target.value === "doctorado" && periodo.clave.slice(0, 1) === "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/otoño`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {
                const listaProgramas = responses.data;

                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(true)

        } else if (e.target.value === "doctorado" && periodo.clave.slice(0, 1) !== "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/primavera`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {

                const listaProgramas = responses.data;

                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(true)

        } else if (e.target.value === "maestria" && periodo.clave.slice(0, 1) !== "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/primavera`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {

                const listaProgramas = responses.data;
                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(true)

        } else {
            setPrograma([]);
            setVerDocs(false)
        }
    }

    const [carga, setCarga] = React.useState(true);
    const handleCargaClose = () => {
        setCarga(false);
    };

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <MenuBookIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Seleccionar posgrado
                        </Typography>
                        <br />
                    </Grid>

                </Grid>

                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Estudios: Le recordamos que únicamente se puede registrar en un programa de posgrado</Typography>
                        </React.Fragment>
                        }
                    >
                    <Fab size="small" color="#f48124" className={classes.margin} >
                        <HelpOutlineIcon />
                    </Fab>
                </HtmlTooltip> 
                <br />
                <br />
                {carga ? <Backdrop className={classes.backdrop} open={carga}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.estudios && props.errors.estudios ? (true) : false}>
                                                <InputLabel >Estudios que desea realizar</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.estudios}
                                                    onChange={e => {
                                                        handleChangeEstudios(e);
                                                        props.setFieldValue("estudios", e.target.value);

                                                    }}
                                                    onBlur={props.handleBlur}
                                                    label="Estudios que desea realizar"
                                                    inputProps={{
                                                        name: 'estudios',
                                                        id: 'estudios',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'doctorado'}>Doctorado</option>
                                                    <option value={'maestria'}>Maestria</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.estudios && props.errors.estudios ? (props.errors.estudios) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.periodo && props.errors.periodo ? (
                                                    true
                                                ) : false}>
                                                <InputLabel>Periodo</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.periodo}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="periodo"
                                                    inputProps={{
                                                        name: 'periodo',
                                                        id: 'periodo',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={periodo.clave}>{periodo.nombre}</option>

                                                </Select>
                                                <FormHelperText>{props.touched.periodo && props.errors.periodo ? (
                                                    props.errors.periodo
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={props.touched.posgrado && props.errors.posgrado ? (true) : false}
                                            >
                                                <InputLabel >Posgrado</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.posgrado}
                                                    onChange={e => {
                                                        handleChangePrograma(e);
                                                        props.setFieldValue("posgrado", e.target.value);

                                                    }}
                                                    onBlur={props.handleBlur}
                                                    label="posgrado"
                                                    inputProps={{
                                                        name: 'posgrado',
                                                        id: 'posgrado',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        programa ?
                                                            programa.map((programa) => {
                                                                // if(programa.tipo.toLowerCase() === "maestria"){
                                                                return <option key={programa.idprograma} value={programa.idprograma + "-" + programa.programa}>{programa.programa}</option>
                                                                // }
                                                            }) : null
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.posgrado && props.errors.posgrado ? (props.errors.posgrado) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl}
                                                error={props.touched.categoria && props.errors.categoria ? (
                                                    true
                                                ) : false}>
                                                <InputLabel >Categoría</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.categoria}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="categoria"
                                                    inputProps={{
                                                        name: 'categoria',
                                                        id: 'categoria',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'Tiempo completo'}>Tiempo completo</option>
                                                </Select>
                                                <FormHelperText>{props.touched.categoria && props.errors.categoria ? (
                                                    props.errors.categoria
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {
                                        equipot ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.formControl}
                                                        error={props.touched.equipot && props.errors.equipot ? (
                                                            true
                                                        ) : false}>
                                                        <InputLabel >SEDE</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.equipot}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            label="Sede"
                                                            inputProps={{
                                                                name: 'equipot',
                                                                id: 'equipot',
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={'chapingo'}>Chapingo</option>
                                                            <option value={'morelia'}>Morelia</option>   
                                                            {/* <option value={'chiapas'}>Chiapas</option>
                                                            <option value={'michoacan'}>Michoacan</option>
                                                            <option value={'zacatecas'}>Zacatecas</option> */}
                                                        </Select>
                                                        <FormHelperText>{props.touched.equipot && props.errors.equipot ? (
                                                            props.errors.equipot
                                                        ) : null}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid> : null
                                    }

                                    <Grid
                                        container
                                        spacing={2}
                                        justify="center"
                                        // alignItems="center"
                                        item
                                    >
                                    </Grid>
                                    
                                    <br />

                                    <Grid item xs={12}>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">Adjuntar documentación en formato PDF, tamaño menor a 2 MB.
                                                Todos los documentos son obligatorios y deberan ser legibles, de lo contrario no seran aceptados. </Typography>
                                            </React.Fragment>
                                            }
                                        >
                                        <Fab size="small" color="#f48124" className={classes.margin} >
                                            <HelpOutlineIcon />
                                        </Fab>
                                    </HtmlTooltip>
                                    </Grid>
                                    <br />
                                    {verDocs ?
                                        <>
                                            
                                        </>
                                        : null}

                                    <Grid container direction="column" item xs={12} sm={7}>
                                        <label htmlFor="anteproyecto_investigacion">
                                            <input
                                                style={{ display: 'none' }}
                                                id="anteproyecto_investigacion"
                                                name="anteproyecto_investigacion"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                Proyecto de Investigación o Trabajo Profesional a Desarrollar

                                                {fileAnteproyecto ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.anteproyecto && props.errors.anteproyecto) && (props.errors.anteproyecto)}
                                            </Typography>
                                        </label>
                                        <Grid item xs={1} sm={2}>
                                            {/* <CircularProgress variant="determinate" value={uploadFinanciamiento} max='100' ></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {anteproyectoMensaje2 !== null && anteproyectoMensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={anteproyectoMensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>

                                        <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">Una carta a quien corresponda, donde indique quien financiara sus estudios de posgrado si usted  ya cuenta con un apoyo o beca; o bien, indicar que en caso de ser aceptado quiere ser considerado para obtener una beca por el CONACyT</Typography>
                                                    </React.Fragment>
                                                    }
                                                >
                                            <label htmlFor="financiamiento">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="financiamiento"
                                                    name="financiamiento"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento fuente de financiamiento

                                                    {fileFinanciamiento ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.financiamiento && props.errors.financiamiento) && (props.errors.financiamiento)}
                                                </Typography>
                                            </label>
                                        </HtmlTooltip>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {financiamientoMensaje2 !== null && financiamientoMensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={financiamientoMensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>

                                        <Grid>
                                        <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">En caso de haber tenido beca CONACyT, carta de liberación de CONACyT o constancia de trámite:</Typography>
                                                    </React.Fragment>
                                                    }
                                                >
                                                <label htmlFor="documento">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="documento"
                                                        name="documento"
                                                        type="file"
                                                        accept="application/pdf"
                                                        onChange={handleOnChange}
                                                    />
                                                    <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                                        Documento finiquito CONACYT

                                                        {fileDocumento ?
                                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                            : null}
                                                    </Button>
                                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                                        {(props.touched.documento && props.errors.documento) && (props.errors.documento)}
                                                    </Typography>
                                                </label>
                                            </HtmlTooltip>
                                            <Grid item xs={1} sm={2}>
                                                <Typography variant='body2'>
                                                    {documentoMensaje2 !== null && documentoMensaje2 !== '' ?
                                                        <a style={{ display: "table-cell" }} href={documentoMensaje2} target="_blank" rel="noreferrer">
                                                            Ver archivo
                                                        </a> : null}
                                                </Typography>
                                            </Grid>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">Requisito Especifico: Solo para el programa MAESTRÍA EN CIENCIAS EN ESTRATEGIA AGROEMPRESARIAL.</Typography>
                                                    </React.Fragment>
                                                    }
                                                >
                                                <Fab size="small" color="#f48124" className={classes.margin} >
                                                    <HelpOutlineIcon />
                                                </Fab>
                                            </HtmlTooltip>
                                        </Grid>




                                        <label htmlFor="requisito1">
                                            <input
                                                style={{ display: 'none' }}
                                                id="requisito1"
                                                name="requisito1"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                Matriz de Congruencia
                                                {fileRequisito1 ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.requisito1 && props.errors.requisito1) && (props.errors.requisito1)}
                                            </Typography>
                                        </label>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {requisito1Mensaje2 !== null && requisito1Mensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={requisito1Mensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                   {/*  <Grid container direction="column" item xs={12} sm={7}>

                                        <label htmlFor="requisito2">
                                            <input
                                                style={{ display: 'none' }}
                                                id="requisito2"
                                                name="requisito2"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                Carta de Intención
                                                {fileRequisito2 ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.requisito2 && props.errors.requisito2) && (props.errors.requisito2)}
                                            </Typography>
                                        </label>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {requisito2Mensaje2 !== null && requisito2Mensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={requisito2Mensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                    </Grid> */}
                                    {/* <Grid container direction="column" item xs={12} sm={7}>

                                        <label htmlFor="requisito3">
                                            <input
                                                style={{ display: 'none' }}
                                                id="requisito3"
                                                name="requisito3"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                Requisito Específico 3
                                                {fileRequisito3 ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.requisito3 && props.errors.requisito3) && (props.errors.requisito3)}
                                            </Typography>
                                        </label>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {requisito3Mensaje2 !== null && requisito3Mensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={requisito3Mensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>


                                    </Grid> */}
                                    <br />
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
        </div >
    );
}

export default RegistroPosgrado;