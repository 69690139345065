import React, { useEffect, useState } from 'react'
import { Avatar, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { FILE_SIZE, SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },
}));

const FormExperiencia = () => {
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);
    const classes = useStyles();
    const [experienciaAnterior, setexperienciaAnterior] = useState('')
    const [persona, setPersona] = useState('')
    const [FileCons1, setFileCons1] = useState(0)
    const [FileCons2, setFileCons2] = useState(0)
    const [FileCons3, setFileCons3] = useState(0)
    const [FileCV, setFileCV] = useState(0);
    const [, setUploadCons1] = useState(0)
    const [, setUploadCons2] = useState(0)
    const [, setUploadCons3] = useState(0)    
    const [, setUploadCV] = useState(0);
    const [, setMenCons1] = useState('')
    const [, setMenCons2] = useState('')
    const [, setMenCons3] = useState('')
    const [, setMenCV] = useState('');

    const [FilePub1, setFilePub1] = useState(0)
    const [FilePub2, setFilePub2] = useState(0)
    const [FilePub3, setFilePub3] = useState(0)    
    const [, setUploadPub1] = useState(0)
    const [, setUploadPub2] = useState(0)
    const [, setUploadPub3] = useState(0)    
    const [, setMenPub1] = useState('')
    const [, setMenPub2] = useState('')
    const [, setMenPub3] = useState('')    
    const [recarga, setRecarga] = useState(true)

    const [pub1, setPub1] = useState(null)
    const [pub2, setPub2] = useState(null)
    const [pub3, setPub3] = useState(null)
    const [cons1, setCons1] = useState(null)
    const [cons2, setCons2] = useState(null)
    const [cons3, setCons3] = useState(null)
    const [cv, setCV] = useState(null);

    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);
    useEffect(() => {
        setexperienciaAnterior({
            cons1: '',
            cons2: '',
            cons3: '',
            empresa: '',
            fechaini: '',
            fechater: '',
            lugar: '',
            actividad: '',
            pub1: '',
            pub2: '',
            pub3: '',
            cv:'',
        })

        //REVISAR SI EXITE YA EXPERIENCIA DE ESTA PERSONA 
        var user = fba.auth().currentUser;
        axios.get(Global + "persona/correo/" + user.email, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            setPersona(response.data)
            axios.get(Global + "experiencia/personas/" + response.data[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async responses => {
                //console.log(responses.data)
                //SI RESPONSES ESTA VACIO ES PORQ NO EXISTE UN REGISTRO
                if (responses.data[0]) {
                    console.log(responses.data[0])
                    setexperienciaAnterior(responses.data[0])
                    if (responses.data[0].pub1 !== null && responses.data[0].pub1 !== '') {
                        let storage = fba.storage().ref();
                        let httpsReference = storage.child('' + responses.data[0].pub1)
                        setPub1(await httpsReference.getDownloadURL());
                        setUploadPub1(100)
                        setMenPub1('Archivo cargado')

                    }
                    if (responses.data[0].pub2 !== null && responses.data[0].pub2 !== '') {
                        // alert(responses.data[0].pub2)
                        let storage2 = fba.storage().ref();
                        let httpsReference2 = storage2.child('' + responses.data[0].pub2)
                        setPub2(await httpsReference2.getDownloadURL());
                        setUploadPub2(100)
                        setMenPub2('Archivo cargado')
                    }
                    if (responses.data[0].pub3 !== null && responses.data[0].pub3 !== '') {
                        let storage3 = fba.storage().ref();
                        let httpsReference3 = storage3.child('' + responses.data[0].pub3)
                        setPub3(await httpsReference3.getDownloadURL());
                        setUploadPub3(100)
                        setMenPub3('Archivo cargado')
                    }
                    if (responses.data[0].cons1 !== null && responses.data[0].cons1 !== '') {
                        let storage4 = fba.storage().ref();
                        let httpsReference4 = storage4.child('' + responses.data[0].cons1)
                        setCons1(await httpsReference4.getDownloadURL());
                        setUploadCons1(100)
                        setMenCons1('Archivo cargado')
                    }
                    if (responses.data[0].cons2 !== null && responses.data[0].cons2 !== '') {
                        let storage5 = fba.storage().ref();
                        let httpsReference5 = storage5.child('' + responses.data[0].cons2)
                        setCons2(await httpsReference5.getDownloadURL());
                        setUploadCons2(100)
                        setMenCons2('Archivo cargado')
                    }
                    if (responses.data[0].cons3 !== null && responses.data[0].cons3 !== '') {
                        let storage6 = fba.storage().ref();
                        let httpsReference6 = storage6.child('' + responses.data[0].cons3)
                        setCons3(await httpsReference6.getDownloadURL());
                        setUploadCons3(100)
                        setMenCons3('Archivo cargado')
                    }
                    if (responses.data[0].cv !== null && responses.data[0].cv !== '') {
                        let storage7 = fba.storage().ref();
                        let httpsReference7 = storage7.child('' + responses.data[0].cv)
                        setCV(await httpsReference7.getDownloadURL());
                        setUploadCV(100);
                        setMenCV('Archivo cargado');
                    }
                }
            }).catch(error => console.log(error))

        }).catch(error => console.log(error))
        handleCargaClose()
    }, [recarga])

    const [carga, setCarga] = React.useState(true);
    const handleCargaClose = () => {
        setCarga(false);
    };

    const initial = experienciaAnterior

    //Schema de validacion
    const schemaValidacion = Yup.object({
        /* empresa: Yup.string().required('Es obligatorio ingresar el nombre de la empresa'),
        fechaini: Yup.string().required('Es obligatorio seleccionar la fecha en que inició'),
        fechater: Yup.string().required('Es obligatorio seleccionar la fecha en que termino')
            .test('match',
                'La fecha de termino no debe ser menor a la fecha de inicio',
                function (fechater) {
                    if (fechater !== "")
                        return fechater >= this.parent.fechaini
                }),
        lugar: Yup.string().required('Es obligatorio ingresar el lugar de trabajo'),
        actividad: Yup.string().required('Es obligatorio ingresar su actividad'),
        cons1: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (FileCons1 === 0 || SUPPORTED_PDF.includes(FileCons1.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (FileCons1 === 0 || FileCons1.size <= FILE_SIZE)),
        cons2: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (FileCons2 === 0 || SUPPORTED_PDF.includes(FileCons2.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (FileCons2 === 0 || FileCons2.size <= FILE_SIZE)),
        cons3: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (FileCons3 === 0 || SUPPORTED_PDF.includes(FileCons3.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (FileCons3 === 0 || FileCons3.size <= FILE_SIZE)),
        pub1: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (FilePub1 === 0 || SUPPORTED_PDF.includes(FilePub1.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (FilePub1 === 0 || FilePub1.size <= FILE_SIZE)),
        pub2: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (FilePub2 === 0 || SUPPORTED_PDF.includes(FilePub2.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (FilePub2 === 0 || FilePub2.size <= FILE_SIZE)),
        pub3: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (FilePub3 === 0 || SUPPORTED_PDF.includes(FilePub3.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (FilePub3 === 0 || FilePub3.size <= FILE_SIZE)), */
        cv: Yup.mixed()
        .test('fileType', "El formato del archivo es incompatible",
            () => (FileCV === 0 || SUPPORTED_PDF.includes(FileCV.type)))
        .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
            () => (FileCV === 0 || FileCV.size <= FILE_SIZE))
        .test( "fileRequired", "El documento es obligatorio",
            () =>(FileCV !== false && FileCV !== 0) || cv !== null
          ),
    })


    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]

            switch (event.target.id) {
                case "cons1":
                    setFileCons1(file)
                    setUploadCons1(0)
                    setMenCons1('')
                    break;
                case "cons2":
                    setFileCons2(file)
                    setUploadCons2(0)
                    setMenCons2('')
                    break;
                case "cons3":
                    setFileCons3(file)
                    setUploadCons3(0)
                    setMenCons3('')
                    break;
                case "pub1":
                    setFilePub1(file)
                    setUploadPub1(0)
                    setMenPub1('')
                    break;
                case "pub2":
                    setFilePub2(file)
                    setUploadPub2(0)
                    setMenPub2('')
                    break;
                case "pub3":
                    setFilePub3(file)
                    setUploadPub3(0)
                    setMenPub3('')
                    break;
                case "cv":
                    setFileCV(file)
                    setUploadCV(0)
                    setMenCV('')
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "cons1":
                    setFileCons1(false)
                    break;
                case "cons2":
                    setFileCons2(false)
                    break;
                case "cons3":
                    setFileCons3(false)
                    break;
                case "pub1":
                    setFilePub1(false)
                    break;
                case "pub2":
                    setFilePub2(false)
                    break;
                case "pub3":
                    setFilePub3(false)
                    break;
                case "cv":
                    setFileCV(false)
                    break;
                default:
                    break;
            }
        }
    }

    const funcionSubmit = valores => {
        axios.get(Global + "experiencia/personas/" + persona[0].idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(responses => {
            if (responses.data[0]) {
                setexperienciaAnterior(responses.data[0])
            }
        }).catch(error => console.log(error))

        if (experienciaAnterior.empresa === '') {
            let linkCon1 = ''
            let linkCon2 = ''
            let linkCon3 = ''
            let linkPub1 = ''
            let linkPub2 = ''
            let linkPub3 = ''
            let linkCV = '';     
            //Se revisa si se cargara o no cada archivo
            if (FileCons1) {
                linkCon1 = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FileCons1.name
                const storageRef = fba.storage().ref(linkCon1)
                const task = storageRef.put(FileCons1)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCons1(Math.round(porcentage))
                    setMenCons1(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenCons1('Error en la carga del archivo ' + error.message)
                }, () => {
                })
            }
            if (FileCons2) {
                linkCon2 = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FileCons2.name
                const storageRef = fba.storage().ref(linkCon2)
                const task = storageRef.put(FileCons2)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCons2(Math.round(porcentage))
                    setMenCons2(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenCons2('Error en la carga del archivo ' + error.message)
                }, () => {
                })

            }
            if (FileCons3) {
                linkCon3 = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FileCons3.name
                const storageRef = fba.storage().ref(linkCon3)
                const task = storageRef.put(FileCons3)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCons3(Math.round(porcentage))
                    setMenCons3(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenCons3('Error en la carga del archivo ' + error.message)
                }, () => {
                })
            }
            if (FilePub1) {
                linkPub1 = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FilePub1.name
                const storageRef = fba.storage().ref(linkPub1)
                const task = storageRef.put(FilePub1)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadPub1(Math.round(porcentage))
                    setMenPub1(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenPub1('Error en la carga del archivo ' + error.message)
                }, () => {
                })
            }
            if (FilePub2) {
                linkPub2 = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FilePub2.name
                const storageRef = fba.storage().ref(linkPub2)
                const task = storageRef.put(FilePub2)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadPub2(Math.round(porcentage))
                    setMenPub2(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenPub2('Error en la carga del archivo ' + error.message)
                }, () => {
                })

            }
            if (FilePub3) {
                linkPub3 = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FilePub3.name
                const storageRef = fba.storage().ref(linkPub3)
                const task = storageRef.put(FilePub3)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadPub3(Math.round(porcentage))
                    setMenPub3(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenPub3('Error en la carga del archivo ' + error.message)
                }, () => {
                })

            }
            if (FileCV) {
                
                linkCV = persona[0].correo + "/DocumentosExperiencia/" + uuidv4() + FileCV.name
                const storageRef = fba.storage().ref(linkCV)
                const task = storageRef.put(FileCV)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCV(Math.round(porcentage))
                    setMenCV(Math.round(porcentage) + '%')
                }, (error) => {
                    setMenCV('Error en la carga del archivo ' + error.message)                          
                }, () => {
                })

            }
            let experiencia = {
                cons1: linkCon1,
                cons2: linkCon2,
                cons3: linkCon3,
                empresa: valores.empresa,
                fechaini: valores.fechaini,
                fechater: valores.fechater,
                lugar: valores.lugar,
                actividad: valores.actividad,
                pub1: linkPub1,
                pub2: linkPub2,
                pub3: linkPub3,
                cv:linkCV
            };
            axios.post(Global + "experiencia/personas/" + persona[0].idP, experiencia, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
            }).catch(error => {
                console.log(error);
            })

        } else {
            let linkCon1 = experienciaAnterior.cons1
            let linkCon2 = experienciaAnterior.cons2
            let linkCon3 = experienciaAnterior.cons3
            let linkPub1 = experienciaAnterior.pub1
            let linkPub2 = experienciaAnterior.pub2
            let linkPub3 = experienciaAnterior.pub3
            let linkCV = experienciaAnterior.cv;
            if (FileCons1) {
                if (experienciaAnterior.cons1 === '') {
                    linkCon1 = persona[0].correo + '/DocumentosExperiencia/' + uuidv4() + FileCons1.name
                }
                const storageRef = fba.storage().ref(linkCon1)
                const task = storageRef.put(FileCons1)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCons1(porcentage)
                    setMenCons1(porcentage + '%')
                }, (error) => {
                    setMenCons1('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenCons1('Archivo listo')
                })
            }
            if (FileCons2) {
                if (experienciaAnterior.cons2 === '') {
                    linkCon2 = persona[0].correo + '/DocumentosExperiencia/' + uuidv4() + FileCons2.name
                }
                const storageRef = fba.storage().ref(linkCon2)
                const task = storageRef.put(FileCons2)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCons2(porcentage)
                    setMenCons2(porcentage + '%')
                }, (error) => {
                    setMenCons2('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenCons2('Archivo listo')
                })
            }
            if (FileCons3) {
                if (experienciaAnterior.cons3 === '') {
                    linkCon3 = persona[0].correo + '/DocumentosExperiencia/' + uuidv4() + FileCons3.name
                }
                const storageRef = fba.storage().ref(linkCon3)
                const task = storageRef.put(FileCons3)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCons3(porcentage)
                    setMenCons3(porcentage + '%')
                }, (error) => {
                    setMenCons3('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenCons3('Archivo listo')
                })
            }
            if (FilePub1) {
                if (experienciaAnterior.pub1 === '') {
                    linkPub1 = persona[0].correo + '/DocumentosExperiencia/' + FilePub1.name
                }
                const storageRef = fba.storage().ref(linkPub1)
                const task = storageRef.put(FilePub1)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadPub1(porcentage)
                    setMenPub1(porcentage + '%')
                }, (error) => {
                    setMenPub1('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenPub1('Archivo listo')
                })
            }
            if (FilePub2) {
                if (experienciaAnterior.pub2 === '') {
                    linkPub2 = persona[0].correo + '/DocumentosExperiencia/' + FilePub2.name
                }
                const storageRef = fba.storage().ref(linkPub2)
                const task = storageRef.put(FilePub2)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadPub2(porcentage)
                    setMenPub2(porcentage + '%')
                }, (error) => {
                    setMenPub2('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenPub2('Archivo listo')
                })
            }
            if (FilePub3) {
                if (experienciaAnterior.pub3 === '') {
                    linkPub3 = persona[0].correo + '/DocumentosExperiencia/' + FilePub3.name
                }
                const storageRef = fba.storage().ref(linkPub3)
                const task = storageRef.put(FilePub3)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadPub3(porcentage)
                    setMenPub3(porcentage + '%')
                }, (error) => {
                    setMenPub3('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenPub3('Archivo listo')
                })
            }
            if (FileCV) {
                if (experienciaAnterior.cv === '' || experienciaAnterior.cv == null) {
                    linkCV = persona[0].correo + '/DocumentosExperiencia/' + FileCV.name
                }
                const storageRef = fba.storage().ref(linkCV);
                const task = storageRef.put(FileCV);
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCV(porcentage)
                    setMenCV(porcentage + '%')
                }, (error) => {
                    setMenCV('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setMenPub3('Archivo listo')
                })
            }
            let experiencia = {
                cons1: linkCon1,
                cons2: linkCon2,
                cons3: linkCon3,
                empresa: valores.empresa,
                fechaini: valores.fechaini,
                fechater: valores.fechater,
                lugar: valores.lugar,
                actividad: valores.actividad,
                pub1: linkPub1,
                pub2: linkPub2,
                pub3: linkPub3,
                cv: linkCV,
            };            
            axios.put(Global + "experiencia/" + experienciaAnterior.idexperiencia, experiencia, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
            }).catch(error => {
                console.log(error);
            })

        }
        setRecarga(!recarga)
    }

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <BusinessCenterIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Experiencia profesional
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                {carga ? <Backdrop className={classes.backdrop} open={carga} onClick={handleCargaClose}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            //console.log(props);
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="Empresa"
                                                name="empresa"
                                                id="empresa"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.empresa}
                                                onChange={(e) => {
                                                    let emp = e.target.value;
                                                    props.setFieldValue("empresa", emp.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.empresa && props.errors.empresa ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.empresa && props.errors.empresa ? (
                                                    props.errors.empresa
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="Lugar"
                                                name="lugar"
                                                id="lugar"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.lugar}
                                                onChange={(e) => {
                                                    let lugar = e.target.value;
                                                    props.setFieldValue("lugar", lugar.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.lugar && props.errors.lugar ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.lugar && props.errors.lugar ? (
                                                    props.errors.lugar
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                label="Actividad"
                                                name="actividad"
                                                id="actividad"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.actividad}
                                                onChange={(e) => {
                                                    let actividad = e.target.value;
                                                    props.setFieldValue("actividad", actividad.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.actividad && props.errors.actividad ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.actividad && props.errors.actividad ? (
                                                    props.errors.actividad
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required variant="outlined" fullWidth className={classes.formControl} error={props.touched.fechaini && props.errors.fechaini ? (
                                                true
                                            ) : false}>
                                                <InputLabel >Año de inicio</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.fechaini}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="fecha de inicio"
                                                    inputProps={{
                                                        name: 'fechaini',
                                                        id: 'fechaini',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        years.map((year, index) => {
                                                            return <option key={`year${index}`} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.fechaini && props.errors.fechaini ? (
                                                    props.errors.fechaini
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required variant="outlined" fullWidth className={classes.formControl} error={props.touched.fechater && props.errors.fechater ? (
                                                true
                                            ) : false}>
                                                <InputLabel >Año de termino</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.fechater}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="fecha de termino"
                                                    inputProps={{
                                                        name: 'fechater',
                                                        id: 'fechater',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        years.map((year, index) => {
                                                            return <option key={`year${index}`} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.fechater && props.errors.fechater ? (
                                                    props.errors.fechater
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">Documento Obligatorio,
                                                        Únicamente se aceptan archivos en formato PDF, con un peso máximo de 2 MB. </Typography>
                                                </React.Fragment>
                                                }
                                            >
                                            <Fab size="small" color="#f48124" className={classes.margin} >
                                                <HelpOutlineIcon />
                                            </Fab>
                                       </HtmlTooltip>
                                    </Grid>
                                    <br />

                                    {/* <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="h6">
                                                Constancias de experiencia profesional
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="h6">
                                                Constancias de publicación
                                            </Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid container spacing={2}>
                                    <Grid item xs={6} sm={5}>
                                            <label htmlFor="cv">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="cv"
                                                    name="cv"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Currículum vitae
                                                    {FileCV ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.cv && props.errors.cv) && (props.errors.cv)}
                                                </Typography>
                                            </label>

                                        </Grid>
                                        
                                       
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadPub3} max='100' ></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {cv !== null && cv !== '' ?
                                                    <a style={{ display: "table-cell" }} href={cv} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={6} sm={5}>
                                                <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit">Adjuntar documentación en formato PDF, tamaño menor a 2 MB.
                                                                                            Constancias y Publicaciones no obligatorias, solo adjuntar las mas relevantes </Typography>
                                                            </React.Fragment>
                                                            }
                                                        >
                                                        <Fab size="small" color="#f48124" className={classes.margin} >
                                                            <HelpOutlineIcon />
                                                        </Fab>
                                                </HtmlTooltip>
                                            <label htmlFor="cons1">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="cons1"
                                                    name="cons1"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Constancia 1
                                                    {FileCons1 ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.cons1 && props.errors.cons1) && (props.errors.cons1)}
                                                </Typography>
                                            </label>

                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadCons1} max='100'></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {cons1 !== null && cons1 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={cons1} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <label htmlFor="pub1">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="pub1"
                                                    name="pub1"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Publicación 1
                                                    {FilePub1 ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.pub1 && props.errors.pub1) && (props.errors.pub1)}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadPub1} max='100'></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {pub1 !== null && pub1 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={pub1} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a>
                                                    : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={5}>

                                            <label htmlFor="cons2">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="cons2"
                                                    name="cons2"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Constancia 2
                                                    {FileCons2 ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.cons2 && props.errors.cons2) && (props.errors.cons2)}
                                                </Typography>
                                            </label>

                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadCons2} max='100'></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {cons2 !== null && cons2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={cons2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <label htmlFor="pub2">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="pub2"
                                                    name="pub2"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Publicación 2
                                                    {FilePub2 ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.pub2 && props.errors.pub2) && (props.errors.pub2)}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadPub2} max='100'></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {pub2 !== null && pub2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={pub2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <label htmlFor="cons3">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="cons3"
                                                    name="cons3"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Constancia 3
                                                    {FileCons3 ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.cons3 && props.errors.cons3) && (props.errors.cons3)}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadCons3} max='100'></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {cons3 !== null && cons3 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={cons3} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <label htmlFor="pub3">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="pub3"
                                                    name="pub3"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Publicación 3
                                                    {FilePub3 ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.pub3 && props.errors.pub3) && (props.errors.pub3)}
                                                </Typography>
                                            </label>

                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {/* <CircularProgress variant="determinate" value={UploadPub3} max='100' ></CircularProgress> */}
                                            <Typography variant='body2'>
                                                {pub3 !== null && pub3 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={pub3} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>

                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
        </div >
    );

}

export default FormExperiencia;
