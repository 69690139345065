import React, { useEffect, useState } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../../constants/global';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const AgregarAdscripcion = (props) => {
    // console.log(props)
    const classes = useStyles();
    const [paises, setPaises] = React.useState([])
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [recarga, setRecarga] = useState(false)
    const [carga, setCarga] = useState(false)

    useEffect(async () => {
        setRegistroAnterior({
            idins: '',
            nombre: '',
            pais: '',
            giro: '',
            pagweb: '',
        })
        await axios.get(Global + "pais", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response.data)
            setPaises(response.data)
        }).catch(error => console.log(error))

        handleClose()
    }, [recarga])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        nombre: Yup.string().required('Es obligatorio ingresar el nombre'),
        pais: Yup.string().required('Es obligatorio seleccionar un pais'),
        giro: Yup.string().required('Es obligatorio ingresar el giro de la institución'),

    })

    const initial = registroAnterior
    // console.log(registroAnterior)

    const funcionSubmit = async valores => {
        // console.log(valores)
        setCarga(true)
        let instituto = {
            nombre: valores.nombre,
            pais: {
                idpais: valores.pais
            },
            giro: valores.giro,
            pagweb: valores.pagweb,
        };

        await axios.get(Global + "institucion/nombre/" + valores.nombre, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data.length == 0) {
                axios.post(Global + "institucion/pais/" + valores.pais, instituto, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    // console.log(response);
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    setCarga(false)
                }).catch(error => {
                    console.log(error);
                    setCarga(false)
                })
            } else {
                Swal({
                    title: 'La institucion ya esta registrado',
                    text: ' ',
                    icon: 'error',
                    button: false,
                    timer: 1000
                })
            }
        }).catch(error => {
            // console.log(error);

        })
        props.metodo()
    }



    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>

                {open ? <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            //console.log(props);
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Nombre de la institución"
                                                name="nombre"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.nombre}
                                                onChange={(e)=>{
                                                    let nombre = e.target.value;
                                                    props.setFieldValue("nombre",nombre.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.nombre && props.errors.nombre ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.nombre && props.errors.nombre ? (
                                                    props.errors.nombre
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.pais && props.errors.pais ? (true) : false}>
                                                <InputLabel>Pais</InputLabel>
                                                <Select
                                                    labelId="pais"
                                                    native
                                                    // value={props.values.pais}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="pais"
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        paises.map((pais) => {
                                                            return <option key={pais.idpais} value={pais.idpais}>{pais.pais}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.pais && props.errors.pais ? (props.errors.pais) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Giro"
                                                name="giro"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.giro}
                                                onChange={(e)=>{
                                                    let giro = e.target.value;
                                                    props.setFieldValue("giro",giro.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.giro && props.errors.giro ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.giro && props.errors.giro ? (
                                                    props.errors.giro
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Pagina Web"
                                                name="pagweb"
                                                variant="outlined"
                                                fullWidth
                                                type='email'
                                                // value={props.values.pagweb}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.pagweb && props.errors.pagweb ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.pagweb && props.errors.pagweb ? (
                                                    props.errors.pagweb
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>

                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                props.metodo();
                            }}>
                            Cancelar
                                            </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>
            </Paper >
        </div >
    );
}

export default AgregarAdscripcion;