import React, { useEffect, useState } from 'react'
import { Avatar, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global, FILE_SIZE, SUPPORTED_PDF } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { v4 as uuidv4 } from 'uuid';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    margin: {
        margin: theme.spacing(1),
        color: "#EF6C00"
      },

}));

function RegistroPosgrado({ changePosgrado }) {
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '2px solid #f48124',
        },
      }))(Tooltip);

    //Subidas
    const [, setUploadDocumento] = useState(0)
    const [, setUploadFinanciamiento] = useState(0)
    const [, setUploadAnteproyecto] = useState(0)
    const [, setUploadRequisito1] = useState(0);
    const [, setUploadRequisito2] = useState(0);
    const [, setUploadRequisito3] = useState(0);

    //Mensajes
    const [, setDocumentoMensaje] = useState('')
    const [, setFinanciamientoMensaje] = useState('')
    const [, setAnteproyectoMensaje] = useState('')
    const [, setRequisito1Mensaje] = useState('');
    const [, setRequisito2Mensaje] = useState('');
    const [, setRequisito3Mensaje] = useState('');

    //Archivos
    const [fileDocumento, setFileDocumento] = useState(0)
    const [fileFinanciamiento, setFileFinanciamiento] = useState(0)
    const [fileAnteproyecto, setFileAnteproyecto] = useState(0);
    const [fileRequisito1, setFileRequisito1] = useState(0);
    const [fileRequisito2, setFileRequisito2] = useState(0);
    const [fileRequisito3, setFileRequisito3] = useState(0);

    const [usuario, setUsuario] = useState('');
    const [escolaridadID, setEscolaridadID] = useState('');

    const [posgrado, setPosgrado] = useState([]);

    const [open, setOpen] = useState(false);
    const [periodo, setPeriodo] = useState({ clave: "", nombre: "" });
    const [recarga, setRecarga] = useState(true);
    const [verDocs, setVerDocs] = useState(false);
    const classes = useStyles();

    useEffect(() => {

        setPosgrado({
            estudios: '',
            posgrado: '',
            periodo: '',
            categoria: '',
            equipot: '',
            documento: '',
            financiamiento: '',
            anteproyecto: '',
            requisito1: '',
            requisito2: '',
            requisito3: '',

        })

        const datosIniciales = async () => {
            /**Usuario id */
            let userFB = fba.auth().currentUser;
            let user = '';
            await axios.get(Global + "persona/correo/" + userFB.email, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                user = response.data;
                setUsuario(user[0].idP);

            }).catch(error => console.log(error))


            let escolaridad = '';
            await axios.get(Global + "escolaridad/personas/" + user[0].idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                escolaridad = response.data[0].idescolaridad
            }).catch(error => console.log(error))
            setEscolaridadID(escolaridad);

            await axios.get(`${Global}convocatoira/periodo`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {

                const letraPeriodo = response.data[0].slice(0, 1);
                const añoPeriodo = response.data[0].slice(1);
                if (letraPeriodo === "P") {
                    setPeriodo({ clave: `O${añoPeriodo}`, nombre: `Otoño ${añoPeriodo}` })

                } else {
                    setPeriodo({ clave: `P${añoPeriodo}`, nombre: `Primavera ${añoPeriodo}` })

                }

            }).catch((error) => {

            })
        }

        datosIniciales();

    }, [])


    //Schema de validacion
    const schemaValidacion = Yup.object({
        estudios: Yup.string().required('Es obligatorio ingresar el estudio que desea realizar'),
        posgrado: Yup.string().required('Es obligatorio seleccionar el posgrado.'),
        periodo: Yup.string().required('Es obligatorio seleccionar el periodo'),
        equipot: Yup.string().when('posgrado', (posgrado) => {
            if (posgrado !== undefined) {
                if (posgrado.split("-")[1] === "Maestría en Ciencias en Desarrollo Rural Regional") {
                    return Yup.string().required("El campo es obligatorio para la Maestría en Ciencias en Desarrollo Rural Regional")
                } else {
                    return Yup.string()
                }
            }
        }),
        documento: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileDocumento === 0 || SUPPORTED_PDF.includes(fileDocumento.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileDocumento === 0 || fileDocumento.size <= FILE_SIZE))
            .test('fileRequired', "El documento es obligatorio",
            () => (fileDocumento !== 0 && fileDocumento !== false)),
        financiamiento: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileFinanciamiento === 0 || SUPPORTED_PDF.includes(fileFinanciamiento.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileFinanciamiento === 0 || fileFinanciamiento.size <= FILE_SIZE))
            .test('fileRequired', "El documento es obligatorio",
        () => (fileFinanciamiento !== 0 && fileFinanciamiento !== false)),
        anteproyecto: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileAnteproyecto === 0 || SUPPORTED_PDF.includes(fileAnteproyecto.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileAnteproyecto === 0 || fileAnteproyecto.size <= FILE_SIZE))
            .test('fileRequired', "El documento es obligatorio",
                () => (fileAnteproyecto !== 0 && fileAnteproyecto !== false)),    
        requisito1: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileRequisito1 === 0 || SUPPORTED_PDF.includes(fileRequisito1.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileRequisito1 === 0 || fileRequisito1.size <= FILE_SIZE)),
                requisito2: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileRequisito2 === 0 || SUPPORTED_PDF.includes(fileRequisito2.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileRequisito2 === 0 || fileRequisito2.size <= FILE_SIZE)),
        requisito3: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileRequisito3 === 0 || SUPPORTED_PDF.includes(fileRequisito3.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileRequisito3 === 0 || fileRequisito3.size <= FILE_SIZE)),
        // posgrado.split("-")[1] === "Maestría en Ciencias en Desarrollo Rural Regional",//just an e.g. you can return a function
        // equipot: Yup.string().required('Es obligatorio selecionar el equipo de trabajo.'),
    })

    const handleClose = () => {
        setOpen(false);
    }

    const initial = posgrado

    const funcionSubmit = async valores => {

        let linkDocumento = ""
        let linkFinanciamiento = ""
        let linkAnteproyecto = "";
        let linkRequisito1 = "";
        let linkRequisito2 = "";
        let linkRequisito3 = "";

        if (fileDocumento) {
            linkDocumento = fba.auth().currentUser.email + "/DocumentosEscolaridad/" + uuidv4() + fileDocumento.name
            const storageRef = fba.storage().ref(linkDocumento)
            const task = storageRef.put(fileDocumento)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadDocumento(Math.round(porcentage))
                setDocumentoMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setDocumentoMensaje('Error en la carga del archivo ' + error.message)
            })
        }

        if (fileFinanciamiento) {
            linkFinanciamiento = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileFinanciamiento.name
            const storageRef = fba.storage().ref(linkFinanciamiento)
            const task = storageRef.put(fileFinanciamiento)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadFinanciamiento(Math.round(porcentage))
                setFinanciamientoMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setFinanciamientoMensaje('Error en la carga del archivo ' + error.message)
            })
        }
        if (fileAnteproyecto) {
            linkAnteproyecto = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileAnteproyecto.name
            const storageRef = fba.storage().ref(linkAnteproyecto)
            const task = storageRef.put(fileAnteproyecto)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadAnteproyecto(Math.round(porcentage))
                setAnteproyectoMensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setAnteproyectoMensaje('Error en la carga del archivo ' + error.message)
            })
        }

        if (fileRequisito1) {
            linkRequisito1 = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileRequisito1.name
            const storageRef = fba.storage().ref(linkRequisito1)
            const task = storageRef.put(fileRequisito1)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadRequisito1(Math.round(porcentage))
                setRequisito1Mensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setRequisito1Mensaje('Error en la carga del archivo ' + error.message)
            })
        }

        if (fileRequisito2) {
            linkRequisito2 = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileRequisito2.name
            const storageRef = fba.storage().ref(linkRequisito2)
            const task = storageRef.put(fileRequisito2)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadRequisito2(Math.round(porcentage))
                setRequisito2Mensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setRequisito2Mensaje('Error en la carga del archivo ' + error.message)
            })
        }

        if (fileRequisito3) {
            linkRequisito3 = fba.auth().currentUser.email + "/DocumentosEstudiosRealizados/" + uuidv4() + fileRequisito3.name
            const storageRef = fba.storage().ref(linkRequisito3)
            const task = storageRef.put(fileRequisito3)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadRequisito3(Math.round(porcentage))
                setRequisito3Mensaje(Math.round(porcentage) + '%')
            }, (error) => {
                setRequisito3Mensaje('Error en la carga del archivo ' + error.message)
            })
        }


        let escolaridad = {
            finan: linkDocumento,
        };

        let estudios_realizados = {
            cate: valores.categoria,
            fechaini: valores.periodo,
            finan: linkFinanciamiento,
            nivel: valores.estudios,
            anteproyecto: linkAnteproyecto,
            req1: linkRequisito1,
            req2: linkRequisito2,
            req3: linkRequisito3
        }

        let equipo = {
            nombre: valores.equipot
        }
        let id_programa = valores.posgrado.split("-")
        let estudiosID = '';
        if ((!fileFinanciamiento && estudios_realizados.finan === '') ||
            (!fileAnteproyecto && estudios_realizados.anteproyecto === '')) {

            Swal({
                title: 'Cargar Archivos',
                text: ' ',
                icon: 'error',
                button: false,
                timer: 1000
            })
        } else {
            await axios.post(Global + "estudiosrea/persona/" + usuario + "/programa/" + id_programa[0], estudios_realizados, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                estudiosID = response.data.idposgrado;
            }).catch(error => {
                console.log(error);
            })

            if (valores.equipot !== '') {
                // alert("No null")
                // alert(escolaridadID)
                axios.post(Global + "equipo/estudiosrea/" + estudiosID, equipo, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).catch(error => {
                    console.log(error);
                })
            }

           


                await axios.put(Global + "escolaridad/posgrados/" + escolaridadID, escolaridad, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    changePosgrado(true)
                }).catch(error => {
                    console.log(error.response);
                })

                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
            
        }//else validacion
        setRecarga(!recarga)
    }

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "documento":
                    setFileDocumento(file)
                    setUploadDocumento(0)
                    setDocumentoMensaje('')
                    break;
                case "financiamiento":
                    setFileFinanciamiento(file)
                    setUploadFinanciamiento(0)
                    setFinanciamientoMensaje('')
                    break;
                case "anteproyecto":
                    setFileAnteproyecto(file)
                    setUploadAnteproyecto(0)
                    setAnteproyectoMensaje('')
                    break;
                case "requisito1":
                    setFileRequisito1(file);
                    setUploadRequisito1(0);
                    setRequisito1Mensaje('');
                    break;
                case "requisito2":

                    setFileRequisito2(file);
                    setUploadRequisito2(0);
                    setRequisito2Mensaje('');
                    break;
                case "requisito3":
                    setFileRequisito3(file);
                    setUploadRequisito3(0);
                    setRequisito3Mensaje('');
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "documento":
                    setFileDocumento(false)
                    break;
                case "financiamiento":
                    setFileFinanciamiento(false)
                    break;
                case "anteproyecto":
                    setFileAnteproyecto(false)
                    break;
                case "requisito1":
                    setFileRequisito1(false)
                    break;
                case "requisito2":
                    setFileRequisito2(false)
                    break;
                case "requisito3":
                    setFileRequisito3(false)
                    break;
                default:
                    break;
            }
        }
    }

    const [equipot, setEquipot] = useState(false);
    const handleChangePrograma = e => {
        let optionPosgrado = e.target.value.split("-")

        // if (optionPosgrado[1] === "Maestría en Ciencias en Desarrollo Rural Regional") {
        if (optionPosgrado[1] === "MAESTRÍA EN CIENCIAS EN DESARROLLO RURAL REGIONAL") {
            setEquipot(true);
        } else {
            setEquipot(false);
        }
    }

    const [programa, setPrograma] = useState([]);

    const handleChangeEstudios = e => {
        const nuevosProgramas = [];
        if (e.target.value === "maestria" && periodo.clave.slice(0, 1) === "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/otoño`, {//preguntar a back porque otoño si estoy validando primavera
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {
                const listaProgramas = responses.data;

                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(false)
        }
        else if (e.target.value === "doctorado" && periodo.clave.slice(0, 1) === "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/otoño`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {
                const listaProgramas = responses.data;

                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(true)

        } else if (e.target.value === "doctorado" && periodo.clave.slice(0, 1) !== "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/primavera`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {

                const listaProgramas = responses.data;

                listaProgramas.map((programa) => {


                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);

                })

            }).catch(error => console.log(error))
            setVerDocs(true)

        } else if (e.target.value === "maestria" && periodo.clave.slice(0, 1) !== "P") {
            axios.get(`${Global}programa/nivel/${e.target.value}/periodo/primavera`, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(responses => {

                const listaProgramas = responses.data;
                listaProgramas.map((programa) => {

                    nuevosProgramas.push(programa);
                    setPrograma(nuevosProgramas);
                })

            }).catch(error => console.log(error))
            setVerDocs(true)

        } else {
            setPrograma([]);
            setVerDocs(false)
        }

    }

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <MenuBookIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Registro posgrado
                        </Typography>
                        <br />

                    </Grid>
                </Grid>
                 <br />
                {/* <Tooltip title="Estudios: Le recordamos que únicamente se puede registrar en un programa de posgrado." aria-label="add">
                    <Avatar className={classes.avatar}>
                        <HelpOutlineIcon />
                    </Avatar>
                </Tooltip> */}
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Estudios: le recordamos que únicamente se puede registrar en un programa de posgrado</Typography>
                        </React.Fragment>
                        }
                    >
                    <Fab size="small" color="#f48124" className={classes.margin} >
                        <HelpOutlineIcon />
                    </Fab>
                </HtmlTooltip> 
                <br />
                <br />
                <Formik
                    validationSchema={schemaValidacion}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        funcionSubmit(valores)
                    }}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="success">
                                            Información registrada
                                        </Alert>
                                    </Snackbar>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={props.touched.estudios && props.errors.estudios ? (true) : false}>
                                            <InputLabel >Estudios que desea realizar</InputLabel>
                                            <Select
                                                native
                                                value={props.values.estudios}
                                                onChange={e => {
                                                    handleChangeEstudios(e);
                                                    props.setFieldValue("estudios", e.target.value);

                                                }}
                                                onBlur={props.handleBlur}
                                                label="Estudios que desea realizar"
                                                inputProps={{
                                                    name: 'estudios',
                                                    id: 'estudios',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'doctorado'}>Doctorado</option>
                                                <option value={'maestria'}>Maestria</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.estudios && props.errors.estudios ? (props.errors.estudios) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.formControl}
                                            error={props.touched.periodo && props.errors.periodo ? (
                                                true
                                            ) : false}>
                                            <InputLabel>Periodo</InputLabel>
                                            <Select
                                                native
                                                value={props.values.periodo}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="periodo"
                                                inputProps={{
                                                    name: 'periodo',
                                                    id: 'periodo',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={periodo.clave}>{periodo.nombre}</option>

                                            </Select>
                                            <FormHelperText>{props.touched.periodo && props.errors.periodo ? (
                                                props.errors.periodo
                                            ) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={props.touched.posgrado && props.errors.posgrado ? (true) : false}>
                                            <InputLabel >Posgrado</InputLabel>
                                            <Select
                                                native
                                                value={props.values.posgrado}
                                                onChange={e => {
                                                    handleChangePrograma(e);
                                                    props.setFieldValue("posgrado", e.target.value);

                                                }}
                                                onBlur={props.handleBlur}
                                                label="posgrado"
                                                inputProps={{
                                                    name: 'posgrado',
                                                    id: 'posgrado',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {
                                                    programa ?
                                                        programa.map((programa) => {
                                                            return <option key={programa.idprograma} value={programa.idprograma + "-" + programa.programa}>{programa.programa}</option>
                                                        }) : null
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.posgrado && props.errors.posgrado ? (props.errors.posgrado) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="outlined" fullWidth className={classes.formControl}
                                            error={props.touched.categoria && props.errors.categoria ? (
                                                true
                                            ) : false}>
                                            <InputLabel >Categoría</InputLabel>
                                            <Select
                                                native
                                                value={props.values.categoria}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="categoria"
                                                inputProps={{
                                                    name: 'categoria',
                                                    id: 'categoria',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'Tiempo completo'}>Tiempo completo</option>
                                            </Select>
                                            <FormHelperText>{props.touched.categoria && props.errors.categoria ? (
                                                props.errors.categoria
                                            ) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {
                                    equipot ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    className={classes.formControl}
                                                    error={props.touched.equipot && props.errors.equipot ? (
                                                        true
                                                    ) : false}>
                                                    <InputLabel >SEDE</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.equipot}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Equipo de trabajo"
                                                        inputProps={{
                                                            name: 'equipot',
                                                            id: 'equipot',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={'chapingo'}>Chapingo</option>
                                                        <option value={'morelia'}>Morelia</option>
                                                        {/* <option value={'chiapas'}>Chiapas</option>
                                                        <option value={'michoacan'}>Michoacan</option>
                                                        <option value={'zacatecas'}>Zacatecas</option> */}
                                                    </Select>
                                                    <FormHelperText>{props.touched.equipot && props.errors.equipot ? (
                                                        props.errors.equipot
                                                    ) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid> : null
                                }
                                    <br/> 
                                <Grid item xs={12}>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">Adjuntar documentación en formato PDF, tamaño menor a 2 MB.
                                                Todos los documentos son obligatorios y deberán ser legibles, de lo contrario no serán aceptados. </Typography>
                                            </React.Fragment>
                                            }
                                        >
                                        <Fab size="small" color="#f48124" className={classes.margin} >
                                            <HelpOutlineIcon />
                                        </Fab>
                                    </HtmlTooltip>
                                    <br/> 
                                </Grid>
                                <br/> 
                                {verDocs ?
                                    <>
                                   
                                    </>
                                    : null}
                                <Grid container direction="column" item xs={12} sm={7}>
                                    <label htmlFor="anteproyecto">
                                        <input
                                            style={{ display: 'none' }}
                                            id="anteproyecto"
                                            name="anteproyecto"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleOnChange}
                                        />
                                        <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                            Proyecto de Investigación o Trabajo Profesional a Desarrollar
                                            {fileAnteproyecto ?
                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                : null}
                                        </Button>
                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                            {(props.touched.anteproyecto && props.errors.anteproyecto) && (props.errors.anteproyecto)}
                                        </Typography>


                                    </label>

                                    <Grid>
                                        {/* <Tooltip title="Una carta a quien corresponda, donde indique quien financiara sus estudios de posgrado si usted  ya cuenta con un apoyo o beca; o bien, indicar que en caso de ser aceptado quiere ser considerado para obtener una beca por el CONACyT" aria-label="add"> */}
                                        <label htmlFor="financiamiento">
                                            <input
                                                style={{ display: 'none' }}
                                                id="financiamiento"
                                                name="financiamiento"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                Documento fuente de financiamiento
                                                {fileFinanciamiento ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.financiamiento && props.errors.financiamiento) && (props.errors.financiamiento)}
                                            </Typography>


                                        </label>

                                    </Grid>

                                    <Grid>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">En caso de haber tenido beca CONACyT, carta de liberación de CONACyT o constancia de trámite: </Typography>
                                            </React.Fragment>
                                            }
                                        >
                                       {/*  <Tooltip title="En caso de haber tenido beca CONACyT, carta de liberación de CONACyT o constancia de trámite:" aria-label="tit"> */}
                                            <label htmlFor="documento">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="documento"
                                                    name="documento"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                   
                                                                
                                                 
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Documento finiquito CONACYT
                                                    {fileDocumento ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.documento && props.errors.documento) && (props.errors.documento)}
                                                </Typography>
                                            </label>
                                        {/*  </Tooltip> */}
                                       </HtmlTooltip>

                                    </Grid>

                                </Grid>



                                <Grid container direction="column" item xs={12} sm={7}>
                                <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">Requisito específico: Sólo para el programa MAESTRÍA EN CIENCIAS EN ESTRATEGIA AGROEMPRESARIAL.</Typography>
                                            </React.Fragment>
                                            }
                                        >
                                        <Fab size="small" color="#f48124" className={classes.margin} >
                                            <HelpOutlineIcon />
                                        </Fab>
                                    </HtmlTooltip>
                                    <label htmlFor="requisito1">
                                        <input
                                            style={{ display: 'none' }}
                                            id="requisito1"
                                            name="requisito1"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleOnChange}
                                        />
                                        <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                            Matriz de Congruencia
                                            {fileRequisito1 ?
                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                : null}
                                        </Button>
                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                            {(props.touched.requisito1 && props.errors.requisito1) && (props.errors.requisito1)}
                                        </Typography>


                                    </label>

                                </Grid>
                                {/* <Grid container direction="column" item xs={12} sm={7}>

                                    <label htmlFor="requisito2">
                                        <input
                                            style={{ display: 'none' }}
                                            id="requisito2"
                                            name="requisito2"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleOnChange}
                                        />
                                        <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                            Carta de Intención
                                            {fileRequisito2 ?
                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                : null}
                                        </Button>
                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                            {(props.touched.requisito2 && props.errors.requisito2) && (props.errors.requisito2)}
                                        </Typography>


                                    </label>

                                </Grid> */}
                               {/*  <Grid container direction="column" item xs={12} sm={7}>

                                    <label htmlFor="requisito3">
                                        <input
                                            style={{ display: 'none' }}
                                            id="requisito3"
                                            name="requisito3"
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleOnChange}
                                        />
                                        <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                            Requisito Específico 3
                                            {fileRequisito3 ?
                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                : null}
                                        </Button>
                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                            {(props.touched.requisito3 && props.errors.requisito3) && (props.errors.requisito3)}
                                        </Typography>


                                    </label>

                                </Grid> */}

                                <br />
                                <br />
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}></Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.boton}>
                                            Guardar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}></Grid>
                                </Grid>
                            </form>
                        )
                    }}
                </Formik>
            </Paper >
        </div>
    );

}

export default RegistroPosgrado;