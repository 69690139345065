import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import axios from "axios";
import { Global } from "../../../../constants/global"
import { Grid, Typography } from '@material-ui/core'
import { Hidden, Dialog, DialogTitle, DialogContent, CircularProgress } from "@material-ui/core"
import AgregarPlan from "./AgregarPlan";
import AgregarMateria from './AgregarMateria';
import { Auth } from "../../../../context/AuthContext";
import { fba } from "../../../../firebaseConfig";
import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function TablaPlanes(props) {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [recarga, setRecarga] = useState(false)
    const [scroll, setScroll] = React.useState('paper');
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')
    const { usuario } = useContext(Auth);
    const [change, setChange] = useState(false);


    const [columns, setColumns] = useState([
        { title: 'Clave plan', field: 'clave' },
        { title: 'Clave programa', field: 'programa.clave' },
        { title: 'Programa', field: 'programa.programa', validate: rowData => rowData.programa === '' ? { isValid: false, helperText: 'El campo nombre no puede ser vacío' } : true },
        {
            title: "Maya", field: "mapa",
            render: rowData => rowData.mapa !== "" ?
                <a style={{ display: "table-cell" }} href={rowData.mapa} target="_blank" rel="noreferrer">
                    Ver archivo
                </a> :
                null
        },
    ]);

    useEffect(() => {

        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "plan", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                let storage = fba.storage().ref();
                if (response.data) {
                    response.data.map(async (e, index) => {
                        if (e.mapa !== null && e.mapa !== '') {
                            let httpsReference = storage.child('' + e.mapa)
                            response.data[index].mapa = await httpsReference.getDownloadURL()
                        }
                    })                    
                }
                setData(response.data)

            }).catch(error => console.log(error))
            
            
        }
        
        datos()
        handleCargaClose();


    }, [recarga, change])



    const handleCargaClose = () => {
        setChange(true);
    };

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (valor) => {
        if (valor) {
            setRecarga(!recarga)
        }
        setOpen(false)
    }

    return (

        change ?
            rol === "coordinador_general" ?
                <>
                    <Dialog
                        open={open}
                        scroll={scroll}
                        maxWidth='sm'
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title" >
                            <Typography className={classes.subtitulo}>
                                Agregar
                    </Typography>
                        </DialogTitle>
                        <DialogContent dividers={scroll === 'paper'}>
                            <AgregarPlan metodo={handleClose} />
                        </DialogContent>
                    </Dialog>
                    <MaterialTable
                        title={
                            <Hidden smDown>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo}>
                                            Listado de planes de estudio
                                </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        }
                        options={{
                            exportButton:true,
                            exportFileName:"Listado de planes de estudio",
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            toolbar: {
                                searchTooltip: 'Buscar en la tabla',
                                searchPlaceholder: "Buscar",
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                exportTitle: "Exportar"
                            },
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                editRow: {
                                    deleteText: "¿Esta seguro que desea dar de baja la materia?",
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Dar de baja"
                                },
                                emptyDataSourceMessage: 'No hay información por mostrar',
                                deleteTooltip: "Dar de baja",

                            }
                        }}
                        data={data}
                        columns={columns}
                        actions={[
                            {
                                icon: 'add_box',
                                tooltip: 'Agregar',
                                isFreeAction: true,
                                onClick: () => handleClickOpen()
                            }
                        ]}
                        detailPanel={[
                            {
                                icon: 'more_vert',
                                openIcon: 'expand_less',
                                iconProps: { color: 'secondary' },
                                tooltip: 'Lista de materias',
                                render: rowData => {
                                    return (
                                        <AgregarMateria programa={rowData} rol={rol} />
                                    )
                                },
                            },
                        ]}
                    />
                </>
                :
                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de planes de estudio
                                </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    }
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de planes de estudio",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: "Buscar",
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {

                            emptyDataSourceMessage: 'No hay información por mostrar',

                        }
                    }}
                    data={data}
                    columns={columns}
                    detailPanel={[
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary' },
                            tooltip: 'Lista de materias',
                            render: rowData => {
                                console.log(rowData)
                                return (
                                    <AgregarMateria programa={rowData} rol={rol} />
                                )
                            },
                        },
                    ]}
                />
            :
            <Backdrop className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
    )
}
export default TablaPlanes
