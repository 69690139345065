import React, { useEffect, useState, useContext } from 'react'
import { Avatar, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { Auth } from "../../../context/AuthContext";
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import { FORMATOFECHAMAXHOY } from '../../generales/Fecha';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function Ocupacion(props) {

    const classes = useStyles();
    const { persona } = props.data
    const [ocupacionAnterior, setOcupacionAnterior] = useState('')
    const [paises, setPaises] = useState([]);
    const [paisselect, setPaisselect] = useState('pais')
    const [codigo, setCodigo] = useState(0);
    const [estados, setEstados] = useState([]);
    const [estadoselect, setEstadoselect] = useState('estado')
    const [ciudades, setCiudades] = useState([]);
    const [ciudadselect, setCiudadselect] = useState('ciudad')
    const [recarga, setRecarga] = useState('')
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [acceso, setAcceso] = useState(true)
    const [rcarga, setrCarga] = useState(false)



    useEffect(() => {

        setOcupacionAnterior({
            actividades: '',
            afinidad: '',
            area: '',
            giro: '',
            sector: '',
            paginaweb: '',
            fechater: '',
            notas: '',
            ocupacion: '',
            empresa: '',
            pais: '',
            cp: '',
            estado: '',
            ciudad: '',
            colonia: '',
            calle: '',
            direccion: '',
            fechaIn: '',
            telefono: '',
        })

        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            doc.data().rol === "administrador" ||
                                doc.data().rol === "coordinador_general" ||
                                doc.data().rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)
                        } else {
                            return null;
                        }
                    });
                });
            }

            await axios.get(Global + "pais", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaises(response.data)
            }).catch(error => console.log(error))


            //REVISAR SI EXITE YA EXPERIENCIA DE ESTA PERSONA 
            await axios.get(Global + "ocupacion/personas/" + persona.idP, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async responses => {

                await axios.get(Global + "codigo/cp/" + responses.data[0].cp, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    setEstados(response.data)
                }).catch(error => console.log(error))

                await axios.get(Global + "codigo/estado/" + responses.data[0].estado, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    setCiudades(response.data)
                }).catch(error => console.log(error))
                if (responses.data[0]) {
                    // console.log(responses.data)
                    setOcupacionAnterior(responses.data[0])
                    setPaisselect(responses.data[0].pais)
                    setCodigo(responses.data[0].cp)
                    setEstadoselect(responses.data[0].estado)
                    setCiudadselect(responses.data[0].ciudad)
                }
            }).catch(error => console.log(error))
            handleCargaClose()
        }

        datos();

    }, [recarga])

    const [carga, setCarga] = useState(true);

    const handleCargaClose = () => {
        setCarga(false);
    };

    const initial = ocupacionAnterior

    useEffect(() => {
        axios.get(Global + "codigo/cp/" + codigo, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setEstados(response.data)
        }).catch(error => console.log(error))
    }, [codigo])

    useEffect(() => {
        axios.get(Global + "codigo/estado/" + estadoselect, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setCiudades(response.data)
        }).catch(error => console.log(error))
    }, [estadoselect])

    const funcionSubmit = valores => {
        setrCarga(true)
        if (valores.pais !== 'MÉXICO') {
            valores.cp = ''
            valores.estado = ''
            valores.ciudad = ''
            valores.colonia = ''
            valores.calle = ''
        }
        let ocupacion = {
            actividades: valores.actividades,
            afinidad: valores.afinidad,
            area: valores.area,
            giro: valores.giro,
            sector: valores.sector,
            paginaweb: valores.paginaweb,
            fechater: valores.fechater,
            notas: valores.notas,
            ocupacion: valores.ocupacion,
            empresa: valores.empresa,
            pais: valores.pais,
            cp: valores.cp,
            estado: valores.estado,
            ciudad: valores.ciudad,
            colonia: valores.colonia,
            calle: valores.calle,
            direccion: valores.direccion,
            fechaIn: valores.fechaIn,
            telefono: valores.telefono,
        };
        if (ocupacionAnterior.ocupacion == '') {
            axios.post(Global + "ocupacion/persona/" + persona.idP, ocupacion, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
                setrCarga(false)
            }).catch(error => {
                console.log(error);
                setrCarga(false)
            })
        } else {
            axios.put(Global + "ocupacion/" + ocupacionAnterior.idocupacion, ocupacion, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                Swal({
                    title: 'Datos registrados',
                    text: ' ',
                    icon: 'success',
                    button: false,
                    timer: 1000
                })
                setrCarga(false)
            }).catch(error => {
                console.log(error);
                setrCarga(false)
            })
        }
        setRecarga('recarga')
    }

    //Schema de validacion
    const schemaValidacion = Yup.object({
        ocupacion: Yup.string().required('Es obligatorio ingresar la ocupacion actual')
    })

    const handleOnChangePais = (event) => {
        setPaisselect(event.target.value)
        //console.log(event)
    }
    const handleOnChangeCodigo = (event) => {
        setCodigo(event.target.value)
        //console.log(event)
    }
    const handleOnChangeEstado = (event) => {
        setEstadoselect(event.target.value)
        //console.log(event)
    }
    const handleOnChangeCiudad = (event) => {
        setCiudadselect(event.target.value)
        //console.log(event)
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={rcarga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <BusinessCenterIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Ocupación actual
                        </Typography>
                    </Grid>
                </Grid>

                <br />
                {carga ?
                    <Backdrop className={classes.backdrop} open={carga}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}>
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Institucion o Empresa"
                                                name="empresa"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.empresa}
                                                onChange={(e)=>{
                                                    let empresa = e.target.value;
                                                    props.setFieldValue("empresa",empresa.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Area, seccion o departamento"
                                                name="area"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.area}
                                                onChange={(e)=>{
                                                    let area = e.target.value;
                                                    props.setFieldValue("area",area.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.area && props.errors.area ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.area && props.errors.area ? (
                                                    props.errors.area
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                className={classes.formControl}
                                            >
                                                <InputLabel >Sector</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.sector}
                                                    onChange={props.handleChange}
                                                    label="Sector"
                                                    inputProps={{
                                                        name: 'sector',
                                                        id: 'sector',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={"PUBLICO"}>PUBLICO</option>
                                                    <option value={"PRIVADO"}>PRIVADO</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.sector && props.errors.sector ? (props.errors.sector) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Giro"
                                                name="giro"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.giro}
                                                onChange={(e)=>{
                                                    let giro = e.target.value;
                                                    props.setFieldValue("giro",giro.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                            >
                                            </TextField>
                                        </Grid>



                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Ocupacion"
                                                name="ocupacion"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.ocupacion}
                                                onChange={(e)=>{
                                                    let ocup = e.target.value;
                                                    props.setFieldValue("ocupacion",ocup.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.ocupacion && props.errors.ocupacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.ocupacion && props.errors.ocupacion ? (
                                                    props.errors.ocupacion
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Pagina web"
                                                name="paginaweb"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.paginaweb}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.paginaweb && props.errors.paginaweb ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.paginaweb && props.errors.paginaweb ? (
                                                    props.errors.paginaweb
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                className={classes.formControl}
                                            >
                                                <InputLabel >Pais</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.pais}
                                                    onChange={e => {
                                                        handleOnChangePais(e);
                                                        props.setFieldValue("pais", e.target.value);

                                                    }}
                                                    label="pais"
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        paises.map((pais) => {
                                                            return <option key={pais.idpais} value={pais.pais}>{pais.pais}</option>
                                                        })
                                                    }

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.pais && props.errors.pais ? (props.errors.pais) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Telefonos y extensiones"
                                                name="telefono"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.telefono}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.telefono && props.errors.telefono ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.telefono && props.errors.telefono ? (
                                                    props.errors.telefono
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>


                                        {
                                            (paisselect === 'MÉXICO') ?
                                                <>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Codigo Postal"
                                                            name="cp"
                                                            variant="outlined"
                                                            disabled={acceso}
                                                            fullWidth
                                                            value={props.values.cp}
                                                            onChange={e => {
                                                                handleOnChangeCodigo(e);
                                                                props.setFieldValue("cp", e.target.value);
                                                            }}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={acceso}
                                                            className={classes.formControl}
                                                        >
                                                            <InputLabel >Estado</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.estado}
                                                                onChange={e => {
                                                                    handleOnChangeEstado(e);
                                                                    props.setFieldValue("estado", e.target.value);

                                                                }}
                                                                label="estado"
                                                                inputProps={{
                                                                    name: 'estado',
                                                                    id: 'estado',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    estados.map((estado) => {
                                                                        return <option key={estado.estado} value={estado.estado}>{estado.estado}</option>
                                                                    })
                                                                }

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={acceso}
                                                            className={classes.formControl}
                                                        >
                                                            <InputLabel >Ciudad</InputLabel>
                                                            <Select
                                                                native
                                                                value={props.values.ciudad}
                                                                onChange={e => {
                                                                    handleOnChangeCiudad(e);
                                                                    props.setFieldValue("ciudad", e.target.value);
                                                                }}
                                                                label="ciudad"
                                                                inputProps={{
                                                                    name: 'ciudad',
                                                                    id: 'ciudad',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    ciudades.map((ciudad) => {
                                                                        return <option key={ciudad.ciudad} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                                    })
                                                                }

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Colonia"
                                                            name="colonia"
                                                            disabled={acceso}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={props.values.colonia}
                                                            onChange={props.handleChange}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Calle y numero "
                                                            name="calle"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={acceso}
                                                            value={props.values.calle}
                                                            onChange={(e)=>{
                                                                let calle = e.target.value;
                                                                props.setFieldValue("calle",calle.toUpperCase())
                                                            }}
                                                            onBlur={props.handleBlur}
                                                        >
                                                        </TextField>
                                                    </Grid>

                                                </> : null
                                        }


                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Direccion completa"
                                                name="direccion"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.direccion}
                                                onChange={(e)=>{
                                                    let direc = e.target.value;
                                                    props.setFieldValue("direccion",direc.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Fecha de inicio"
                                                name="fechaIn"
                                                disabled={acceso}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                                value={props.values.fechaIn}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                type="date"
                                                InputProps={FORMATOFECHAMAXHOY}
                                            />
                                            
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Fecha de termino"
                                                name="fechater"
                                                disabled={acceso}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                type="date"
                                                fullWidth
                                                value={props.values.fechater}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                InputProps={FORMATOFECHAMAXHOY}
                                            />
                                            
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Especifique la actividad"
                                                name="actividades"
                                                variant="outlined"
                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.actividades}
                                                onChange={(e)=>{
                                                    let act = e.target.value;
                                                    props.setFieldValue("actividades",act.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                type="text"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                className={classes.formControl}
                                            >
                                                <InputLabel >Existe afinidad con el programa</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.afinidad}
                                                    label="Existe afinidad con el programa"
                                                    onChange={props.handleChange}
                                                    inputProps={{
                                                        name: 'afinidad',
                                                        id: 'afinidad',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={"1"}>Si</option>
                                                    <option value={"0"}>No</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.afinidad && props.errors.afinidad ? (props.errors.afinidad) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Notas"
                                                name="notas"
                                                disabled={acceso}
                                                variant="outlined"
                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                fullWidth
                                                value={props.values.notas}
                                                onChange={(e)=>{
                                                    let notas = e.target.value;
                                                    props.setFieldValue("notas",notas.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <br />
                                    {
                                        !acceso ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                            </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            :
                                            null
                                    }

                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
        </div>
    );

}

export default Ocupacion;