import React, { useEffect } from 'react'
import { fba } from "../../../firebaseConfig";
import { Global } from '../../../constants/global';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Hidden, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios';
import { obtenerAlumnos } from '../../../servicios/obtenerAlumnos';
import { Button } from 'antd';
import { FORMATOFECHAMINHOY } from '../../generales/Fecha';
import swal from 'sweetalert';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    boton: {
        fontFamily: "Poppins, Sans-serif",
        fontSize: "15px",
        fontWeight: 600,
        textTransform: "none",
        lineHeight: "18px",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 1px",
        borderRadius: "6px 6px 6px 6px",
        padding: "16px 50px 16px 50px",
        backgroundColor: "#2361b2",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#1481e8",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaCitaExam(props) {

    const classes = useStyles();
    const { useState } = React;
    const [detalles, setDetalles] = useState('');
    const [open, setOpen] = React.useState(false);
    const [cita, setCita] = React.useState({ nombre: '' });
    const [alumno, setAlumno] = React.useState()
    const [citaDatos, setCitaDatos] = React.useState({
        fecha: '',
        hora: '',
        lugar: ''
    });
    const [errors, setErrors] = React.useState({
        fecha: '',
        hora: '',
        lugar: ''
    })

    const [columns, setColumns] = useState([
        {
            title: 'Nombre', field: 'persona.nombre'
        },
        {
            title: 'Paterno', field: 'persona.apellidoP'
        },
        {
            title: 'Materno', field: 'persona.apellidoM'
        },
        {
            title: 'Programa', field: 'programa.programa'
        },
    ]);
    /* const [actualizar, setActualizar] = useState(true) */
    const [data, setData] = useState([]);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');

    useEffect(() => {
        let userFB = fba.auth().currentUser;
        //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
        db.where('correo', '==', userFB.email).get().then(snapshot => {
            snapshot.docs.forEach(doc => {
                //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                if (doc.data().posgrado === 0) {
                    //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
                    axios.get(Global + "alumno/estatus/VG/", {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        setData(response.data)
                    }).catch(error => console.log(error))
                } else {
                    obtenerAlumnos(doc,'alumno/programa')
                        .then(programa=>{                            
                            let alumnos = [];
                            for(let alumno of programa){
                                console.log('lista de alumnos examen',alumnos)
                                alumnos = alumnos.concat(alumno);
                            }
                            setData(alumnos);
                        })
                        .catch((err)=>{console.log(err)})
                }
            });
        });
    }, [])  

    const handleClick = (event) => {
        axios.get(Global + "alumno/" + event.idalumno, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data) {

                setAlumno(response.data)
                setCita(event);
                setOpen(true);
            }
        }).catch(error => console.log(error));
    }
    const handleClose = () => {

        setOpen(false);
    };


    const handleSubmit = valores => {

        /* const validate = {
            fecha: '',
            hora: '',
            lugar: ''
        };
        if (citaDatos.fecha !== '') {
            if (citaDatos.hora !== '') {
                if (citaDatos.lugar !== '') {
                    let data = {
                        fecha: citaDatos.fecha,
                        hora: citaDatos.hora,
                        lugar: citaDatos.lugar,
                        razon: "Cita para examen profesional",
                        tipo: "examen"
                    }
                    //Se envian los datos capturados a la tabla de cita
                    axios.post(Global + "citae/alumno/" + cita.idalumno, data, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => { */
                        /* let cambio = alumno;
                        cambio.estatus = "VG"
                        //cambio.activo = 0
                        setAlumno(cambio)  */
                        //Actualizamos el estado de aspirante
                       /*  console.log('alumno',alumno)
                        axios.put(Global + "alumno/" + alumno.idalumno, alumno, {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => { */
                        //Aqui se haria la parte de envio de correo 
                        let correo = {
                            destinatario: [alumno.persona.correo],
                            alumno: alumno.persona.nombre + " " + alumno.persona.apellidoP + " " + alumno.persona.apellidoM,
                            fecha: citaDatos.fecha,
                            hora: citaDatos.hora,
                            lugar: citaDatos.lugar,
                        }
                            axios.post(Global + "email/citae?alumno=" +
                                alumno.persona.nombre + " " + alumno.persona.apellidoP + " " + alumno.persona.apellidoM
                                + "&destinatario=" + alumno.persona.correo
                                + "&fecha=" + citaDatos.fecha + "&hora=" + citaDatos.hora
                                + "&lugar=" + citaDatos.lugar,
                                {
                                    cita: {
                                        cita: "cita"
                                    }
                                },
                                {
                                    headers: {
                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                    }
                                }).then(response => {

                                    swal({
                                        title: 'La Carta ha sido enviada',
                                        text: ' ',
                                        icon: 'success',
                                        button: false,
                                        timer: 1000
                                      })
                                   /*  setActualizar(!actualizar); */
                                    handleClose()
                                    
                                }).catch(error => {
                                    console.log(error);
                                })

                                //catch del post para BD
                      /*   }).catch(error => {
                            console.log(error);
                        })
                    
                    
                } else {
                    validate.lugar = "Debe ingresar el lugar de la cita"
                    setErrors(validate)
                }
            } else {
                validate.hora = "Debe ingresar la hora de la cita"
                setErrors(validate)
            }
            setActualizar("ACTUALIZAR");
        } else {
            validate.fecha = "Debe ingresar la fecha para cita"
            setErrors(validate)
        } */
        //REALIZAR EL AXIOS PARA EL REGISTRO O MODIFICAION DE LA HORA DE CITA

    }



    return (
        <>
        <MaterialTable
            title={
                <Hidden smDown>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                            <Typography className={classes.subtitulo}>
                            Cita de Examen Profesional
                        </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            }      
            actions={[
                {  
                    tooltip: 'Asignar',                    
                    onClick: (e,rowData) =>{
                    handleClick(rowData)}
                },

            ]}
            
            components = {{

               Action: props => (
                <Button
                onClick={(event) => props.action.onClick(event, props.data)}   
                variant="contained"
                color="primary"
                className={classes.boton}>
                    Asignar
                </Button>
                ),


            }}
            options={{

                actionsColumnIndex:-1,
                actionsCellStyle:{width:"30%"},
                exportButton:true,
                exportFileName:"Asignación de citas",
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                columnsButton: true,
                headerStyle: {
                    backgroundColor: '#66abe0b8',
                    color: '#FFF'
                }
            }}
            localization={{
                header:{
                    actions:""
                },
                toolbar: {
                    exportCSVName: "Exportar como CSV",
                    exportPDFName: "Exportar como PDF",
                    exportTitle: "Exportar",
                    searchTooltip: 'Buscar en la tabla',
                    searchPlaceholder: "Buscar"
                },
                pagination: {
                    labelDisplayedRows: '',
                    labelRowsSelect: '',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Siguiente página',
                    lastTooltip: 'Última página',
                },
                body: {
                    emptyDataSourceMessage: 'No hay información por mostrar',
                }
            }}
            data={data}
            columns={columns}
            />
            <div>
                <Dialog open={open} onClose={handleClose} >
                    <DialogTitle>
                        <DialogContentText className={classes.subtitulo} style={{ marginBottom: -10 }}>Registrar cita</DialogContentText>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Ingrese los datos de la cita para el Examen: {cita.nombre}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fecha"
                            label="Fecha"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={valores => { citaDatos.fecha = valores.target.value }}
                            error={errors.fecha !== '' ? (
                                true
                            ) : false}
                            helperText={errors.fecha !== '' ? (
                                errors.fecha
                            ) : null}
                            InputProps={FORMATOFECHAMINHOY}
                        />

                        <TextField
                            margin="dense"
                            id="hora"
                            label="Hora"
                            type="time"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={valores => { citaDatos.hora = valores.target.value }}
                            error={errors.hora !== '' ? (
                                true
                            ) : false}
                            helperText={errors.hora !== '' ? (
                                errors.hora
                            ) : null}
                        />
                        <TextField

                            margin="dense"
                            id="lugar"
                            label="Lugar"
                            type="text"
                            fullWidth
                            onChange={valores => { citaDatos.lugar = valores.target.value }}
                            error={errors.lugar !== '' ? (
                                true
                            ) : false}
                            helperText={errors.lugar !== '' ? (
                                errors.lugar
                            ) : null}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" className={classes.boton}>
                            Cancelar
                                        </Button>
                        <Button onClick={handleSubmit} className={classes.boton}>
                            Enviar
                        </Button>
                    </DialogActions>
                  
                </Dialog>
            </div>

        </>
    );
 }
 export default TablaCitaExam;