import React, { useEffect, useState } from 'react'
import { FormControl, FormGroup, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import { Global } from '../../../constants/global';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "10%",
        marginRight: "10%",
    },
}));


function RegistroOtorgantes({ info }) {


    const [values, setValues] = useState({
        tiempo: '',
        nivel: '',
        cantidad: '',
        porciento: '',
        conocimiento: '',
        iniciativa: '',
        motivacion: '',
        responsabilidad: '',
        liderazgo: '',
        trabajobp: '',
        trabajoeq: '',
        comunor: '',
        comunes: '',
        flexibilidad: '',
        fortalezadeb: '',
        intelectual: '',
        orotgante: '',
        correo: '',
        estudio: '',
        ocupacion: '',
        institucion: '',
        celular: '',
    })
    const [ocupacion, setOcupacion] = useState({
        estudiante: false,
        tesista: false,
        profesionista: false,
        academico: false
    })


    useEffect(() => {

        let datos = async () => {
            await axios.get(Global + "cartad/carta/" + info, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if (response.data.length > 0) {
                    setValues(response.data[0])
                    let nivel = ""
                    if (response.data[0].nivel.split(",")[4] !== "") {
                        nivel = response.data[0].nivel.split(",")[4]
                    }
                    setValues({
                        orotgante: response.data[0].carta.orotgante,
                        correo: response.data[0].carta.correo,
                        estudio: response.data[0].carta.estudio,
                        ocupacion: response.data[0].carta.ocupacion,
                        institucion: response.data[0].carta.institucion,
                        celular: response.data[0].carta.celular,
                        fortalezadeb: response.data[0].carta.fortalezadeb,
                        nivel: nivel

                    })
                    
                    let ocupacion = {
                        estudiante: false,
                        tesista: false,
                        profesionista: false,
                        academico: false
                    }
                    // console.log(response.data[0].nivel.split(",")[0])
                    // console.log(response.data[0].nivel.split(",")[1])
                    // console.log(response.data[0].nivel.split(",")[2])
                    // console.log(response.data[0].nivel.split(",")[3])
                    if (response.data[0].nivel.split(",")[0] === "true") {
                        ocupacion.estudiante= true
                    }
                    if (response.data[0].nivel.split(",")[1] === "true") {
                        ocupacion.tesista = true
                    }
                    if (response.data[0].nivel.split(",")[2] === "true") {
                        ocupacion.profesionista = true
                    }
                    if (response.data[0].nivel.split(",")[3] === "true") {
                        ocupacion.academico = true
                    }
                

                    setOcupacion({
                        estudiante: ocupacion.estudiante,
                        tesista: ocupacion.tesista,
                        profesionista: ocupacion.profesionista,
                        academico: ocupacion.academico
                    });

                }

            }).catch(error => console.log(error));
        }

        datos()


    }, [])


    // console.log(values)
    const classes = useStyles();

    const initial = values

    // console.log(ocupacion);
    const { estudiante, tesista, profesionista, academico } = ocupacion;
    // console.log(profesionista);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initial}
            >
                {props => {
                    return (

                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1">
                                        Conozco al aspirante desde hace
                                            </Typography>
                                    <br />

                                    <TextField
                                        label="Años de conocido"
                                        name="tiempo"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.tiempo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}></Grid>


                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1">
                                        como (puede marcar varias opciones):
                                            </Typography>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled
                                                        checked={estudiante}
                                                        name="estudiante"
                                                        value="estudiante" />}
                                                label="Estudiante"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled
                                                        checked={tesista} name="tesista" />}
                                                value="tesista"
                                                label="Tesista"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox disabled checked={profesionista} name="profesionista" />}
                                                value="profesionista"
                                                label="Profesionista"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox disabled checked={academico} name="academico" />}
                                                value="academico"
                                                label="Acádemico"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        label="Otro"
                                        name="nivel"
                                        variant="outlined"
                                        disabled
                                        required
                                        fullWidth
                                        value={props.values.nivel}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>


                                <Grid item xs={12} >
                                    <Typography variant="body1">
                                        De entre aproximadamente:
                                            </Typography><br />
                                    <TextField
                                        label="Número de estudiantes"
                                        name="cantidad"
                                        variant="outlined"
                                        disabled
                                        type="text"
                                        fullWidth
                                        value={props.values.cantidad}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} >
                                    <Typography variant="body1">
                                        considero que este aspirante esta en
                                            </Typography><br />
                                    <TextField
                                        label="Porcentaje"
                                        name="porciento"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.porciento}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                    <Typography variant="body1">
                                        más alto.
                                    </Typography>
                                </Grid>

                            </Grid>
                            <br />
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Asignar una calificación entre 5 y 10
                                    (10 es el rango más alto) por cada uno
                                    de los atributos  que a su juicio posee
                                    el aspirante. Si considera que no tiene
                                    los elementos suficientes para valorar
                                    un atributo, califique con SC(Sin conocimiento)
                          </Typography>
                            </Grid>
                            <br />
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Habilidades del aspirante
                                        </Typography>
                            </Grid>
                            <br />
                            <Grid
                                container
                                spacing={2}>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        label="Conocimiento del campo de estudio"
                                        name="conocimiento"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.conocimiento}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Iniciativa"
                                        name="iniciativa"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.iniciativa}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Motivación (claridad en sus metas)"
                                        name="motivacion"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.motivacion}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Responsabilidad"
                                        name="responsabilidad"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.responsabilidad}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Capacidad de liderazgo"
                                        name="liderazgo"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.liderazgo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Habilidad de trabajo bajo presión"
                                        name="trabajobp"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.trabajobp}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Facilidad para trabajo en equipo"
                                        name="trabajoeq"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.trabajoeq}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Habilidad de comunicación oral"
                                        name="comunor"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.comunor}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    ></TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Habilidad de comunicación escrita"
                                        name="comunes"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.comunes}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>

                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Flexibilidad para adaptarse a niveles ambientes"
                                        name="flexibilidad"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.flexibilidad}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Habilidad intelectual"
                                        name="intelectual"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.intelectual}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>
                                </Grid>

                                <br />
                                <br />
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Información de la persona
                                        que otorgó esta carta de recomendación
                                  </Typography>
                                </Grid>
                                <br />
                                <br />

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Correo"
                                        name="correo"
                                        id="correo"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        value={props.values.correo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Nombre"
                                        name="orotgante"
                                        id="orotgante"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        value={props.values.orotgante}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Grado máximo de estudios"
                                        name="estudio"
                                        variant="outlined"
                                        type='text'
                                        disabled
                                        fullWidth
                                        value={props.values.estudio}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >

                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Ocupación"
                                        name="ocupacion"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        value={props.values.ocupacion}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Institución donde labora"
                                        name="institucion"
                                        id="institucion"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        value={props.values.institucion}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                    </TextField>
                                </Grid>

                                

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Principales Habilidades"
                                        name="fortalezadeb"
                                        id="fortalezadeb"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        multiline
                                        value={props.values.fortalezadeb}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Principales Debilidades"
                                        name="celular"
                                        id="celular"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        value={props.values.celular}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    >
                                    </TextField>

                                </Grid>
                            </Grid>
                        </>
                    )
                }}
            </Formik>

        </div>
    );
}

export default RegistroOtorgantes;