import React, { useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import Swal from 'sweetalert'
import DetallesProfesor from "./DetallesProfesor"
import { Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";
import { obtenerProfesores } from '../../../servicios/obtenerProfesores';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));
function TablaProfesores(props) {

    const classes = useStyles();
    const { useState } = React;
    const [detalles, setDetalles] = useState('');

    const [columns, setColumns] = useState([
        {
            title: 'Nombre', field: 'persona.nombre'
        },
        {
            title: 'Apellido Paterno', field: 'persona.apellidoM'
        },
        {
            title: 'Apellido Materno', field: 'persona.apellidoP'
        },
        
    ]);

    const [data, setData] = useState([]);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(() => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {

                    if (doc.data().posgrado === 0) {
                        axios.get(Global + "profesor/estatus/alta", {
                            headers: {
                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {
                            setData(response.data)
                        }).catch(error => console.log(error))
                    } else {
                        obtenerProfesores(doc,'profesor/estatus/programa/')
                        .then(programa=>{
                            let profesores = [];
                            for(let profesor of programa){
                                profesores = profesores.concat(profesor);
                            }
                            setData(profesores)
                        })
                        .catch((err)=>{console.log(err)})
                    }

                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }
                });
            });
        }
        
    }, [])

    useEffect(() => {
        if (detalles !== '') {
            props.metodo('Detalles de profesor')
        } else {
            props.metodo('Profesores')
        }
    }, [detalles])

    const redirect = (data) => {
        setDetalles(data);
    }

    return (
        detalles !== '' ?
            <>
                <DetallesProfesor data={detalles} metodo={redirect} />
            </>
            :
            rol === "administrador" ||
                rol === "coordinador_general" ||
                rol === "coordinador_posgrado" ?
                <MaterialTable
                    style={{
                        box_shadow: 'none'
                    }}
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de profesores",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea inhabilitar este docente?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones',
                        }

                    }}
                    title={
                        <Hidden smDown>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de profesores
                        </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>}
                    columns={columns}
                    data={data}
                    actions={[
                        {
                            icon: 'folder_shared',
                            tooltip: 'Detalles de Profesor',
                            onClick: (event, rowData) => {
                                redirect(rowData)
                                // console.log(rowData)
                            }
                        }
                    ]}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    const dataDelete = [...data];
                                    const index = oldData.tableData.id;
                                    // console.log(dataDelete)
                                    // console.log(index)
                                    var changeData = {
                                        abreviatura: dataDelete[index].abreviatura,
                                        estatus: 'baja',
                                        idprofesor: dataDelete[index].idprofesor,
                                        persona: dataDelete[index].persona,
                                    }
                                    dataDelete.splice(index, 1);
                                    setData([...dataDelete]);
                                    await axios.put(Global + "profesor/" + changeData.idprofesor, changeData, {
                                        headers: {
                                            'Access-Control-Allow-Origin': "http://localhost:3000",
                                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                            'Content-Type': 'application/json',
                                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                        }
                                    }).then(response => {
                                        // console.log(response);
                                        Swal({
                                            title: 'Profesor eliminado',
                                            text: ' ',
                                            icon: 'success',
                                            button: false,
                                            timer: 1000
                                        })
                                    }).catch(error => {
                                        console.log(error);
                                    })

                                    resolve()
                                }, 1000)
                            }),
                    }}
                />
                :
                <MaterialTable
                    style={{
                        box_shadow: 'none'
                    }}
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de profesores",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea inhabilitar este docente?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones',
                        }

                    }}
                    title={
                        <Hidden smDown>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de profesores
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>}
                    columns={columns}
                    data={data}
                    actions={[
                        {
                            icon: 'folder_shared',
                            tooltip: 'Detalles de Profesor',
                            onClick: (event, rowData) => {
                                redirect(rowData)
                                // console.log(rowData)
                            }
                        }
                    ]}
                />

    )
}

export default TablaProfesores;