import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#020e69',
            light: '#7986cb',
            contrastText: '#fffc'
        },
        secondary: {
            main: '#034AA7',
            light: '#ff4081',
            contrastText: '#fffc',
        },
        iconos: {
            main: '#2361b1',
            light: '#ff4081',
            contrastText: '#fffc',
        }
    }
});

export default theme;