import React, { useEffect, useState, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../constants/global';
import EscolaridadProfesor from './EscolaridadProfesor';
import RegistroEscolaridadP from './RegistroEscolaridadP';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaEscolaridad(props) {

    const classes = useStyles();
    const { persona } = props.data;
    const [data, setData] = useState([]);
    const [recarga, setRecarga] = useState(false);
    const [nuevo, setNuevo] = useState(false);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    const [columns, setColumns] = useState([
        {
            title: 'Grado', field: 'grado'
        },
        {
            title: 'Título', field: 'titulo'
        },
        {
            title: 'Cédula', field: 'cedula'
        },
    ]);



    useEffect(() => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        return null;
                    }
                });
            });
        }

        axios.get(Global + "escolaridad/personas/" + persona.idP, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response)
            setData(response.data)
        }).catch(error => console.log(error))
    }, [recarga])

    // useEffect(() => {
    //     if (detalles !== '') {
    //         props.metodo('Detalles de profesor')
    //     } else {
    //         props.metodo('Profesores')
    //     }
    // }, [detalles])

    const actualiza = () => {
        setRecarga(!recarga)
    }

    const cambioVista = () => {
        setNuevo(!nuevo)
    }

    const actualizarCambio = async () => {
        await actualiza()
        await cambioVista()
    }

    return (
        nuevo ?
            <RegistroEscolaridadP data={persona} metodo={actualizarCambio} />
            :
            rol === "administrador" ||
                rol === "coordinador_general" ||
                rol === "coordinador_posgrado" ?
                <MaterialTable
                    style={{
                        box_shadow: 'none'
                    }}
                    title={<Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                            <Typography className={classes.subtitulo}>
                                Escolaridad
                    </Typography>
                        </Grid>
                    </Grid>}
                    options={{
                        exportButton:true,
                        exportFileName:"Escolaridad",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            addTooltip: 'Agregar',
                            editTooltip: 'Editar',
                            deleteTooltip: 'Deshabilitada',
                            editRow: {
                                deleteText: '¿Desea eliminar la escolaridad?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones',
                        }

                    }}
                    columns={columns}
                    data={data}
                    detailPanel={[
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary', fontSize: 'medium' },
                            tooltip: 'Información de escolaridad',
                            render: rowData => {
                                return (
                                    <EscolaridadProfesor data={rowData} metodo={actualiza} rol={rol} />
                                )
                            },
                        },
                    ]}
                    actions={[
                        {
                            icon: 'add_box',
                            tooltip: 'Agregar',
                            isFreeAction: true,
                            onClick: (event) => cambioVista()
                        }
                    ]}
                />
                :
                <MaterialTable
                    style={{
                        box_shadow: 'none'
                    }}
                    title={<Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                            <Typography className={classes.subtitulo}>
                                Escolaridad
                    </Typography>
                        </Grid>
                    </Grid>}
                    options={{
                        exportButton:true,
                        exportFileName:"Escolaridad",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay información por mostrar',
                        },
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: 'Buscar',
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones',
                        }

                    }}
                    columns={columns}
                    data={data}
                    detailPanel={[
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary', fontSize: 'medium' },
                            tooltip: 'Información de escolaridad',
                            render: rowData => {
                                return (
                                    <EscolaridadProfesor data={rowData} metodo={actualiza} rol={rol} />
                                )
                            },
                        },
                    ]}

                />
    )
}

export default TablaEscolaridad;