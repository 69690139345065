import axios from "axios";
import { Global } from "../../../../constants/global";
export const uploadService = async ({ csv, entidad }) => {
  const formData = new FormData();
  formData.append("file", csv);
  let response = {
    success: "",
    failed: {},
  };
  const token = window.localStorage.getItem("token");
  const endpoint = `${Global}descargar/upload/entidad/${entidad}`;
  await axios
    .post(endpoint, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      response.success = res.data;
    })
    .catch((error) => {
      response.failed = error.response?.data || "error de conexión";
    });
  return response;
};
