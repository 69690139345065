import React, { useEffect, useState, useContext } from 'react'
import { Avatar, Paper, Typography, Button, TextField, Grid, FormControl, InputLabel, Select, FormHelperText, CircularProgress } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik"
import * as Yup from 'yup'
import axios from "axios";
import { Global } from "../../../constants/global"
import Swal from 'sweetalert'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Backdrop from '@material-ui/core/Backdrop';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import ArticuloTesis from './ArticuloTesis';
import LectorExterno from './LectorExterno';
import ComiteAsesor from './ComiteAsesor';
import { Auth } from "../../../context/AuthContext";
import { SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FORMATOFECHAMINHOY } from '../../generales/Fecha'
import ExamenProfesional from './ExamenProfesional';
import TablaCita from '../citas/TablaCita';
import JuradoExaminador from './JuradoExaminador';
import AgregarReunion from './AgregarReunion';
import TablaReunion from './TablaReunion';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    foto: {
        width: '180px',
        height: '180px'
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function Tesis(props) {

    // console.log(props)
    const classes = useStyles();
    const { idalumno } = props.data;
    const { correo } = props.data.persona;
    const { idprograma } = props.data.programa;
    const [datos, setDatos] = useState([])
    const [open, setOpen] = useState(true);
    const [portada, setPortada] = useState(0);
    const [linea, setLinea] = useState(null);
    const [acta, setActa] = useState(0);
    const [actaMensaje, setActaMensaje] = useState(null);
    const [portadaMensaje, setPortadaMensaje] = useState(null);
    const [profesores, setProfesores] = useState([]);
    const [existe, setExiste] = useState(false)
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [acceso, setAcceso] = useState(true)
    const [rol, setRol] = useState(true)
    const [carga, setCarga] = useState(false)


    useEffect(() => {

        let tesis = {
            archivo: '',
            docap: '',
            docmod: '',
            fechain: '',
            portada: '',
            resultado: '',
            tesis: '',
            alumno: '',
            linea: '',
            idtesis: '',
            clavet: '',
            sedere: '',
            entregado: '',
            modalidad: '',
            tipo: '',
        }

        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        // console.log(doc.data())
                        setRol(doc.data().rol)
                        if (usuario.email === doc.data().correo) {
                            doc.data().rol === "administrador" ||
                                doc.data().rol === "coordinador_general" ||
                                doc.data().rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)
                        } else {
                            // console.log("No se encuentra");
                            return null;
                        }
                    });
                });
            }

            await axios.get(`${Global}linea/programa/` + idprograma, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
            }).then((response) => {
                setLinea(response.data);
            }).catch((error) => console.log(error));

            await axios.get(`${Global}profesor`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
            }).then((response) => {
                setProfesores(response.data);
            }).catch((error) => console.log(error));

            await axios.get(Global + "tesis/alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                if (response.data.length > 0) {
                    tesis.idtesis = response.data[0].idtesis
                    tesis.archivo = response.data[0].archivo
                    tesis.docap = response.data[0].docap
                    tesis.docmod = response.data[0].docmod
                    tesis.fechain = response.data[0].fechain
                    tesis.portada = response.data[0].portada
                    tesis.resultado = response.data[0].resultado
                    tesis.tesis = response.data[0].tesis
                    tesis.linea = response.data[0].lineaInvestigacion.idlinea
                    tesis.clavet = response.data[0].clavet
                    tesis.sedere = response.data[0].sedere
                    tesis.entregado = response.data[0].entregado
                    tesis.modalidad = response.data[0].modalidad
                    tesis.tipo = response.data[0].tipo
                    // console.log("===",response.data[0].lineaInvestigacion.idlinea)

                    setExiste(true);
                }
            }).catch(error => console.log(error))

            setDatos(tesis)
            setOpen(false)

            if (tesis.portada !== null && tesis.portada !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + tesis.portada)
                setPortadaMensaje(await httpsReference.getDownloadURL());
            }
            if (tesis.archivo !== null && tesis.archivo !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + tesis.archivo)
                setActaMensaje(await httpsReference.getDownloadURL());
            }


        }

        datos()


    }, [open])

    const schemaValidation = Yup.object({
        archivo: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (acta === 0 || SUPPORTED_PDF.includes(acta.type))),
        portada: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (portada === 0 || SUPPORTED_PDF.includes(portada.type))),
        fechain: '',
        resultado: '',
        tesis: '',
        alumno: '',
        linea: ''
    })

    const functionSubmit = async (valores) => {
        setCarga(true)
        let linkActa = datos.archivo
        let linkPortada = datos.portada

        if (acta) {
            if (datos.archivo === '') {
                //console.log('entro')
                linkActa = correo + '/Tesis/' + uuidv4() + acta.name
            }
            // linkDocumento = correo + '/DocumentosPredoctoral/' + uuidv4() + adjunto.name
            const storageRef = fba.storage().ref(linkActa)
            const task = storageRef.put(acta)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        if (portada) {
            if (datos.portada === '') {
                //console.log('entro')
                linkPortada = correo + '/Tesis/' + uuidv4() + portada.name
            }
            // linkDocumento = correo + '/DocumentosPredoctoral/' + uuidv4() + adjunto.name
            const storageRef = fba.storage().ref(linkPortada)
            const task = storageRef.put(portada)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        let tesis = {
            alumno: {
                idalumno: ''
            },
            archivo: linkActa,
            docap: '',
            docmod: '',
            fechain: valores.fechain,
            lineaInvestigacion: {
                idlinea: valores.linea
            },
            portada: linkPortada,
            resultado: valores.resultado,
            sedere: valores.sedere,
            entregado: valores.entregado,
            modalidad: valores.modalidad,
            clavet: valores.clavet,
            tesis: valores.tesis,
            tipo: valores.tipo,
        }

        axios.get(Global + "tesis/alumno/" + idalumno, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async response => {

            if (response.data.length > 0) {

                tesis.alumno.idalumno = response.data[0].alumno.idalumno
                // tesis.lineaInvestigacion.idlinea = response.data[0].lineaInvestigacion.idlinea
                // console.log(tesis)
                await axios.put(Global + "tesis/" + response.data[0].idtesis, tesis, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(() => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    setCarga(false)
                    handleOpen()
                }).catch(error => console.log(error))
            } else {
                await axios.post(Global + "tesis/linea/" + valores.linea + "/alumno/" + idalumno, tesis, {
                    headers: {
                        'Access-Control-Allow-Origin': "http://localhost:3000",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(() => {
                    Swal({
                        title: 'Datos registrados',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    handleOpen()
                    setCarga(false)
                }).catch(error => console.log(error))
            }
        }).catch(error => console.log(error))



    }

    const handleOpen = () => {
        setOpen(true);
    };


    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "archivo":
                    setActa(file);
                    break;
                case "portada":
                    setPortada(file);
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "archivo":
                    setActa(false)
                    break;
                case "portada":
                    setPortada(false)
                    break;
                default:
                    break;
            }
        }
    }


    const initial = datos

    return (
        <>
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {open ?
                <Backdrop className={classes.backdrop} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <>
               
                    <Accordion>
                        <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                    {/* <Paper elevation={3} className={classes.paperContainer}></Paper> */}
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                   {/*  <MonetizationOnIcon /> */}
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Tesis
                                </Typography>
                            </Grid>
                        </Grid>
                        

                        </AccordionSummary>
                                <AccordionDetails>
                        <br />

                        <Formik
                            validationSchema={schemaValidation}
                            enableReinitialize
                            initialValues={initial}
                            onSubmit={(values) => {
                                functionSubmit(values)
                            }}
                        >
                            {props => {
                                return (
                                    <form noValidate onSubmit={props.handleSubmit}>
                                        <Grid container spacing={3}>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    error={props.touched.linea && props.errors.linea ? (true) : false}>
                                                    <InputLabel>Linea de investigación</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.linea}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Linea de investigación"
                                                        inputProps={{
                                                            name: 'linea',
                                                            id: 'linea',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {linea ?
                                                            linea.map((linea) => {
                                                                // console.log(linea.idlinea)
                                                                return <option key={linea.idlinea} value={linea.idlinea} >{linea.nombre}</option>
                                                            }) : null
                                                        }

                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.linea && props.errors.linea ? (props.errors.linea) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    label="Titulo de Tesis"
                                                    id="tesis"
                                                    name="tesis"
                                                    type="text"
                                                    value={props.values.tesis}
                                                    onChange={(e) => {
                                                        let tesis = e.target.value;
                                                        props.setFieldValue("tesis", tesis.toUpperCase())
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.tesis && props.errors.tesis ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.tesis && props.errors.tesis ? (
                                                        props.errors.tesis
                                                    ) : null} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    label="Clave de Tesis"
                                                    id="clavet"
                                                    name="clavet"
                                                    type="text"
                                                    value={props.values.clavet}
                                                    onChange={(e) => {
                                                        let clavet = e.target.value;
                                                        props.setFieldValue("clavet", clavet.toUpperCase())
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.clavet && props.errors.clavet ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.clavet && props.errors.clavet ? (
                                                        props.errors.clavet
                                                    ) : null} />
                                            </Grid>

                                            {/* <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    label="Fecha de examen"
                                                    type="date"
                                                    id="fechain"
                                                    name="fechain"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={props.values.fechain}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.fechain && props.errors.fechain ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.fechain && props.errors.fechain ? (
                                                        props.errors.fechain
                                                    ) : null} 
                                                    InputProps={FORMATOFECHAMINHOY}
                                                    />
                                            </Grid> */}

                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    error={props.touched.sedere && props.errors.sedere ? (true) : false}>
                                                    <InputLabel>Sede Dere</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.sedere}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Sede Dere"
                                                        inputProps={{
                                                            name: 'sedere',
                                                            id: 'sedere',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value="Si">Si</option>
                                                        <option value="No">No</option>
                                                        
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.sedere && props.errors.sedere ? (props.errors.sedere) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    error={props.touched.entregado && props.errors.entregado ? (true) : false}>
                                                    <InputLabel>Entregado</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.entregado}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Entregado"
                                                        inputProps={{
                                                            name: 'entregado',
                                                            id: 'entregado',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value="Si">Si</option>
                                                        <option value="No">No</option>
                                                        
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.entregado && props.errors.entregado ? (props.errors.entregado) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            

                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    error={props.touched.modalidad && props.errors.modalidad ? (true) : false}>
                                                    <InputLabel>Modalidad</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.modalidad}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Modalidad"
                                                        inputProps={{
                                                            name: 'modalidad',
                                                            id: 'modalidad',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value="Conjunta">Conjunta</option>
                                                        <option value="Individual">Individual</option>

                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.modalidad && props.errors.modalidad ? (props.errors.modalidad) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    error={props.touched.tipo && props.errors.tipo ? (true) : false}>
                                                    <InputLabel>Tipo de Tesis</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.tipo}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Tipo de Tesis"
                                                        inputProps={{
                                                            name: 'tipo',
                                                            id: 'tipo',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value="TESIS PROFESIONAL">TESIS PROFESIONAL</option>
                                                        <option value="FORMULACIÓN Y EVALUACIÓN DE PROYECTOS">FORMULACIÓN Y EVALUACIÓN DE PROYECTOS</option>
                                                        <option value="DESARR. DE UN PROY. DE SERV. UNIT">DESARR. DE UN PROY. DE SERV. UNIT</option>
                                                        <option value="MEMORIA DE EXPERIENCIA PROFESIONAL">MEMORIA DE EXPERIENCIA PROFESIONAL</option>
                                                        <option value="SEMINARIO DE TITULACIÓN">SEMINARIO DE TITULACIÓN</option>
                                                        <option value="TITULACIÓN POR DIPLOMADO O ESPECIALIZACIÓN">TITULACIÓN POR DIPLOMADO O ESPECIALIZACIÓN</option>
                                                        <option value="ESTANCIA PREPROFESIONAL">ESTANCIA PREPROFESIONAL</option>
                                                        <option value="TESIS DE GRADO">TESIS DE GRADO</option>
                                                        <option value="INFORME DE ESTANCIA PREPROFESIONAL">INFORME DE ESTANCIA PREPROFESIONAL</option>

                                                        
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.tipo && props.errors.tipo ? (props.errors.tipo) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                           

                                           {/*  <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={acceso}
                                                    error={props.touched.resultado && props.errors.resultado ? (true) : false}>
                                                    <InputLabel>Resultado</InputLabel>
                                                    <Select
                                                        native
                                                        value={props.values.resultado}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        label="Resultado"
                                                        inputProps={{
                                                            name: 'resultado',
                                                            id: 'resultado',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value="Aprobador por mayoria">Aprobador por mayoria</option>
                                                        <option value="Aprobador por unanimidad">Aprobador por unanimidad</option>
                                                        <option value="Aprobador por unanimidad con honorifica">Aprobador por unanimidad con honorifica</option>
                                                        <option value="Diferido">Diferido</option>
                                                        <option value="Reprobado">Reprobado</option>
                                                    </Select>
                                                    <FormHelperText>
                                                        {props.touched.resultado && props.errors.resultado ? (props.errors.resultado) : null}</FormHelperText>
                                                </FormControl>
                                            </Grid> */}

                                           

                                            <Grid item xs={12} sm={12}>
                                                <Grid item xs={12} sm={3}>
                                                    <label htmlFor="archivo">
                                                        <input
                                                            style={{ display: 'none' }}
                                                            id="archivo"
                                                            name="archivo"
                                                            type="file"
                                                            disabled={acceso}
                                                            accept="application/pdf"
                                                            onChange={handleOnChange}
                                                        />
                                                        <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                                            Documento Registro de Grado
                                                            {acta ?
                                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                                : null}
                                                        </Button>
                                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                                            {(props.touched.archivo && props.errors.archivo) && (props.errors.archivo)}
                                                        </Typography>
                                                    </label>
                                                </Grid>
                                                <Grid item xs={1} sm={2}>
                                                    <Typography variant='body2'>
                                                        {actaMensaje !== null && actaMensaje !== '' ?
                                                            <a style={{ display: "table-cell" }} href={actaMensaje} target="_blank" rel="noreferrer">
                                                                Ver archivo
                                                            </a> : "Sin documento"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            {/* <Grid item xs={12} sm={12}>
                                                <Grid item xs={12} sm={3}>
                                                    <label htmlFor="portada">
                                                        <input
                                                            style={{ display: 'none' }}
                                                            id="portada"
                                                            name="portada"
                                                            disabled={acceso}
                                                            type="file"
                                                            accept="application/pdf"
                                                            onChange={handleOnChange}
                                                        />
                                                        <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                            <FolderOpenIcon style={{ marginRight: 10 }} />
                                                            Portada de la tesis
                                                            {portada ?
                                                                <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                                : null}
                                                        </Button>
                                                        <Typography variant="caption" display="block" gutterBottom color="error">
                                                            {(props.touched.portada && props.errors.portada) && (props.errors.portada)}
                                                        </Typography>
                                                    </label>
                                                </Grid>
                                                <Grid item xs={1} sm={2}>
                                                    <Typography variant='body2'>
                                                        {portadaMensaje !== null && portadaMensaje !== '' ?
                                                            <a style={{ display: "table-cell" }} href={portadaMensaje} target="_blank" rel="noreferrer">
                                                                Ver archivo
                                                            </a> : "Sin documento"}
                                                    </Typography>
                                                </Grid>
                                            </Grid> */}


                                            {
                                                !acceso ?
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={4}></Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                className={classes.boton}>
                                                                Guardar
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}></Grid>
                                                    </Grid>
                                                    : null
                                            }

                                        </Grid>
                                    </form>
                                )
                            }}
                        </Formik>

                    <br />
                    <br /> 
                    </AccordionDetails>
                    </Accordion>
                    {existe ?
                        <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Artículos derivados de la Tesis</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <ArticuloTesis data={datos} rol={rol} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>Comité Asesor (Revisores de Tesis)</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <ComiteAsesor data={datos} correo={correo} metodo={handleOpen} profesor={profesores} rol={rol} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography className={classes.heading}>Lector Externo</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <LectorExterno data={datos} rol={rol} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                           {/*  <ExpansionPanel>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography className={classes.heading}>Reunión Acordión</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <TablaReunion data={datos} rol={rol} />
                                            <AgregarReunion data={datos} rol={rol} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </ExpansionPanel> */}

                            {/* <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography className={classes.heading}>Cita de Examen Profesional</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <JuradoExaminador data={datos} rol={rol} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion> */}
                            
                        </>
                       
                        : null
                    }
                </>
            

            }

        </>
      
    )

}

export default Tesis;