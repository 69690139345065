import axios from "axios";
import React from "react";

const Actualizartoken = () => {
    //Codigo para actualizar token +++++++++++++++++++++++++++++++++
    const [num, setNum] = React.useState(3000)
    let intervalRef = React.useRef()
    const decreaseNum = () => setNum((prev) => prev - 1);

    React.useEffect(() => {
        if (window.localStorage.getItem('refreshToken') !== null) {
            axios.post("https://securetoken.googleapis.com/v1/token?key=AIzaSyDbEjkKzEgL09oCVFuhcD_T6C5MvrIksn4",
                {
                    grant_type: 'refresh_token',
                    refresh_token: window.localStorage.getItem("refreshToken")
                })
                .then((response) => {
                    window.localStorage.setItem('refreshToken', response.data.refresh_token);
                    window.localStorage.setItem('token', response.data.access_token);
                })
            setNum(3000)
        }
        intervalRef.current = setInterval(decreaseNum, 1000);
        return () => clearInterval(intervalRef.current);

    }, []);

    React.useEffect(async () => {
        if (num === 1) {
            if (window.localStorage.getItem('refreshToken') !== null) {
                axios.post("https://securetoken.googleapis.com/v1/token?key=AIzaSyDbEjkKzEgL09oCVFuhcD_T6C5MvrIksn4",
                    {
                        grant_type: 'refresh_token',
                        refresh_token: window.localStorage.getItem("refreshToken")
                    })
                    .then((response) => {
                        // console.log(response);
                        window.localStorage.setItem('refreshToken', response.data.refresh_token);
                        window.localStorage.setItem('token', response.data.access_token);
                    })
                setNum(3000)
            }
        }

    }, [num === 1])
    //????????????????????????????????????????????????????????????????/

    return (
        null
        // <>
        //     {num}
        // </>
    );
};

export default Actualizartoken;
