import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { FormControl, FormHelperText, InputLabel, Select,Hidden,Typography } from '@material-ui/core/';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { obtenerAspirantes } from '../../../servicios/obtenerAspirantes';

function createData(idpersona, nombre, paterno, materno, correo, idaspirante, estatus, idposgrado, posgrado) {
    return { idpersona, nombre, paterno, materno, correo, idaspirante, estatus, idposgrado, posgrado };
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    boton: {
        fontFamily: "Poppins, Sans-serif",
        fontSize: "15px",
        textTransform: "none",
        lineHeight: "18px",
        borderStyle: "solid",
        borderRadius: "6px 6px 6px 6px",
        backgroundColor: "#2361b2",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#1481e8",
        },
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EnhancedTable() {
    const classes = useStyles();
    const rows2 = []
    const [actualizar, setActualizar] = useState('')
    const [open, setOpen] = React.useState(false);
    const [aspirante, setAspirante] = React.useState('')
    let uno = new Date().getFullYear()
    let dos = new Date().getFullYear() + 3
    let anio = uno + "-" + dos
    const [datos, setDatos] = useState([]);
    const [programas, setProgramas] = useState([])
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [data, setData] = React.useState([]);
    const [columns] = React.useState([
        { title: 'Nombre', field: "nombre" },
        { title: 'Paterno', field: "paterno" },
        { title: 'Materno', field: "materno"},
        { title: 'Posgrado', field: "posgrado"},
    ]);

    useEffect(() => {
        //se sacan los datos del usuario logueado
        let userFB = fba.auth().currentUser;
        if (fba.auth().currentUser) {
            //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
            db.where('correo', '==', userFB.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {

                    //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                    if (doc.data().posgrado === 0) {
                        //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo
                        axios.get(Global + "aspirante/estatus/Registro_Inscripcion", {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {

                            response.data.map((renglon, index) => {
                                rows2[index] = createData(renglon[0], renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8])

                            })

                            setData(rows2)
                        }).catch(error => console.log(error))
                    }
                    else {//si es diferente de 0 entonces tiene acceso a solo un posgrado
                        //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo y programa del usuario
                        obtenerAspirantes(doc,'aspirante/estatus/Registro_Inscripcion/programa/')
                        .then(programa=>{
                            let aspirantes = [];
                            for(let aspirante of programa){
                                aspirantes = aspirantes.concat(aspirante);
                            }
                            console.log('lista de aspirantes',aspirantes)
                            aspirantes.map((renglon,index)=>{
                                rows2[index] = createData(renglon[0], renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8])
                            })
                            setData(rows2)
                            
                        })
                        .catch((err)=>{console.log(err)})
                    }
                });
            });

        }
    }, [actualizar])

    const handleClick = (event) => {

        axios.get(Global + "aspirante/" + event.idaspirante, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            if (response.data) {

                setAspirante(response.data)
                setOpen(true);
            }
        }).catch(error => console.log(error));

        let data = {
            matricula: '',
            generacion: anio,
            posgrado: ''
        }

        axios.get(Global + "estudiosrea/persona/" + event.idpersona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
            }
        }).then(async response => {

            data.posgrado = response.data[0].programa.idprograma
            axios.get(Global + "programa/tipo/" + response.data[0].nivel, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(async response => {
                setDatos(data)
                setProgramas(response.data)
            }).catch(error => console.log(error))

        }).catch(error => console.log(error))

    }

    const handleClose = () => {
        setOpen(false);
    };

    const schemaValidation = Yup.object({
        matricula: Yup.string().required('Es obligatorio ingresar la matricula.').matches(/^[0-9]{7}[-]{1}[0-9]{1}$/, 'El campo no cumple con el formato de la matricula. (0000000-0)'),
        posgrado: Yup.string().required('Es obligatorio seleccionar el posgrado.'),

    })

    const functionSubmit = async valores => {

        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        const alumno = {
            estatus: "VG",
            fechain: today,
            generacion: valores.generacion,
            matricula: valores.matricula,
            programa: {
                idprograma: valores.posgrado
            }
        }

        axios.post(Global + "alumno/persona/" + aspirante.persona.idP, alumno, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(() => {

        }).catch(error => console.log(error));

        let cambio = {
            estatus: "Registro_Terminado",
            activo: 1
        }
        axios.put(Global + "aspirante/" + aspirante.idaspirante, cambio, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(responses => {
            setActualizar("actualiza")
        }).catch(error => console.log(error));

        setOpen(false);

    }

    const initial = datos

    return (
        <div className={classes.root}>
        <MaterialTable
            title={
                <Hidden smDown>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center">
                        <Grid item>
                        <Typography variant="h5" className={classes.subtitulo}>
                            Asignación de matrícula a los aspirantes
                        </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            } 
            actions={[
                {  
                    tooltip: 'Ver información',
                    
                    onClick: (e,rowData) =>
                    handleClick(rowData)
                },

            ]}
            components={{
                Action: props => (
                <Button
                onClick={(event) => props.action.onClick(event, props.data)}
                    variant="contained"
                    color="primary"
                    className={classes.boton}>
                    Asignar matrícula
                </Button>
                ),
                }}
            options={{

                actionsColumnIndex:-1,
                actionsCellStyle:{width:"30%"},
                exportButton:true,
                exportFileName:"Asignación de matrícula a los aspirantes",
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                headerStyle: {
                    backgroundColor: '#66abe0b8',
                    color: '#FFF'
                }
            }}
            localization={{
                header:{
                    actions:""
                },
                toolbar: {
                    exportCSVName: "Exportar como CSV",
                    exportPDFName: "Exportar como PDF",
                    exportTitle: "Exportar",
                    searchTooltip: 'Buscar en la tabla',
                    searchPlaceholder: "Buscar"
                },
                pagination: {
                    labelDisplayedRows: '',
                    labelRowsSelect: '',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Siguiente página',
                    lastTooltip: 'Última página',
                },
                body: {
                    emptyDataSourceMessage: 'No hay información por mostrar',
                }
            }}
            data={data}
            columns={columns}
        />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Verificación de acción"}</DialogTitle>
                <Formik
                    validationSchema={schemaValidation}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        functionSubmit(valores)
                    }}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Asignación de matrícula al aspirante para registrarlo como alumno.
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        label="Matrícula"
                                        id="matricula"
                                        type="text"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={props.values.matricula}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.touched.matricula && props.errors.matricula ? (
                                            true
                                        ) : false}
                                        helperText={props.touched.matricula && props.errors.matricula ? (
                                            props.errors.matricula
                                        ) : null}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        disabled
                                        label="Generación"
                                        id="generacion"
                                        type="text"
                                        value={props.values.generacion}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                    <FormControl
                                        fullWidth
                                        className={classes.formControl}
                                        error={props.touched.posgrado && props.errors.posgrado ? (true) : false}>
                                        <InputLabel
                                            shrink>Posgrado</InputLabel>
                                        <Select
                                            native
                                            value={props.values.posgrado}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            label="posgrado"
                                            inputProps={{
                                                name: 'posgrado',
                                                id: 'posgrado',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                programas ?
                                                    programas.map((programa) => {
                                                        return <option key={programa.idprograma} value={programa.idprograma}>{programa.programa}</option>
                                                    }) : null
                                            }
                                        </Select>
                                        <FormHelperText>
                                            {props.touched.posgrado && props.errors.posgrado ? (props.errors.posgrado) : null}</FormHelperText>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary">
                                        Continuar
                                    </Button>
                                </DialogActions>
                            </form>
                        )
                    }}
                </Formik>
            </Dialog>
        </div >
    );
}
