import React, { useEffect, useState } from 'react'
import { CircularProgress, Typography, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {  FORMATOFECHAMINHOY } from "../../generales/Fecha";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function ActualizarActualizacion(props) {
    // console.log(props.data)
    // const { correo } = "";

    const classes = useStyles();
    const { idcurso } = props.data.cursoActualizacion;
    const { correo } = props.data.alumno.persona;
    const [paises, setPaises] = useState([])
    const [instituciones, setInstituciones] = useState([])
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [idCurso, setCurso] = useState('');
    const [adjunto, setAdjunto] = useState(0);
    // const [uploadAjunto, setUploadAdjunto] = useState(0);
    // const [adjuntoMensaje, setAdjuntoMensaje] = useState('')
    const [adjuntoMensaje2, setAdjuntoMensaje2] = useState('')
    const [acceso, setAcceso] = useState(true)


    useEffect(() => {

        let curso = {
            curso: '',
            dependencia: '',
            documento: '',
            fechafin: '',
            fechain: '',
            horas: '',
            institucion: '',
            pais: '',
        }
        
        const datosIniciales = async () => {

            props.rol === "administrador" ||
                props.rol === "coordinador_general" ||
                props.rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)

            await axios.get(Global + "cursomovilidad/" + idcurso, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                curso.adjunto = response.data.adjunto
                curso.curso = response.data.curso
                curso.dependencia = response.data.dependencia
                curso.fechafin = response.data.fechafin
                curso.fechain = response.data.fechain
                curso.horas = response.data.horas
                curso.institucion = response.data.institucion.idins
                curso.pais = response.data.pais.idpais
                setCurso(response.data.idcurso)

            }).catch(error => console.log(error))


            await axios.get(Global + "pais", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaises(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "institucion", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setInstituciones(response.data)
            }).catch(error => console.log(error))

            if (curso.adjunto !== null && curso.adjunto !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + curso.adjunto)
                setAdjuntoMensaje2(await httpsReference.getDownloadURL());
            }
            handleClose()
        }



        datosIniciales();
        setRegistroAnterior(curso)
    }, [])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        adjunto: '',
        curso: Yup.string().required("El campo de curso es obligatorio"),
        dependencia: '',
        documento: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (adjunto === 0 || SUPPORTED_PDF.includes(adjunto.type))),
        horas: '',
        institucion: Yup.string().required("El campo de institucion obligatoria"),
        pais: Yup.string().required("El campo de pais es obligatoria"),
    })



    const funcionSubmit = async valores => {

        let linkDocumento = registroAnterior.adjunto
        if (adjunto) {
            if (registroAnterior.adjunto === '') {
                linkDocumento = correo + '/DocumentosCursoActualizacion/' + uuidv4() + adjunto.name
            }
            const storageRef = fba.storage().ref(linkDocumento)
            const task = storageRef.put(adjunto)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        let curso = {
            adjunto: linkDocumento,
            curso: valores.curso,
            dependencia: valores.dependencia,
            fechafin: valores.fechafin,
            fechain: valores.fechain,
            horas: valores.horas,
            institucion: {
                idins: valores.institucion,
            },
            pais: {
                idpais: valores.pais,
            }
        }

        await axios.put(Global + "cursomovilidad/" + idCurso, curso, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(() => {
            Swal({
                title: 'Datos actualizados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })

        }).catch(error => {
            console.log(error);
        })
        props.metodo()
    }

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "documento":
                    setAdjunto(file);
                    break;
                default:
                    break;
            }

        } else {
            switch (event.target.id) {
                case "documento":
                    setAdjunto(false)
                    break;
                default:
                    break;
            }
        }
    }

    const initial = registroAnterior

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Curso de actualización"
                                                name="curso"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.curso}
                                                onChange={(e) => {
                                                    let curso = e.target.value;
                                                    props.setFieldValue("curso", curso.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.curso && props.errors.curso ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.curso && props.errors.curso ? (
                                                    props.errors.curso
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Inicio"
                                                id="fechain"
                                                name="fechain"
                                                type="date"
                                                disabled={acceso}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fechain}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechain && props.errors.fechain ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Termino"
                                                id="fechafin"
                                                name="fechafin"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fechafin}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fechafin && props.errors.fechafin ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fechafin && props.errors.fechafin ? (
                                                    props.errors.fechafin
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                className={classes.formControl}
                                                error={props.touched.institucion && props.errors.institucion ? (true) : false}>
                                                <InputLabel>Institución</InputLabel>
                                                <Select
                                                    labelId="Institucion"
                                                    native
                                                    value={props.values.institucion}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Institucion"
                                                    inputProps={{
                                                        name: 'institucion',
                                                        id: 'institucion',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        instituciones.map((institucion) => {
                                                            return <option key={institucion.idins} value={institucion.idins}>{institucion.nombre}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Dependencia"
                                                name="dependencia"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.dependencia}
                                                onChange={(e) => {
                                                    let dep = e.target.value;
                                                    props.setFieldValue("dependencia", dep.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.dependencia && props.errors.dependencia ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.dependencia && props.errors.dependencia ? (
                                                    props.errors.dependencia
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                className={classes.formControl}
                                                error={props.touched.pais && props.errors.pais ? (true) : false}>
                                                <InputLabel>País</InputLabel>
                                                <Select
                                                    labelId="Pais"
                                                    native
                                                    value={props.values.pais}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="pais"
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        paises.map((pais) => {
                                                            return <option key={pais.idpais} value={pais.idpais}>{pais.pais}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.pais && props.errors.pais ? (props.errors.pais) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Cantidad de horas"
                                                name="horas"
                                                disabled={acceso}
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.horas}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.horas && props.errors.horas ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.horas && props.errors.horas ? (
                                                    props.errors.horas
                                                ) : null}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid item xs={12} sm={3}>
                                                <label htmlFor="documento">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="documento"
                                                        name="documento"
                                                        disabled={acceso}
                                                        type="file"
                                                        accept="application/pdf"
                                                        onChange={handleOnChange}
                                                    />
                                                    <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                                        Adjunto
                                                        {adjunto ?
                                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                            : null}
                                                    </Button>
                                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                                        {(props.touched.documento && props.errors.documento) && (props.errors.documento)}
                                                    </Typography>
                                                </label>
                                            </Grid>
                                            <Grid item xs={1} sm={2}>
                                                <Typography variant='body2'>
                                                    {adjuntoMensaje2 !== null && adjuntoMensaje2 !== '' ?
                                                        <a style={{ display: "table-cell" }} href={adjuntoMensaje2} target="_blank" rel="noreferrer">
                                                            Ver archivo
                                                        </a>
                                                        : "Sin documento"}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <br />
                                    {
                                        !acceso ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            :
                                            null
                                    }
                                </form>
                            )
                        }}
                    </Formik>

                }
            </Paper >
        </div >
    );
}

export default ActualizarActualizacion;