import React, { useEffect, useState } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';

import Swal from 'sweetalert'
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const ActualizarCurso = (props) => {
    // console.log(props)
    const classes = useStyles();
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [materias, setMaterias] = useState([])
    const [profesores, setProfesores] = useState([])
    const [sedes, setSedes] = useState([])
    const year = (new Date()).getFullYear();
    const [open, setOpen] = React.useState(true);
    const [acceso, setAcceso] = useState(true)

    useEffect(async () => {
        const carga = async () => {
            setRegistroAnterior({
                materia: props.data.materia.idmateria,
                profesor: props.data.profesor.idprofesor,
                sede: props.data.sede.idsede,
                semestre: props.data.semestre,
                grado: props.data.grado,
                ciclo: props.data.ciclo,
                sesion: props.data.sesion,
                especial: props.data.especial,
            })

            props.rol === "coordinador_general" ? setAcceso(false) : setAcceso(true)


            await axios.get(Global + "materia", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setMaterias(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "profesor", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setProfesores(response.data)
            }).catch(error => console.log(error))

            await axios.get(Global + "sede", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setSedes(response.data)
            }).catch(error => console.log(error))
        }
        await carga();
        handleClose();
    }, [])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        materia: Yup.string().required('Es obligatorio seleccionar la materia'),
        profesor: Yup.string().required('Es obligatorio seleccionar el profesor'),
        sede: Yup.string().required('Es obligatorio seleccionar la sede'),
        semestre: Yup.string().required('Es obligatorio seleccionar el semestre'),
        grado: Yup.string().required('Es obligatorio seleccionar el grado'),
        ciclo: Yup.string().required('Es obligatorio ingresar el ciclo'),
        sesion: Yup.string().required('Es obligatorio seleccionar la sesión')
    })

    const initial = registroAnterior

    const funcionSubmit = async valores => {
        // console.log(valores)

        let curso = {
            materia: {
                idmateria: valores.materia
            },
            profesor: {
                idprofesor: valores.profesor
            },
            sede: {
                idsede: valores.sede
            },
            semestre: valores.semestre,
            grado: valores.grado,
            ciclo: valores.ciclo,
            sesion: valores.sesion,
            especial: valores.especial,
        };

        await axios.put(Global + "curso/" + props.data.idcurso, curso, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response);
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            props.metodo(true)
        }).catch(error => {
            console.log(error);
        })
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <br />
            {open ?
                <Backdrop className={classes.backdrop} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                <Formik
                    validationSchema={schemaValidacion}
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(valores) => {
                        funcionSubmit(valores)
                    }}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <Grid container spacing={1} justify='center'>
                                    <Grid item xs={12} sm={10}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            disabled={acceso}
                                            className={classes.formControl}
                                            error={props.touched.profesor && props.errors.profesor ? (true) : false}>
                                            <InputLabel>Profesor</InputLabel>
                                            <Select
                                                labelId="Profesor"
                                                native
                                                value={props.values.profesor}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="profesor"
                                                inputProps={{
                                                    name: 'profesor',
                                                    id: 'profesor',
                                                }}
                                            >
                                                <option aria-label="None" value="" />{
                                                    profesores.map((profesor) => {
                                                        return <option key={profesor.idprofesor} value={profesor.idprofesor}>{profesor.persona.nombre + " " + profesor.persona.apellidoP + " " + profesor.persona.apellidoM}</option>
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.profesor && props.errors.profesor ? (props.errors.profesor) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.materia && props.errors.materia ? (true) : false}
                                            className={classes.formControl}
                                            required
                                        >
                                            <InputLabel >Materia</InputLabel>
                                            <Select
                                                native
                                                value={props.values.materia}
                                                onChange={e => {
                                                    props.setFieldValue("materia", e.target.value);

                                                }}
                                                label="Materia"
                                                inputProps={{
                                                    name: 'materia',
                                                    id: 'materia',
                                                }}
                                            >
                                                <option aria-label="None" value="" />{
                                                    materias.map((materia) => {
                                                        return <option key={materia.idmateria} value={materia.idmateria}>{materia.nombre}</option>
                                                    })
                                                }

                                            </Select>
                                            <FormHelperText>
                                                {props.touched.materia && props.errors.materia ? (props.errors.materia) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={10}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.sede && props.errors.sede ? (true) : false}
                                            className={classes.formControl}
                                            required
                                        >
                                            <InputLabel >Sede</InputLabel>
                                            <Select
                                                native
                                                value={props.values.sede}
                                                onChange={e => {
                                                    // handleOnChangePais(e);
                                                    props.setFieldValue("sede", e.target.value);

                                                }}
                                                label="sede"
                                                inputProps={{
                                                    name: 'sede',
                                                    id: 'sede',
                                                }}
                                            >
                                                <option aria-label="None" value="" />{
                                                    sedes.map((sede) => {
                                                        return <option key={sede.idsede} value={sede.idsede}>{sede.nombre}</option>
                                                    })
                                                }

                                            </Select>
                                            <FormHelperText>
                                                {props.touched.sede && props.errors.sede ? (props.errors.sede) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            disabled={acceso}

                                            error={props.touched.semestre && props.errors.semestre ? (true) : false}>
                                            <InputLabel>Semestre</InputLabel>
                                            <select
                                                native
                                                value={props.values.semestre}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Semestre"
                                                inputProps={{
                                                    name: 'semestre',
                                                    id: 'semestre',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={"PRIMERO"}>PRIMERO</option>
                                                <option value={"SEGUNDO"}>SEGUNDO</option>
                                                <option value={"TERCERO"}>TERCERO</option>
                                                <option value={"CAURTO"}>CAURTO</option>
                                                <option value={"QUINTO"}>QUINTO</option>
                                                <option value={"SEXTO"}>SEXTO</option>
                                            </select>
                                            <FormHelperText>
                                                {props.touched.semestre && props.errors.semestre ? (props.errors.semestre) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            required
                                            error={props.touched.grado && props.errors.grado ? (true) : false}>
                                            <InputLabel>Grado</InputLabel>
                                            <Select
                                                native
                                                value={props.values.grado}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Grado"
                                                inputProps={{
                                                    name: 'grado',
                                                    id: 'grado',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'11'}>11</option>
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>
                                                <option value={'16'}>16</option>
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.grado && props.errors.grado ? (props.errors.grado) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={12} sm={5}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            required
                                            error={props.touched.sesion && props.errors.sesion ? (true) : false}>
                                            <InputLabel>Sesión</InputLabel>
                                            <Select
                                                native
                                                value={props.values.sesion}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="sesion"
                                                inputProps={{
                                                    name: 'sesion',
                                                    id: 'sesion',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={"Otoño"}>Otoño</option>
                                                <option value={"Primavera"}>Primavera</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.sesion && props.errors.sesion ? (props.errors.sesion) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            label="Ciclo"
                                            name="ciclo"
                                            variant="outlined"
                                            required
                                            disabled={acceso}

                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //defaultValue={escolaridadAnterior[0].promedio}
                                            value={props.values.ciclo}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.ciclo && props.errors.ciclo ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.ciclo && props.errors.ciclo ? (
                                                props.errors.ciclo
                                            ) : null}
                                        // max="100"
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            label="Especial"
                                            name="especial"
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //defaultValue={escolaridadAnterior[0].promedio}
                                            value={props.values.especial}
                                            onChange={(e)=>{
                                                let esp = e.target.value;
                                                props.setFieldValue("especial",esp.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.especial && props.errors.especial ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.especial && props.errors.especial ? (
                                                props.errors.especial
                                            ) : null}
                                        // max="100"
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <br />
                                {
                                    !acceso ?
                                        <Grid container spacing={1} justify='center'>
                                            <Grid item xs={12} sm={5} >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={classes.boton}>
                                                    Guardar
                                            </Button>
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                }

                            </form>
                        )
                    }}
                </Formik >
            }
            <br />
        </>
    );
}

export default ActualizarCurso