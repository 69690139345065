import { compareDesc, compareAsc, parseISO } from 'date-fns'


let today = new Date()
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();
today = yyyy + '-' + mm + '-' + dd;

//Validaci[on] para fechas que deben ser menores a la actual
export const menor = (fecha) => {
    const result = compareDesc(parseISO(fecha), parseISO(today))
    if (result !== -1) {
        return true
    }
}

export const mayor = (fecha) => {
    const result = compareAsc(parseISO(today), parseISO(fecha))
    if (result !== 1) {
        return true
    }
}
export const FORMATOFECHAMINHOY = {inputProps: { min: today,max:"2099-12-31"}};
//Para que los campos de fecha no acepten mas de 4 digitos en el año y no se escojan fechas mayores al dia de hoy
export const FORMATOFECHAMAXHOY = {inputProps: { min: "1900-01-01", max: today}}

