import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({

    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaAsignacion(props) {
    const classes = useStyles();
    const [data] = useState(props.rows);

    const [columns] = useState([
        { title: 'Nombre(s)', field: 'nombre' },
        { title: 'Apellido paterno', field: 'paterno' },
        { title: 'Apellido materno', field: 'materno' },
        { title: 'Posgrado Solicitado', field: 'posgrado' },
        { title: 'Matrícula', field: 'matricula' },
    ]);

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <>
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Asignación de matrícula
                        </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    exportButton: true,
                    exportFileName: "Asignación de matrícula",
                    search: true,
                    pageSize: 20,
                    pageSizeOptions: [20, 100, 200, 300],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar",
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: "Buscar"
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                    }
                }}
                data={data}
                columns={columns}
            />
        </>
    )
}

export default TablaAsignacion;