import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';
import LineaInvestigacion from './LineaInvestigacion';
import Participantes from './Participantes';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#b1cde3',
        marginTop: '2%',
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: '5%',
    },
    barra: {
        backgroundColor: '#2361b1',
    }
}));

export default function SimpleTabs(props) {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.root}>
            <AppBar position="static" className={classes.barra}>
                <Tabs value={value} onChange={handleChange} variant="scrollable"
                    scrollButtons="auto">
                    <Tab label="Lineas de investigacion" {...a11yProps(0)} />
                    {/* <Tab label="Generaciones" {...a11yProps(1)} /> */}
                    <Tab label="Participantes" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <LineaInvestigacion programa={props.programa}/>
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                Generaciones
            </TabPanel> */}
            <TabPanel value={value} index={1}>
                <Participantes programa={props.programa}/>
            </TabPanel>
        </Paper>
    );
}