import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListAlt from "@material-ui/icons/ListAlt";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BookIcon from "@material-ui/icons/Book";
import Zoom from "@material-ui/core/Zoom";
import Burguer from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import ComputerSharpIcon from "@material-ui/icons/ComputerSharp";
import HomeWorkSharpIcon from "@material-ui/icons/HomeWorkSharp";
import HowToRegSharpIcon from "@material-ui/icons/HowToRegSharp";
import LocalLibraryRoundedIcon from "@material-ui/icons/LocalLibraryRounded";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BackupIcon from "@material-ui/icons/Backup";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { fba } from "../../firebaseConfig";
import { Link } from "react-router-dom";

import theme from "../../styles/globalStyles";

import VpnKeyIcon from '@material-ui/icons/VpnKey';

const firestore = fba.firestore();
const db = firestore.collection('usuarios');

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
});

function MenuBurguer({ usuario }) {

  const [rol, setRol] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (usuario.email !== null || usuario.email !== undefined) {
      db.where('correo', '==', usuario.email).get().then(snapshot => {
        snapshot.docs.forEach(doc => {
          if (usuario.email === doc.data().correo) {
            setRol(doc.data().rol)
          } else {
            return null;
          }
        });
      });
    }
  }, [])

  // const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);

  // const handleClick = () => {
  //     setOpen(!open);
  // };

  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };
  const handleClick6 = () => {
    setOpen6(!open6);
  };
  const handleClick7 = () => {
    setOpen7(!open7);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, openT) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: openT });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {
          //Administrador de la Aplicacion y Academico-Coordinador General de Posgrado
        (rol === 'coordinador_general' || rol === 'administrador') ?
          <>
            <ListItem component={Link} to="/siip/IndexUnidad">
              <ListItemIcon>
                <HomeWorkSharpIcon>
                  <ListAlt />
                </HomeWorkSharpIcon>
              </ListItemIcon>
              <ListItemText primary="Unidad académica" />
            </ListItem>
            <Divider />

            <ListItem button onClick={handleClick2}>
              <ListItemIcon>
                <ComputerSharpIcon>
                  <ListAlt />
                </ComputerSharpIcon>
              </ListItemIcon>
              <ListItemText primary="Programas" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/siip/programa"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Registrar programa" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/siip/actualizarPrograma"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Actualizar programa" />
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={handleClick3}>
              <ListItemIcon>
                <HowToRegSharpIcon>
                  <ListAlt />
                </HowToRegSharpIcon>
              </ListItemIcon>
              <ListItemText primary="Aspirantes" />
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/siip/Aspirantes"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Lista de aspirantes" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/aspiranteAceptacion"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Revisión de documentos" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/asignarCita"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Cita para entrevista" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/reasignarCita"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Reagendar entrevista" />
                </ListItem>

                {/* <ListItem component={Link} to="/aceptarServiciosEscolares" button className={classes.nested}>

                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Aprobación de Servicios escolares" />
                        </ListItem> */}

                <ListItem
                  component={Link}
                  to="/filtroGeneral"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Aprobación de coordinación general" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/asignarCarta"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Cartas de aceptación e inscripción" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/reasignarCarta"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Reagendar inscripción" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/aceptarAspirante"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Registro de alumno" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/asignarMatriculas"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Asignación de matrícula" />
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={handleClick4}>
              <ListItemIcon>
                <AssignmentIndRoundedIcon>
                  <ListAlt />
                </AssignmentIndRoundedIcon>
              </ListItemIcon>
              <ListItemText primary="Alumno" />
              {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open4} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/siip/IndexAlumno"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Menú alumnos" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/asignarCitaExam"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Citas para Examen Profesional" />
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={handleClick5}>
              <ListItemIcon>
                <LocalLibraryRoundedIcon>
                  <ListAlt />
                </LocalLibraryRoundedIcon>
              </ListItemIcon>
              <ListItemText primary="Personal Académico y Administrativo" />
              {open5 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open5} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/siip/AgregarProfesor"
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Agregar" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/siip/IndexProfesores"
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Menú profesores" />
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={handleClick6}>
              <ListItemIcon>
                <BookIcon>
                  <ListAlt />
                </BookIcon>
              </ListItemIcon>
              <ListItemText primary="Calificaciones" />
              {open6 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open6} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/siip/IndexMateria"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Materias" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/siip/plan_de_estudio"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Plan de estudio" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/siip/IndexCurso"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Cursos" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/siip/AlumnoCurso"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Alumnos-Cursos" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/siip/IndexCalificaciones"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Calificaciones" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/historial"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Historial academico" />
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={handleClick7}>
              <ListItemIcon>
                <LibraryBooksIcon>
                  <ListAlt />
                </LibraryBooksIcon>
              </ListItemIcon>
              <ListItemText primary="Catálogos" />
              {open7 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open7} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/asociacion"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Asociación" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/convocatoria"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Convocatoria" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/entidad"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Entidad federativa" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/pais"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Países" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Sede"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Sedes" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Ubpp"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="UBPP" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Becante"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Becantes" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Motivo"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Motivo de baja" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Institucion"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Instituciones" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Etnia"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Etnia" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Moneda"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Moneda" />
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem component={Link} to="/estadisticas">
              <ListItemIcon>
                <EqualizerIcon>
                  <ListAlt />
                </EqualizerIcon>
              </ListItemIcon>
              <ListItemText primary="Estadísticas" />
            </ListItem>
            <Divider />

            <ListItem component={Link} to="/siip/roles">
              <ListItemIcon>
                <VpnKeyIcon>
                  <ListAlt />
                </VpnKeyIcon>
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItem>
            <Divider />

            <ListItem component={Link} to="/siip/importaArchivos">
              <ListItemIcon>
                <BackupIcon>
                  <ListAlt />
                </BackupIcon>
              </ListItemIcon>
              <ListItemText primary="Importar archivos" />
            </ListItem>

            <Divider />
          </>
          : null
        }

        {
          //Administrativo Coordinacion General
          (rol === 'asistente_coordinador_general') ?
            <>
              <ListItem component={Link} to="/siip/IndexUnidad">
                <ListItemIcon>
                  <HomeWorkSharpIcon>
                    <ListAlt />
                  </HomeWorkSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Unidad acádemica" />
              </ListItem>
              <Divider />

              <ListItem button onClick={handleClick2}>
                <ListItemIcon>
                  <ComputerSharpIcon>
                    <ListAlt />
                  </ComputerSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Programas" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/actualizarPrograma"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Lista de programas" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick3}>
                <ListItemIcon>
                  <HowToRegSharpIcon>
                    <ListAlt />
                  </HowToRegSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Aspirantes" />
                {open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/Aspirantes"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Lista de aspirantes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/aspiranteAceptacion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Revisión de documentos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cita para entrevista" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/reasignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar entrevista" />
                  </ListItem>

                  {/* <ListItem component={Link} to="/aceptarServiciosEscolares" button className={classes.nested}>

                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Aprobación de Servicios escolares" />
                        </ListItem> */}

                  <ListItem
                    component={Link}
                    to="/filtroGeneral"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Aprobación de coordinación general" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cartas de aceptación e inscripción" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/reasignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar inscripción" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/aceptarAspirante"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Registro de alumno" />
                  </ListItem>

                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick4}>
                <ListItemIcon>
                  <AssignmentIndRoundedIcon>
                    <ListAlt />
                  </AssignmentIndRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Alumno" />
                {open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexAlumno"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú alumnos" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/asignarCitaExam"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Citas para Examen Profesional" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick5}>
                <ListItemIcon>
                  <LocalLibraryRoundedIcon>
                    <ListAlt />
                  </LocalLibraryRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Profesores" />
                {open5 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open5} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexProfesores"
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú profesores" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick6}>
                <ListItemIcon>
                  <BookIcon>
                    <ListAlt />
                  </BookIcon>
                </ListItemIcon>
                <ListItemText primary="Calificaciones" />
                {open6 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open6} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexMateria"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Materias" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/plan_de_estudio"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Plan de estudio" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/IndexCurso"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cursos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/AlumnoCurso"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Alumnos-Cursos" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/historial"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Historial academico" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick7}>
                <ListItemIcon>
                  <LibraryBooksIcon>
                    <ListAlt />
                  </LibraryBooksIcon>
                </ListItemIcon>
                <ListItemText primary="Catálogos" />
                {open7 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open7} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/asociacion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Asociación" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/convocatoria"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Convocatoria" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/entidad"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Entidad federativa" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/pais"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Países" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Sede"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Sedes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Ubpp"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="UBPP" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Becante"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Becantes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Motivo"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Motivo de baja" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Institucion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Instituciones" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Etnia"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Etnia" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Moneda"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Moneda" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem component={Link} to="/siip/importaArchivos">
                <ListItemIcon>
                  <BackupIcon>
                    <ListAlt />
                  </BackupIcon>
                </ListItemIcon>
                <ListItemText primary="Importar archivos" />
              </ListItem>
              <Divider />
            </>
            : null
        }



{
  // Academico-Evaluador Aspitante
          (rol === 'evaluador_aspirante') ?
            <>
              <ListItem component={Link} to="/siip/IndexUnidad">
                <ListItemIcon>
                  <HomeWorkSharpIcon>
                    <ListAlt />
                  </HomeWorkSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Unidad acádemica" />
              </ListItem>
              <Divider />

              <ListItem button onClick={handleClick2}>
                <ListItemIcon>
                  <ComputerSharpIcon>
                    <ListAlt />
                  </ComputerSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Programas" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/actualizarPrograma"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Lista de programas" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick3}>
                <ListItemIcon>
                  <HowToRegSharpIcon>
                    <ListAlt />
                  </HowToRegSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Aspirantes" />
                {open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/Aspirantes"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Lista de aspirantes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/aspiranteAceptacion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Revisión de documentos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cita para entrevista" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/reasignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar entrevista" />
                  </ListItem>

                  {/* <ListItem component={Link} to="/aceptarServiciosEscolares" button className={classes.nested}>

                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Aprobación de Servicios escolares" />
                        </ListItem> */}

                  <ListItem
                    component={Link}
                    to="/filtroGeneral"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Aprobación de coordinación general" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cartas de aceptación e inscripción" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/reasignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar inscripción" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/aceptarAspirante"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Registro de alumno" />
                  </ListItem>

                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick4}>
                <ListItemIcon>
                  <AssignmentIndRoundedIcon>
                    <ListAlt />
                  </AssignmentIndRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Alumno" />
                {open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexAlumno"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú alumnos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCitaExam"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Citas para Examen Profesional" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick5}>
                <ListItemIcon>
                  <LocalLibraryRoundedIcon>
                    <ListAlt />
                  </LocalLibraryRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Profesores" />
                {open5 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open5} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexProfesores"
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú profesores" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick6}>
                <ListItemIcon>
                  <BookIcon>
                    <ListAlt />
                  </BookIcon>
                </ListItemIcon>
                <ListItemText primary="Calificaciones" />
                {open6 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={open6} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexMateria"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Materias" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/plan_de_estudio"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Plan de estudio" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/IndexCurso"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cursos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/AlumnoCurso"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Alumnos-Cursos" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/historial"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Historial academico" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick7}>
                <ListItemIcon>
                  <LibraryBooksIcon>
                    <ListAlt />
                  </LibraryBooksIcon>
                </ListItemIcon>
                <ListItemText primary="Catálogos" />
                {open7 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open7} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/asociacion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Asociación" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/convocatoria"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Convocatoria" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/entidad"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Entidad federativa" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/pais"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Países" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Sede"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Sedes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Ubpp"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="UBPP" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Becante"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Becantes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Motivo"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Motivo de baja" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Institucion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Instituciones" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Etnia"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Etnia" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/Moneda"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Moneda" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem component={Link} to="/siip/importaArchivos">
                <ListItemIcon>
                  <BackupIcon>
                    <ListAlt />
                  </BackupIcon>
                </ListItemIcon>
                <ListItemText primary="Importar archivos" />
              </ListItem>
              <Divider />
            </>
            : null
        }






        {
          //Academico-Coordinador Programa
          (rol === 'coordinador_posgrado') ?
            <>
              <ListItem button onClick={handleClick2}>
                <ListItemIcon>
                  <ComputerSharpIcon>
                    <ListAlt />
                  </ComputerSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Programa" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/actualizarPrograma"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Actualizar programa" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick3}>
                <ListItemIcon>
                  <HowToRegSharpIcon>
                    <ListAlt />
                  </HowToRegSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Aspirantes" />
                {open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/Aspirantes"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Lista de aspirantes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/aspiranteAceptacion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Revisión de documentos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cita para entrevista" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/reasignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar entrevista" />
                  </ListItem>

                  {/* <ListItem component={Link} to="/aceptarServiciosEscolares" button className={classes.nested}>

                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Aprobación de Servicios escolares" />
                        </ListItem> */}

                  { <ListItem
                    component={Link}
                    to="/filtroGeneral"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Aprobación de coordinación general" />
                  </ListItem> }

                  { <ListItem
                    component={Link}
                    to="/asignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cartas de aceptación e inscripción" />
                  </ListItem> }

                  { <ListItem
                    component={Link}
                    to="/reasignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar inscripción" />
                  </ListItem> }

                  { <ListItem
                    component={Link}
                    to="/aceptarAspirante"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Registro de alumno" />
                  </ListItem> }

                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick4}>
                <ListItemIcon>
                  <AssignmentIndRoundedIcon>
                    <ListAlt />
                  </AssignmentIndRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Alumnos" />
                {open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexAlumno"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú alumnos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCitaExam"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cita para Examen Profesional" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick5}>
                <ListItemIcon>
                  <LocalLibraryRoundedIcon>
                    <ListAlt />
                  </LocalLibraryRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Profesores" />
                {open5 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              
              <Collapse in={open5} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/AgregarProfesor"
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Agregar profesor" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/IndexProfesores"
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú profesores" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick6}>
                <ListItemIcon>
                  <BookIcon>
                    <ListAlt />
                  </BookIcon>
                </ListItemIcon>
                <ListItemText primary="Calificaciones" />
                {open6 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={open6} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexMateria"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Materias" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/plan_de_estudio"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Plan de estudio" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/IndexCurso"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cursos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/AlumnoCurso"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Alumnos-Cursos" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/historial"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Historial academico" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

            </>
            : null
        }








        {
          //Administrativo Programa
          (rol === 'asistente_coordinador_posgrado') ?
            <>
              <ListItem button onClick={handleClick2}>
                <ListItemIcon>
                  <ComputerSharpIcon>
                    <ListAlt />
                  </ComputerSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Programas" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/actualizarPrograma"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Actualizar programa" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick3}>
                <ListItemIcon>
                  <HowToRegSharpIcon>
                    <ListAlt />
                  </HowToRegSharpIcon>
                </ListItemIcon>
                <ListItemText primary="Aspirantes" />
                {open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/Aspirantes"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Lista de aspirantes" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/aspiranteAceptacion"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Revisión de documentos" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/asignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cita para entrevista" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/reasignarCita"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar entrevista" />
                  </ListItem>

                  {/* <ListItem component={Link} to="/aceptarServiciosEscolares" button className={classes.nested}>

                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Aprobación de Servicios escolares" />
                        </ListItem> */}

                  { <ListItem
                    component={Link}
                    to="/filtroGeneral"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Aprobación de coordinación general" />
                  </ListItem>
                   }

                  { <ListItem
                    component={Link}
                    to="/asignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Cartas de aceptación e inscripción" />
                  </ListItem> }

                  {<ListItem
                    component={Link}
                    to="/reasignarCarta"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Reagendar inscripción" />
                  </ListItem> }

                  {<ListItem
                    component={Link}
                    to="/aceptarAspirante"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Registro de alumno" />
                  </ListItem> }
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick4}>
                <ListItemIcon>
                  <AssignmentIndRoundedIcon>
                    <ListAlt />
                  </AssignmentIndRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Alumno" />
                {open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexAlumno"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú alumnos" />
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/asignarCitaExam"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Citas para Examen Profesional" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

              <ListItem button onClick={handleClick5}>
                <ListItemIcon>
                  <LocalLibraryRoundedIcon>
                    <ListAlt />
                  </LocalLibraryRoundedIcon>
                </ListItemIcon>
                <ListItemText primary="Profesores" />
                {open5 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open5} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/AgregarProfesor"
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Agregar profesor" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/siip/IndexProfesores"
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Menú profesores" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />

            </>
            : null
        }
        

       





        {
          //Academico
          (rol === 'profesor') ?
            <>
              <ListItem button onClick={handleClick6}>
                <ListItemIcon>
                  <BookIcon>
                    <ListAlt />
                  </BookIcon>
                </ListItemIcon>
                <ListItemText primary="Calificaciones" />
                {open6 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open6} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    to="/siip/IndexCalificaciones"
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Calificaciones" />
                  </ListItem>

                </List>
              </Collapse>
              <Divider />
            </>
            : null
        }
        {
          (rol === "alumno") ?
            <>
            </>
            : null
        }

      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div>
        <React.Fragment>
          <Tooltip
            TransitionComponent={Zoom}
            onClick={toggleDrawer("left", true)}
            title="Menú"
          >
            <Burguer style={{ color: "#6EC1E4", fontSize: "2.5em" }} />
          </Tooltip>
          <SwipeableDrawer
            elevation={2}
            anchor="left"
            open={state.left}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
        </React.Fragment>
      </div>
    </ThemeProvider>
  );
}

// export default withStyles(useStyles)(MenuBurguer);
export default MenuBurguer;
