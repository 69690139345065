import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import axios from "axios";
import { Global } from "../../../constants/global"
import { Grid, Typography, Hidden } from '@material-ui/core'
import { fba } from "../../../firebaseConfig";
import { obtenerAspirantes } from '../../../servicios/obtenerAspirantes';


const useStyles = makeStyles((theme) => ({

    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function createData(nombre, paterno, materno, estatus, correo, fecha_na, estado_civil, rfc, genero,
    curp, celular, telefono,  pais_asp, colonia, calle, grado, institucion, pais, estado, fecha_ini,
     fecha_fin,titulacion,promedio,cedula,programa,nivel,etniapersona,resp_etnia) {

    return {nombre, paterno, materno, estatus, correo, fecha_na, estado_civil, rfc, genero,
        curp, celular, telefono, pais_asp, colonia,  calle, grado, institucion, pais, estado, fecha_ini,
         fecha_fin,titulacion,promedio,cedula,programa,nivel,etniapersona,resp_etnia};
}

function TablaAspirantes() {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [recarga, setRecarga] = useState(false)
    const rows2 = []


    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');


    useEffect(() => {

        let userFB = fba.auth().currentUser;
        //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
        db.where('correo', '==', userFB.email).get().then(snapshot => {
            snapshot.docs.forEach(doc => {
                //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                if (doc.data().posgrado === 0) {
                    //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
                    
                    axios.get(Global + "aspirante/lista", {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        console.log('responsedata',response.data)
                        response.data.map((renglon, index) => {
                        
                            rows2[index] = createData(renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8], renglon[9],
                                                       renglon[10], renglon[11], renglon[12], renglon[13], renglon[14], renglon[15], renglon[16], renglon[17], renglon[18],
                                                       renglon[19], renglon[20], renglon[21], renglon[22], renglon[23],renglon[24], renglon[25], renglon[26], renglon[27], renglon[28])
                        })
                        setData(rows2)
                    }).catch(error => console.log(error))
                }
                else {
                    obtenerAspirantes(doc,'aspirante/programas/')
                    
                        .then(programa=>{
                            
                            let aspirantes = [];
                            console.log('programa',programa)
                            for(let aspirante of programa){
                                aspirantes = aspirantes.concat(aspirante);
                            }
                            aspirantes.map((renglon,index)=>{
                                rows2[index] = createData(renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8], renglon[9],
                                    renglon[10], renglon[11], renglon[12], renglon[13], renglon[14], renglon[15], renglon[16], renglon[17], renglon[18],
                                    renglon[19], renglon[20], renglon[21], renglon[22], renglon[23],renglon[24], renglon[25], renglon[26], renglon[27], renglon[28])
                            })
                            setData(rows2)
                            
                        })
                        .catch((err)=>{console.log(err)})
                }
            });
        });


    }, [recarga])



    
        const [columns] = useState([
        { title: 'Nombre', field: 'nombre' },
        { title: 'Primer Apellido', field: 'paterno' },
        { title: 'Segundo Apellido', field: 'materno' },
        { title: 'Estatus', field: 'estatus' },
        { title: 'Correo', field: 'correo' },
        { title: 'Fecha de Nacimiento',type: "date", field: 'fecha_na' },
        { title: 'Estado Civil', field: 'estado_civil' },
        { title: 'RFC', field: 'rfc' },
        { title: 'Genero', field: 'genero' },
        { title: 'Curp', field: 'curp' },
        { title: 'Celular', field: 'celular' },
        { title: 'Telefono', field: 'telefono' },
        { title: 'Pais', field: 'pais_asp' },
        { title: 'Colonia', field: 'colonia' },
        { title: 'Calle', field: 'calle' },
        { title: 'Grado', field: 'grado' },
        { title: 'Institución', field: 'institucion' },
        { title: 'Pais', field: 'pais' },
        { title: 'Estado', field: 'estado' },
        { title: 'Fecha de Inicio', field: 'fecha_ini' },
        { title: 'Fecha de Termino', field: 'fecha_fin' },
        { title: 'Año de Titulación', field: 'titulacion' },
        { title: 'Promedio', field: 'promedio' },
        { title: 'Cedula', field: 'cedula' },
        { title: 'Programa', field: 'programa' },
        { title: 'Nivel', field: 'nivel' },  
        { title: 'Etnia', field: 'etniapersona' },
        { title: 'Respuesta Etnia', field: 'resp_etnia' },    
    ]);

    return (
        <>
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Listado de aspirantes
                            </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    exportButton: true,
                    exportFileName: "Listado de aspirantes",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10,25,50,100,200],
                    columnsButton: true,
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar",
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: "Buscar"
                    },
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                    }
                }}
                data={data}
                columns={columns}
            />
        </>
    )
}
export default TablaAspirantes