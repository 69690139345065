import React, { useEffect, useState } from "react";
import { fba } from "../firebaseConfig";
import Cargando from './Cargando';

export const Auth = React.createContext();

export const AuthContext = ({ children }) => {
    
    const [usuario, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
  
    useEffect(() => {
        fba.auth().onAuthStateChanged(function (user) {
            setUsuario(user);
            setShowChild(true);
        });
    });

    if (!showChild) {
        return <Cargando />;
    } else {
        return (
            <Auth.Provider value={{usuario}}>
                {children}
            </Auth.Provider>
        );
    }
};