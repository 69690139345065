import React, { useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';
import { Global } from '../../../../constants/global';
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Institucion from './Institucion';
import AgregarInstitucion from './AgregarInstitucion';
import Backdrop from '@material-ui/core/Backdrop';
import * as Yup from 'yup';
import { Auth } from "../../../../context/AuthContext";
import { fba } from "../../../../firebaseConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function TablaInstitucion(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [recarga, setRecarga] = React.useState(true);
    const [nuevo, setNuevo] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [ubpps, setUbpps] = React.useState([])
    const { useState } = React;

    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')


    useEffect(async () => {

        if (usuario.email !== null || usuario.email !== undefined) {
            db.where('correo', '==', usuario.email).get().then(snapshot => {
                snapshot.docs.forEach(doc => {
                    // console.log(doc.data())
                    if (usuario.email === doc.data().correo) {
                        setRol(doc.data().rol)
                    } else {
                        // console.log("No se encuentra");
                        return null;
                    }
                });
            });
        }

        await axios.get(Global + "institucion", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response.data)
            setData(response.data)
            setOpen(false)
        }).catch(error => console.log(error))
    }, [recarga])

    const [columns, setColumns] = useState([
        { title: 'Institución', field: 'nombre' },
        { title: 'País', field: 'pais.pais' },
        { title: 'Giro', field: 'giro' },
        { title: 'Web', field: 'pagweb' },
    ]);

    const actualiza = () => {
        setOpen(true)
        setRecarga(!recarga)
    }

    const actualizaNuevo = () => {
        setNuevo(false)
        setRecarga(!recarga)
    }



    const handleClose = () => {
        setNuevo(true)
    }
    // Schema de validacion
    const schemaValidacion = Yup.object({
        nombre: Yup.string().required('Es obligatorio seleccionar la UBPP'),
        pais: Yup.string().required('Es obligatorio seleccionar el pais'),
        giro: Yup.string().required('Es obligatorio ingresar una area o seccion'),
        pagweb: Yup.string().required('Es obligatorio seleccionar la categoria'),
    })

    // console.log(registroAnterior)
    const funcionSubmit = async valores => {
        // console.log(valores)
    }

    return (
        open ?
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop> :
            nuevo ?
                <AgregarInstitucion data={props.data} metodo={actualizaNuevo} />
                : <div>
                    {

                        rol === "coordinador_general" ?
                            <MaterialTable
                                title={<Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo}>
                                            Listado de instituciones
                            </Typography>
                                    </Grid>
                                </Grid>}
                                options={{
                                    exportButton:true,
                                    exportFileName:"Listado de instituciones",
                                    search: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 20],
                                    headerStyle: {
                                        backgroundColor: '#66abe0b8',
                                        color: '#FFF'
                                    }
                                }}
                                detailPanel={[
                                    {
                                        icon: 'create',
                                        openIcon: 'expand_less',
                                        // iconProps: { color: 'secondary', fontSize: 'medium' },
                                        tooltip: 'Editar',
                                        render: rowData => {
                                            // console.log(rowData)
                                            // actualiza()
                                            return (
                                                <Institucion data={rowData} metodo={actualiza} rol={rol}/>
                                            )
                                        },
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '',
                                        labelRowsSelect: '',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Siguiente página',
                                        lastTooltip: 'Última página',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay información por mostrar',
                                        addTooltip: 'Agregar',
                                        editTooltip: 'Editar',
                                        deleteTooltip: 'Deshabilitada',
                                        editRow: {
                                            deleteText: '¿Desea eliminar la adscripcion del profesor?',
                                            cancelTooltip: 'Cancelar',
                                            saveTooltip: 'Guardar'
                                        }
                                    },
                                    toolbar: {
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                        exportTitle: "Exportar",
                                        searchTooltip: 'Buscar en la tabla',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    }

                                }}
                                columns={columns}
                                data={data}
                                actions={[
                                    {
                                        icon: 'add_box',
                                        tooltip: 'Agregar',
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            // console.log(event.target.value)
                                            setNuevo(true)
                                        }
                                    }
                                ]}
                            />
                            :
                            <MaterialTable
                                title={<Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo}>
                                            Listado de instituciones
                        </Typography>
                                    </Grid>
                                </Grid>}
                                options={{
                                    exportButton:true,
                                    exportFileName:"Listado de instituciones",
                                    search: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 20],
                                    headerStyle: {
                                        backgroundColor: '#66abe0b8',
                                        color: '#FFF'
                                    }
                                }}
                                detailPanel={[
                                    {
                                        icon: 'create',
                                        openIcon: 'expand_less',
                                        // iconProps: { color: 'secondary', fontSize: 'medium' },
                                        tooltip: 'Ver',
                                        render: rowData => {
                                            // console.log(rowData)
                                            // actualiza()
                                            return (
                                                <Institucion data={rowData} metodo={actualiza} rol={rol}/>
                                            )
                                        },
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '',
                                        labelRowsSelect: '',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Siguiente página',
                                        lastTooltip: 'Última página',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay información por mostrar',
                                        addTooltip: 'Agregar',
                                        editTooltip: 'Editar',
                                        deleteTooltip: 'Deshabilitada',
                                        editRow: {
                                            deleteText: '¿Desea eliminar la adscripcion del profesor?',
                                            cancelTooltip: 'Cancelar',
                                            saveTooltip: 'Guardar'
                                        }
                                    },
                                    toolbar: {
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                        exportTitle: "Exportar",
                                        searchTooltip: 'Buscar en la tabla',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    }

                                }}
                                columns={columns}
                                data={data}
                            />
                    }
                </div>

    )
}

export default TablaInstitucion;