import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Paper, Backdrop, TextField, Typography, FormControl, InputLabel, FormHelperText, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Swal from 'sweetalert';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import { SUPPORTED_PDF } from "../../../constants/global"
import { mayor,FORMATOFECHAMINHOY } from "../../generales/Fecha"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

function ActualizarReunion(props) {

    const classes = useStyles();
    const { idreunion } = props.data;
    const { correo } = props.data.alumno.persona;
    const [registroAnterior, setRegistroAnterior] = useState([])
    const [adjunto, setAdjunto] = useState(0);
    const [idReunion, setReunion] = useState('')
    const [acceso, setAcceso] = useState(true)

  /*    // Validar campo de año
    const [errorAnio,setErrorAnio]= React.useState(false);
    const [msjError,setmsjError]=React.useState("");
    const [anio, setAnio]=React.useState(""); */

    const [adjuntoMensaje2, setAdjuntoMensaje2] = useState('')

    //Seleccionar año 
    const year = new Date().getFullYear();
    const years = Array.from(new Array(100), (val, index) => year + index);

    useEffect(() => {

        let reunion = {
            acuerdo: '',
            archivo: '',
            avance: '',
            avancesem: '',
            comentarios: '',
            documento: '',
            fecha: '',
            movilidad: '',
            presentacion: '',
            inglest:'',
            diareunion: '',
            mesreunion: '',
            anioreunion: '',
            diaentrega: '',
            mesentrega: '',
            anioentrega: '',

            horareunion: '',
            minutosreunion: '',
            investigacion1: '',
            investigacion2: '',
            investigacion3: '',
            horaentrega:'',
            minutosentrega:'',


        }

        const datosIniciales = async () => {

            props.rol === "administrador" ||
                props.rol === "coordinador_general" ||
                props.rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)
              console.log('get',idreunion)
            await axios.get(Global + "reunionalumno/" + idreunion, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                reunion.acuerdo = response.data.acuerdo
                reunion.archivo = response.data.archivo
                reunion.avance = response.data.avance
                reunion.avancesem = response.data.avancesem
                reunion.comentarios = response.data.comentarios
                reunion.documento = response.data.documento
                reunion.fecha = response.data.fecha
                reunion.movilidad = response.data.movilidad
                reunion.presentacion = response.data.presentacion
                reunion.inglest = response.data.inglest
                reunion.diareunion = response.data.diareunion
                reunion.mesreunion = response.data.mesreunion
                reunion.anioreunion = response.data.anioreunion
                reunion.diaentrega = response.data.diaentrega
                reunion.mesentrega = response.data.mesentrega
                reunion.anioentrega = response.data.anioentrega

                reunion.horareunion =response.data.horareunion
                reunion.minutosreunion = response.data.minutosreunion
                reunion.investigacion1 = response.data.investigacion1
                reunion.investigacion2 = response.data.investigacion2
                reunion.investigacion3 = response.data.investigacion3
                reunion.horaentrega = response.data.horaentrega
                reunion.minutosentrega = response.data.minutosentrega

                setReunion(response.data.idreunion)


            }).catch(error => console.log(error))

            if (reunion.archivo !== null && reunion.archivo !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + reunion.archivo)
                setAdjuntoMensaje2(await httpsReference.getDownloadURL());
            }
            handleClose()
        }

        datosIniciales();
        setRegistroAnterior(reunion)

    }, [])


    // Schema de validacion
    const schemaValidacion = Yup.object({
        archivo: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
        () => (adjunto === 0 || SUPPORTED_PDF.includes(adjunto.type))),

    /* avance: Yup.string().required("El campo de avance por semestre es obligatorio"), */
    diareunion: Yup.string().required("El campo de día es obligatorio"),
    mesreunion: Yup.string().required("El campo de mes es obligatorio"),
    anioreunion: Yup.string().required("El campo de año es obligatorio"),
    diaentrega: Yup.string().required("El campo de día es obligatorio"),
    mesentrega: Yup.string().required("El campo de mes es obligatorio"),
    anioentrega: Yup.string().required("El campo de año es obligatorio"),

    horareunion: Yup.string().required("El campo de hora es obligatorio"),
    minutosreunion: Yup.string().required("El campo de minutos es obligatorio") ,
    horaentrega:Yup.string().required("El campo de hora de entrega es obligatorio"),
    minutosentrega:Yup.string().required("El campo de minutos de entrega es obligatorio"),

    comentarios: '',
    documento: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
        () => (adjunto === 0 || SUPPORTED_PDF.includes(adjunto.type))),
    avan: '',
    movilidad: '',
    presentacion: '',
    })


    const funcionSubmit = async valores => {

        let linkDocumento = ""
        if (adjunto) {
            if (registroAnterior.archivo === '') {
                //console.log('entro')
                linkDocumento = correo + '/DocumentosReuniones/' + uuidv4() + adjunto.name
            }
            // linkDocumento = correo + '/DocumentosPredoctoral/' + uuidv4() + adjunto.name
            const storageRef = fba.storage().ref(linkDocumento)
            const task = storageRef.put(adjunto)
            task.on('state_changed', (snapshot) => {
                // let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // setUploadTitulo(porcentage)
                // setTitulomensaje(porcentage + '%')
            }, (error) => {
                // setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        let reunion = {
            acuerdo: valores.acuerdo,
            archivo: linkDocumento,
            avance: valores.avance,
            avancesem: valores.avancesem,
            comentarios: valores.comentarios,
            documento: valores.documento,
            fecha: valores.fecha,
            movilidad: valores.movilidad,
            presentacion: valores.presentacion,
            inglest: valores.inglest,
            diareunion: valores.diareunion,
            mesreunion: valores.mesreunion,
            anioreunion: valores.anioreunion,
            diaentrega: valores.diaentrega,
            mesentrega: valores.mesentrega,
            anioentrega: valores.anioentrega,
            
            horareunion: valores.horareunion,
            minutosreunion: valores.minutosreunion,
            investigacion1: valores.investigacion1,
            investigacion2: valores.investigacion2,
            investigacion3: valores.investigacion3,
            horaentrega: valores.horaentrega,
            minutosentrega: valores.minutosentrega,

            alumno: {
                idalumno: ''
            }
        };

        axios.put(Global + "reunionalumno/" + idReunion, reunion, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(() => {
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
            props.metodo()
        }).catch(error => { console.log(error); })

    }

    const handleClickDownload = (idreunion) => () => {
        console.log('idreunion',idreunion)
        axios({
            url: Global + "pdf/reunion/"+idreunion, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Acta_Reunión.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })

    }

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "archivo":
                    setAdjunto(file);
                    break;
                default:
                    break;
            }

        } else {
            switch (event.target.id) {
                case "archivo":
                    setAdjunto(false)
                    break;
                default:
                    break;
            }
        }
    }

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const initial = registroAnterior

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>

                                       {/*  <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                label="Fecha de la reunión"
                                                id="fecha"
                                                name="fecha"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.values.fecha}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.fecha && props.errors.fecha ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.fecha && props.errors.fecha ? (
                                                    props.errors.fecha
                                                ) : null} 
                                                InputProps={FORMATOFECHAMINHOY}
                                                />
                                        </Grid> */}

                                        <Grid item xs={12} sm={4}>
                                         <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            disabled={acceso}
                                            error={props.touched.diareunion && props.errors.diareunion ? (true) : false}>
                                            <InputLabel>Dia de Reunión</InputLabel>
                                            <Select
                                                native
                                                value={props.values.diareunion}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Dia de Reunión"
                                                inputProps={{
                                                    name: 'diareunion',
                                                    id: 'diareunion'
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'01'}>01</option>  
                                                <option value={'02'}>02</option>
                                                <option value={'03'}>03</option>  
                                                <option value={'04'}>04</option>
                                                <option value={'05'}>05</option>  
                                                <option value={'06'}>06</option>
                                                <option value={'07'}>07</option>  
                                                <option value={'08'}>08</option>
                                                <option value={'09'}>09</option>  
                                                <option value={'10'}>10</option>
                                                <option value={'11'}>11</option>  
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>  
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>  
                                                <option value={'16'}>16</option>  
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>  
                                                <option value={'19'}>19</option>
                                                <option value={'20'}>20</option>  
                                                <option value={'21'}>21</option>
                                                <option value={'22'}>22</option>  
                                                <option value={'23'}>23</option>
                                                <option value={'24'}>24</option>  
                                                <option value={'25'}>25</option>
                                                <option value={'26'}>26</option>  
                                                <option value={'27'}>27</option>
                                                <option value={'28'}>28</option>  
                                                <option value={'29'}>29</option>
                                                <option value={'30'}>30</option>  
                                                <option value={'31'}>31</option>                       
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.diareunion && props.errors.diareunion ? (props.errors.diareunion) : null} </FormHelperText>
                                         </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                error={props.touched.mesreunion && props.errors.mesreunion? (true) : false}>
                                                <InputLabel>Mes de Reunión</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.mesreunion}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="mes"
                                                    inputProps={{
                                                        name: 'mesreunion',
                                                        id: 'mesreunion'
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={"ENERO"}>ENERO</option>
                                                    <option value={"FEBRERO"}>FEBRERO</option>
                                                    <option value={"MARZO"}>MARZO</option>
                                                    <option value={"ABRIL"}>ABRIL</option>
                                                    <option value={"MAYO"}>MAYO</option>
                                                    <option value={"JUNIO"}>JUNIO</option>
                                                    <option value={"JULIO"}>JULIO</option>
                                                    <option value={"AGOSTO"}>AGOSTO</option>
                                                    <option value={"SEPTIEMBRE"}>SEPTIEMBRE</option>
                                                    <option value={"OCTUBRE"}>OCTUBRE</option>
                                                    <option value={"NOVIEMBRE"}>NOVIEMBRE</option>
                                                    <option value={"DICIEMBRE"}>DICIEMBRE</option>
                                                    
                                                                        
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.mesreunion && props.errors.mesreunion ? (props.errors.mesreunion) : null} </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                        <FormControl
                                            /* disabled={acceso} */
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={
                                            props.touched.anioreunion && props.errors.anioreunion
                                                ? true
                                                : false
                                            }
                                        >
                                            <InputLabel>Año de reunión</InputLabel>
                                            <Select
                                            native
                                            value={props.values.anioreunion}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            label="Año de reunón"
                                            inputProps={{
                                                name: "anioreunion",
                                                id: "anioreunion",
                                            }}
                                            >
                                            <option aria-label="None" value="" />
                                            {years.map((year, index) => {
                                                return (
                                                <option key={`year${index}`} value={year}>
                                                    {year}
                                                </option>
                                                );
                                            })}
                                            </Select>
                                            <FormHelperText>
                                            {props.touched.anioreunion && props.errors.anioreunion
                                                ? props.errors.anioreunion
                                                : null}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={4}>
                                        <TextField
                                            label="Año de reunióm"
                                            name="anioreunion"
                                            variant="outlined"
                                            inputProps={{min:2000, max:2200}}
                                            type='number'
                                            fullWidth
                                            value={props.values.anioreunion}
                                            onChange={(e) => {
                                                let ingl = e.target.value;

                                                setAnio(e.target.value);
                                                if(anio.length>4 ){
                                                    setErrorAnio(true);
                                                    setmsjError("Error al escribir el año");
                                                }else{setErrorAnio(false);
                                                    setmsjError("");}

                                                props.setFieldValue("anioreunion", ingl.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={ errorAnio && props.touched.anioreunion && props.errors.anioreunion ? (
                                                true
                                            ) : false}
                                            helperText={msjError && props.touched.anioreunion && props.errors.anioreunion ? (
                                                props.errors.anioreunion
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid> */}

                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={props.touched.horareunion && props.errors.horareunion ? (true) : false}>
                                            <InputLabel>Hora de reunión</InputLabel>
                                            <Select
                                                native
                                                value={props.values.horareunion}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="hora de reunión"
                                                inputProps={{
                                                    name: 'horareunion',
                                                    id: 'horareunion'
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'00'}>00</option>
                                                <option value={'01'}>01</option>  
                                                <option value={'02'}>02</option>
                                                <option value={'03'}>03</option>  
                                                <option value={'04'}>04</option>
                                                <option value={'05'}>05</option>  
                                                <option value={'06'}>06</option>
                                                <option value={'07'}>07</option>  
                                                <option value={'08'}>08</option>
                                                <option value={'09'}>09</option>  
                                                <option value={'10'}>10</option>
                                                <option value={'11'}>11</option>  
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>  
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>  
                                                <option value={'16'}>16</option>  
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>  
                                                <option value={'19'}>19</option>
                                                <option value={'20'}>20</option>  
                                                <option value={'21'}>21</option>
                                                <option value={'22'}>22</option>  
                                                <option value={'23'}>23</option> 
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.horareunion && props.errors.horareunion ? (props.errors.horareunion) : null} </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={props.touched.minutosreunion && props.errors.minutosreunion ? (true) : false}>
                                            <InputLabel>Minutos de reunión</InputLabel>
                                            <Select
                                                native
                                                value={props.values.minutosreunion}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="minutos de reunión"
                                                inputProps={{
                                                    name: 'minutosreunion',
                                                    id: 'minutosreunion'
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'00'}>00</option>
                                                <option value={'01'}>01</option>  
                                                <option value={'02'}>02</option>
                                                <option value={'03'}>03</option>  
                                                <option value={'04'}>04</option>
                                                <option value={'05'}>05</option>  
                                                <option value={'06'}>06</option>
                                                <option value={'07'}>07</option>  
                                                <option value={'08'}>08</option>
                                                <option value={'09'}>09</option>  
                                                <option value={'10'}>10</option>
                                                <option value={'11'}>11</option>  
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>  
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>  
                                                <option value={'16'}>16</option>  
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>  
                                                <option value={'19'}>19</option>
                                                <option value={'20'}>20</option>  
                                                <option value={'21'}>21</option>
                                                <option value={'22'}>22</option>  
                                                <option value={'23'}>23</option>
                                                <option value={'24'}>24</option>  
                                                <option value={'25'}>25</option>
                                                <option value={'26'}>26</option>
                                                <option value={'27'}>27</option>
                                                <option value={'28'}>28</option>
                                                <option value={'29'}>29</option>
                                                <option value={'30'}>30</option>
                                                <option value={'31'}>31</option>
                                                <option value={'32'}>32</option>  
                                                <option value={'33'}>33</option>
                                                <option value={'34'}>34</option>  
                                                <option value={'35'}>35</option>
                                                <option value={'36'}>36</option>
                                                <option value={'37'}>37</option>
                                                <option value={'38'}>38</option>
                                                <option value={'39'}>39</option>
                                                <option value={'40'}>40</option>
                                                <option value={'41'}>41</option>
                                                <option value={'42'}>42</option>  
                                                <option value={'43'}>43</option>
                                                <option value={'44'}>44</option>  
                                                <option value={'45'}>45</option>
                                                <option value={'46'}>46</option>
                                                <option value={'47'}>47</option>
                                                <option value={'48'}>48</option>
                                                <option value={'49'}>49</option>
                                                <option value={'50'}>50</option>
                                                <option value={'51'}>51</option>
                                                <option value={'52'}>52</option>  
                                                <option value={'53'}>53</option>
                                                <option value={'54'}>54</option>  
                                                <option value={'55'}>55</option>
                                                <option value={'56'}>56</option>
                                                <option value={'57'}>57</option>
                                                <option value={'58'}>58</option>
                                                <option value={'59'}>59</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.minutosreunion && props.errors.minutosreunion ? (props.errors.minutosreunion) : null} </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                            <TextField
                                                label="Avance (%) en el documento de graduación, debidamente justificado"
                                                name="avance"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                inputProps={{min:0, max:1000}}
                                                type="number"
                                                value={props.values.avance}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.avance && props.errors.avance ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.avance && props.errors.avance ? (
                                                    props.errors.avance
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            disabled={acceso}
                                            error={props.touched.diaentrega && props.errors.diaentrega ? (true) : false}>
                                            <InputLabel>Dia de Entrega</InputLabel>
                                            <Select
                                                native
                                                value={props.values.diaentrega}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Dia de Entrega"
                                                inputProps={{
                                                    name: 'diaentrega',
                                                    id: 'diaentrega'
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'01'}>01</option>  
                                                <option value={'02'}>02</option>
                                                <option value={'03'}>03</option>  
                                                <option value={'04'}>04</option>
                                                <option value={'05'}>05</option>  
                                                <option value={'06'}>06</option>
                                                <option value={'07'}>07</option>  
                                                <option value={'08'}>08</option>
                                                <option value={'09'}>09</option>  
                                                <option value={'10'}>10</option>
                                                <option value={'11'}>11</option>  
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>  
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>  
                                                <option value={'16'}>16</option>  
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>  
                                                <option value={'19'}>19</option>
                                                <option value={'20'}>20</option>  
                                                <option value={'21'}>21</option>
                                                <option value={'22'}>22</option>  
                                                <option value={'23'}>23</option>
                                                <option value={'24'}>24</option>  
                                                <option value={'25'}>25</option>
                                                <option value={'26'}>26</option>  
                                                <option value={'27'}>27</option>
                                                <option value={'28'}>28</option>  
                                                <option value={'29'}>29</option>
                                                <option value={'30'}>30</option>  
                                                <option value={'31'}>31</option>                       
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.diaentrega && props.errors.diaentrega ? (props.errors.diaentrega) : null} </FormHelperText>
                                        </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                error={props.touched.mesentrega && props.errors.mesentrega? (true) : false}>
                                                <InputLabel>Mes de Entrega</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.mesentrega}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="mes"
                                                    inputProps={{
                                                        name: 'mesentrega',
                                                        id: 'mesentrega'
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={"ENERO"}>ENERO</option>
                                                    <option value={"FEBRERO"}>FEBRERO</option>
                                                    <option value={"MARZO"}>MARZO</option>
                                                    <option value={"ABRIL"}>ABRIL</option>
                                                    <option value={"MAYO"}>MAYO</option>
                                                    <option value={"JUNIO"}>JUNIO</option>
                                                    <option value={"JULIO"}>JULIO</option>
                                                    <option value={"AGOSTO"}>AGOSTO</option>
                                                    <option value={"SEPTIEMBRE"}>SEPTIEMBRE</option>
                                                    <option value={"OCTUBRE"}>OCTUBRE</option>
                                                    <option value={"NOVIEMBRE"}>NOVIEMBRE</option>
                                                    <option value={"DICIEMBRE"}>DICIEMBRE</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.mesentrega && props.errors.mesentrega ? (props.errors.mesentrega) : null} </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            /* disabled={acceso} */
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            error={
                                            props.touched.anioentrega && props.errors.anioentrega
                                                ? true
                                                : false
                                            }
                                        >
                                            <InputLabel>Año de entrega</InputLabel>
                                            <Select
                                            native
                                            value={props.values.anioentrega}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            label="Año de reunón"
                                            inputProps={{
                                                name: "anioentrega",
                                                id: "anioentrega",
                                            }}
                                            >
                                            <option aria-label="None" value="" />
                                            {years.map((year, index) => {
                                                return (
                                                <option key={`year${index}`} value={year}>
                                                    {year}
                                                </option>
                                                );
                                            })}
                                            </Select>
                                            <FormHelperText>
                                            {props.touched.anioentrega && props.errors.anioentrega
                                                ? props.errors.anioentrega
                                                : null}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={4}>
                                        <TextField
                                            label="Año de entrega"
                                            name="anioentrega"
                                            variant="outlined"
                                            inputProps={{min:2000, max:3000}}
                                            type='number'
                                            fullWidth
                                            value={props.values.anioentrega}
                                            onChange={(e) => {
                                                let ingl = e.target.value;
                                                props.setFieldValue("anioentrega", ingl.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.anioentrega && props.errors.anioentrega ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.anioentrega && props.errors.anioentrega ? (
                                                props.errors.anioentrega
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid> */}

                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={props.touched.horaentrega && props.errors.horaentrega ? (true) : false}>
                                            <InputLabel>Hora de entrega</InputLabel>
                                            <Select
                                                native
                                                value={props.values.horaentrega}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="hora de reunión"
                                                inputProps={{
                                                    name: 'horaentrega',
                                                    id: 'horaentrega'
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'00'}>00</option>
                                                <option value={'01'}>01</option>  
                                                <option value={'02'}>02</option>
                                                <option value={'03'}>03</option>  
                                                <option value={'04'}>04</option>
                                                <option value={'05'}>05</option>  
                                                <option value={'06'}>06</option>
                                                <option value={'07'}>07</option>  
                                                <option value={'08'}>08</option>
                                                <option value={'09'}>09</option>  
                                                <option value={'10'}>10</option>
                                                <option value={'11'}>11</option>  
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>  
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>  
                                                <option value={'16'}>16</option>  
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>  
                                                <option value={'19'}>19</option>
                                                <option value={'20'}>20</option>  
                                                <option value={'21'}>21</option>
                                                <option value={'22'}>22</option>  
                                                <option value={'23'}>23</option> 
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.horaentrega && props.errors.horaentrega ? (props.errors.horaentrega) : null} </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={props.touched.minutosentrega && props.errors.minutosentrega ? (true) : false}>
                                            <InputLabel>Minutos de entrega</InputLabel>
                                            <Select
                                                native
                                                value={props.values.minutosentrega}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="minutos de reunión"
                                                inputProps={{
                                                    name: 'minutosentrega',
                                                    id: 'minutosentrega'
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'00'}>00</option>
                                                <option value={'01'}>01</option>  
                                                <option value={'02'}>02</option>
                                                <option value={'03'}>03</option>  
                                                <option value={'04'}>04</option>
                                                <option value={'05'}>05</option>  
                                                <option value={'06'}>06</option>
                                                <option value={'07'}>07</option>  
                                                <option value={'08'}>08</option>
                                                <option value={'09'}>09</option>  
                                                <option value={'10'}>10</option>
                                                <option value={'11'}>11</option>  
                                                <option value={'12'}>12</option>
                                                <option value={'13'}>13</option>  
                                                <option value={'14'}>14</option>
                                                <option value={'15'}>15</option>  
                                                <option value={'16'}>16</option>  
                                                <option value={'17'}>17</option>
                                                <option value={'18'}>18</option>  
                                                <option value={'19'}>19</option>
                                                <option value={'20'}>20</option>  
                                                <option value={'21'}>21</option>
                                                <option value={'22'}>22</option>  
                                                <option value={'23'}>23</option>
                                                <option value={'24'}>24</option>  
                                                <option value={'25'}>25</option>
                                                <option value={'26'}>26</option>
                                                <option value={'27'}>27</option>
                                                <option value={'28'}>28</option>
                                                <option value={'29'}>29</option>
                                                <option value={'30'}>30</option>
                                                <option value={'31'}>31</option>
                                                <option value={'32'}>32</option>  
                                                <option value={'33'}>33</option>
                                                <option value={'34'}>34</option>  
                                                <option value={'35'}>35</option>
                                                <option value={'36'}>36</option>
                                                <option value={'37'}>37</option>
                                                <option value={'38'}>38</option>
                                                <option value={'39'}>39</option>
                                                <option value={'40'}>40</option>
                                                <option value={'41'}>41</option>
                                                <option value={'42'}>42</option>  
                                                <option value={'43'}>43</option>
                                                <option value={'44'}>44</option>  
                                                <option value={'45'}>45</option>
                                                <option value={'46'}>46</option>
                                                <option value={'47'}>47</option>
                                                <option value={'48'}>48</option>
                                                <option value={'49'}>49</option>
                                                <option value={'50'}>50</option>
                                                <option value={'51'}>51</option>
                                                <option value={'52'}>52</option>  
                                                <option value={'53'}>53</option>
                                                <option value={'54'}>54</option>  
                                                <option value={'55'}>55</option>
                                                <option value={'56'}>56</option>
                                                <option value={'57'}>57</option>
                                                <option value={'58'}>58</option>
                                                <option value={'59'}>59</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.minutosentrega && props.errors.minutosentrega ? (props.errors.minutosentrega) : null} </FormHelperText>
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12}>
                                            <TextField
                                                label="Documento de graduación"
                                                name="documento"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.documento}
                                                onChange={(e) => {
                                                    let doc = e.target.value;
                                                    props.setFieldValue("documento", doc.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.documento && props.errors.documento ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.documento && props.errors.documento ? (
                                                    props.errors.documento
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Idioma Inglés"
                                                name="inglest"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.inglest}
                                                onChange={(e) => {
                                                    let inglest = e.target.value;
                                                    props.setFieldValue("inglest", inglest.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.inglest && props.errors.inglest ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.inglest && props.errors.inglest ? (
                                                    props.errors.inglest
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                        <TextField
                                            label="investigación 1"
                                            name="investigacion1"
                                            variant="outlined"
                                            fullWidth
                                            value={props.values.investigacion1}
                                            onChange={(e) => {
                                                let ingl = e.target.value;
                                                props.setFieldValue("investigacion1", ingl.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.investigacion1 && props.errors.investigacion1 ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.investigacion1 && props.errors.investigacion1 ? (
                                                props.errors.investigacion1
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="investigación 2"
                                            name="investigacion2"
                                            variant="outlined"
                                            fullWidth
                                            value={props.values.investigacion2}
                                            onChange={(e) => {
                                                let ingl = e.target.value;
                                                props.setFieldValue("investigacion2", ingl.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.investigacion2 && props.errors.investigacion2 ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.investigacion2 && props.errors.investigacion2 ? (
                                                props.errors.investigacion2
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="investigación 3"
                                            name="investigacion3"
                                            variant="outlined"
                                            fullWidth
                                            value={props.values.investigacion3}
                                            onChange={(e) => {
                                                let ingl = e.target.value;
                                                props.setFieldValue("investigacion3", ingl.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.investigacion3 && props.errors.investigacion3 ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.investigacion3 && props.errors.investigacion3 ? (
                                                props.errors.investigacion3
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Programación de presentación de resultados en congresos científicos"
                                                name="presentacion"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.presentacion}
                                                onChange={(e) => {
                                                    let presentacion = e.target.value;
                                                    props.setFieldValue("presentacion", presentacion.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.presentacion && props.errors.presentacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.presentacion && props.errors.presentacion ? (
                                                    props.errors.presentacion
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Acuerdos y programación"
                                                name="acuerdo"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                value={props.values.acuerdo}
                                                onChange={(e) => {
                                                    let acuerdo = e.target.value;
                                                    props.setFieldValue("acuerdo", acuerdo.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.acuerdo && props.errors.acuerdo ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.acuerdo && props.errors.acuerdo ? (
                                                    props.errors.acuerdo
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                       {/*  <Grid item xs={12}>
                                            <TextField
                                                label="Avances por semestre"
                                                name="avancesem"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled={acceso}
                                                value={props.values.avancesem}
                                                onChange={(e) => {
                                                    let avance = e.target.value;
                                                    props.setFieldValue("avancesem", avance.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.avancesem && props.errors.avancesem ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.avancesem && props.errors.avancesem ? (
                                                    props.errors.avancesem
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid> */}

                                        {/* <Grid item xs={12}>
                                            <TextField
                                                label="Programación de movilidad"
                                                name="movilidad"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.movilidad}
                                                onChange={(e) => {
                                                    let movilidad = e.target.value;
                                                    props.setFieldValue("movilidad", movilidad.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.movilidad && props.errors.movilidad ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.movilidad && props.errors.movilidad ? (
                                                    props.errors.movilidad
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid> */}

                                       {/*  <Grid item xs={12}>
                                            <TextField
                                                label="Comentarios adicionales"
                                                name="comentarios"
                                                variant="outlined"
                                                fullWidth
                                                disabled={acceso}
                                                value={props.values.comentarios}
                                                onChange={(e) => {
                                                    let comen = e.target.value;
                                                    props.setFieldValue("comentarios", comen.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.comentarios && props.errors.comentarios ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.comentarios && props.errors.comentarios ? (
                                                    props.errors.comentarios
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid> */}

                                        <Grid item xs={12} sm={12}>
                                            <Grid item xs={12} sm={3}>
                                                <label htmlFor="archivo">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="archivo"
                                                        name="archivo"
                                                        type="file"
                                                        disabled={acceso}
                                                        accept="application/pdf"
                                                        onChange={handleOnChange}
                                                    />
                                                    <Button className={classes.boton} color="primary" variant="contained" component="span" >
                                                        <FolderOpenIcon style={{ marginRight: 10 }} />
                                                        Acta
                                                        {adjunto ?
                                                            <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                            : null}
                                                    </Button>

                                                    {"  "}
                                                    <Button
                                                           onClick={handleClickDownload(idreunion)}
                                                            variant="contained"   
                                                            color="primary"
                                                            aling="left"
                                                            className={classes.boton}>
                                                            Imprimir Acta
                                                    </Button>

                                                    <Typography variant="caption" display="block" gutterBottom color="error">
                                                        {(props.touched.archivo && props.errors.archivo) && (props.errors.archivo)}
                                                    </Typography>
                                                </label>
                                            </Grid>
                                            <Grid item xs={1} sm={2}>
                                                <Typography variant='body2'>
                                                    {adjuntoMensaje2 !== null && adjuntoMensaje2 !== '' ?
                                                        <a style={{ display: "table-cell" }} href={adjuntoMensaje2} target="_blank" rel="noreferrer">
                                                            Ver archivo
                                                        </a> : "Sin documento"}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <br />
                                    {
                                        !acceso ?

                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.boton}>
                                                        Guardar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                            </Grid>
                                            : null
                                    }
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
        </div >
    );
}

export default ActualizarReunion;