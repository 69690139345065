import React, { useEffect, useState, useContext } from 'react'
import { CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography, Breadcrumbs, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Head from "../../generales/Head";
import Pie from "../../generales/Footer";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { Auth } from "../../../context/AuthContext";
import Swal from 'sweetalert'
import HomeIcon from '@material-ui/icons/Home';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { FORMATOFECHAMAXHOY, menor } from "../../generales/Fecha"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: "5%",
        marginRight: "5%",
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },

    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

}));

const AgregarProfesor = ({ history }) => {
    const classes = useStyles();
    const { usuario } = useContext(Auth);
    const [carga, setCarga] = useState(false)
    useEffect(() => {
        if (usuario === null) {
            history.push("/siip");
        }
    })

    const [paises, setPaises] = useState([]);
    const [paisselect, setPaisselect] = useState('pais')
    // const [codigo, setCodigo] = useState(0);
    const [estados, setEstados] = useState([]);
    const [estadoselect, setEstadoselect] = useState('estado')
    const [ciudades, setCiudades] = useState([]);
    const [ciudadselect, setCiudadselect] = useState('ciudad')
    // const [colonias, setColonias] = useState([]);
    // const [coloniaselect, setColoniaselect] = useState('colonia')
    const [etnia, setEtnia] = useState([]);

    useEffect(async () => {
        // PAIS
        await axios.get(Global + "pais", {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response);
            setPaises(response.data);
        }).catch(error => console.log(error));

        // ETNIA
        await axios.get(Global + "etnia", {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            // console.log(response);
            setEtnia(response.data);
        }).catch(error => console.log(error));

        axios.get(Global + "codigo/estados", {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setEstados(response.data)
        }).catch(error => console.log(error))

    }, []);

    useEffect(() => {
        axios.get(Global + "codigo/estado/" + estadoselect, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setCiudades(response.data)
        }).catch(error => console.log(error))
    }, [estadoselect])


    const initial = ({
        paterno: '',
        materno: '',
        nombre: '',
        civil: '',
        nacimiento: '',
        genero: '',
        pais: '',
        // codigo: '',
        estado: '',
        ciudad: '',
        // colonia: '',
        // otra: '',
        correo: '',
        correo2: '',
        telefono: '',
        celular: '',
        etnico: '',
        cvu: '',
        rfc: '',
        curp: '',
    });

    const schemaValidacion = Yup.object({
        paterno: Yup.string().required('Es obligatorio ingresar el apellido paterno'),
        materno: Yup.string().required('Es obligatorio ingresar el aperllido materno'),
        nombre: Yup.string().required('Es obligatorio ingresar el nombre'),
        nacimiento: Yup.string().required('Es obligatorio seleccionar la fecha de nacimiento').test('match',
            'La fecha debe ser menor a la fecha actual',
            function (fecha) {
                return menor(fecha)
            }
        ),
        genero: Yup.string().required('Es obligatorio seleccionar el genero'),
        // civil: Yup.string().required('Es obligatorio seleccionar el estado civil'),
        pais: Yup.string().required('Es obligatorio seleccionar el pais de nacimiento'),
        // codigo: Yup.number().when('pais', (pais) => {
        //     if (pais == "MÉXICO") {
        //         return Yup.string().required('Es obligatorio ingresar el codigo postal').matches(/^[1-9][0-9]*$/, "Solo debe ingresar números.")
        //     } else {
        //         return Yup.string()
        //     }
        // }),
        estado: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required('Es obligatorio seleccionar el estado')
            } else {
                return Yup.string()
            }
        }),
        ciudad: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required('Es obligatorio seleccionar la ciudad')
            } else {
                return Yup.string()
            }
        }),
        // colonia: Yup.string().when('pais', (pais) => {
        //     if (pais == "MÉXICO") {
        //         return Yup.string().required('Es obligatorio seleccionar la colonia')
        //     } else {
        //         return Yup.string()
        //     }
        // }),
        // otra: Yup.string().when('colonia', (colonia) => {
        //     if (colonia == "otra") {
        //         return Yup.string().required('Es obligatorio ingresar la colonia')
        //     } else {
        //         return Yup.string()
        //     }
        // }),
        correo: Yup.string().required('El campo correo es obligatorio').email('Ingrese un email valido'),
        correo2: Yup.string().email('Ingrese un email valido'),
        telefono: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        celular: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        rfc: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required("El campo RFC es obligatorio si es de México").matches(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/, 'El campo no cumple con el formato de RFC.')
            } else {
                return Yup.string()
            }
        }),
        curp: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required("El campo CURP es obligatorio si es de México").matches(/^[A-Z]{4}[0-9]{6}[A-Z]{6}[A-Z0-9]{2}$/, 'El campo no cumple con el format de CURP.')
            } else {
                return Yup.string()
            }
        }),
    })

    const funcionSubmit = async valores => {
        setCarga(true)
        const data = {
            abreviatura: '',
            estatus: 'alta',
            persona: {
                nombre: valores.nombre,
                apellidoM: valores.paterno,
                apellidoP: valores.materno,
                fecha_na: valores.nacimiento,
                rfc: valores.rfc,
                curp: valores.curp,
                estado_civil: valores.civil,
                genero: valores.genero,
                telefono: valores.telefono,
                celular: valores.celular,
                correo: valores.correo,
                email: valores.correo2,
                cvu: valores.cvu,
                fotografia: "",
                doc_curp: "",
                acta_nac: ""
            }
        }
        await axios.post(Global + "profesor", data, {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {


            axios.get(Global + "pais/pais/" + valores.pais, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response2 => {
                const direccion = {
                    ciudad: valores.ciudad,
                    estado: valores.estado,
                }
                axios.post(Global + "lugarna/pais/" + response2.data[0].idpais + "/persona/" + response.data.persona.idP, direccion, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response3 => {
                    if (valores.etnico !== '') {
                        axios.post(Global + "personaed/persona/" + response.data.persona.idP + "/etnia/" + valores.etnico, {}, {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).then(response => {

                        }).catch(error => console.log(error))
                    }
                    Swal({
                        title: 'Profesor registrado',
                        text: ' ',
                        icon: 'success',
                        button: false,
                        timer: 1000
                    })
                    setCarga(false)
                    listado()

                }).catch(error => console.log(error))


            }).catch(error => console.log(error))

        }).catch(error => console.log(error))


    }

    const listado = () => {
        history.push("/siip/IndexProfesores");
    }
    const handleOnChangePais = (event) => {
        setPaisselect(event.target.value)
    }

    const handleOnChangeEstado = (event) => {
        setEstadoselect(event.target.value)
    }
    const handleOnChangeCiudad = (event) => {
        setCiudadselect(event.target.value)
    }

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <Head />
            <Backdrop className={classes.backdrop} open={carga}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} className={classes.paperContainer}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link href="/inicio" className={classes.link}>
                        <HomeIcon className={classes.icon} />
                        Inicio
                    </Link>
                    <Link
                        aria-current="page"
                        className={classes.link}
                    >
                        <LocalLibraryIcon className={classes.icon} />
                        Profesores
                    </Link>
                    <Link
                        color="textPrimary"
                        href="/siip/AgregarProfesor"
                        aria-current="page"
                        className={classes.link}
                    >
                        <PersonAddIcon className={classes.icon} />
                        Agregar Profesor
                    </Link>
                </Breadcrumbs>
                <Grid
                    container
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                        <br />
                        <Typography className={classes.subtitulo}>
                            Agregar datos personales de profesor
                        </Typography>
                    </Grid>
                </Grid>
                {open ?
                    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Apellido Paterno"
                                                name="paterno"
                                                id="paterno"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={props.values.paterno}
                                                onChange={(e) => {
                                                    let apellidop = e.target.value;
                                                    props.setFieldValue("paterno", apellidop.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.paterno && props.errors.paterno ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.paterno && props.errors.paterno ? (
                                                    props.errors.paterno
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Apellido Materno"
                                                name="materno"
                                                id="materno"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={props.values.materno}
                                                onChange={(e) => {
                                                    let apellidom = e.target.value;
                                                    props.setFieldValue("materno", apellidom.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.materno && props.errors.materno ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.materno && props.errors.materno ? (
                                                    props.errors.materno
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                label="Nombre"
                                                name="nombre"
                                                id="nombre"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={props.values.nombre}
                                                onChange={(e) => {
                                                    let name = e.target.value;
                                                    props.setFieldValue("nombre", name.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.nombre && props.errors.nombre ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.nombre && props.errors.nombre ? (
                                                    props.errors.nombre
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={props.touched.genero && props.errors.genero ? (true) : false}
                                                required

                                            >
                                                <InputLabel>Género</InputLabel>
                                                <Select
                                                    native
                                                    // value={formik.values.gen}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Género"
                                                    inputProps={{
                                                        name: 'genero',
                                                        id: 'genero',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'Masculino'}>Masculino</option>
                                                    <option value={'Femenino'}>Femenino</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.genero && props.errors.genero ? (props.errors.genero) : null}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={props.touched.civil && props.errors.civil ? (true) : false}>
                                                <InputLabel>Estado civil</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.civil}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Estado civil"
                                                    inputProps={{
                                                        name: 'civil',
                                                        id: 'civil',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'soltero'}>Soltero</option>
                                                    <option value={'casado'}>Casado</option>
                                                    <option value={'divorciado'}>Divorciado</option>
                                                    <option value={'separacion'}>Separación en proceso judicial</option>
                                                    <option value={'viudo'}>Viudo</option>
                                                    <option value={'concubinato'}>Concubinato</option>
                                                </Select>

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="nacimiento"
                                                name="nacimiento"
                                                label="Fecha de nacimiento"
                                                type="date"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.nacimiento && props.errors.nacimiento ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.nacimiento && props.errors.nacimiento ? (
                                                    props.errors.nacimiento
                                                ) : null}
                                                InputProps={FORMATOFECHAMAXHOY}
                                            /> 
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={props.touched.pais && props.errors.pais ? (true) : false}
                                                className={classes.formControl}
                                                required
                                            >
                                                <InputLabel >Pais de origen</InputLabel>
                                                <Select
                                                    native
                                                    // value={props.values.pais}
                                                    onChange={e => {
                                                        handleOnChangePais(e);
                                                        props.setFieldValue("pais", e.target.value);

                                                    }}
                                                    label="pais de origen"
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        paises.map((pais) => {
                                                            return <option key={pais.idpais} value={pais.pais}>{pais.pais}</option>
                                                        })
                                                    }

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.pais && props.errors.pais ? (props.errors.pais) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {
                                        (paisselect === 'MÉXICO') ?
                                            <>
                                                <Grid container spacing={2}>
                                                    {/* <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Codigo Postal"
                                                            name="codigo"
                                                            id="codigo"
                                                            variant="outlined"
                                                            fullWidth
                                                            required

                                                            //defaultValue={escolaridadAnterior[0].estado}
                                                            // value={props.values.codigo}
                                                            onChange={e => {
                                                                handleOnChangeCodigo(e);
                                                                props.setFieldValue("codigo", e.target.value);

                                                            }}
                                                            error={props.touched.codigo && props.errors.codigo ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.codigo && props.errors.codigo ? (
                                                                props.errors.codigo
                                                            ) : null}
                                                        >
                                                        </TextField>
                                                    </Grid> */}
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required

                                                            className={classes.formControl}
                                                            error={props.touched.estado && props.errors.estado ? (true) : false}
                                                        >
                                                            <InputLabel >Estado</InputLabel>
                                                            <Select
                                                                native
                                                                // value={props.values.estado}
                                                                onChange={e => {
                                                                    handleOnChangeEstado(e);
                                                                    props.setFieldValue("estado", e.target.value);

                                                                }}
                                                                label="estado"
                                                                inputProps={{
                                                                    name: 'estado',
                                                                    id: 'estado',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    estados.map((estado) => {
                                                                        return <option key={estado.estado} value={estado.estado}>{estado.estado}</option>
                                                                    })
                                                                }

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.estado && props.errors.estado ? (props.errors.institucion) : null}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.ciudad && props.errors.ciudad ? (true) : false}
                                                        >
                                                            <InputLabel >Ciudad</InputLabel>
                                                            <Select
                                                                native
                                                                // value={props.values.ciudad}
                                                                onChange={e => {
                                                                    handleOnChangeCiudad(e);
                                                                    props.setFieldValue("ciudad", e.target.value);

                                                                }}
                                                                label="ciudad"
                                                                inputProps={{
                                                                    name: 'ciudad',
                                                                    id: 'ciudad',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    ciudades.map((ciudad) => {
                                                                        return <option key={ciudad.ciudad} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                                    })
                                                                }

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.ciudad && props.errors.ciudad ? (props.errors.ciudad) : null}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    {/* <Grid item xs={12} >
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            className={classes.formControl}
                                                            error={props.touched.colonia && props.errors.colonia ? (true) : false}
                                                        >
                                                            <InputLabel >Colinia</InputLabel>
                                                            <Select
                                                                native
                                                                // value={props.values.colonia}
                                                                onChange={e => {
                                                                    handleOnChangeColonia(e);
                                                                    props.setFieldValue("colonia", e.target.value);

                                                                }}
                                                                label="colonia"
                                                                inputProps={{
                                                                    name: 'colonia',
                                                                    id: 'colonia',
                                                                }}
                                                            >
                                                                <option aria-label="None" value="" />
                                                                {
                                                                    colonias.map((colonia) => {
                                                                        return <option key={colonia.colonia} value={colonia.colonia}>{colonia.colonia}</option>
                                                                    })
                                                                }
                                                                <option value="otra">Otra colonia...</option>

                                                            </Select>
                                                            <FormHelperText>
                                                                {props.touched.colonia && props.errors.colonia ? (props.errors.colonia) : null}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid> */}

                                                    {/* <Grid item xs={12}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Otra colonia"
                                                            id="otra"
                                                            name="otra"
                                                            // value={formik.values.otroDom}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.otra && props.errors.otra ? (
                                                                true
                                                            ) : false}
                                                            helperText={props.touched.otra && props.errors.otra ? (
                                                                props.errors.otra
                                                            ) : null}

                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </>
                                            :
                                            null}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="correo"
                                                label="Correo electronico"
                                                name="correo"
                                                // value={formik.values.correo}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.correo && props.errors.correo ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.correo && props.errors.correo ? (
                                                    props.errors.correo
                                                ) : null}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="correo2"
                                                label="Segundo Correo electronico"
                                                name="correo2"
                                                // value={formik.values.correo2}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.correo2 && props.errors.correo2 ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.correo2 && props.errors.correo2 ? (
                                                    props.errors.correo2
                                                ) : null}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="telefono"
                                                label="Teléfono"
                                                name="telefono"
                                                // value={formik.values.telefono}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.telefono && props.errors.telefono ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.telefono && props.errors.telefono ? (
                                                    props.errors.telefono
                                                ) : null}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="celular"
                                                label="Celular"
                                                name="celular"
                                                // value={formik.values.celular}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.celular && props.errors.celular ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.celular && props.errors.celular ? (
                                                    props.errors.celular
                                                ) : null}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                            >
                                                <InputLabel>Grupo étnico</InputLabel>
                                                <Select
                                                    native
                                                    // value={formik.values.etnico}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Grupo étnico"
                                                    inputProps={{
                                                        name: 'etnico',
                                                        id: 'etnico',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        etnia.map((etnia) => {
                                                            return <option key={etnia.idetnia} value={etnia.idetnia}>{etnia.etnia}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.etnico && props.errors.etnico ? (props.errors.etnico) : null}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="cvu"
                                                label="CVU"
                                                name="cvu"
                                                // value={formik.values.cvu}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.cvu && props.errors.cvu ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.cvu && props.errors.cvu ? (
                                                    props.errors.cvu
                                                ) : null}

                                            />
                                        </Grid>
                                    </Grid>
                                    {(paisselect === 'MÉXICO') ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Tooltip title="RFC: Esta compuesto pro cuatro letras, seis números y tres digitos para homoclave (AAAA000000) todo en mayusculas" aria-label="add">
                                                    <HelpOutlineIcon className={classes.avatar} />
                                                </Tooltip>
                                                <br />
                                                <TextField
                                                    name="rfc"
                                                    type="text"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    id="rfc"
                                                    label="RFC"
                                                    value={props.values.rfc}
                                                    onChange={(e) => {
                                                        let rfc = e.target.value;
                                                        props.setFieldValue("rfc", rfc.toUpperCase())
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.rfc && props.errors.rfc ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.rfc && props.errors.rfc ? (
                                                        props.errors.rfc
                                                    ) : null}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Tooltip title="CURP: Esta compuesto por (AAAA000000HMDRES00) todo en mayusculas" aria-label="add">
                                                    <HelpOutlineIcon className={classes.avatar} />
                                                </Tooltip>
                                                <br />
                                                <TextField
                                                    name="curp"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    id="curp"
                                                    label="CURP"
                                                    value={props.values.curp}
                                                    onChange={(e) => {
                                                        let curp = e.target.value;
                                                        props.setFieldValue("curp", curp.toUpperCase())
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.curp && props.errors.curp ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.curp && props.errors.curp ? (
                                                        props.errors.curp
                                                    ) : null}
                                                    type='text'

                                                />
                                            </Grid>
                                        </Grid>
                                        : null}
                                    <br />
                                    <br />
                                    < Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Paper >
            <Pie />
        </div >
    );
}

export default AgregarProfesor;