import React, { useState, useEffect, useContext } from 'react';
import { Hidden, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Global } from "../../../constants/global"
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";
import MaterialTable from 'material-table'
import axios from "axios";
import Swal from 'sweetalert'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function RegistroLinea(props) {

    const classes = useStyles();

    const [columns, setColumns] = useState([
        {
            title: "Linea", field: "nombre", validate: rowData =>
                rowData.nombre === '' ? { isValid: false, helperText: 'El nombre no puede estar vacio' }
                    :
                    rowData.nombre !== undefined && rowData.nombre !== "" && rowData.nombre !== null ?
                        rowData.nombre.trim().length !== rowData.nombre.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                        : true
        },
        {
            title: "Descripción", field: "descripcion",
            validate: rowData =>
                rowData.descripcion !== undefined && rowData.descripcion !== "" && rowData.descripcion !== null ?
                    rowData.descripcion.trim().length !== rowData.descripcion.length ? { isValid: false, helperText: 'El campo no debe tener espacios innecesarios' } : true
                    : true
                
        }
    ])

    const [data, setData] = useState([])
    const { idprograma } = props.programa;
    const [open, setOpen] = useState(false)
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')


    useEffect(() => {

        const datos = async () => {

            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            return null;
                        }
                    });
                });
            }


            await axios.get(Global + "linea/estatus/programa/" + idprograma, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setData(response.data)
            }).catch(error => console.log(error))

        }

        datos()

    }, [])

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

    }, [open]);

    return (
        <div className={classes.root}>
            {
                rol === "administrador" ||
                    rol === "coordinador_general" ||
                    rol === "coordinador_posgrado" ?
                    <MaterialTable
                        title={
                            <Hidden smDown>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo} >
                                            Lineas de investigación
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>}
                        options={{
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Buscar"
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                addTooltip: "Agregar",
                                editTooltip: "Editar",
                                deleteTooltip: "Dar de baja",
                                editRow: {
                                    deleteText: "¿Esta seguro que desea dar de baja?",
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Guardar"
                                },
                                emptyDataSourceMessage: 'No hay información por mostrar',

                            }
                        }}
                        columns={columns}
                        data={data}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                        if (newData.nombre !== undefined) {
                                            await axios.get(Global + "linea/nombre/" + newData.nombre,
                                                {
                                                    headers: {
                                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                        'Content-Type': 'application/json',
                                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                    }
                                                }).then(response => {
                                                    if (response.data.length < 1) {

                                                        axios.post(Global + "linea/programa/" + idprograma,
                                                            {
                                                                descripcion: newData.descripcion,
                                                                estatus: "Alta",
                                                                nombre: newData.nombre,

                                                            }, {
                                                            headers: {
                                                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                                'Content-Type': 'application/json',
                                                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                            }
                                                        }).then(response => {
                                                            Swal({
                                                                title: 'Programa registrado',
                                                                text: ' ',
                                                                icon: 'success',
                                                                button: false,
                                                                timer: 1000
                                                            })
                                                            setData([...data, response.data]);
                                                        }).catch(error => console.log(error))

                                                    } else {

                                                        Swal({
                                                            title: 'Esta linea de investigacion ya existe',
                                                            text: ' ',
                                                            icon: 'error',
                                                            button: false,
                                                            timer: 1000
                                                        })

                                                    }
                                                }).catch(error => console.log(error))


                                        } else {
                                            Swal({
                                                title: 'El nombre no puede estar vacío',
                                                text: ' ',
                                                icon: 'error',
                                                button: false,
                                                timer: 2000
                                            })
                                        }
                                        resolve();
                                    }, 1000)
                                }),
                            onRowUpdate: (newData, oldData, rowData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {

                                        // console.log(rowData)
                                        if (newData.nombre !== undefined) {
                                            await axios.get(Global + "linea/nombre/" + newData.nombre,
                                                {
                                                    headers: {
                                                        'Access-Control-Allow-Origin': "http://localhost:3000",
                                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                        'Content-Type': 'application/json',
                                                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                    }
                                                }).then(response => {
                                                    if (response.data.length < 1) {
                                                        axios.put(Global + "linea/" + oldData.idlinea,
                                                            {
                                                                descripcion: newData.descripcion,
                                                                estatus: "Alta",
                                                                nombre: newData.nombre,
                                                                programa: {
                                                                    idprograma: idprograma,
                                                                }

                                                            }, {
                                                            headers: {
                                                                'Access-Control-Allow-Origin': "http://localhost:3000",
                                                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                                'Content-Type': 'application/json',
                                                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                            }
                                                        }).then(response => {
                                                            const dataUpdate = [...data];
                                                            const index = oldData.tableData.id;
                                                            dataUpdate[index] = newData;
                                                            setData([...dataUpdate]);
                                                            Swal({
                                                                title: 'Linea de investigación actualizada',
                                                                text: ' ',
                                                                icon: 'success',
                                                                button: false,
                                                                timer: 1000
                                                            })
                                                        }).catch(error => console.log(error))

                                                    } else {

                                                        Swal({
                                                            title: 'Esta linea de investigacion ya existe',
                                                            text: ' ',
                                                            icon: 'error',
                                                            button: false,
                                                            timer: 1000
                                                        })

                                                    }
                                                }).catch(error => console.log(error))


                                        } else {
                                            Swal({
                                                title: 'El nombre no puede estar vacío',
                                                text: ' ',
                                                icon: 'error',
                                                button: false,
                                                timer: 2000
                                            })
                                        }


                                        resolve();
                                    }, 1000)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                        oldData.estatus = "baja"
                                        await axios.put(Global + "linea/" + oldData.idlinea, oldData

                                            , {
                                                headers: {
                                                    'Access-Control-Allow-Origin': "http://localhost:3000",
                                                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                                                }
                                            }).catch(error => console.log(error))


                                        const dataDelete = [...data];
                                        const index = oldData.tableData.id;
                                        dataDelete.splice(index, 1);
                                        setData([...dataDelete]);
                                        resolve();
                                    }, 1000);
                                })
                        }}
                    />
                    :
                    <MaterialTable
                        title={
                            <Hidden smDown>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.subtitulo} >
                                            Lineas de investigación
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>}
                        options={{
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                            headerStyle: {
                                backgroundColor: '#66abe0b8',
                                color: '#FFF'
                            }
                        }}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Buscar"
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            pagination: {
                                labelDisplayedRows: '',
                                labelRowsSelect: '',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Siguiente página',
                                lastTooltip: 'Última página',
                            },
                            body: {
                                addTooltip: "Agregar",
                                editTooltip: "Editar",
                                deleteTooltip: "Dar de baja",
                                editRow: {
                                    deleteText: "¿Esta seguro que desea dar de baja?",
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Guardar"
                                },
                                emptyDataSourceMessage: 'No hay información por mostrar',

                            }
                        }}
                        columns={columns}
                        data={data}
                    />
            }
        </div>

    )
}

export default RegistroLinea