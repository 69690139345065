import React, { useEffect } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
//import { Divider } from '@material-ui/core';
import logo from '../../img/logo.png'
import logoposgrado from '../../img/logoposgrado.jpg';
import { fba } from '../../firebaseConfig'
import theme from '../../styles/globalStyles'
import axios from 'axios';
import { Global } from '../../constants/global';


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    logo: {
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
        marginLeft: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        color: '#fffc'
    },
    divider: {
        color: 'white',
        backgroundColor: 'rgb(255 255 255 / 80%)',
        position: 'fixed'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));



export default function PrimarySearchAppBar({ history }) {
    useEffect(async () => {
        const refrescar = async () => {
            if (window.localStorage.getItem('refreshToken') !== null) {
                await axios.get(Global + "direccion/prueba", {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    // console.log(response)
                }).catch(error => {
                    // console.log(error)
                    // console.log('esho')
                    axios.post("https://securetoken.googleapis.com/v1/token?key=AIzaSyDbEjkKzEgL09oCVFuhcD_T6C5MvrIksn4",
                        {
                            grant_type: 'refresh_token',
                            refresh_token: window.localStorage.getItem("refreshToken")
                        })
                        .then((response) => {
                            window.localStorage.setItem('refreshToken', response.data.refresh_token);
                            window.localStorage.setItem('token', response.data.access_token);
                        })
                })
            }
        }
        await refrescar();
    }, [])
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleCerrar = () => {
        fba.auth().signOut()
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refreshToken')
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
            <MenuItem onClick={handleCerrar}>Cerrar sesión</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Perfil</p>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <ThemeProvider theme={theme}>
                <AppBar position="fixed" color='primary'>
                    <Toolbar>
                        {/* <MenuBurger className={classes.menuButton} /> */}
                        {/* <MenuIcon /> */}
                        <img src={logo} alt="" className={classes.logo} style={{ height: '53px', width: '200px' }} />
                        <div className={classes.grow} />
                        <div>
                            {/* <Typography className={classes.title} variant="h6" >
                                POSGRADO registro
                            </Typography>
                            <Divider className={classes.divider} />
                            <Typography className={classes.title} variant="h6" >
                                CHAPINGO
                            </Typography> */}
                            <img src={logoposgrado} alt="" className={classes.logoposgrado} style={{ height: '53px', width: '200px' }} />
                            <div className={classes.grow} />
                        </div>
                       {/*  <div className={classes.grow} />
                        <div className={classes.grow} /> */}
                        <div className={classes.sectionDesktop}>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle fontSize="large" />
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                <div className={classes.offset}></div>
            </ThemeProvider>
        </>
    );
}
