import React, { useEffect, useState } from 'react'
import { Avatar, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import Swal from 'sweetalert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FORMATOFECHAMAXHOY } from "../../generales/Fecha";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const RegistroEscolaridad = (props) => {
    // console.log(props)
    const [Instituciones, setInstituciones] = useState([])
    const [Paises, setPaises] = useState([])
    const [persona, setPersona] = useState(props.data)
    const [escolaridadAnterior, setEscolaridadAnterior] = useState([])
    const [uploadTitulo, setUploadTitulo] = useState(0)
    const [uploadLista, setUploadLista] = useState(0)
    const [uploadDiploma, setUploadDiploma] = useState(0)
    const [uploadCeduladoc, setUploadCeduladoc] = useState(0)
    const [uploadToefel, setUploadToefel] = useState(0)
    const [Titulomensaje, setTitulomensaje] = useState(null)
    const [Titulomensaje2, setTitulomensaje2] = useState(null)
    const [Listamensaje, setListamensaje] = useState(null)
    const [Listamensaje2, setListamensaje2] = useState(null)
    const [Diplomamensaje, setDiplomamensaje] = useState(null)
    const [Diplomamensaje2, setDiplomamensaje2] = useState(null)
    const [ceduladocmensaje, setCeduladocmensaje] = useState(null)
    const [ceduladocmensaje2, setCeduladocmensaje2] = useState(null)
    const [toefelmensaje, setToefelmensaje] = useState(null)
    const [toefelmensaje2, setToefelmensaje2] = useState(null)
    const [fileTitulo, setFileTitulo] = useState(0)
    const [fileLista, setFileLista] = useState(0)
    const [fileDiploma, setFileDiploma] = useState(0)
    const [fileCeduladoc, setFileCeduladoc] = useState(0)
    const [fileToefel, setFileToefel] = useState(0)
    const classes = useStyles();
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);
    const [recarga, setRecarga] = useState(false)



    useEffect(async () => {
        const carga = async () => {
            setEscolaridadAnterior({
                grado: '',
                idescolaridad: '',
                institucion: '',
                ins: '',
                dip: '',
                esp: '',
                estado: '',
                pais: '',
                fechain: '',
                fechafin: '',
                finan: '',
                promedio: '',
                cedula: '',
                lm: '',
                ced: '',
                titulo: '',
                titl: '',
                prt: '',
                toefel: '',
                titulacion: ''
            })

            axios.get(Global + "institucion", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setInstituciones(response.data)
            }).catch(error => console.log(error))

            axios.get(Global + "pais", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaises(response.data)
            }).catch(error => console.log(error))

        }
        await carga()
        handleClose()
    }, [recarga])

    // Schema de validacion
    const schemaValidacion = Yup.object({
        grado: Yup.string().required('Es obligatorio ingresar el grado de estudio'),
        titulo: Yup.string().required('Es obligatorio ingresar el nombre del título'),
        institucion: Yup.string().required('Es obligatorio seleccionar una institución'),
        estado: Yup.string().required('Es obligatorio ingresar el estado'),
        pais: Yup.string().required('Es obligatorio seleccionar el pais'),
        fechain: Yup.string().required('Es obligatorio seleccionar el año'),
        fechafin: Yup.string().required('Es obligatorio selecionar el año'),
        /* promedio: Yup.number().positive('El promedio debe ser un valor numerico postitivo')
            .required('Es obligatorio ingresar el promedio').max(10, 'El promedio debe ser maximo de 100')
            .min(80.0, 'El promedio minimo es de 80.0'), */
        cedula: Yup.string().required('Es obligatorio ingresar la cedula'),
        titl: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileTitulo === 0 || SUPPORTED_PDF.includes(fileTitulo.type))),
        lm: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileLista === 0 || SUPPORTED_PDF.includes(fileLista.type))),
        ced: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileCeduladoc === 0 || SUPPORTED_PDF.includes(fileCeduladoc.type))),
        dip: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileDiploma === 0 || SUPPORTED_PDF.includes(fileDiploma.type))),
        toefel: Yup.mixed().test('fileType', "El formato del archivo es incompatible",
            () => (fileToefel === 0 || SUPPORTED_PDF.includes(fileToefel.type))),
        titulacion: ''

    })

    const funcionSubmit = async valores => {
        console.log('Registro Escolaridad',valores)
        var linkTitulo = escolaridadAnterior.titl
        var linkCeduladoc = escolaridadAnterior.ced
        var linkDiploma = escolaridadAnterior.dip
        var linkLista = escolaridadAnterior.lm
        var linkToefel = escolaridadAnterior.toefel

        if (fileTitulo) {
            if (escolaridadAnterior.titl === '') {
                linkTitulo = persona.correo + '/DocumentosEscolaridad/' + uuidv4() + fileTitulo.name
            }
            const storageRef = fba.storage().ref(linkTitulo)
            const task = storageRef.put(fileTitulo)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadTitulo(porcentage)
                setTitulomensaje(porcentage + '%')
            }, (error) => {
                setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }
        if (fileLista) {
            if (escolaridadAnterior.lm === '') {
                linkLista = persona.correo + '/DocumentosEscolaridad/' + uuidv4() + fileLista.name
            }
            const storageRef = fba.storage().ref(linkLista)
            const task = storageRef.put(fileLista)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadLista(porcentage)
                setListamensaje(porcentage + '%')
            }, (error) => {
                setListamensaje('Error en la carga del archivo ' + error.message)
            })
        }
        if (fileCeduladoc) {
            if (escolaridadAnterior.ced === '') {
                linkCeduladoc = persona.correo + '/DocumentosEscolaridad/' + uuidv4() + fileCeduladoc.name
            }
            const storageRef = fba.storage().ref(linkCeduladoc)
            const task = storageRef.put(fileCeduladoc)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadCeduladoc(porcentage)
                setCeduladocmensaje(porcentage + '%')
            }, (error) => {
                setCeduladocmensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }
        if (fileDiploma) {
            if (escolaridadAnterior.dip === '') {
                linkDiploma = persona.correo + '/DocumentosEscolaridad/' + uuidv4() + fileDiploma.name
            }
            const storageRef = fba.storage().ref(linkDiploma)
            const task = storageRef.put(fileDiploma)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadDiploma(porcentage)
                setDiplomamensaje(porcentage + '%')
            }, (error) => {
                setDiplomamensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        if (fileToefel) {
            if (escolaridadAnterior.dip === '') {
                linkToefel = persona.correo + '/DocumentosEscolaridad/' + uuidv4() + fileToefel.name
            }
            const storageRef = fba.storage().ref(linkToefel)
            const task = storageRef.put(fileToefel)
            task.on('state_changed', (snapshot) => {
                let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadToefel(porcentage)
                setToefelmensaje(porcentage + '%')
            }, (error) => {
                setTitulomensaje('Error en la carga del archivo ' + error.message)
            }, () => {
                //setTitulomensaje('Archivo listo')
            })
        }

        let escolaridad = {
            cedula: valores.cedula,
            estado: valores.estado,
            fechafin: valores.fechafin,
            fechain: valores.fechain,
            grado: valores.grado,
            ins: valores.ins,
            ced: linkCeduladoc,
            pais: valores.pais,
            promedio: valores.promedio,
            lm: linkLista,
            titl: linkTitulo,
            dip: linkDiploma,
            esp: valores.esp,
            titulo: valores.titulo,
            toefel: linkToefel,
            titulacion: valores.titulacion
        };
        console.log('Registro Escolaridad',escolaridad)
        await axios.post(Global + "escolaridad/institucion/" + valores.institucion + "/persona/" + persona.idP, escolaridad, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            Swal({
                title: 'Datos registrados',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })
        }).catch(error => {
            console.log(error);
        })
        props.metodo()
    }

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "titl":
                    setFileTitulo(file)
                    setUploadTitulo(0)
                    setTitulomensaje('')
                    break;
                case "lm":
                    setFileLista(file)
                    setUploadLista(0)
                    setListamensaje('')
                    break;
                case "ced":
                    setFileCeduladoc(file)
                    setUploadCeduladoc(0)
                    setCeduladocmensaje('')
                    break;
                case "dip":
                    setFileDiploma(file)
                    setUploadDiploma(0)
                    setDiplomamensaje('')
                    break;
                case "toefel":
                    setFileToefel(file)
                    setUploadToefel(0)
                    setToefelmensaje('')
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case "titl":
                    setFileTitulo(false)
                    break;
                case "lm":
                    setFileLista(false)
                    break;
                case "ced":
                    setFileCeduladoc(false)
                    break;
                case "dip":
                    setFileDiploma(false)
                    break;
                case "toefel":
                    setToefelmensaje(false)
                    break;
                default:
                    break;
            }
        }
    }

    const initial = escolaridadAnterior

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <AccountBalanceIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtitulo}>
                            Escolaridad
                        </Typography>
                    </Grid>
                </Grid>
                <br />

                {open ?
                    <Backdrop className={classes.backdrop} open={open}>
                        <CircularProgress color="inherit" />
                    </Backdrop> :
                    <Formik
                        validationSchema={schemaValidacion}
                        enableReinitialize
                        initialValues={initial}
                        onSubmit={(valores) => {
                            funcionSubmit(valores)
                        }}
                    >
                        {props => {
                            return (
                                <form noValidate onSubmit={props.handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                required
                                                className={classes.formControl}
                                                error={props.touched.grado && props.errors.grado ? (true) : false}>
                                                <InputLabel id="grad">Grado</InputLabel>
                                                <Select
                                                    labelId="grad"
                                                    native
                                                    value={props.values.grado}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Grado"
                                                    inputProps={{
                                                        name: 'grado',
                                                        id: 'grado',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    <option value={'Licenciatura'}>Licenciatura</option>
                                                    <option value={'Maestria'}>Maestria</option>
                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.grado && props.errors.grado ? (props.errors.grado) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Titulo"
                                                name="titulo"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.titulo}
                                                onChange={(e) => {
                                                    let titulo = e.target.value;
                                                    props.setFieldValue("titulo", titulo.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.titulo && props.errors.titulo ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.titulo && props.errors.titulo ? (
                                                    props.errors.titulo
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Especialidad"
                                                name="esp"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.esp}
                                                onChange={(e) => {
                                                    let especialidad = e.target.value;
                                                    props.setFieldValue("esp", especialidad.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.esp && props.errors.esp ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.esp && props.errors.esp ? (
                                                    props.errors.esp
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                className={classes.formControl}
                                                error={props.touched.institucion && props.errors.institucion ? (true) : false}>
                                                <InputLabel id="inst">Institución</InputLabel>
                                                <Select
                                                    labelId='inst'
                                                    native
                                                    required
                                                    value={props.values.institucion}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="institucion"
                                                    inputProps={{
                                                        name: 'institucion',
                                                        id: 'institucion',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        Instituciones.map((instituto) => {
                                                            return <option key={instituto.idins} value={instituto.idins}>{instituto.nombre}</option>
                                                        })
                                                    }

                                                </Select>
                                                <FormHelperText>
                                                    {props.touched.institucion && props.errors.institucion ? (props.errors.institucion) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Otra Institución"
                                                name="ins"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.ins}
                                                onChange={(e) => {
                                                    let ins = e.target.value;
                                                    props.setFieldValue("ins", ins.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.ins && props.errors.ins ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.ins && props.errors.ins ? (
                                                    props.errors.ins
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={props.touched.pais && props.errors.pais ? (
                                                true
                                            ) : false}>
                                                <InputLabel >País</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.pais}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="pais"
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />{
                                                        Paises.map((pais) => {
                                                            return <option key={pais.idpais} value={pais.pais}>{pais.pais}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.pais && props.errors.pais ? (
                                                    props.errors.pais
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Estado o provincia"
                                                name="estado"
                                                id="estado"
                                                variant="outlined"

                                                fullWidth
                                                //defaultValue={escolaridadAnterior[0].estado}
                                                value={props.values.estado}
                                                onChange={(e) => {
                                                    let estado = e.target.value;
                                                    props.setFieldValue("estado", estado.toUpperCase())
                                                }}
                                                onBlur={props.handleBlur}
                                                error={props.touched.estado && props.errors.estado ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.estado && props.errors.estado ? (
                                                    props.errors.estado
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={props.touched.fechain && props.errors.fechain ? (
                                                true
                                            ) : false}>
                                                <InputLabel >Año de inicio</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.fechain}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Año de inicio"

                                                    inputProps={{
                                                        name: 'fechain',
                                                        id: 'fechain',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        years.map((year, index) => {
                                                            return <option key={`year${index}`} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.fechain && props.errors.fechain ? (
                                                    props.errors.fechain
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl variant="outlined" fullWidth className={classes.formControl} error={props.touched.fechafin && props.errors.fechafin ? (
                                                true
                                            ) : false}>
                                                <InputLabel >Año de terminación</InputLabel>
                                                <Select
                                                    native
                                                    value={props.values.fechafin}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    label="Año de terminacion"
                                                    inputProps={{
                                                        name: 'fechafin',
                                                        id: 'fechafin',
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        years.map((year, index) => {
                                                            return <option key={`year${index}`} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{props.touched.fechafin && props.errors.fechafin ? (
                                                    props.errors.fechafin
                                                ) : null}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Tooltip title="RANGO DE 80.0 a 100" aria-label="add">
                                                <TextField
                                                    label="Promedio"
                                                    name="promedio"
                                                    variant="outlined"

                                                    fullWidth
                                                    //defaultValue={escolaridadAnterior[0].promedio}
                                                    value={props.values.promedio}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.promedio && props.errors.promedio ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.promedio && props.errors.promedio ? (
                                                        props.errors.promedio
                                                    ) : null}
                                                    type="number"
                                                    max="100"
                                                >
                                                </TextField>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Cédula"
                                                name="cedula"
                                                variant="outlined"
                                                fullWidth
                                                value={props.values.cedula}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.cedula && props.errors.cedula ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.cedula && props.errors.cedula ? (
                                                    props.errors.cedula
                                                ) : null}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="titulacion"
                                                name="titulacion"
                                                label="Fecha de titulación"
                                                type="date"
                                                value={props.values.titulacion}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.titulacion && props.errors.titulacion ? (
                                                    true
                                                ) : false}
                                                helperText={props.touched.titulacion && props.errors.titulacion ? (
                                                    props.errors.titulacion
                                                ) : null}
                                                InputProps={FORMATOFECHAMAXHOY}
                                            /> 
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Tooltip title="Adjuntar su documentación en formato Pdf, con tamaño menor que 2MB por archivo.
                                                        ES IMPORTANTE QUE ADJUNTES TODOS LOS DOCUMENTOS QUE SON OBLIGATORIOS,
                                                        TODOS LOS DOCUMENTOS DEBERÁN SER LEGIBLES Y ESCANEADOS, DE LO CONTRARIO NO SERÁN ACEPTADOS EN EL POSGRADO SOLICITADO.
                                                        Si los documentos contienen imágenes, favor de redimensionar a 600px x 800px e incluirlas en el PDF
                                                        " aria-label="add">
                                        <HelpOutlineIcon className={classes.avatar} />
                                    </Tooltip>

                                    <Grid item xs={12} sm={6}>
                                        <Tooltip title="Titulo de licenciatura: o en su caso, una carta compromiso del director de tesis informando que presentara su examen profesional o de grado antes de la fecha de inscripción al posgrado" aria-label="tit" >
                                            <label htmlFor="titl">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="titl"
                                                    name="titl"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}

                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                    <CreateNewFolderIcon style={{ marginRight: 10 }} />
                                                    Título de licenciatura
                                                    {fileTitulo ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.titl && props.errors.titl) && (props.errors.titl)}
                                                </Typography>
                                            </label>
                                        </Tooltip>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {Titulomensaje2 !== null && Titulomensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={Titulomensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <Grid item xs={12} sm={6}>
                                        <label htmlFor="ced" fullWidth>
                                            <input
                                                style={{ display: 'none' }}
                                                id="ced"
                                                name="ced"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                <CreateNewFolderIcon style={{ marginRight: 10 }} />
                                                Comprobante de cedula profesional
                                                {fileCeduladoc ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.ced && props.errors.ced) && (props.errors.ced)}
                                            </Typography>
                                        </label>

                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {ceduladocmensaje2 !== null && ceduladocmensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={ceduladocmensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    <br />
                                    <Grid item xs={12} sm={6}>
                                        <label htmlFor="lm">
                                            <input
                                                style={{ display: 'none' }}
                                                id="lm"
                                                name="lm"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                <CreateNewFolderIcon style={{ marginRight: 10 }} />
                                                Lista oficial de materias
                                                {fileLista ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.lm && props.errors.lm) && (props.errors.lm)}
                                            </Typography>
                                        </label>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {Listamensaje2 !== null && Listamensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={Listamensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <br />
                                    <Grid item xs={12} sm={6}>
                                        <label htmlFor="dip">
                                            <input
                                                style={{ display: 'none' }}
                                                id="dip"
                                                name="dip"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                <CreateNewFolderIcon style={{ marginRight: 10 }} />
                                                Diploma
                                                {fileDiploma ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.dip && props.errors.dip) && (props.errors.dip)}
                                            </Typography>
                                        </label>

                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {Diplomamensaje2 !== null && Diplomamensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={Diplomamensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}

                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    <br />
                                    <Grid item xs={12} sm={6}>
                                        <label htmlFor="toefel">
                                            <input
                                                style={{ display: 'none' }}
                                                id="toefel"
                                                name="toefel"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span">
                                                <CreateNewFolderIcon style={{ marginRight: 10 }} />
                                                oficio de TOEFEL
                                                {fileToefel ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.toefel && props.errors.toefel) && (props.errors.toefel)}
                                            </Typography>
                                        </label>

                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {toefelmensaje2 !== null && toefelmensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={toefelmensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : null}

                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    <br />
                                    <br />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}></Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={classes.boton}>
                                                Guardar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}></Grid>
                                    </Grid>
                                </form>
                            )
                        }}
                    </Formik>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                props.metodo();
                            }}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                </Grid>
            </Paper >
        </div >
    );
}

export default RegistroEscolaridad;