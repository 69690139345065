import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        flexDirection: 'column',
        marginBottom: '10%',
        padding: '2%'
    },
    titulo: {
        marginTop: theme.spacing(5),
    }
}));


const Aviso = (props) => {
    const classes = useStyles();
    return (
        <Paper elevation={3} className={classes.paper} >
            <Typography variant="h4" align="center" className={classes.titulo}>
                {props.titulo}
                <hr />
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" >
                        {props.mensaje}
                        <hr />
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Aviso;