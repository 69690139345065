import React, { useEffect, useState, useContext } from 'react'
import { Avatar, Backdrop, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";
import { Auth } from "../../../context/AuthContext";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { menor,FORMATOFECHAMAXHOY } from '../../generales/Fecha'
import { FILE_SIZE, SUPPORTED_IMG, SUPPORTED_PDF } from "../../../constants/global"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        background: "#034AA7",
        backgroundColor: "#034AA7",
        color: '#fff',
        borderRadius: "4px 4px 4px 4px",
    },
    foto: {
        width: '180px',
        height: '180px'
    },
    boton: {
        fontFamily: { "Poppins": "SansSerif" },
        fontSize: "15px",
        fontWeight: "500",
        backgroundColor: "#034AA7",
        borderRadius: "4px 4px 4px 4px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));

const Personales = (props) => {

    const { idalumno } = props.data;
    const classes = useStyles();
    const [recargar, setRecargar] = useState(false)
    const [paises, setPaises] = useState([]);
    const [paisselect, setPaisselect] = useState('')
    const [estadoselect, setEstadoselect] = useState('estado')
    const [ciudadselect, setCiudadselect] = useState('ciudad')
    const [etnias, setEtnias] = useState([]);
    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [personalesAnterior, setPersonalesAnterior] = useState('')

    /** Para los campos de archivos **/
    const [uploadActa, setUploadActa] = useState(0);
    const [fileActa, setFileActa] = useState(0);
    const [actaMensaje, setActaMensaje] = useState('')
    const [actaMensaje2, setActaMensaje2] = useState('')

    const [uploadCurp, setUploadCurp] = useState(0);
    const [fileCurp, setFileCurp] = useState(0);
    const [curpMensaje, setCurpMensaje] = useState('')
    const [curpMensaje2, setCurpMensaje2] = useState('')

    const [uploadFoto, setUploadFoto] = useState(0);
    const [fileFoto, setFileFoto] = useState(0);
    const [fotoMensaje, setFotoMensaje] = useState('')
    const [fotoMensaje2, setFotoMensaje2] = useState('')
    /** Fin de campo archivos **/

    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [acceso, setAcceso] = useState(true)


    useEffect(() => {

        // console.log('hecho')
        let DatosAnteriores = {
            estatus: '',
            abreviatura: '',
            acta_nac: '',
            apellidoM: '',
            apellidoP: '',
            celular: '',
            correo: '',
            curp: '',
            doc_curp: '',
            cv: '',
            cvu: '',
            email: '',
            estado_civil: '',
            fecha_na: '',
            fotografia: '',
            genero: '',
            idP: '',
            nombre: '',
            orcid: '',
            rfc: '',
            telefono: '',
            idlugar: '',
            ciudad: '',
            estado: '',
            pais: '',
            etnia: '',
            idetnia: '',
        }

        const cargarDatos = async () => {
            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        // console.log(doc.data())
                        if (usuario.email === doc.data().correo) {
                            doc.data().rol === "administrador" ||
                                doc.data().rol === "coordinador_general" ||
                                doc.data().rol === "coordinador_posgrado" ? setAcceso(false) : setAcceso(true)
                        } else {
                            // console.log("No se encuentra");
                            return null;
                        }
                    });
                });
            }
            await axios.get(Global + "alumno/" + idalumno, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response)
                DatosAnteriores.idP = response.data.idalumno
                DatosAnteriores.estatus = response.data.estatus
                DatosAnteriores.abreviatura = response.data.abreviatura
                DatosAnteriores.acta_nac = response.data.persona.acta_nac
                DatosAnteriores.apellidoM = response.data.persona.apellidoM
                DatosAnteriores.apellidoP = response.data.persona.apellidoP
                DatosAnteriores.celular = response.data.persona.celular
                DatosAnteriores.correo = response.data.persona.correo
                DatosAnteriores.curp = response.data.persona.curp
                DatosAnteriores.doc_curp = response.data.persona.doc_curp
                DatosAnteriores.acta_nac = response.data.persona.acta_nac
                DatosAnteriores.cv = response.data.persona.cv
                DatosAnteriores.cvu = response.data.persona.cvu
                DatosAnteriores.doc_curp = response.data.persona.doc_curp
                DatosAnteriores.email = response.data.persona.email
                DatosAnteriores.estado_civil = response.data.persona.estado_civil
                DatosAnteriores.fecha_na = response.data.persona.fecha_na
                DatosAnteriores.fotografia = response.data.persona.fotografia
                DatosAnteriores.genero = response.data.persona.genero
                DatosAnteriores.idP = response.data.persona.idP
                DatosAnteriores.nombre = response.data.persona.nombre
                DatosAnteriores.orcid = response.data.persona.orcid
                DatosAnteriores.rfc = response.data.persona.rfc
                DatosAnteriores.telefono = response.data.persona.telefono

            }).catch(error => console.log(error));

            if (DatosAnteriores.doc_curp !== null && DatosAnteriores.doc_curp !== '') {
                let storage = fba.storage().ref();
                let httpsReference = storage.child('' + DatosAnteriores.doc_curp)
                setCurpMensaje2(await httpsReference.getDownloadURL());
            }
            if (DatosAnteriores.fotografia !== null && DatosAnteriores.fotografia !== '') {
                let storage2 = fba.storage().ref();
                let httpsReference2 = storage2.child('' + DatosAnteriores.fotografia)
                setFotoMensaje2(await httpsReference2.getDownloadURL());
            }
            if (DatosAnteriores.acta_nac !== null && DatosAnteriores.acta_nac !== '') {
                let storage3 = fba.storage().ref();
                let httpsReference3 = storage3.child('' + DatosAnteriores.acta_nac)
                setActaMensaje2(await httpsReference3.getDownloadURL());
            }

            //etnia
            await axios.get(Global + "personaed/personas/" + DatosAnteriores.idP, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data[0].etnia.etnia)
                if (response.data.length > 0) {
                    DatosAnteriores.idetnia = response.data[0].etnia.idetnia
                    DatosAnteriores.etnia = response.data[0].etnia.etnia
                }
            }).catch(error => console.log(error));

            //optener el lugar de nacimiento
            await axios.get(Global + "lugarna/personas/" + DatosAnteriores.idP, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // alert(response.data[0].ciudad)
                DatosAnteriores.ciudad = response.data[0].ciudad
                DatosAnteriores.estado = response.data[0].estado
                DatosAnteriores.pais = response.data[0].pais.pais
                DatosAnteriores.idlugar = response.data[0].idLN
                setPaisselect(response.data[0].pais.pais)
            }).catch(error => console.log(error));

            await axios.get(Global + "codigo/estado/" + DatosAnteriores.estado, {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setCiudades(response.data)
            }).catch(error => console.log(error))

            // PAIS
            await axios.get(Global + "pais", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setPaises(response.data);
            }).catch(error => console.log(error));

            // ETNIA
            await axios.get(Global + "etnia", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setEtnias(response.data);
            }).catch(error => console.log(error));
            //Estados
            await axios.get(Global + "codigo/estados", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                setEstados(response.data);
            }).catch(error => console.log(error));

            setPersonalesAnterior(DatosAnteriores);
            handleClose()


        }

        cargarDatos();
    }, [recargar])

    useEffect(() => {
        axios.get(Global + "codigo/estado/" + estadoselect, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(response => {
            setCiudades(response.data)
        }).catch(error => console.log(error))
    }, [estadoselect])

    //Schema de validacion
    const schemaValidacion = Yup.object({
        apellidoP: Yup.string().required('Es obligatorio ingresar el apellido paterno'),
        apellidoM: Yup.string().required('Es obligatorio ingresar el aperllido materno'),
        nombre: Yup.string().required('Es obligatorio ingresar el nombre'),
        fecha_na: Yup.string().required('Es obligatorio seleccionar la fecha de nacimiento')
            .test('match',
                'La fecha debe ser menor a la fecha actual',
                function (fecha) {
                    return menor(fecha)
                }
            ),
        genero: Yup.string().required('Es obligatorio seleccionar el genero'),
        estado_civil: Yup.string().required('Es obligatorio seleccionar el estado civil'),
        pais: Yup.string().required('Es obligatorio seleccionar el pais de nacimiento'),
        estado: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required('Es obligatorio seleccionar el estado')
            } else {
                return Yup.string()
            }
        }),
        ciudad: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required('Es obligatorio seleccionar la ciudad')
            } else {
                return Yup.string()
            }
        }),
        correo: Yup.string().required('El campo correo es obligatorio').email('Ingrese un email valido'),
        email: Yup.string().email('Ingrese un email valido'),
        telefono: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        celular: Yup.string().matches(/^[1-9][0-9]*$/, "Solo debe ingresar números."),
        rfc: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required("El campo RFC es obligatorio si es de México").matches(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/, 'El campo no cumple con el formato de RFC.')
            } else {
                return Yup.string()
            }
        }),
        curp: Yup.string().when('pais', (pais) => {
            if (pais === "MÉXICO") {
                return Yup.string().required("El campo CURP es obligatorio si es de México").matches(/^[A-Z]{4}[0-9]{6}[A-Z]{6}[A-Z0-9]{2}$/, 'El campo no cumple con el format de CURP.')
            } else {
                return Yup.string()
            }
        }),
        fotografia: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileFoto === 0 || SUPPORTED_IMG.includes(fileFoto.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileFoto === 0 || fileFoto.size <= FILE_SIZE)),
        acta_nac: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileActa === 0 || SUPPORTED_PDF.includes(fileActa.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileActa === 0 || fileActa.size <= FILE_SIZE)),
        doc_curp: Yup.mixed()
            .test('fileType', "El formato del archivo es incompatible",
                () => (fileCurp === 0 || SUPPORTED_PDF.includes(fileCurp.type)))
            .test('fileSize', "El tamaño del archivo es mayor a 2Mb",
                () => (fileCurp === 0 || fileCurp.size <= FILE_SIZE))
    })

    const initial = personalesAnterior

    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOnChangePais = (event) => {
        setPaisselect(event.target.value)
    }

    const handleOnChangeEstado = (event) => {
        setEstadoselect(event.target.value)
    }

    const handleOnChangeCiudad = (event) => {
        setCiudadselect(event.target.value)
    }

    const handleOnChange = (event) => {

        if (event.target.files[0]) {
            const file = event.target.files[0]
            switch (event.target.id) {
                case "doc_curp":
                    setFileCurp(file)
                    setUploadCurp(0)
                    break;
                case "acta_nac":
                    setFileActa(file)
                    setUploadActa(0)
                    break;
                case "fotografia":
                    setFileFoto(file)
                    setUploadFoto(0)
                    break;
                default:
                    break;
            }

        } else {
            switch (event.target.id) {
                case "doc_curp":
                    setFileCurp(false)
                    break;
                case "acta_nac":
                    setFileActa(false)
                    break;
                case "fotografia":
                    setFileFoto(false)
                    break;
                default:
                    break;
            }
        }
    }


    const funcionSubmit = async (values) => {

        let persona = {
            acta_nac: values.acta_nac,
            apellidoM: values.apellidoM,
            apellidoP: values.apellidoP,
            celular: values.celular,
            correo: values.correo,
            curp: values.curp,
            doc_curp: values.doc_curp,
            cv: values.cv,
            cvu: values.cvu,
            email: values.email,
            estado_civil: values.estado_civil,
            fecha_na: values.fecha_na,
            fotografia: values.fotografia,
            genero: values.genero,
            idP: values.idP,
            nombre: values.nombre,
            orcid: values.orcid,
            rfc: values.rfc,
            telefono: values.telefono,
            idlugar: values.idlugar,
            ciudad: values.ciudad,
            estado: values.estado,
            pais: values.pais,
            etnia: values.etnia,
            idetnia: values.idetnia,
            estatus: values.estatus,

        }

        var linkFoto = persona.fotografia
        var linkCurp = persona.doc_curp
        var linkActa = persona.acta_nac

        const guardar = async () => {

            //Foto para storage
            if (fileFoto) {
                if (persona.fotografia === '' || persona.fotografia == null) {
                    linkFoto = persona.correo + '/DocumentosDatosGenerales/' + uuidv4() + fileFoto.name
                }
                const storageRef2 = fba.storage().ref(linkFoto)
                const task2 = storageRef2.put(fileFoto)
                task2.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadFoto(porcentage)
                    setFotoMensaje(porcentage + '%')
                }, (error) => {
                    console.log(error);
                    // setTitulomensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
                persona.fotografia = linkFoto
            }
            if (fileCurp) {
                if (persona.doc_curp === '' || persona.doc_curp == null) {
                    linkCurp = persona.correo + '/DocumentosDatosGenerales/' + uuidv4() + fileCurp.name
                }
                const storageRef = fba.storage().ref(linkCurp)
                const task = storageRef.put(fileCurp)
                task.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadCurp(porcentage)
                    setCurpMensaje(porcentage + '%')
                }, (error) => {
                    console.log(error);
                    // setTitulomensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
                persona.doc_curp = linkCurp
            }
            if (fileActa) {
                if (persona.acta_nac === '' || persona.acta_nac == null) {
                    linkActa = persona.correo + '/DocumentosDatosGenerales/' + uuidv4() + fileActa.name
                }
                const storageRef3 = fba.storage().ref(linkActa)
                const task3 = storageRef3.put(fileActa)
                task3.on('state_changed', (snapshot) => {
                    let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadActa(porcentage)
                    setActaMensaje(porcentage + '%')
                }, (error) => {
                    console.log(error);
                    // setTitulomensaje('Error en la carga del archivo ' + error.message)
                }, () => {
                    //setTitulomensaje('Archivo listo')
                })
                persona.acta_nac = linkActa
            }

            // Actualizar persona, registrar nuevos campos 
            await axios.put(Global + "persona/" + persona.idP, persona, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
            }).catch(error => console.log(error));

            //Lugar de nacimiento
            await axios.get(Global + "pais/pais/" + persona.pais, {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {

                const datos = {
                    ciudad: persona.ciudad,
                    estado: persona.estado,
                    pais: {
                        idpais: response.data[0].idpais
                    },
                }

                axios.put(Global + "lugarna/" + persona.idlugar, datos, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).then(response => {
                    // console.log(response.data)
                }).catch(error => console.log(error));
            }).catch(error => console.log(error));

            // Actualizar etnia


            // await axios.get(Global + "personaed/personas/" + persona.idetnia, {
            //     headers: {
            //         'Access-Control-Allow-Origin': "*",
            //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
            //         'Content-Type': 'application/json',
            //         Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            //     }
            // }).then(response => {

            // }).catch(error => console.log(error))

            if (personalesAnterior.idetnia !== "" && personalesAnterior.idetnia !== null) {
                let personaed = {
                    etnia: {
                        idetnia: persona.idetnia
                    }
                }
                axios.put(Global + "personaed/" + persona.idP, personaed, {
                    headers: {
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                    }
                }).catch(error => console.log(error));

            } else {

                if (values.idetnia != null && values.idetnia !== "") {
                    axios.post(Global + "personaed/persona/" + persona.idP + "/etnia/" + persona.etnia,
                        {
                            etnia: {
                                etnia: "string",
                            },
                        },
                        {
                            headers: {
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                            }
                        }).catch(error => console.log(error));
                }
            }

            Swal({
                title: 'Alumno actualizado',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 1000
            })

        }

        setOpen(true)
        await guardar()
        setRecargar(!recargar)
    }

    return (
        <Paper elevation={3} className={classes.paperContainer}>

            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Avatar className={classes.avatar}>
                        <LocalLibraryIcon />
                    </Avatar>
                </Grid>
                <Grid item>
                    <Typography className={classes.subtitulo}>
                        Datos personales
                    </Typography>
                </Grid>
            </Grid>
            <br />

            {open ?
                <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <CircularProgress color="inherit" />
                </Backdrop> :
                <Formik
                    enableReinitialize
                    validationSchema={schemaValidacion}
                    initialValues={initial}
                    onSubmit={(values) => {
                        funcionSubmit(values)
                    }}
                >
                    {props => {

                        return (
                            <form noValidate onSubmit={props.handleSubmit}>

                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={3}>
                                        <Avatar src={fotoMensaje2} className={classes.foto} />
                                        <br />
                                        <label htmlFor="fotografia">
                                            <input
                                                style={{ display: 'none' }}
                                                id="fotografia"
                                                name="fotografia"
                                                type="file"
                                                disabled={acceso}
                                                accept="image/jpeg, image/png, image/jpg"
                                                onChange={handleOnChange}
                                            />
                                            <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                <FolderOpenIcon style={{ marginRight: 10 }} />
                                                Fotografia
                                                {fileFoto ?
                                                    <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                    : null}
                                            </Button>
                                            <Typography variant="caption" display="block" gutterBottom color="error">
                                                {(props.touched.fotografia && props.errors.fotografia) && (props.errors.fotografia)}
                                            </Typography>
                                        </label>
                                    </Grid>
                                </Grid>
                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            label="Apellido Paterno"
                                            name="apellidoP"
                                            id="apellidoP"
                                            variant="outlined"
                                            required
                                            disabled={acceso}
                                            fullWidth
                                            value={props.values.apellidoP}
                                            onChange={(e) => {
                                                let paterno = e.target.value;
                                                props.setFieldValue("apellidoP", paterno.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={props.touched.apellidoP && props.errors.apellidoP ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.apellidoP && props.errors.apellidoP ? (
                                                props.errors.apellidoP
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            label="Apellido Materno"
                                            name="apellidoM"
                                            id="apellidoM"
                                            variant="outlined"
                                            required
                                            disabled={acceso}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            value={props.values.apellidoM}
                                            onChange={(e) => {
                                                let materno = e.target.value;
                                                props.setFieldValue("apellidoM", materno.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.apellidoM && props.errors.apellidoM ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.apellidoM && props.errors.apellidoM ? (
                                                props.errors.apellidoM
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            label="Nombre"
                                            name="nombre"
                                            id="nombre"
                                            variant="outlined"
                                            required
                                            disabled={acceso}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.values.nombre}
                                            onChange={(e) => {
                                                let name = e.target.value;
                                                props.setFieldValue("nombre", name.toUpperCase())
                                            }}
                                            onBlur={props.handleBlur}
                                            error={props.touched.nombre && props.errors.nombre ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.nombre && props.errors.nombre ? (
                                                props.errors.nombre
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Abreviatura de titulo"
                                            name="abreviatura"
                                            id="abreviatura"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            InputLabelProps={personalesAnterior.abreviatura !== '' ? {
                                                shrink: true,
                                            } : {}}
                                            defaultValue={personalesAnterior.abreviatura}
                                            value={props.values.abreviatura}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.abreviatura && props.errors.abreviatura ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.abreviatura && props.errors.abreviatura ? (
                                                props.errors.abreviatura
                                            ) : null}
                                        >
                                        </TextField>
                                    </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined" fullWidth required
                                            className={classes.formControl}

                                            disabled={acceso}
                                            error={props.touched.genero && props.errors.genero ? (true) : false}>
                                            <InputLabel>Género</InputLabel>
                                            <Select
                                                native
                                                // defaultValue={personalesAnterior.genero}
                                                value={props.values.genero}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}

                                                label="Género"
                                                inputProps={{
                                                    name: 'genero',
                                                    id: 'genero',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option key='Masculino' value={'Masculino'}>Masculino</option>
                                                <option key='Femenino' value={'Femenino'}>Femenino</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.genero && props.errors.genero ? (props.errors.genero) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            className={classes.formControl}
                                            error={props.touched.estado_civil && props.errors.estado_civil ? (true) : false}>
                                            <InputLabel>Estado civil</InputLabel>
                                            <Select
                                                native
                                                value={props.values.estado_civil}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                label="Estado civil"

                                                inputProps={{
                                                    name: 'estado_civil',
                                                    id: 'estado_civil',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={'soltero'}>Soltero</option>
                                                <option value={'casado'}>Casado</option>
                                                <option value={'divorciado'}>Divorciado</option>
                                                <option value={'separacion'}>Separación en proceso judicial</option>
                                                <option value={'viudo'}>Viudo</option>
                                                <option value={'concubinato'}>Concubinato</option>
                                            </Select>
                                            <FormHelperText>
                                                {props.touched.estado_civil && props.errors.estado_civil ? (props.errors.estado_civil) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={acceso}
                                            id="fecha_na"
                                            name="fecha_na"
                                            label="Fecha de nacimiento"
                                            type="date"
                                            value={props.values.fecha_na}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.fecha_na && props.errors.fecha_na ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.fecha_na && props.errors.fecha_na ? (
                                                props.errors.fecha_na
                                           ) : null}
                                            InputProps={FORMATOFECHAMAXHOY}
                                        /> 
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            error={props.touched.pais && props.errors.pais ? (true) : false}
                                            className={classes.formControl}
                                            required
                                        >
                                            <InputLabel >Pais de origen</InputLabel>
                                            <Select
                                                native
                                                value={props.values.pais}
                                                onChange={e => {
                                                    handleOnChangePais(e);
                                                    props.setFieldValue("pais", e.target.value);

                                                }}
                                                label="pais de origen"

                                                inputProps={{
                                                    name: 'pais',
                                                    id: 'pais',
                                                }}
                                            >
                                                <option aria-label="None" value="" />{
                                                    paises.map((pais) => {
                                                        return <option key={pais.idpais} value={pais.pais}>{pais.pais}</option>
                                                    })
                                                }

                                            </Select>
                                            <FormHelperText>
                                                {props.touched.pais && props.errors.pais ? (props.errors.pais) : null}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {
                                    (paisselect === 'MÉXICO') ?
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        disabled={acceso}
                                                        className={classes.formControl}
                                                        error={props.touched.estado && props.errors.estado ? (true) : false}
                                                    >
                                                        <InputLabel >Estado</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.estado}
                                                            onChange={e => {
                                                                handleOnChangeEstado(e);
                                                                props.setFieldValue("estado", e.target.value);

                                                            }}
                                                            label="estado"
                                                            inputProps={{
                                                                name: 'estado',
                                                                id: 'estado',
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            {
                                                                estados.map((estado) => {
                                                                    return <option key={estado.estado} value={estado.estado}>{estado.estado}</option>
                                                                })
                                                            }

                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.estado && props.errors.estado ? (props.errors.estado) : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        disabled={acceso}
                                                        className={classes.formControl}
                                                        error={props.touched.ciudad && props.errors.ciudad ? (true) : false}
                                                    >
                                                        <InputLabel>Ciudad</InputLabel>
                                                        <Select
                                                            native
                                                            value={props.values.ciudad}
                                                            onChange={e => {
                                                                handleOnChangeCiudad(e);
                                                                props.setFieldValue("ciudad", e.target.value);

                                                            }}
                                                            label="ciudad"

                                                            inputProps={{
                                                                name: 'ciudad',
                                                                id: 'ciudad',
                                                            }}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            {
                                                                ciudades.map((ciudad) => {
                                                                    return <option key={ciudad.ciudad} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                                })
                                                            }

                                                        </Select>
                                                        <FormHelperText>
                                                            {props.touched.ciudad && props.errors.ciudad ? (props.errors.ciudad) : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        null
                                }

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={acceso}
                                            label="Correo electronico"
                                            id="correo"
                                            name="correo"

                                            value={props.values.correo}
                                            error={props.touched.correo && props.errors.correo ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.correo && props.errors.correo ? (
                                                props.errors.correo
                                            ) : null}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            label="Segundo correo electronico"
                                            id="email"
                                            name="email"

                                            value={props.values.email}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.email && props.errors.email ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.email && props.errors.email ? (
                                                props.errors.email
                                            ) : null}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            id="telefono"
                                            label="Teléfono"
                                            name="telefono"
                                            value={props.values.telefono}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.telefono && props.errors.telefono ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.telefono && props.errors.telefono ? (
                                                props.errors.telefono
                                            ) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            id="celular"
                                            label="Celular"
                                            name="celular"

                                            value={props.values.celular}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.celular && props.errors.celular ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.celular && props.errors.celular ? (
                                                props.errors.celular
                                            ) : null}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            className={classes.formControl}
                                            error={props.touched.idetnia && props.errors.idetnia ? (true) : false}
                                        >
                                            <InputLabel>Etnia</InputLabel>
                                            <Select
                                                native
                                                value={props.values.idetnia}
                                                label="Etnia"
                                                onChange={props.handleChange}

                                                inputProps={{
                                                    name: 'idetnia',
                                                    id: 'idetnia',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {
                                                    etnias.map((etnia) => {
                                                        return <option key={etnia.idetnia} value={etnia.idetnia}>{etnia.etnia}</option>
                                                    })
                                                }

                                            </Select>
                                            <FormHelperText>
                                                {props.touched.idetnia && props.errors.idetnia ? (props.errors.idetnia) : null}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            id="cvu"
                                            label="CVU"
                                            name="cvu"

                                            value={props.values.cvu}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.cvu && props.errors.cvu ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.cvu && props.errors.cvu ? (
                                                props.errors.cvu
                                            ) : null}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <Tooltip title="Formato: 0000-0012-3456-7890
                                    según: https://support.orcid.org/knowledgebase/articles/116780-structure-of-the-orcid-identifier" aria-label="add">
                                            <HelpOutlineIcon className={classes.avatar} />
                                        </Tooltip>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={acceso}
                                            id="orcid"
                                            label="Identificador ORCID"
                                            name="orcid"

                                            value={props.values.orcid}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={props.touched.orcid && props.errors.orcid ? (
                                                true
                                            ) : false}
                                            helperText={props.touched.orcid && props.errors.orcid ? (
                                                props.errors.orcid
                                            ) : null}

                                        />
                                    </Grid>

                                </Grid>
                                {
                                    (paisselect === 'MÉXICO') ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Tooltip title="RFC: Esta compuesto pro cuatro letras y seis números (AAAA000000)" aria-label="add">
                                                    <HelpOutlineIcon className={classes.avatar} />
                                                </Tooltip>
                                                <br />
                                                <TextField
                                                    name="rfc"
                                                    type="text"
                                                    variant="outlined"
                                                    required
                                                    disabled={acceso}
                                                    fullWidth
                                                    id="rfc"
                                                    label="RFC"
                                                    value={props.values.rfc}
                                                    onChange={(e) => {
                                                        let rfc = e.target.value;
                                                        props.setFieldValue("rfc", rfc.toUpperCase())
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.rfc && props.errors.rfc ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.rfc && props.errors.rfc ? (
                                                        props.errors.rfc
                                                    ) : null}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Tooltip title="CURP: Esta compuesto por (AAAA000000HMDRES00)" aria-label="add">
                                                    <HelpOutlineIcon className={classes.avatar} />
                                                </Tooltip>
                                                <br />
                                                <TextField
                                                    name="curp"
                                                    variant="outlined"
                                                    required
                                                    disabled={acceso}
                                                    fullWidth
                                                    id="curp"
                                                    label="CURP"
                                                    value={props.values.curp}
                                                    onChange={(e) => {
                                                        let curp = e.target.value;
                                                        props.setFieldValue("curp", curp.toUpperCase())
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.curp && props.errors.curp ? (
                                                        true
                                                    ) : false}
                                                    helperText={props.touched.curp && props.errors.curp ? (
                                                        props.errors.curp
                                                    ) : null}
                                                    type='text'

                                                />
                                            </Grid>
                                        </Grid>
                                        : null
                                }

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="doc_curp">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="doc_curp"
                                                    name="doc_curp"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Curp
                                                    {fileCurp ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.doc_curp && props.errors.doc_curp) && (props.errors.doc_curp)}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {curpMensaje2 !== null && curpMensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={curpMensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : "Sin documento"}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid item xs={12} sm={3}>
                                            <label htmlFor="acta_nac">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="acta_nac"
                                                    name="acta_nac"
                                                    type="file"
                                                    disabled={acceso}
                                                    accept="application/pdf"
                                                    onChange={handleOnChange}
                                                />
                                                <Button className={classes.boton} color="primary" variant="contained" component="span" fullWidth>
                                                    <FolderOpenIcon style={{ marginRight: 10 }} />
                                                    Acta de nacimiento
                                                    {fileActa ?
                                                        <><CheckCircleIcon style={{ marginLeft: '10%' }} /></>
                                                        : null}
                                                </Button>
                                                <Typography variant="caption" display="block" gutterBottom color="error">
                                                    {(props.touched.acta_nac && props.errors.acta_nac) && (props.errors.acta_nac)}
                                                </Typography>
                                            </label>
                                        </Grid>
                                        <Grid item xs={1} sm={2}>
                                            <Typography variant='body2'>
                                                {actaMensaje2 !== null && actaMensaje2 !== '' ?
                                                    <a style={{ display: "table-cell" }} href={actaMensaje2} target="_blank" rel="noreferrer">
                                                        Ver archivo
                                                    </a> : "Sin documento"}
                                            </Typography>
                                        </Grid>

                                    </Grid>

                                </Grid>


                                <br />
                                <br />
                                <br />
                                {
                                    !acceso ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}></Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    className={classes.boton}>
                                                    Guardar
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={4}></Grid>
                                        </Grid>
                                        :
                                        null
                                }

                            </form>
                        )
                    }}

                </Formik>
            }
        </Paper >
    );
}

export default Personales;