import React, { useEffect, useState } from 'react'
import { Avatar, Grid, Paper, TextField, Typography } from '@material-ui/core'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import axios from 'axios';
import { Global } from '../../../constants/global';
import { fba } from "../../../firebaseConfig";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        // display:'flex',
        // flexDirection:'column',
        padding: theme.spacing(5),
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        marginLeft: "20px",
        marginRight: "20px",
    },
    avatar: {
        background: theme.palette.secondary.main,
    }
}));

function Experiencia({ persona }) {

    const [pub1, setPub1] = useState(null)
    const [pub2, setPub2] = useState(null)
    const [pub3, setPub3] = useState(null)
    const [cons1, setCons1] = useState(null)
    const [cons2, setCons2] = useState(null)
    const [cons3, setCons3] = useState(null)
    const [cv, setCV] = useState(null);

    const [experienciaAnterior, setexperienciaAnterior] = useState({
        cons1: '',
        cons2: '',
        cons3: '',
        empresa: '',
        fechaini: '',
        fechater: '',
        lugar: '',
        actividad: '',
        pub1: '',
        pub2: '',
        pub3: '',
        cv:'',
    })


    useEffect(() => {

        axios.get(Global + "experiencia/personas/" + persona, {
            headers: {
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + window.localStorage.getItem('token'),
            }
        }).then(async responses => {

            if (responses.data.length > 0) {
                setexperienciaAnterior(responses.data[0])
                if (responses.data[0].pub1 !== null && responses.data[0].pub1 !== '') {
                    let storage = fba.storage().ref();
                    let httpsReference = storage.child('' + responses.data[0].pub1)
                    setPub1(await httpsReference.getDownloadURL());
                }
                if (responses.data[0].pub2 !== null && responses.data[0].pub2 !== '') {
                    let storage2 = fba.storage().ref();
                    let httpsReference2 = storage2.child('' + responses.data[0].pub2)
                    setPub2(await httpsReference2.getDownloadURL());
                }
                if (responses.data[0].pub3 !== null && responses.data[0].pub3 !== '') {
                    let storage3 = fba.storage().ref();
                    let httpsReference3 = storage3.child('' + responses.data[0].pub3)
                    setPub3(await httpsReference3.getDownloadURL());
                }
                if (responses.data[0].cons1 !== null && responses.data[0].cons1 !== '') {
                    let storage4 = fba.storage().ref();
                    let httpsReference4 = storage4.child('' + responses.data[0].cons1)
                    setCons1(await httpsReference4.getDownloadURL());
                }
                if (responses.data[0].cons2 !== null && responses.data[0].cons2 !== '') {
                    let storage5 = fba.storage().ref();
                    let httpsReference5 = storage5.child('' + responses.data[0].cons2)
                    setCons2(await httpsReference5.getDownloadURL());
                }
                if (responses.data[0].cons3 !== null && responses.data[0].cons3 !== '') {
                    let storage6 = fba.storage().ref();
                    let httpsReference6 = storage6.child('' + responses.data[0].cons3)
                    setCons3(await httpsReference6.getDownloadURL());
                }
                if (responses.data[0].cv !== null && responses.data[0].cv !== '') {
                    let storage6 = fba.storage().ref();
                    let httpsReference6 = storage6.child('' + responses.data[0].cv);
                    setCV(await httpsReference6.getDownloadURL());
                }
            }

        }).catch(error => console.log(error))



    }, [])

    const initial = experienciaAnterior
    const classes = useStyles();

    return (
        <div>
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid
                    container
                    spacing={1}
                    alignItems="center">
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <BusinessCenterIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">
                            Experiencia profesional
                        </Typography>
                    </Grid>

                </Grid>


                <br />
                <Formik
                    enableReinitialize
                    initialValues={initial}
                >
                    {props => {
                        return (
                            <form noValidate onSubmit={props.handleSubmit}>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            label="Empresa"
                                            name="empresa"
                                            id="empresa"
                                            value={props.values.empresa}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Lugar"
                                            name="lugar"
                                            id="lugar"
                                            variant="outlined"
                                            disabled
                                            type="text"
                                            fullWidth
                                            value={props.values.lugar}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Actividad"
                                            name="actividad"
                                            id="actividad"
                                            disabled
                                            type="text"
                                            fullWidth
                                            value={props.values.actividad}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="fechaini"
                                            name="fechaini"
                                            label="Fecha de inicio"
                                            value={props.values.fechaini}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            fullWidth
                                            id="fechater"
                                            name="fechater"
                                            label="Fecha de termino"
                                            value={props.values.fechaini}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">
                                            Constancias de experiencia profesional
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">
                                            Publicaciones
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    {/* <Typography variant="h6">
                                        Constancias de experiencia profesional
                                        </Typography> */}
                                    <Grid item xs={12} sm={6}>
                                        Constacia 1
                                        <Grid item xs={10} sm={8}>
                                            {cons1 !== null && cons1 !== '' ?
                                                <a style={{ display: "table-cell" }} href={cons1} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}

                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        Publicacion 1
                                        <Grid item xs={10} sm={8}>
                                            {pub1 !== null && pub1 !== '' ?
                                                <a style={{ display: "table-cell" }} href={pub1} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        Constancia 2
                                        <Grid item xs={10} sm={8}>
                                            {cons2 !== null && cons2 !== '' ?
                                                <a style={{ display: "table-cell" }} href={cons2} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        Publicacion 2
                                        <Grid item xs={10} sm={8}>
                                            {pub2 !== null && pub2 !== '' ?
                                                <a style={{ display: "table-cell" }} href={pub2} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        Constancia 3
                                        <Grid item xs={10} sm={8}>
                                            {cons3 !== null && cons3 !== '' ?
                                                <a style={{ display: "table-cell" }} href={cons3} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        Publicacion 3
                                        <Grid item xs={10} sm={8}>
                                            {pub3 !== null && pub3 !== '' ?
                                                <a style={{ display: "table-cell" }} href={pub3} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Curriculum Vitae
                                        <Grid item xs={10} sm={8}>
                                            {cv !== null && cv !== '' ?
                                                <a style={{ display: "table-cell" }} href={cv} target="_blank" rel="noreferrer">
                                                    Ver archivo
                                            </a> : "Sin documento"}
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </form>
                        )
                    }}
                </Formik>
            </Paper >
        </div >
    );

}

export default Experiencia;
