import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import {  Hidden, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Global } from "../../../../constants/global"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function TablaEntidad(props) {

    const classes = useStyles();

    const [columns, setColumns] = useState([
        {
            title: "Nombre", field: "estado"
        },
    ])

    const [data, setData] = useState([])

    useEffect(() => {

        const datos = async () => {

            await axios.get(Global + "codigo/estados", {
                headers: {
                    'Access-Control-Allow-Origin': "http://localhost:3000",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                //console.log(response.data)
                setData(response.data)
            }).catch(error => console.log(error))

        }

        datos()

    }, [])



    return (
        <div className={classes.root}>
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo} >
                                    Entidades
                    </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>}
                options={{
                    exportButton:true,
                    exportFileName:"Entidades",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar",
                        searchPlaceholder: "Buscar"
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        addTooltip: "Agregar",
                        editTooltip: "Editar",
                        deleteTooltip: "Dar de baja",
                        editRow: {
                            deleteText: "¿Esta seguro que desea dar de baja?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar"
                        },
                        emptyDataSourceMessage: 'No hay información por mostrar',

                    }
                }}
                columns={columns}
                data={data}
            />
        </div>

    )
}

export default TablaEntidad