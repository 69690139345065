import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../img/logoregistro.jpg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Encabezado from '../generales/Encabezado';
import { Paper } from '@material-ui/core';
import { fba } from "../../firebaseConfig";
import Pie from '../generales/Footer';
import axios from 'axios';
import { Global } from '../../constants/global';
import Mensaje from './Mensaje';
import Swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
    paperdiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        paddingRight: '20px',
        paddingLeft: '20px',
    },
    paperIma: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-12),
        backgroundColor: '#fff',
        width: '190px',
        height: '190px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    logo: {
        height: '160px',
        width: '120px'
    }
}));


const Password = ({ history }) => {

    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);
    const [user, setUser] = useState(fba.auth().currentUser)
    const [mensaje, setMensaje] = useState(false)
    const [correo, setCorreo] = useState(null)
    // console.log(user)

    useEffect(() => {
        if (redirect) {
            history.push("/registro");
        }
    }, [history, redirect]);


    useEffect(() => {
        // console.log(fba.auth().isSignInWithEmailLink(window.location.href))
        if (fba.auth().isSignInWithEmailLink(window.location.href)) {
            var email = window.localStorage.getItem('emailForSignIn');
            setCorreo(email)
            // console.log(email)
            if (!email) {
                email = window.prompt('Ingrese su correo electrónico para confirmar su identidad');
                setCorreo(email)
                // setMensaje(true)
            }

            setUser(fba.auth().currentUser)
            // console.log(fba.auth().currentUser)
            if (!fba.auth().currentUser) {
                fba.auth().signInWithEmailLink(email, window.location.href)
                    .then(function (result) {
                        // window.localStorage.removeItem('emailForSignIn');
                        setMensaje(false)
                        // console.log(result)
                    })
                    .catch(function (error) {
                        // console.log(error)
                        setMensaje(true)
                    });
            }
        }
    }, [fba.auth().currentUser])
    // }, [])


    const formik = useFormik({
        initialValues: {
            paterno: '',
            materno: '',
            nombre: '',
            pass: '',
            passconfirm: ''
        },
        validationSchema: Yup.object({
            paterno: Yup.string().required('El apellido paterno es obligatorio'),
            materno: Yup.string().required('El apellido materno es obligatorio'),
            nombre: Yup.string().required('El nombre es obligatorio'),
            pass: Yup.string().required('La contraseña es obligatoria').min(6, 'Minimo 6 caracteres'),
            passconfirm: Yup.string().required('La confirmacion de contraseña es obligatoria').min(6, 'Minimo 6 caracteres'),
        }),
        onSubmit: async valores => {
            console.log(valores)
            let aspirante = {
                estatus: "Registro",
                activo: "0",
                persona: {
                    apellidoP: valores.paterno,
                    apellidoM: valores.materno,
                    nombre: valores.nombre,
                    correo: correo,
                },
            };
            if (valores.pass !== valores.passconfirm) {
                Swal({
                    title: 'Las contraseñas no coinciden',
                    text: ' ',
                    icon: 'error',
                    button: false,
                    timer: 1000
                })
            } else {
                user.updatePassword(valores.pass).then(function () {
                    //Registro de aspirantes en la BD
                    axios.post(Global + "aspirante/", aspirante, {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + fba.auth().currentUser._lat
                        }
                    }).then(response => {
                        // console.log(response);
                        // Update successful.
                        fba.auth().signInWithEmailAndPassword(user.email, valores.pass)
                            .then(result => {
                                // console.log(result)
                                window.localStorage.setItem('token', result.user._lat);
                                setRedirect(true);
                                window.localStorage.removeItem('emailForSignIn');
                                // window.localStorage.removeItem('paterno');
                                // window.localStorage.removeItem('materno');
                                // window.localStorage.removeItem('nombre');
                                Swal({
                                    title: 'La contraseña se registró con éxito',
                                    text: 'utilicé esta contraseña para acceder después',
                                    icon: 'success',
                                    button: false,
                                    timer: 3000
                                })
                            })
                            .catch(e => {
                                // console.log(e.message)
                            })
                    }).catch(error => {
                        console.log(error);
                    })
               /*  }).catch(error => {
                    console.log(error);
                }) */
                    // console.log("pase")

                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    });

    return (
        <div className={classes.root}>
            <Encabezado />
            {!mensaje ?
                <Container component="main" maxWidth="xs" style={{ padding: '10px' }}>
                    <div className={classes.paperdir} >
                        <div className={classes.paperIma}>
                            <Avatar className={classes.avatar}>
                                <img src={logo} alt="" className={classes.logo} />
                            </Avatar>
                        </div>

                        <Paper elevation={3} className={classes.paper} >
                            <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                                <br />
                                <Typography component="h1" variant="h5" align="center">
                                    Registro de contraseña
                                    <hr />
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="paterno"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="paterno"
                                            label="Apellido paterno"
                                            // autoFocus
                                            value={formik.values.paterno}
                                            onChange={(e) => {
                                                let pat = e.target.value;
                                                formik.setFieldValue("paterno", pat.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.paterno && formik.errors.paterno ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.paterno && formik.errors.paterno ? (
                                                formik.errors.paterno
                                            ) : null}
                                            type='text'
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="materno"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="materno"
                                            label="Apellido materno"
                                            value={formik.values.materno}
                                            onChange={(e) => {
                                                let mat = e.target.value;
                                                formik.setFieldValue("materno", mat.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.materno && formik.errors.materno ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.materno && formik.errors.materno ? (
                                                formik.errors.materno
                                            ) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="nombre"
                                            label="Nombre"
                                            name="nombre"
                                            value={formik.values.nombre}
                                            onChange={(e) => {
                                                let name = e.target.value;
                                                formik.setFieldValue("nombre", name.toUpperCase())
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.nombre && formik.errors.nombre ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.nombre && formik.errors.nombre ? (
                                                formik.errors.nombre
                                            ) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="pass"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="pass"
                                            label="Contraseña"
                                            autoFocus
                                            value={formik.values.pass}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.pass && formik.errors.pass ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.pass && formik.errors.pass ? (
                                                formik.errors.pass
                                            ) : null}
                                            type='password'
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="passconfirm"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="passconfirm"
                                            label="Confirmar contraseña"
                                            value={formik.values.passconfirm}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.passconfirm && formik.errors.passconfirm ? (
                                                true
                                            ) : false}
                                            helperText={formik.touched.passconfirm && formik.errors.passconfirm ? (
                                                formik.errors.passconfirm
                                            ) : null}
                                            type='password'
                                        />
                                    </Grid>

                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Guardar
                                </Button>
                            </form>
                        </Paper>
                    </div>
                </Container>
                : <Mensaje titulo="Link terminado" mensaje="Este link ya no esta disponible para ser usado. Solicite un nuevo link desde la ventana de registro." />
            }
            <Pie />
        </div >
    );
}

export default Password;