import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import axios from "axios";
import { Global } from "../../../constants/global"
import { Grid, Hidden, Typography } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import AgregarCurso from './AgregarCurso';
import ActualizarCurso from './ActualizarCurso';
import { Auth } from "../../../context/AuthContext";
import { fba } from "../../../firebaseConfig";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    grow: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    titulo: {
        fontSize: "2em",
        lineHeight: "1em",
        color: "#226af8",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
        textTransform: "capitalize",
        textAlign: 'center'
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
    campo: {
        background: theme.palette.text.main,
    },
}));

function TablaCurso(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [recarga, setRecarga] = useState(false)
    const [scroll, setScroll] = React.useState('paper');
    const [columns, setColumns] = useState([
        { title: 'Materia', field: 'materia.nombre' },
        { title: 'Profesor', render: (rowData) => rowData.profesor.persona.nombre + " " + rowData.profesor.persona.apellidoP + " " + rowData.profesor.persona.apellidoM },
        { title: 'Semestre', field: 'semestre', type: 'numeric' },
        { title: 'Grado', field: 'grado', type: 'numeric' },
        { title: 'Ciclo', field: 'ciclo', type: 'numeric', },
        { title: 'Sede', field: 'sede.nombre' },
    ]);
    const { usuario } = useContext(Auth);
    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');
    const [rol, setRol] = useState('')

    useEffect(() => {

        const datos = async () => {
            if (usuario.email !== null || usuario.email !== undefined) {
                db.where('correo', '==', usuario.email).get().then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        // console.log(doc.data())
                        if (usuario.email === doc.data().correo) {
                            setRol(doc.data().rol)
                        } else {
                            // console.log("No se encuentra");
                            return null;
                        }
                    });
                });
            }
            await axios.get(Global + "curso", {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                }
            }).then(response => {
                // console.log(response.data)
                setData(response.data)
            }).catch(error => console.log(error))
        }

        datos()
    }, [recarga])


    const handleClickOpen = (valores) => {
        setOpen(true)
    }

    const handleClose = (valor) => {
        if (valor) {
            setRecarga(!recarga)
        }
        setOpen(false)
    }

    return (
        rol === "coordinador_general" ?

            <>
                <Dialog
                    open={open}
                    scroll={scroll}
                    maxWidth='sm'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title" >
                        <Typography className={classes.subtitulo}>
                            Agregar nuevo curso
                    </Typography>
                    </DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <AgregarCurso metodo={handleClose} />
                    </DialogContent>
                </Dialog>
                <MaterialTable
                    title={
                        <Hidden smDown>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center">
                                <Grid item>
                                    <Typography className={classes.subtitulo}>
                                        Listado de cursos
                        </Typography>

                                </Grid>
                            </Grid>
                        </Hidden>}
                    options={{
                        exportButton:true,
                        exportFileName:"Listado de cursos",
                        search: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        headerStyle: {
                            backgroundColor: '#66abe0b8',
                            color: '#FFF'
                        }
                    }}
                    localization={{
                        toolbar: {
                            searchTooltip: 'Buscar en la tabla',
                            searchPlaceholder: "Buscar",
                            exportCSVName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            exportTitle: "Exportar"
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '',
                            labelRowsSelect: '',
                            firstTooltip: 'Primera página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Siguiente página',
                            lastTooltip: 'Última página',
                        },
                        body: {
                            editRow: {
                                deleteText: "¿Esta seguro que desea dar de baja la materia?",
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Dar de baja"
                            },
                            emptyDataSourceMessage: 'No hay información por mostrar',
                            deleteTooltip: "Dar de baja",

                        }
                    }}
                    columns={columns}
                    data={data}
                    actions={[
                        {
                            icon: 'add_box',
                            tooltip: 'Agregar',
                            isFreeAction: true,
                            onClick: (event) => handleClickOpen()
                        }
                    ]}
                    detailPanel={[
                        {
                            icon: 'more_vert',
                            openIcon: 'expand_less',
                            iconProps: { color: 'secondary' },
                            tooltip: 'Información de curso',
                            render: rowData => {
                                // console.log(rowData)
                                // actualiza()
                                return (
                                    <ActualizarCurso data={rowData} metodo={handleClose} rol={rol}/>
                                )
                            },
                        },
                    ]}
                />
            </>
            :
            <MaterialTable
                title={
                    <Hidden smDown>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Typography className={classes.subtitulo}>
                                    Listado de cursos
                                </Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                }
                options={{
                    exportButton:true,
                    exportFileName:"Listado de cursos",
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        backgroundColor: '#66abe0b8',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchTooltip: 'Buscar en la tabla',
                        searchPlaceholder: "Buscar",
                        exportCSVName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        exportTitle: "Exportar"
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    pagination: {
                        labelDisplayedRows: '',
                        labelRowsSelect: '',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Siguiente página',
                        lastTooltip: 'Última página',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay información por mostrar',
                        deleteTooltip: "Dar de baja",

                    }
                }}
                columns={columns}
                data={data}
                detailPanel={[
                    {
                        icon: 'more_vert',
                        openIcon: 'expand_less',
                        iconProps: { color: 'secondary' },
                        tooltip: 'Información de curso',
                        render: rowData => {
                            return (
                                <ActualizarCurso data={rowData} metodo={handleClose} rol={rol}/>
                            )
                        },
                    },
                ]}
            />

    )
}
export default TablaCurso
