import React, { useEffect, useState } from "react";
import {
  lighten,
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Hidden,Button } from "@material-ui/core";
import axios from "axios";
import { Global } from "../../../constants/global";
import { fba } from "../../../firebaseConfig";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import MaterialTable, { MTableToolbar } from "material-table";
import { obtenerAspirantes } from "../../../servicios/obtenerAspirantes";

function createData(
  idpersona,
  nombre,
  paterno,
  materno,
  correo,
  idaspirante,
  estatus,
  idposgrado,
  posgrado
) {
  return {
    idpersona,
    nombre,
    paterno,
    materno,
    correo,
    idaspirante,
    estatus,
    idposgrado,
    posgrado,
  };
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten("#61ce70", 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  con: {
    color: "#61ce70",
  },
}));

const useStyles = makeStyles({
  root: {
    width: "100%",
  },

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  boton: {
    fontFamily: "Poppins, Sans-serif",
    fontSize: "15px",
    textTransform: "none",
    lineHeight: "18px",
    borderStyle: "solid",
    borderRadius: "6px 6px 6px 6px",
    backgroundColor: "#2361b2",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#1481e8",
    },
  },
  subtitulo: {
    fontSize: "20px",
    fontFamily: "Roboto, Helvetica,Arial,sans-serif",
    fontWeight: 500,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EnhancedTable() {
  const classes = useStyles();
  const classesBar = useToolbarStyles();
  const [selected, setSelected] = useState([]);
  const rows2 = [];
  const [actualizar, setActualizar] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [columns] = useState([
    { title: "Paterno", field: "paterno" },
    { title: "Materno", field: "materno" },
    { title: "Nombre", field: "nombre" },
    { title: "Posgrado", field: "posgrado" },
  ]);

  const firestore = fba.firestore();

  const db = firestore.collection("usuarios");

  const handleAgregar = () => {
    selected.forEach((element) => {
      axios
        .get(Global + "aspirante/" + element, {
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          let cambio = response.data;
          cambio.estatus = "Registro_General";
          cambio.activo = 0;
          axios
            .put(Global + "aspirante/" + element, cambio, {
              headers: {
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
              },
            })
            .then((responses) => {
              setSelected([]);

              setActualizar(responses.data.persona.idP);
              handleClose();
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    });
  };

  useEffect(() => {
    //se sacan los datos del usuario logueado
    let userFB = fba.auth().currentUser;
    //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
    db.where("correo", "==", userFB.email)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos
          if (doc.data().posgrado === 0) {
            //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo

            axios
              .get(Global + "aspirante/estatus/Registro_Cita", {
                headers: {
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage.getItem("token"),
                },
              })
              .then((response) => {
                response.data.map((renglon, index) => {
                  rows2[index] = createData(
                    renglon[0],
                    renglon[1],
                    renglon[2],
                    renglon[3],
                    renglon[4],
                    renglon[5],
                    renglon[6],
                    renglon[7],
                    renglon[8]
                  );
                });

                setData(rows2);
              })
              .catch((error) => console.log(error));
          } else {
            //si es diferente de 0 entonces tiene acceso a solo un posgrado
            //se traen los registros de todos los aspirantes cullo estado sean de Registro_Completo y programa del usuario
            obtenerAspirantes(doc,'aspirante/estatus/Registro_Cita/programa/')
              .then(programa=>{
                  let aspirantes = [];
                  for(let aspirante of programa){
                      aspirantes = aspirantes.concat(aspirante);
                  }
                  console.log('lista de aspirantes',aspirantes)
                  aspirantes.map((renglon,index)=>{
                      rows2[index] = createData(renglon[0], renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8])
                  })
                  setData(rows2)
                  
              })
              .catch((err)=>{console.log(err)})
          }
        });
      });
  }, [actualizar]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      secondary: {
        dark: "#115293",
        main: "#4caf50",
      },
    },
  });

  return (
    <div className={classes.root}>
      <MaterialTable
        title={
          <Hidden smDown>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography className={classes.subtitulo}>Selección</Typography>
              </Grid>
            </Grid>
          </Hidden>
        }
        actions={[
            () => ({
                tooltip: "Agregar",
                position: "toolbarOnSelect",
            })
        ]}
        components={{
          Action: (props) => (
            <Button
              variant="contained"
              className={classes.boton}
              endIcon={
                <PlaylistAddCheckIcon
                  style={{ fontSize: 30 }}
                  className={classesBar.con}
                />
              }
              onClick={handleClickOpen}
            >
              Agregar
            </Button>
          ),
          Toolbar: (props) => (
            <MuiThemeProvider theme={theme}>
              <MTableToolbar {...props} />
            </MuiThemeProvider>
          ),
        }}
        options={{
          actionsColumnIndex: -1,
          actionsCellStyle: { width: "30%" },
          exportButton: true,
          exportFileName:
            "Aprobación de aspirantes para realizar el proceso de inscripción",
          search: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          headerStyle: {
            backgroundColor: "#66abe0b8",
            color: "#FFF",
          },

          selection: true,
          showTextRowsSelected: true,
          showSelectAllCheckbox: true,
        }}
        localization={{
          toolbar: {
            exportCSVName: "Exportar como CSV",
            exportPDFName: "Exportar como PDF",
            exportTitle: "Exportar",
            nRowsSelected: "{0} seleccionados",
          },
          pagination: {
            labelDisplayedRows: "",
            labelRowsSelect: "",
            firstTooltip: "Primera página",
            previousTooltip: "Página anterior",
            nextTooltip: "Siguiente página",
            lastTooltip: "Última página",
          },
          body: {
            emptyDataSourceMessage: "No hay información por mostrar",
          },
        }}
        data={data}
        columns={columns}
        onSelectionChange={(rows) => {
          let selectedRows = rows.map((aspirante) => aspirante.idaspirante);
          setSelected(selectedRows);
        }}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Verificación de acción"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro de que desea agregar los aspirantes seleccionados para
            el proceso de inscripción?, podrá seleccionar más aspirantes
            después.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.boton}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAgregar}
            color="primary"
            className={classes.boton}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
